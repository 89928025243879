import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { tasksModel } from "../../model/query/tasksModel";
import { userModel } from "../../model/query/userModel";
import { TError } from "../../types/error";
import { IImageDB } from "../../types/gallery";
import { ITask, ITaskDB } from "../../types/tasks";
import { IUserDB } from "../../types/user";
import { ErrorsActions } from "./errorsActions";

export enum TasksActions {
  SET_TASKS = "SET_TASKS",
  ADD_TASK = "ADD_TASK",
  REMOVE_TASK = "REMOVE_TASK",
  EDIT_TASK = "EDIT_TASK",
}

type TSetTasksAction = {
  type: TasksActions.SET_TASKS;
  payload: ITaskDB[];
};

type TRemoveTaskAction = {
  type: TasksActions.REMOVE_TASK;
  payload: string;
};

type TEditTaskAction = {
  type: TasksActions.EDIT_TASK;
  payload: ITaskDB;
};

type TAddTaskAction = {
  type: TasksActions.ADD_TASK;
  payload: ITaskDB;
};

export type TTasksAction =
  | TSetTasksAction
  | TRemoveTaskAction
  | TEditTaskAction
  | TAddTaskAction;

export const FETCH_AND_SET_TASKS = async (id: string) => {
  try {
    const tasks = await tasksModel.getTasksForUser(id);

    return {
      type: TasksActions.SET_TASKS,
      payload: tasks,
    };
  } catch (err) {
    return false;
  }
};

export const REMOVE_TASK_BY_ID = async (id: string) => {
  try {
    await tasksModel.removeTaskById(id);

    return {
      type: TasksActions.REMOVE_TASK,
      payload: id,
    };
  } catch (err: any) {
    throw err;
  }
};

export const EDIT_TASK = async (data: FormData) => {
  try {
    const task = await tasksModel.editTask(data);

    return {
      type: TasksActions.EDIT_TASK,
      payload: task,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors
    }
  }
};

export const ADD_TASK = async (data: FormData) => {
  try {
    const task = await tasksModel.addTask(data);

    return {
      type: TasksActions.ADD_TASK,
      payload: task,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors
    }
  }
};
