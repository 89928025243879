import { IAppConfig } from "../reducers/appConfigReducer";

export enum AppConfigActions {
  SET_APP_CONFIG = "SET_APP_CONFIG",
}

type TSetAppConfigAction = {
  type: AppConfigActions.SET_APP_CONFIG;
  payload: IAppConfig;
};

export const appConfigLanguageLocalStorageKey = "__config_language";
export const appConfigStoreLocalStorageKey = "__config_store";

export type TAppConfigAction = TSetAppConfigAction;

export const SET_APP_CONFIG = (config: IAppConfig) => {
  localStorage.setItem(appConfigLanguageLocalStorageKey, config.language._id);
  localStorage.setItem(appConfigStoreLocalStorageKey, config.store._id);

  return {
    type: AppConfigActions.SET_APP_CONFIG,
    payload: config,
  };
};
