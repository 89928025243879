import React, { useState } from "react";
import { getManufacturerName } from "../../common/functions";
import { useAppConfig, useManufacturers } from "../../common/redux-hooks";
import { NonTranslatedString } from "../../constants/language";
import { IManufacturerID } from "../../types/manufacturer";
import Checkbox from "../../ui/checkbox";
import { EditButton, FlexCenterAll } from "../../ui/common";
import Input from "../../ui/input";
import Table from "../../ui/table";

interface IRowActionButtons {
  manufacturer: IManufacturerID;
}

const RowActionButtons = ({
  manufacturer,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  return (
    <div>
      <EditButton
        path={`edit-manufacturer/${manufacturer._id}`}
        data-title="Upravit výrobce"
      />
    </div>
  );
};

interface IProps {}

export const ManufacturersList = ({}: IProps): React.ReactElement<IProps> => {
  const manufacturers = useManufacturers();
  const { language } = useAppConfig();
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        maxWidth: 700,
      }}
    >
      <Input
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vyhledat výrobce"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <Table
        query={search}
        headerKeys={[
          {
            content: "",
            width: "10%",
          },
          {
            content: "Název",
            width: "50%",
            query_key: "title",
          },
          {
            content: (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                Název je stejný pro všechny jazyky
              </div>
            ),
            width: "20%",
          },
          {
            content: (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                Zobrazovat výrobce u produktu
              </div>
            ),
            width: "20%",
          },
        ]}
        rows={manufacturers.map((m) => ({
          cells: [
            {
              content: <RowActionButtons manufacturer={m} />,
              key: "actions",
              value: "actions",
            },
            {
              content: getManufacturerName(m, language.locale) || (
                <NonTranslatedString />
              ),
              key: "value",
              value: m.name,
            },
            {
              content: (
                <FlexCenterAll>
                  <Checkbox
                    readonly
                    value={m.nameIsSameForAllLanguages}
                    setValue={() => null}
                  />
                </FlexCenterAll>
              ),
              key: "nameIsSameForAllLanguages",
              value: m.nameIsSameForAllLanguages,
            },
            {
              content: (
                <FlexCenterAll>
                  <Checkbox readonly value={m.visible} setValue={() => null} />
                </FlexCenterAll>
              ),
              key: "visible",
              value: m.visible,
            },
          ],
        }))}
      />
    </div>
  );
};

export default ManufacturersList;
