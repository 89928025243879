import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Button from "../../ui/button";
import { FlexCenterAlign } from "../../ui/common";
import { useColors } from "../../providers/theme/theme-provider";

export interface IFileInputRef {
  openFileInput(): void;
}

interface IProps {
  setFile(file: File): void;
  setFiles?(files: File[]): void;
  hidden?: boolean;
  customButton?: React.ReactNode;
  accept?: string;
  multiple?: boolean;
  label?: string;
}

export const FileInput = forwardRef(
  (
    {
      setFile,
      setFiles,
      hidden,
      customButton,
      accept = "image/jpeg, image/png, image/jpg",
      multiple = true,
      label,
    }: IProps,
    ref: React.ForwardedRef<IFileInputRef>
  ): React.ReactElement<IProps> => {
    const inputRef = useRef<HTMLInputElement>(null);
    const colors = useColors();

    const handleClick = () => {
      inputRef.current?.click();
    };

    useImperativeHandle(
      ref,
      () => ({
        openFileInput() {
          handleClick();
        },
      }),
      []
    );

    const onInputClick = (
      event: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
      const element = event.target as HTMLInputElement;
      element.value = "";
    };

    useEffect(() => {
      const handlePaste = (event: any) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData)
          .items;

        const files: File[] = [];

        for (const item of items) {
          if (item.type.indexOf("image") !== -1) {
            const blob: File = item.getAsFile();
            files.push(blob);
          }
        }

        if (files.length > 0) {
          if (setFiles) {
            setFiles(files);
          } else {
            setFile(files[0]);
          }
        }
      };

      document.addEventListener("paste", handlePaste);

      return () => {
        document.removeEventListener("paste", handlePaste);
      };
    }, []);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      if (files) {
        if (files.length > 1 && setFiles) {
          const filesArray = [];

          for (const file of files) {
            filesArray.push(file);
          }

          setFiles(filesArray);
        } else {
          for (const file of files) {
            setFile(file);
          }
        }
      }
    };

    return (
      <div>
        {!hidden &&
          (customButton || (
            <FlexCenterAlign>
              <Button onClick={handleClick}>
                {label ? label : multiple ? "Nahrát soubory" : "Nahrát soubor"}
              </Button>
              <div
                style={{
                  marginLeft: 10,
                  fontWeight: 500,
                  fontSize: 11,
                  color: colors.OPPOSITE_MAIN_100,
                }}
              >
                nebo Ctrl+V
              </div>
            </FlexCenterAlign>
          ))}
        <input
          multiple={multiple}
          accept={accept}
          type="file"
          ref={inputRef}
          onClick={onInputClick}
          onChange={handleChange}
          style={{
            display: "none",
            zIndex: -1,
          }}
        />
      </div>
    );
  }
);

export default FileInput;
