import { IManufacturerID } from "../../types/manufacturer";
import { manufacturerModel } from "./../../model/query/manufacturerModel";
import { ErrorsActions } from "./errorsActions";

export enum ManufacturerActions {
  SET_MANUFACTURERS = "SET_MANUFACTURERS",
  ADD_MANUFACTURER = "ADD_MANUFACTURER",
  EDIT_MANUFACTURER = "EDIT_MANUFACTURER",
  REMOVE_MANUFACTURER_BY_ID = "REMOVE_MANUFACTURER_BY_ID",
}

type TSetManufacturersAction = {
  type: ManufacturerActions.SET_MANUFACTURERS;
  payload: IManufacturerID[];
};

type TAddManufacturerAction = {
  type: ManufacturerActions.ADD_MANUFACTURER;
  payload: IManufacturerID;
};

type TEditManufacturerAction = {
  type: ManufacturerActions.EDIT_MANUFACTURER;
  payload: IManufacturerID;
};

type TRemoveManufacturerByIdAction = {
  type: ManufacturerActions.REMOVE_MANUFACTURER_BY_ID;
  payload: string;
};

export type TManufacturerAction =
  | TSetManufacturersAction
  | TAddManufacturerAction
  | TEditManufacturerAction
  | TRemoveManufacturerByIdAction;

export const FETCH_AND_SET_MANUFACTURERS = async () => {
  try {
    const Manufacturers = await manufacturerModel.getManufacturers();

    return {
      type: ManufacturerActions.SET_MANUFACTURERS,
      payload: Manufacturers,
    };
  } catch (err) {
    throw err;
  }
};

export const ADD_MANUFACTURER = async (data: FormData) => {
  try {
    const Manufacturer = await manufacturerModel.addManufacturer(data);

    return {
      type: ManufacturerActions.ADD_MANUFACTURER,
      payload: Manufacturer,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_MANUFACTURER = async (data: FormData) => {
  try {
    const Manufacturer = await manufacturerModel.editManufacturer(data);

    return {
      type: ManufacturerActions.EDIT_MANUFACTURER,
      payload: Manufacturer,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_MANUFACTURER_BY_ID = async (id: string) => {
  try {
    await manufacturerModel.removeManufacturerById(id);

    return {
      type: ManufacturerActions.REMOVE_MANUFACTURER_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw err;
  }
};
