import React from "react";
import { FiEye } from "react-icons/fi";
import {
  IModalContext,
  useModal,
} from "../../../../providers/modal/modal-provider";
import colors from "../../../../ui/colors";
import { FlexCenterAll, HeaderText } from "../../../../ui/common";
import IconButton from "../../../../ui/icon-button";
import { BeforeAndAfter } from "../../../components/history/history-view-changes";
import { IHistoryActionChanges } from "../../../../types/history";
import { useColors } from "../../../../providers/theme/theme-provider";

interface IProps {
  modalContext: IModalContext;
  changed: IHistoryActionChanges;
}

export const ProductChangesModal = ({
  changed,
  modalContext,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors()

  return (
    <div>
      <div style={{
        fontSize: 14
      }}>
        Stav produktu byl uložen při předchozí úpravě, chcete předchozí stav načíst?
      </div>
      <FlexCenterAll
        style={{
          marginTop: 8,
        }}
      >
        <div style={{
          fontSize: 11,
          marginRight: 8,
          opacity: 0.6
        }}>
          Zobrazit předchozí stav
        </div>
        <IconButton
          onClick={() =>
            modalContext.createModal(
              <div
                style={{
                  maxWidth: 900,
                }}
              >
                <HeaderText
                  style={{
                    fontSize: 15,
                    marginTop: 20,
                    marginLeft: 12,
                    fontWeight: 500,
                  }}
                >
                  Nastanou tyto změny
                </HeaderText>
                <BeforeAndAfter after={changed.after} before={changed.before} />
              </div>
            )
          }
          data-title="Zobrazit změny"
          style={{
            padding: 5,
            backgroundColor: colors.OPPOSITE_MAIN_250,
            marginRight: 6,
          }}
        >
          <FiEye size={18} color={colors.MAIN_400} />
        </IconButton>
      </FlexCenterAll>
    </div>
  );
};

export default ProductChangesModal;
