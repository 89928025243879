import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { getManufacturerName } from "../../../common/functions";
import {
  useAppConfig,
  useCategoriesState,
  useManufacturers,
  useParameterGroups,
} from "../../../common/redux-hooks";
import { NonTranslatedString } from "../../../constants/language";
import { useColors } from "../../../providers/theme/theme-provider";
import { IProductParameter } from "../../../types/parameter";
import {
  IProductFilters,
  ProductFilterItemType,
  TProductFilterItem,
} from "../../../types/products";
import { TNumberWithUnit, TUnit } from "../../../types/units";
import AppleSwitcher from "../../../ui/apple-switcher";
import CategoryMultiSelection from "../../../ui/category-multi-selection";
import { FlexCenterAlign } from "../../../ui/common";
import Dropdown from "../../../ui/dropdown";
import DropdownMultiSelect from "../../../ui/dropdown-multi-select";
import Input from "../../../ui/input";
import ValueWithUnitInput from "../../../ui/value-with-unit-input";
import ParametersInput from "../product-editor/components/parameters-input/parameters-input";

export const NumberFiltersWrapper = styled.div`
  display: table;

  & > * {
    display: table-row;

    & > div {
      display: table-cell;
      vertical-align: middle;
      padding-top: 4px;
      padding-right: 8px;
    }
  }
`;

interface IProductFilterCategoriesSelectProps {
  categories: TProductFilterItem<string[]>;
  setFilters: Dispatch<SetStateAction<IProductFilters>>;
}

export const ProductFilterCategoriesSelect = ({
  categories,
  setFilters,
}: IProductFilterCategoriesSelectProps): React.ReactElement<IProductFilterCategoriesSelectProps> => {
  const { populatedCategories } = useCategoriesState();

  return (
    <div>
      <FlexCenterAlign
        style={{
          marginBottom: 12,
        }}
      >
        <AppleSwitcher
          setValue={() =>
            setFilters((f) => ({
              ...f,
              categories: {
                ...f.categories,
                type:
                  f.categories.type === ProductFilterItemType.Contains
                    ? ProductFilterItemType.DoesNotContain
                    : ProductFilterItemType.Contains,
              },
            }))
          }
          value={categories.type === ProductFilterItemType.Contains}
        />
        <div
          style={{
            fontSize: 12,
            marginLeft: 8,
          }}
        >
          {categories.type === ProductFilterItemType.Contains
            ? "Obsahují"
            : "Neobsahují"}{" "}
          tyto kategorie
        </div>
      </FlexCenterAlign>
      <CategoryMultiSelection
        selectedIDs={categories.value}
        addCategory={(id) =>
          setFilters((f) => ({
            ...f,
            categories: {
              ...f.categories,
              value: [...f.categories.value, id],
            },
          }))
        }
        removeCategory={(id) =>
          setFilters((f) => ({
            ...f,
            categories: {
              ...f.categories,
              value: f.categories.value.filter((_id) => id !== _id),
            },
          }))
        }
        list={populatedCategories}
        categories={populatedCategories}
        deleteTitle="Odstranit kategorii z filtru"
        title="Přidejte kategorii do filtru"
      />
    </div>
  );
};

const PriceFilterWrapper = styled.div`
  align-items: center;

  & > * {
    margin-right: 8px;
  }
`;

interface IProductFilterNumberItemProps {
  title: string;
  numberItem: TProductFilterItem<number>;
  setNumberItem(numberItem: TProductFilterItem<number>): void;
}

export const ProductFilterNumberItem = ({
  title,
  numberItem,
  setNumberItem,
}: IProductFilterNumberItemProps): React.ReactElement<IProductFilterNumberItemProps> => {
  const colors = useColors();

  return (
    <PriceFilterWrapper
      style={{
        marginBottom: 12,
        marginTop: 8,
      }}
    >
      <div>
        <div
          style={{
            fontSize: 13,
            textAlign: "right",
          }}
        >
          {title} je
        </div>
      </div>

      <div>
        <Dropdown
          list={[
            {
              content: "menší než",
              value: ProductFilterItemType.LessThan,
              unique_id: ProductFilterItemType.LessThan,
            },
            {
              content: "rovna",
              value: ProductFilterItemType.Equal,
              unique_id: ProductFilterItemType.Equal,
            },
            {
              content: "větší než",
              value: ProductFilterItemType.MoreThan,
              unique_id: ProductFilterItemType.MoreThan,
            },
          ]}
          onSelectValue={(type) => setNumberItem({ ...numberItem, type })}
          selectedUniqueId={numberItem.type}
          isDeleteButtonDisabled
        />
      </div>
      <div>
        <Input
          value={numberItem.value}
          setValue={(value) => setNumberItem({ ...numberItem, value })}
          type="number"
        />
      </div>
    </PriceFilterWrapper>
  );
};

interface IProductFilterParamsSelectProps {
  params: TProductFilterItem<IProductParameter[]>;
  setFilters: Dispatch<SetStateAction<IProductFilters>>;
}

export const ProductFilterParamsSelect = ({
  params,
  setFilters,
}: IProductFilterParamsSelectProps): React.ReactElement<IProductFilterParamsSelectProps> => {
  return (
    <div>
      <FlexCenterAlign
        style={{
          marginBottom: 12,
        }}
      >
        <AppleSwitcher
          setValue={() =>
            setFilters((f) => ({
              ...f,
              params: {
                ...f.params,
                type:
                  f.params.type === ProductFilterItemType.Contains
                    ? ProductFilterItemType.DoesNotContain
                    : ProductFilterItemType.Contains,
              },
            }))
          }
          value={params.type === ProductFilterItemType.Contains}
        />
        <div
          style={{
            fontSize: 12,
            marginLeft: 8,
          }}
        >
          {params.type === ProductFilterItemType.Contains
            ? "Obsahují"
            : "Neobsahují"}{" "}
          tyto parametry
        </div>
      </FlexCenterAlign>

      <ParametersInput
        isForFilter
        params={params.value}
        setParams={(params) =>
          setFilters((f) => ({ ...f, params: { ...f.params, value: params } }))
        }
      />
    </div>
  );
};

interface IProductFilterManufacturerSelectProps {
  manufacturer: TProductFilterItem<string[]>;
  setFilters: Dispatch<SetStateAction<IProductFilters>>;
}

export const ProductFilterManufacturerSelect = ({
  manufacturer,
  setFilters,
}: IProductFilterManufacturerSelectProps): React.ReactElement<IProductFilterManufacturerSelectProps> => {
  const allManufacturers = useManufacturers();
  const { language } = useAppConfig();

  return (
    <div>
      <FlexCenterAlign
        style={{
          marginBottom: 12,
        }}
      >
        <AppleSwitcher
          setValue={() =>
            setFilters((f) => ({
              ...f,
              manufacturer: {
                ...f.manufacturer,
                type:
                  f.manufacturer.type === ProductFilterItemType.Contains
                    ? ProductFilterItemType.DoesNotContain
                    : ProductFilterItemType.Contains,
              },
            }))
          }
          value={manufacturer.type === ProductFilterItemType.Contains}
        />
        <div
          style={{
            fontSize: 12,
            marginLeft: 8,
          }}
        >
          {manufacturer.type === ProductFilterItemType.Contains
            ? "Obsahují"
            : "Neobsahují"}{" "}
          tyto výrobce
        </div>
      </FlexCenterAlign>

      <DropdownMultiSelect
        title="Přidejte výrobce do filtru"
        deleteTitle="Odebrat výrobce z filtru"
        list={allManufacturers.map((m) => ({
          content: getManufacturerName(m, language.locale) || (
            <NonTranslatedString />
          ),
          value: m._id,
          unique_id: m._id,
          disabled: manufacturer.value.includes(m._id),
        }))}
        onRemoveUniqueID={(id) =>
          setFilters((f) => ({
            ...f,
            manufacturer: {
              ...f.manufacturer,
              value: f.manufacturer.value.filter((_id) => _id !== id),
            },
          }))
        }
        onSelectValue={(_, id) => {
          setFilters((f) => ({
            ...f,
            manufacturer: {
              ...f.manufacturer,
              value: [...f.manufacturer.value, id],
            },
          }));
        }}
        selectedDropdownIDs={manufacturer.value}
      />
    </div>
  );
};

interface IProductFilterParameterGroupSelectProps {
  parameter_groups: TProductFilterItem<string[]>;
  setFilters: Dispatch<SetStateAction<IProductFilters>>;
}

export const ProductFilterParameterGroupSelect = ({
  parameter_groups,
  setFilters,
}: IProductFilterParameterGroupSelectProps): React.ReactElement<IProductFilterParameterGroupSelectProps> => {
  const allParameterGroups = useParameterGroups();

  return (
    <div
      style={{
        width: 340,
      }}
    >
      <FlexCenterAlign
        style={{
          marginBottom: 12,
        }}
      >
        <AppleSwitcher
          setValue={() =>
            setFilters((f) => ({
              ...f,
              parameter_groups: {
                ...f.parameter_groups,
                type:
                  f.parameter_groups.type === ProductFilterItemType.Contains
                    ? ProductFilterItemType.DoesNotContain
                    : ProductFilterItemType.Contains,
              },
            }))
          }
          value={parameter_groups.type === ProductFilterItemType.Contains}
        />
        <div
          style={{
            fontSize: 12,
            marginLeft: 8,
          }}
        >
          {parameter_groups.type === ProductFilterItemType.Contains
            ? "Obsahují"
            : "Neobsahují"}{" "}
          tyto parametrové skupiny
        </div>
      </FlexCenterAlign>

      <DropdownMultiSelect
        title="Přidejte parameterovou skupinu do filtru"
        deleteTitle="Odebrat parameterovou skupinu z filtru"
        list={allParameterGroups.map((m) => ({
          content: m.title,
          value: m._id,
          unique_id: m._id,
          disabled: parameter_groups.value.includes(m._id),
        }))}
        onRemoveUniqueID={(id) =>
          setFilters((f) => ({
            ...f,
            parameter_groups: {
              ...f.parameter_groups,
              value: f.parameter_groups.value.filter((_id) => _id !== id),
            },
          }))
        }
        onSelectValue={(_, id) => {
          setFilters((f) => ({
            ...f,
            parameter_groups: {
              ...f.parameter_groups,
              value: [...f.parameter_groups.value, id],
            },
          }));
        }}
        selectedDropdownIDs={parameter_groups.value}
      />
    </div>
  );
};

interface IFilterNumberInputWithUnitProps<T = TUnit> {
  title: string;
  item: TProductFilterItem<TNumberWithUnit<T>>;
  setItem(item: TProductFilterItem<TNumberWithUnit<T>>): void;
}

const FilterNumberInputWithUnit = <T extends TUnit>({
  title,
  item,
  setItem,
}: IFilterNumberInputWithUnitProps<T>) => {
  return (
    <div>
      <div>
        <div
          style={{
            fontSize: 13,
            textAlign: "right",
          }}
        >
          {title} je
        </div>
      </div>

      <div>
        <Dropdown
          list={[
            {
              content: "menší než",
              value: ProductFilterItemType.LessThan,
              unique_id: ProductFilterItemType.LessThan,
            },
            {
              content: "rovna",
              value: ProductFilterItemType.Equal,
              unique_id: ProductFilterItemType.Equal,
            },
            {
              content: "větší než",
              value: ProductFilterItemType.MoreThan,
              unique_id: ProductFilterItemType.MoreThan,
            },
          ]}
          onSelectValue={(type) => setItem({ ...item, type })}
          selectedUniqueId={item.type}
          isDeleteButtonDisabled
        />
      </div>

      <div>
        <ValueWithUnitInput
          value={item.value.value}
          unit={item.value.unit}
          setUnit={(unit) =>
            setItem({ ...item, value: { ...item.value, unit } })
          }
          setValue={(value) =>
            setItem({ ...item, value: { ...item.value, value } })
          }
        />
      </div>
    </div>
  );
};

interface IProductFilterPackagingProps {
  filters: IProductFilters;
  setFilters: Dispatch<SetStateAction<IProductFilters>>;
}

export const ProductFilterPackaging = ({
  filters,
  setFilters,
}: IProductFilterPackagingProps): React.ReactElement<IProductFilterPackagingProps> => {
  return (
    <>
      <FilterNumberInputWithUnit
        title="Délka"
        item={filters.length}
        setItem={(length) => setFilters((f) => ({ ...f, length }))}
      />
      <FilterNumberInputWithUnit
        title="Šířka"
        item={filters.width}
        setItem={(width) => setFilters((f) => ({ ...f, width }))}
      />
      <FilterNumberInputWithUnit
        title="Výška"
        item={filters.height}
        setItem={(height) => setFilters((f) => ({ ...f, height }))}
      />
      <FilterNumberInputWithUnit
        title="Hrubá váha"
        item={filters.brutto_weight}
        setItem={(brutto_weight) =>
          setFilters((f) => ({ ...f, brutto_weight }))
        }
      />
      <FilterNumberInputWithUnit
        title="Čistá váha"
        item={filters.netto_weight}
        setItem={(netto_weight) => setFilters((f) => ({ ...f, netto_weight }))}
      />
    </>
  );
};
