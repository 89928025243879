import React from "react";
import { ICategory, IPopulatedCategoryId } from "../../../../types/category";
import CategoryMultiSelection from "../../../../ui/category-multi-selection";
import { InputsWrapper } from "../../../../ui/common";

interface IProps {
  categories: IPopulatedCategoryId[];
  productCategories: string[];
  setCategories(categories: string[]): void;
}

export const ProductCategories = ({
  categories,
  productCategories,
  setCategories,
}: IProps): React.ReactElement<IProps> => {
  return (
    <InputsWrapper>
      <CategoryMultiSelection
        title="Vyberte kategorii produktu"
        deleteTitle="Odstranit kategorii"
        addCategory={(id) => {
          if (!id) return;

          setCategories([...productCategories, id]);
        }}
        categories={categories}
        list={categories.filter((c) => !productCategories.includes(c._id))}
        removeCategory={(_id) => {
          let newCategories = [...productCategories];

          newCategories = newCategories.filter((id) => id !== _id);

          setCategories(newCategories);
        }}
        selectedIDs={productCategories}
      />
    </InputsWrapper>
  );
};

export default ProductCategories;
