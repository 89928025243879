import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppConfig, useParameterValues } from "../../../common/redux-hooks";
import {
  defaultLanguageKey,
  NonTranslatedString,
} from "../../../constants/language";
import { IParameterValueID } from "../../../types/parameter";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButtonActionStyles,
  EditButton,
  FlexCenterAll,
} from "../../../ui/common";
import Input from "../../../ui/input";
import Table from "../../../ui/table";

interface IRowActionButtons {
  parameterValue: IParameterValueID;
}

const RowActionButtons = ({
  parameterValue,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  return (
    <div>
      <EditButton
        path={`edit-value/${parameterValue._id}`}
        data-title="Upravit hodnotu"
      />
    </div>
  );
};

interface IProps {}

export const ParameterValuesList = ({}: IProps): React.ReactElement<IProps> => {
  const parameterValues = useParameterValues();
  const navigate = useNavigate();
  const { language } = useAppConfig();
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        maxWidth: 700,
        marginLeft: 40,
      }}
    >
      <Button
        style={{ ...AddButtonActionStyles }}
        onClick={() => navigate("/parameters/add-value")}
      >
        Přidat hodnotu
      </Button>
      <Input
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vyhledat hodnotu"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <Table
        query={search}
        headerKeys={[
          {
            content: "",
            width: "10%",
          },
          {
            content: "Hodnota",
            width: "55%",
            query_key: "value",
          },
          {
            content: (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                Hodnota je stejná pro všechny jazyky
              </div>
            ),
            width: "35%",
          },
        ]}
        rows={parameterValues.map((p) => ({
          cells: [
            {
              content: <RowActionButtons parameterValue={p} />,
              key: "actions",
              value: "actions",
            },
            {
              content: p.value[
                p.valueIsSameForAllLanguages
                  ? defaultLanguageKey
                  : language.locale
              ] || <NonTranslatedString />,
              key: "value",
              value: p.value,
            },
            {
              content: (
                <FlexCenterAll>
                  <Checkbox
                    readonly
                    value={p.valueIsSameForAllLanguages}
                    setValue={() => null}
                  />
                </FlexCenterAll>
              ),

              key: "valueIsSameForAllLanguages",
              value: p.valueIsSameForAllLanguages,
            },
          ],
        }))}
      />
    </div>
  );
};

export default ParameterValuesList;
