import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from "react";
import styled from "styled-components";
import { TCallback } from "../../types/common";
import { CloseButton, InputsWrapper } from "../../ui/common";
import Overlay from "../common/overlay";
import { useImageWindow } from "../image-window/image-window-provider";
import { useColors } from "../theme/theme-provider";
import { useModal } from "./modal-provider";

const ModalComponentWrapper = styled.div`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 200ms ease;

  &.appear {
    opacity: 1;
  }
`;

interface IClosingWrapperProps {
  children: React.ReactNode;
  onClose(): void;
}

export const ClosingWrapper = ({
  children,
  onClose,
}: IClosingWrapperProps): React.ReactElement<IClosingWrapperProps> => {
  const colors = useColors()

  return (
    <InputsWrapper
      style={{
        position: "relative",
        backgroundColor: colors.MAIN_450,
        padding: 12
      }}
    >
      <div
        style={{
          position: "absolute",
          right: 12,
          top: 12,
          zIndex: 100000,
        }}
      >
        <CloseButton data-title="Zavřít okno (Esc)" onClick={onClose} />
      </div>
      <div>{children}</div>
    </InputsWrapper>
  );
};

export interface IModalWindowRef {
  close(callback: TCallback): void;
}

interface IProps {
  component: React.ReactNode;
  withoutClosingWrapper?: boolean;
}

export const ModalWindow = forwardRef(
  (
    { component, withoutClosingWrapper }: IProps,
    ref: ForwardedRef<IModalWindowRef>
  ): React.ReactElement<IProps> => {
    const { lastIndex, closeModal } = useModal();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const { isImageWindowOpened } = useImageWindow();

    useImperativeHandle(
      ref,
      () => ({
        close(callback) {
          if (!wrapperRef.current) return;

          const eventListener = (e: TransitionEvent) => {
            if (wrapperRef.current && e.target === wrapperRef.current) {
              callback();
            }
          };

          overlayRef.current?.classList.remove("active");
          wrapperRef.current.classList.remove("appear");
          wrapperRef.current.addEventListener("transitionend", eventListener);
        },
      }),
      [wrapperRef.current]
    );

    useEffect(() => {
      setTimeout(() => {
        if (!wrapperRef.current) return;

        overlayRef.current?.classList.add("active");
        wrapperRef.current.classList.add("appear");
      }, 0);
    }, [wrapperRef.current]);

    const escHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape" && !isImageWindowOpened) {
        closeModal();
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", escHandler, false);
      return () => {
        document.removeEventListener("keydown", escHandler, false);
      };
    }, [lastIndex, isImageWindowOpened]);

    return (
      <Overlay ref={overlayRef} zIndex={1}>
        <ModalComponentWrapper ref={wrapperRef}>
          {withoutClosingWrapper ? (
            component
          ) : (
            <ClosingWrapper onClose={closeModal}>{component}</ClosingWrapper>
          )}
        </ModalComponentWrapper>
      </Overlay>
    );
  }
);

export default ModalWindow;
