import { Dispatch } from "@reduxjs/toolkit";
import { FETCH_AND_SET_CATEGORIES } from "../redux/actions/categoryActions";
import { FETCH_AND_SET_CURRENCIES } from "../redux/actions/currencyActions";
import { FETCH_AND_SET_LANGUAGES } from "../redux/actions/languagesActions";
import { FETCH_AND_SET_PARAMETER_GROUPS } from "../redux/actions/parameterGroupsActions";
import { FETCH_AND_SET_PAYMENTS } from "../redux/actions/paymentActions";
import { FETCH_AND_SET_SHIPPINGS } from "../redux/actions/shippingActions";
import { FETCH_AND_SET_STORES } from "../redux/actions/storesActions";
import { FETCH_AND_SET_TASKS } from "../redux/actions/tasksActions";
import { CHECK_AUTH, LOGOUT_USER } from "../redux/actions/userActions";
import { FETCH_AND_SET_USERS } from "../redux/actions/usersActions";
import { IUserDB } from "../types/user";
import { FETCH_AND_SET_MANUFACTURERS } from "./../redux/actions/manufacturerActions";
import { FETCH_AND_SET_PARAMETERS } from "./../redux/actions/parameterActions";
import { FETCH_AND_SET_PARAMETER_VALUES } from "./../redux/actions/parameterValueActions";

export const FETCH_AND_SET_ALL = (
  user: IUserDB,
  dispatch: Dispatch,
  logged: boolean,
  onDone: () => void
) => {
  const fetchAndSet = async () => {
    try {
      const auth = await CHECK_AUTH();

      if (!auth) {
        dispatch(LOGOUT_USER());
      }
    } catch (err) {
      dispatch(LOGOUT_USER());
    }

    let requestsCompleted = 0;
    let targetRequestsCompleted = 12;

    const completeRequest = () => {
      requestsCompleted++;

      if (requestsCompleted === targetRequestsCompleted) {
        onDone();
      }
    };

    FETCH_AND_SET_LANGUAGES()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_STORES()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_CATEGORIES()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_SHIPPINGS()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_PAYMENTS()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_CURRENCIES()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_TASKS(user._id)
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_USERS()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_PARAMETERS()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_PARAMETER_VALUES()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_PARAMETER_GROUPS()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });

    FETCH_AND_SET_MANUFACTURERS()
      .then((action) => {
        completeRequest();
        if (action) {
          dispatch(action);
        }
      })
      .catch(() => {
        completeRequest();
      });
  };

  if (logged && user) {
    fetchAndSet();
  } else {
    onDone();
  }
};
