import React, { useRef, useState } from "react";
import {
  GlobalErrorWindow,
  IGlobalErrorWindowRef,
} from "./global-error-window";

interface IGlobalErrorContext {
  showError(e: ErrorEvent): void;
}

const GlobalErrorContext = React.createContext<IGlobalErrorContext>(
  {} as IGlobalErrorContext
);

export const useGlobalError = () => {
  return React.useContext(GlobalErrorContext);
};

interface IProps {
  children: React.ReactNode;
}

export const GlobalErrorProvider = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const globalErrorWindow = useRef<IGlobalErrorWindowRef>(null);

  const globalErrorContext: IGlobalErrorContext = {
    showError(e) {
      globalErrorWindow.current?.showError(e);
    },
  };

  return (
    <GlobalErrorContext.Provider value={globalErrorContext}>
      <GlobalErrorWindow ref={globalErrorWindow} />
      {children}
    </GlobalErrorContext.Provider>
  );
};

export default GlobalErrorProvider;
