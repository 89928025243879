import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { useClickOutside } from "../../../common/use-click-outside";
import { useTooltipProvider } from "../../../providers/tooltip/tooltip-provider";
import { TaskPriority } from "../../../types/tasks";
import colors from "../../../ui/colors";
import { getPriorityComponent, priorityArray } from "./components";

interface IPriorityDropdownWrapper {
  active: boolean;
}

const PriorityDropdownWrapper = styled.div<IPriorityDropdownWrapper>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_400};
  `}

  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
  box-shadow: 2px 2px 8px #00000010;
  border-radius: 8px;
  padding: 4px;
  margin-left: -3px;
  margin-top: 5px;

  transition: visibility 150ms ease, opacity 150ms ease, height 150ms ease;

  ${({ active }) =>
    active &&
    css`
      z-index: 10;
      visibility: visible;
      opacity: 1;
      height: 112px;
    `}
`;

interface IProps {
  priority: TaskPriority;
  onSelectPriority(priority: TaskPriority): void;
  disabled?: boolean;
}

export const PriorityDropdown = ({
  priority,
  onSelectPriority,
  disabled,
}: IProps): React.ReactElement<IProps> => {
  const [active, setActive] = useState(false);
  const wrapperRef = useRef(null);
  const ignoreRef = useRef(null);

  const { clearTooltip } = useTooltipProvider();

  useEffect(() => {
    if (active) {
      clearTooltip();
    }
  }, [active]);

  useClickOutside(
    wrapperRef,
    () => {
      setActive(false);
    },
    ignoreRef
  );

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div ref={ignoreRef}>
        {getPriorityComponent(
          priority,
          !disabled,
          disabled
            ? undefined
            : () => {
                setActive(!active);
              },
          active
        )}
      </div>
      {!disabled && (
        <PriorityDropdownWrapper ref={wrapperRef} active={active}>
          {priorityArray.map((p, i) => (
            <div
              key={i}
              style={{
                marginBottom: 4,
              }}
            >
              {getPriorityComponent(p, true, () => {
                onSelectPriority(p);
                setActive(false);
              })}
            </div>
          ))}
        </PriorityDropdownWrapper>
      )}
    </div>
  );
};

export default PriorityDropdown;
