import React, {
  createContext,
  PropsWithChildren,
  useState,
  useEffect,
  useRef,
} from "react";
import { Animations, Theme } from "../../types/theme";
import { DARK_THEME, IThemeColors, LIGHT_THEME } from "./themes";
import { ThemeContext } from "styled-components";

const LOCALSTORAGE_THEME_KEY = "__theme";
const LOCALSTORAGE_ANIMATIONS_KEY = "__animations";
const LOCALSTORAGE_BORDER_RADIUS_KEY = "__borderRadius";

export interface IThemeContext {
  colors: IThemeColors;
  theme: Theme;
  borderRadius: number;
  animations: Animations;
  updateTheme(theme: Theme): void;
  updateAnimations(animations: Animations): void;
  updateBorderRadius(borderRadius: number): void;
}

const defaultAnimations = Animations.Pretty;
const defaultTheme = Theme.Dark;

const getColorPalleteByTheme = (theme: Theme) => {
  switch (theme) {
    case Theme.Light:
      return LIGHT_THEME;
    case Theme.Dark:
      return DARK_THEME;
  }
};

const defaultBorderRadius = 8;

export const borderRadiusRange = {
  min: 0,
  max: 24,
};

export const useTheme = () => {
  const context = React.useContext(ThemeContext);

  if (!context) {
    throw new Error("Theme context is not defined!");
  }

  return context;
};

export const useColors = (): IThemeColors => {
  return useTheme().colors;
};

export const ThemeProvider = ({
  children,
}: PropsWithChildren): React.ReactElement<PropsWithChildren> => {
  const [animations, setAnimations] = useState(defaultAnimations);
  const [theme, setTheme] = useState(defaultTheme);
  const [borderRadius, setBorderRadius] = useState(defaultBorderRadius);

  const updateTheme = (theme: Theme) => {
    localStorage.setItem(LOCALSTORAGE_THEME_KEY, theme);
    setTheme(theme);
  };

  const updateAnimations = (animations: Animations) => {
    localStorage.setItem(LOCALSTORAGE_ANIMATIONS_KEY, animations);
    setAnimations(animations);
  };

  const canSave = useRef(true);

  const updateBorderRadius = (_borderRadius: number) => {
    if (canSave.current) {
      canSave.current = false;

      localStorage.setItem(
        LOCALSTORAGE_BORDER_RADIUS_KEY,
        _borderRadius.toString()
      );

      setBorderRadius(_borderRadius);

      setTimeout(() => {
        canSave.current = true;
      }, 150);
    }
  };

  useEffect(() => {
    const themeItem = localStorage.getItem(LOCALSTORAGE_THEME_KEY);
    const animationsItem = localStorage.getItem(LOCALSTORAGE_ANIMATIONS_KEY);
    const borderRadiusItem = localStorage.getItem(
      LOCALSTORAGE_BORDER_RADIUS_KEY
    );

    if (themeItem && (themeItem === Theme.Light || themeItem === Theme.Dark)) {
      updateTheme(themeItem);
    }

    if (
      animationsItem &&
      (animationsItem === Animations.None ||
        animationsItem === Animations.Pretty)
    ) {
      updateAnimations(animationsItem);
    }

    if (borderRadiusItem && !isNaN(parseFloat(borderRadiusItem))) {
      updateBorderRadius(parseFloat(borderRadiusItem));
    } else {
      updateBorderRadius(defaultBorderRadius);
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        colors: getColorPalleteByTheme(theme),
        theme,
        updateTheme,
        updateBorderRadius,
        updateAnimations,
        borderRadius,
        animations,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
