import { IEditorParameter } from "./parameter-editor";
import { isLanguageStringEmpty } from "../../../common/functions";
import { TGetError } from "../../../common/redux-hooks";
import { TLanguageString } from "../../../types/common";
import { ErrorType, TReduxError } from "../../../types/error";
import { ISlugErrorsObject, getSlugErrors } from "../../../common/slug-validator";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.PARAMETER);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export interface TParameterErrorsObject extends ISlugErrorsObject {
  title?: TReduxError;
  canHaveAnyValue?: TReduxError;
};

type TParameterErrors = {
  errors: TParameterErrorsObject;
  isValid: boolean;
};

export const getParameterErrors = (
  getError: TGetError,
  parameter: IEditorParameter,
  locale: string
): TParameterErrors => {
  const title = getErrorOfKey(
    "title",
    getError,
    isLanguageStringEmpty(parameter.title)
  );

  const canHaveAnyValue = getErrorOfKey(
    "canHaveAnyValue",
    getError,
    !parameter.canHaveAnyValue && !parameter.values.length
  );

  const { errors: slugErrors, isValid: slugIsValid } = getSlugErrors(
    parameter.slug,
    getError,
    locale
  );


  return {
    errors: {
      title,
      canHaveAnyValue,
      ...slugErrors
    },
    isValid: !title && !canHaveAnyValue && slugIsValid,
  };
};
