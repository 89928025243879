import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useNavigateCatch } from "../../../common/navigate";
import { useErrors, useLanguages } from "../../../common/redux-hooks";
import { currencyModel } from "../../../model/query/currencyModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_CURRENCY,
  ADD_DEFAULT_CURRENCY,
  EDIT_CURRENCY,
  EDIT_DEFAULT_CURRENCY,
  REMOVE_CURRENCY_BY_ID,
} from "../../../redux/actions/currencyActions";
import { useDispatch } from "../../../redux/store";
import { EditorType } from "../../../types/common";
import { ICurrencyID } from "../../../types/currency";
import { ErrorType } from "../../../types/error";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
} from "../../../ui/common";
import { InputWithTitle } from "../../../ui/input-with-title";
import BackButton from "../../components/back-button";
import { getCurrencyEditorErrors } from "./editor-errors";
import { adminRegistrationModel } from "../../../model/query/adminRegistrationModel";

const mapCurrencyToEditorCurrency = (
  currency: ICurrencyID
): IEditorCurrency => {
  const { title, symbol, currency_shortcut } = currency;

  return {
    currency_shortcut,
    symbol,
    title,
  };
};

const initialEditorCurrency: IEditorCurrency = {
  title: "",
  symbol: "",
  currency_shortcut: "",
};

export interface IEditorCurrency {
  title: string;
  symbol: string;
  currency_shortcut: string;
}

interface IProps {
  type: EditorType;
  isForDefaultCurrency?: boolean;
  registrationKey?: string;
  onSuccess?(currency: ICurrencyID): void;
  outerId?: string;
}

export const CurrencyEditor = ({
  type,
  isForDefaultCurrency,
  registrationKey,
  onSuccess,
  outerId,
}: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const [originalEditingCurrency, setOriginalEditingCurrency] =
    useState<ICurrencyID | null>(null);
  const [currency, setCurrency] = useState<IEditorCurrency>(
    initialEditorCurrency
  );

  const { closeNotification } = useNotifications();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getCurrencyEditorErrors(getError, currency);
  const dispatch = useDispatch();
  const navigate = useNavigateCatch();
  const { call } = useQueryWithPopupErrorHandling();
  const { createConfirmation } = useConfirmation();
  const colors = useColors();
  const languages = useLanguages();

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.CURRENCY);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getCurrency = async (id: string) => {
      call(
        () =>
          isForDefaultCurrency && registrationKey
            ? adminRegistrationModel.getDefaultCurrency(registrationKey)
            : currencyModel.getCurrencyById(id),
        "Zobrazení měny nebylo úspěšné",
        null,
        (currency) => {
          const c = mapCurrencyToEditorCurrency(currency);

          setOriginalEditingCurrency(currency);
          setCurrency(c);
        },
        () => {
          navigate("/currencies");
        }
      );
    };

    const id = _id || outerId;

    if (id && type === EditorType.Edit) {
      getCurrency(id);
    }
  }, [_id, type, outerId]);

  const appendCurrencyToForm = useCallback(
    (form: FormData) => {
      form.append("title", currency.title);
      form.append("symbol", currency.symbol);
      form.append("currency_shortcut", currency.currency_shortcut);

      if (registrationKey) {
        form.append("registration_key", registrationKey);
      }
    },
    [currency, registrationKey]
  );

  const addCurrency = () => {
    const data = new FormData();

    appendCurrencyToForm(data);

    call(
      () =>
        isForDefaultCurrency ? ADD_DEFAULT_CURRENCY(data) : ADD_CURRENCY(data),
      "Přidání měny nebylo úspěšné",
      "Přidání měny bylo úspěšné",
      (action) => {
        onSuccess?.(action.payload);
        dispatch(action);
        navigate("/currencies");
      },
      (err) => {
        if (err?.payload) {
          dispatch(err);
        }
      }
    );
  };

  const editCurrency = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit měnu", async () => {
      if (!originalEditingCurrency?._id) {
        return;
      }

      const data = new FormData();

      data.append("_id", originalEditingCurrency._id);
      appendCurrencyToForm(data);

      call(
        () =>
          isForDefaultCurrency
            ? EDIT_DEFAULT_CURRENCY(data)
            : EDIT_CURRENCY(data),
        "Úprava měny nebyla úspěšná",
        "Úprava měny byla úspěšná",
        (action) => {
          dispatch(action);
          onSuccess?.(action.payload)

          if (exit) {
            navigate("/currencies");
          } else {
            setCurrency(mapCurrencyToEditorCurrency(action.payload));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const isCurrencyUsedInLanguage = !!(
    originalEditingCurrency &&
    languages.some((l) => l.currency._id === originalEditingCurrency._id)
  );

  const removeCurrency = () => {
    createConfirmation("Potvrzení: Odstranit měnu", async () => {
      if (!_id || isCurrencyUsedInLanguage) {
        return;
      }

      call(
        () => REMOVE_CURRENCY_BY_ID(_id),
        "Odstranění měny nebylo úspěšné",
        "Odstranění měny bylo úspěšné",
        (remove) => {
          dispatch(remove);
          navigate("/currencies");
        }
      );
    });
  };

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!isValid}
          onClick={addCurrency}
        >
          Přidat měnu
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editCurrency()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editCurrency(true)}>
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            data-title={
              isCurrencyUsedInLanguage
                ? "Měna je použita v jazyce a proto ji nelze odstranit"
                : undefined
            }
            disabled={isCurrencyUsedInLanguage}
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removeCurrency}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper
      node={isForDefaultCurrency ? <></> : node}
      deps={[isValid, appendCurrencyToForm, isCurrencyUsedInLanguage]}
    >
      <div>
        {!isForDefaultCurrency && <BackButton path="/currencies" />}
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          {type === EditorType.Add
            ? isForDefaultCurrency
              ? "Přidání výchozí měny"
              : "Přidání měny"
            : isForDefaultCurrency
            ? "Úprava výchozí měny"
            : "Úprava měny"}
        </div>
        <Flex>
          <div>
            <Flex>
              <InputsWrapper>
                <InputWithTitle
                  inputProps={{
                    error: errors.title_empty,
                  }}
                  wrapperStyle={{
                    marginTop: 0,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={currency.title}
                  setValue={(title) => {
                    {
                      setCurrency((c) => ({
                        ...c,
                        title,
                      }));
                    }
                  }}
                  title="Název měny"
                />
                <InputWithTitle
                  inputProps={{
                    error: errors.symbol_empty,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={currency.symbol}
                  setValue={(symbol) => {
                    {
                      setCurrency((c) => ({
                        ...c,
                        symbol,
                      }));
                    }
                  }}
                  title="Symbol"
                />
                <InputWithTitle
                  inputProps={{
                    error:
                      errors.currency_shortcut_empty ||
                      errors.currency_shortcut_exists,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={currency.currency_shortcut}
                  setValue={(currency_shortcut) => {
                    {
                      setCurrency((c) => ({
                        ...c,
                        currency_shortcut,
                      }));
                    }
                  }}
                  title="Měnová zkratka"
                />
              </InputsWrapper>
            </Flex>
          </div>
        </Flex>
      </div>
      {isForDefaultCurrency ? (
        type === EditorType.Add ? (
          <FlexCenterAll style={{ marginTop: 16 }}>
            <Button
              disabled={!isValid}
              style={{
                ...AddButtonStyles,
                backgroundColor: colors.MAIN_300,
              }}
              hoverBackgroundColor={colors.MAIN_250}
              onClick={addCurrency}
            >
              Přidat měnu
            </Button>
          </FlexCenterAll>
        ) : (
          <FlexCenterAll style={{ marginTop: 16 }}>
            <AddButton disabled={!isValid} onClick={() => editCurrency()}>
              Uložit
            </AddButton>
          </FlexCenterAll>
        )
      ) : null}
    </BottomFixedWrapperWrapper>
  );
};

export default CurrencyEditor;
