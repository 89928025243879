import { ReactNode, useState, useEffect } from "react";
import {
  ParameterValueType,
  TParameterValueParams,
} from "../../../../types/parameter";
import { SketchPicker } from "react-color";
import { Flex } from "../../../../ui/common";
import { useTheme } from "styled-components";
import { InputWithTitle } from "../../../../ui/input-with-title";

type TSetParams = (params: TParameterValueParams) => void;

interface IColorTypeComponentProps {
  params?: TParameterValueParams;
  setParams: TSetParams;
}

const ColorTypeComponent = ({
  params,
  setParams,
}: IColorTypeComponentProps) => {
  const [color, setColor] = useState(params?.color || "#fff");

  let timeoutId: NodeJS.Timeout;
  const { borderRadius } = useTheme();

  useEffect(() => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      setParams({ color });
    }, 200);
  }, [color]);

  return (
    <div>
      <div
        style={{
          marginBottom: 8,
          marginLeft: 4,
          fontSize: 13,
        }}
      >
        Vyberte barvu
      </div>

      <Flex>
        <SketchPicker color={color} onChange={(color) => setColor(color.hex)} />
        <div
          style={{
            width: 64,
            height: 64,
            marginLeft: 24,
            backgroundColor: color,
            borderRadius: borderRadius,
          }}
        />
      </Flex>
    </div>
  );
};

export const getComponentByParameterValueType = (
  type: ParameterValueType,
  setParams: TSetParams,
  params?: TParameterValueParams
): ReactNode => {
  switch (type) {
    case ParameterValueType.Color:
      return <ColorTypeComponent params={params} setParams={setParams} />;
    default:
      break;
  }
};
