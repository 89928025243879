import React, { useState, useEffect } from "react";
import { useSelector } from "./../redux/store";
import Button from "./button";
import { FlexCenterAlign } from "./common";
import styled from "styled-components";
import { useColors } from "../providers/theme/theme-provider";
import { ILanguageID } from "../types/language";
import { useAppConfig } from "../common/redux-hooks";

const LanguageButtonsWrapper = styled.div`
  & > div > div {
    margin-right: 6px;
  }
`;

interface IProps {
  onSelectLanguage(language: ILanguageID): void;
  disabled?: boolean;
}

export const LanguageSelector = ({
  onSelectLanguage,
  disabled,
}: IProps): React.ReactElement<IProps> => {
  const { language } = useAppConfig();
  const { languages } = useSelector(({ languages }) => languages);

  const [selected, setSelected] = useState(language);

  useEffect(() => {
    onSelectLanguage(language);
  }, [language]);

  const onLanguageClick = (language: ILanguageID) => {
    setSelected(language);
    onSelectLanguage(language);
  };

  const colors = useColors();

  return (
    <LanguageButtonsWrapper>
      <FlexCenterAlign>
        {languages.map((language, i) => (
          <div>
            <Button
              disabled={disabled}
              style={{
                backgroundColor:
                  selected._id === language._id ? colors.MAIN_300 : undefined,
              }}
              key={i}
              onClick={() => onLanguageClick(language)}
            >
              {language.title}
            </Button>
          </div>
        ))}
      </FlexCenterAlign>
    </LanguageButtonsWrapper>
  );
};

export default LanguageSelector;
