import React, { useEffect, useState } from "react";
import { getBase64, isValidBase64Image } from "../../common/base64fileEncoders";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { useColors } from "../../providers/theme/theme-provider";
import { useDispatch } from "../../redux/store";
import { IEditImageDB, IImageDB } from "../../types/gallery";
import { InputWithTitle } from "../../ui/input-with-title";
import { useConfirmation } from "./../../providers/confirmation/confirmation-provider";
import { useModal } from "./../../providers/modal/modal-provider";
import { EDIT_IMAGE } from "./../../redux/actions/galleryActions";
import { Button } from "./../../ui/button";
import { colors } from "./../../ui/colors";
import { FlexCenterAll, GalleryImage } from "./../../ui/common";
import FileInput from "./../components/file-input";

const appendImageToForm = (form: FormData, image: IEditImageDB) => {
  form.append("_id", image._id);
  form.append("name", image.name);

  if (image.image) {
    form.append("image", image.image);
  }

  if (!isValidBase64Image(image.url)) {
    form.append("url", image.url);
  }
};

interface IProps {
  image: IImageDB;
  onSuccess?(image: IImageDB): void;
}

export const EditImage = ({
  image,
  onSuccess,
}: IProps): React.ReactElement<IProps> => {
  const [_image, setImage] = useState<IEditImageDB>(image);
  const { closeModal } = useModal();
  const { createConfirmation } = useConfirmation();
  const dispatch = useDispatch();
  const colors = useColors();
  const { call } = useQueryWithPopupErrorHandling();

  const confirm = async () => {
    const data = new FormData();

    appendImageToForm(data, _image);

    call(
      () => EDIT_IMAGE(data),
      "Úprava fotky nebyla úspěšná",
      "Úprava fotky byla úspěšná",
      (action) => {
        dispatch(action);
        onSuccess?.(action.payload);
        closeModal();
      },
      () => {
        closeModal();
      }
    );
  };

  const changeImage = async (image: File) => {
    const url = await getBase64(image);

    setImage((i) => ({ ...i, image, url }));
  };

  const editImageConfirm = () => {
    createConfirmation(
      "Opravdu chcete upravit fotku z galerie společně se všemi použitími?",
      confirm
    );
  };

  useEffect(() => {
    setImage(image);
  }, [image]);

  return (
    <div
      style={{
        padding: 12,
        width: 300,
      }}
    >
      <InputWithTitle
        inputStyle={{
          width: "100%",
        }}
        wrapperStyle={{
          width: "100%",
        }}
        title="Název"
        value={_image.name}
        setValue={(name) => setImage((i) => ({ ...i, name }))}
      />
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
        }}
      >
        <FileInput setFile={changeImage} />
        <GalleryImage src={_image.url} />
      </div>
      <div>
        <FlexCenterAll
          style={{
            marginTop: 20,
          }}
        >
          <div
            style={{
              marginRight: 8,
            }}
          >
            <Button
              style={{
                backgroundColor: colors.MAIN_300,
                color: "#fff",
                fontSize: 14,
              }}
              hoverBackgroundColor={colors.MAIN_250}
              onClick={editImageConfirm}
            >
              OK
            </Button>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: colors.MAIN_400,
                letterSpacing: 0.75,
                fontSize: 14,
                border: "none",
              }}
              hoverBackgroundColor={colors.MAIN_350}
              onClick={() => closeModal()}
            >
              Storno
            </Button>
          </div>
        </FlexCenterAll>
      </div>
    </div>
  );
};

export default EditImage;
