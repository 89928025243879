import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { useStores } from "../../common/redux-hooks";
import { useConfirmation } from "../../providers/confirmation/confirmation-provider";
import { SimplePopupType } from "../../providers/simple-popup/data";
import { useSimplePopups } from "../../providers/simple-popup/simple-popup-provider";
import { EDIT_TERMS_AND_CONDITIONS } from "../../redux/actions/storesActions";
import { useDispatch } from "../../redux/store";
import { IStoreID } from "../../types/store";
import { BottomFixedWrapperWrapper } from "../../ui/bottom-fixed-wrapper-wrapper";
import {
  AddButton,
  FlexCenterAlign,
  FlexCenterAll,
  HeaderText,
} from "../../ui/common";
import RichTextEditor from "../../ui/editorTinyMce";
import BackButton from "../components/back-button";

const EditorWrapper = styled.div`
  margin-top: 20px;
  width: 75%;
`;

interface IProps {}

export const TermsAndConditionsEditor =
  ({}: IProps): React.ReactElement<IProps> => {
    const stores = useStores();
    const { store: storeParam } = useParams();
    const navigate = useNavigate();
    const [store, setStore] = useState<IStoreID | null>(null);
    const [terms, setTerms] = useState<string>("");
    
    const dispatch = useDispatch();
    const { createConfirmation } = useConfirmation();
    const { call } = useQueryWithPopupErrorHandling();
    const { createSimplePopup } = useSimplePopups();

    useEffect(() => {
      const knownStore = stores.find((store) => store.key === storeParam);

      if (!stores || !storeParam || !knownStore) {
        navigate("/terms-and-conditions");
      } else {
        setStore(knownStore);
        setTerms(knownStore.termsAndConditions || "");
      }
    }, [storeParam, stores]);

    const editTermsAndConditions = useCallback(
      (closeOnSuccess?: boolean) => {
        createConfirmation("Opravdu chcete obchodní podmínky upravit?", () => {
          if (!store) {
            createSimplePopup("Eshop není definován", SimplePopupType.Error);
            return;
          }

          call(
            () => EDIT_TERMS_AND_CONDITIONS(terms, store._id),
            "Úprava obchodních podmínek nebyla úspěšná",
            "Obchodní podmínky byly úspěšně upraveny",
            (action) => {
              dispatch(action);

              if (closeOnSuccess) {
                navigate("/terms-and-conditions");
              }
            }
          );
        });
      },
      [terms, store]
    );

    const node = (
      <FlexCenterAll
        style={{
          marginLeft: 16,
        }}
      >
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton onClick={() => editTermsAndConditions()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton onClick={() => editTermsAndConditions(true)}>
              Uložit a zavřít
            </AddButton>
          </div>
        </FlexCenterAlign>
      </FlexCenterAll>
    );

    if (!store) {
      return <></>;
    }

    return (
      <BottomFixedWrapperWrapper node={node} deps={[editTermsAndConditions]}>
        <BackButton path="/terms-and-conditions" />
        <HeaderText
          style={{
            marginTop: 20,
          }}
        >
          Obchodní podmínky pro eshop {store.title}
        </HeaderText>
        <EditorWrapper>
          <RichTextEditor
            value={terms}
            onEditorChange={(terms) => setTerms(terms)}
          />
        </EditorWrapper>
      </BottomFixedWrapperWrapper>
    );
  };

export default TermsAndConditionsEditor;
