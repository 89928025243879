import { NonTranslatedString } from "../constants/language";
import { defaultLanguageKey } from "../constants/language";
import { IParameterValueID } from "../types/parameter";

export const getParameterValueValue = (
  parameterValue: IParameterValueID,
  locale: string
) => {
  return parameterValue.valueIsSameForAllLanguages
    ? parameterValue.value[defaultLanguageKey]
    : parameterValue.value[locale] || <NonTranslatedString />;
};

export const getParameterValueLocalized = (
  parameterValue: IParameterValueID | number | undefined,
  locale: string,
  unit?: string
): React.ReactNode => {
  if (parameterValue === undefined) {
    return;
  }

  if (typeof parameterValue === "number") {
    return `${parameterValue} ${unit}`;
  }

  return parameterValue.valueIsSameForAllLanguages
    ? parameterValue.value[defaultLanguageKey]
    : parameterValue.value[locale] || <NonTranslatedString />;
};
