import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { getFullName } from "../../../common/functions";
import { IHistoryAction } from "../../../types/history";
import { FlexCenterAlign, HeaderText, InputsWrapper } from "../../../ui/common";
import { getLocaleDateStringWithHoursAndMinutes } from "./../../../common/date";
import HistoryActionChanges from "./history-action-changes";

interface IBeforeAndAfterProps {
  before: any;
  after: any;
}

export const BeforeAndAfter = ({ before, after }: IBeforeAndAfterProps) => {
  return (
    <FlexCenterAlign
      style={{
        padding: 10,
      }}
    >
      <InputsWrapper>
        <HistoryActionChanges changes={before} />
      </InputsWrapper>
      <div
        style={{
          margin: "0px 32px",
        }}
      >
        <BsArrowRight size={22} />
      </div>
      <InputsWrapper>
        <HistoryActionChanges changes={after} />
      </InputsWrapper>
    </FlexCenterAlign>
  );
};

interface IProps {
  action: IHistoryAction;
}

export const HistoryViewChanges = ({
  action,
}: IProps): React.ReactElement<IProps> => {
  return (
    <div
      style={{
        marginRight: 40,
        maxWidth: 900,
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <HeaderText
        style={{
          marginLeft: 20,
          marginTop: 20,
          fontSize: 14,
        }}
      >
        Změna {getLocaleDateStringWithHoursAndMinutes(new Date(action.date))} -{" "}
        {action.created_by
          ? getFullName(action.created_by)
          : "Neznámý uživatel"}
      </HeaderText>
      <BeforeAndAfter
        after={action.changes.after}
        before={action.changes.before}
      />
    </div>
  );
};

export default HistoryViewChanges;
