import React, { useState } from "react";
import { BiDownload } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import styled, { css } from "styled-components";
import {
  calculateOrderItemsSum,
  getOrderCouponDeduction,
  getOrderFullPriceVat,
} from "../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { getPrice, getPriceOrFree } from "../../common/price";
import {
  getOrderStateTitle,
  getPaymentTypeTitle,
  getShippingTypeTitle,
} from "../../locales/determineString";
import { orderModel } from "../../model/query/orderModel";
import { useColors } from "../../providers/theme/theme-provider";
import { GoPayPaymentState, IOrder } from "../../types/order";
import { PaymentType } from "../../types/payment";
import Button from "../../ui/button";
import {
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  HeaderText,
  InputsWrapper,
} from "../../ui/common";
import IconButton from "../../ui/icon-button";
import LazyLoadingGalleryImage from "../../ui/lazy-loading-image";
import Table, { SortByType, TSortBy } from "../../ui/table";
import GoPayPaymentStateComponent from "./gopay-payment-state";
import ShippingInfo from "./shipping-info";
import { useModal } from "../../providers/modal/modal-provider";
import OrderStateChangeModal from "./order-state-change/order-state-change-modal";
import { AiFillEdit, AiFillShopping } from "react-icons/ai";
import { useOrdersList } from "./orders-list-context";

const Section = styled(InputsWrapper)`
  ${({ theme }) => css`
    background-color: ${theme.colors.MAIN_400};
    font-size: 14px;
    margin-right: 12px;
    font-weight: 500;
  `}
`;

export const SectionTitle = styled(HeaderText)`
  font-size: 15px;
  margin-bottom: 12px;
  font-weight: 400;
`;

export const SectionPropertyTitle = styled(HeaderText)`
  font-size: 13px;
  margin-right: 8px;
  font-weight: 400;
`;

export const SectionProperty = styled(FlexCenterAlign)`
  margin-bottom: 6px;
`;

interface IProps {
  order: IOrder;
  fetchOrder(): void;
}

export const ViewOrder = ({
  order: initialOrder,
  fetchOrder,
}: IProps): React.ReactElement<IProps> => {
  const [order, setOrder] = useState(initialOrder);
  const colors = useColors();
  const currency = order.language.currency;
  const { call } = useQueryWithPopupErrorHandling();

  const [sortBy, setSortBy] = useState<TSortBy>({
    key: "",
    type: SortByType.None,
  });

  const downloadInvoice = () => {
    const url = order.invoice_url;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `faktura-${order.custom_id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const [goPayState, setGoPayState] = useState<GoPayPaymentState | null>(null);

  const checkGoPayPaymentState = async () => {
    call(
      () => orderModel.checkGoPayState(order._id),
      null,
      null,
      (state) => setGoPayState(state)
    );
  };

  const { createModal } = useModal();

  const onEditStateClick = () => {
    createModal(
      <OrderStateChangeModal
        fetch={(state) => {
          fetchOrder();
          setOrder((o) => ({ ...o, state }));
        }}
        order={order}
      />
    );
  };

  return (
    <div
      style={{
        marginRight: 40,
        maxWidth: "75vw",
        height: "90vh",
        overflow: "auto",
      }}
    >
      <HeaderText
        style={{
          marginBottom: 12,
        }}
      >
        Objednávka č. {order.custom_id}
      </HeaderText>
      <Flex>
        <Section>
          <SectionTitle>Informace</SectionTitle>
          <SectionProperty>
            <SectionPropertyTitle>Číslo objednávky:</SectionPropertyTitle>
            <div>{order.custom_id}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Stav:</SectionPropertyTitle>
            <div>{getOrderStateTitle(order.state)}</div>
            <IconButton
              onClick={onEditStateClick}
              data-title="Změnit stav objednávky"
              style={{
                padding: 4,
                marginLeft: 8,
                marginRight: 4,
                backgroundColor: colors.MAIN_300,
              }}
            >
              <AiFillEdit size={20} color={colors.OPPOSITE_MAIN_300} />
            </IconButton>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Vznik:</SectionPropertyTitle>
            <div>{new Date(order.date).toLocaleString()}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>
              "Heureka" email:
            </SectionPropertyTitle>
            <div>{order.heurekaEmailSent ? "Ano" : "Ne"}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>
              "Vyřízeno" email odeslán:
            </SectionPropertyTitle>
            <div>{order.completedEmailSent ? "Ano" : "Ne"}</div>
          </SectionProperty>
          <SectionProperty>
            <Button
              onClick={downloadInvoice}
              style={{
                paddingLeft: 8,
                fontWeight: 500,
                marginTop: 6,
                backgroundColor: colors.MAIN_350,
              }}
              hoverBackgroundColor={colors.MAIN_300}
            >
              <FlexCenterAlign>
                <BiDownload
                  size={22}
                  style={{
                    marginRight: 8,
                  }}
                />
                Stáhnout fakturu
              </FlexCenterAlign>
            </Button>
          </SectionProperty>
        </Section>

        <Section>
          <SectionTitle>Fakturační údaje</SectionTitle>
          <SectionProperty>
            <SectionPropertyTitle>E-mail:</SectionPropertyTitle>
            <div>{order.checkout.email}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Jméno:</SectionPropertyTitle>
            <div>{order.checkout.firstName}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Příjmení:</SectionPropertyTitle>
            <div>{order.checkout.lastName}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Telefonní číslo:</SectionPropertyTitle>
            <div>
              +{order.checkout.phoneNumber.prefix}{" "}
              {order.checkout.phoneNumber.value}
            </div>
          </SectionProperty>
        </Section>

        <Section>
          <SectionTitle>Dodací údaje</SectionTitle>
          <SectionProperty>
            <SectionPropertyTitle>Město:</SectionPropertyTitle>
            <div>{order.checkout.city}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Adresa:</SectionPropertyTitle>
            <div>{order.checkout.address}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>PSČ:</SectionPropertyTitle>
            <div>{order.checkout.zip}</div>
          </SectionProperty>
        </Section>

        <Section>
          <SectionTitle>Doprava a platba</SectionTitle>
          <SectionProperty>
            <SectionPropertyTitle>Platební metoda:</SectionPropertyTitle>
            <div>{getPaymentTypeTitle(order.payment.type)}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Cena platební metody:</SectionPropertyTitle>
            <div>{getPriceOrFree(order.payment_price, currency.symbol)}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Doprava:</SectionPropertyTitle>
            <div>{getShippingTypeTitle(order.shipping.type)}</div>
          </SectionProperty>
          <SectionProperty>
            <SectionPropertyTitle>Cena dopravy:</SectionPropertyTitle>
            <div>{getPriceOrFree(order.shipping_price, currency.symbol)}</div>
          </SectionProperty>
          {order.payment.type !== PaymentType.Cash && (
            <SectionProperty>
              <div>
                <Button
                  onClick={checkGoPayPaymentState}
                  style={{
                    fontWeight: 500,
                    marginTop: 6,
                    backgroundColor: colors.MAIN_350,
                  }}
                  hoverBackgroundColor={colors.MAIN_300}
                >
                  Zjistit aktuální stav platby GoPay
                </Button>
                <GoPayPaymentStateComponent goPayState={goPayState} />
              </div>
            </SectionProperty>
          )}
        </Section>

        <Section>
          <SectionTitle>Informace o dopravě</SectionTitle>
          <ShippingInfo order={order} />
        </Section>
      </Flex>
      <Section
        style={{
          marginTop: 16,
        }}
      >
        <SectionTitle>Položky ({order.items.length})</SectionTitle>
        <div
          style={{
            maxHeight: 340,
            overflow: "auto",
          }}
        >
          <Table
            onSortByChange={setSortBy}
            headerKeys={[
              { content: "", width: "55px" },
              { content: "", width: "140px" },
              {
                content: "Kód položky",
                width: "136px",
                sort_by_key: "eshop_id",
              },
              { content: "Název položky", width: "30%", sort_by_key: "name" },
              {
                content: "Cena za jeden kus",
                width: "20%",
                sort_by_key: "price",
              },
              {
                content: "Počet položek",
                width: "20%",
                sort_by_key: "quantity",
              },
              { content: "Cena celkem", width: "25%", sort_by_key: "total" },
            ]}
            rows={order.items
              .map((item) => ({
                cells: [
                  {
                    key: "actions",
                    value: "actions",
                    content: (
                      <a
                        href={`/products/edit-product/${item.product!._id}`}
                        target="_blank"
                      >
                        <IconButton
                          data-title="Zobrazit položku"
                          hoverBackgroundColor={colors.MAIN_250}
                          style={{
                            padding: 4,
                            marginRight: 6,
                            backgroundColor: colors.MAIN_300,
                          }}
                          onClick={
                            item.product
                              ? (e) =>
                                  window.open(
                                    `/products/edit-product/${
                                      item.product!._id
                                    }`
                                  )
                              : undefined
                          }
                        >
                          <FiExternalLink
                            size={16}
                            color={colors.OPPOSITE_MAIN_450}
                          />
                        </IconButton>
                      </a>
                    ),
                  },
                  {
                    key: "image",
                    value: item.product?.image || "",
                    content: item.product && (
                      <FlexCenterAll>
                        <LazyLoadingGalleryImage
                          style={{
                            maxHeight: 96,
                            minHeight: 96,
                            width: "auto",
                            minWidth: "auto",
                            objectFit: "contain",
                          }}
                          src={item.product.image?.url || ""}
                        />
                      </FlexCenterAll>
                    ),
                  },
                  {
                    key: "eshop_id",
                    value: item.product?.eshop_id || "",
                    content: item.product?.eshop_id || "",
                  },
                  {
                    key: "name",
                    value: item.name,
                    content: item.name,
                  },
                  {
                    key: "price",
                    value: item.price_vat,
                    content: getPriceOrFree(item.price_vat, currency.symbol),
                  },
                  {
                    key: "quantity",
                    value: item.quantity,
                    content: item.quantity,
                  },
                  {
                    key: "total",
                    value: item.price_vat * item.quantity,
                    content: getPriceOrFree(
                      item.price_vat * item.quantity,
                      currency.symbol
                    ),
                  },
                ],
              }))
              .sort((a, b) => {
                const sortKeyA = a.cells.find(
                  (cell) => cell.key === sortBy.key
                );
                const sortKeyB = b.cells.find(
                  (cell) => cell.key === sortBy.key
                );

                if (!sortKeyA || !sortKeyB) {
                  return 0;
                }

                const result = () => {
                  if (sortKeyA.value > sortKeyB.value) {
                    return 1;
                  } else if (sortKeyA.value < sortKeyB?.value) {
                    return -1;
                  }
                  return 0;
                };
                return sortBy.type === SortByType.Descending
                  ? result() * -1
                  : result();
              })}
          />
        </div>
      </Section>
      <Section
        style={{
          marginTop: 16,
        }}
      >
        {order.coupon ? (
          <>
            <SectionProperty>
              <SectionPropertyTitle style={{ fontSize: 13 }}>
                Kód kupónu:
              </SectionPropertyTitle>
              <div>{order.coupon.key}</div>
            </SectionProperty>
            <SectionProperty
              style={{
                marginBottom: 16,
              }}
            >
              <SectionPropertyTitle style={{ fontSize: 13 }}>
                Sleva po aplikaci kupónu na zboží:
              </SectionPropertyTitle>
              <div>
                -{getPrice(getOrderCouponDeduction(order), currency.symbol)}
              </div>
            </SectionProperty>
          </>
        ) : null}
        <SectionProperty>
          <SectionPropertyTitle style={{ fontSize: 13 }}>
            Cena celkem bez DPH (bez dopravy a platby):
          </SectionPropertyTitle>
          <div>
            {getPrice(
              calculateOrderItemsSum(order.items) * 0.79,
              currency.symbol
            )}
          </div>
        </SectionProperty>
        <SectionProperty
          style={{
            marginBottom: 16,
          }}
        >
          <SectionPropertyTitle style={{ fontSize: 13 }}>
            Cena celkem včetně DPH (bez dopravy a platby):
          </SectionPropertyTitle>
          <div>
            {getPrice(calculateOrderItemsSum(order.items), currency.symbol)}
          </div>
        </SectionProperty>
        <SectionProperty
          style={{
            fontSize: 17,
          }}
        >
          <SectionPropertyTitle style={{ fontSize: 16 }}>
            Cena celkem bez DPH:
          </SectionPropertyTitle>
          <div>
            {getPrice(getOrderFullPriceVat(order) * 0.79, currency.symbol)}
          </div>
        </SectionProperty>
        <SectionProperty
          style={{
            fontSize: 20,
          }}
        >
          <SectionPropertyTitle style={{ fontSize: 18 }}>
            Cena celkem vč. DPH:
          </SectionPropertyTitle>
          <div>{getPrice(getOrderFullPriceVat(order), currency.symbol)}</div>
        </SectionProperty>
      </Section>
    </div>
  );
};

export default ViewOrder;
