import { GoPayPaymentState, OrderState } from "../types/order";
import { ParameterType, ParameterValueType } from "../types/parameter";
import { PaymentType } from "../types/payment";
import { IRole, UserRole } from "../types/role";
import { ShippingType } from "../types/shipping";

export const getTitleByRole = (role: UserRole) => {
  switch (role) {
    case UserRole.Admin:
      return "Administrátor";
    case UserRole.Manager:
      return "Manažer";
    case UserRole.Translator:
      return "Překladatel";
    case UserRole.TranslatorAdmin:
      return "Překladatel admin";
    case UserRole.Writer:
      return "Produktový specialista";
    case UserRole.Custom:
      return "Vlastní";
  }
};

export const getParameterTypeTitle = (type: ParameterType): string => {
  switch (type) {
    case ParameterType.Memory:
      return "Paměť";
    case ParameterType.ClothingSizes:
      return "Velikosti oblečení";
    case ParameterType.Colors:
      return "Barvy";
    case ParameterType.Manufacturers:
      return "Výrobci";
    case ParameterType.None:
    default:
      return "Neurčeno";
  }
};

export const getParameterValueTypeTitle = (
  type: ParameterValueType
): string => {
  switch (type) {
    case ParameterValueType.ClothingSize:
      return "Velikost oblečení";
    case ParameterValueType.Color:
      return "Barva";
    case ParameterValueType.Text:
    default:
      return "Text";
  }
};

export const getShippingTypeTitle = (type: ShippingType) => {
  switch (type) {
    case ShippingType.PPL:
      return "PPL - Na adresu";
    case ShippingType.Zasilkovna:
      return "Zásilkovna";
    default:
      return "";
  }
};

export const getPaymentTypeTitle = (type: PaymentType) => {
  switch (type) {
    case PaymentType.Card:
      return "Platební kartou online";
    case PaymentType.ApplePay:
      return "Apple Pay";
    case PaymentType.GooglePay:
      return "Google Pay";
    case PaymentType.Cash:
      return "Hotově při převzetí";
    case PaymentType.BankTransfer:
      return "Bankovním převodem";
    default:
      return "";
  }
};

export const getBooleanTitle = (value: boolean): string => {
  return value ? "Ano" : "Ne";
};

export const getOrderStateTitle = (state: OrderState) => {
  switch (state) {
    case OrderState.New:
      return "Nová";
    case OrderState.Completed:
      return "Vyřízeno";
    case OrderState.HandedToCarrier:
      return "Zabalena";
    case OrderState.InProgress:
      return "Zpracovává se";
    case OrderState.WaitingForPayment:
      return "Čeká na platbu";
    case OrderState.Canceled:
      return "Zrušeno";
    case OrderState.ReturnArrived:
      return "Vrácená objednávka přijata";
    case OrderState.ReturnConfirmedByUser:
      return "Objednávka byla vrácena zákazníkem";
    default:
      return state;
  }
};

export const getOrderGoPayPaymentState = (state: GoPayPaymentState) => {
  switch (state) {
    case GoPayPaymentState.CREATED:
      return "Platba vytvořena";

    case GoPayPaymentState.PAID:
      return "Platba uhrazena";

    case GoPayPaymentState.CANCELED:
      return "Platba zamítnuta";

    case GoPayPaymentState.PAYMENT_METHOD_CHOSEN:
      return "Platební metoda potvrzena";

    case GoPayPaymentState.TIMEOUTED:
      return "Platbě vypršela životnost";

    case GoPayPaymentState.AUTHORIZED:
      return "Platba předautorizována";

    case GoPayPaymentState.REFUNDED:
      return "Platba vrácena";

    case GoPayPaymentState.PARTIALLY_REFUNDED:
      return "Platba částečně vrácena";
  }
};
