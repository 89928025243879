import { ReactNode, useRef, useState, useEffect } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { useClickOutside } from "../common/use-click-outside";
import Input from "./input";
import { DeleteButton, FlexCenterAlign } from "./common";
import { BiX } from "react-icons/bi";
import IconButton from "./icon-button";
import { TReduxError } from "../types/error";
import { useColors } from "../providers/theme/theme-provider";
import { Animations } from "../types/theme";

export type TDropdownItem = {
  content: string | ReactNode;
  value: any;
  query?: string;
  disabled?: boolean;
  unique_id?: string;
};

interface WrapperProps {
  length?: number;
  active: boolean;
}

const TITLE_HEIGHT = 32;

const DropdownWrapper = styled.div<WrapperProps>`
  position: relative;
  flex-wrap: wrap;
  height: ${TITLE_HEIGHT}px;

  ${({ active }) => css`
    z-index: ${active ? 8 : 5};
  `}
`;

const DropdownItemWrapper = styled.div<WrapperProps>`
  ${(props) => css`
    border-radius: ${props.theme.borderRadius / 2}px;
  `}

  ${(props) =>
    props.theme.animations === Animations.None &&
    css`
      transition: none !important;
    `}

  transition: opacity 150ms ease, height 150ms ease;
  box-shadow: 4px 4px 8px #0000001a;
  opacity: 0;
  max-height: 240px;
  overflow: auto;
  height: 0;
  visibility: hidden;
  top: 10px;
  min-width: 240px;

  &.no-overflow {
    overflow: hidden !important;

    @media (max-width: 800px) {
      overflow: auto !important;
    }
  }

  ${(props) =>
    props.active &&
    css`
      height: auto;
      opacity: 1;
      visibility: visible;
      transition: opacity 150ms ease, height 150ms ease;
      z-index: 10;
    `}
`;

const DropdownTitle = styled.div<WrapperProps>`
  font-size: 13px;
  font-weight: 400;
  height: ${TITLE_HEIGHT}px;
  position: relative;
  width: 100%;
`;

interface DropdownItemProps {
  noHover: boolean;
}

const DropdownItem = styled.button<DropdownItemProps>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_400};

    &:nth-child(2n) {
      background-color: ${props.theme.colors.MAIN_350};
    }

    &:disabled {
      pointer-events: none;
      & > span {
        opacity: 0.5;
      }
    }

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      background-color: ${props.theme.colors.MAIN_300};
    }
  `}

  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  padding-left: 13px;
  color: inherit;
  cursor: pointer;
  text-align: left;
  text-overflow: ellipsis;
  transition: none;
  padding-top: 8px;
  padding-bottom: 8px;

  span {
    max-width: calc(100% - 12px);
    text-overflow: ellipsis;
  }
`;

const getValueToQuery = (i: TDropdownItem): string | undefined => {
  if (i.query) {
    return i.query.toLowerCase();
  } else if (typeof i.content === "string") {
    return i.content.toLowerCase();
  } else if (typeof i.value === "string") {
    return i.value.toLowerCase();
  }
};

const getFilteredListByQuery = (list: TDropdownItem[], search: string) => {
  return list
    .filter((i) => {
      if (!search) {
        return true;
      }

      const stringValue = getValueToQuery(i);

      return stringValue ? stringValue.includes(search.toLowerCase()) : false;
    })
    .sort((a, b) => {
      const aValue = getValueToQuery(a);
      const bValue = getValueToQuery(b);

      if (!aValue || !bValue) {
        return 0;
      }

      if (aValue.startsWith(search.toLowerCase())) {
        return -1;
      }

      if (bValue.startsWith(search.toLowerCase())) {
        return 1;
      }

      return aValue.localeCompare(bValue);
    });
};

interface IProps {
  list: TDropdownItem[];
  onSelectValue(value: any, unique_id?: string): void;
  title?: string;
  noHover?: boolean;
  style?: CSSProperties;
  selectedUniqueId?: string;
  isDeleteButtonDisabled?: boolean;
  disabled?: boolean;
  error?: TReduxError;
  canHaveValue?: boolean;
  onBlurSearch?(search: string): void;
  onSearchChange?(search: string): void;
  activeSearch?: string;
}

export const Dropdown = ({
  onSelectValue,
  list,
  title,
  noHover = false,
  style,
  selectedUniqueId,
  disabled,
  isDeleteButtonDisabled,
  error,
  canHaveValue,
  onBlurSearch,
  onSearchChange,
  activeSearch,
}: IProps) => {
  const [active, setActive] = useState<boolean>(false);
  const [search, setSearch] = useState(activeSearch || "");
  const colors = useColors();

  useEffect(() => {
    if (activeSearch !== undefined) {
      setSearch(activeSearch);
    }
  }, [activeSearch]);

  const dropdownItemWrapper = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(wrapperRef, () => {
    setActive(false);
  });

  const filteredList = getFilteredListByQuery(list, search);

  const uniqueIdContent =
    selectedUniqueId &&
    list.find((v) => v.unique_id === selectedUniqueId)?.content;

  const isDeleteButtonActive =
    uniqueIdContent && !active && !disabled && !isDeleteButtonDisabled;

  return (
    <DropdownWrapper ref={wrapperRef} active={active} style={style}>
      <DropdownTitle
        active={active}
        style={{
          width: "100%",
        }}
      >
        <FlexCenterAlign>
          <Input
            error={error}
            wrapperStyle={{
              width: "100%",
            }}
            disabled={disabled || list.length === 0}
            style={{
              width: "100%",
              paddingRight: isDeleteButtonActive ? 20 : 0,
            }}
            onBlur={() => {
              onBlurSearch?.(search);
            }}
            onFocus={() => {
              setActive(true);
            }}
            placeholder={uniqueIdContent ? "" : title || ""}
            value={active ? search : canHaveValue ? search : ""}
            onChange={(e) => {
              onSearchChange?.(e.target.value);
              setSearch(e.target.value);
            }}
          />
          {!active && uniqueIdContent ? (
            <div
              style={{
                position: "absolute",
                left: "1em",
                pointerEvents: "none",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: isDeleteButtonActive ? "calc(100% - 56px)" : "100%",
              }}
            >
              {uniqueIdContent}
            </div>
          ) : null}
        </FlexCenterAlign>

        {isDeleteButtonActive ? (
          <IconButton
            style={{
              position: "absolute",
              right: 12,
              top: 5,
              padding: 2,
              backgroundColor: colors.MAIN_200,
            }}
            onClick={() => onSelectValue(null)}
          >
            <BiX size={16} color={colors.OPPOSITE_MAIN_400} />
          </IconButton>
        ) : null}
      </DropdownTitle>
      <DropdownItemWrapper
        ref={dropdownItemWrapper}
        active={!disabled && active}
        length={filteredList.length}
      >
        {filteredList.map((item, i) => (
          <DropdownItem
            disabled={item.disabled}
            key={i}
            style={{
              backgroundColor: selectedUniqueId
                ? item.unique_id === selectedUniqueId
                  ? colors.MAIN_250
                  : undefined
                : undefined,
            }}
            onClick={() => {
              if (item.disabled) return;

              setSearch("");
              onSelectValue(item.value, item.unique_id);
              setActive(false);
            }}
            noHover={noHover}
          >
            <span>{item.content}</span>
          </DropdownItem>
        ))}
      </DropdownItemWrapper>
    </DropdownWrapper>
  );
};

export default Dropdown;
