import { IImageDB } from "./gallery";
import { IUserDB } from "./user";

export const getTaskStateIndex = (state: TaskState) => {
  switch (state) {
    case TaskState.New:
      return 0;
    case TaskState.InProgress:
      return 1;
    case TaskState.Done:
      return 2;
    case TaskState.Verified:
      return 3;
    case TaskState.Invalid:
      return 4;
  }
};

export const getTaskPriorityIndex = (priority: TaskPriority) => {
  switch (priority) {
    case TaskPriority.Low:
      return 0;
    case TaskPriority.Medium:
      return 1;
    case TaskPriority.High:
      return 2;
  }
};

export enum TaskState {
  New = "New",
  InProgress = "InProgress",
  Done = "Done",
  Verified = "Verified",
  Invalid = "Invalid"
}

export enum TaskPriority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export interface ITask {
  title: string;
  description: string;
  priority: TaskPriority;
  assigned: IUserDB[];
  due_date: number;
  state: TaskState;

  created_by: IUserDB;
  created_date: number;
  assignees_edit_permission: boolean;
  images: IImageDB[];
}

export interface ITaskDB extends ITask {
  _id: string;
}
