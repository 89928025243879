import { TGetError } from "../common/redux-hooks";
import { TReduxError } from "./error";
import { TSlug } from "./slugs";

export enum EditorType {
  Add,
  Edit,
}

export type TLanguageString = {
  [key: string]: string;
};

export type TLanguageSlug = {
  [key: string]: TSlug;
};

export type TLanguageBoolean = {
  [key: string]: boolean;
};

export type TLanguageNumber = {
  [key: string]: number;
};

export type TCallback = (value?: any) => any;

export type TGetErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => TReduxError | undefined;