import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { GalleryListMode } from "..";
import { useUser } from "../../../common/redux-hooks";
import { galleryModel } from "../../../model/query/galleryModel";
import { useDispatch, useSelector } from "../../../redux/store";
import { IImageDB, ImageType } from "../../../types/gallery";
import Button from "../../../ui/button";
import colors from "../../../ui/colors";
import {
  DeleteButton,
  EditButton,
  Flex,
  FlexCenterAll,
  NoResultsFound,
} from "../../../ui/common";
import Loading from "../../../ui/loading";
import {
  IMAGE_ITEM_HEIGHT,
  IMAGE_ITEM_WIDTH,
  LazyLoadImageItem,
} from "./image-item";
import Table, { ITEMS_PER_PAGE } from "../../../ui/table";
import LazyTable from "../../../ui/lazy-table";
import LazyLoadingGalleryImage from "../../../ui/lazy-loading-image";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useModal } from "../../../providers/modal/modal-provider";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { REMOVE_IMAGE_BY_ID } from "../../../redux/actions/galleryActions";
import EditImage from "../edit-image";

const GalleryListWrapper = styled.div`
  overflow: auto;
  margin-top: 20px;
  height: calc(100% - 150px);
  padding-right: 16px;
  padding-left: 8px;

  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, ${IMAGE_ITEM_WIDTH}px);
  grid-gap: 8px;
  grid-row-gap: 16px;

  &::after {
    content: "";
    flex: auto;
  }
`;

interface IProps {
  query: string;
  mode: GalleryListMode;
  onClickImage?(image: IImageDB): void;
  type?: ImageType;
  isModal?: boolean;
  forceRefreshGalleryList?: number;
}

export const GalleryList = ({
  query,
  type,
  mode,
  onClickImage,
  forceRefreshGalleryList,
}: IProps): React.ReactElement<IProps> => {
  const user = useUser();
  const [queryLoading, setQueryLoading] = useState(false);
  const [filteredImages, setFilteredImages] = useState<IImageDB[]>([]);

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const setByQuery = async () => {
    setQueryLoading(true);
    try {
      const { results, totalCount } = await galleryModel.getImagesByQuery(
        user._id,
        query,
        page * ITEMS_PER_PAGE,
        ITEMS_PER_PAGE,
        type
      );
      setQueryLoading(false);

      setFilteredImages(results);
      setTotalCount(totalCount);
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    setPage(0);
  }, [query]);

  useEffect(() => {
    setByQuery();
  }, [query, type, user._id, forceRefreshGalleryList, page]);

  const [_mode, setMode] = useState(mode);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [indexes, setIndexes] = useState([0, 30]);

  useEffect(() => {
    wrapperRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      wrapperRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [wrapperRef.current, indexes, filteredImages.length]);

  const handleScroll = (e: Event) => {
    e.stopPropagation();

    if (indexes[1] >= filteredImages.length) {
      return;
    }

    if (!wrapperRef.current) {
      return;
    }

    if (
      wrapperRef.current.scrollHeight - (wrapperRef.current.scrollTop + 100) >
      wrapperRef.current.clientHeight
    )
      return;

    setIndexes((indexes) => {
      return [indexes[0], indexes[1] + 30];
    });
  };

  const dispatch = useDispatch();
  const { createConfirmation } = useConfirmation();
  const { createModal } = useModal();
  const { call } = useQueryWithPopupErrorHandling();

  const removeImage = async (image: IImageDB) => {
    createConfirmation(
      "Opravdu chcete odstranit fotku z galerie společně se všemi použitími?",
      async () => {
        call(
          () => REMOVE_IMAGE_BY_ID(image._id),
          "Odstranění fotky nebylo úspěšné",
          "Odstranění fotky bylo úspěšné",
          (action) => {
            setByQuery();
            dispatch(action);
          }
        );
      }
    );
  };

  const editImage = (image: IImageDB) => {
    createModal(<EditImage onSuccess={setByQuery} image={image} />);
  };

  return (
    <>
      {mode === GalleryListMode.ChooseAndUploadOrManage && (
        <div
          style={{
            marginLeft: 8,
          }}
        >
          {(_mode === GalleryListMode.ChooseAndUploadOrManage ||
            _mode === GalleryListMode.ChooseAndUpload) && (
            <Button
              style={{
                backgroundColor: colors.White,
              }}
              hoverBackgroundColor={colors.WhiteHover}
              onClick={() => setMode(GalleryListMode.Manage)}
            >
              Změnit na:{" "}
              <span style={{ fontWeight: 500 }}>Úprava fotografií</span>
            </Button>
          )}
          {_mode === GalleryListMode.Manage && (
            <Button
              style={{
                backgroundColor: colors.White,
              }}
              hoverBackgroundColor={colors.WhiteHover}
              onClick={() => setMode(GalleryListMode.ChooseAndUpload)}
            >
              Změnit na:{" "}
              <span style={{ fontWeight: 500 }}>Výběr fotografií</span>
            </Button>
          )}
        </div>
      )}
      <LazyTable
        onSetPage={setPage}
        selectedPage={page}
        onSelectRow={
          onClickImage ? (row) => onClickImage?.(row.value) : undefined
        }
        customRowHeight={128}
        totalCount={totalCount}
        isLoading={queryLoading}
        rows={filteredImages.map((i) => ({
          value: i,
          cells: [
            {
              content: (_mode === GalleryListMode.Manage ||
                _mode === GalleryListMode.ChooseAndUploadOrManage) && (
                <FlexCenterAll>
                  <EditButton
                    onClick={() => editImage(i)}
                    data-title="Upravit foto"
                    style={{ marginRight: 4 }}
                  />
                  <DeleteButton
                    onClick={() => removeImage(i)}
                    data-title="Odstranit foto"
                  />
                </FlexCenterAll>
              ),
              key: "actions",
              value: "actions",
            },
            {
              content: (
                <Flex style={{ paddingRight: 10 }}>
                  <LazyLoadingGalleryImage
                    style={{
                      maxHeight: 128,
                      minHeight: 128,
                      width: "auto",
                      minWidth: "auto",
                      objectFit: "contain",
                    }}
                    src={i.url}
                  />
                </Flex>
              ),
              key: "image",
              value: i.url,
            },
            {
              content: i.name,
              key: "name",
              value: i.name,
            },
            {
              content: new Date(i.date).toLocaleString(),
              key: "date",
              value: i.date,
            },
          ],
        }))}
        headerKeys={[
          {
            content: "",
            width:
              _mode === GalleryListMode.Manage ||
              _mode === GalleryListMode.ChooseAndUploadOrManage
                ? "130px"
                : "0px",
          },
          {
            content: "",
            width: "150px",
          },
          {
            content: "Název",
          },
          {
            content: "Přidáno",
          },
        ]}
      />
      {/* <GalleryListWrapper
            ref={wrapperRef}
            style={{
              display: !filteredImages.length ? "none" : "grid",
            }}
          >
            {filteredImages.slice(indexes[0], indexes[1]).map((image, i) => (
              <LazyLoadImageItem
                key={i}
                image={image}
                mode={_mode}
                onClickImage={onClickImage}
              />
            ))}
          </GalleryListWrapper> */}
      {/* </>
      )} */}
    </>
  );
};

export default GalleryList;
