import { IEditorManufacturer } from "./manufacturers-editor";
import { isLanguageStringEmpty } from "../../../common/functions";
import { TGetError } from "../../../common/redux-hooks";
import { TLanguageString } from "../../../types/common";
import { ErrorType, TReduxError } from "../../../types/error";
import {
  ISlugErrorsObject,
  getSlugErrors,
} from "../../../common/slug-validator";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.MANUFACTURER);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export interface TManufacturerEditorErrorsObject extends ISlugErrorsObject {
  name?: TReduxError;
  image?: TReduxError;
  description?: TReduxError;
}

type TManufacturerEditorErrors = {
  errors: TManufacturerEditorErrorsObject;
  isValid: boolean;
};

export const getManufacturerEditorErrors = (
  getError: TGetError,
  manufacturer: IEditorManufacturer,
  locale: string
): TManufacturerEditorErrors => {
  const name = getErrorOfKey(
    "name",
    getError,
    isLanguageStringEmpty(manufacturer.name)
  );

  const image = getErrorOfKey("image", getError, !manufacturer.image);

  const description = getErrorOfKey(
    "description",
    getError,
    isLanguageStringEmpty(manufacturer.description)
  );

  const { errors: slugErrors, isValid: slugIsValid } = getSlugErrors(
    manufacturer.slug || {},
    getError,
    locale
  );

  return {
    errors: {
      name,
      image,
      description,
      ...slugErrors,
    },
    isValid: !name && !image && !description && slugIsValid,
  };
};
