import { IParameterValueID } from "../../types/parameter";
import { parameterValueModel } from "./../../model/query/parameterValueModel";
import { ErrorsActions } from "./errorsActions";

export enum ParameterValueActions {
  SET_PARAMETER_VALUES = "SET_PARAMETER_VALUES",
  ADD_PARAMETER_VALUE = "ADD_PARAMETER_VALUE",
  EDIT_PARAMETER_VALUE = "EDIT_PARAMETER_VALUE",
  REMOVE_PARAMETER_VALUE_BY_ID = "REMOVE_PARAMETER_VALUE_BY_ID",
}

type TSetParameterValuesAction = {
  type: ParameterValueActions.SET_PARAMETER_VALUES;
  payload: IParameterValueID[];
};

type TAddParameterValueAction = {
  type: ParameterValueActions.ADD_PARAMETER_VALUE;
  payload: IParameterValueID;
};

type TEditParameterValueAction = {
  type: ParameterValueActions.EDIT_PARAMETER_VALUE;
  payload: IParameterValueID;
};

type TRemoveParameterValueByIdAction = {
  type: ParameterValueActions.REMOVE_PARAMETER_VALUE_BY_ID;
  payload: string;
};

export type TParameterValueAction =
  | TSetParameterValuesAction
  | TAddParameterValueAction
  | TEditParameterValueAction
  | TRemoveParameterValueByIdAction;

export const FETCH_AND_SET_PARAMETER_VALUES = async () => {
  try {
    const parameterValues = await parameterValueModel.getParameterValues();

    return {
      type: ParameterValueActions.SET_PARAMETER_VALUES,
      payload: parameterValues,
    };
  } catch (err) {
    return false;
  }
};

export const ADD_PARAMETER_VALUE = async (data: FormData) => {
  try {
    const parameterValue = await parameterValueModel.addParameterValue(data);

    return {
      type: ParameterValueActions.ADD_PARAMETER_VALUE,
      payload: parameterValue,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_PARAMETER_VALUE = async (data: FormData) => {
  try {
    const parameterValue = await parameterValueModel.editParameterValue(data);

    return {
      type: ParameterValueActions.EDIT_PARAMETER_VALUE,
      payload: parameterValue,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_PARAMETER_VALUE_BY_ID = async (id: string) => {
  try {
    await parameterValueModel.removeParameterValueById(id);

    return {
      type: ParameterValueActions.REMOVE_PARAMETER_VALUE_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw err;
  }
};
