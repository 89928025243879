import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useNavigateCatch } from "../../../common/navigate";
import {
  useErrors,
  useLanguages
} from "../../../common/redux-hooks";
import { adminRegistrationModel } from "../../../model/query/adminRegistrationModel";
import { storeModel } from "../../../model/query/storeModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_DEFAULT_STORE,
  ADD_STORE,
  EDIT_DEFAULT_STORE,
  EDIT_STORE,
  REMOVE_STORE_BY_ID,
} from "../../../redux/actions/storesActions";
import { useDispatch, useSelector } from "../../../redux/store";
import { EditorType } from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { ILanguageID } from "../../../types/language";
import { IStoreID } from "../../../types/store";
import AppleSwitcher from "../../../ui/apple-switcher";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
} from "../../../ui/common";
import DropdownMultiSelect from "../../../ui/dropdown-multi-select";
import Input from "../../../ui/input";
import { InputWithTitle } from "../../../ui/input-with-title";
import BackButton from "../../components/back-button";
import { getStoreEditorErrors } from "./editor-errors";

const mapStoreToEditorStore = (store: IStoreID): IEditorStore => {
  const {
    key,
    title,
    isDefaultStore,
    isWIP,
    default_email,
    email_subject,
    eshop_identity,
    languages,
    instagram_name,
    smtp_password,
    title_lowercase,
    url,
  } = store;

  return {
    default_email,
    email_subject,
    eshop_identity,
    instagram_name,
    smtp_password,
    title_lowercase,
    url,
    key,
    title,
    languages,
    isDefaultStore,
    isWIP: !!isWIP,
  };
};

export interface IEditorStore {
  key: string;
  url: string;
  title: string;
  title_lowercase: string;
  eshop_identity: string;
  instagram_name: string;
  email_subject: string;
  default_email: string;
  smtp_password: string;
  languages: ILanguageID[];
  isWIP: boolean;
  isDefaultStore: boolean;
}

const initialStoreState: IEditorStore = {
  isDefaultStore: false,
  key: "",
  title: "",
  default_email: "",
  email_subject: "",
  eshop_identity: "",
  instagram_name: "",
  smtp_password: "",
  title_lowercase: "",
  url: "",
  languages: [],
  isWIP: false,
};

interface IProps {
  type: EditorType;
  isForDefaultStore?: boolean;
  registrationKey?: string;
  onSuccess?(store: IStoreID): void;
  outerId?: string;
}

export const StoreEditor = ({
  type,
  isForDefaultStore,
  registrationKey,
  onSuccess,
  outerId,
}: IProps): React.ReactElement<IProps> => {
  const [originalStore, setOriginalStore] = useState<IStoreID | null>(null);
  const [store, setStore] = useState<IEditorStore>(initialStoreState);

  const stores = useSelector(({ stores }) => stores.stores);
  const { createConfirmation } = useConfirmation();
  const navigate = useNavigateCatch();
  const { _id } = useParams();
  const dispatch = useDispatch();
  const colors = useColors();
  const { call } = useQueryWithPopupErrorHandling();
  const languages = useLanguages();

  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getStoreEditorErrors(getError, store);

  const appendStoreToForm = useCallback(
    (form: FormData) => {
      form.append("key", store.key);
      form.append("url", store.url);
      form.append("title", store.title);
      form.append("title_lowercase", store.title_lowercase);
      form.append("eshop_identity", store.eshop_identity);
      form.append("instagram_name", store.instagram_name);
      form.append("email_subject", store.email_subject);
      form.append("default_email", store.default_email);
      form.append("smtp_password", store.smtp_password);
      form.append(
        "languages",
        JSON.stringify(store.languages.map((language) => language._id))
      );

      form.append(
        "isDefaultStore",
        JSON.stringify(isForDefaultStore ? true : store.isDefaultStore)
      );
      form.append("isWIP", JSON.stringify(store.isWIP));

      if (registrationKey) {
        form.append("registration_key", registrationKey);
      }
    },
    [store, registrationKey, isForDefaultStore]
  );

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.STORE);
    };
  }, []);

  const addStoreFromData = async () => {
    const form = new FormData();

    appendStoreToForm(form);

    call(
      () => (isForDefaultStore ? ADD_DEFAULT_STORE(form) : ADD_STORE(form)),
      "Přidání obchodu nebylo úspěšné",
      "Přidání obchodu bylo úspěšné",
      (action) => {
        dispatch(action);
        onSuccess?.(action.payload);
        navigate("/stores");
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  const editStoreFromData = async (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit obchod", async () => {
      const form = new FormData();

      if (!originalStore) return;

      form.append("_id", originalStore._id);

      appendStoreToForm(form);

      call(
        () => (isForDefaultStore ? EDIT_DEFAULT_STORE(form) : EDIT_STORE(form)),
        "Úprava obchodu nebyla úspěšná",
        "Úprava obchodu byla úspěšná",
        (action) => {
          dispatch(action);
          onSuccess?.(action.payload);

          if (exit) {
            navigate("/stores");
          } else {
            setStore(mapStoreToEditorStore(action.payload));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeStore = async () => {
    if (stores.length === 1 || store.isDefaultStore) {
      return;
    }

    createConfirmation("Potvrzení: Odstranit obchod", async () => {
      if (!originalStore?._id) {
        return;
      }

      call(
        () => REMOVE_STORE_BY_ID(originalStore._id),
        "Odstranění obchodu nebylo úspěšné",
        "Odstranění obchodu bylo úspěšné",
        (action) => {
          dispatch(action);
          navigate("/stores");
        }
      );
    });
  };

  const getStoreByIdCall = async (id: string) => {
    call(
      () =>
        isForDefaultStore && registrationKey
          ? adminRegistrationModel.getDefaultStore(registrationKey)
          : storeModel.getStoreById(id),
      "Zobrazení obchodu nebylo úspěšné",
      null,
      (store) => {
        const l = mapStoreToEditorStore(store);

        setOriginalStore(store);
        setStore(l);
      },
      () => {
        navigate("/stores");
      }
    );
  };

  useEffect(() => {
    const id = _id || outerId;

    if (type === EditorType.Edit && id) {
      getStoreByIdCall(id);
    }
  }, [_id, type, outerId]);

  const node =
    type === EditorType.Add ? (
      <FlexCenterAll>
        <Button
          disabled={!isValid}
          style={{
            ...AddButtonStyles,
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
          onClick={addStoreFromData}
        >
          Přidat obchod
        </Button>
      </FlexCenterAll>
    ) : (
      <FlexCenterAll>
        <div
          style={{
            marginRight: 16,
          }}
        >
          <AddButton disabled={!isValid} onClick={() => editStoreFromData()}>
            Uložit
          </AddButton>
        </div>
        <div
          style={{
            marginRight: 16,
          }}
        >
          <AddButton
            disabled={!isValid}
            onClick={() => editStoreFromData(true)}
          >
            Uložit a zavřít
          </AddButton>
        </div>
        <div>
          <RemoveButton
            data-title={
              stores.length === 1
                ? "Obchod nelze odstranit jelikož je jediný vytvořený"
                : store.isDefaultStore
                ? "Obchod nelze odstranit jelikož je zvolen jako výchozí"
                : undefined
            }
            disabled={stores.length === 1 || store.isDefaultStore}
            onClick={removeStore}
          >
            Odstranit
          </RemoveButton>
        </div>
      </FlexCenterAll>
    );

  return (
    <BottomFixedWrapperWrapper
      node={!isForDefaultStore ? node : <></>}
      deps={[type, appendStoreToForm, isValid]}
    >
      {!isForDefaultStore && <BackButton />}
      <div
        style={{
          marginTop: 40,
          fontSize: 36,
          marginLeft: 8,
          opacity: 0.444,
        }}
      >
        {type === EditorType.Add
          ? isForDefaultStore
            ? "Přidat výchozí obchod"
            : "Přidat nový obchod"
          : isForDefaultStore
          ? "Upravit výchozí obchod"
          : "Upravit obchod"}
      </div>
      <div style={{ marginTop: 20 }}>
        <Flex>
          <div>
            <InputsWrapper>
              <InputWithTitle
                inputProps={{
                  error: errors.key || errors.key_already_exists,
                }}
                wrapperStyle={{
                  marginTop: 0,
                }}
                value={store.key}
                setValue={(key) => setStore((l) => ({ ...l, key }))}
                title="Klíč obchodu (URL friendly)"
              />
              <InputWithTitle
                inputProps={{
                  error: errors.url,
                }}
                value={store.url}
                setValue={(url) => setStore((l) => ({ ...l, url }))}
                title="URL obchodu (např. https://kappu.cz/) - musí mít '/' na konci"
              />
              <InputWithTitle
                inputProps={{
                  error: errors.title,
                }}
                value={store.title}
                setValue={(title) => setStore((l) => ({ ...l, title }))}
                title="Název obchodu (s doménou a začínající velké písmeno)"
              />
              <InputWithTitle
                inputProps={{
                  error: errors.title_lowercase,
                }}
                value={store.title_lowercase}
                setValue={(title_lowercase) =>
                  setStore((l) => ({ ...l, title_lowercase }))
                }
                title="Název obchodu malými písmeny (s doménou)"
              />
              <InputWithTitle
                inputProps={{
                  error: errors.eshop_identity,
                }}
                value={store.eshop_identity}
                setValue={(eshop_identity) =>
                  setStore((l) => ({ ...l, eshop_identity }))
                }
                title="Název obchodu bez domény, malými písmeny"
              />
              <div
                style={{
                  marginTop: 16,
                  marginBottom: 4,
                  marginLeft: 4,
                  fontSize: 13,
                }}
              >
                Instagramový profil (bez @)
              </div>
              <FlexCenterAlign>
                <div
                  style={{
                    marginRight: 4,
                    fontSize: 13,
                  }}
                >
                  @
                </div>
                <Input
                  error={errors.instagram_name}
                  value={store.instagram_name}
                  setValue={(instagram_name) =>
                    setStore((l) => ({ ...l, instagram_name }))
                  }
                />
              </FlexCenterAlign>

              <InputWithTitle
                inputProps={{
                  error: errors.email_subject,
                }}
                value={store.email_subject}
                setValue={(email_subject) =>
                  setStore((l) => ({ ...l, email_subject }))
                }
                title="Předmět v emailu pro zákazníka (např. 'Kappu.cz ☕')"
              />

              <InputWithTitle
                inputProps={{
                  error: errors.default_email,
                }}
                value={store.default_email}
                setValue={(default_email) =>
                  setStore((l) => ({ ...l, default_email }))
                }
                title="Výchozí email obchodu"
              />

              <InputWithTitle
                inputProps={{
                  error: errors.smtp_password,
                }}
                value={store.smtp_password}
                setValue={(smtp_password) =>
                  setStore((l) => ({ ...l, smtp_password }))
                }
                title="Heslo k SMTP"
              />

              <div>
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 4,
                    marginLeft: 4,
                    fontSize: 13,
                  }}
                >
                  Jazyky
                </div>
                <DropdownMultiSelect
                  error={errors.languages_empty}
                  list={languages.map((c) => ({
                    content: `${c.title} (${c.country_id})`,
                    value: c,
                    query: `${c.title} (${c.country_id})`,
                    unique_id: c._id,
                    disabled: store.languages.some((l) => l._id === c._id),
                  }))}
                  onSelectValue={(language) =>
                    setStore((store) => ({
                      ...store,
                      languages: [...store.languages, language],
                    }))
                  }
                  selectedDropdownIDs={store.languages.map((lang) => lang._id)}
                  onRemoveUniqueID={(id) =>
                    setStore((store) => ({
                      ...store,
                      languages: store.languages.filter(
                        (lang) => lang._id !== id
                      ),
                    }))
                  }
                />
              </div>

              <FlexCenterAlign
                style={{
                  marginTop: 8,
                }}
              >
                <Checkbox
                  disabled={isForDefaultStore}
                  value={isForDefaultStore ? true : store.isDefaultStore}
                  setValue={(isDefault) =>
                    setStore((l) => ({ ...l, isDefault }))
                  }
                />
                <div
                  style={{
                    marginLeft: 8,
                    fontWeight: 400,
                    fontSize: 13,
                  }}
                >
                  Je výchozí obchod
                </div>
              </FlexCenterAlign>

              {!isForDefaultStore && (
                <FlexCenterAlign
                  style={{
                    marginTop: 24,
                  }}
                >
                  <AppleSwitcher
                    value={store.isWIP}
                    setValue={(isWIP) => setStore((l) => ({ ...l, isWIP }))}
                  />
                  <div
                    style={{
                      marginLeft: 8,
                      fontWeight: 400,
                      fontSize: 15,
                    }}
                  >
                    Obchod je pozastavený (WIP)
                  </div>
                </FlexCenterAlign>
              )}
            </InputsWrapper>
          </div>
        </Flex>
      </div>
      {isForDefaultStore ? (
        type === EditorType.Add ? (
          <FlexCenterAll style={{ marginTop: 16 }}>
            <Button
              disabled={!isValid}
              style={{
                ...AddButtonStyles,
                backgroundColor: colors.MAIN_300,
              }}
              hoverBackgroundColor={colors.MAIN_250}
              onClick={addStoreFromData}
            >
              Přidat obchod
            </Button>
          </FlexCenterAll>
        ) : (
          <FlexCenterAll style={{ marginTop: 16 }}>
            <AddButton disabled={!isValid} onClick={() => editStoreFromData()}>
              Uložit
            </AddButton>
          </FlexCenterAll>
        )
      ) : null}
    </BottomFixedWrapperWrapper>
  );
};

export default StoreEditor;
