import { IProduct } from "../../types/products";
import { AnalyticsTimeScale, TFromToDate } from "../../view/analytics/types";
import axios from "../axios";

export interface ISalesResult {
  totalSales: number;
  previousSalesByTimeScale: number | undefined;
}

export interface IOrdersResult {
  totalOrders: number;
  previousOrdersByTimeScale: number | undefined;
}

export interface IMostSoldProductsResult {
  products: IProductWithCount[];
}

export interface IProductWithCount {
  product: IProduct | null;
  name: string;
  price_vat: number;
  count: number;
}

export interface IAnalyticsModel {
  getSales: (
    timeScaleOrDate: AnalyticsTimeScale | TFromToDate,
    store: string,
    language: string
  ) => Promise<ISalesResult>;
  getOrders: (
    timeScaleOrDate: AnalyticsTimeScale | TFromToDate,
    store: string,
    language: string
  ) => Promise<IOrdersResult>;
  getMostSoldProducts: (
    timeScaleOrDate: AnalyticsTimeScale | TFromToDate,
    limit: number,
    store: string,
    language: string
  ) => Promise<IMostSoldProductsResult>;
}

export const analyticsModel: IAnalyticsModel = {
  getOrders(timeScaleOrDate, store, language) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IOrdersResult>(
          `/analytics/get-orders`,
          {
            params: {
              timeScaleOrDate: JSON.stringify(timeScaleOrDate),
              store,
              language,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getSales(timeScaleOrDate, store, language) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ISalesResult>(`/analytics/get-sales`, {
          params: {
            timeScaleOrDate: JSON.stringify(timeScaleOrDate),
            language,
            store,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getMostSoldProducts(timeScaleOrDate, limit, store, language) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IMostSoldProductsResult>(
          `/analytics/get-most-sold-products`,
          {
            params: {
              timeScaleOrDate: JSON.stringify(timeScaleOrDate),
              limit,
              store,
              language,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
