import { TAction } from ".";

export interface ISearchState {}

const initialSearchState: ISearchState = {};

export const searchReducer = (
  state: ISearchState = initialSearchState,
  action: TAction
): ISearchState => {
  switch (action.type) {
    default:
      return state;
  }
};
