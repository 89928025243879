import axios from "../axios";
import { IParameterGroupID } from "./../../types/parameter";

export interface IParameterGroupModel {
  getParameterGroups: () => Promise<IParameterGroupID[]>;
  getParameterGroupById: (id: string) => Promise<IParameterGroupID>;
  addParameterGroup: (data: FormData) => Promise<IParameterGroupID>;
  editParameterGroup: (data: FormData) => Promise<IParameterGroupID>;
  removeParameterGroupById: (id: string) => Promise<boolean>;
}

export const parameterGroupModel: IParameterGroupModel = {
  getParameterGroups() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IParameterGroupID[]>(
          `/parameter-groups/get-all`
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getParameterGroupById(_id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IParameterGroupID>(
          `/parameter-groups/get-by-id`,
          {
            params: {
              _id,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  addParameterGroup(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IParameterGroupID>(
          `/parameter-groups/add`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editParameterGroup(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IParameterGroupID>(
          `/parameter-groups/edit`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeParameterGroupById(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(
          `/parameter-groups/remove-by-id`,
          {
            _id,
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
