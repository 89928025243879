import { TLanguageString } from "./common";
import { IUserOnlyNameWithID } from "./user";
export enum HistoryActionType {
  ADD = "ADD",
  EDIT = "EDIT",
  REMOVE = "REMOVE",
}

export enum HistoryActionItemType {
  PRODUCT = "Product",
  CATEGORY = "Category",
}

export interface IHistoryActionChanges {
  before: any;
  after: any;
}

export type THistoryActionProduct = {
  _id: string;
  name: TLanguageString;
};

export type THistoryActionCategory = {
  _id: string;
  name: TLanguageString;
};

export type THistoryActionItem = THistoryActionProduct | THistoryActionCategory;

export interface IHistoryAction {
  _id: string;
  created_by: IUserOnlyNameWithID | null;
  changes: IHistoryActionChanges;
  item_type: HistoryActionItemType;
  item_id: THistoryActionItem;
  item_name: TLanguageString;
  type: HistoryActionType;
  date: number;
}

export enum RevertActionResult {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  HAS_SOME_UNAVAILABLE_CHANGES = "HAS_SOME_UNAVAILABLE_CHANGES",
  IS_SAME_ACTION_REMOVED = "IS_SAME_ACTION_REMOVED",
  IS_SAME_WITH_UNAVAILABLE_CHANGES_REMOVED = "IS_SAME_WITH_UNAVAILABLE_CHANGES_REMOVED",
  IS_SAME = "IS_SAME",
}
