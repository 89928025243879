import React, { useEffect, useState } from "react";
import YearMonthSelect from "./year-month-select";
import {
  Days,
  EnglishDays,
  EnglishMonths,
  ISelectedTimes,
  IsSelectedType,
  SelectedDateType,
  TDate,
} from "./types";
import { CSSProperties } from "styled-components";
import Button from "../button";
import { FlexCenterAll } from "./../common";
import colors from "../colors";
import { useColors } from "../../providers/theme/theme-provider";

type TDaysInMonth = {
  day: string;
  index: number;
};

const getDaysInMonthArray = (
  month: number,
  year: number,
  mondayFirst?: boolean
): TDaysInMonth[] => {
  const date = new Date(year, month, 0),
    count = date.getDate(),
    lastDay = date.getDay(),
    arr: TDaysInMonth[] = [];

  let index = mondayFirst ? (lastDay - 1 < 0 ? 6 : lastDay - 1) : lastDay;

  for (let i = 0; i < count; i++) {
    arr.push({ day: Days[index], index });

    index--;

    if (index < 0) {
      index = 6;
    }
  }

  return arr.reverse();
};

const getSelectedDateString = (
  selectedTimes: ISelectedTimes
): React.ReactElement => {
  const styles: CSSProperties = {
    fontSize: 17,
    letterSpacing: 0.75,
    color: "#444",
    textAlign: "center",
  };

  if (!selectedTimes.date) return <></>;

  if (selectedTimes.date.type === SelectedDateType.Range) {
    let fromDate =
        selectedTimes.date.from &&
        new Date(
          selectedTimes.date.from.year,
          selectedTimes.date.from.month - 1,
          selectedTimes.date.from.date
        ),
      toDate =
        selectedTimes.date.to &&
        new Date(
          selectedTimes.date.to.year,
          selectedTimes.date.to.month - 1,
          selectedTimes.date.to.date
        );

    return (
      <div
        style={{
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={styles}>Vybrané {!toDate ? "datum" : "rozmezí"}</div>
        <div style={styles}>
          {fromDate && toDate
            ? toDate < fromDate
              ? toDate.toLocaleDateString()
              : fromDate.toLocaleDateString()
            : fromDate
            ? fromDate.toLocaleDateString()
            : null}
          {toDate && fromDate ? " - " : null}
          {fromDate && toDate
            ? fromDate > toDate
              ? fromDate.toLocaleDateString()
              : toDate.toLocaleDateString()
            : toDate
            ? toDate.toLocaleDateString()
            : null}
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          fontSize: 17,
          letterSpacing: 0.75,
          color: "#444",
          textAlign: "center",
        }}
      >
        Vybrané datum:{" "}
        {new Date(
          selectedTimes.date.date.year,
          selectedTimes.date.date.month - 1,
          selectedTimes.date.date.date
        ).toLocaleDateString()}
      </div>
    );
  }
};

const isSelectedDateEqual = (
  selectedTimes: ISelectedTimes,
  currentDate: TDate
): IsSelectedType => {
  const selectedDate = selectedTimes.date;

  if (!selectedDate) return IsSelectedType.None;

  if (selectedDate.type === SelectedDateType.Range) {
    if (
      (selectedDate.from &&
        selectedDate.from.date === currentDate.date &&
        selectedDate.from.month === currentDate.month &&
        selectedDate.from.year === currentDate.year) ||
      (selectedDate.to &&
        selectedDate.to.date === currentDate.date &&
        selectedDate.to.month === currentDate.month &&
        selectedDate.to.year === currentDate.year)
    ) {
      return IsSelectedType.Full;
    }

    if (selectedDate.from && selectedDate.to) {
      let currentDateTime = new Date(
          currentDate.year,
          currentDate.month,
          currentDate.date
        ).getTime(),
        fromTime = new Date(
          selectedDate.from.year,
          selectedDate.from.month,
          selectedDate.from.date
        ).getTime(),
        toTime = new Date(
          selectedDate.to.year,
          selectedDate.to.month,
          selectedDate.to.date
        ).getTime();

      if (fromTime > toTime) {
        if (currentDateTime >= toTime && currentDateTime <= fromTime)
          return IsSelectedType.Partly;
      }

      if (currentDateTime >= fromTime && currentDateTime <= toTime)
        return IsSelectedType.Partly;
    }
  }

  if (selectedDate.type === SelectedDateType.Single) {
    if (
      selectedDate.date.date === currentDate.date &&
      selectedDate.date.month === currentDate.month &&
      selectedDate.date.year === currentDate.year
    ) {
      return IsSelectedType.Full;
    }
  }

  return IsSelectedType.None;
};

const getDateTimeFromTDate = (date: TDate): number => {
  return new Date(date.year, date.month, date.date).getTime();
};

type TRange = {
  type: SelectedDateType.Range;
  from: Date;
  to: Date;
};

export type TOnSelectDate =
  | TRange
  | { date: Date; type: SelectedDateType.Single };

export const shiftToMondayFirst = (days: string[]): string[] => {
  let daysCopy = [...days];

  daysCopy.push(daysCopy.shift() || "");

  return daysCopy;
};

interface IProps {
  onSelectDate(date: TOnSelectDate | null): void;
  selectedDate?: TOnSelectDate;
  /** 12 length string array, starting December first, i.e.: ["December", "January"] */
  months?: string[];
  /** 7 length string array, starting Sunday first, i.e.: ["Sunday", "Monday"] */
  days?: string[];
  /** Allows date range to be selected instead of single date */
  rangeOption?: boolean;
  mondayFirst?: boolean;
  hideSelectedDate?: boolean;
  minDate?: Date;
  maxDate?: Date;
  size?: number;
}

export const Calendar = ({
  minDate,
  maxDate,
  selectedDate,
  months = EnglishMonths,
  days = EnglishDays,
  mondayFirst = false,
  rangeOption = false,
  hideSelectedDate = false,
  size = 260,
  onSelectDate,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  const getSelectedTimes = (): ISelectedTimes => {
    if (selectedDate) {
      if (selectedDate.type === SelectedDateType.Range) {
        return {
          month: selectedDate.from.getMonth() + 1,
          year: selectedDate.from.getFullYear(),
          date: {
            type: SelectedDateType.Range,
            from: {
              date: selectedDate.from.getDate(),
              month: selectedDate.from.getMonth() + 1,
              year: selectedDate.from.getFullYear(),
            },
            to: {
              date: selectedDate.to.getDate(),
              month: selectedDate.to.getMonth() + 1,
              year: selectedDate.to.getFullYear(),
            },
          },
        };
      } else {
        return {
          month: selectedDate.date.getMonth() + 1,
          year: selectedDate.date.getFullYear(),
          date: {
            type: SelectedDateType.Single,
            date: {
              date: selectedDate.date.getDate(),
              month: selectedDate.date.getMonth() + 1,
              year: selectedDate.date.getFullYear(),
            },
          },
        };
      }
    }

    return {
      date: null,
      month: currents.month,
      year: currents.year,
    } as ISelectedTimes;
  };

  const date = new Date(),
    currents = {
      date: date.getDate(),
      day: date.getDay(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    },
    [selectedTimes, setSelectedTimes] = useState<ISelectedTimes>(
      getSelectedTimes()
    ),
    daysToRender = mondayFirst ? shiftToMondayFirst(days) : days;

  useEffect(() => {
    if (!selectedDate) {
      setSelectedTimes(getSelectedTimes());
    }
  }, [selectedDate]);

  const [isSelectedFrom, setIsSelectedFrom] = useState(true);

  useEffect(() => {
    if (selectedTimes.date) {
      if (
        selectedTimes.date.type === SelectedDateType.Range &&
        selectedTimes.date.from
      ) {
        if (selectedTimes.date.to) {
          const fromDate = new Date(
              selectedTimes.date.from.year,
              selectedTimes.date.from.month - 1,
              selectedTimes.date.from.date
            ),
            toDate = new Date(
              selectedTimes.date.to.year,
              selectedTimes.date.to.month - 1,
              selectedTimes.date.to.date
            );

          onSelectDate({
            from: toDate < fromDate ? toDate : fromDate,
            to: fromDate > toDate ? fromDate : toDate,
            type: SelectedDateType.Range,
          });
          return;
        } else {
          onSelectDate({
            date: new Date(
              selectedTimes.date.from.year,
              selectedTimes.date.from.month - 1,
              selectedTimes.date.from.date
            ),
            type: SelectedDateType.Single,
          });
        }
      } else if (selectedTimes.date.type === SelectedDateType.Single) {
        onSelectDate({
          date: new Date(
            selectedTimes.date.date.year,
            selectedTimes.date.date.month - 1,
            selectedTimes.date.date.date
          ),
          type: SelectedDateType.Single,
        });
      }
    } else {
      onSelectDate(null);
    }
  }, [selectedTimes.date, selectedTimes.month, selectedTimes.year]);

  const onSelectTile = (tileIndex: number) => {
    const selectedDate: TDate = {
      month: selectedTimes.month,
      year: selectedTimes.year,
      date: tileIndex,
    };

    if (rangeOption) {
      if (isSelectedFrom) {
        if (
          selectedTimes.date &&
          selectedTimes.date.type === SelectedDateType.Range &&
          selectedTimes.date.from &&
          selectedDate.date === selectedTimes.date.from.date &&
          selectedDate.month === selectedTimes.date.from.month &&
          selectedDate.year === selectedTimes.date.from.year
        ) {
          setSelectedTimes({
            ...selectedTimes,
            date: null,
          });
          setIsSelectedFrom(true);
          return;
        }

        setSelectedTimes({
          ...selectedTimes,
          date: {
            type: SelectedDateType.Range,
            from: selectedDate,
            to: null,
          },
        });
        setIsSelectedFrom(false);
      } else {
        if (
          selectedTimes.date &&
          selectedTimes.date.type === SelectedDateType.Range &&
          selectedTimes.date.from
        ) {
          if (
            selectedDate.date === selectedTimes.date.from.date &&
            selectedDate.month === selectedTimes.date.from.month &&
            selectedDate.year === selectedTimes.date.from.year
          ) {
            setSelectedTimes({
              ...selectedTimes,
              date: null,
            });
            setIsSelectedFrom(true);
            return;
          }

          setSelectedTimes({
            ...selectedTimes,
            date: {
              ...selectedTimes.date,
              to: selectedDate,
            },
          });
        }
        setIsSelectedFrom(true);
      }

      return;
    }

    setSelectedTimes({
      ...selectedTimes,
      date: {
        type: SelectedDateType.Single,
        date: selectedDate,
      },
    });
  };

  const TILE_WIDTH = size / 7 - 0.0001;

  if (days.length !== 7 || months.length !== 12) {
    throw new Error(
      "Months or days array is not correct length! Should be 7 different days and 12 different months."
    );
  }

  return (
    <div
      style={{
        width: size,
      }}
    >
      <YearMonthSelect
        months={months}
        selectedTimes={selectedTimes}
        setSelectedTimes={setSelectedTimes}
        size={size}
        minDate={minDate}
        maxDate={maxDate}
      />
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {daysToRender.map((d, i) => (
            <div
              key={d}
              style={{
                width: TILE_WIDTH,
                height: 36,
                borderBottomWidth: 1,
                justifyContent: "center",
                display: "flex",
                paddingBottom: 4,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: 13,
                  color: mondayFirst
                    ? i === 6
                      ? colors.OPPOSITE_MAIN_100
                      : i === 5
                      ? colors.OPPOSITE_MAIN_100
                      : colors.OPPOSITE_MAIN_400
                    : i === 0
                    ? colors.OPPOSITE_MAIN_100
                    : i === 6
                    ? colors.OPPOSITE_MAIN_100
                    : colors.OPPOSITE_MAIN_400,
                  fontWeight: "500",
                }}
              >
                {d.slice(0, 1)}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {getDaysInMonthArray(
            selectedTimes.month,
            selectedTimes.year,
            mondayFirst
          ).map((d, i) => {
            const currentDate: TDate = {
                date: i + 1,
                month: selectedTimes.month,
                year: selectedTimes.year,
              },
              currentDateTime = getDateTimeFromTDate({
                ...currentDate,
                month: currentDate.month - 1,
              }),
              selectedType =
                selectedTimes.date &&
                isSelectedDateEqual(selectedTimes, currentDate),
              isToday =
                currents.date === currentDate.date &&
                currents.month === currentDate.month &&
                currents.year === currentDate.year,
              isOutOfRange =
                (minDate &&
                  currentDateTime < minDate.getTime() &&
                  currentDate.date !== minDate.getDate()) ||
                (maxDate &&
                  currentDateTime > maxDate.getTime() &&
                  currentDate.date !== maxDate.getDate());

            return (
              <FlexCenterAll
                key={i}
                style={{
                  height: TILE_WIDTH,
                  width: TILE_WIDTH,
                  marginLeft: i === 0 ? Math.abs(0 - d.index) * TILE_WIDTH : 0,
                }}
              >
                <Button
                  disabled={isOutOfRange}
                  onClick={() => onSelectTile(i + 1)}
                  key={i}
                  hoverBackgroundColor={colors.MAIN_200}
                  style={{
                    height: TILE_WIDTH - 4,
                    width: TILE_WIDTH - 4,
                    borderTopWidth: i < 7 ? 0 : 1,
                    borderTopColor: "#eee",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: isOutOfRange ? 0.2 : 1,
                    padding: 0,
                    boxShadow: "unset",
                    position: "relative",
                    backgroundColor:
                      selectedType === IsSelectedType.Partly
                        ? colors.MAIN_300
                        : selectedType === IsSelectedType.Full
                        ? colors.MAIN_250
                        : isToday
                        ? colors.MAIN_400
                        : "unset",
                  }}
                >
                  <div
                    style={{
                      top: 1,
                      left: 0.2,
                      fontSize: 14,
                      zIndex: 2,
                      color: mondayFirst
                        ? d.index === 6
                          ? selectedType === IsSelectedType.Full
                            ? colors.OPPOSITE_MAIN_450
                            : colors.OPPOSITE_MAIN_200
                          : d.index === 5
                          ? selectedType === IsSelectedType.Full
                            ? colors.OPPOSITE_MAIN_450
                            : colors.OPPOSITE_MAIN_200
                          : selectedType === IsSelectedType.Full
                          ? colors.OPPOSITE_MAIN_450
                          : colors.OPPOSITE_MAIN_450
                        : d.index === 0
                        ? selectedType === IsSelectedType.Full
                          ? colors.OPPOSITE_MAIN_450
                          : colors.OPPOSITE_MAIN_200
                        : d.index === 6
                        ? selectedType === IsSelectedType.Full
                          ? colors.OPPOSITE_MAIN_450
                          : colors.OPPOSITE_MAIN_200
                        : selectedType === IsSelectedType.Full
                        ? colors.OPPOSITE_MAIN_450
                        : colors.OPPOSITE_MAIN_450,
                    }}
                  >
                    {i + 1}
                  </div>
                </Button>
              </FlexCenterAll>
            );
          })}
        </div>
      </div>
      {!hideSelectedDate && selectedTimes.date && (
        <div
          style={{
            marginTop: 16,
          }}
        >
          {getSelectedDateString(selectedTimes)}
        </div>
      )}
    </div>
  );
};

export default Calendar;
