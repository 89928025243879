import React from "react";
import { useColors } from "../../providers/theme/theme-provider";
import { FlexCenterAll } from "../../ui/common";
import { Loading } from "./../../ui/loading";

interface IProps {}

export const LoadingScreen = ({}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <FlexCenterAll
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 10000,
        background: colors.MAIN_500,
      }}
    >
      <Loading />
    </FlexCenterAll>
  );
};

export default LoadingScreen;
