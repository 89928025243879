import { IImageDB, ImageType } from "../../types/gallery";
import axios from "../axios";

export interface IGalleryModel {
  getImages: (user_id: string) => Promise<IImageDB[]>;
  getImageById: (id: string) => Promise<IImageDB | null>;
  getImagesByQuery: (
    user_id: string,
    query: string,
    offset: number,
    limit: number,
    type?: ImageType
  ) => Promise<{ totalCount: number; results: IImageDB[] }>;
  removeImageById: (id: string) => Promise<boolean>;
  uploadImage: (data: FormData) => Promise<IImageDB[]>;
  editImage: (data: FormData) => Promise<IImageDB>;
}

export const galleryModel: IGalleryModel = {
  getImages(user_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IImageDB[]>(`/gallery/get-all`, {
          params: {
            user_id,
          },
        });
        res(response.data);
      } catch (err) {
        rej(err);
      }
    });
  },
  getImageById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IImageDB | null>(
          `/gallery/get-by-id`,
          {
            params: {
              id,
            },
          }
        );
        res(response.data);
      } catch (err) {
        rej(err);
      }
    });
  },
  getImagesByQuery(user_id, query, offset, limit, type) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<{
          totalCount: number;
          results: IImageDB[];
        }>(`/gallery/get-filtered`, {
          params: {
            user_id,
            query,
            offset,
            limit,
            type,
          },
        });
        res(response.data);
      } catch (err) {
        rej(err);
      }
    });
  },
  removeImageById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/gallery/remove-by-id`, {
          id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  uploadImage(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await await axios.post<IImageDB[]>(
          `/gallery/upload-image`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editImage(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await await axios.post<IImageDB>(
          `/gallery/edit-image`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
