import React, { useEffect, useState } from "react";
import { useModel } from "../../model/provider";
import { IProduct } from "../../types/products";
import { Flex } from "../../ui/common";
import Input from "../../ui/input";
import ViewWrapper from "../view-wrapper";
import ParameterValuesList from "./list/parameter-values-list";
import ParametersList from "./list/parameters-list";

interface IProps {}

export const Parameters = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <ViewWrapper
      style={{
        marginBottom: 40,
      }}
    >
      <Flex>
        <ParametersList />
        <ParameterValuesList />
      </Flex>
    </ViewWrapper>
  );
};

export default Parameters;
