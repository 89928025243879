import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import styled from "styled-components";
import Loading from "../../ui/loading";
import LoadingBar, { ILoadingBarRef } from "../../ui/loading-bar";
import Overlay from "../common/overlay";

const AbsoluteLoadingBarWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000000;
`;

export interface ILoadingWithOverlayWindowRef {
  close(): void;
  show(): void;
}

interface IProps {}

export const LoadingWithOverlayWindow = forwardRef(
  (
    {}: IProps,
    ref: React.ForwardedRef<ILoadingWithOverlayWindowRef>
  ): React.ReactElement<IProps> => {
    const loadingBarRef = useRef<ILoadingBarRef>(null);
    const [active, setActive] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        close() {
          setActive(false);
          loadingBarRef.current?.finish();
        },
        show() {
          setActive(true);
          loadingBarRef.current?.start();
        },
      }),
      [active, loadingBarRef.current]
    );

    if (!active) {
      return <></>;
    }

    return (
      <>
        <Overlay active={active} zIndex={10000} />
        <AbsoluteLoadingBarWrapper>
          <LoadingBar ref={loadingBarRef} />
        </AbsoluteLoadingBarWrapper>
      </>
    );
  }
);

export default LoadingWithOverlayWindow;
