export enum AdKey {
  Meta = "META",
}

export interface IAd {
  key: AdKey;
  date: number;
}

export interface IAdID extends IAd {
  _id: string;
}
