import React, { ButtonHTMLAttributes } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { useColors } from "../providers/theme/theme-provider";
import { TReduxError } from "../types/error";
import { colors } from "./colors";
import { FlexCenterAlign } from "./common";
import ErrorTooltip from "./tooltips/error-tooltip";

const StyledButton = styled.button<IButtonProps>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_400};
    color: ${props.theme.colors.OPPOSITE_MAIN_400};
    border-radius: ${props.theme.borderRadius}px;
  `}

  padding: 0.6em 1.25em;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.015em;
  cursor: pointer;

  &:hover {
    background-color: ${({ hoverBackgroundColor, theme }) =>
      hoverBackgroundColor
        ? css`
            ${hoverBackgroundColor} !important
          `
        : theme.colors.MAIN_350};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:active {
    opacity: 0.5;
  }

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.colors.ERROR_400};
    `}
`;

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  hoverBackgroundColor?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  error?: TReduxError | string | boolean;
  wrapperStyle?: CSSProperties;
  errorTooltipDisabled?: boolean;
}

export const Button: React.FC<IButtonProps> = ({
  children,
  hoverBackgroundColor,
  buttonRef,
  error,
  wrapperStyle,
  errorTooltipDisabled,
  ...props
}: IButtonProps) => {
  return (
    <FlexCenterAlign
      style={{
        display: "inline-flex",
        ...wrapperStyle,
      }}
    >
      <StyledButton
        {...props}
        ref={buttonRef}
        hoverBackgroundColor={hoverBackgroundColor}
        error={error}
      >
        {children}
      </StyledButton>
      <ErrorTooltip error={error} errorTooltipDisabled={errorTooltipDisabled} />
    </FlexCenterAlign>
  );
};

export default Button;
