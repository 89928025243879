import React from "react";
import styled, { css, CSSProperties } from "styled-components";
import { colors } from "./colors";
import { MdOutlineCheck } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { useColors } from "../providers/theme/theme-provider";
import { BsCheck, BsCheck2 } from "react-icons/bs";
import { FiCheck } from "react-icons/fi";

const StyledCheckbox = styled.button<{
  size?: number;
}>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;

  ${(props) => css`
    border: 1px solid ${props.theme.colors.MAIN_150};
    background-color: ${props.theme.colors.MAIN_350};
    border-radius: ${props.theme.borderRadius / 2}px;

    &:disabled {
      &:hover {
        background-color: ${props.theme.colors.MAIN_350};
      }
    }

    &:hover {
      background-color: ${props.theme.colors.MAIN_250};
    }
  `}

  ${({ size }) => css`
    width: ${size || 22}px;
    height: ${size || 22}px;
  `}

  &:disabled {
    opacity: 0.7;
    cursor: default;

    &:active {
      opacity: 0.7;
    }
  }

  &:active {
    opacity: 0.8;
  }
`;

interface IProps {
  value: boolean;
  setValue(value: boolean): void;
  disabled?: boolean;
  style?: CSSProperties;
  black?: boolean;
  size?: number;
  readonly?: boolean;
}

export const Checkbox = ({
  value,
  setValue,
  disabled,
  style,
  size = 22,
  readonly,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <StyledCheckbox
      disabled={disabled}
      onClick={readonly ? undefined : () => setValue(!value)}
      style={{
        ...style,
        cursor: readonly || disabled ? "default" : "pointer",
      }}
      size={size}
    >
      {value && <FiCheck size={size} color={colors.OPPOSITE_MAIN_500} />}
    </StyledCheckbox>
  );
};

export default Checkbox;
