import React from "react";
import {
  SelectedProductItem,
  SelectedProductsWrapper,
  TSelectedProduct,
} from "../selected-products";
import { FlexCenterAlign } from "../../../ui/common";
import Button from "../../../ui/button";
import styled from "styled-components";
import { useModal } from "../../../providers/modal/modal-provider";
import ParameterGroupActionModal from "./parameter-group-action-modal";
import CategoryGroupActionModal from "./category-group-action-modal";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { productModel } from "../../../model/query/productModel";
import { useColors } from "../../../providers/theme/theme-provider";
import { useAppConfig } from "../../../common/redux-hooks";

const ActionButtonsWrapper = styled.div`
  margin-top: 16px;

  & > div {
    margin-bottom: 12px;

    & > div {
      margin-right: 8px;
    }
  }
`;

interface IProps {
  selectedProducts: TSelectedProduct[];
}

export const GroupActionsModal = ({
  selectedProducts,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();
  const { store } = useAppConfig();
  const { createModal } = useModal();
  const { createConfirmation } = useConfirmation();
  const { call } = useQueryWithPopupErrorHandling();

  const addParameterClick = () => {
    createModal(
      <ParameterGroupActionModal
        type="add"
        selectedProducts={selectedProducts}
      />
    );
  };
  const removeParameterClick = () => {
    createModal(
      <ParameterGroupActionModal
        type="remove"
        selectedProducts={selectedProducts}
      />
    );
  };

  const addCategoryClick = () => {
    createModal(
      <CategoryGroupActionModal
        type="add"
        selectedProducts={selectedProducts}
      />
    );
  };
  const removeCategoryClick = () => {
    createModal(
      <CategoryGroupActionModal
        type="remove"
        selectedProducts={selectedProducts}
      />
    );
  };

  const availableOnEshopClick = () => {
    createConfirmation(
      "Opravdu chcete zviditelnit vybrané produkty na eshopu?",
      () => {
        const data = new FormData();

        data.append(
          "products",
          JSON.stringify(selectedProducts.map((p) => p.id))
        );
        data.append("store", store._id);

        call(
          () => productModel.groupSetAvailable(data),
          "Zviditelnění produktů na eshopu nebylo úspěšné",
          "Zviditelnění produktů na eshopu bylo úspěšné",
          () => {}
        );
      }
    );
  };

  const notAvailableOnEshopClick = () => {
    createConfirmation(
      "Opravdu chcete schovat vybrané produkty na eshopu?",
      () => {
        const data = new FormData();

        data.append(
          "products",
          JSON.stringify(selectedProducts.map((p) => p.id))
        );
        data.append("store", store._id);

        call(
          () => productModel.groupSetNotAvailable(data),
          "Schování produktů na eshopu nebylo úspěšné",
          "Schování produktů na eshopu bylo úspěšné",
          () => {}
        );
      }
    );
  };

  const deleteProductsClick = () => {
    createConfirmation("Opravdu chcete odstranit vybrané produkty?", () => {
      setTimeout(() => {
        createConfirmation("Potvrzení: Odstranění produktů", () => {
          const data = new FormData();

          data.append(
            "products",
            JSON.stringify(selectedProducts.map((p) => p.id))
          );

          call(
            () => productModel.groupDeleteProducts(data),
            "Odstranění produktů nebylo úspěšné",
            "Odstranění produktů bylo úspěšné",
            () => {
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          );
        });
      }, 250);
    });
  };

  return (
    <div
      style={{
        paddingRight: 50,
      }}
    >
      <div
        style={{
          fontSize: 13,
          marginBottom: 8,
          marginLeft: 6,
        }}
      >
        Pro vybrané produkty
      </div>
      <SelectedProductsWrapper>
        {selectedProducts.map((p, i) => (
          <SelectedProductItem key={i}>{p.value.eshop_id}</SelectedProductItem>
        ))}
      </SelectedProductsWrapper>

      <div
        style={{
          fontSize: 13,
          marginBottom: 8,
          marginTop: 20,
          marginLeft: 6,
        }}
      >
        Zvolte hromadnou akci
      </div>

      <ActionButtonsWrapper>
        <FlexCenterAlign>
          <Button onClick={addParameterClick}>Přidat parametr</Button>
          <Button onClick={removeParameterClick}>Odebrat parametr</Button>
        </FlexCenterAlign>
        <FlexCenterAlign>
          <Button onClick={addCategoryClick}>Přidat kategorii</Button>
          <Button onClick={removeCategoryClick}>Odebrat kategorii</Button>
        </FlexCenterAlign>
        <div>
          <Button onClick={availableOnEshopClick}>
            Zviditelnit na eshopu <b>{store.title}</b>
          </Button>
          <div
            style={{
              marginTop: 8,
            }}
          >
            <Button onClick={notAvailableOnEshopClick}>
              Schovat na eshopu <b>{store.title}</b>
            </Button>
          </div>
        </div>
        <div
          style={{
            marginTop: 8,
          }}
        >
          <Button
            style={{
              backgroundColor: colors.ERROR_400,
              color: "#fff",
            }}
            hoverBackgroundColor={colors.ERROR_500}
            onClick={deleteProductsClick}
          >
            Odstranit produkty
          </Button>
        </div>
      </ActionButtonsWrapper>
    </div>
  );
};

export default GroupActionsModal;
