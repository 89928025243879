import React from "react";
import { useNavigate } from "react-router-dom";
import { EditButton, Flex } from "../../../ui/common";
import Table, { TRow } from "../../../ui/table";
import { useSelector } from "./../../../redux/store";
import { ILanguageID } from "./../../../types/language";
import { useRole } from "../../../common/redux-hooks";
import { isDisabledByRole } from "../../../types/role";

interface IRowActionButtons {
  language: ILanguageID;
}

const RowActionButtons = ({
  language,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  const role = useRole();
  return (
    <Flex>
      {!isDisabledByRole(role, { type: "language", permission: "edit" }) && (
        <EditButton
          path={`edit-language/${language._id}`}
          data-title="Upravit eshop"
        />
      )}
    </Flex>
  );
};

interface IProps {
  query: string;
}

export const LanguagesList = ({}: IProps): React.ReactElement<IProps> => {
  const { languages } = useSelector(({ languages }) => languages);

  const languageRows: TRow[] = languages.map((l) => {
    return {
      cells: [
        {
          content: <RowActionButtons language={l} />,
          key: "actions",
          value: null,
        },
        {
          content: l.locale,
          key: "key",
          value: l.locale,
        },
        {
          content: l.title,
          key: "title",
          value: l.title,
        },
      ],
    };
  });

  return (
    <div
      style={{
        marginRight: 20,
        width: 600,
        marginBottom: 40,
      }}
    >
      <Table
        headerKeys={[
          { content: "", query_key: "" },
          { content: "Locale klíč", query_key: "key" },
          { content: "Název jazyka", query_key: "title" },
        ]}
        rows={languageRows}
      />
    </div>
  );
};

export default LanguagesList;
