export enum AnalyticsTimeScale {
  Hour = "Hour",
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Year = "Year",
  All = "All",
}

export type TFromToDate = {
    from: number;
    to: number;
}