import { IHistoryAction, RevertActionResult } from "./../../types/history";
import axios from "../axios";

interface IGetHistoryActionsResult {
  historyActions: IHistoryAction[];
  totalCount: number;
}

export interface IHistoryActionsModel {
  getAllHistoryActions(
    offset: number,
    limit: number
  ): Promise<IGetHistoryActionsResult>;
  getHistoryActionsByItemId(
    item_id: string,
    offset: number,
    limit: number
  ): Promise<IGetHistoryActionsResult>;
  revertHistoryAction(_id: string): Promise<RevertActionResult>;
}

export const historyActionsModel: IHistoryActionsModel = {
  getAllHistoryActions(offset, limit) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IGetHistoryActionsResult>(
          `/history-actions/get-all`,
          {
            params: {
              offset,
              limit,
            },
          }
        );

        res(response.data);
      } catch (err) {
        rej(err);
      }
    });
  },
  getHistoryActionsByItemId(item_id, offset, limit) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IGetHistoryActionsResult>(
          `/history-actions/get-by-item-id`,
          {
            params: {
              item_id,
              offset,
              limit,
            },
          }
        );

        res(response.data);
      } catch (err) {
        rej(err);
      }
    });
  },
  revertHistoryAction(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<RevertActionResult>(
          `/history-actions/revert`,
          {
            _id,
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
