import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useModel } from "../../model/provider";
import Button from "../../ui/button";
import { Flex, InputsWrapper } from "../../ui/common";
import { InputWithTitle } from "../../ui/input-with-title";
import BackButton from "../components/back-button";
import ViewWrapper from "../view-wrapper";
import { useConfirmation } from "./../../providers/confirmation/confirmation-provider";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";

interface IProps {}

export const TestingEnvironment = ({}: IProps): React.ReactElement<IProps> => {
  const [loading, setLoading] = useState(false);

  const { createConfirmation } = useConfirmation();
  const {
    testingModel: { backendTestingCall, removeTestingItems },
  } = useModel();

  const { call } = useQueryWithPopupErrorHandling();

  const backendTestingRequest = () => {
    call(
      () => backendTestingCall(),
      null,
      "Zavolání na backend bylo úspěšné",
      () => null
    );
  };

  const removeTestingItemsCall = () => {
    createConfirmation(
      "Opravdu chcete vytvořit kategorie a produkty z dané URL kategorie?",
      async () => {
        await removeTestingItems();
      }
    );
  };

  return (
    <ViewWrapper>
      <Flex
        style={{
          marginTop: 20,
        }}
      >
        <InputsWrapper>
          <Button
            style={{
              width: "100%",
            }}
            onClick={backendTestingRequest}
          >
            Backend testing request
          </Button>
        </InputsWrapper>
      </Flex>

      <Flex
        style={{
          marginTop: 20,
        }}
      >
        <InputsWrapper>
          <Button
            disabled={loading}
            style={{
              width: "100%",
            }}
            onClick={removeTestingItemsCall}
          >
            Vymazat testovací produkty, kategorie, obrázky
          </Button>
        </InputsWrapper>
      </Flex>
    </ViewWrapper>
  );
};

export default TestingEnvironment;
