import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getFullName } from "../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { useModal } from "../../providers/modal/modal-provider";
import { SimplePopupType } from "../../providers/simple-popup/data";
import { useColors } from "../../providers/theme/theme-provider";
import { useDispatch } from "../../redux/store";
import Button from "../../ui/button";
import {
  DefaultAvatar,
  EditButton,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  ProfileImage,
} from "../../ui/common";
import FileInput, { IFileInputRef } from "../components/file-input";
import { useErrors, useUser } from "./../../common/redux-hooks";
import {
  CHANGE_PASSWORD,
  EDIT_USER_INFO,
  UPLOAD_PROFILE_IMAGE,
} from "./../../redux/actions/userActions";
import UserPasswordEditor, {
  IPasswords,
} from "./components/change-password/user-password-editor";
import { getChangePasswordErrors } from "./components/change-password/user-password-editor-errors";
import UserInfoEditor, {
  IUserInfo,
} from "./components/change-user-info/user-info-editor";
import { getEditUserInfoErrors } from "./components/change-user-info/user-info-editor-errors";

const ChangePasswordModal = () => {
  const user = useUser();
  const [passwords, setPasswords] = useState<IPasswords>({
    newPassword: "",
    newPasswordConfirm: "",
    oldPassword: "",
  });
  const { getError } = useErrors();
  const { isValid } = getChangePasswordErrors(getError, passwords);

  const { call } = useQueryWithPopupErrorHandling();
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const colors = useColors();

  const onChangePasswordClick = async () => {
    const data = new FormData();

    data.append("id", user._id);
    data.append("new_password", passwords.newPassword);
    data.append("old_password", passwords.oldPassword);

    call(
      () => CHANGE_PASSWORD(data),
      "Změna hesla nebyla úspěšná",
      "Změna hesla byla úspěšná",
      (action) => {
        closeModal();
        dispatch(action);
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  return (
    <div>
      <UserPasswordEditor
        oldPasswordEnabled
        passwords={passwords}
        setPasswords={setPasswords}
      />
      <FlexCenterAll
        style={{
          marginTop: 20,
        }}
      >
        <Button
          disabled={!isValid}
          onClick={onChangePasswordClick}
          style={{
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
        >
          Změnit heslo
        </Button>
      </FlexCenterAll>
    </div>
  );
};

const EditUserInfoModal = () => {
  const user = useUser();
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName,
  });
  const { closeModal } = useModal();
  const colors = useColors();
  const { getError } = useErrors();
  const { isValid } = getEditUserInfoErrors(getError, userInfo);
  const { call } = useQueryWithPopupErrorHandling();
  const dispatch = useDispatch();

  const onEditUserInfoClick = async () => {
    if (
      userInfo.userName === user.userName &&
      userInfo.firstName === user.firstName &&
      userInfo.lastName === user.lastName
    ) {
      closeModal();
      return;
    }

    const data = new FormData();

    data.append("id", user._id);
    data.append("firstName", userInfo.firstName);
    data.append("lastName", userInfo.lastName);
    data.append("userName", userInfo.userName);

    call(
      () => EDIT_USER_INFO(data),
      "Změna osobních údajů nebyla úspěšná",
      "Změna osobních údajů byla úspěšná",
      (action) => {
        closeModal();
        dispatch(action);
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  return (
    <div>
      <UserInfoEditor setUserInfo={setUserInfo} userInfo={userInfo} />
      <FlexCenterAll
        style={{
          marginTop: 20,
        }}
      >
        <Button
          disabled={!isValid}
          onClick={onEditUserInfoClick}
          style={{
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
        >
          Změnit osobní údaje
        </Button>
      </FlexCenterAll>
    </div>
  );
};

const FullName = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-left: 12px;
`;

interface IProps {}

export const Profile = ({}: IProps): React.ReactElement<IProps> => {
  const user = useUser();
  const dispatch = useDispatch();
  const { createModal, closeModal } = useModal();

  const onChangeImage = async (file: File) => {
    const data = new FormData();

    data.append("id", user._id);
    data.append("profile-image", file);

    const imageOrFalse = await UPLOAD_PROFILE_IMAGE(data);

    if (imageOrFalse) {
      dispatch(imageOrFalse);
    }
  };

  const fileInputRef = useRef<IFileInputRef>(null);

  const onChangePasswordClick = () => {
    createModal(<ChangePasswordModal />);
  };

  const onChangeUserInfoClick = () => {
    createModal(<EditUserInfoModal />);
  };

  return (
    <div>
      <Flex>
        <InputsWrapper
          style={{
            padding: "20px 30px",
          }}
        >
          <FlexCenterAlign>
            <div
              style={{
                position: "relative",
              }}
            >
              <FileInput
                ref={fileInputRef}
                setFile={onChangeImage}
                customButton={
                  <EditButton
                    data-title="Změnit profilovou fotku"
                    style={{
                      opacity: 0.8,
                      padding: 6,
                      position: "absolute",
                      left: -10,
                      top: -10,
                    }}
                    size={16}
                    onClick={() => fileInputRef.current?.openFileInput()}
                  />
                }
              />
              {user.image ? (
                <ProfileImage src={user.image.url} />
              ) : (
                <DefaultAvatar letter={user.firstName.charAt(0)} />
              )}
            </div>
            <FullName>{getFullName(user)}</FullName>
          </FlexCenterAlign>
        </InputsWrapper>
      </Flex>
      <Flex
        style={{
          marginTop: 12,
        }}
      >
        <InputsWrapper>
          <div>
            <Button onClick={onChangeUserInfoClick}>Změnit osobní údaje</Button>
          </div>
          <div
            style={{
              marginTop: 8,
            }}
          >
            <Button
              style={{
                width: "100%",
              }}
              wrapperStyle={{
                width: "100%",
              }}
              onClick={onChangePasswordClick}
            >
              Změnit heslo
            </Button>
          </div>
        </InputsWrapper>
      </Flex>
    </div>
  );
};

export default Profile;
