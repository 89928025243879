import { IPopulatedCategoryId } from "./category";
import { TLanguageNumber, TLanguageSlug, TLanguageString } from "./common";
import { IImageDB } from "./gallery";
import { IHistoryAction } from "./history";
import { IManufacturerID } from "./manufacturer";
import { IOrder } from "./order";
import { IProductParameter, IProductParameterGroup } from "./parameter";
import { INewProductParameterGroup } from "./product-parameter-group";
import { IStoreID } from "./store";
import { ISubProduct } from "./subproduct";
import { LengthUnit, TNumberWithUnit, WeightUnit } from "./units";

export interface IPackaging {
  width: TNumberWithUnit<LengthUnit>;
  height: TNumberWithUnit<LengthUnit>;
  length: TNumberWithUnit<LengthUnit>;

  brutto_weight: TNumberWithUnit<WeightUnit>;
  netto_weight: TNumberWithUnit<WeightUnit>;
}

export interface IVariantProduct {
  _id: string;
  name: TLanguageString;
  image: IImageDB | null;
}

export interface IVariant {
  products: IVariantProduct[];
  generated: boolean;
}

export interface IProduct {
  _id: string;
  ean: string;
  eshop_id: string;
  name: TLanguageString;
  shortName?: TLanguageString;
  description: TLanguageString;
  image: IImageDB | null;
  gallery: IImageDB[];
  packaging: IPackaging;
  stock: number;
  isInExternalStock: boolean;
  price_vat: TLanguageNumber;
  buy_price: TLanguageNumber;
  params: IProductParameter[];
  parameter_groups: IProductParameterGroup[];
  product_parameter_groups: INewProductParameterGroup[];
  orders: IOrder[];
  last_buy_date: number;
  categories: IPopulatedCategoryId[];
  history: IHistoryAction[];
  slug: TLanguageSlug;
  manufacturer: IManufacturerID | null;
  stores: IStoreID[];
  available_in_stores: IStoreID[];
  translation_needed: boolean;
  added_date: number;
  variants: IVariant[];
  related_products: IVariant[];
  sale_price: TLanguageNumber;
  external_link?: string;
  subproducts: ISubProduct[];
}

export interface IUnpopulatedProduct
  extends Omit<IProduct, "stores" | "available_in_stores"> {
  stores: string[];
  available_in_stores: string[];
}

export interface IEditorProduct {
  _id?: string;
  image: IImageDB | null;
  gallery: IImageDB[];
  ean: string;
  eshop_id: string;
  name: TLanguageString;
  shortName?: TLanguageString;
  description: TLanguageString;
  stock: number;
  isInExternalStock: boolean;
  price_vat: TLanguageNumber;
  sale_price: TLanguageNumber;
  buy_price: TLanguageNumber;
  packaging: IPackaging;
  params: IProductParameter[];
  parameter_groups: IProductParameterGroup[];
  categories: string[];
  slug: TLanguageSlug;
  manufacturer: IManufacturerID | null;
  stores: IStoreID[];
  available_in_stores: IStoreID[];
  translation_needed: boolean;
  variants: IVariant[];
  related_products: IVariant[];
  external_link?: string;
  subproducts: ISubProduct[];
}

export const productInitState: IEditorProduct = {
  image: null,
  gallery: [],
  ean: "",
  eshop_id: "",
  name: {},
  description: {},
  stock: 0,
  isInExternalStock: false,
  price_vat: {},
  buy_price: {},
  sale_price: {},
  packaging: {
    width: {
      value: 0,
      unit: LengthUnit.cm,
    },
    height: {
      value: 0,
      unit: LengthUnit.cm,
    },
    length: {
      value: 0,
      unit: LengthUnit.cm,
    },
    brutto_weight: {
      value: 0,
      unit: WeightUnit.g,
    },
    netto_weight: {
      value: 0,
      unit: WeightUnit.g,
    },
  },
  params: [],
  parameter_groups: [],
  categories: [],
  slug: {},
  manufacturer: null,
  stores: [],
  available_in_stores: [],
  translation_needed: true,
  variants: [],
  related_products: [],
  subproducts: [],
  external_link: "",
};

export enum ProductFilterItemType {
  Contains = "CONTAINS",
  DoesNotContain = "DOES_NOT_CONTAIN",
  Equal = "EQUAL",
  LessThan = "LESS_THAN",
  MoreThan = "MORE_THAN",
}

export type TProductFilterItem<T> = {
  value: T;
  type: ProductFilterItemType;
};

export interface IProductFilters {
  buy_price: TProductFilterItem<number>;
  price_vat: TProductFilterItem<number>;
  stock: TProductFilterItem<number>;
  categories: TProductFilterItem<string[]>;
  manufacturer: TProductFilterItem<string[]>;
  parameter_groups: TProductFilterItem<string[]>;
  params: TProductFilterItem<IProductParameter[]>;
  width: TProductFilterItem<TNumberWithUnit<LengthUnit>>;
  height: TProductFilterItem<TNumberWithUnit<LengthUnit>>;
  length: TProductFilterItem<TNumberWithUnit<LengthUnit>>;
  netto_weight: TProductFilterItem<TNumberWithUnit<WeightUnit>>;
  brutto_weight: TProductFilterItem<TNumberWithUnit<WeightUnit>>;
}

export const initialProductFilters: IProductFilters = {
  price_vat: {
    value: 0,
    type: ProductFilterItemType.Equal,
  },
  buy_price: {
    value: 0,
    type: ProductFilterItemType.Equal,
  },
  stock: {
    value: 0,
    type: ProductFilterItemType.Equal,
  },
  categories: {
    value: [],
    type: ProductFilterItemType.Contains,
  },
  manufacturer: {
    value: [],
    type: ProductFilterItemType.Contains,
  },
  parameter_groups: {
    value: [],
    type: ProductFilterItemType.Contains,
  },
  params: {
    value: [],
    type: ProductFilterItemType.Contains,
  },
  brutto_weight: {
    type: ProductFilterItemType.Equal,
    value: {
      unit: WeightUnit.g,
      value: 0,
    },
  },
  netto_weight: {
    type: ProductFilterItemType.Equal,
    value: {
      unit: WeightUnit.g,
      value: 0,
    },
  },
  width: {
    type: ProductFilterItemType.Equal,
    value: {
      unit: LengthUnit.cm,
      value: 0,
    },
  },
  height: {
    type: ProductFilterItemType.Equal,
    value: {
      unit: LengthUnit.cm,
      value: 0,
    },
  },
  length: {
    type: ProductFilterItemType.Equal,
    value: {
      unit: LengthUnit.cm,
      value: 0,
    },
  },
};
