import React from "react";
import { CSSProperties } from "styled-components";
import { TUnit } from "../types/units";
import { FlexCenterAlign } from "./common";
import ValueWithUnitInput, {
  IValueWithUnitInputProps,
} from "./value-with-unit-input";

interface IProps<T> extends IValueWithUnitInputProps<T> {
  title: string;
  wrapperStyle?: CSSProperties;
}

export const NumberWithUnitInputWithTitle = <T extends TUnit>({
  title,
  wrapperStyle,
  ...props
}: IProps<T>): React.ReactElement<IProps<T>> => {
  return (
    <div
      style={{
        marginTop: 16,
        ...wrapperStyle,
      }}
    >
      <div
        style={{
          marginBottom: 4,
          marginLeft: 9,
        }}
      >
        <FlexCenterAlign
          style={{
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: 13 }}>{title}</div>
        </FlexCenterAlign>
      </div>
      <FlexCenterAlign>
        <ValueWithUnitInput {...props} />
      </FlexCenterAlign>
    </div>
  );
};

export default NumberWithUnitInputWithTitle;
