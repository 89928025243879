import React from "react";
import StoreEditor from "../../../stores/store-editor/store-editor";
import { EditorType } from "../../../../types/common";
import { useAdminRegistration } from "../../admin-registration-provider";
import { IStoreID } from "../../../../types/store";
import Button from "../../../../ui/button";
import { AddButtonStyles, FlexCenterAll } from "../../../../ui/common";
import { useColors } from "../../../../providers/theme/theme-provider";
import { Navigate, useNavigate } from "react-router-dom";

interface IProps {}

export const DefaultStoreRegistration =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();
    const { adminRegistration, setAdminRegistration } = useAdminRegistration();
    const colors = useColors();

    const onSuccess = (store: IStoreID) => {
      setAdminRegistration((a) => ({ ...a, store }));
    };

    const onContinue = () => {
      navigate("/registration/admin-configuration");
    };

    if (!adminRegistration.registrationKey) {
      return <Navigate to="/registration" />;
    }

    return (
      <div style={{ marginBottom: 40 }}>
        <StoreEditor
          onSuccess={onSuccess}
          isForDefaultStore
          outerId={
            adminRegistration.store ? adminRegistration.store._id : undefined
          }
          type={adminRegistration.store ? EditorType.Edit : EditorType.Add}
          registrationKey={adminRegistration.registrationKey}
        />
        <FlexCenterAll
          style={{
            marginTop: 12,
          }}
        >
          <Button
            disabled={!adminRegistration.store}
            style={{
              ...AddButtonStyles,
              backgroundColor: colors.MAIN_400,
              fontSize: 15,
            }}
            hoverBackgroundColor={colors.MAIN_350}
            onClick={onContinue}
          >
            Pokračovat
          </Button>
        </FlexCenterAll>
      </div>
    );
  };

export default DefaultStoreRegistration;
