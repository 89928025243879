import React from "react";
import { ILanguageID } from "../types/language";

export const LanguageContext = React.createContext<ILanguageID | null>(null);

export const useLanguageContext = (): ILanguageID => {
  const context = React.useContext(LanguageContext);

  if (!context) {
    throw new Error("Language context is not defined!");
  }

  return context;
};
