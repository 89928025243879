import React from "react";
import {
  getParameterValueLocalized
} from "../../../../common/parameter-value";
import { useAppConfig } from "../../../../common/redux-hooks";
import { nonTranslatedString } from "../../../../constants/language";
import { useModal } from "../../../../providers/modal/modal-provider";
import { useColors } from "../../../../providers/theme/theme-provider";
import { EditorType } from "../../../../types/common";
import { IProductParameter } from "../../../../types/parameter";
import { IEditorProduct } from "../../../../types/products";
import { ISubProduct } from "../../../../types/subproduct";
import Button from "../../../../ui/button";
import {
  EditButton,
  FlexCenterAlign,
  InputsWrapper,
} from "../../../../ui/common";
import SubProductEditor from "./subproduct-editor";

function getUniqueSubProducts(subProducts: ISubProduct[]): ISubProduct[] {
  const uniqueSubProducts: ISubProduct[] = [];

  subProducts.forEach((subProduct) => {
    const isParameterAlreadyAdded = uniqueSubProducts.some((uniqueSubProduct) =>
      uniqueSubProduct.parameters.every((p) =>
        subProduct.parameters.find(
          (p2) => p.parameter?._id === p2.parameter?._id
        )
      )
    );

    if (!isParameterAlreadyAdded) {
      uniqueSubProducts.push(subProduct);
    }
  });

  return uniqueSubProducts;
}

const hasSameParametersOnlyParameter = (
  params1: IProductParameter[],
  params2: IProductParameter[]
) => {
  if (params1.length === params2.length) {
    if (
      params1.every((param1) =>
        params2.find((param2) => {
          if (param1.parameter?._id === param2.parameter?._id) {
            return true;
          }

          return false;
        })
      )
    ) {
      return true;
    }
  }

  return false;
};

interface IProps {
  product: IEditorProduct;
  setProduct: React.Dispatch<React.SetStateAction<IEditorProduct>>;
}

export const SubproductsView = ({
  product,
  setProduct,
}: IProps): React.ReactElement<IProps> => {
  const { createModal } = useModal();
  const { language } = useAppConfig();

  const createAddSubproductModal = () => {
    createModal(
      <SubProductEditor
        product={product}
        setProduct={setProduct}
        type={EditorType.Add}
      />
    );
  };

  const createEditSubproductModal = (
    subProduct: ISubProduct,
    index: number
  ) => {
    createModal(
      <SubProductEditor
        type={EditorType.Edit}
        product={product}
        initialSubProduct={subProduct}
        setProduct={setProduct}
        index={index}
      />
    );
  };

  const uniqueSubProducts = getUniqueSubProducts(product.subproducts);
  const colors = useColors();

  return (
    <div>
      <FlexCenterAlign>
        <Button onClick={createAddSubproductModal}>Přidat podprodukt</Button>
      </FlexCenterAlign>
      {uniqueSubProducts.map((subproductGroup, i) => (
        <InputsWrapper
          key={i}
          style={{
            marginTop: 24,
          }}
        >
          <FlexCenterAlign
            style={{
              color: colors.OPPOSITE_MAIN_200,
              fontSize: 13,
              marginBottom: 8,
              fontWeight: 500,
            }}
          >
            {subproductGroup.parameters
              .map(
                (p) =>
                  p.parameter?.title[language.locale] || nonTranslatedString
              )
              .join(", ")}
          </FlexCenterAlign>
          {product.subproducts
            .filter((s) =>
              hasSameParametersOnlyParameter(
                s.parameters,
                subproductGroup.parameters
              )
            )
            .map((sub, j) => (
              <FlexCenterAlign
                key={j}
                style={{
                  marginBottom: 6,
                  justifyContent: "space-between",
                }}
              >
                <FlexCenterAlign>
                  <div
                    style={{
                      marginRight: 12,
                      fontSize: 11,
                    }}
                  >
                    {sub.stock} ks skladem
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      marginRight: 20,
                    }}
                  >
                    {sub.parameters
                      .map((s) =>
                        getParameterValueLocalized(
                          s.value_id || s.value_number,
                          language.locale,
                          s.unit
                        )
                      )
                      .join(", ")}
                  </div>
                </FlexCenterAlign>
                <EditButton
                  onClick={() =>
                    createEditSubproductModal(
                      sub,
                      product.subproducts.findIndex((s) => s === sub)
                    )
                  }
                />
              </FlexCenterAlign>
            ))}

          {/* {product.subproducts
            .filter((s) => s.parameter._id === subproductGroup.parameter._id)
            .map((sub, i) => (
              <FlexCenterAlign
                style={{
                  marginBottom: 6,
                  justifyContent: "space-between",
                }}
              >
                <FlexCenterAlign>
                  <div
                    style={{
                      marginRight: 12,
                      fontSize: 11,
                    }}
                  >
                    {sub.stock} ks skladem
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      marginRight: 20,
                    }}
                  >
                    {getParameterValueLocalized(
                      sub.value_id,
                      language.locale,
                      sub.unit
                    )}
                  </div>
                </FlexCenterAlign>
                <EditButton
                  onClick={() =>
                    createEditSubproductModal(
                      sub,
                      product.subproducts.findIndex((s) => s === sub)
                    )
                  }
                />
              </FlexCenterAlign>
            ))} */}
        </InputsWrapper>
      ))}
    </div>
  );
};

export default SubproductsView;
