import React from "react";
import styled from "styled-components";
import { HeaderText } from "../../ui/common";
import ViewWrapper from "../view-wrapper";
import MostSoldProducts from "./most-sold-products";
import Orders from "./orders";
import Sales from "./sales";
import { useAppConfig } from "../../common/redux-hooks";

const MeasuresWrapper = styled.div`
  display: flex;
  margin-top: 40px;

  & > div {
    margin-right: 24px;
  }
`;

interface IProps {}

export const Analytics = ({}: IProps): React.ReactElement<IProps> => {
  const { store } = useAppConfig();

  return (
    <ViewWrapper>
      <HeaderText
        style={{
          fontSize: 18,
          marginBottom: 12,
        }}
      >
        Analytika pro obchod:{" "}
        <span
          style={{
            fontWeight: 500,
          }}
        >
          {store.title}
        </span>
      </HeaderText>

      <MeasuresWrapper>
        <Sales />
        <Orders />
      </MeasuresWrapper>
      <MostSoldProducts />
    </ViewWrapper>
  );
};

export default Analytics;
