import { ILanguageID} from "../../types/language";
import { IStoreID } from "../../types/store";
import { StoreActions, TStoreAction } from "../actions/storesActions";

export interface IStoresState {
  stores: IStoreID[];
}

const initialStoresState: IStoresState = {
  stores: [],
};

export const storesReducer = (
  state: IStoresState = initialStoresState,
  action: TStoreAction
): IStoresState => {
  switch (action.type) {
    case StoreActions.SET_STORES:
      return { ...state, stores: action.payload };
    case StoreActions.ADD_STORE:
      if (!action.payload) return state;

      return {
        ...state,
        stores: [...state.stores, action.payload],
      };
    case StoreActions.EDIT_STORE:
      const stores = [...state.stores],
        i = stores.findIndex((l) => l._id === action.payload._id);

      stores[i] = action.payload;

      return { ...state, stores };
    case StoreActions.REMOVE_STORE_BY_ID:
      return {
        ...state,
        stores: state.stores.filter((c) => c._id !== action.payload),
      };
    default:
      return state;
  }
};
