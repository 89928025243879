import { TError } from "../../types/error";
import { ITaskDB } from "../../types/tasks";
import axios from "../axios";

export interface ITasksModel {
  addTask(data: FormData): Promise<ITaskDB>;
  editTask(data: FormData): Promise<ITaskDB>;
  removeTaskById(id: string): Promise<boolean>;
  getTasksForUser(user: string): Promise<ITaskDB[] | false>;
}

export const tasksModel: ITasksModel = {
  getTasksForUser(id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ITaskDB[]>(`/tasks/get-tasks-for-user`, {
          params: {
            id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addTask(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ITaskDB>(`/tasks/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editTask(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ITaskDB>(`/tasks/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeTaskById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.delete<boolean>(`/tasks/remove-by-id`, {
          params: {
            id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
