import axios from "../axios";

export interface ITestingModel {
  backendTestingCall: () => Promise<void>;
  removeTestingItems: () => Promise<boolean>;
}

export const testingModel: ITestingModel = {
  backendTestingCall() {
    return new Promise(async (res, rej) => {
      await axios.post<boolean>(`/testing/backend-testing-call`);
    });
  },
  removeTestingItems() {
    return new Promise(async (res, rej) => {
      const response = await axios.delete<boolean>(
        `/testing/remove-testing-items`
      );

      res(response.data);
    });
  },
};
