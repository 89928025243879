export enum ImageType {
  Product = "Product",
  Category = "Category",
  Profile = "Profile",
  Task = "Task",
  Manufacturer = "Manufacturer",
  Email = "Email",
}

export interface IImageDB {
  _id: string;
  name: string;
  url: string;
  type: ImageType;
  date: number;
}

export interface IEditImageDB {
  _id: string;
  name: string;
  url: string;
  image?: File;
}
