import React from "react";
import styled from "styled-components";
import { useStores } from "../../common/redux-hooks";
import { EditButton, HeaderText } from "../../ui/common";
import Table, { TRow } from "../../ui/table";
import ViewWrapper from "../view-wrapper";

interface IProps {}

const TableWrapper = styled.div`
  width: 75%;
`;

export const PrivacyPolicy = ({}: IProps): React.ReactElement<IProps> => {
  const stores = useStores();

  const rows: TRow[] = stores.map((l) => ({
    cells: [
      {
        key: "actions",
        value: "actions",
        content: (
          <>
            <EditButton
              data-title="Upravit podmínky ochrany osobních údajů"
              path={`/privacy-policy/edit/${l.key}`}
            />
          </>
        ),
      },
      {
        key: "title",
        value: l.title + " - " + l.key.toUpperCase(),
        content: l.title + " - " + l.key.toUpperCase(),
      },
      {
        key: "PrivacyPolicy",
        value: l.privacyPolicy ? "Ano" : "Ne",
        content: l.privacyPolicy ? "Ano" : "Ne",
      },
    ],
  }));

  return (
    <ViewWrapper>
      <HeaderText
        style={{
          marginBottom: 20,
        }}
      >
        Podmínky ochrany osobních údajů
      </HeaderText>
      <TableWrapper>
        <Table
          headerKeys={[
            { content: "", width: "50px" },
            { content: "Eshop" },
            { content: "Má podmínky ochrany osobních údajů" },
          ]}
          rows={rows}
        />
      </TableWrapper>
    </ViewWrapper>
  );
};

export default PrivacyPolicy;
