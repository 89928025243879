import React from "react";
import { useAppConfig, useParameterValues } from "../common/redux-hooks";
import { TReduxError } from "../types/error";
import { IParameterValueID } from "../types/parameter";
import { getContentForParameterValue } from "../view/products/product-editor/components/parameters-input/content";
import DropdownMultiSelect from "./dropdown-multi-select";

interface IProps {
  selectedIDs: string[];
  onSelect(parameterValue: IParameterValueID): void;
  onRemove(id: string): void;
  error?: TReduxError;
}

export const ParameterValuesMultiSelect = ({
  selectedIDs,
  onSelect,
  onRemove,
  error,
}: IProps): React.ReactElement<IProps> => {
  const { language } = useAppConfig();
  const parameterValues = useParameterValues();

  return (
    <div>
      <DropdownMultiSelect
        error={error}
        list={parameterValues.map((p) => ({
          content: getContentForParameterValue(p, language.locale),
          value: p,
          unique_id: p._id,
          disabled: selectedIDs.includes(p._id),
          query: p.value[language.locale],
        }))}
        onRemoveUniqueID={onRemove}
        onSelectValue={(value) => {
          onSelect(value);
        }}
        selectedDropdownIDs={selectedIDs}
        title="Vyberte hodnoty pro parametr"
        deleteTitle="Odstranit hodnotu"
      />
    </div>
  );
};

export default ParameterValuesMultiSelect;
