import React, { useEffect, useState } from "react";
import { emailsModel } from "../../../model/query/emailModel";
import { IEmailRecipientID } from "../../../types/emailRecipient";
import { EditButton, FlexCenterAlign, HeaderText } from "../../../ui/common";
import Table, { TRow } from "../../../ui/table";
import Checkbox from "../../../ui/checkbox";
import IconButton from "../../../ui/icon-button";
import { IEmailID } from "../../../types/email";
import { useModal } from "../../../providers/modal/modal-provider";
import { useNavigate } from "react-router-dom";

interface IProps {}

export const UnfinishedEmailsList =
  ({}: IProps): React.ReactElement<IProps> => {
    const [isLoading, setIsLoading] = useState(true);
    const [emails, setEmails] = useState<IEmailID[]>([]);
    const navigate = useNavigate()

    useEffect(() => {
      const fetchEmails = async () => {
        try {
          setIsLoading(true);
          const e = await emailsModel.getUnfinishedEmails();
          setIsLoading(false);

          setEmails(e);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      };

      fetchEmails();
    }, []);
    const rows: TRow[] = emails.map((e) => ({
      cells: [
        {
          content: (
            <FlexCenterAlign>
              <EditButton
                onClick={() => navigate(`/emails/edit-email/${e._id}`)}
                data-title="Zobrazit email"
                style={{
                  padding: 4,
                }}
              />
            </FlexCenterAlign>
          ),
          key: "actions",
          value: "actions",
        },
        {
          key: "subject",
          value: e.subject,
          content: e.subject,
        },
        {
          key: "date",
          content: new Date(e.date).toLocaleString(),
          value: e.date,
        },
      ],
    }));

    return (
      <Table
        loading={isLoading}
        headerKeys={[
          { content: "", width: "50px" },
          { content: "Název" },
          { content: "Vytvořeno" },
        ]}
        rows={rows}
      />
    );
  };

export default UnfinishedEmailsList;
