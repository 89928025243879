import React, { useState } from "react";
import { useAppConfig, usePayments } from "../../common/redux-hooks";
import {
  getBooleanTitle,
  getPaymentTypeTitle,
} from "../../locales/determineString";
import { IPaymentID } from "../../types/payment";
import { EditButton } from "../../ui/common";
import Input from "../../ui/input";
import Table from "../../ui/table";

interface IRowActionButtons {
  payment: IPaymentID;
}

const RowActionButtons = ({
  payment,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  return (
    <div>
      <EditButton
        path={`edit-payment/${payment._id}`}
        data-title="Upravit platební metodu"
      />
    </div>
  );
};

interface IProps {}

export const PaymentsList = ({}: IProps): React.ReactElement<IProps> => {
  const payments = usePayments();
  const { language, store } = useAppConfig();
  const locale = language.locale;
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        maxWidth: 700,
      }}
    >
      <Input
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vyhledat platební metodu"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <Table
        query={search}
        headerKeys={[
          {
            content: "",
            width: "10%",
          },
          {
            content: "Typ platební metody",
            width: "30%",
            query_key: "type",
          },
          {
            content: "Cena platební metody",
            width: "30%",
            query_key: "price",
          },
          {
            content: "Dostupné v obchodě",
            width: "30%",
            query_key: "available_on_eshop",
          },
        ]}
        rows={payments.map((c) => ({
          cells: [
            {
              content: <RowActionButtons payment={c} />,
              key: "actions",
              value: "actions",
            },
            {
              content: getPaymentTypeTitle(c.type),
              key: "type",
              value: getPaymentTypeTitle(c.type),
            },
            {
              content: `${c.price?.[locale] || 0} ${language.currency.symbol}`,
              key: "price",
              value: `${c.price?.[locale] || 0} ${language.currency.symbol}`,
            },
            {
              content: getBooleanTitle(
                c.stores.some((paymentStore) => paymentStore._id === store._id)
              ),
              key: "available_on_eshop",
              value: getBooleanTitle(
                c.stores.some((paymentStore) => paymentStore._id === store._id)
              ),
            },
          ],
        }))}
      />
    </div>
  );
};

export default PaymentsList;
