import React from "react";
import { IEmailID } from "../../../types/email";
import { Flex, InputsWrapper, StyledLabel } from "../../../ui/common";
import RichTextEditor from "../../../ui/editorTinyMce";
import { ImageType } from "../../../types/gallery";

interface IProps {
  email: IEmailID;
}

export const EmailView = ({ email }: IProps): React.ReactElement<IProps> => {
  return (
    <>
      <Flex>
        <InputsWrapper style={{ marginBottom: 4 }}>
          <StyledLabel>Předmět emailu</StyledLabel>
          {email.subject}
        </InputsWrapper>
      </Flex>
      <InputsWrapper style={{ width: 680, paddingTop: 4 }}>
        <StyledLabel style={{ marginBottom: 8 }}>Obsah emailu</StyledLabel>
        <RichTextEditor
          imageType={ImageType.Email}
          value={email.content}
        />
      </InputsWrapper>
    </>
  );
};

export default EmailView;
