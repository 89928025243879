import React from "react";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";
import { GalleryListMode } from "..";
import { getLocaleDateStringWithHoursAndMinutes } from "../../../common/date";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useModal } from "../../../providers/modal/modal-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import { REMOVE_IMAGE_BY_ID } from "../../../redux/actions/galleryActions";
import { useDispatch } from "../../../redux/store";
import { IImageDB } from "../../../types/gallery";
import colors from "../../../ui/colors";
import {
  DeleteButton,
  EditButton,
  Flex,
  FlexCenterAll,
  InputsWrapper,
} from "../../../ui/common";
import LazyLoadingGalleryImage from "../../../ui/lazy-loading-image";
import EditImage from "../edit-image";

const ImageItemDate = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.OPPOSITE_MAIN_200};
  `}

  font-size: 11px;
  opacity: 0.5;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2px;
  margin-top: -4px;
`;

export const IMAGE_ITEM_HEIGHT = 276;
export const IMAGE_ITEM_WIDTH = 205;

const Wrapper = styled.div`
  width: ${IMAGE_ITEM_WIDTH}px;
  height: ${IMAGE_ITEM_HEIGHT}px;
`;

interface IImageItemProps {
  image: IImageDB;
  mode: GalleryListMode;
  onClickImage?(image: IImageDB): void;
}

export const LazyLoadImageItem = ({
  image,
  mode,
  onClickImage,
}: IImageItemProps): React.ReactElement<IImageItemProps> => {
  const dispatch = useDispatch();
  const { createConfirmation } = useConfirmation();
  const { createModal } = useModal();
  const { call } = useQueryWithPopupErrorHandling();

  const removeImage = async () => {
    createConfirmation(
      "Opravdu chcete odstranit fotku z galerie společně se všemi použitími?",
      async () => {
        call(
          () => REMOVE_IMAGE_BY_ID(image._id),
          "Odstranění fotky nebylo úspěšné",
          "Odstranění fotky bylo úspěšné",
          (action) => {
            dispatch(action);
          }
        );
      }
    );
  };

  const editImage = () => {
    createModal(<EditImage image={image} />);
  };

  const canChoose =
    mode === GalleryListMode.Choose ||
    mode === GalleryListMode.ChooseAndUpload ||
    mode === GalleryListMode.ChooseAndUploadOrManage;

  const handleOnClickWrapper = () => {
    if (!canChoose) return;

    onClickImage?.(image);
  };

  const date = new Date(image.date);

  const dateString = getLocaleDateStringWithHoursAndMinutes(date);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const colors = useColors();

  return (
    <Wrapper ref={ref}>
      <InputsWrapper
        hoverable={canChoose}
        onClick={handleOnClickWrapper}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {mode === GalleryListMode.Manage && (
          <>
            <DeleteButton
              data-title="Odstranit fotku"
              style={{
                position: "absolute",
                zIndex: 2,
                top: 9,
                padding: 4,
                right: 9,
              }}
              onClick={removeImage}
            />
          </>
        )}
        <ImageItemDate>{dateString}</ImageItemDate>
        <FlexCenterAll
          style={{
            position: "relative",
          }}
        >
          {mode === GalleryListMode.Manage && (
            <EditButton
              data-title="Upravit záznam"
              style={{
                zIndex: 10,
                padding: 4,
                marginLeft: -16,
                marginRight: 6,
              }}
              onClick={editImage}
            />
          )}
          <div
            style={{
              fontSize: 12,
              width: "75%",
              textAlign: "center",
              textOverflow: "ellipsis",
              overflow: "hidden",
              position: "relative",
              whiteSpace: "nowrap",
              fontWeight: 500,
            }}
          >
            {image.name}
          </div>
        </FlexCenterAll>

        <Flex>
          <FlexCenterAll
            style={{
              position: "relative",
              marginTop: 12,
              width: 196,
              height: 196,
            }}
          >
            <LazyLoadingGalleryImage disableEnlargement src={image.url} />
          </FlexCenterAll>
        </Flex>
      </InputsWrapper>
    </Wrapper>
  );
};
