import { IUserDB } from "../../types/user";
import { TUsersAction, UsersActions } from "../actions/usersActions";

export interface IUsersState {
  users: IUserDB[];
}

const initialUsersState: IUsersState = {
  users: [],
};

export const usersReducer = (
  state: IUsersState = initialUsersState,
  action: TUsersAction
): IUsersState => {
  switch (action.type) {
    case UsersActions.SET_USERS:
      return { ...state, users: action.payload };
    case UsersActions.EDIT_USER:
      let users = [...state.users],
        index = users.findIndex((i) => i._id === action.payload._id);

      users[index] = action.payload;

      return { ...state, users };
    case UsersActions.ADD_USER:
      return { ...state, users: [...state.users, action.payload] };
    case UsersActions.REMOVE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    default:
      return state;
  }
};
