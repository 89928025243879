import { IHistoryAction } from "./../../types/history";
import {
  HistoryActionsActions,
  THistoryActionsAction,
} from "../actions/historyActionsActions";

export interface IHistoryActionsState {
  historyActions: IHistoryAction[];
}

const initialHistoryActionsState: IHistoryActionsState = {
  historyActions: [],
};

export const historyActionsReducer = (
  state: IHistoryActionsState = initialHistoryActionsState,
  action: THistoryActionsAction
): IHistoryActionsState => {
  switch (action.type) {
    case HistoryActionsActions.SET_HISTORY_ACTIONS:
      return { ...state, historyActions: action.payload };
    default:
      return state;
  }
};
