import React from "react";
import styled from "styled-components";
import { useColors } from "../../../../providers/theme/theme-provider";
import { IThemeColors } from "../../../../providers/theme/themes";
import Button from "../../../../ui/button";
import { FlexCenterAlign } from "../../../../ui/common";

export enum AdminAddUserSelectedWindow {
  Info,
  Password,
  Permissions,
}

const WindowSelectorButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 8px;
  }
`;

const getBackgroundColor = (
  selectedWindow: AdminAddUserSelectedWindow,
  is: AdminAddUserSelectedWindow,
  colors: IThemeColors
): string | undefined => {
  return selectedWindow === is ? colors.MAIN_250 : undefined;
};

interface IProps {
  selectedWindow: AdminAddUserSelectedWindow;
  setSelectedWindow(window: AdminAddUserSelectedWindow): void;
  isForDefaultAdmin?: boolean;
}

export const WindowSelector = ({
  selectedWindow,
  setSelectedWindow,
  isForDefaultAdmin,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <FlexCenterAlign
      style={{
        margin: "0px 8px",
      }}
    >
      <WindowSelectorButtonsWrapper>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              AdminAddUserSelectedWindow.Info,
              colors
            ),
          }}
          onClick={() => setSelectedWindow(AdminAddUserSelectedWindow.Info)}
        >
          Informace
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              AdminAddUserSelectedWindow.Password,
              colors
            ),
          }}
          onClick={() => setSelectedWindow(AdminAddUserSelectedWindow.Password)}
        >
          Heslo
        </Button>
        {!isForDefaultAdmin && (
          <Button
            style={{
              backgroundColor: getBackgroundColor(
                selectedWindow,
                AdminAddUserSelectedWindow.Permissions,
                colors
              ),
            }}
            onClick={() =>
              setSelectedWindow(AdminAddUserSelectedWindow.Permissions)
            }
          >
            Práva
          </Button>
        )}
      </WindowSelectorButtonsWrapper>
    </FlexCenterAlign>
  );
};

export default WindowSelector;
