import { TLanguageSlug } from "../types/common";
import { ErrorType, TReduxError } from "../types/error";
import { TGetError } from "./redux-hooks";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.SLUG);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export interface ISlugErrorsObject {
  slug_value_invalid?: TReduxError;
  slug_value_empty?: TReduxError;
  slug_already_exists?: TReduxError;
}

interface ISlugErrors {
  errors: ISlugErrorsObject;
  isValid: boolean;
}

export const getSlugErrors = (
  slug: TLanguageSlug,
  getError: TGetError,
  locale: string
): ISlugErrors => {
  const slug_value_empty_params = getError("slug_value_empty", ErrorType.SLUG, {
    locale,
  })?.params!;

  const slug_value_empty = getErrorOfKey(
    "slug_value_empty",
    getError,
    slug_value_empty_params &&
      locale === slug_value_empty_params.locale &&
      !slug[locale].value
  );

  const slug_value_invalid_params =
    slug[locale] &&
    getError("slug_value_invalid", ErrorType.SLUG, {
      value: slug[locale].value,
      locale,
    })?.params!;

  const slug_value_invalid = getErrorOfKey(
    "slug_value_invalid",
    getError,
    slug_value_invalid_params &&
      locale === slug_value_invalid_params.locale &&
      slug_value_invalid_params.value === slug[locale].value
  );

  const slug_already_exists_params =
    slug[locale] &&
    getError("slug_already_exists", ErrorType.SLUG, {
      value: slug[locale].value,
      locale,
    })?.params!;

  const slug_already_exists = getErrorOfKey(
    "slug_already_exists",
    getError,
    slug_already_exists_params &&
      slug_already_exists_params.locale === locale &&
      slug_already_exists_params.value === slug[locale].value &&
      slug[locale].userDefined
  );

  return {
    errors: {
      slug_already_exists,
      slug_value_empty,
      slug_value_invalid,
    },
    isValid: !slug_already_exists && !slug_value_empty && !slug_value_invalid,
  };
};
