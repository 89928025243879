import React, { useState, useEffect } from "react";
import { useSelector } from "../../redux/store";
import Checkbox from "../../ui/checkbox";
import { Flex, FlexCenterAlign, InputsWrapper } from "../../ui/common";
import Input from "../../ui/input";
import ViewWrapper from "../view-wrapper";
import CategoriesList from "./categories-list/categories-list";
import CategoryActionButtons from "./category-action-buttons";
import { useAppConfig } from "../../common/redux-hooks";

const SHOW_SUBCATEGORIES_LOCALSTORAGE_KEY = "__showSubcategories";

interface IProps {}

export const Categories = ({}: IProps): React.ReactElement<IProps> => {
  const { populatedCategories } = useSelector((state) => state.categories);
  const [showSubcategories, setShowSubcategories] = useState(true);
  const [search, setSearch] = useState("");
  const { store } = useAppConfig();

  useEffect(() => {
    const showSubcategoriesItem = localStorage.getItem(
      SHOW_SUBCATEGORIES_LOCALSTORAGE_KEY
    );

    if (showSubcategoriesItem) {
      setShowSubcategories(!!JSON.parse(showSubcategoriesItem));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      SHOW_SUBCATEGORIES_LOCALSTORAGE_KEY,
      JSON.stringify(showSubcategories)
    );
  }, [showSubcategories]);

  return (
    <ViewWrapper>
      <CategoryActionButtons />
      <div
        style={{
          fontSize: 18,
          fontWeight: 500,
          padding: "10px 0",
          margin: "10px 0",
        }}
      >
        {showSubcategories
          ? `Seznam kategorií pro ${store.title}`
          : "Seznam všech kategorií"}
      </div>
      <Flex>
        <InputsWrapper>
          <FlexCenterAlign>
            <div
              style={{
                marginRight: 8,
                fontSize: 12,
              }}
            >
              Ukázat všechny kategorie
            </div>
            <Checkbox
              disabled={populatedCategories.length === 0}
              setValue={(value) => setShowSubcategories(!value)}
              value={!showSubcategories}
            />
          </FlexCenterAlign>
        </InputsWrapper>
      </Flex>

      <Input
        disabled={populatedCategories.length === 0}
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vyhledat kategorie"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <CategoriesList query={search} showSubcategories={showSubcategories} />
    </ViewWrapper>
  );
};

export default Categories;
