import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import {
  useErrors
} from "../../../common/redux-hooks";
import { orderModel } from "../../../model/query/orderModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useDispatch } from "../../../redux/store";
import {
  EditorType
} from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { IOrder, IOrderItem, OrderState } from "../../../types/order";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import {
  AddButton,
  FlexCenterAlign,
  FlexCenterAll,
  RemoveButton,
  RemoveButtonStyles,
} from "../../../ui/common";
import BackButton from "../../components/back-button";
import { getOrderEditorErrors } from "./editor-errors";

const mapOrderToEditorOrder = (order: IOrder): IEditorOrder => {
  const { state, items } = order;

  return {
    state,
    items,
  };
};

const initialEditorOrder: IEditorOrder = {
  state: OrderState.WaitingForPayment,
  items: [],
};

export interface IEditorOrder {
  state: OrderState;
  items: IOrderItem[];
}

interface IProps {
  type: EditorType;
}

export const OrderEditor = ({ type }: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const [order, setOrder] = useState<IEditorOrder>(initialEditorOrder);

  const { closeNotification } = useNotifications();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getOrderEditorErrors(getError, order);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useQueryWithPopupErrorHandling();
  const { createConfirmation } = useConfirmation();

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.PAYMENT);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getOrder = async (id: string) => {
      call(
        () => orderModel.getOrderById(id),
        "Zobrazení platební metody nebylo úspěšné",
        null,
        (order) => {
          const c = mapOrderToEditorOrder(order);

          setOrder(c);
        },
        () => {
          navigate("/orders");
        }
      );
    };

    if (_id && type === EditorType.Edit) {
      getOrder(_id);
    }
  }, [_id, type]);

  const appendOrderToForm = useCallback(
    (form: FormData) => {
      form.append("state", order.state);
      // form.append(
      //   "items",
      //   JSON.stringify(
      //     order.items.map((o) => ({
      //       product: o.product._id,
      //       price_vat: o.price_vat,
      //       quantity: o.quantity,
      //       discount_price: o.discount_price,
      //     }))
      //   )
      // );
    },
    [order]
  );

  const editOrder = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit platební metodu", async () => {
      if (!_id) {
        return;
      }

      const data = new FormData();

      data.append("_id", _id);
      appendOrderToForm(data);

      call(
        () => orderModel.editOrder(data),
        "Úprava objednváky nebyla úspěšná",
        "Úprava objednváky byla úspěšná",
        (action) => {
          if (exit) {
            navigate("/orders");
          } else {
            setOrder(mapOrderToEditorOrder(action));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeOrder = () => {
    createConfirmation("Potvrzení: Odstranit objednávku", async () => {
      if (!_id) {
        return;
      }

      call(
        () => orderModel.removeOrderById(_id),
        "Odstranění objednávky nebylo úspěšné",
        "Odstranění objednávky bylo úspěšné",
        (action) => {
          if (action) {
            navigate("/orders");
          }
        }
      );
    });
  };

  const node = (
    <FlexCenterAll>
      <FlexCenterAlign>
        <div
          style={{
            marginRight: 16,
          }}
        >
          <AddButton disabled={!isValid} onClick={() => editOrder()}>
            Uložit
          </AddButton>
        </div>
        <div
          style={{
            marginRight: 16,
          }}
        >
          <AddButton disabled={!isValid} onClick={() => editOrder(true)}>
            Uložit a zavřít
          </AddButton>
        </div>
        <RemoveButton
          style={{
            ...RemoveButtonStyles,
            display: "block",
          }}
          onClick={removeOrder}
        >
          Odstranit
        </RemoveButton>
      </FlexCenterAlign>
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper node={node} deps={[isValid]}>
      <div>
        <BackButton path="/orders" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          Úprava objednávky
        </div>
      </div>
    </BottomFixedWrapperWrapper>
  );
};

export default OrderEditor;
