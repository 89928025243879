import { IImageDB } from "../../types/gallery";
import { IUserDB } from "../../types/user";
import axios from "../axios";

interface ILoginUserResponse {
  token: string;
}

interface IUploadProfileImageResponse {
  token: string;
  image: IImageDB;
}

export interface IUserModel {
  loginUser: (data: FormData) => Promise<ILoginUserResponse>;
  checkAuth: () => Promise<boolean>;
  logoutUser: (id: string) => Promise<boolean>;
  changePassword: (data: FormData) => Promise<ILoginUserResponse>;
  editUserInfo: (data: FormData) => Promise<ILoginUserResponse>;
  adminAddUser: (data: FormData) => Promise<IUserDB>;
  adminChangePassword: (data: FormData) => Promise<boolean>;
  adminEditUserInfo: (data: FormData) => Promise<IUserDB>;
  adminEditUserPermissions: (data: FormData) => Promise<IUserDB>;
  adminRemoveUser: (id: string) => Promise<boolean>;
  uploadProfileImage: (
    data: FormData
  ) => Promise<IUploadProfileImageResponse | false>;
}

export const userModel: IUserModel = {
  checkAuth() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/user/auth`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  loginUser(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILoginUserResponse>(
          `/user/login`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  logoutUser(id) {
    return new Promise(async (res, rej) => {
      const response = await axios.post<boolean>(`/user/logout`, {
        id,
      });

      res(response.data);
    });
  },
  editUserInfo(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILoginUserResponse>(
          `/user/edit-user-info`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  changePassword(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILoginUserResponse>(
          `/user/change-password`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  uploadProfileImage(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IUploadProfileImageResponse | false>(
          `/user/upload-profile-image`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  adminChangePassword(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(
          `/user/admin-change-password`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  adminAddUser(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IUserDB>(
          `/user/admin-add-user`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  adminEditUserInfo(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IUserDB>(
          `/user/admin-edit-user-info`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  adminEditUserPermissions(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IUserDB>(
          `/user/admin-edit-user-permissions`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  adminRemoveUser(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.delete<boolean>(
          `/user/admin-remove-user`,
          {
            params: {
              _id,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
