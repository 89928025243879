import React, { useEffect, useState, useRef } from "react";
import { VscFilter } from "react-icons/vsc";
import {
  NavigateOptions,
  useSearchParams,
  URLSearchParamsInit,
} from "react-router-dom";
import {
  useAppConfig,
  useProductFilterQuery,
  useProductFilters,
  useProducts,
} from "../../common/redux-hooks";
import { useModal } from "../../providers/modal/modal-provider";
import { useColors } from "../../providers/theme/theme-provider";
import {
  SET_PRODUCT_FILTERS,
  SET_PRODUCT_QUERY,
} from "../../redux/actions/filterActions";
import { useDispatch } from "../../redux/store";
import {
  initialProductFilters,
  IProduct,
  IProductFilters,
} from "../../types/products";
import Checkbox from "../../ui/checkbox";
import {
  DeleteButton,
  Flex,
  FlexCenterAlign,
  InputsWrapper,
} from "../../ui/common";
import IconButton from "../../ui/icon-button";
import { InputWithTitle } from "../../ui/input-with-title";
import { ICategory, IPopulatedCategoryId } from "./../../types/category";
import FiltersModal from "./filters/filters-modal";
import ProductsList from "./products-list";

interface IProductSearchModalProps {
  onSelect(product: IProduct): void;
  disabledIDs?: string[];
}

export const ProductSearchModal = ({
  onSelect,
  disabledIDs,
}: IProductSearchModalProps) => {
  const { closeModal } = useModal();

  return (
    <div
      style={{
        maxHeight: "90vh",
        overflow: "auto",
        marginRight: 40,
        maxWidth: "90vw",
      }}
    >
      <ProductSearch
        disabledIDs={disabledIDs}
        onSelect={(product) => {
          onSelect(product);
          closeModal();
        }}
      />
    </div>
  );
};

const SearchInfo = () => {
  return (
    <div
      style={{
        textAlign: "left",
      }}
    >
      <div
        style={{
          fontSize: 14,
          marginBottom: 6,
          fontWeight: 500,
        }}
      >
        Rychle hlédání
      </div>
      <div>
        Pole označíme jako <b>NázevPole:</b>
      </div>
      <div
        style={{
          marginTop: 4,
        }}
      >
        Znak <b>;</b> odděluje vyhledávaná pole
      </div>
      <div
        style={{
          marginTop: 4,
        }}
      >
        Znak <b>+</b> přidává hodnoty pro vyhledávané pole
      </div>
      <div
        style={{
          marginTop: 8,
        }}
      >
        <b> EAN: 11+22;Cena: 30</b>
      </div>
      <div>
        Vyhledá produkty, které mají EAN <b>11</b> nebo <b>22</b> a cenu{" "}
        <b>30</b>
      </div>
    </div>
  );
};

export const getCategoryIDsWithChildSubcategories = (
  selected: string[],
  allCategories: IPopulatedCategoryId[]
): string[] => {
  let ids: string[] = [];

  selected.forEach((id) => {
    const s = allCategories.find((c) => c._id === id);

    if (s && s.subcategories.length > 0) {
      selected.push(
        ...getCategoryIDsWithChildSubcategories(
          s.subcategories.map((c) => c._id),
          allCategories
        )
      );
    }
  });

  for (let i = 0; i < selected.length; i++) {
    if (!ids.includes(selected[i])) {
      ids.push(selected[i]);
    }
  }

  return ids;
};

function getParams(query: string): { [key: string]: string[] } | string {
  if (!query.includes(":")) {
    return query;
  }

  const paramArray = query.split(";");
  const params: { [key: string]: string[] } = {};

  for (let i = 0; i < paramArray.length; i++) {
    const [paramKey, paramValue] = paramArray[i].split(":");

    if (!paramValue) {
      continue;
    }

    const trimmedParamKey = paramKey.trim().toLowerCase();
    const trimmedParamValue = paramValue.trim();

    if (trimmedParamValue.includes("+")) {
      params[trimmedParamKey] = trimmedParamValue
        .split("+")
        .map((v) => v.trim())
        .filter((v) => v !== "");
    } else if (trimmedParamValue !== "") {
      params[trimmedParamKey] = [trimmedParamValue];
    }
  }

  return params;
}

interface IKnownParamProps {
  paramKey: string;
  params: {
    [key: string]: string[];
  };
  title: string;
}

const KnownParam = ({ title, params, paramKey }: IKnownParamProps) => {
  return (
    <div
      style={{
        fontSize: 11,
        marginBottom: 8,
      }}
    >
      <div
        style={{
          fontSize: 12,
          fontWeight: 500,
        }}
      >
        {title}
      </div>
      {params[paramKey].map((p, i) => (
        <span key={i}>
          {p}{" "}
          {i !== params[paramKey].length - 1 && (
            <span
              style={{
                opacity: 0.6,
              }}
            >
              nebo{" "}
            </span>
          )}
        </span>
      ))}
    </div>
  );
};

const getKnownParamsNodes = (params: {
  [key: string]: string[];
}): React.ReactNode[] => {
  const knownParamsArray: React.ReactNode[] = [];

  const knownKeys = [
    "ean",
    "název",
    "cena",
    "nazev",
    "vyrobce",
    "výrobce",
    "kod",
    "kód",
  ];

  Object.keys(params).forEach((key) => {
    if (knownKeys.includes(key)) {
      switch (key) {
        case "ean":
          if (params[key]) {
            knownParamsArray.push(
              <KnownParam paramKey={key} params={params} title="EAN" />
            );
          }
          break;
        case "název":
        case "nazev":
          knownParamsArray.push(
            <KnownParam paramKey={key} params={params} title="Název" />
          );
          break;
        case "vyrobce":
        case "výrobce":
          knownParamsArray.push(
            <KnownParam paramKey={key} params={params} title="Výrobce" />
          );
          break;
        case "cena":
          knownParamsArray.push(
            <KnownParam paramKey={key} params={params} title="Cena vč. DPH" />
          );
          break;
        case "kod":
        case "kód":
          knownParamsArray.push(
            <KnownParam paramKey={key} params={params} title="Kód" />
          );
          break;
        default:
          break;
      }
    }
  });

  return knownParamsArray.map((el) => <>{el}</>);
};

interface IProps {
  searchParams?: URLSearchParams;
  setSearchParams?(
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions
  ): void;
  onSelect?(product: IProduct): void;
  disabledIDs?: string[];
}

export const ProductSearch = ({
  searchParams,
  setSearchParams,
  onSelect,
  disabledIDs,
}: IProps): React.ReactElement<IProps> => {
  const products = useProducts();
  const filters = useProductFilters();
  const dispatch = useDispatch();
  const { store } = useAppConfig();

  const i = searchParams?.get("i")! || false;
  const e = searchParams?.get("e")! || false;

  useEffect(() => {
    const search = searchParams?.get("q");

    if (search) {
      dispatch(SET_PRODUCT_QUERY(search));
    }
  }, []);

  const query = useProductFilterQuery();
  const [includeSubcategoryProducts, setIncludeSubcategoryProducts] = useState(
    !i
  );
  const [selectedStoreOnly, setSelectedStoreOnly] = useState(true);
  const [mustExactlyMatchQuery, setMustExactlyMatchQuery] = useState(!!e);

  useEffect(() => {
    setSearchParams?.((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);

      if (query) {
        newSearchParams.set("q", query);
      } else {
        newSearchParams.delete("q");
      }

      if (!includeSubcategoryProducts) {
        newSearchParams.set("i", "1");
      } else {
        newSearchParams.delete("i");
      }

      if (mustExactlyMatchQuery) {
        newSearchParams.set("e", "1");
      } else {
        newSearchParams.delete("e");
      }

      return newSearchParams;
    });
  }, [query, includeSubcategoryProducts, mustExactlyMatchQuery]);

  const params = getParams(query);
  const knownParamsNodes =
    typeof params !== "string" ? getKnownParamsNodes(params) : null;

  const colors = useColors();
  const { createModal } = useModal();

  const removeFilters = () => {
    dispatch(SET_PRODUCT_FILTERS(null));
  };

  const showFilters = () => {
    createModal(<FiltersModal />);
  };

  return (
    <div>
      <Flex
        style={{
          marginBottom: 20,
          marginTop: 20,
          alignItems: "flex-start",
        }}
      >
        <InputsWrapper
          style={{
            width: 360,
          }}
        >
          <InputWithTitle
            inputProps={{
              placeholder: "Aa",
            }}
            inputStyle={{
              width: "100%",
            }}
            wrapperStyle={{
              width: "100%",
              marginTop: 0,
              marginBottom: 4,
            }}
            setValue={(query) => dispatch(SET_PRODUCT_QUERY(query))}
            value={query}
            title="Vyhledat produkt"
            info={<SearchInfo />}
            infoWidth={300}
          />

          {knownParamsNodes?.length ? (
            <div
              style={{
                marginLeft: 8,
              }}
            >
              {knownParamsNodes}
            </div>
          ) : null}

          <FlexCenterAlign
            style={{
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            <Checkbox
              disabled={!knownParamsNodes?.length || products.length === 0}
              setValue={setMustExactlyMatchQuery}
              value={mustExactlyMatchQuery}
            />{" "}
            <div style={{ fontSize: 10, marginLeft: 8 }}>
              Rychlé hledání musí být přesné
            </div>
          </FlexCenterAlign>

          <FlexCenterAlign
            style={{
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            <Checkbox
              disabled={products.length === 0}
              setValue={setIncludeSubcategoryProducts}
              value={includeSubcategoryProducts}
            />{" "}
            <div style={{ fontSize: 10, marginLeft: 8 }}>
              Z vybraných kategorií zobrazit i produkty v podkategoriích
            </div>
          </FlexCenterAlign>

          <FlexCenterAlign
            style={{
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            <Checkbox
              setValue={setSelectedStoreOnly}
              value={selectedStoreOnly}
            />{" "}
            <div style={{ fontSize: 10, marginLeft: 8 }}>
              Zobrazit produkty pouze pro obchod {store.title_lowercase}
            </div>
          </FlexCenterAlign>
        </InputsWrapper>

        <FlexCenterAlign>
          <IconButton
            onClick={showFilters}
            data-title="Filtrovat produkty"
            style={{
              marginLeft: 8,
              backgroundColor: colors.MAIN_350,
            }}
            hoverBackgroundColor={colors.MAIN_300}
          >
            <VscFilter size={24} color={colors.OPPOSITE_MAIN_400} />
          </IconButton>
          {filters && (
            <DeleteButton
              style={{
                marginLeft: 8,
              }}
              data-title="Odstranit filtry"
              onClick={removeFilters}
            />
          )}
        </FlexCenterAlign>
      </Flex>

      <ProductsList
        disabledIDs={disabledIDs}
        selectedStoreOnly={selectedStoreOnly}
        mustExactlyMatchQuery={mustExactlyMatchQuery}
        onSelect={onSelect}
      />
    </div>
  );
};

export default ProductSearch;
