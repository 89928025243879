import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import styled from "styled-components";
import {
  useAppConfigWithLoading,
  useLanguages,
  useLoggedIn,
  useStores,
  useUser,
} from "../common/redux-hooks";
import { useLoadingWithOverlay } from "../providers/loading-with-overlay/loading-with-overlay-provider";
import {
  SET_APP_CONFIG,
  appConfigLanguageLocalStorageKey,
  appConfigStoreLocalStorageKey,
} from "../redux/actions/appConfigActions";
import { LOGOUT_USER } from "../redux/actions/userActions";
import { AppConfigEnum } from "../redux/reducers/appConfigReducer";
import { useDispatch } from "../redux/store";
import ServerStatusWrapper from "../server-status-wrapper";
import AppConfigDropdown from "../ui/app-config-dropdown";
import LoadingScreen from "../view/components/loading-screen";
import LandingPage from "../view/landing-page";
import LoginPage from "../view/login";
import Settings from "../view/settings";
import Sidebar, { SidebarContext } from "../view/sidebar/sidebar";
import AppLogicResolver from "./app-logic-resolver";
import { FETCH_AND_SET_ALL } from "./fetch-and-set";
import { wrapWithPermission } from "./permission-wrapper";
import {
  AdminRoutes,
  AdsRoutes,
  AnalyticsRoutes,
  CategoryRoutes,
  ComplaintsInfoRoutes,
  CouponsRoutes,
  CurrencyRoutes,
  EmailRoutes,
  GalleryRoutes,
  LanguagesRoutes,
  ManufacturerRoutes,
  OrderRoutes,
  ParameterGroupRoutes,
  ParameterRoutes,
  PaymentRoutes,
  PrivacyPolicyRoutes,
  ProductRoutes,
  ShippingRoutes,
  StoresRoutes,
  TermsAndConditionsRoutes,
  TestingRoutes,
  UserRoutes,
} from "./routes";

const SidebarPaddingWrapper = styled.div`
  float: left;
`;

const RouterWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  overflow: hidden;

  @media (max-width: 800px) {
    overflow: auto !important;
  }
`;

export const Router = () => {
  const dispatch = useDispatch();
  const logged = useLoggedIn();
  const user = useUser();
  const stores = useStores();
  const languages = useLanguages();
  const appConfig = useAppConfigWithLoading();

  const { showLoading, closeLoading } = useLoadingWithOverlay();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    showLoading();
    FETCH_AND_SET_ALL(user, dispatch, logged, () => {
      setLoading(false);
      closeLoading();
    });
  }, [dispatch, logged, user]);

  useEffect(() => {
    if (appConfig !== AppConfigEnum.IsLoading) {
      return;
    }

    if (languages.length && stores.length) {
      const languageLocalStorageItem = localStorage.getItem(
        appConfigLanguageLocalStorageKey
      );
      const storeLocalStorageItem = localStorage.getItem(
        appConfigStoreLocalStorageKey
      );

      const lsLanguage = languages.find(
        (lang) => lang._id === languageLocalStorageItem
      );

      const lsStore = stores.find(
        (store) => store._id === storeLocalStorageItem
      );

      if (lsLanguage && lsStore) {
        if (
          lsStore.languages.find(
            (storeLanguage) => storeLanguage._id === lsLanguage._id
          )
        ) {
          dispatch(SET_APP_CONFIG({ language: lsLanguage, store: lsStore }));
          return;
        }

        dispatch(
          SET_APP_CONFIG({ language: lsStore.languages[0], store: lsStore })
        );
        return;
      }

      const defaultStore = stores.find((store) => store.isDefaultStore);

      if (!defaultStore) {
        throw new Error("No default store is defined!");
      }

      dispatch(
        SET_APP_CONFIG({
          language: defaultStore.languages[0],
          store: defaultStore,
        })
      );
    } else if (!stores.length && !loading) {
      dispatch(LOGOUT_USER());
    }
  }, [languages, stores, appConfig]);

  const [sidebarWidth, setSidebarWidth] = useState(0);

  if (loading || (appConfig === AppConfigEnum.IsLoading && logged)) {
    return <LoadingScreen />;
  }

  return (
    <RouterWrapper>
      <BrowserRouter>
        <ServerStatusWrapper>
          <AppLogicResolver />

          <SidebarContext.Provider
            value={{
              sidebarWidth,
            }}
          >
            {logged && (
              <>
                <Sidebar setSidebarWidth={setSidebarWidth} />
                <AppConfigDropdown />
              </>
            )}
            <SidebarPaddingWrapper
              style={{
                paddingLeft: 20,
                width: `calc(100% - ${sidebarWidth}px)`,
                minWidth: logged ? `calc(1280px - ${sidebarWidth}px)` : 0,
                minHeight: logged ? 960 : 0,
              }}
            >
              <Routes>
                <Route path="/">
                  <Route
                    index
                    element={wrapWithPermission(
                      <LandingPage />,
                      undefined,
                      logged
                    )}
                  />
                </Route>

                <Route path="/settings">
                  <Route
                    index
                    element={wrapWithPermission(
                      <Settings />,
                      undefined,
                      logged
                    )}
                  />
                </Route>

                <Route path="/products/*" element={<ProductRoutes />} />
                <Route path="/categories/*" element={<CategoryRoutes />} />
                <Route path="/parameters/*" element={<ParameterRoutes />} />
                <Route
                  path="/parameter-groups/*"
                  element={<ParameterGroupRoutes />}
                />
                <Route
                  path="/manufacturers/*"
                  element={<ManufacturerRoutes />}
                />
                <Route path="/languages/*" element={<LanguagesRoutes />} />
                <Route path="/stores/*" element={<StoresRoutes />} />
                <Route path="/currencies/*" element={<CurrencyRoutes />} />
                <Route path="/payments/*" element={<PaymentRoutes />} />
                <Route path="/orders/*" element={<OrderRoutes />} />
                <Route path="/analytics/*" element={<AnalyticsRoutes />} />
                <Route path="/shippings/*" element={<ShippingRoutes />} />
                <Route path="/emails/*" element={<EmailRoutes />} />
                <Route path="/ads/*" element={<AdsRoutes />} />
                <Route
                  path="/terms-and-conditions/*"
                  element={<TermsAndConditionsRoutes />}
                />
                <Route path="/coupons/*" element={<CouponsRoutes />} />
                <Route
                  path="/complaints-info/*"
                  element={<ComplaintsInfoRoutes />}
                />
                <Route
                  path="/privacy-policy/*"
                  element={<PrivacyPolicyRoutes />}
                />
                <Route path="/gallery/*" element={<GalleryRoutes />} />
                <Route path="/testing/*" element={<TestingRoutes />} />
                <Route path="/admin/*" element={<AdminRoutes />} />
                <Route path="/profile/*" element={<UserRoutes />} />

                <Route path="/login">
                  <Route index element={<LoginPage />} />
                </Route>

                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </SidebarPaddingWrapper>
          </SidebarContext.Provider>
        </ServerStatusWrapper>
      </BrowserRouter>
    </RouterWrapper>
  );
};

export default Router;
