import { default as axiosModule } from "axios";
import Cookies from "js-cookie";
import { API_URL } from "./../constants/api";

const axios = axiosModule.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
