import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { AiFillShopping, AiOutlineShop } from "react-icons/ai";
import { BiCategoryAlt } from "react-icons/bi";
import { BsCurrencyDollar } from "react-icons/bs";
import { FaHome, FaLanguage, FaStore } from "react-icons/fa";
import { FiChevronDown, FiPackage } from "react-icons/fi";
import { IoMdAnalytics, IoMdImages } from "react-icons/io";
import { RiAdminFill, RiTestTubeLine } from "react-icons/ri";
import { VscSymbolParameter } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useLoggedIn, useRole } from "../../common/redux-hooks";
import { useColors } from "../../providers/theme/theme-provider";
import { isDisabledByRole } from "../../types/role";
import { FlexCenterAlign } from "../../ui/common";
import IconButton, { IconStyledWrapper } from "../../ui/icon-button";
import SidebarLogout from "./logout";
import SettingsButton from "./settings-button";
import { SidebarUser } from "./user";

const SidebarButton = styled.div<{ active: boolean }>`
  width: 100%;
  transition: background-color 100ms ease;
  padding: 2px;
  cursor: pointer;

  ${(props) => css`
    border-radius: ${props.theme.borderRadius}px;

    &:hover {
      background-color: ${props.active
        ? props.theme.colors.MAIN_300
        : props.theme.colors.MAIN_350};
    }
  `}

  ${({ active, theme }) => css`
    & > div > div:first-child {
      transition: background-color 100ms ease;
      border-radius: ${theme.borderRadius}px;
      background-color: unset;
    }

    background-color: ${active ? theme.colors.MAIN_300 : "unset"};
  `}
`;

const SidebarButtonChild = styled.button<{ active: boolean }>`
  width: 100%;
  transition: background-color 100ms ease;
  padding: 2px;

  ${(props) => css`
    border-radius: ${props.theme.borderRadius}px;

    &:hover {
      background-color: ${props.active
        ? props.theme.colors.MAIN_200
        : props.theme.colors.MAIN_250};
    }
  `}

  ${({ active, theme }) => css`
    background-color: ${active ? theme.colors.MAIN_300 : "unset"};
  `}

  &:focus {
    box-shadow: unset !important;
  }
`;

const SidebarButtonText = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.OPPOSITE_MAIN_400};
  `}

  font-family: "Source Sans Pro";
  letter-spacing: 0.03em;
  margin-left: 8px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  padding-right: 12px;
`;

interface ISidebarItemChildProps {
  path: string;
  text: string;
}

const SidebarItemChild = ({ path, text }: ISidebarItemChildProps) => {
  const navigate = useNavigate(),
    location = useLocation();

  return (
    <SidebarButtonChild
      style={{
        padding: "12px 0",
      }}
      active={
        path === "/"
          ? location.pathname === path
          : location.pathname.includes(path)
      }
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        navigate(path);
      }}
    >
      <SidebarButtonText>{text}</SidebarButtonText>
    </SidebarButtonChild>
  );
};

const SidebarItemChildWrapper = styled.div`
  margin-top: 8px;
`;

interface ISidebarItemProps {
  path: string;
  text: string;
  icon: React.ReactNode;
  children?: React.ReactNode[];
}

const SidebarItem = ({ path, text, icon, children }: ISidebarItemProps) => {
  const navigate = useNavigate(),
    location = useLocation(),
    colors = useColors();

  const [expanded, setExpanded] = useState(false);

  const onChildrenExpandClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setExpanded((expanded) => !expanded);
  };

  const isSelected =
    path === "/"
      ? location.pathname === path
      : location.pathname.includes(path);

  return (
    <SidebarButton
      className="sidebar-button"
      active={isSelected}
      onClick={() => navigate(path)}
    >
      <FlexCenterAlign>
        <IconStyledWrapper
          style={{
            padding: 8,
          }}
        >
          {icon}
        </IconStyledWrapper>
        <FlexCenterAlign
          style={{
            justifyContent: "space-between",
            width: "100%",
            paddingRight: 8,
          }}
        >
          <SidebarButtonText>{text}</SidebarButtonText>

          {children && (
            <IconButton
              hoverBackgroundColor={
                isSelected ? colors.MAIN_200 : colors.MAIN_300
              }
              style={{
                backgroundColor: isSelected ? colors.MAIN_250 : colors.MAIN_350,
                padding: 2,
              }}
              onClick={onChildrenExpandClick}
            >
              <FiChevronDown size={20} color={colors.OPPOSITE_MAIN_450} />
            </IconButton>
          )}
        </FlexCenterAlign>
      </FlexCenterAlign>

      {children && expanded && (
        <SidebarItemChildWrapper>
          {children.map((child) => child)}
        </SidebarItemChildWrapper>
      )}
    </SidebarButton>
  );
};

interface ISidebarContext {
  sidebarWidth: number;
}

export const SidebarContext = React.createContext<ISidebarContext>(
  {} as ISidebarContext
);

export const useSidebar = () => {
  return React.useContext(SidebarContext);
};

const SidebarWrapper = styled.div`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_400};
  `}

  float: left;
  padding: 20px 12px;
  min-height: 100vh;
  width: 200px;
  display: flex;
  flex-direction: column;
`;

const SidebarItemsWrapper = styled.div`
  & > .sidebar-button {
    margin-top: 16px;
  }
`;

interface IProps {
  setSidebarWidth(width: number): void;
}

export const Sidebar = ({
  setSidebarWidth,
}: IProps): React.ReactElement<IProps> => {
  const role = useRole();
  const logged = useLoggedIn();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current?.clientWidth && logged) {
      setSidebarWidth(wrapperRef.current.clientWidth);
    } else if (!logged) {
      setSidebarWidth(0);
    }
  }, [wrapperRef.current, logged]);

  const colors = useColors();

  return (
    <SidebarWrapper ref={wrapperRef}>
      <div>
        <SettingsButton />

        <SidebarUser />
        <SidebarItemsWrapper>
          <SidebarItem
            text="Domov"
            path="/"
            icon={<FaHome size={26} color={colors.OPPOSITE_MAIN_400} />}
          />
          {!isDisabledByRole(role, {
            type: "product",
          }) && (
            <SidebarItem
              text="Produkty"
              path="/products"
              icon={
                <AiFillShopping size={26} color={colors.OPPOSITE_MAIN_400} />
              }
            />
          )}
          {!isDisabledByRole(role, { type: "category" }) && (
            <SidebarItem
              text="Kategorie"
              path="/categories"
              icon={
                <BiCategoryAlt size={26} color={colors.OPPOSITE_MAIN_400} />
              }
            />
          )}
          {!isDisabledByRole(role, { type: "parameter" }) && (
            <SidebarItem
              text="Parametry"
              path="/parameters"
              icon={
                <VscSymbolParameter
                  size={26}
                  color={colors.OPPOSITE_MAIN_400}
                />
              }
              children={[
                <SidebarItemChild
                  key="parameter-groups"
                  path="parameter-groups"
                  text="Parametrové skupiny"
                />,
              ]}
            />
          )}
          {!isDisabledByRole(role, { type: "gallery" }) && (
            <SidebarItem
              text="Galerie"
              path="/gallery"
              icon={<IoMdImages size={26} color={colors.OPPOSITE_MAIN_400} />}
            />
          )}
          {!isDisabledByRole(role, { type: "store" }) && (
            <SidebarItem
              text="Obchody"
              path="/stores"
              icon={<FaStore size={26} color={colors.OPPOSITE_MAIN_400} />}
              children={[
                isDisabledByRole(role, { type: "email" }) ? (
                  <></>
                ) : (
                  <SidebarItemChild key="emails" path="emails" text="Emaily" />
                ),
                // isDisabledByRole(role, { type: "email" }) ? (
                //   <></>
                // ) : (
                //   <SidebarItemChild key="ads" path="ads" text="Reklamy" />
                // ),
                isDisabledByRole(role, { type: "shipping" }) ? (
                  <></>
                ) : (
                  <SidebarItemChild
                    key="shippings"
                    path="shippings"
                    text="Doprava"
                  />
                ),
                isDisabledByRole(role, { type: "payment" }) ? (
                  <></>
                ) : (
                  <SidebarItemChild
                    key="payments"
                    path="payments"
                    text="Platební metody"
                  />
                ),
                isDisabledByRole(role, { type: "terms_and_conditions" }) ? (
                  <></>
                ) : (
                  <SidebarItemChild
                    key="terms-and-conditions"
                    path="terms-and-conditions"
                    text="Obchodní podmínky"
                  />
                ),
                isDisabledByRole(role, { type: "terms_and_conditions" }) ? (
                  <></>
                ) : (
                  <SidebarItemChild
                    key="complaints-info"
                    path="complaints-info"
                    text="Reklamační řád"
                  />
                ),
                isDisabledByRole(role, { type: "terms_and_conditions" }) ? (
                  <></>
                ) : (
                  <SidebarItemChild
                    key="privacy-policy"
                    path="privacy-policy"
                    text="Podmínky ochrany osobních údajů"
                  />
                ),
                isDisabledByRole(role, { type: "coupons" }) ? (
                  <></>
                ) : (
                  <SidebarItemChild
                    key="coupons"
                    path="coupons"
                    text="Slevové kupóny"
                  />
                ),
              ]}
            />
          )}
          {!isDisabledByRole(role, { type: "order" }) && (
            <SidebarItem
              text="Objednávky"
              path="/orders"
              icon={
                <AiOutlineShop size={26} color={colors.OPPOSITE_MAIN_400} />
              }
            />
          )}
          {!isDisabledByRole(role, { type: "manufacturer" }) && (
            <SidebarItem
              text="Výrobci"
              path="/manufacturers"
              icon={<FiPackage size={26} color={colors.OPPOSITE_MAIN_400} />}
            />
          )}
          {!isDisabledByRole(role, { type: "language" }) && (
            <SidebarItem
              text="Jazyky"
              path="/languages"
              icon={<FaLanguage size={26} color={colors.OPPOSITE_MAIN_400} />}
              children={[
                <SidebarItemChild
                  key="currencies"
                  path="currencies"
                  text="Měny"
                />,
              ]}
            />
          )}
          {!isDisabledByRole(role, { type: "order" }) && (
            <SidebarItem
              text="Analytika"
              path="/analytics"
              icon={
                <IoMdAnalytics size={26} color={colors.OPPOSITE_MAIN_400} />
              }
            />
          )}
          {!isDisabledByRole(role, { type: "user" }) && (
            <SidebarItem
              text="Testování"
              path="/testing"
              icon={
                <RiTestTubeLine size={26} color={colors.OPPOSITE_MAIN_400} />
              }
            />
          )}
          {!isDisabledByRole(role, { type: "user" }) && (
            <SidebarItem
              text="Admin"
              path="/admin"
              icon={<RiAdminFill size={26} color={colors.OPPOSITE_MAIN_400} />}
            />
          )}
        </SidebarItemsWrapper>
      </div>

      <div
        style={{
          marginTop: 100,
        }}
      >
        <SidebarLogout />
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
