import React from "react";
import { AiFillInteraction } from "react-icons/ai";
import { BiX } from "react-icons/bi";
import styled, { css } from "styled-components";
import { useModal } from "../../providers/modal/modal-provider";
import { useColors } from "../../providers/theme/theme-provider";
import { IProduct } from "../../types/products";
import Button from "../../ui/button";
import { FlexCenterAlign } from "../../ui/common";
import GroupActionsModal from "./group-actions/group-actions-modal";

export type TSelectedProduct = {
  id: string;
  value: IProduct;
};

interface IProps {
  selectedProducts: TSelectedProduct[];
  setSelectedProducts(products: TSelectedProduct[]): void;
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const SelectedProductsWrapper = styled(FlexCenterAlign)`
  flex-wrap: wrap;
  max-width: 400px;

  & > div {
    margin-right: 8px;
    margin-top: 8px;
  }
`;

export const SelectedProductItem = styled.div`
  ${({ theme }) => css`
    font-size: 13px;
    background-color: ${theme.colors.MAIN_400};
    padding: 6px 10px;
    border-radius: ${theme.borderRadius}px;
  `}
`;

export const SelectedProducts = ({
  selectedProducts,
  setSelectedProducts,
}: IProps): React.ReactElement<IProps> => {
  const { createModal } = useModal();

  const groupActionClick = () => {
    createModal(<GroupActionsModal selectedProducts={selectedProducts} />);
  };

  const colors = useColors();

  if (!selectedProducts.length) {
    return <></>;
  }

  return (
    <Wrapper>
      <div
        style={{
          fontSize: 13,
          marginBottom: 12,
          marginLeft: 2,
        }}
      >
        Vybrané produkty
      </div>
      <FlexCenterAlign>
        <SelectedProductsWrapper>
          {selectedProducts.map((p, i) => (
            <SelectedProductItem key={i}>
              {p.value.eshop_id}
            </SelectedProductItem>
          ))}
        </SelectedProductsWrapper>
        <Button
          onClick={groupActionClick}
          style={{
            marginLeft: 12,
            paddingLeft: 8,
          }}
        >
          <FlexCenterAlign>
            <AiFillInteraction
              size={21}
              style={{
                marginRight: 6,
              }}
              color={colors.OPPOSITE_MAIN_400}
            />
            Hromadné akce
          </FlexCenterAlign>
        </Button>
      </FlexCenterAlign>
      <Button
        style={{
          marginTop: 20,
          padding: "4px 12px"
        }}
        onClick={() => setSelectedProducts([])}
      >
        <FlexCenterAlign>
          <BiX
            size={21}
            style={{
              marginRight: 4,
            }}
            color={colors.OPPOSITE_MAIN_450}
          />
          Zrušit výběr produktů
        </FlexCenterAlign>
      </Button>
    </Wrapper>
  );
};

export default SelectedProducts;
