import { ParameterValueActions, TParameterValueAction } from "./../actions/parameterValueActions";
import { IParameterValueID } from "./../../types/parameter";

export interface IParameterValuesState {
  values: IParameterValueID[];
}

const initialParameterValuesState: IParameterValuesState = {
  values: [],
};

export const parameterValuesReducer = (
  state: IParameterValuesState = initialParameterValuesState,
  action: TParameterValueAction
): IParameterValuesState => {
  switch (action.type) {
    case ParameterValueActions.SET_PARAMETER_VALUES:
      return { ...state, values: action.payload };
    case ParameterValueActions.ADD_PARAMETER_VALUE:
      return { ...state, values: [...state.values, action.payload] };
    case ParameterValueActions.EDIT_PARAMETER_VALUE:
      let values = [...state.values],
        index = values.findIndex((i) => i._id === action.payload._id);

      values[index] = action.payload;

      return { ...state, values };
    case ParameterValueActions.REMOVE_PARAMETER_VALUE_BY_ID:
      return {
        ...state,
        values: state.values.filter((p) => p._id !== action.payload),
      };
    default:
      return state;
  }
};
