import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "styled-components";
import { useClickOutside } from "../common/use-click-outside";
import { useColors } from "../providers/theme/theme-provider";
import Button, { IButtonProps } from "./button";
import Calendar, { TOnSelectDate } from "./calendar/calendar";
import { SelectedDateType } from "./calendar/types";

enum CalendarPosition {
  Top,
  Bottom,
}

interface IProps {
  setSelectedDate(date: TOnSelectDate | null): void;
  selectedDate?: TOnSelectDate;
  disabled?: boolean;
  isRange?: boolean;
  buttonProps?: IButtonProps;
  minDate?: Date;
}

export const CalendarButton = ({
  selectedDate,
  setSelectedDate,
  isRange,
  disabled,
  minDate,
  buttonProps,
}: IProps): React.ReactElement<IProps> => {
  const [opened, setOpened] = useState(false);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useClickOutside(
    calendarWrapperRef,
    () => {
      setOpened(false);
    },
    buttonRef
  );

  const colors = useColors();
  const { borderRadius } = useTheme();

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Button
        disabled={disabled}
        buttonRef={buttonRef}
        onClick={() => setOpened(!opened)}
        style={{
          backgroundColor: colors.MAIN_300,
          fontSize: 14,
          padding: "6px 16px",
        }}
        hoverBackgroundColor={colors.MAIN_250}
        {...buttonProps}
      >
        {!selectedDate
          ? "Vyberte datum"
          : selectedDate.type === SelectedDateType.Single
          ? selectedDate.date.toLocaleDateString()
          : selectedDate.from.toLocaleDateString() +
            " - " +
            selectedDate.to.toLocaleDateString()}
      </Button>

      <div
        ref={calendarWrapperRef}
        style={{
          position: "absolute",
          display: opened ? "block" : "none",
          left: 0,
          zIndex: 1,
          marginTop: 4,
          backgroundColor: colors.MAIN_350,
          borderRadius: borderRadius,
          padding: 20,
          paddingTop: 8,
        }}
      >
        <Calendar
          minDate={minDate}
          hideSelectedDate
          rangeOption={isRange}
          days={[
            "Neděle",
            "Pondělí",
            "Úterý",
            "Středa",
            "Čtvrtek",
            "Pátek",
            "Sobota",
          ]}
          mondayFirst
          months={[
            "Prosinec",
            "Leden",
            "Únor",
            "Březen",
            "Duben",
            "Květen",
            "Červen",
            "Červenec",
            "Srpen",
            "Září",
            "Říjen",
            "Listopad",
          ]}
          onSelectDate={(date) => {
            setSelectedDate(date);
          }}
          selectedDate={selectedDate}
        />
      </div>
    </div>
  );
};

export default CalendarButton;
