export const getLocaleDateStringWithHoursAndMinutes = (date: Date): string => {
  return (
    date.toLocaleDateString() +
    " " +
    date.toLocaleTimeString(undefined, {
      second: undefined,
      hour: "numeric",
      minute: "numeric",
    })
  );
};

export const getLocaleDateStringWithHoursAndMinutesAndSeconds = (
  date: Date
): string => {
  return (
    date.toLocaleDateString() +
    " " +
    date.toLocaleTimeString(undefined, {
      second: "numeric",
      hour: "numeric",
      minute: "numeric",
    })
  );
};
