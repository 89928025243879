import { ICurrencyID } from "../../types/currency";
import { IShippingID } from "../../types/shipping";
import { ShippingActions, TShippingAction } from "../actions/shippingActions";

export interface IShippingsState {
  shippings: IShippingID[];
}

const initialShippingsState: IShippingsState = {
  shippings: [],
};

export const shippingsReducer = (
  state: IShippingsState = initialShippingsState,
  action: TShippingAction
): IShippingsState => {
  switch (action.type) {
    case ShippingActions.SET_SHIPPINGS:
      return { ...state, shippings: action.payload };
    case ShippingActions.ADD_SHIPPING:
      return {
        ...state,
        shippings: [...state.shippings, action.payload],
      };
    case ShippingActions.EDIT_SHIPPING:
      let shippings = [...state.shippings],
        index = shippings.findIndex((i) => i._id === action.payload._id);

      shippings[index] = action.payload;

      return { ...state, shippings };
    case ShippingActions.REMOVE_SHIPPING_BY_ID:
      return {
        ...state,
        shippings: state.shippings.filter((p) => p._id !== action.payload),
      };
    default:
      return state;
  }
};
