import React, { useRef, useState } from "react";
import ImageWindow, { IImageWindowRef } from "./image-window";

interface IImageContext {
  closeImageWindow(): void;
  createImageWindow(src: string): void;
  isImageWindowOpened: boolean;
}

const ImageContext = React.createContext<IImageContext>({} as IImageContext);

export const useImageWindow = () => {
  return React.useContext(ImageContext);
};

interface IProps {
  children: React.ReactNode;
}

export const ImageProvider = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const imageWindow = useRef<IImageWindowRef>(null);
  const [isImageWindowOpened, setIsImageWindowOpened] = useState(false);

  const imageContext: IImageContext = {
    createImageWindow(src) {
      imageWindow.current?.show(src);
    },
    closeImageWindow() {
      imageWindow.current?.close();
    },
    isImageWindowOpened,
  };

  return (
    <ImageContext.Provider value={imageContext}>
      <ImageWindow
        ref={imageWindow}
        setIsImageWindowOpened={setIsImageWindowOpened}
      />
      {children}
    </ImageContext.Provider>
  );
};

export default ImageProvider;
