import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { userModel } from "../../model/query/userModel";
import { IImageDB } from "../../types/gallery";
import { IUserDB } from "../../types/user";
import { ErrorsActions } from "./errorsActions";
import { UsersActions } from "./usersActions";

export enum UserActions {
  SET_USER = "SET_USER",
  SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE",
}

type TSetUserAction = {
  type: UserActions.SET_USER;
  payload: IUserDB | null;
};

type TSetProfileImageAction = {
  type: UserActions.SET_PROFILE_IMAGE;
  payload: IImageDB;
};

type TDecodedUser = {
  exp: number;
  iat: number;
  user: IUserDB | null;
};

export type TUserAction = TSetUserAction | TSetProfileImageAction;

const SET_USER_FROM_TOKEN = (token: string) => {
  Cookies.set("token", token, {
    expires: 28,
    // sameSite: "strict",
    // secure: true,
  });
  const decoded: TDecodedUser = jwtDecode(token);

  return decoded;
};

export const GET_USER_FROM_COOKIE = () => {
  const token = Cookies.get("token");

  if (token) {
    try {
      const decoded: TDecodedUser = jwtDecode(token);

      return {
        type: UserActions.SET_USER,
        payload: decoded.user,
      };
    } catch (err) {
      return {
        type: UserActions.SET_USER,
        payload: null,
      };
    }
  } else {
    return {
      type: UserActions.SET_USER,
      payload: null,
    };
  }
};

export const CHECK_AUTH = async () => {
  try {
    const result = await userModel.checkAuth();

    return result;
  } catch (data: any) {
    throw data;
  }
};

export const LOGIN_USER = async (data: FormData) => {
  try {
    const { token } = await userModel.loginUser(data);

    const decoded = SET_USER_FROM_TOKEN(token);

    return {
      type: UserActions.SET_USER,
      payload: decoded.user,
    };
  } catch (data: any) {
    throw data;
  }
};

export const LOGOUT_USER = () => {
  Cookies.remove("token");

  return { type: UserActions.SET_USER, payload: null };
};

export const EDIT_USER_INFO = async (data: FormData) => {
  try {
    const { token } = await userModel.editUserInfo(data);

    const decoded = SET_USER_FROM_TOKEN(token);

    return {
      type: UserActions.SET_USER,
      payload: decoded.user,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const CHANGE_PASSWORD = async (data: FormData) => {
  try {
    const { token } = await userModel.changePassword(data);

    const decoded = SET_USER_FROM_TOKEN(token);

    return {
      type: UserActions.SET_USER,
      payload: decoded.user,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const UPLOAD_PROFILE_IMAGE = async (data: FormData) => {
  const imageOrFalse = await userModel.uploadProfileImage(data);

  if (imageOrFalse) {
    const decoded = SET_USER_FROM_TOKEN(imageOrFalse.token);

    return {
      type: UserActions.SET_USER,
      payload: decoded.user,
    };
  }

  return false;
};
