import { uniqueId } from "lodash";
import React from "react";
import { FiX } from "react-icons/fi";
import Button from "../button";
import { Flex, FlexCenterAlign, FlexCenterAll, InputsWrapper } from "../common";
import IconButton from "../icon-button";
import Input from "../input";
import { ISpecialAttributeID } from "../../types/special-attribute";

interface IProps {
  specialAttributes: ISpecialAttributeID[];
  setSpecialAttributes(specialAttributes: ISpecialAttributeID[]): void;
}

export const SpecialAttributesEditor = ({
  specialAttributes,
  setSpecialAttributes,
}: IProps): React.ReactElement<IProps> => {
  const addSpecialAttribute = () => {
    setSpecialAttributes([
      ...specialAttributes,
      { key: "", value: "", _id: uniqueId() },
    ]);
  };

  const editSpecialAttribute = (attribute: ISpecialAttributeID) => {
    const newAttributes = [...specialAttributes];
    const attributeIndex = newAttributes.findIndex(
      (atr) => atr._id === attribute._id
    );

    if (attributeIndex === -1) {
      return;
    }

    newAttributes[attributeIndex] = attribute;
    setSpecialAttributes(newAttributes);
  };

  const removeSpecialAttribute = (attribute: ISpecialAttributeID) => {
    setSpecialAttributes(
      specialAttributes.filter((atr) => atr._id !== attribute._id)
    );
  };

  return (
    <Flex>
      <InputsWrapper>
        {specialAttributes.map((attribute, i) => (
          <FlexCenterAlign style={{ marginBottom: 8 }}>
            <Input
              placeholder="Klíč"
              value={attribute.key}
              setValue={(key) => editSpecialAttribute({ ...attribute, key })}
            />
            <Input
              style={{ marginLeft: 8 }}
              placeholder="Hodnota"
              value={attribute.value}
              setValue={(value) =>
                editSpecialAttribute({ ...attribute, value })
              }
            />
            <IconButton
              onClick={() => removeSpecialAttribute(attribute)}
              style={{ marginLeft: 8, padding: 4 }}
            >
              <FiX />
            </IconButton>
          </FlexCenterAlign>
        ))}
        <FlexCenterAll>
          <Button onClick={addSpecialAttribute}>
            Přidat speciální atribut
          </Button>
        </FlexCenterAll>
      </InputsWrapper>
    </Flex>
  );
};

export default SpecialAttributesEditor;
