import { getDefaultRole } from "../../types/role";
import { IUserDB } from "../../types/user";
import { TUserAction, UserActions } from "../actions/userActions";

const initialUser: IUserDB = {
  _id: "",
  firstName: "",
  lastName: "",
  userName: "",
  role: getDefaultRole(),
};

export interface IUserState {
  user: IUserDB;
  logged: boolean;
  isLoading: boolean;
}

const initialUserState: IUserState = {
  user: initialUser,
  logged: false,
  isLoading: true,
};

export const userReducer = (
  state: IUserState = initialUserState,
  action: TUserAction
): IUserState => {
  switch (action.type) {
    case UserActions.SET_USER:
      if (action.payload) {
        return {
          ...state,
          user: action.payload,
          logged: true,
          isLoading: false,
        };
      }

      return { ...state, user: initialUser, logged: false, isLoading: false };
    case UserActions.SET_PROFILE_IMAGE:
      return {
        ...state,
        user: {
          ...state.user,
          image: action.payload,
        },
      };
    default:
      return state;
  }
};
