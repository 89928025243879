import { useAnimate } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IProductWithCount,
  analyticsModel,
} from "../../model/query/analyticsModel";
import { useColors } from "../../providers/theme/theme-provider";
import CalendarButton from "../../ui/calendar-button";
import { TOnSelectDate } from "../../ui/calendar/calendar";
import { SelectedDateType } from "../../ui/calendar/types";
import {
  DeleteButton,
  Flex,
  FlexCenterAlign,
  InputsWrapper,
  LinkTo,
} from "../../ui/common";
import Dropdown from "../../ui/dropdown";
import LazyLoadingGalleryImage from "../../ui/lazy-loading-image";
import { AnalyticsTimeScale } from "./types";
import { getDefaultTimeScaleText } from "./utils";
import { useAppConfig } from "../../common/redux-hooks";

interface IProps {}

export const MostSoldProducts = ({}: IProps): React.ReactElement<IProps> => {
  const { store, language } = useAppConfig();
  const colors = useColors();
  const [limit, setLimit] = useState(10);

  const [timeScaleDisabled, setTimeScaleDisabled] = useState(false);
  const [timeScale, setTimeScale] = useState<AnalyticsTimeScale>(
    AnalyticsTimeScale.Month
  );
  const [fromTo, setFromTo] = useState<TOnSelectDate | undefined>();

  const [products, setProducts] = useState<IProductWithCount[]>([]);

  useEffect(() => {
    const getSalesByTimeScale = async () => {
      const timeScaleOrDate =
        fromTo?.type === SelectedDateType.Range
          ? { from: fromTo.from.getTime(), to: fromTo.to.getTime() }
          : fromTo?.type === SelectedDateType.Single
          ? { from: fromTo.date.getTime(), to: fromTo.date.getTime() }
          : timeScale;

      try {
        const { products } = await analyticsModel.getMostSoldProducts(
          timeScaleOrDate,
          limit,
          store._id,
          language._id
        );

        setProducts(products);
      } catch (err) {}
    };

    getSalesByTimeScale();
  }, [timeScale, fromTo, language, limit, store]);

  return (
    <Flex
      style={{
        marginTop: 20,
      }}
    >
      <InputsWrapper>
        <div>
          <div
            style={{
              fontSize: 22,
              fontWeight: 500,
              marginLeft: 6,
              marginBottom: 8,
            }}
          >
            Nejprodávanější produkty
          </div>

          <FlexCenterAlign>
            <Flex>
              <Dropdown
                style={{
                  opacity: timeScaleDisabled ? 0.5 : undefined,
                }}
                disabled={timeScaleDisabled}
                title="Vyberte časové rozmezí"
                list={Object.values(AnalyticsTimeScale)
                  .reverse()
                  .map((s) => ({
                    content: getDefaultTimeScaleText(s),
                    value: s,
                    unique_id: s,
                  }))}
                isDeleteButtonDisabled
                onSelectValue={setTimeScale}
                selectedUniqueId={timeScale}
              />
            </Flex>
            <FlexCenterAlign
              style={{
                marginLeft: 20,
                marginTop: -1,
              }}
            >
              <CalendarButton
                isRange
                selectedDate={fromTo}
                setSelectedDate={(date) => {
                  setTimeScaleDisabled(!!date);
                  setFromTo(date || undefined);
                }}
                buttonProps={{
                  style: {
                    backgroundColor: colors.MAIN_400,
                  },
                  hoverBackgroundColor: colors.MAIN_350,
                }}
              />
              {fromTo && (
                <DeleteButton
                  style={{
                    marginLeft: 8,
                  }}
                  data-title="Vymazat datum"
                  onClick={() => {
                    setTimeScaleDisabled(false);
                    setFromTo(undefined);
                  }}
                />
              )}
            </FlexCenterAlign>
          </FlexCenterAlign>
          <Flex
            style={{
              marginTop: 12,
            }}
          >
            <div>
              <div
                style={{
                  marginLeft: 6,
                  marginBottom: 4,
                  fontSize: 13,
                }}
              >
                Zvolte limit
              </div>
              <Dropdown
                title="Limit"
                list={[1, 3, 5, 10, 25, 50, 100, 200].map((value) => ({
                  content: value,
                  value: value,
                  unique_id: value.toString(),
                }))}
                selectedUniqueId={limit.toString()}
                isDeleteButtonDisabled
                onSelectValue={(value) => setLimit(value)}
              />
            </div>
          </Flex>
        </div>

        <div
          style={{
            marginTop: 20,
          }}
        >
          {!products.length ? (
            <div
              style={{
                marginLeft: 8,
                opacity: 0.5,
              }}
            >
              Žádné prodané kusy nebyly nalezeny
            </div>
          ) : (
            products.map((p, i) => (
              <InputsWrapper
                style={{
                  backgroundColor: colors.MAIN_400,
                  marginBottom: 16,
                  padding: 8,
                }}
                key={i}
              >
                <Flex
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <div>
                    <LazyLoadingGalleryImage
                      style={{
                        width: 128,
                        height: 128,
                        objectFit: "contain",
                      }}
                      src={p.product?.image?.url || ""}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: 24,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 16,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 20,
                          marginRight: 2,
                          fontWeight: 500,
                        }}
                      >
                        {p.count}
                      </span>
                      ks
                    </div>
                    <div
                      data-title={p.name || undefined}
                      style={{
                        fontSize: 15,
                        maxWidth: 360,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        marginTop: 8,
                      }}
                    >
                      {p.name}
                    </div>
                    {p.product && (
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        <LinkTo
                          data-title="Přejít na produkt"
                          onClick={
                            p.product
                              ? () =>
                                  window.open(
                                    `/products/edit-product/${p.product!._id}`
                                  )
                              : undefined
                          }
                        />
                      </div>
                    )}
                  </div>
                </Flex>
              </InputsWrapper>
            ))
          )}
        </div>
      </InputsWrapper>
    </Flex>
  );
};

export default MostSoldProducts;
