import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { useClickOutside } from "../../common/use-click-outside";
import { translateUnit } from "../../constants/language";
import { useTooltipProvider } from "../../providers/tooltip/tooltip-provider";
import {
  availableLengthUnits,
  availableWeightUnits,
  TUnit,
  UnitType,
} from "../../types/units";
import Button from "../button";

const ITEM_HEIGHT = 34;
const ITEM_WIDTH = 50;

interface IUnitDropdownWrapper {
  active: boolean;
  itemLength: number;
}

const UnitDropdownWrapper = styled.div<IUnitDropdownWrapper>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_400};
  `}

  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
  box-shadow: 2px 2px 8px #00000010;
  border-radius: 8px;
  margin-top: 4px;
  text-align: center;
  width: ${ITEM_WIDTH}px;

  transition: visibility 150ms ease, opacity 50ms ease, height 150ms ease 100ms;

  & > div > button:focus {
    box-shadow: none !important;
  }

  ${({ active, itemLength }) =>
    active &&
    css`
      transition: visibility 0ms ease, opacity 150ms ease, height 150ms ease;
      z-index: 10;
      visibility: visible;
      opacity: 1;
      height: ${itemLength * ITEM_HEIGHT}px;
    `}
`;

interface IProps {
  unit: TUnit;
  onSelectUnit(unit: TUnit): void;
  unitType: UnitType;
  disabled?: boolean;
}

export const UnitDropdown = ({
  unit,
  onSelectUnit,
  unitType,
  disabled,
}: IProps): React.ReactElement<IProps> => {
  const [active, setActive] = useState(false);
  const wrapperRef = useRef(null);
  const ignoreRef = useRef(null);

  const { clearTooltip } = useTooltipProvider();

  useEffect(() => {
    if (active) {
      clearTooltip();
    }
  }, [active]);

  useClickOutside(
    wrapperRef,
    () => {
      setActive(false);
    },
    ignoreRef
  );

  const units =
    unitType === UnitType.Length ? availableLengthUnits : availableWeightUnits;

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div ref={ignoreRef}>
        <Button
          style={{
            width: ITEM_WIDTH,
            paddingRight: 0,
            paddingLeft: 0,
          }}
          onClick={() => {
            setActive((active) => !active);
          }}
        >
          {translateUnit(unit)}
        </Button>
      </div>
      {!disabled && (
        <UnitDropdownWrapper
          itemLength={units.length}
          ref={wrapperRef}
          active={active}
        >
          {units.map((u, i) => (
            <Button
              key={i}
              style={{
                height: ITEM_HEIGHT,
                width: ITEM_WIDTH,
                padding: 0,
              }}
              onClick={() => {
                onSelectUnit(u);
                setActive(false);
              }}
            >
              {translateUnit(u)}
            </Button>
          ))}
        </UnitDropdownWrapper>
      )}
    </div>
  );
};

export default UnitDropdown;
