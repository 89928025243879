import React from "react";
import IconButton, { IconStyledWrapper } from "../../ui/icon-button";
import { FiLogOut } from "react-icons/fi";
import colors from "../../ui/colors";
import { FlexCenterAlign, FlexCenterAll } from "../../ui/common";
import styled from "styled-components";
import Button from "../../ui/button";
import { useDispatch } from "../../redux/store";
import { LOGOUT_USER } from "../../redux/actions/userActions";
import { useUser } from "../../common/redux-hooks";
import { useColors } from "../../providers/theme/theme-provider";

const LogoutText = styled.div`
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 500;
  margin-left: 12px;
`;

interface IProps {}

export const SidebarLogout = ({}: IProps): React.ReactElement<IProps> => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(LOGOUT_USER());
  };

  const colors = useColors()

  return (
    <div
      style={{
        backgroundColor: "unset",
        boxShadow: "none",
        padding: "4px 0",
        marginLeft: 8,
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <FlexCenterAlign>
        <IconButton
          style={{
            backgroundColor: colors.ERROR_400,
            padding: 10,
          }}
        >
          <FiLogOut color={"#fff"} size={20} />
        </IconButton>
        <LogoutText>Odhlásit se</LogoutText>
      </FlexCenterAlign>
    </div>
  );
};

export default SidebarLogout;
