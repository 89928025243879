import { isLanguageStringEmpty } from "../../../common/functions";
import { TGetError } from "../../../common/redux-hooks";
import { ISlugErrorsObject, getSlugErrors } from "../../../common/slug-validator";
import { generateSlug } from "../../../common/slugs";
import { TLanguageString } from "../../../types/common";
import { ErrorType, TReduxError } from "../../../types/error";
import {
  IProductParameter,
  IProductParameterGroup,
} from "../../../types/parameter";
import { IEditorProduct } from "../../../types/products";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.PRODUCT);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export interface IProductEditorErrorsObject extends ISlugErrorsObject {
  name?: TReduxError;
  ean?: TReduxError;
  eshop_id?: TReduxError;
  eshop_id_exists?: TReduxError;
  buy_price?: TReduxError;
  price_vat?: TReduxError;
  description?: TReduxError;
  image?: TReduxError;
  slug_value_invalid?: TReduxError;
  slug_value_empty?: TReduxError;
  slug_already_exists?: TReduxError;
};

type TProductEditorErrors = {
  errors: IProductEditorErrorsObject;
  isValid: boolean;
};

export const getProductEditorErrors = (
  getError: TGetError,
  product: IEditorProduct,
  locale: string
): TProductEditorErrors => {
  const name = getErrorOfKey(
    "name",
    getError,
    isLanguageStringEmpty(product.name)
  );
  const ean = getErrorOfKey("ean", getError, product.ean === "");
  const eshop_id = getErrorOfKey("eshop_id", getError, product.eshop_id === "");

  const eshop_id_exists_params = getError(
    "eshop_id_exists",
    ErrorType.PRODUCT,
    {
      value: product.eshop_id,
    }
  )?.params!;
  const eshop_id_exists = getErrorOfKey(
    "eshop_id_exists",
    getError,
    eshop_id_exists_params && eshop_id_exists_params.value === product.eshop_id
  );

  const buy_price = getErrorOfKey(
    "buy_price",
    getError,
    !product.buy_price[locale] || product.buy_price[locale] === 0
  );
  const price_vat = getErrorOfKey(
    "price_vat",
    getError,
    !product.price_vat[locale] || product.price_vat[locale] === 0
  );

  const description = getErrorOfKey(
    "description",
    getError,
    isLanguageStringEmpty(product.description)
  );
  const image = getErrorOfKey("image", getError, !product.image);

  const { errors: slugErrors, isValid: slugIsValid } = getSlugErrors(
    product.slug,
    getError,
    locale
  );

  return {
    errors: {
      name,
      ean,
      buy_price,
      price_vat,
      description,
      image,
      eshop_id,
      eshop_id_exists,
      ...slugErrors,
    },
    isValid:
      !name &&
      !ean &&
      !buy_price &&
      !price_vat &&
      !description &&
      !image &&
      !eshop_id &&
      !eshop_id_exists &&
      slugIsValid,
  };
};

export const getProductParameterErrors = (
  getError: TGetError,
  parameter: IProductParameter,
  parameterGroupId?: string
) => {
  const keyPrefix = parameterGroupId ? `group_${parameterGroupId}_` : "";

  const value_missing = parameter.parameter?._id
    ? getErrorOfKey(
        keyPrefix + "value_missing_" + parameter.parameter._id,
        getError,
        !parameter.value_id && !parameter.value_number
      )
    : undefined;

  const wrong_value_for_parameter = parameter.parameter?._id
    ? getErrorOfKey(
        keyPrefix + "wrong_value_for_parameter_" + parameter.parameter._id,
        getError,
        !parameter.value_id && !parameter.value_number
      )
    : undefined;

  return {
    errors: {
      value_missing,
      wrong_value_for_parameter,
    },
    isValid: !value_missing && !wrong_value_for_parameter,
  };
};

export const hasParameterErrors = (
  parameters: IProductParameter[],
  parameter_groups: IProductParameterGroup[],
  getError: TGetError
): boolean => {
  for (let i = 0; i < parameters.length; i++) {
    const { isValid } = getProductParameterErrors(getError, parameters[i]);

    if (!isValid) {
      return true;
    }
  }

  for (let i = 0; i < parameter_groups.length; i++) {
    for (let j = 0; j < parameter_groups[i].parameters.length; j++) {
      const { isValid } = getProductParameterErrors(
        getError,
        parameter_groups[i].parameters[j],
        parameter_groups[i].group?._id
      );

      if (!isValid) {
        return true;
      }
    }
  }

  return false;
};
