import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/button";
import { AddButtonActionStyles, FlexCenterAlign } from "../../ui/common";
import ViewWrapper from "../view-wrapper";
import CurrenciesList from "./currencies-list";

export const CurrenciesActionButtons =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();

    return (
      <FlexCenterAlign>
        <Button
          style={AddButtonActionStyles}
          onClick={() => navigate("/currencies/add-currency")}
        >
          Přidat měnu
        </Button>
      </FlexCenterAlign>
    );
  };

interface IProps {}

export const Currencies = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <ViewWrapper>
      <CurrenciesActionButtons />

      <CurrenciesList />
    </ViewWrapper>
  );
};

export default Currencies;
