import { IProduct, IUnpopulatedProduct } from "../../types/products";
import axios from "../axios";

import { IProductFilters } from "../../types/products";
import { ITEMS_PER_PAGE, TSortBy } from "../../ui/table";

export enum ProductsSortBy {
  None = "added_date",
  EshopID = "eshop_id",
  EAN = "ean",
  Name = "name",
  Stock = "stock",
  PriceVat = "price_vat",
  BuyPrice = "buy_price",
  AddedDate = "added_date",
  AvailableOnEshop = "available_on_eshop"
}

export interface IQueryFilters {
  query: string;
  mustMatchExactly: boolean;
  filters: IProductFilters | null;
  sortBy?: TSortBy;
  page: number;
  language: string;
  store?: string;
}

export type TFilteredProductsResult = {
  products: IUnpopulatedProduct[];
  totalCount: number;
};

export interface IProductModel {
  removeProductById: (id: string) => Promise<boolean>;
  addProduct: (data: FormData) => Promise<IProduct>;
  editProduct: (data: FormData) => Promise<IProduct>;
  groupAddParameters: (data: FormData) => Promise<boolean>;
  groupRemoveParameters: (data: FormData) => Promise<boolean>;
  groupAddCategories: (data: FormData) => Promise<boolean>;
  groupRemoveCategories: (data: FormData) => Promise<boolean>;
  groupSetAvailable: (data: FormData) => Promise<boolean>;
  groupSetNotAvailable: (data: FormData) => Promise<boolean>;
  groupDeleteProducts: (data: FormData) => Promise<boolean>;
  getProductById: (id: string) => Promise<IProduct>;
  getFilteredProducts: (
    filter?: IQueryFilters
  ) => Promise<TFilteredProductsResult>;
  distributeGalleryAmongstProducts: () => Promise<boolean>;
  importProducts: (data: FormData) => Promise<boolean>;
}

export const productModel: IProductModel = {
  removeProductById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(`/products/remove-by-id`, {
          id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  distributeGalleryAmongstProducts() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(`/products/distribute-gallery`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  importProducts(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(`/products/import-products`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addProduct(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(`/products/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editProduct(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(`/products/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  groupAddParameters(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(
          `/products/group-add-parameters`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  groupRemoveParameters(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(
          `/products/group-remove-parameters`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  groupAddCategories(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(
          `/products/group-add-categories`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  groupRemoveCategories(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(
          `/products/group-remove-categories`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  groupSetAvailable(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(
          `/products/group-set-available`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  groupSetNotAvailable(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(
          `/products/group-set-not-available`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  groupDeleteProducts(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post(
          `/products/group-delete-products`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getFilteredProducts(filter?: IQueryFilters) {
    return new Promise(async (res, rej) => {
      let parsedFilters: any | null = null;

      if (filter?.filters) {
        parsedFilters = {
          ...filter.filters,
          params: {
            ...filter.filters.params,
            value: filter.filters.params.value.map((p) => ({
              parameter: p.parameter?._id,
              value_id: p.value_id?._id,
              value_number: p.value_number,
              unit: p.unit,
            })),
          },
        };
      }

      const response = await axios.get<TFilteredProductsResult>(
        `/products/get-filtered`,
        filter && {
          params: {
            query: JSON.stringify(filter.query),
            language: filter.language,
            filters: parsedFilters ? JSON.stringify(parsedFilters) : undefined,
            exact: JSON.stringify(filter.mustMatchExactly),
            sortBy: filter.sortBy ? JSON.stringify(filter.sortBy) : undefined,
            offset: filter.page ? filter.page * ITEMS_PER_PAGE : undefined,
            store: filter.store,
          },
        }
      );

      res(response.data);
    });
  },
  getProductById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IProduct>(`/products/get-by-id`, {
          params: {
            id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
