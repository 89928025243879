import { IShippingID } from "../../types/shipping";
import { shippingModel } from "./../../model/query/shippingModel";
import { ErrorsActions } from "./errorsActions";

export enum ShippingActions {
  SET_SHIPPINGS = "SET_SHIPPINGS",
  ADD_SHIPPING = "ADD_SHIPPING",
  EDIT_SHIPPING = "EDIT_SHIPPING",
  REMOVE_SHIPPING_BY_ID = "REMOVE_SHIPPING_BY_ID",
}

type TSetShippingsAction = {
  type: ShippingActions.SET_SHIPPINGS;
  payload: IShippingID[];
};

type TAddShippingAction = {
  type: ShippingActions.ADD_SHIPPING;
  payload: IShippingID;
};

type TEditShippingAction = {
  type: ShippingActions.EDIT_SHIPPING;
  payload: IShippingID;
};

type TRemoveShippingByIdAction = {
  type: ShippingActions.REMOVE_SHIPPING_BY_ID;
  payload: string;
};

export type TShippingAction =
  | TSetShippingsAction
  | TAddShippingAction
  | TEditShippingAction
  | TRemoveShippingByIdAction;

export const FETCH_AND_SET_SHIPPINGS = async () => {
  try {
    const Shippings = await shippingModel.getShippings();

    return {
      type: ShippingActions.SET_SHIPPINGS,
      payload: Shippings,
    };
  } catch (err) {
    throw err;
  }
};

export const ADD_SHIPPING = async (data: FormData) => {
  try {
    const Shipping = await shippingModel.addShipping(data);

    return {
      type: ShippingActions.ADD_SHIPPING,
      payload: Shipping,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_SHIPPING = async (data: FormData) => {
  try {
    const Shipping = await shippingModel.editShipping(data);

    return {
      type: ShippingActions.EDIT_SHIPPING,
      payload: Shipping,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_SHIPPING_BY_ID = async (id: string) => {
  try {
    await shippingModel.removeShippingById(id);

    return {
      type: ShippingActions.REMOVE_SHIPPING_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw err;
  }
};
