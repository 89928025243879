import React from "react";
import { ModelContext } from "./provider";
import { combineQueries } from "./query";

interface IProps {
  children: React.ReactNode;
}

export const Model = ({ children }: IProps): React.ReactElement<IProps> => {
  return (
    <ModelContext.Provider value={combineQueries()}>
      {children}
    </ModelContext.Provider>
  );
};

export default Model;
