import React, { ReactNode, useEffect, PropsWithChildren } from "react";
import { clearLocalStorage } from "../common/local-storage";
import Model from "../model";
import { GET_USER_FROM_COOKIE } from "../redux/actions/userActions";
import { useDispatch } from "../redux/store";
import ConfirmationProvider from "./confirmation/confirmation-provider";
import GlobalErrorProvider from "./global-error/global-error-provider";
import ImageWindow from "./image-window/image-window";
import ImageProvider from "./image-window/image-window-provider";
import LoadingWithOverlayProvider from "./loading-with-overlay/loading-with-overlay-provider";
import ModalProvider from "./modal/modal-provider";
import NotificationProvider from "./notifications/notification-provider";
import PortalProvider from "./portal/portal-provider";
import SimplePopupProvider from "./simple-popup/simple-popup-provider";
import ThemeProvider from "./theme/theme-provider";
import TooltipProvider from "./tooltip/tooltip-provider";

interface IProps {
  children: ReactNode;
}

export const CombinedProviders = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const dispatch = useDispatch();

  useEffect(() => {
    const cookieUser = GET_USER_FROM_COOKIE();

    if (cookieUser) {
      dispatch(cookieUser);
    }

    clearLocalStorage();
  }, []);

  return (
    <GlobalErrorProvider>
      <Model>
        <ThemeProvider>
          <LoadingWithOverlayProvider>
            <ImageProvider>
              <TooltipProvider>
                <ConfirmationProvider>
                  <SimplePopupProvider>
                    <NotificationProvider>
                      <ModalProvider>
                        <PortalProvider>{children}</PortalProvider>
                      </ModalProvider>
                    </NotificationProvider>
                  </SimplePopupProvider>
                </ConfirmationProvider>
              </TooltipProvider>
            </ImageProvider>
          </LoadingWithOverlayProvider>
        </ThemeProvider>
      </Model>
    </GlobalErrorProvider>
  );
};
