import _ from "lodash";
import { ICategory } from "../types/category";
import { TLanguageString } from "../types/common";
import { CouponType } from "../types/coupon";
import { IHistoryActionChanges } from "../types/history";
import { ICheckout, IOrder, IOrderItem } from "../types/order";
import { IEditorProduct, IProduct } from "../types/products";
import { IUserDB, IUserOnlyNameWithID } from "../types/user";
import { IEditorCategory } from "../view/categories/category-actions/category-editor";
import { IEditorManufacturer } from "../view/manufacturers/manufacturers-editor/manufacturers-editor";
import {
  defaultLanguageKey
} from "./../constants/language";
import {
  TLanguageNumber,
  TLanguageSlug
} from "./../types/common";
import { IManufacturerID } from "./../types/manufacturer";
import { LengthUnit, TUnit, WeightUnit } from "./../types/units";

export const compareArrays = (
  arr1: any[],
  arr2: any[],
  ignorePosition: boolean = false
): boolean => {
  if (!ignorePosition) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (typeof arr1[i] === "object" && typeof arr2[i] === "object") {
        if (!compareObjects(arr1[i], arr2[i])) {
          return false;
        }
      } else {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
    }
    return true;
  } else {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const matchList = arr1.map((item) => arr2.includes(item));
    return !matchList.includes(false);
  }
};

interface IObject {
  [key: string]: any;
}

export const compareObjects = (obj1: IObject, obj2: IObject): boolean => {
  const obj1Props = Object.getOwnPropertyNames(obj1);
  const obj2Props = Object.getOwnPropertyNames(obj2);

  if (obj1Props.length !== obj2Props.length) {
    return false;
  }

  for (let i = 0; i < obj1Props.length; i++) {
    const propName = obj1Props[i];
    if (obj1[propName] !== obj2[propName]) {
      return false;
    }
  }

  return true;
};

export const compareInnerHtmlLanguageString = (
  html1: TLanguageString,
  html2: TLanguageString
) => {
  let isSame = true;

  if (!compareArrays(Object.keys(html1), Object.keys(html2), true)) {
    return false;
  }

  Object.keys(html1).forEach((key) => {
    isSame =
      isSame &&
      compareInnerHtml(html1[key].normalize(), html2[key].normalize());
  });

  return isSame;
};

export const compareInnerHtml = (html1: string, html2: string) => {
  const htmlDiv1 = document.createElement("div");
  const htmlDiv2 = document.createElement("div");

  htmlDiv1.innerHTML = html1;
  htmlDiv2.innerHTML = html2;

  return htmlDiv1.innerHTML === htmlDiv2.innerHTML;
};

export const getFullName = (
  user: IUserDB | IUserOnlyNameWithID | ICheckout
) => {
  return user.firstName + " " + user.lastName;
};

export const isLanguageStringEmpty = (
  languageString: TLanguageString
): boolean => {
  if (!languageString) {
    return true;
  }

  return !Object.keys(languageString).some((key) => languageString[key]);
};

export const compareTwoDatesByYearMonthDay = (
  dateA: number,
  dateB: number
): number => {
  let a = new Date(dateA);
  let b = new Date(dateB);
  if (a.getFullYear() === b.getFullYear()) {
    if (a.getMonth() === b.getMonth()) {
      return a.getDate() > b.getDate() ? 1 : -1;
    } else {
      return a.getMonth() > b.getMonth() ? 1 : -1;
    }
  } else {
    return a.getFullYear() > b.getFullYear() ? 1 : -1;
  }
};

export const truncateDate = (date: number): number => {
  var d = new Date(date);
  d.setMilliseconds(0);
  d.setSeconds(0);
  d.setMinutes(0);
  d.setHours(0);
  return d.getTime();
};

export const isObject = (value: any) => {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
};

const filterEmptyLanguageSlug = (slug: TLanguageSlug): TLanguageSlug => {
  const filteredSlug: TLanguageSlug = {};

  Object.keys(slug).forEach((key) => {
    if (slug[key]?.value?.length) {
      filteredSlug[key] = slug[key];
    }
  });

  return filteredSlug;
};

const filterEmptyLanguageString = (
  languageString: TLanguageString
): TLanguageString => {
  const filteredLanguageString: TLanguageString = {};

  Object.keys(languageString).forEach((key) => {
    if (languageString[key]?.length) {
      filteredLanguageString[key] = languageString[key];
    }
  });

  return filteredLanguageString;
};

export const filterEmptyLanguageNumber = (
  languageNumber: TLanguageNumber
): TLanguageNumber => {
  const filteredLanguageNumber: TLanguageNumber = {};

  if (!languageNumber) {
    return {};
  }

  Object.keys(languageNumber).forEach((key) => {
    if (languageNumber[key] !== undefined && languageNumber[key] !== null) {
      filteredLanguageNumber[key] = languageNumber[key];
    }
  });

  return filteredLanguageNumber;
};

const filterEmptyCategoryProperties = (
  category: ICategory | IEditorCategory
) => {
  category.name = filterEmptyLanguageString(category.name);
  category.slug = filterEmptyLanguageSlug(category.slug);
};

const filterEmptyProductProperties = (product: IProduct | IEditorProduct) => {
  product.name = filterEmptyLanguageString(product.name);
  product.slug = filterEmptyLanguageSlug(product.slug);
  product.description = filterEmptyLanguageString(product.description);
  product.price_vat = filterEmptyLanguageNumber(product.price_vat);
  product.sale_price = filterEmptyLanguageNumber(product.sale_price);
  product.buy_price = filterEmptyLanguageNumber(product.buy_price);
};

export const getProductChangedFields = (
  before: IProduct | IEditorProduct,
  after: IProduct | IEditorProduct
): IHistoryActionChanges => {
  const changes: IHistoryActionChanges = {
    before: {},
    after: {},
  };

  filterEmptyProductProperties(before);
  filterEmptyProductProperties(after);

  if (!_.isEqual(before.stores, after.stores)) {
    changes.before.stores = before.stores;
    changes.after.stores = after.stores;
  }

  if (!_.isEqual(before.buy_price, after.buy_price)) {
    changes.before.buy_price = before.buy_price;
    changes.after.buy_price = after.buy_price;
  }

  if (!_.isEqual(before.categories, after.categories)) {
    changes.before.categories = before.categories;
    changes.after.categories = after.categories;
  }

  if (!_.isEqual(before.description, after.description)) {
    changes.before.description = before.description;
    changes.after.description = after.description;
  }

  if (before.ean !== after.ean) {
    changes.before.ean = before.ean;
    changes.after.ean = after.ean;
  }

  if (before.eshop_id !== after.eshop_id) {
    changes.before.eshop_id = before.eshop_id;
    changes.after.eshop_id = after.eshop_id;
  }

  if (!_.isEqual(before.gallery, after.gallery)) {
    changes.before.gallery = before.gallery;
    changes.after.gallery = after.gallery;
  }

  if (!_.isEqual(before.image, after.image)) {
    changes.before.image = before.image || null;
    changes.after.image = after.image || null;
  }

  if (!_.isEqual(before.manufacturer, after.manufacturer)) {
    changes.before.manufacturer = before.manufacturer;
    changes.after.manufacturer = after.manufacturer;
  }

  if (!_.isEqual(before.name, after.name)) {
    changes.before.name = before.name;
    changes.after.name = after.name;
  }

  if (!_.isEqual(before.packaging, after.packaging)) {
    changes.before.packaging = before.packaging;
    changes.after.packaging = after.packaging;
  }

  if (!_.isEqual(before.params, after.params)) {
    changes.before.params = before.params;
    changes.after.params = after.params;
  }

  if (!_.isEqual(before.parameter_groups, after.parameter_groups)) {
    changes.before.params = before.params;
    changes.after.params = after.params;
  }

  if (!_.isEqual(before.price_vat, after.price_vat)) {
    changes.before.price_vat = before.price_vat;
    changes.after.price_vat = after.price_vat;
  }

  if (!_.isEqual(before.sale_price, after.sale_price)) {
    changes.before.sale_price = before.sale_price;
    changes.after.sale_price = after.sale_price;
  }

  if (!_.isEqual(before.slug, after.slug)) {
    changes.before.slug = before.slug;
    changes.after.slug = after.slug;
  }

  if (before.stock !== after.stock) {
    changes.before.stock = before.stock;
    changes.after.stock = after.stock;
  }

  if (before.translation_needed !== after.translation_needed) {
    changes.before.translation_needed = before.translation_needed;
    changes.after.translation_needed = after.translation_needed;
  }
  return changes;
};

export const getCategoryChangedFields = (
  before: ICategory | IEditorCategory,
  after: ICategory | IEditorCategory
): IHistoryActionChanges => {
  const changes: IHistoryActionChanges = {
    before: {},
    after: {},
  };

  filterEmptyCategoryProperties(before);
  filterEmptyCategoryProperties(after);

  if (before.isMainCategory !== after.isMainCategory) {
    changes.before.isMainCategory = before.isMainCategory;
    changes.after.isMainCategory = after.isMainCategory;
  }

  if (!_.isEqual(before.subcategories, after.subcategories)) {
    changes.before.subcategories = before.subcategories;
    changes.after.subcategories = after.subcategories;
  }

  if (!_.isEqual(before.image, after.image)) {
    changes.before.image = before.image;
    changes.after.image = after.image;
  }

  if (!_.isEqual(before.name, after.name)) {
    changes.before.name = before.name;
    changes.after.name = after.name;
  }

  if (!_.isEqual(before.slug, after.slug)) {
    changes.before.slug = before.slug;
    changes.after.slug = after.slug;
  }

  return changes;
};

export const isDecimalNumber = (value: string): boolean => {
  return /^[+-]?\d+(\.\d+|,\d+)?$/.test(value);
};

export const parseUnitFromString = (unit: string): TUnit | null => {
  if (WeightUnit[unit as keyof typeof WeightUnit]) {
    return WeightUnit[unit as keyof typeof WeightUnit];
  }

  if (LengthUnit[unit as keyof typeof LengthUnit]) {
    return LengthUnit[unit as keyof typeof LengthUnit];
  }

  return null;
};

export const getManufacturerName = (
  manufacturer: IManufacturerID | IEditorManufacturer,
  locale: string
): string | undefined => {
  if (manufacturer.nameIsSameForAllLanguages) {
    return manufacturer.name[defaultLanguageKey];
  }

  return manufacturer.name[locale] || undefined;
};

export const calculateOrderItemsSum = (items: IOrderItem[]): number => {
  return items.reduce(
    (prev, item) => prev + (item.sale_price || item.price_vat) * item.quantity,
    0
  );
};

export const calculateOrderItemsSumWithCoupon = (order: IOrder) => {
  const couponDeduction = getOrderCouponDeduction(order);
  const itemsSum = calculateOrderItemsSum(order.items);

  if (itemsSum - couponDeduction < 0) {
    return 0;
  }

  return itemsSum - couponDeduction;
};

export const getOrderFullPriceVat = (order: IOrder) => {
  return (
    calculateOrderItemsSumWithCoupon(order) +
    order.shipping_price +
    order.payment_price
  );
};

export const getOrderCouponDeduction = (order: IOrder) => {
  if (!order.coupon) {
    return 0;
  }

  if (order.coupon.type === CouponType.Percent) {
    const itemsPrice = calculateOrderItemsSum(order.items);

    return (order.coupon.value / 100) * itemsPrice;
  }

  if (order.coupon.type === CouponType.Value) {
    return order.coupon.value;
  }

  return 0;
};