import React, { useRef, useState, useEffect, useCallback } from "react";
import ModalWindow, { IModalWindowRef } from "./modal-window";
import { Overlay } from "./../common/overlay";

export interface IModalContext {
  createModal(
    component: React.ReactNode,
    withoutClosingWrapper?: boolean
  ): void;
  closeModal(): void;
  lastIndex: number;
}

const ModalContext = React.createContext<IModalContext>({} as IModalContext);

export const useModal = () => {
  return React.useContext(ModalContext);
};

interface IProps {
  children: React.ReactNode;
}

export const ModalProvider = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const modalRefs = useRef<IModalWindowRef[]>([]);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [modalWindows, setModalWindows] = useState<React.ReactNode[]>([]);

  const closeModal = useCallback(() => {
    let index = modalWindows.length - 1;

    if (index === 0) overlayRef.current?.classList.remove("active");

    modalRefs.current[index]?.close(() => {
      modalRefs.current = modalRefs.current.filter((_, i) => i !== index);
      setModalWindows((w) => w.filter((_, i) => i !== index));
    });
  }, [modalWindows.length]);

  const modalContext: IModalContext = {
    createModal(component, withoutClosingWrapper) {
      overlayRef.current?.classList.add("active");

      setModalWindows((windows) => {
        return [
          ...windows,
          <ModalWindow
            ref={(ref) => {
              if (ref) modalRefs.current[modalWindows.length] = ref;
            }}
            component={component}
            withoutClosingWrapper={withoutClosingWrapper}
          />,
        ];
      });
    },
    closeModal,
    lastIndex: modalWindows.length - 1,
  };

  return (
    <ModalContext.Provider value={modalContext}>
      {modalWindows.map((m, i) => (
        <div key={i}>{m}</div>
      ))}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
