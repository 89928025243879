import { ICurrencyID } from "../../types/currency";
import { ILanguageID } from "../../types/language";
import { IStoreID } from "../../types/store";
import { IUserDB } from "../../types/user";
import axios from "../axios";

interface ICheckRegistrationKeyResult {
  store: IStoreID | null;
  language: ILanguageID | null;
  admin: IUserDB | null;
  currency: ICurrencyID | null;
}

export interface IAdminRegistrationModel {
  checkRegistrationKey: (key: string) => Promise<ICheckRegistrationKeyResult>;
  getDefaultStore: (key: string) => Promise<IStoreID>;
  getDefaultLanguage: (key: string) => Promise<ILanguageID>;
  addDefaultStore: (data: FormData) => Promise<IStoreID>;
  addDefaultLanguage: (data: FormData) => Promise<ILanguageID>;
  addDefaultAdmin: (data: FormData) => Promise<IUserDB>;
  editDefaultStore: (data: FormData) => Promise<IStoreID>;
  editDefaultLanguage: (data: FormData) => Promise<ILanguageID>;
  getDefaultCurrency: (key: string) => Promise<ICurrencyID>;
  addDefaultCurrency: (data: FormData) => Promise<ICurrencyID>;
  editDefaultCurrency: (data: FormData) => Promise<ICurrencyID>;
}

export const adminRegistrationModel: IAdminRegistrationModel = {
  checkRegistrationKey(registration_key) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICheckRegistrationKeyResult>(
          `/admin-registration/check-register-key`,
          { registration_key }
        );

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  addDefaultStore(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IStoreID>(
          `/admin-registration/add-default-store`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addDefaultCurrency(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICurrencyID>(
          `/admin-registration/add-default-currency`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addDefaultAdmin(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IUserDB>(
          `/admin-registration/add-default-admin`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addDefaultLanguage(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILanguageID>(
          `/admin-registration/add-default-language`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editDefaultStore(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IStoreID>(
          `/admin-registration/edit-default-store`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editDefaultCurrency(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICurrencyID>(
          `/admin-registration/edit-default-currency`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editDefaultLanguage(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILanguageID>(
          `/admin-registration/edit-default-language`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getDefaultLanguage(registration_key: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILanguageID>(
          `/admin-registration/get-default-language`,
          { registration_key }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getDefaultStore(registration_key: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IStoreID>(
          `/admin-registration/get-default-store`,
          { registration_key }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getDefaultCurrency(registration_key: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICurrencyID>(
          `/admin-registration/get-default-currency`,
          { registration_key }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
