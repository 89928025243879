import { categoryModel } from "../../model/query/categoryModel";
import { ICategoryId, IUnpopulatedCategory } from "../../types/category";
import { ErrorsActions } from "./errorsActions";

export enum CategoryActions {
  SET_CATEGORIES = "SET_CATEGORIES",
  ADD_CATEGORY = "ADD_CATEGORY",
  EDIT_CATEGORY = "EDIT_CATEGORY",
  REMOVE_CATEGORY_BY_ID = "REMOVE_CATEGORY_BY_ID",
  REMOVE_CATEGORY_FROM_SUBCATEGORIES = "REMOVE_CATEGORY_FROM_SUBCATEGORIES",
}

type TSetCategoriesAction = {
  type: CategoryActions.SET_CATEGORIES;
  payload: IUnpopulatedCategory[];
};

type TAddCategoryAction = {
  type: CategoryActions.ADD_CATEGORY;
  payload: ICategoryId | null;
};

type TEditCategoryAction = {
  type: CategoryActions.EDIT_CATEGORY;
  payload: ICategoryId | null;
};

type TRemoveCategoryByIdAction = {
  type: CategoryActions.REMOVE_CATEGORY_BY_ID;
  payload: string | null;
};

type TRemoveCategoryFromSubcategoriesAction = {
  type: CategoryActions.REMOVE_CATEGORY_FROM_SUBCATEGORIES;
  payload: { parent_id: string; category_id: string } | null;
};

export type TCategoryAction =
  | TSetCategoriesAction
  | TAddCategoryAction
  | TEditCategoryAction
  | TRemoveCategoryByIdAction
  | TRemoveCategoryFromSubcategoriesAction;

export const FETCH_AND_SET_CATEGORIES = async () => {
  try {
    const categories = await categoryModel.getCategories();

    return {
      type: CategoryActions.SET_CATEGORIES,
      payload: categories,
    };
  } catch (err) {
    return false;
  }
};

export const ADD_CATEGORY = async (data: FormData) => {
  try {
    const category = await categoryModel.addCategory(data);

    return {
      type: CategoryActions.ADD_CATEGORY,
      payload: category,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_CATEGORY = async (data: FormData) => {
  try {
    const category = await categoryModel.editCategory(data);

    return {
      type: CategoryActions.EDIT_CATEGORY,
      payload: category,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_CATEGORY_BY_ID = async (id: string) => {
  const isRemoved = await categoryModel.removeCategoryById(id);

  return {
    type: CategoryActions.REMOVE_CATEGORY_BY_ID,
    payload: isRemoved ? id : null,
  };
};

export const REMOVE_CATEGORY_FROM_SUBCATEGORIES = async (
  parent_id: string,
  category_id: string
) => {
  try {
    await categoryModel.removeCategoryFromSubcategories(parent_id, category_id);

    return {
      type: CategoryActions.REMOVE_CATEGORY_FROM_SUBCATEGORIES,
      payload: { parent_id, category_id },
    };
  } catch (err) {
    throw err;
  }
};
