import React, { useState } from "react";
import { Flex, SelectedWindowStyled } from "../../ui/common";
import ViewWrapper from "../view-wrapper";
import UserList from "./users/user-list";
import WindowSelector, { AdminSelectedWindow } from "./window-selector";
import HistoryView from "../components/history/history-view";

interface IProps {}

export const Admin = ({}: IProps): React.ReactElement<IProps> => {
  const [selectedWindow, setSelectedWindow] = useState<AdminSelectedWindow>(
    AdminSelectedWindow.Users
  );

  return (
    <ViewWrapper>
      <WindowSelector
        selectedWindow={selectedWindow}
        setSelectedWindow={setSelectedWindow}
      />
      <Flex>
        <SelectedWindowStyled
          visible={selectedWindow === AdminSelectedWindow.Users}
        >
          <UserList />
        </SelectedWindowStyled>
        <SelectedWindowStyled
          visible={selectedWindow === AdminSelectedWindow.Actions}
        >
          <HistoryView />
        </SelectedWindowStyled>
      </Flex>
    </ViewWrapper>
  );
};

export default Admin;
