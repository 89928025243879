export const colors = {
  SeaGreenCrayola: "#6be39a",
  AliceBlue: "#DEE8E9",
  LavenderBlue: "#CAC3F1",
  DarkLavenderBlue: "#8a80c2",
  DarkerLavenderBlue: "#7a6fba",
  DarkAliceBlue: "#DFE8EA",
  DarkerAliceBlue: "#cedadd",
  AliceBlue2: "#E9F1F4",
  AliceBlue2Light: "#f5fcfc",
  LightSilverMetallic: "#B3B4BA",
  SilverMetallic: "#B3B4BA",
  LightAliceBlue: "#edf5f6",
  White: "#FBFDFF",
  WhiteHover: "#FFF",
  WhiteFocus: "#FFF",
  AliceBlue3: "#EBF2F5",
  LightCoral: "#F18E8F",
  LighterCoral: "#eca0a1",
  DarkerLightCoral: "#db7879",
  DarkerLightCoral2: "#c96163",
  DarkRed: "#5b2e2f",
  LightGraniteGray: "#83818b",
  GraniteGray: "#64636C",
  Charcoal: "#3E494E",
};

export default colors;
