import React, { useRef, useState } from "react";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { useColors } from "../../providers/theme/theme-provider";
import { useDispatch } from "../../redux/store";
import { IImageDB, ImageType } from "../../types/gallery";
import Button from "../../ui/button";
import { FlexCenterAlign } from "../../ui/common";
import Dropdown from "../../ui/dropdown";
import Input from "../../ui/input";
import { useModal } from "./../../providers/modal/modal-provider";
import ImageUploadModal from "./image-upload-modal";
import GalleryList from "./list/gallery-list";
import { useConfirmation } from "../../providers/confirmation/confirmation-provider";
import { productModel } from "../../model/query/productModel";

export enum GalleryListMode {
  Manage,
  ChooseAndUpload,
  Choose,
  ChooseAndUploadOrManage,
}

interface IProps {
  mode: GalleryListMode;
  onClickImage?(image: IImageDB): void;
  type?: ImageType;
  disableSelectType?: boolean;
  isModal?: boolean;
}

export const Gallery = ({
  mode,
  onClickImage,
  type,
  disableSelectType,
  isModal,
}: IProps): React.ReactElement<IProps> => {
  const { createModal } = useModal();
  const [selectedType, setSelectedType] = useState<ImageType | undefined>(type);
  const [query, setQuery] = useState("");
  const { createConfirmation } = useConfirmation();
  const { call } = useQueryWithPopupErrorHandling();
  const [forceRefreshGalleryList, setForceRefreshGalleryList] = useState(0);

  const onSuccessUpload = () => {
    setForceRefreshGalleryList((f) => (f += 1));
  };

  const createFileUploadModal = () => {
    createModal(
      <ImageUploadModal
        type={type}
        disableSelectType={disableSelectType}
        onSuccess={onSuccessUpload}
      />
    );
  };

  const updateProductsByGallery = () => {
    createConfirmation(
      "Opravdu chcete přiřadit fotky produktům podle jejich názvu?",
      () => {
        call(
          () => productModel.distributeGalleryAmongstProducts(),
          "Přiřazení fotek nebylo úspěšné",
          "Přiřazení fotek bylo úspěšné",
          () => {}
        );
      }
    );
  };

  const colors = useColors();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginRight: 20,
        marginBottom: 40
      }}
    >
      <div
        style={{
          marginBottom: 24,
        }}
      >
        <div
          style={{
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          Galerie
        </div>
        <div
          style={{
            marginLeft: 8,
          }}
        >
          <div
            style={{
              width: 240,
              marginBottom: 8,
            }}
          >
            <Dropdown
              disabled={disableSelectType}
              list={[
                {
                  content: "Kategorie",
                  unique_id: ImageType.Category,
                  value: ImageType.Category,
                },
                {
                  content: "Produkty",
                  unique_id: ImageType.Product,
                  value: ImageType.Product,
                },
                {
                  content: "Výrobci",
                  unique_id: ImageType.Manufacturer,
                  value: ImageType.Manufacturer,
                },
                {
                  content: "Emaily",
                  unique_id: ImageType.Email,
                  value: ImageType.Email,
                },
              ]}
              title="Typ obrázku"
              onSelectValue={(_, uniqueId) =>
                setSelectedType(uniqueId as ImageType)
              }
              selectedUniqueId={selectedType}
            />
          </div>
          <FlexCenterAlign>
            <Input
              value={query}
              style={{
                width: 240,
              }}
              placeholder="Vyhledat foto podle názvu"
              onChange={(e) => setQuery(e.target.value)}
            />
            {mode !== GalleryListMode.Choose && (
              <div
                style={{
                  marginLeft: 12,
                }}
              >
                <Button
                  style={{
                    backgroundColor: colors.MAIN_300,
                  }}
                  hoverBackgroundColor={colors.MAIN_250}
                  onClick={createFileUploadModal}
                >
                  Nahrát fotku
                </Button>
                {mode !== GalleryListMode.ChooseAndUpload && (
                  <Button
                    style={{
                      backgroundColor: colors.MAIN_300,
                      marginLeft: 12,
                    }}
                    hoverBackgroundColor={colors.MAIN_250}
                    onClick={updateProductsByGallery}
                  >
                    Přiřadit foto k produktům
                  </Button>
                )}
              </div>
            )}
          </FlexCenterAlign>
        </div>
      </div>

      <GalleryList
        forceRefreshGalleryList={forceRefreshGalleryList}
        isModal={isModal}
        query={query}
        mode={mode}
        onClickImage={onClickImage}
        type={selectedType}
      />
    </div>
  );
};

export default Gallery;
