import { ICurrencyID } from "../../types/currency";
import { IPaymentID } from "../../types/payment";
import { PaymentActions, TPaymentAction } from "../actions/paymentActions";

export interface IPaymentsState {
  payments: IPaymentID[];
}

const initialPaymentsState: IPaymentsState = {
  payments: [],
};

export const paymentsReducer = (
  state: IPaymentsState = initialPaymentsState,
  action: TPaymentAction
): IPaymentsState => {
  switch (action.type) {
    case PaymentActions.SET_PAYMENTS:
      return { ...state, payments: action.payload };
    case PaymentActions.ADD_PAYMENT:
      return {
        ...state,
        payments: [...state.payments, action.payload],
      };
    case PaymentActions.EDIT_PAYMENT:
      let payments = [...state.payments],
        index = payments.findIndex((i) => i._id === action.payload._id);

      payments[index] = action.payload;

      return { ...state, payments };
    case PaymentActions.REMOVE_PAYMENT_BY_ID:
      return {
        ...state,
        payments: state.payments.filter((p) => p._id !== action.payload),
      };
    default:
      return state;
  }
};
