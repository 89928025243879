import { IImageDB } from "../../types/gallery";
import { IUserDB } from "../../types/user";
import axios from "../axios";

export interface IUsersModel {
  getUsers: () => Promise<IUserDB[]>;
  getUserById: (id: string) => Promise<IUserDB | null>;
}

export const usersModel: IUsersModel = {
  getUsers() {
    return new Promise(async (res, rej) => {
      const response = await axios.get<IUserDB[]>(`/users/get-all`);

      res(response.data);
    });
  },
  getUserById(id) {
    return new Promise(async (res, rej) => {
      const response = await axios.get<IUserDB | null>(`/users/get-by-id`, {
        params: {
          id,
        },
      });

      res(response.data);
    });
  },
};
