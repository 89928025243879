import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useAppConfig } from "../common/redux-hooks";
import { useClickOutside } from "../common/use-click-outside";
import { useColors } from "../providers/theme/theme-provider";
import { useTooltipProvider } from "../providers/tooltip/tooltip-provider";
import { SET_APP_CONFIG } from "../redux/actions/appConfigActions";
import { useDispatch, useSelector } from "../redux/store";
import Button from "./button";
import { FlexCenterAll } from "./common";

interface IGlobalLanguageDropdownWrapper {
  active: boolean;
}

const GlobalLanguageDropdownWrapper = styled.div<IGlobalLanguageDropdownWrapper>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_400};
  `}

  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
  right: 0;
  box-shadow: 2px 2px 8px #00000010;
  border-radius: 8px;
  margin-top: 5px;

  * {
    width: 100%;
    white-space: nowrap;
  }

  transition: visibility 150ms ease, opacity 150ms ease, height 150ms ease;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

const Wrapper = styled.div`
  position: relative;
`;

interface IProps {}

export const GlobalLanguageDropdown =
  ({}: IProps): React.ReactElement<IProps> => {
    const { language, store } = useAppConfig();
    const dispatch = useDispatch();
    const [active, setActive] = useState(false);
    const wrapperRef = useRef(null);

    const { clearTooltip } = useTooltipProvider();

    useEffect(() => {
      if (active) {
        clearTooltip();
      }
    }, [active]);

    const ignoreRef = useRef(null);

    useClickOutside(
      wrapperRef,
      () => {
        setActive(false);
      },
      ignoreRef
    );

    const colors = useColors();

    return (
      <Wrapper>
        <Button buttonRef={ignoreRef} onClick={() => setActive((a) => !a)}>
          {language.title}
        </Button>
        <GlobalLanguageDropdownWrapper ref={wrapperRef} active={active}>
          {store.languages.map((l, i) => (
            <FlexCenterAll key={l.iso_key}>
              <Button
                key={i}
                style={{
                  marginBottom: 4,
                  backgroundColor:
                    language._id === l._id ? colors.MAIN_300 : colors.MAIN_400,
                }}
                hoverBackgroundColor={colors.MAIN_350}
                onClick={() => {
                  if (l._id !== language._id) {
                    dispatch(SET_APP_CONFIG({ language: l, store }));
                  }
                  setActive(false);
                }}
              >
                {l.title}
              </Button>
            </FlexCenterAll>
          ))}
        </GlobalLanguageDropdownWrapper>
      </Wrapper>
    );
  };

export default GlobalLanguageDropdown;
