import React, { useEffect, useState } from "react";
import {
  ITEMS_PER_PAGE,
  SortByType,
  StyledTable,
  THeaderKey,
  TRow,
  TSortBy,
  TableHeadCell,
  TableRow,
} from "./table";
import { useColors } from "../providers/theme/theme-provider";
import Loading from "./loading";
import { FlexCenterAlign, NoResultsFound } from "./common";
import IconButton from "./icon-button";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Checkbox from "./checkbox";

export type TSelectRowId = {
  id: string;
  value: any;
};

interface IProps {
  rows: TRow[];
  headerKeys: THeaderKey[];
  totalCount?: number;
  isLoading?: boolean;
  customRowHeight?: number;
  onSelectRow?(row: TRow): void;
  onSetPage?(page: number): void;
  onSortByChange?(sortBy: TSortBy): void;
  selectedRowIDs?: TSelectRowId[];
  onSelectRowId?(row: TSelectRowId): void;
  selectRows?(rows: TSelectRowId[]): void;
  deselectRows?(rows: TSelectRowId[]): void;
  selectableRowWidth?: string;
  selectedPage?: number;
  selectedSortBy?: TSortBy;
}

export const LazyTable = ({
  rows,
  headerKeys,
  isLoading,
  onSelectRow,
  onSortByChange,
  customRowHeight,
  onSetPage,
  totalCount,
  selectedRowIDs,
  onSelectRowId,
  selectRows,
  deselectRows,
  selectableRowWidth,
  selectedPage,
  selectedSortBy,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();
  const maxPage = Math.ceil((totalCount || rows.length) / ITEMS_PER_PAGE);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState<TSortBy>({
    type: SortByType.None,
    key: "",
  });

  useEffect(() => {
    if (selectedSortBy) {
      setSortBy(selectedSortBy);
    }
  }, [selectedSortBy]);

  useEffect(() => {
    if (selectedPage !== undefined) {
      setPage(selectedPage);
    }
  }, [selectedPage]);

  const handleSortBy = (key: string) => {
    if (sortBy.key === key) {
      if (sortBy.type === SortByType.Descending) {
        setSortBy({
          key,
          type: SortByType.Ascending,
        });
      } else if (sortBy.type === SortByType.Ascending) {
        setSortBy({
          key: "",
          type: SortByType.None,
        });
      }
    } else {
      setSortBy({
        key,
        type: SortByType.Descending,
      });
    }
  };

  useEffect(() => {
    onSortByChange?.(sortBy);
  }, [sortBy.type, sortBy.key]);

  const nextPage = () => {
    if (page + 2 > maxPage) {
      return;
    }
    onSetPage?.(page + 1);
    setPage((p) => p + 1);
  };

  const previousPage = () => {
    if (page - 1 < 0) {
      return;
    }
    onSetPage?.(page - 1);
    setPage((p) => p - 1);
  };

  const selectedOnCurrentPage =
    rows
      .map((row) => selectedRowIDs?.find((id) => row.id && id.id === row.id))
      .filter((r) => !!r) || [];

  const allSelectedOnCurrentPage = selectedOnCurrentPage.length === rows.length;

  const selectAll = () => {
    if (!onSelectRowId || !selectRows) {
      return;
    }

    selectRows(rows.map((r) => ({ id: r.id || "", value: r.value })));
  };

  const deselectAll = () => {
    if (!onSelectRowId || !deselectRows) {
      return;
    }

    deselectRows(rows.map((r) => ({ id: r.id || "", value: r.value })));
  };

  const [rememberedRowId, setRememberedRowId] = useState<string | null>(null);

  const onClickRow = (row: TRow) => {
    onSelectRow?.(row);

    if (row.rememberClick && row.id) {
      setRememberedRowId(row.id);
    }
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          {selectedRowIDs && onSelectRowId && (
            <th
              style={{ width: selectableRowWidth ? selectableRowWidth : "3%" }}
            >
              <Checkbox
                value={allSelectedOnCurrentPage}
                setValue={() =>
                  allSelectedOnCurrentPage ? deselectAll() : selectAll()
                }
              />
            </th>
          )}

          {headerKeys.map((key, i) => (
            <TableHeadCell
              canSort={!!key.sort_by_key}
              key={i}
              style={{
                width: key.width,
                cursor: key.sort_by_key ? "pointer" : "default",
              }}
              onClick={
                key.sort_by_key
                  ? () => key.sort_by_key && handleSortBy(key.sort_by_key)
                  : undefined
              }
            >
              <FlexCenterAlign
                style={{
                  position: "relative",
                }}
              >
                {key.content}

                {key.sort_by_key && (
                  <div
                    className="sort-by"
                    style={{
                      marginLeft: 8,
                      opacity: sortBy.key === key.sort_by_key ? 1 : undefined,
                    }}
                  >
                    {sortBy.type === SortByType.Ascending ? (
                      <FiChevronUp size={24} />
                    ) : (
                      <FiChevronDown size={24} />
                    )}
                  </div>
                )}
              </FlexCenterAlign>
            </TableHeadCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td
              style={{
                padding: "120px 0",
                textAlign: "center",
                fontSize: 18,
                height: customRowHeight
                  ? customRowHeight * ITEMS_PER_PAGE
                  : undefined,
                color: colors.OPPOSITE_MAIN_150,
              }}
              colSpan={
                headerKeys.length + (selectedRowIDs && onSelectRowId ? 1 : 0)
              }
            >
              <div>
                <Loading />
              </div>
              <div
                style={{
                  marginTop: 16,
                }}
              >
                {"Načítání položek"}
              </div>
            </td>
          </tr>
        ) : !rows.length ? (
          <tr>
            <td
              style={{
                padding: "60px 0",
                textAlign: "center",
                fontSize: 18,
                color: colors.OPPOSITE_MAIN_150,
              }}
              colSpan={
                headerKeys.length + (selectedRowIDs && onSelectRowId ? 1 : 0)
              }
            >
              <NoResultsFound />
            </td>
          </tr>
        ) : (
          rows.map((row) => (
            <TableRow
              disabled={row.disabled}
              key={row.id}
              selectable={!!onSelectRow}
              onClick={() => onClickRow(row)}
              $isRemembered={rememberedRowId === row.id}
            >
              {row.selectable && row.id && selectedRowIDs && onSelectRowId && (
                <td key="selectable-cell">
                  <Checkbox
                    value={selectedRowIDs.some((id) => row.id === id.id)}
                    setValue={() =>
                      onSelectRowId({ id: row.id!, value: row.value })
                    }
                  />
                </td>
              )}
              {row.cells.map((c, ci) => (
                <td
                  key={ci}
                  style={{
                    width: c.width,
                  }}
                >
                  {c.content}
                </td>
              ))}
            </TableRow>
          ))
        )}
      </tbody>
      <tfoot>
        <tr>
          <td
            colSpan={
              headerKeys.length <= 3
                ? 2 + (selectedRowIDs && onSelectRowId ? 1 : 0)
                : 3 + (selectedRowIDs && onSelectRowId ? 1 : 0)
            }
          >
            <div
              style={{
                fontSize: 14,
                color: colors.OPPOSITE_MAIN_150,
              }}
            >
              Počet výsledků:{" "}
              <span
                style={{
                  fontWeight: 500,
                }}
              >
                {totalCount || rows.length}
              </span>
            </div>
          </td>
          <td colSpan={headerKeys.length - 3}>
            <FlexCenterAlign
              style={{
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                data-title="Předchozí stránka"
                onClick={previousPage}
                style={{
                  padding: 4,
                }}
                disabled={page - 1 < 0}
              >
                <MdNavigateBefore size={28} color={colors.MAIN_150} />
              </IconButton>
              <div
                style={{
                  margin: "0 40px",
                  color: colors.OPPOSITE_MAIN_150,
                  fontSize: 13,
                  textAlign: "center",
                }}
              >
                stránka {page + 1} z {maxPage === 0 ? 1 : maxPage}
              </div>
              <IconButton
                data-title="Další stránka"
                onClick={nextPage}
                style={{
                  marginRight: 40,
                  padding: 4,
                }}
                disabled={page + 2 > maxPage}
              >
                <MdNavigateNext size={28} color={colors.MAIN_150} />
              </IconButton>
            </FlexCenterAlign>
          </td>
        </tr>
      </tfoot>
    </StyledTable>
  );
};

export default LazyTable;
