import { useEffect } from "react";
import {
  compareArrays,
  compareObjects,
  getCategoryChangedFields,
} from "../../../common/functions";
import {
  AddCategoryStateItemKey,
  EditCategoryStateItemKey,
  LocalStorageSavedDateKey,
} from "../../../common/local-storage";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { EditorType } from "../../../types/common";
import { IEditorCategory } from "./category-editor";

function isValidJsonString(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

function isValidCategory(obj: any): obj is IEditorCategory {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.name === "object" &&
    (typeof obj.image === "object" || !obj.image) &&
    Array.isArray(obj.subcategories) &&
    typeof obj.isMainCategory === "boolean"
  );
}

export const deleteSavedCategoryStateFromLocalStorage = (
  categoryId?: string
) => {
  if (!categoryId) {
    localStorage.removeItem(AddCategoryStateItemKey);
    return;
  }

  localStorage.removeItem(EditCategoryStateItemKey + categoryId);
};

const saveCategoryStateToLocalStorage = (json: string, categoryId?: string) => {
  if (!categoryId) {
    localStorage.setItem(AddCategoryStateItemKey, json);
    return;
  }

  localStorage.setItem(EditCategoryStateItemKey + categoryId, json);
};

function parseCategory(jsonString: string): IEditorCategory {
  if (!isValidJsonString(jsonString)) {
    throw new Error("Invalid JSON string");
  }

  const obj = JSON.parse(jsonString);

  delete obj.date_saved;

  if (!isValidCategory(obj)) {
    throw new Error("Invalid category");
  }

  return obj;
}

const getCategoryStateFromLocalStorage = (
  categoryId?: string
): IEditorCategory | null => {
  const categoryState = localStorage.getItem(
    categoryId ? EditCategoryStateItemKey + categoryId : AddCategoryStateItemKey
  );

  if (!categoryState) return null;

  try {
    const category = parseCategory(categoryState);

    return category;
  } catch (_err) {
    return null;
  }
};

const combineCategoryFromStateWithCategory = (
  state: IEditorCategory,
  category: IEditorCategory
): IEditorCategory => {
  const mergedCategory: IEditorCategory = { ...category };
  Object.keys(state).forEach((key) => {
    const k = key as keyof IEditorCategory;

    if (k === "name") {
      mergedCategory[k] = { ...category[k], ...state[k] };
    } else {
      (category[k] as any) = state[k];
    }
  });
  return mergedCategory;
};

const getStringifiedCategoryOfFieldsThatAreDifferent = (
  currentCategory: IEditorCategory,
  originalCategory: IEditorCategory
): string => {
  const changes = getCategoryChangedFields(originalCategory, currentCategory);

  return JSON.stringify({
    ...changes.after,
    [LocalStorageSavedDateKey]: Date.now(),
  });
};

export const useLocalStorageCategorySaving = (
  category: IEditorCategory,
  categoryInitState: IEditorCategory,
  originalEditingCategory: IEditorCategory | null,
  type: EditorType,
  setCategory: (category: IEditorCategory) => void
) => {
  const saveToLocalStorage = () => {
    const save = () => {
      if (type === EditorType.Edit && originalEditingCategory) {
        saveCategoryStateToLocalStorage(
          getStringifiedCategoryOfFieldsThatAreDifferent(
            category,
            originalEditingCategory
          ),
          category._id
        );
        return;
      }

      saveCategoryStateToLocalStorage(
        getStringifiedCategoryOfFieldsThatAreDifferent(
          category,
          categoryInitState
        )
      );
    };

    if (type === EditorType.Edit && originalEditingCategory) {
      if (
        Object.keys(
          getCategoryChangedFields(originalEditingCategory, category).after
        ).length === 0
      ) {
        deleteSavedCategoryStateFromLocalStorage(originalEditingCategory._id);
        return;
      }
    } else if (
      Object.keys(getCategoryChangedFields(categoryInitState, category).after)
        .length === 0
    ) {
      deleteSavedCategoryStateFromLocalStorage();
      return;
    }

    save();
  };

  useEffect(() => {
    let saveTimer = setTimeout(() => {
      saveToLocalStorage();
    }, 500);

    return () => {
      clearTimeout(saveTimer);
    };
  });

  const { createNotification } = useNotifications();

  useEffect(() => {
    const getCategoryState = () => {
      const state = getCategoryStateFromLocalStorage(category._id);

      if (state) {
        createNotification(
          "Stav kategorie byl uložen při předchozí úpravě, chcete předchozí stav načíst?",
          () => {
            setCategory(combineCategoryFromStateWithCategory(state, category));
          },
          () => {
            deleteSavedCategoryStateFromLocalStorage(category._id);
          },
          {
            cancel: "Ne (vymaže uložený stav)",
            ok: "Ano",
          }
        );
      }
    };

    getCategoryState();
  }, [type, category._id]);
};
