import React from "react";
import styled from "styled-components";
import { useColors } from "../../../providers/theme/theme-provider";
import { IThemeColors } from "../../../providers/theme/themes";
import Button from "../../../ui/button";
import { FlexCenterAlign } from "../../../ui/common";
import { EditorType } from "../../../types/common";

export enum CategorySelectedWindow {
  BasicInfo,
  Subcategories,
  History,
  Description,
  SpecialAttributes,
}

const WindowSelectorButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 8px;
  }
`;

const getBackgroundColor = (
  selectedWindow: CategorySelectedWindow,
  is: CategorySelectedWindow,
  colors: IThemeColors
): string | undefined => {
  return selectedWindow === is ? colors.MAIN_250 : undefined;
};

interface IProps {
  selectedWindow: CategorySelectedWindow;
  setSelectedWindow(window: CategorySelectedWindow): void;
  editorType: EditorType;
}

export const WindowSelector = ({
  selectedWindow,
  setSelectedWindow,
  editorType,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <FlexCenterAlign
      style={{
        margin: "0px 8px",
        marginBottom: 20,
      }}
    >
      <WindowSelectorButtonsWrapper>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              CategorySelectedWindow.BasicInfo,
              colors
            ),
          }}
          onClick={() => setSelectedWindow(CategorySelectedWindow.BasicInfo)}
        >
          Základní info
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              CategorySelectedWindow.Description,
              colors
            ),
          }}
          onClick={() => setSelectedWindow(CategorySelectedWindow.Description)}
        >
          Popis
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              CategorySelectedWindow.Subcategories,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(CategorySelectedWindow.Subcategories)
          }
        >
          Podkategorie
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              CategorySelectedWindow.SpecialAttributes,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(CategorySelectedWindow.SpecialAttributes)
          }
        >
          Speciální atributy
        </Button>
        {editorType !== EditorType.Add && (
          <Button
            style={{
              backgroundColor: getBackgroundColor(
                selectedWindow,
                CategorySelectedWindow.History,
                colors
              ),
            }}
            onClick={() => setSelectedWindow(CategorySelectedWindow.History)}
          >
            Historie
          </Button>
        )}
      </WindowSelectorButtonsWrapper>
    </FlexCenterAlign>
  );
};

export default WindowSelector;
