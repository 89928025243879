import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppConfig, useParameters } from "../../../common/redux-hooks";
import { NonTranslatedString } from "../../../constants/language";
import { IParameterID } from "../../../types/parameter";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButtonActionStyles,
  EditButton,
  FlexCenterAll,
} from "../../../ui/common";
import Input from "../../../ui/input";
import Table from "../../../ui/table";

interface IRowActionButtons {
  parameter: IParameterID;
}

const RowActionButtons = ({
  parameter,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  return (
    <div>
      <EditButton
        path={`edit-parameter/${parameter._id}`}
        data-title="Upravit parametr"
      />
    </div>
  );
};

interface IProps {}

export const ParametersList = ({}: IProps): React.ReactElement<IProps> => {
  const parameters = useParameters();
  const navigate = useNavigate();
  const { language } = useAppConfig();
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        maxWidth: 700,
      }}
    >
      <Button
        style={AddButtonActionStyles}
        onClick={() => navigate("/parameters/add-parameter")}
      >
        Přidat parametr
      </Button>
      <Input
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vyhledat parametr"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <Table
        query={search}
        headerKeys={[
          {
            content: "",
            width: "10%",
          },
          {
            content: "Název",
            width: "40%",
            query_key: "title",
          },
          {
            content: (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                Jakákoliv hodnota
              </div>
            ),
            width: "25%",
          },
          {
            content: (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                Může být číslo
              </div>
            ),
            width: "25%",
          },
        ]}
        rows={parameters.map((p) => ({
          cells: [
            {
              content: <RowActionButtons parameter={p} />,
              key: "actions",
              value: "actions",
            },
            {
              content: p.title[language.locale] || <NonTranslatedString />,
              key: "title",
              value: p.title,
            },
            {
              content: (
                <FlexCenterAll>
                  <Checkbox
                    readonly
                    value={p.canHaveAnyValue}
                    setValue={() => null}
                  />
                </FlexCenterAll>
              ),

              key: "canHaveAnyValue",
              value: p.canHaveAnyValue,
            },
            {
              content: (
                <FlexCenterAll>
                  <Checkbox
                    readonly
                    value={p.canEditWithNumber}
                    setValue={() => null}
                  />
                </FlexCenterAll>
              ),
              key: "canEditWithNumber",
              value: p.canEditWithNumber,
            },
          ],
        }))}
      />
    </div>
  );
};

export default ParametersList;
