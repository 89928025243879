import { historyActionsModel } from "./../../model/query/historyActionsModel";
import { IHistoryAction } from "./../../types/history";

export enum HistoryActionsActions {
  SET_HISTORY_ACTIONS = "SET_HISTORY_ACTIONS",
}

type TSetHistoryActionsAction = {
  type: HistoryActionsActions.SET_HISTORY_ACTIONS;
  payload: IHistoryAction[];
};

export type THistoryActionsAction = TSetHistoryActionsAction;
