import React, { useState } from "react";
import { BsQuestion } from "react-icons/bs";
import { useProductFilters } from "../../../common/redux-hooks";
import { useModal } from "../../../providers/modal/modal-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import { SET_PRODUCT_FILTERS } from "../../../redux/actions/filterActions";
import { useDispatch } from "../../../redux/store";
import {
  initialProductFilters,
  IProductFilters,
} from "../../../types/products";
import Button from "../../../ui/button";
import { Flex, FlexCenterAlign, FlexCenterAll } from "../../../ui/common";
import {
  NumberFiltersWrapper,
  ProductFilterCategoriesSelect,
  ProductFilterManufacturerSelect,
  ProductFilterNumberItem,
  ProductFilterPackaging,
  ProductFilterParameterGroupSelect,
  ProductFilterParamsSelect,
} from "./components";

interface IProps {}

export const FiltersModal = ({}: IProps): React.ReactElement<IProps> => {
  const productFilterState = useProductFilters();

  const [filters, setFilters] = useState<IProductFilters>(
    productFilterState || initialProductFilters
  );
  const colors = useColors();
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const onApplyFiltersClick = () => {
    dispatch(SET_PRODUCT_FILTERS(filters));
    closeModal();
  };

  return (
    <div
      style={{
        maxHeight: "80vh",
        marginRight: 40,
        padding: 12,
        paddingRight: 20,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          fontSize: 18,
          opacity: 0.5,
          marginBottom: 20,
        }}
      >
        Vyhledá produkty, které
      </div>
      <Flex>
        <div>
          <ProductFilterCategoriesSelect
            setFilters={setFilters}
            categories={filters.categories}
          />
        </div>

        <div style={{ marginLeft: 16 }}>
          <ProductFilterManufacturerSelect
            manufacturer={filters.manufacturer}
            setFilters={setFilters}
          />
        </div>
      </Flex>

      <div
        style={{
          marginTop: 20,
        }}
      >
        <div>
          <ProductFilterParameterGroupSelect
            parameter_groups={filters.parameter_groups}
            setFilters={setFilters}
          />
        </div>
        <div
          style={{
            marginTop: 20,
          }}
        >
          <ProductFilterParamsSelect
            setFilters={setFilters}
            params={filters.params}
          />
        </div>
      </div>
      <FlexCenterAlign
        style={{
          marginTop: 24,
        }}
      >
        <div
          style={{
            marginRight: 12,
            marginLeft: 8,
          }}
        >
          Číselné hodnoty
        </div>
        <BsQuestion
          size={20}
          color={colors.OPPOSITE_MAIN_400}
          data-title={`Hodnota "0" bude považována jako nevyplněný filtr.`}
        />
      </FlexCenterAlign>
      <NumberFiltersWrapper
        style={{
          marginTop: 12,
        }}
      >
        <ProductFilterNumberItem
          title="Prodávací cena"
          setNumberItem={(price_vat) =>
            setFilters((f) => ({ ...f, price_vat }))
          }
          numberItem={filters.price_vat}
        />
        <ProductFilterNumberItem
          title="Nákupní cena"
          setNumberItem={(buy_price) =>
            setFilters((f) => ({ ...f, buy_price }))
          }
          numberItem={filters.buy_price}
        />
        <ProductFilterNumberItem
          title="Počet kusů skladem"
          setNumberItem={(stock) => setFilters((f) => ({ ...f, stock }))}
          numberItem={filters.stock}
        />
        <ProductFilterPackaging filters={filters} setFilters={setFilters} />
      </NumberFiltersWrapper>

      <FlexCenterAll
        style={{
          marginTop: 28,
          marginBottom: 12,
        }}
      >
        <Button
          onClick={onApplyFiltersClick}
          style={{
            marginRight: 12,
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
        >
          Aplikovat filtry
        </Button>
        <Button onClick={closeModal}>Storno</Button>
      </FlexCenterAll>
    </div>
  );
};

export default FiltersModal;
