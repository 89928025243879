export const LocalStorageSavedDateKey = "__ls_date";

export const AddProductStateItemKey = "add-product-state";
export const EditProductStateItemKey = "edit-product-state-";
export const AddCategoryStateItemKey = "add-category-state";
export const EditCategoryStateItemKey = "edit-category-state-";

export const clearLocalStorage = () => {
  const items = { ...localStorage };

  Object.keys(items).forEach((key) => {
    try {
      if (
        key.startsWith(AddProductStateItemKey) ||
        key.startsWith(EditProductStateItemKey) ||
        key.startsWith(AddCategoryStateItemKey) ||
        key.startsWith(EditCategoryStateItemKey)
      ) {
        const parse = JSON.parse(items[key]);
        const date = parse[LocalStorageSavedDateKey];

        if (isOlderThan24Hours(date) || !date) {
          localStorage.removeItem(key);
        }
      }
    } catch (err) {
      localStorage.removeItem(key); 
    }
  });
};

function isOlderThan24Hours(dateAsNumber: number) {
  const today = new Date();
  const inputDate = new Date(dateAsNumber);
  const DayAgo = new Date(today.getTime() - 24 * 60 * 60 * 1000);
  return inputDate < DayAgo;
}
