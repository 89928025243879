import { ICouponID } from "../../types/coupon";
import axios from "../axios";

export interface ICouponsModel {
  getCoupons(): Promise<ICouponID[]>;
  getCouponById(id: string): Promise<ICouponID>;
  addCoupon(data: FormData): Promise<ICouponID>;
  editCoupon(data: FormData): Promise<ICouponID>;
  removeCoupon(id: string): Promise<boolean>;
}

export const couponsModel: ICouponsModel = {
  getCoupons() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ICouponID[]>(`/coupons/get-all`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getCouponById(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ICouponID>(`/coupons/get-by-id`, {
          params: { _id },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addCoupon(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICouponID>(`/coupons/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editCoupon(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICouponID>(`/coupons/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeCoupon(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/coupons/remove-by-id`, {
          _id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
