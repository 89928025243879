import { ILanguageID } from "../../types/language";
import { IStoreID } from "../../types/store";
import {
  AppConfigActions,
  TAppConfigAction,
} from "../actions/appConfigActions";

export interface IAppConfig {
  language: ILanguageID;
  store: IStoreID;
}

export enum AppConfigEnum {
  IsLoading = "Config is loading",
}

export interface IAppConfigState {
  config: IAppConfig | AppConfigEnum.IsLoading;
}

const initialAppConfigState: IAppConfigState = {
  config: AppConfigEnum.IsLoading,
};

export const appConfigReducer = (
  state: IAppConfigState = initialAppConfigState,
  action: TAppConfigAction
): IAppConfigState => {
  switch (action.type) {
    case AppConfigActions.SET_APP_CONFIG:
      return { ...state, config: action.payload };
    default:
      return state;
  }
};
