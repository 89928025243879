import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/button";
import { AddButtonActionStyles } from "../../ui/common";

interface IProps {}

export const StoresActionButtons =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();

    return (
      <div>
        <Button style={AddButtonActionStyles} onClick={() => navigate("/stores/add-store")}>
          Přidat obchod
        </Button>
      </div>
    );
  };

export default StoresActionButtons;
