import React from "react";
import styled from "styled-components";
import GlobalStoreDropdown from "./global-store-dropdown";
import GlobalLanguageDropdown from "./global-language-dropdown";

const AppConfigDropdownWrapper = styled.div`
    position: fixed;
    top: 12px;
    right: 24px;
    z-index: 9998;

    display: flex;
    align-items: center;

    & > div {
        margin-left: 12px;
    }
`;

interface IProps {}

export const AppConfigDropdown = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <AppConfigDropdownWrapper>
      <GlobalStoreDropdown />
      <GlobalLanguageDropdown />
    </AppConfigDropdownWrapper>
  );
};

export default AppConfigDropdown;
