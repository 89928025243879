import React, { useState } from "react";
import { useAppConfig, useRole, useShippings } from "../../common/redux-hooks";
import {
  getBooleanTitle,
  getShippingTypeTitle,
} from "../../locales/determineString";
import { isDisabledByRole } from "../../types/role";
import { IShippingID } from "../../types/shipping";
import { EditButton, FlexCenterAll } from "../../ui/common";
import Input from "../../ui/input";
import Table from "../../ui/table";
import Checkbox from "../../ui/checkbox";

interface IRowActionButtons {
  shipping: IShippingID;
}

const RowActionButtons = ({
  shipping,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  const role = useRole();
  return (
    <div>
      {!isDisabledByRole(role, { type: "shipping", permission: "edit" }) && (
        <EditButton
          path={`edit-shipping/${shipping._id}`}
          data-title="Upravit dopravu"
        />
      )}
    </div>
  );
};

interface IProps {}

export const ShippingsList = ({}: IProps): React.ReactElement<IProps> => {
  const shippings = useShippings();
  const appConfig = useAppConfig();
  const currency = appConfig.language.currency;
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        maxWidth: 700,
      }}
    >
      <Input
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vyhledat výrobce"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <Table
        query={search}
        headerKeys={[
          {
            content: "",
            width: "10%",
          },
          {
            content: "Typ dopravy",
            width: "30%",
            query_key: "type",
          },
          {
            content: "Cena dopravy",
            width: "30%",
            query_key: "price",
          },
          {
            content: "Dostupné v obchodě",
            width: "30%",
            query_key: "available_on_eshop",
          },
        ]}
        rows={shippings.map((c) => ({
          cells: [
            {
              content: <RowActionButtons shipping={c} />,
              key: "actions",
              value: "actions",
            },
            {
              content: getShippingTypeTitle(c.type),
              key: "type",
              value: getShippingTypeTitle(c.type),
            },
            {
              content:
                (c.price?.[appConfig.language.locale] || 0) +
                " " +
                (currency ? currency.symbol : ""),
              key: "price",
              value:
                (c.price?.[appConfig.language.locale] || 0) +
                " " +
                (currency ? currency.symbol : ""),
            },
            {
              content: (
                <FlexCenterAll
                  style={{
                    marginLeft: -16,
                  }}
                >
                  <Checkbox
                    readonly
                    value={c.stores.some((s) => s._id === appConfig.store._id)}
                    setValue={() => null}
                  />
                </FlexCenterAll>
              ),
              key: "available_on_eshop",
              value: c.stores.some((s) => s._id === appConfig.store._id),
            },
          ],
        }))}
      />
    </div>
  );
};

export default ShippingsList;
