import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";

import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import thunk from "redux-thunk";
import { IReduxState } from "./types";

// import {
//   createStateSyncMiddleware,
//   initMessageListener,
// } from "redux-state-sync";

// import { persistStore, persistReducer, PersistConfig } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { PersistGate } from "redux-persist/integration/react";

// const persistConfig = {
//   key: "root",
//   storage,
//   blacklist: ["errors"],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunk,
    // createStateSyncMiddleware({
    //   blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
    // }),
  ],
});

// initMessageListener(store);

// export const persistor = persistStore(store);

type AppDispatch = typeof store.dispatch;

export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<IReduxState> = useReduxSelector;
