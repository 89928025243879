import React, { InputHTMLAttributes, PropsWithChildren } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { TReduxError } from "../types/error";
import CapslockWarning from "./capslock-warning";
import { colors } from "./colors";
import { FlexCenterAlign } from "./common";
import { ErrorTooltip } from "./tooltips/error-tooltip";

interface IStyledInputProps {
  hasError?: boolean;
}

const StyledInput = styled.input<IStyledInputProps>`
  padding: 0.6em 1em;
  font-size: 13px;
  border: none;
  font-family: inherit;
  color: inherit;

  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_400};
    border-radius: ${props.theme.borderRadius / 2}px;

    &:hover {
      background-color: ${props.theme.colors.MAIN_350};
    }

    &:focus {
      background-color: ${props.theme.colors.MAIN_350};
      outline: none;
    }
  `}

  &::placeholder {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.6;
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border: 1px solid ${theme.colors.ERROR_500};
      background-color: ${theme.colors.MAIN_350};

      &:hover,
      &:focus {
        background-color: ${theme.colors.MAIN_300};
      }
    `}
`;

interface IProps<T = number | string> {
  error?: TReduxError | string;
  wrapperStyle?: CSSProperties;
  warnAboutCapslock?: boolean;
  setValue?(value: T): void;
  value?: T;
}

export interface IInputProps<T = number | string>
  extends IProps<T>,
    Omit<InputHTMLAttributes<HTMLInputElement>, "value"> {}

export const Input = <T extends number | string>({
  children,
  error,
  wrapperStyle,
  warnAboutCapslock,
  type,
  setValue,
  ...props
}: IInputProps<T>): React.ReactElement<IInputProps<T>> => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props?.onChange && props.onChange(e);
    setValue?.(e.target.value as T);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    props.onBlur?.(e);

    if (type === "number" && typeof props.value === "string" && setValue) {
      const fixedValue = props.value.replace(",", ".");
      const parsed = parseFloat(fixedValue);

      if (isNaN(parsed)) {
        setValue((props.min || 0) as T);
        return;
      }

      const min = props.min !== undefined ? Number(props.min) : undefined;
      const max = props.min !== undefined ? Number(props.max) : undefined;

      let parsedValue = parsed as number;

      if (min !== undefined && parsedValue < min) {
        parsedValue = min;
      }

      if (max !== undefined && parsedValue > max) {
        parsedValue = max;
      }

      setValue(parsedValue as T);
    }
  };

  return (
    <FlexCenterAlign style={{ position: "relative", ...wrapperStyle }}>
      <StyledInput
        spellCheck={false}
        hasError={!!error}
        {...props}
        onChange={handleChange}
        onBlur={onBlur}
        type={type === "number" ? "text" : type}
      />

      <ErrorTooltip error={error} />
      {warnAboutCapslock && <CapslockWarning />}
    </FlexCenterAlign>
  );
};

export default Input;
