import React, { useState } from "react";
import { IProductParameter } from "../../../types/parameter";
import ParameterValuesMultiSelect from "../../../ui/parameter-value-multiselect";
import { ParameterInput } from "../product-editor/components/parameters-input/parameters-input";
import ParametersInput from "../product-editor/components/parameters-input/parameters-input";
import styled from "styled-components";
import Button from "../../../ui/button";
import { FlexCenterAll } from "../../../ui/common";
import { useModal } from "../../../providers/modal/modal-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { productModel } from "../../../model/query/productModel";
import { TSelectedProduct } from "../selected-products";
import { filterAndMapValidParams } from "../product-editor/product-editor";

const Wrapper = styled.div`
  padding-right: 60px;
`;

interface IProps {
  selectedProducts: TSelectedProduct[];
  type: "add" | "remove";
}

export const ParameterGroupActionModal = ({
  selectedProducts,
  type,
}: IProps): React.ReactElement<IProps> => {
  const { closeModal } = useModal();
  const colors = useColors();
  const [parameters, setParameters] = useState<IProductParameter[]>([]);

  const { call } = useQueryWithPopupErrorHandling();

  const confirm = () => {
    const data = new FormData();

    if (type === "add") {
      data.append(
        "params",
        JSON.stringify(
          parameters.filter((p) => !!p.parameter && (p.value_id || p.value_number))
        )
      );
    } else {
      data.append(
        "params",
        JSON.stringify(filterAndMapValidParams(parameters))
      );
    }
    data.append("products", JSON.stringify(selectedProducts.map((p) => p.id)));

    if (type === "add") {
      call(
        () => productModel.groupAddParameters(data),
        "Hromadné přidání parametrů nebylo úspěšné",
        "Hromadné přidání parametrů bylo úspěšné",
        () => closeModal()
      );
    } else {
      call(
        () => productModel.groupRemoveParameters(data),
        "Hromadné odebrání parametrů nebylo úspěšné",
        "Hromadné odebrání parametrů bylo úspěšné",
        () => closeModal()
      );
    }
  };

  return (
    <Wrapper>
      <ParametersInput params={parameters} setParams={setParameters} />
      <FlexCenterAll>
        <Button
          style={{ backgroundColor: colors.MAIN_350, marginRight: 12 }}
          hoverBackgroundColor={colors.MAIN_300}
          onClick={confirm}
        >
          {type === "add" ? "Přidat parametry" : "Odebrat parametry"}
        </Button>
        <Button onClick={closeModal}>Storno</Button>
      </FlexCenterAll>
    </Wrapper>
  );
};

export default ParameterGroupActionModal;
