import { LanguageActions, TLanguageAction } from "../actions/languagesActions";
import { ILanguageID } from "./../../types/language";

export interface ILanguagesState {
  languages: ILanguageID[];
}

const initialLanguagesState: ILanguagesState = {
  languages: [],
};

export const languagesReducer = (
  state: ILanguagesState = initialLanguagesState,
  action: TLanguageAction
): ILanguagesState => {
  switch (action.type) {
    case LanguageActions.SET_LANGUAGES:
      return { ...state, languages: action.payload };
    case LanguageActions.ADD_LANGUAGE:
      if (!action.payload) return state;

      return {
        ...state,
        languages: [...state.languages, action.payload],
      };
    case LanguageActions.EDIT_LANGUAGE:
      const languages = [...state.languages],
        i = languages.findIndex((l) => l._id === action.payload._id);

      languages[i] = action.payload;

      return { ...state, languages };
    case LanguageActions.REMOVE_LANGUAGE_BY_ID:
      return {
        ...state,
        languages: state.languages.filter((c) => c._id !== action.payload),
      };
    default:
      return state;
  }
};
