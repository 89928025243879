import React, { useEffect, useState } from "react";
import { emailsModel } from "../../../model/query/emailModel";
import { IEmailRecipientID } from "../../../types/emailRecipient";
import { HeaderText } from "../../../ui/common";
import Table, { TRow } from "../../../ui/table";
import Checkbox from "../../../ui/checkbox";

interface IProps {}

export const EmailRecipientsList = ({}: IProps): React.ReactElement<IProps> => {
  const [isLoading, setIsLoading] = useState(true);
  const [recipients, setRecipients] = useState<IEmailRecipientID[]>([]);

  const fetchEmails = async () => {
    try {
      setIsLoading(true);
      const e = await emailsModel.getEmailRecipients();
      setIsLoading(false);

      setRecipients(e);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  const setRecipientActive = async (
    recipient: IEmailRecipientID,
    isDisabled: boolean
  ) => {
    await emailsModel.changeRecipientActive(recipient._id, isDisabled);
    fetchEmails();
  };

  const rows: TRow[] = recipients.map((e) => ({
    cells: [
      {
        key: "isDisabled",
        value: e.isDisabled,
        width: "100px",
        content: (
          <Checkbox
            style={{ marginLeft: 10 }}
            value={!Boolean(e.isDisabled)}
            setValue={(value) => setRecipientActive(e, !value)}
          />
        ),
      },
      {
        key: "email",
        value: e.email,
        colSpan: 2,
        content: e.isDisabled ? (
          <span style={{ opacity: 0.2 }}>{e.email}</span>
        ) : (
          e.email
        ),
      },
    ],
  }));

  return (
    <div>
      <HeaderText
        style={{
          marginBottom: 20,
          fontSize: 18,
          marginTop: 32,
        }}
      >
        Příjemci emailů (odběratelé novinek)
      </HeaderText>
      <Table
        emptyColSpan={3}
        loading={isLoading}
        headerKeys={[
          {
            content: "Aktivní",
            width: "100px",
          },
          { content: "E-mail", colSpan: 2 },
        ]}
        rows={rows}
      />
    </div>
  );
};

export default EmailRecipientsList;
