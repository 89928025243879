import React, { useRef } from "react";
import { SimplePopupType } from "./data";
import SimplePopupWindow, {
  ISimplePopupWindowRef,
} from "./simple-popup-window";

interface ISimplePopupContext {
  closeSimplePopup(): void;
  createSimplePopup(title: string, type: SimplePopupType): void;
}

const SimplePopupContext = React.createContext<ISimplePopupContext>(
  {} as ISimplePopupContext
);

export const useSimplePopups = () => {
  return React.useContext(SimplePopupContext);
};

interface IProps {
  children: React.ReactNode;
}

export const SimplePopupProvider = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const simplePopupRef = useRef<ISimplePopupWindowRef>(null);

  const simplePopupContext: ISimplePopupContext = {
    createSimplePopup(title, type) {
      simplePopupRef.current?.show(title, type);
    },
    closeSimplePopup() {
      simplePopupRef.current?.close();
    },
  };

  return (
    <SimplePopupContext.Provider value={simplePopupContext}>
      <SimplePopupWindow ref={simplePopupRef} />
      {children}
    </SimplePopupContext.Provider>
  );
};

export default SimplePopupProvider;
