export const getPrice = (value: number, currency: string) => {
  const fixedPrice = value.toFixed(2);
  const [integerPart, fractionalPart] = fixedPrice.split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    " "
  );

  return `${formattedIntegerPart},${fractionalPart} ${currency}`;
};

export const getPriceOrFree = (value: number | undefined, currency: string) => {
  if (value) {
    return getPrice(value, currency);
  }

  return "Zdarma";
};
