import React from "react";
import { useNavigate } from "react-router-dom";
import { EditButton, Flex } from "../../../ui/common";
import Table, { TRow } from "../../../ui/table";
import { useSelector } from "../../../redux/store";
import { ILanguageID } from "../../../types/language";
import { useRole } from "../../../common/redux-hooks";
import { isDisabledByRole } from "../../../types/role";
import { IStoreID } from "../../../types/store";

interface IRowActionButtons {
  store: IStoreID;
}

const RowActionButtons = ({
  store,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  const role = useRole();
  return (
    <Flex>
      {!isDisabledByRole(role, { type: "store", permission: "edit" }) && (
        <EditButton
          path={`edit-store/${store._id}`}
          data-title="Upravit obchod"
        />
      )}
    </Flex>
  );
};

interface IProps {
  query: string;
}

export const StoresList = ({}: IProps): React.ReactElement<IProps> => {
  const { stores } = useSelector(({ stores }) => stores);

  const storeRows: TRow[] = stores.map((store) => {
    return {
      cells: [
        {
          content: <RowActionButtons store={store} />,
          key: "actions",
          value: null,
        },
        {
          content: store.key,
          key: "key",
          value: store.key,
        },
        {
          content: store.title,
          key: "title",
          value: store.title,
        },
      ],
    };
  });

  return (
    <div
      style={{
        marginRight: 20,
        width: 600,
        marginBottom: 40,
      }}
    >
      <Table
        headerKeys={[
          { content: "", query_key: "" },
          { content: "Klíč obchodu", query_key: "key" },
          { content: "Název", query_key: "title" },
        ]}
        rows={storeRows}
      />
    </div>
  );
};

export default StoresList;
