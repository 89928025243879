import { isLanguageStringEmpty } from "../../../../common/functions";
import { TGetError } from "../../../../common/redux-hooks";
import { TLanguageString } from "../../../../types/common";
import { ErrorType, TReduxError } from "../../../../types/error";
import { IUserInfo } from "./user-info-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.EDIT_USER_INFO);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TEditUserInfoErrorsObject = {
  first_name_empty?: TReduxError;
  last_name_empty?: TReduxError;
  user_name_empty?: TReduxError;
  user_name_already_exists?: TReduxError;
};

type TEditUserInfoErrors = {
  errors: TEditUserInfoErrorsObject;
  isValid: boolean;
};

export const getEditUserInfoErrors = (
  getError: TGetError,
  userInfo: IUserInfo
): TEditUserInfoErrors => {
  const first_name_empty = getErrorOfKey(
    "first_name_empty",
    getError,
    userInfo.firstName === ""
  );

  const last_name_empty = getErrorOfKey(
    "last_name_empty",
    getError,
    userInfo.lastName === ""
  );

  const user_name_empty = getErrorOfKey(
    "user_name_empty",
    getError,
    userInfo.userName === ""
  );

  const user_name_already_exists_params = getError(
    "user_name_already_exists",
    ErrorType.EDIT_USER_INFO,
    {
      value: userInfo.userName,
    }
  )?.params;

  const user_name_already_exists = getErrorOfKey(
    "user_name_already_exists",
    getError,
    !!(
      user_name_already_exists_params &&
      user_name_already_exists_params.value === userInfo.userName
    )
  );

  return {
    errors: {
      first_name_empty,
      last_name_empty,
      user_name_empty,
      user_name_already_exists,
    },
    isValid:
      !first_name_empty &&
      !last_name_empty &&
      !user_name_empty &&
      !user_name_already_exists,
  };
};
