export type ISpecialAttribute = {
  key: string;
  value: string;
};

export interface ISpecialAttributeID extends ISpecialAttribute {
  _id: string;
}

export const validSpecialAttributeFilter = (
  specialAttribute: ISpecialAttributeID
) =>
  !!specialAttribute._id && !!specialAttribute.key && !!specialAttribute.value;

export const mapSpecialAttribute = (
  specialAttribute: ISpecialAttributeID
): ISpecialAttribute => ({
  key: specialAttribute.key,
  value: specialAttribute.value,
});
