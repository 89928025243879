import { TGetError } from "../../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../../types/error";
import { IEditorParameterGroup } from "./parameter-group-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.PARAMETER_GROUP);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TParameterGroupEditorErrorsObject = {
  parameters_empty?: TReduxError;
  title_empty?: TReduxError;
};

type TParameterGroupEditorErrors = {
  errors: TParameterGroupEditorErrorsObject;
  isValid: boolean;
};

export const getParameterGroupEditorErrors = (
  getError: TGetError,
  group: IEditorParameterGroup
): TParameterGroupEditorErrors => {
  const title_empty = getErrorOfKey(
    "title_empty",
    getError,
    group.title === ""
  );

  const parameters_empty = getErrorOfKey(
    "parameters_empty",
    getError,
    group.parameters.length === 0
  );

  return {
    errors: {
      parameters_empty,
      title_empty,
    },
    isValid: !parameters_empty && !title_empty,
  };
};
