import React from "react";
import styled, { css } from "styled-components";

const AppleSwitcherStyled = styled.div<{ value: boolean }>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_200};

    border-radius: ${props.theme.borderRadius}px;

    &::after {
      position: absolute;
      border-radius: ${props.theme.borderRadius}px;
      left: 1px;
      top: 1px;
      content: "";
      background-color: #fff;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      width: 21px;
      height: 21px;
      transition: left 200ms ease;
    }
  `}

  transition: background-color 250ms ease;
  cursor: pointer;
  position: relative;
  width: 42px;
  height: 23px;

  ${({ value }) =>
    value &&
    css`
      background-color: #4bd763;

      &::after {
        left: calc(100% - 22px);
      }
    `}
`;

interface IProps {
  value: boolean;
  setValue(value: boolean): void;
}

export const AppleSwitcher = ({
  value,
  setValue,
}: IProps): React.ReactElement<IProps> => {
  return <AppleSwitcherStyled onClick={() => setValue(!value)} value={value} />;
};

export default AppleSwitcher;
