import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { Animations } from "../types/theme";

const ViewWrapperStyled = styled.div<{ active: boolean }>`
  position: relative;
  padding-top: 60px;
  padding-bottom: 40px;

  ${(props) =>
    props.theme.animations === Animations.Pretty &&
    css`
      transform: translateX(80px);
      opacity: 0;
      transition: transform 250ms ease, opacity 100ms ease 50ms;

      ${props.active &&
      css`
        transform: translateX(0px);
        opacity: 1;
      `}
    `}
`;

interface IProps extends PropsWithChildren {
  style?: CSSProperties;
}

export const ViewWrapper = ({
  children,
  style,
}: IProps): React.ReactElement<IProps> => {
  const [active, setActive] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      setActive(true);
    }
  }, [wrapperRef.current]);

  return (
    <ViewWrapperStyled ref={wrapperRef} style={style} active={active}>
      {children}
    </ViewWrapperStyled>
  );
};

export default ViewWrapper;
