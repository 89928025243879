import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";
import { useClickOutside } from "../../common/use-click-outside";
import { CloseButton, InputsWrapper } from "../../ui/common";
import LazyLoadingGalleryImage from "../../ui/lazy-loading-image";
import Overlay from "../common/overlay";

export interface IImageWindowRef {
  close(): void;
  show(src: string): void;
  isImageWindowOpened(): boolean;
}

interface IProps {
  setIsImageWindowOpened(value: boolean): void;
}

export const ImageWindow = forwardRef(
  (
    { setIsImageWindowOpened }: IProps,
    ref: React.ForwardedRef<IImageWindowRef>
  ): React.ReactElement<IProps> => {
    const [active, setActive] = useState(false);
    const [source, setSource] = useState("");

    useImperativeHandle(
      ref,
      () => ({
        close() {
          setActive(false);
          setIsImageWindowOpened(false);
        },
        show(src) {
          setActive(true);
          setIsImageWindowOpened(true);
          setSource(src);
        },
        isImageWindowOpened() {
          return active;
        },
      }),
      [active]
    );

    const escHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setActive(false);
        setIsImageWindowOpened(false);
      }
    };

    const wrapperRef = useRef<HTMLDivElement>(null);

    useClickOutside(wrapperRef, () => {
      setActive(false);
      setIsImageWindowOpened(false);
    });

    useEffect(() => {
      document.addEventListener("keydown", escHandler, false);
      return () => {
        document.removeEventListener("keydown", escHandler, false);
      };
    }, []);

    return (
      <Overlay active={active} zIndex={10000}>
        <div
          ref={wrapperRef}
          style={{
            position: "relative",
          }}
        >
          <CloseButton
            onClick={() => {
              setActive(false);
              setIsImageWindowOpened(false);
            }}
            style={{
              position: "absolute",
              right: -48,
            }}
            data-title="Zavřít okno (Esc)"
          />
          <LazyLoadingGalleryImage
            src={source}
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "90vh",
              maxWidth: "90vw",
            }}
          />
        </div>
      </Overlay>
    );
  }
);

export default ImageWindow;
