import { galleryModel } from "../../model/query/galleryModel";
import { IImageDB } from "../../types/gallery";

export enum GalleryActions {
  SET_IMAGES = "SET_IMAGES",
  ADD_IMAGES = "ADD_IMAGES",
  EDIT_IMAGE = "EDIT_IMAGE",
  REMOVE_IMAGE_BY_ID = "REMOVE_IMAGE_BY_ID",
}

type TSetImagesAction = {
  type: GalleryActions.SET_IMAGES;
  payload: IImageDB[];
};

type TUploadImageAction = {
  type: GalleryActions.ADD_IMAGES;
  payload: IImageDB[];
};

type TEditImageAction = {
  type: GalleryActions.EDIT_IMAGE;
  payload: IImageDB;
};

type TRemoveImageByIdAction = {
  type: GalleryActions.REMOVE_IMAGE_BY_ID;
  payload: string;
};

export type TGalleryAction =
  | TSetImagesAction
  | TUploadImageAction
  | TEditImageAction
  | TRemoveImageByIdAction;

export const UPLOAD_IMAGE = async (data: FormData) => {
  try {
    const images = await galleryModel.uploadImage(data);

    return {
      type: GalleryActions.ADD_IMAGES,
      payload: images,
    };
  } catch (err) {
    throw err;
  }
};

export const EDIT_IMAGE = async (data: FormData) => {
  try {
    const image = await galleryModel.editImage(data);

    return {
      type: GalleryActions.EDIT_IMAGE,
      payload: image,
    };
  } catch (err) {
    throw err;
  }
};

export const REMOVE_IMAGE_BY_ID = async (id: string) => {
  try {
    await galleryModel.removeImageById(id);

    return {
      type: GalleryActions.REMOVE_IMAGE_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw err;
  }
};
