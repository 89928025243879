import { IParameterModel, parameterModel } from "./parameterModel";
import { IProductModel, productModel } from "./productModel";
import { categoryModel, ICategoryModel } from "./categoryModel";
import { ILanguageModel, languageModel } from "./languageModel";
import { IGalleryModel, galleryModel } from "./galleryModel";
import { ITestingModel, testingModel } from "./testingModel";
import { IUserModel, userModel } from "./userModel";
import { IUsersModel, usersModel } from "./usersModel";

export interface ICombinedQueries {
    categoryModel: ICategoryModel;
    productModel: IProductModel;
    languageModel: ILanguageModel;
    galleryModel: IGalleryModel;
    testingModel: ITestingModel;
    userModel: IUserModel;
    usersModel: IUsersModel;
    parameterModel: IParameterModel;
}

export const combineQueries = (): ICombinedQueries => ({
    categoryModel,
    productModel,
    languageModel,
    galleryModel,
    testingModel,
    userModel,
    usersModel,
    parameterModel
});
