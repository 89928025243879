import { ICategoryId, IPopulatedCategoryId, IUnpopulatedCategory } from "../../types/category";
import axios from "../axios";

export interface ICategoryModel {
  getCategories: () => Promise<IUnpopulatedCategory[]>;
  getCategoryById: (id: string) => Promise<ICategoryId>;
  addCategory: (data: FormData) => Promise<ICategoryId>;
  editCategory: (data: FormData) => Promise<ICategoryId>;
  removeCategoryById: (id: string) => Promise<boolean>;
  removeCategoryFromSubcategories: (
    parent_id: string,
    category_id: string
  ) => Promise<boolean>;
}

export const populateCategories = (categories: IUnpopulatedCategory[]): IPopulatedCategoryId[] => {
  let populatedCategories: IPopulatedCategoryId[] = [];

  categories.forEach((c) => {
    populatedCategories.push({
      ...c,
      parents: [],
      subcategories: populateSubcategories(categories, c.subcategories, [
        c._id,
      ]),
    });
  });

  return populatedCategories;
};

const populateSubcategories = (
  categories: IUnpopulatedCategory[],
  subcategories: string[],
  parents: string[]
): IPopulatedCategoryId[] => {
  let populatedCategories: IPopulatedCategoryId[] = [];

  subcategories.forEach((s) => {
    let c = categories.find((ca) => ca._id === s);

    if (c && !parents.includes(c._id)) {
      populatedCategories.push({
        ...c,
        subcategories: populateSubcategories(categories, c.subcategories, [
          ...parents,
          c._id,
        ]),
        parents: parents,
      });
    }
  });

  return populatedCategories;
};

export const categoryModel: ICategoryModel = {
  getCategories() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IUnpopulatedCategory[]>(`/categories/get-all`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getCategoryById(id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ICategoryId>(`/categories/get-by-id`, {
          params: {
            id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addCategory(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICategoryId>(`/categories/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editCategory(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICategoryId>(
          `/categories/edit`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeCategoryById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/categories/remove-by-id`, {
          id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeCategoryFromSubcategories(parent_id, category_id) {
    return new Promise(async (res, rej) => {
      const response = await axios.post<boolean>(
        `/categories/remove-from-subcategories`,
        {
          parent_id,
          category_id,
        }
      );

      res(response.data);
    });
  },
};
