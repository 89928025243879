import React, { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FiEye } from "react-icons/fi";
import styled, { css } from "styled-components";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useTasks } from "../../../common/redux-hooks";
import { useModal } from "../../../providers/modal/modal-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_TASK,
  EDIT_TASK,
  REMOVE_TASK_BY_ID
} from "../../../redux/actions/tasksActions";
import { useDispatch } from "../../../redux/store";
import { EditorType } from "../../../types/common";
import {
  getTaskPriorityIndex, ITask,
  ITaskDB,
  TaskState
} from "../../../types/tasks";
import Button from "../../../ui/button";
import Dropdown from "../../../ui/dropdown";
import IconButton from "../../../ui/icon-button";
import Input from "../../../ui/input";
import { getTaskStateIndex } from "./../../../types/tasks";
import {
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  HeaderText,
  InputsWrapper
} from "./../../../ui/common";
import { getPriorityComponent, getTextByState } from "./components";
import { TasksContext } from "./context";
import TaskView from "./task-view";

const TaskTable = styled.table`
  margin: 8px;
  border-collapse: collapse;
  width: 100%;
`;

const TaskItemWrapper = styled.tr`
  & > td {
    padding-top: 4px;
  }

  & > td:not(:first-child) {
    padding: 4px 8px;
  }
`;

type TSortBy = {
  type: "low" | "high";
  column: number;
};

const TaskHeadCellStyled = styled.td`
  cursor: pointer;
  user-select: none;

  ${({ theme }) => css`
    color: ${theme.colors.OPPOSITE_MAIN_500};
  `}

  .chevron-wrapper {
    opacity: 0;
  }

  &:hover {
    .chevron-wrapper {
      opacity: 0.7;
    }
  }
`;

interface ITaskHeadCellProps {
  index?: number;
  sortBy?: TSortBy;
  text?: string;
  onClick?(): void;
}

export const TaskHeadCell = ({
  index,
  text,
  sortBy,
  onClick,
}: ITaskHeadCellProps): React.ReactElement<ITaskHeadCellProps> => {
  return (
    <TaskHeadCellStyled onClick={onClick}>
      <FlexCenterAll>
        <FlexCenterAlign
          style={{
            position: "relative",
          }}
        >
          {sortBy && (
            <div
              className="chevron-wrapper"
              style={{
                position: "absolute",
                marginLeft: -22,
                marginTop: 3,
                opacity: sortBy.column === index ? 1 : undefined,
              }}
            >
              {sortBy.type === "low" ? (
                <BsChevronDown size={16} />
              ) : (
                <BsChevronUp size={16} />
              )}
            </div>
          )}
          <div>{text}</div>
        </FlexCenterAlign>
      </FlexCenterAll>
    </TaskHeadCellStyled>
  );
};

interface ITaskItemProps {
  task: ITaskDB;
}

export const TaskItem = ({
  task,
}: ITaskItemProps): React.ReactElement<ITaskItemProps> => {
  const { createModal } = useModal();

  const viewTask = () => {
    createModal(
      <TaskView _id={task._id} type={EditorType.Edit} task={task} />,
      true
    );
  };

  const isDoneOrVerified =
    task.state === TaskState.Done || task.state === TaskState.Verified;
  const isInvalid = task.state === TaskState.Invalid;

  const colors = useColors();

  return (
    <TaskItemWrapper>
      <td>
        <FlexCenterAlign>
          <IconButton
            onClick={viewTask}
            data-title="Zobrazit úkol"
            style={{
              padding: 5,
              backgroundColor: colors.MAIN_300,
              marginRight: 4,
            }}
            hoverBackgroundColor={colors.MAIN_250}
          >
            <FiEye size={18} color={colors.OPPOSITE_MAIN_400} />
          </IconButton>
        </FlexCenterAlign>
      </td>
      <td
        style={{
          width: 32,
          backgroundColor: colors.MAIN_400,
        }}
      >
        <FlexCenterAll>{getPriorityComponent(task.priority)}</FlexCenterAll>
      </td>
      <td
        style={{
          fontWeight: "500",
          fontSize: 14,
          padding: "0px 16px",
          opacity: isDoneOrVerified || isInvalid ? 0.5 : 1,
          textDecoration:
            isDoneOrVerified || isInvalid ? "line-through" : undefined,
          color: isInvalid ? colors.ERROR_400 : undefined,
          cursor: "pointer",
          width: "240px",
        }}
        onClick={viewTask}
      >
        {task.title}
      </td>
      <td
        style={{
          fontSize: 13,
          opacity: 0.7,
          textAlign: "center",
          fontWeight: 500,
          width: 110,
        }}
      >
        {getTextByState(task.state)}
      </td>
      <td
        style={{
          fontWeight: 500,
          fontSize: 12,
          textAlign: "center",
          backgroundColor: colors.MAIN_400,
          opacity: task.due_date < Date.now() ? 0.7 : 1,
          padding: "4px 24px",
          width: 120,
        }}
      >
        {new Date(task.due_date).toLocaleDateString()}
      </td>
      <td
        style={{
          fontWeight: 500,
          fontSize: 12,
          textAlign: "center",
          backgroundColor: colors.MAIN_400,
          opacity: 0.9,
          padding: "4px 24px",
          width: 120,
        }}
      >
        {new Date(task.created_date).toLocaleDateString()}
      </td>
    </TaskItemWrapper>
  );
};

const TaskItemsHead = styled.thead`
  & > tr > td {
    font-weight: 400;
    letter-spacing: 0.02em;
    font-size: 13px;
    text-align: center;
    opacity: 0.75;
    padding-bottom: 8px;

    &:not(:first-child) {
      padding: 4px 8px;

      &:nth-child(2n) {
        ${(props) => css`
          background-color: ${props.theme.colors.MAIN_400};
        `}
      }
    }
  }
`;

interface IProps {}

export const Tasks = ({}: IProps): React.ReactElement<IProps> => {
  const tasks = useTasks();
  const { createModal } = useModal();
  const { call } = useQueryWithPopupErrorHandling();
  const [query, setQuery] = useState("");
  const [selectedState, setSelectedState] = useState<TaskState>(TaskState.New);

  const dispatch = useDispatch();

  const openNewTaskModal = () => {
    createModal(<TaskView type={EditorType.Add} />, true);
  };

  const appendTaskToForm = (form: FormData, task: ITask, _id?: string) => {
    const {
      assigned,
      assignees_edit_permission,
      description,
      due_date,
      priority,
      state,
      title,
      created_by,
    } = task;

    if (_id) {
      form.append("_id", _id);
    }
    form.append("assigned", JSON.stringify(assigned.map((u) => u._id)));
    form.append(
      "assignees_edit_permission",
      JSON.stringify(assignees_edit_permission)
    );
    form.append("description", description);
    form.append("due_date", due_date.toString());
    form.append("priority", priority);
    form.append("state", state);
    form.append("title", title);
    form.append("created_by", created_by._id);
  };

  const addTask = async (task: ITask) => {
    const form = new FormData();

    appendTaskToForm(form, task);

    try {
      const newTask = await ADD_TASK(form);

      dispatch(newTask);
    } catch (err) {}
  };

  const removeTask = async (id: string) => {
    call(
      () => REMOVE_TASK_BY_ID(id),
      "Odstranění úkolu nebylo úspěšné",
      "Odstranění úkolu bylo úspěšné",
      (data) => {
        dispatch(data);
      }
    );
  };

  const changeTask = async (task: ITaskDB) => {
    const form = new FormData();

    appendTaskToForm(form, task, task._id);

    try {
      const editedTask = await EDIT_TASK(form);

      dispatch(editedTask);
    } catch (err) {}
  };

  const [sortBy, setSortBy] = useState<TSortBy>({
    type: "low",
    column: -1,
  });

  const sortByChange = (index: number) => {
    if (sortBy.column !== index) {
      setSortBy({ column: index, type: "high" });
      return;
    }

    if (sortBy.column === index && sortBy.type === "high") {
      setSortBy({ ...sortBy, type: "low" });
      return;
    }

    setSortBy({ column: -1, type: "high" });
  };

  const filteredTasks = [...tasks]
    .filter((t) => {
      if (!query) {
        return true;
      }

      return t.title.toLowerCase().includes(query.toLowerCase());
    })
    .filter((t) => t.state === selectedState)
    .sort((a, b) => {
      const { type, column } = sortBy;

      switch (column) {
        case -1:
          return getTaskStateIndex(a.state) - getTaskStateIndex(b.state);
        case 0:
          if (type === "low") {
            return (
              getTaskPriorityIndex(b.priority) -
              getTaskPriorityIndex(a.priority)
            );
          }

          return (
            getTaskPriorityIndex(a.priority) - getTaskPriorityIndex(b.priority)
          );
        case 1:
          if (type === "low") {
            if (a.title < b.title) {
              return 1;
            } else {
              return -1;
            }
          }

          if (a.title < b.title) {
            return -1;
          } else {
            return 1;
          }
        case 2:
          if (type === "low") {
            return getTaskStateIndex(b.state) - getTaskStateIndex(a.state);
          }

          return getTaskStateIndex(a.state) - getTaskStateIndex(b.state);
        case 3:
          if (type === "low") {
            return b.due_date - a.due_date;
          }

          return a.due_date - b.due_date;

        case 4:
          if (type === "low") {
            return b.created_date - a.created_date;
          }

          return a.created_date - b.created_date;

        default:
          return 0;
      }
    });

  const colors = useColors();

  return (
    <TasksContext.Provider
      value={{
        addTask,
        removeTask,
        changeTask,
      }}
    >
      <Flex
        style={{
          marginBottom: 40,
        }}
      >
        <InputsWrapper
          style={{
            marginRight: 20,
          }}
        >
          <FlexCenterAlign
            style={{
              marginLeft: 12,
              marginBottom: 14,
              justifyContent: "space-between",
            }}
          >
            <HeaderText
              style={{
                marginRight: 12,
              }}
            >
              Úkoly
            </HeaderText>
            <FlexCenterAlign>
              <Dropdown
                list={Object.values(TaskState).map((state) => ({
                  content: getTextByState(state),
                  value: state,
                  query: getTextByState(state),
                  unique_id: state,
                }))}
                onSelectValue={(state) => setSelectedState(state)}
                selectedUniqueId={selectedState}
                isDeleteButtonDisabled
              />
              <Input
                style={{
                  marginLeft: 8,
                }}
                placeholder="Vyhledat úkol"
                setValue={(query) => setQuery(query)}
                value={query}
              />
            </FlexCenterAlign>
          </FlexCenterAlign>

          <Flex>
            <InputsWrapper
              style={{
                backgroundColor: colors.MAIN_400,
                width: "100%"
              }}
            >
              <TaskTable>
                <TaskItemsHead>
                  <tr>
                    <TaskHeadCell />
                    <TaskHeadCell
                      index={0}
                      sortBy={sortBy}
                      onClick={() => sortByChange(0)}
                      text="Priorita"
                    />
                    <TaskHeadCell
                      index={1}
                      sortBy={sortBy}
                      onClick={() => sortByChange(1)}
                      text="Název"
                    />
                    <TaskHeadCell
                      index={2}
                      sortBy={sortBy}
                      onClick={() => sortByChange(2)}
                      text="Stav"
                    />
                    <TaskHeadCell
                      index={3}
                      sortBy={sortBy}
                      onClick={() => sortByChange(3)}
                      text="Platnost do"
                    />
                    <TaskHeadCell
                      index={4}
                      sortBy={sortBy}
                      onClick={() => sortByChange(4)}
                      text="Vytvořeno"
                    />
                  </tr>
                </TaskItemsHead>
                <tbody
                  style={{
                    maxHeight: 40,
                  }}
                >
                  {filteredTasks.length ? (
                    filteredTasks.map((task, i) => (
                      <TaskItem key={i} task={task} />
                    ))
                  ) : (
                    <td colSpan={6}>
                      <FlexCenterAll
                        style={{
                          margin: 40,
                        }}
                      >
                        <HeaderText
                          style={{
                            fontSize: 18,
                            opacity: 0.5
                          }}
                        >
                          Nebyly nalezeny žádné úkoly
                        </HeaderText>
                      </FlexCenterAll>
                    </td>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={9}>
                      <FlexCenterAll>
                        <Button
                          onClick={openNewTaskModal}
                          style={{
                            fontSize: 12,
                            marginTop: 12,
                            backgroundColor: colors.MAIN_350,
                          }}
                          hoverBackgroundColor={colors.MAIN_300}
                        >
                          <FlexCenterAlign>
                            <BiPlus
                              size={19}
                              style={{
                                marginRight: 4,
                              }}
                            />
                            Vytvořit nový úkol
                          </FlexCenterAlign>
                        </Button>
                      </FlexCenterAll>
                    </td>
                  </tr>
                </tfoot>
              </TaskTable>
            </InputsWrapper>
          </Flex>
        </InputsWrapper>
      </Flex>
    </TasksContext.Provider>
  );
};

export default Tasks;
