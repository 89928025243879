import React from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useColors } from "../../providers/theme/theme-provider";
import colors from "../../ui/colors";
import { Flex } from "../../ui/common";
import IconButton from "../../ui/icon-button";

interface IProps {}

export const SettingsButton = ({}: IProps): React.ReactElement<IProps> => {
  const navigate = useNavigate();
  const colors = useColors()

  return (
    <Flex
      style={{
        marginTop: -12,
        marginBottom: 12,
        justifyContent: "flex-end",
      }}
    >
      <IconButton
        onClick={() => navigate("/settings")}
        style={{
          padding: 4,
          backgroundColor: colors.MAIN_250,
        }}
        data-title="Nastavení aplikace"
      >
        <IoSettingsOutline size={20} color={colors.OPPOSITE_MAIN_250} />
      </IconButton>
    </Flex>
  );
};

export default SettingsButton;
