import { populateCategories } from "../../model/query/categoryModel";
import { CategoryActions, TCategoryAction } from "../actions/categoryActions";
import {
  IPopulatedCategoryId,
  IUnpopulatedCategory,
} from "./../../types/category";

export interface ICategoriesState {
  categories: IUnpopulatedCategory[];
  populatedCategories: IPopulatedCategoryId[];
}

const initialCategoriesState: ICategoriesState = {
  categories: [],
  populatedCategories: [],
};

const getDefaultAndPopulatedCategories = (
  categories: IUnpopulatedCategory[]
): {
  categories: IUnpopulatedCategory[];
  populatedCategories: IPopulatedCategoryId[];
} => {
  return {
    categories: categories,
    populatedCategories: populateCategories(categories),
  };
};

export const categoriesReducer = (
  state: ICategoriesState = initialCategoriesState,
  action: TCategoryAction
): ICategoriesState => {
  switch (action.type) {
    case CategoryActions.SET_CATEGORIES:
      return {
        ...state,
        ...getDefaultAndPopulatedCategories(action.payload),
      };
    case CategoryActions.ADD_CATEGORY:
      if (!action.payload) return state;

      return {
        ...state,
        ...getDefaultAndPopulatedCategories([
          ...state.categories,
          {
            ...action.payload,
            available_in_stores: action.payload.available_in_stores.map(
              (s) => s._id
            ),
            stores: action.payload.stores.map((s) => s._id),
          },
        ]),
      };
    case CategoryActions.EDIT_CATEGORY:
      return (() => {
        const { payload } = action;

        if (!payload) return state;

        const c = [...state.categories],
          i = c.findIndex((cat) => cat._id === payload._id);

        c[i] = {
          ...payload,
          available_in_stores: payload.available_in_stores.map((s) => s._id),
          stores: payload.stores.map((s) => s._id),
        };

        return { ...state, ...getDefaultAndPopulatedCategories(c) };
      })();
    case CategoryActions.REMOVE_CATEGORY_BY_ID:
      if (!action.payload) return state;

      return {
        ...state,
        ...getDefaultAndPopulatedCategories(
          state.categories.filter((c) => c._id !== action.payload)
        ),
      };
    case CategoryActions.REMOVE_CATEGORY_FROM_SUBCATEGORIES:
      return (() => {
        const { payload } = action;

        if (!payload) return state;

        const c = [...state.categories],
          i = c.findIndex((cat) => cat._id === payload.parent_id);

        c[i].subcategories = c[i].subcategories.filter(
          (cat) => cat !== payload.category_id
        );

        return { ...state, ...getDefaultAndPopulatedCategories(c) };
      })();
    default:
      return state;
  }
};
