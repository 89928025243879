import { ICurrencyID } from "../../types/currency";
import { CurrencyActions, TCurrencyAction } from "./../actions/currencyActions";

export interface ICurrenciesState {
  currencies: ICurrencyID[];
}

const initialcurrenciesState: ICurrenciesState = {
  currencies: [],
};

export const currenciesReducer = (
  state: ICurrenciesState = initialcurrenciesState,
  action: TCurrencyAction
): ICurrenciesState => {
  switch (action.type) {
    case CurrencyActions.SET_CURRENCIES:
      return { ...state, currencies: action.payload };
    case CurrencyActions.ADD_CURRENCY:
      return {
        ...state,
        currencies: [...state.currencies, action.payload],
      };
    case CurrencyActions.EDIT_CURRENCY:
      let currencies = [...state.currencies],
        index = currencies.findIndex((i) => i._id === action.payload._id);

      currencies[index] = action.payload;

      return { ...state, currencies };
    case CurrencyActions.REMOVE_CURRENCY_BY_ID:
      return {
        ...state,
        currencies: state.currencies.filter((p) => p._id !== action.payload),
      };
    default:
      return state;
  }
};
