import { Navigate, Route, Routes } from "react-router-dom";
import { useLoggedIn } from "../common/redux-hooks";
import { EditorType } from "../types/common";
import Admin from "../view/admin";
import Categories from "../view/categories";
import Currencies from "../view/currencies";
import CurrencyEditor from "../view/currencies/currency-editor/currency-editor";
import Gallery, { GalleryListMode } from "../view/gallery";
import Languages from "../view/languages";
import LanguageEditor from "../view/languages/language-editor/language-editor";
import Manufacturers from "../view/manufacturers";
import ManufacturersEditor from "../view/manufacturers/manufacturers-editor/manufacturers-editor";
import ParameterGroups from "../view/parameter-groups";
import ParameterGroupEditor from "../view/parameter-groups/parameter-group-editor/parameter-group-editor";
import ParameterEditor from "../view/parameters/parameter-editor/parameter-editor";
import ParameterValueEditor from "../view/parameters/parameter-value-editor/parameter-value-editor";
import Products from "../view/products";
import ProductEditor from "../view/products/product-editor/product-editor";
import Profile from "../view/profile";
import TestingEnvironment from "../view/testing";
import ViewWrapper from "../view/view-wrapper";
import { CategoryEditor } from "./../view/categories/category-actions/category-editor";
import { Parameters } from "./../view/parameters/index";
import { wrapWithPermission } from "./permission-wrapper";
import PaymentEditor from "../view/payments/payment-editor/payment-editor";
import ShippingEditor from "../view/shippings/shipping-editor/shipping-editor";
import Shippings from "../view/shippings";
import Payments from "../view/payments";
import Orders from "../view/orders";
import OrderEditor from "../view/orders/order-editor/order-editor";
import Analytics from "../view/analytics";
import TermsAndConditions from "../view/terms-and-conditions";
import TermsAndConditionsEditor from "../view/terms-and-conditions/terms-and-conditions-editor";
import ComplaintsInfo from "../view/complaints-info";
import ComplaintsInfoEditor from "../view/complaints-info/complaints-info-editor";
import PrivacyPolicy from "../view/privacy-policy";
import PrivacyPolicyEditor from "../view/privacy-policy/privacy-policy-editor";
import CouponsEditor from "../view/coupons/coupon-editor";
import Coupons from "../view/coupons";
import StoreEditor from "../view/stores/store-editor/store-editor";
import Stores from "../view/stores";
import Emails from "../view/email";
import SendEmail from "../view/email/send-email/send-email";
import Ads from "../view/ads";

export const ProductRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Products />, {
          type: "product",
        })}
      />
      <Route
        path="add-product"
        element={wrapWithPermission(
          <ViewWrapper>
            <ProductEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "product",
            permission: "add",
            accessDeniedPath: "/products",
          }
        )}
      />
      <Route
        path="edit-product/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <ProductEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "product",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/products",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const CategoryRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Categories />, {
          type: "category",
        })}
      />
      <Route
        path="add-category"
        element={wrapWithPermission(
          <ViewWrapper>
            <CategoryEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "category",
            permission: "add",
            accessDeniedPath: "/categories",
          }
        )}
      />
      <Route
        path="edit-category/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <CategoryEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "category",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/categories",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const ParameterRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Parameters />, { type: "parameter" })}
      />
      <Route
        path="add-parameter"
        element={wrapWithPermission(
          <ViewWrapper>
            <ParameterEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "parameter",
            permission: "add",
            accessDeniedPath: "/parameters",
          }
        )}
      />
      <Route
        path="edit-parameter/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <ParameterEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "parameter",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/parameters",
          }
        )}
      />
      <Route
        path="add-value"
        element={wrapWithPermission(
          <ViewWrapper>
            <ParameterValueEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "parameter",
            permission: "add",
            accessDeniedPath: "/parameters",
          }
        )}
      />
      <Route
        path="edit-value/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            {" "}
            <ParameterValueEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "parameter",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/parameters",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const ParameterGroupRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<ParameterGroups />, {
          type: "parameter_group",
        })}
      />
      <Route
        path="add-parameter-group"
        element={wrapWithPermission(
          <ViewWrapper>
            <ParameterGroupEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "parameter_group",
            permission: "add",
            accessDeniedPath: "/parameter-groups",
          }
        )}
      />
      <Route
        path="edit-parameter-group/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <ParameterGroupEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "parameter_group",
            permission: "edit",
            accessDeniedPath: "/parameter-groups",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const ManufacturerRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Manufacturers />, {
          type: "manufacturer",
        })}
      />
      <Route
        path="add-manufacturer"
        element={wrapWithPermission(
          <ViewWrapper>
            <ManufacturersEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "manufacturer",
            permission: "add",
            accessDeniedPath: "/manufacturers",
          }
        )}
      />
      <Route
        path="edit-manufacturer/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <ManufacturersEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "manufacturer",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/manufacturers",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const CurrencyRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Currencies />, {
          type: "currency",
        })}
      />
      <Route
        path="add-currency"
        element={wrapWithPermission(
          <ViewWrapper>
            <CurrencyEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "currency",
            permission: "add",
            accessDeniedPath: "/currencies",
          }
        )}
      />
      <Route
        path="edit-currency/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <CurrencyEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "currency",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/currencies",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const PaymentRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Payments />, {
          type: "payment",
        })}
      />
      <Route
        path="add-payment"
        element={wrapWithPermission(
          <ViewWrapper>
            <PaymentEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "payment",
            permission: "add",
            accessDeniedPath: "/payments",
          }
        )}
      />
      <Route
        path="edit-payment/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <PaymentEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "payment",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/payments",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const OrderRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Orders />, {
          type: "order",
        })}
      />
      <Route
        path="edit-order/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <OrderEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "order",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/orders",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const AnalyticsRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Analytics />, {
          type: "order",
        })}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const ShippingRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Shippings />, {
          type: "shipping",
        })}
      />
      <Route
        path="add-shipping"
        element={wrapWithPermission(
          <ViewWrapper>
            <ShippingEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "shipping",
            permission: "add",
            accessDeniedPath: "/shippings",
          }
        )}
      />
      <Route
        path="edit-shipping/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <ShippingEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "shipping",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/shippings",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const EmailRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Emails />, {
          type: "email",
        })}
      />
      <Route
        path="send-email"
        element={wrapWithPermission(
          <ViewWrapper>
            <SendEmail />
          </ViewWrapper>,
          {
            type: "email",
            permission: ["add", "edit"],
            accessDeniedPath: "/emails",
          }
        )}
      />
            <Route
        path="edit-email/:id"
        element={wrapWithPermission(
          <ViewWrapper>
            <SendEmail />
          </ViewWrapper>,
          {
            type: "email",
            permission: ["add", "edit"],
            accessDeniedPath: "/emails",
          }
        )}
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const AdsRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Ads />, {
          type: "email",
        })}
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const TermsAndConditionsRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<TermsAndConditions />, {
          type: "terms_and_conditions",
        })}
      />
      <Route
        path="edit/:store"
        element={wrapWithPermission(
          <ViewWrapper>
            <TermsAndConditionsEditor />
          </ViewWrapper>,
          {
            type: "terms_and_conditions",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/terms-and-conditions",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const CouponsRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Coupons />, {
          type: "coupons",
        })}
      />
      <Route
        path="edit/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <CouponsEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "coupons",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/coupons",
          }
        )}
      />
      <Route
        path="add"
        element={wrapWithPermission(
          <ViewWrapper>
            <CouponsEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "coupons",
            permission: ["add"],
            accessDeniedPath: "/coupons",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const ComplaintsInfoRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<ComplaintsInfo />, {
          type: "terms_and_conditions",
        })}
      />
      <Route
        path="edit/:store"
        element={wrapWithPermission(
          <ViewWrapper>
            <ComplaintsInfoEditor />
          </ViewWrapper>,
          {
            type: "terms_and_conditions",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/complaints-info",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const PrivacyPolicyRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<PrivacyPolicy />, {
          type: "terms_and_conditions",
        })}
      />
      <Route
        path="edit/:store"
        element={wrapWithPermission(
          <ViewWrapper>
            <PrivacyPolicyEditor />
          </ViewWrapper>,
          {
            type: "terms_and_conditions",
            permission: ["edit", "edit_language"],
            accessDeniedPath: "/privacy-policy",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const LanguagesRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Languages />, { type: "language" })}
      />
      <Route
        path="add-language"
        element={wrapWithPermission(
          <ViewWrapper>
            <LanguageEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "language",
            permission: "add",
            accessDeniedPath: "/languages",
          }
        )}
      />
      <Route
        path="edit-language/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <LanguageEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "language",
            permission: "edit",
            accessDeniedPath: "/languages",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const StoresRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Stores />, { type: "store" })}
      />
      <Route
        path="add-store"
        element={wrapWithPermission(
          <ViewWrapper>
            <StoreEditor type={EditorType.Add} />
          </ViewWrapper>,
          {
            type: "store",
            permission: "add",
            accessDeniedPath: "/storess",
          }
        )}
      />
      <Route
        path="edit-store/:_id"
        element={wrapWithPermission(
          <ViewWrapper>
            <StoreEditor type={EditorType.Edit} />
          </ViewWrapper>,
          {
            type: "store",
            permission: "edit",
            accessDeniedPath: "/stores",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const GalleryRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(
          <ViewWrapper
            style={{
              paddingBottom: 0,
            }}
          >
            <Gallery mode={GalleryListMode.Manage} />
          </ViewWrapper>,
          {
            type: "gallery",
          }
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const TestingRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<TestingEnvironment />, {
          type: "user",
        })}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(<Admin />, {
          type: "user",
        })}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const UserRoutes = () => {
  const logged = useLoggedIn();

  return (
    <Routes>
      <Route
        index
        element={wrapWithPermission(
          <ViewWrapper>
            <Profile />
          </ViewWrapper>,
          undefined,
          logged
        )}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
