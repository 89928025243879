import { ErrorType, TReduxError } from "../../types/error";

export enum ErrorsActions {
  SET_ERRORS = "SET_ERRORS",
  CLEAR_ERRORS_OF_TYPE = "CLEAR_ERRORS_OF_TYPE"
}

type TSetErrorsAction = {
  type: ErrorsActions.SET_ERRORS;
  payload: TReduxError[];
};

type TClearErrorsOfTypeAction = {
  type: ErrorsActions.CLEAR_ERRORS_OF_TYPE;
  payload: ErrorType;
};

export type TErrorsAction = TSetErrorsAction | TClearErrorsOfTypeAction;

export const SET_ERRORS = (errors: TReduxError[]) => {
  return {
    type: ErrorsActions.SET_ERRORS,
    payload: errors,
  };
};

export const CLEAR_ERRORS_OF_TYPE = (type: ErrorType) => {
  return {
    type: ErrorsActions.CLEAR_ERRORS_OF_TYPE,
    payload: type,
  };
};

