import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isLanguageStringEmpty } from "../../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useAppConfig, useErrors } from "../../../common/redux-hooks";
import { nonTranslatedString } from "../../../constants/language";
import { getParameterTypeTitle } from "../../../locales/determineString";
import { parameterModel } from "../../../model/query/parameterModel";
import { slugModel } from "../../../model/query/slugModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_PARAMETER,
  EDIT_PARAMETER,
  REMOVE_PARAMETER_BY_ID,
} from "../../../redux/actions/parameterActions";
import { useDispatch } from "../../../redux/store";
import {
  EditorType,
  TLanguageSlug,
  TLanguageString,
} from "../../../types/common";
import { ErrorType } from "../../../types/error";
import {
  IParameterID,
  IParameterValueID,
  ParameterType,
} from "../../../types/parameter";
import { defaultSlug } from "../../../types/slugs";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
  TextWithInfo,
} from "../../../ui/common";
import Dropdown from "../../../ui/dropdown";
import { InputWithTitle } from "../../../ui/input-with-title";
import LanguageSelector from "../../../ui/language-selector";
import ParameterValuesMultiSelect from "../../../ui/parameter-value-multiselect";
import SlugInput from "../../../ui/slug-input";
import BackButton from "../../components/back-button";
import { getParameterErrors } from "./editor-errors";

const mapParameterToEditorParameter = (
  parameter: IParameterID
): IEditorParameter => {
  const {
    _id,
    canEditWithNumber,
    canHaveAnyValue,
    title,
    type,
    values,
    slug,
    isVisibleInFilter,
    isVisibleOnProductPage,
    sortIndex,
    generateRelatedProducts,
    generateVariants,
  } = parameter;

  return {
    _id,
    canEditWithNumber,
    canHaveAnyValue,
    title,
    type,
    values,
    isVisibleInFilter: !!isVisibleInFilter,
    isVisibleOnProductPage: !!isVisibleOnProductPage,
    sortIndex: sortIndex || 0,
    slug: slug || {},
    generateRelatedProducts: !!generateRelatedProducts,
    generateVariants: !!generateVariants,
  };
};

const initialEditorParameter: IEditorParameter = {
  title: {},
  values: [],
  canEditWithNumber: true,
  canHaveAnyValue: true,
  type: ParameterType.None,
  slug: {},
  isVisibleOnProductPage: true,
  isVisibleInFilter: true,
  sortIndex: 0,
  generateRelatedProducts: false,
  generateVariants: false,
};

export interface IEditorParameter {
  _id?: string;
  title: TLanguageString;
  type: ParameterType;
  canHaveAnyValue: boolean;
  canEditWithNumber: boolean;
  values: IParameterValueID[];
  slug: TLanguageSlug;
  isVisibleOnProductPage: boolean;
  isVisibleInFilter: boolean;
  sortIndex: number;
  generateVariants: boolean;
  generateRelatedProducts: boolean;
}

interface IProps {
  type: EditorType;
}

export const ParameterEditor = ({
  type,
}: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const appConfig = useAppConfig();
  const [language, setLanguage] = useState(appConfig.language);
  const [parameter, setParameter] = useState<IEditorParameter>(
    initialEditorParameter
  );

  const colors = useColors();
  const { closeNotification } = useNotifications();
  const { createConfirmation } = useConfirmation();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getParameterErrors(
    getError,
    parameter,
    language.locale
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useQueryWithPopupErrorHandling();

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.PARAMETER);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getParameter = async (id: string) => {
      call(
        () => parameterModel.getParameterById(id),
        "Zobrazení parametru nebylo úspěšné",
        null,
        (parameter) => {
          setParameter(mapParameterToEditorParameter(parameter));
        },
        () => {
          navigate("/parameters");
        }
      );
    };

    if (_id && type === EditorType.Edit) {
      getParameter(_id);
    }
  }, [_id, type]);

  const appendParameterToForm = useCallback(
    (form: FormData) => {
      if (!isLanguageStringEmpty(parameter.title)) {
        form.append("title", JSON.stringify(parameter.title));
      }
      form.append(
        "canEditWithNumber",
        JSON.stringify(parameter.canEditWithNumber)
      );
      form.append("canHaveAnyValue", JSON.stringify(parameter.canHaveAnyValue));
      form.append("values", JSON.stringify(parameter.values.map((v) => v._id)));
      form.append("type", parameter.type);

      if (
        typeof parameter.sortIndex === "number" &&
        !isNaN(parameter.sortIndex)
      ) {
        form.append("sortIndex", parameter.sortIndex.toString());
      } else {
        form.append("sortIndex", "0");
      }

      form.append(
        "isVisibleOnProductPage",
        JSON.stringify(parameter.isVisibleOnProductPage)
      );
      form.append(
        "isVisibleInFilter",
        JSON.stringify(parameter.isVisibleInFilter)
      );

      form.append(
        "generateVariants",
        JSON.stringify(parameter.generateVariants)
      );

      form.append(
        "generateRelatedProducts",
        JSON.stringify(parameter.generateRelatedProducts)
      );

      Object.keys(parameter.slug).forEach((key) => {
        const slug = parameter.slug[key];

        if (!slug.userDefined && !slug.value && !parameter.title[key]) {
          delete parameter.slug[key];
        }
      });

      form.append("slug", JSON.stringify(parameter.slug));
    },
    [parameter]
  );

  const addParameter = () => {
    const data = new FormData();

    appendParameterToForm(data);

    call(
      () => ADD_PARAMETER(data),
      "Přidání parametru nebylo úspěšné",
      "Přidání parametru bylo úspěšné",
      (action) => {
        dispatch(action);
        navigate("/parameters");
      },
      (err) => {
        if (Array.isArray(err?.payload)) {
          dispatch(err);
        }
      }
    );
  };

  const editParameter = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit parametr", async () => {
      if (!_id) {
        return;
      }

      const data = new FormData();

      data.append("_id", _id);
      appendParameterToForm(data);

      call(
        () => EDIT_PARAMETER(data),
        "Úprava parametru nebyla úspěšná",
        "Úprava parametru byla úspěšná",
        (action) => {
          dispatch(action);

          if (exit) {
            navigate("/parameters");
          } else {
            setParameter(mapParameterToEditorParameter(action.payload));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeParameter = () => {
    createConfirmation(
      "Potvrzení: Odstranit parametr a jeho použití",
      async () => {
        if (!_id) {
          return;
        }

        call(
          () => REMOVE_PARAMETER_BY_ID(_id),
          "Odstranění parametru nebylo úspěšné",
          "Odstranění parametru bylo úspěšné",
          (remove) => {
            dispatch(remove);
            navigate("/parameters");
          }
        );
      }
    );
  };

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!isValid}
          onClick={addParameter}
        >
          Přidat parametr
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editParameter()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editParameter(true)}>
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removeParameter}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper
      node={node}
      deps={[isValid, appendParameterToForm]}
    >
      <div>
        <BackButton path="/parameters" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          {type === EditorType.Add ? "Přidání parametru" : "Úprava parametru"}
        </div>
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <LanguageSelector onSelectLanguage={setLanguage} />
        </div>
        <Flex
          style={{
            alignItems: "flex-start",
          }}
        >
          <InputsWrapper>
            <InputWithTitle
              info="Tento název se zobrazí na eshopu"
              required
              inputProps={{
                placeholder: !parameter.title[language.locale]
                  ? nonTranslatedString
                  : undefined,
                error: errors.title,
              }}
              wrapperStyle={{
                marginTop: 0,
              }}
              inputStyle={{
                width: 320,
              }}
              value={parameter.title[language.locale] || ""}
              setValue={(title) =>
                setParameter((p) => ({
                  ...p,
                  title: { ...p.title, [language.locale]: title },
                }))
              }
              title="Název parametru"
            />
            {parameter.slug && (
              <SlugInput
                error={
                  errors.slug_value_empty ||
                  errors.slug_value_invalid ||
                  errors.slug_already_exists
                }
                slug={parameter.slug[language.locale] || defaultSlug}
                setSlug={(slug) =>
                  setParameter((p) => ({
                    ...p,
                    slug: {
                      ...p.slug,
                      [language.locale]: slug,
                    },
                  }))
                }
                value={parameter.title[language.locale] || ""}
                getAvailableSlug={async () => {
                  if (!parameter.title[language.locale]) {
                    return defaultSlug;
                  }

                  return await slugModel.getAvailableSlug(
                    parameter.title[language.locale],
                    language.locale,
                    parameter._id
                  );
                }}
              />
            )}

            <div
              style={{
                marginTop: 20,
                fontSize: 12,
                color: colors.OPPOSITE_MAIN_100,
                fontWeight: 500,
              }}
            >
              Zobrazení parametru na eshopu
            </div>
            <FlexCenterAlign
              style={{
                marginTop: 16,
              }}
            >
              <Checkbox
                value={parameter.isVisibleOnProductPage}
                setValue={(isVisibleOnProductPage) =>
                  setParameter((p) => ({ ...p, isVisibleOnProductPage }))
                }
              />
              <div
                style={{
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                Je viditelný u produktu
              </div>
            </FlexCenterAlign>

            <FlexCenterAlign
              style={{
                marginTop: 10,
              }}
            >
              <Checkbox
                value={parameter.isVisibleInFilter}
                setValue={(isVisibleInFilter) =>
                  setParameter((p) => ({ ...p, isVisibleInFilter }))
                }
              />
              <div
                style={{
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                Je viditelný při filtraci na eshopu
              </div>
            </FlexCenterAlign>

            <div
              style={{
                marginTop: 10,
              }}
            >
              <InputWithTitle
                info="Vyšší číslo znamená že se parametr zobrazí jako první"
                inputProps={{
                  type: "number",
                }}
                wrapperStyle={{
                  marginTop: 0,
                }}
                inputStyle={{
                  width: 320,
                }}
                value={
                  parameter.sortIndex === undefined ? 0 : parameter.sortIndex
                }
                setValue={(sortIndex) =>
                  setParameter((p) => ({
                    ...p,
                    sortIndex,
                  }))
                }
                title="Index seřazení"
              />
            </div>
          </InputsWrapper>

          <div
            style={{
              marginLeft: 16,
            }}
          >
            <InputsWrapper>
              <div
                style={{
                  marginLeft: 8,
                  marginBottom: 4,
                }}
              >
                <TextWithInfo
                  info="Typ parameteru, umožní generování barev/datumů/skupiny číslic"
                  text="Typ parametru"
                />
              </div>
              <Dropdown
                list={Object.values(ParameterType).map((s) => ({
                  content: getParameterTypeTitle(s),
                  value: s,
                  unique_id: s,
                  query: getParameterTypeTitle(s),
                }))}
                onSelectValue={(type) =>
                  setParameter((p) => ({ ...p, type, params: undefined }))
                }
                isDeleteButtonDisabled
                selectedUniqueId={parameter.type}
                title="Typ parameteru"
              />
            </InputsWrapper>
            <Flex
              style={{
                marginTop: 16,
              }}
            >
              <InputsWrapper>
                <FlexCenterAlign
                  style={{
                    marginLeft: 8,
                    marginBottom: 4,
                  }}
                >
                  <FlexCenterAlign>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      value={parameter.generateVariants}
                      setValue={(generateVariants) =>
                        setParameter((p) => ({ ...p, generateVariants }))
                      }
                    />
                    <div
                      style={{
                        fontSize: 13,
                      }}
                    >
                      Automaticky generovat varianty
                    </div>
                  </FlexCenterAlign>

                  <TextWithInfo
                    info="Po zaškrtnutí automaticky vygeneruje varianty pro produkty s tímto parametrem, které mají stejnou parametrovou hodnotu"
                    text=""
                  />
                </FlexCenterAlign>

                <FlexCenterAlign
                  style={{
                    marginLeft: 8,
                    marginTop: 12,
                    marginBottom: 4,
                    justifyContent: "space-between",
                  }}
                >
                  <FlexCenterAlign>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      value={parameter.generateRelatedProducts}
                      setValue={(generateRelatedProducts) =>
                        setParameter((p) => ({ ...p, generateRelatedProducts }))
                      }
                    />
                    <div
                      style={{
                        fontSize: 13,
                      }}
                    >
                      Automaticky generovat související produkty
                    </div>
                  </FlexCenterAlign>

                  <TextWithInfo
                    info="Po zaškrtnutí automaticky vygeneruje související pro produkty s tímto parametrem, které mají stejnou parametrovou hodnotu"
                    text=""
                  />
                </FlexCenterAlign>
              </InputsWrapper>
            </Flex>
          </div>
        </Flex>

        <Flex
          style={{
            marginTop: 16,
          }}
        >
          <InputsWrapper>
            <FlexCenterAlign>
              <Checkbox
                value={parameter.canHaveAnyValue}
                setValue={(canHaveAnyValue) =>
                  setParameter((p) => ({ ...p, canHaveAnyValue }))
                }
              />
              <div
                style={{
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                Parametr může mít jakoukoliv hodnotu
              </div>
            </FlexCenterAlign>
            {!parameter.canHaveAnyValue && (
              <div
                style={{
                  marginTop: 12,
                }}
              >
                <ParameterValuesMultiSelect
                  error={errors.canHaveAnyValue}
                  onRemove={(id) =>
                    setParameter((p) => ({
                      ...p,
                      values: p.values.filter((v) => v._id !== id),
                    }))
                  }
                  onSelect={(parameterValue) =>
                    setParameter((p) => ({
                      ...p,
                      values: [...p.values, parameterValue],
                    }))
                  }
                  selectedIDs={parameter.values.map((v) => v._id)}
                />
              </div>
            )}
            <FlexCenterAlign
              style={{
                marginTop: 20,
              }}
            >
              <Checkbox
                value={parameter.canEditWithNumber}
                setValue={(canEditWithNumber) =>
                  setParameter((p) => ({ ...p, canEditWithNumber }))
                }
              />
              <div
                style={{
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                Hodnotu parametru může uživatel přepsat číslem
              </div>
            </FlexCenterAlign>
          </InputsWrapper>
        </Flex>
      </div>
    </BottomFixedWrapperWrapper>
  );
};

export default ParameterEditor;
