import { IManufacturerID } from "../../types/manufacturer";
import {
  ManufacturerActions,
  TManufacturerAction,
} from "./../actions/manufacturerActions";

export interface IManufacturersState {
  manufacturers: IManufacturerID[];
}

const initialManufacturersState: IManufacturersState = {
  manufacturers: [],
};

export const manufacturersReducer = (
  state: IManufacturersState = initialManufacturersState,
  action: TManufacturerAction
): IManufacturersState => {
  switch (action.type) {
    case ManufacturerActions.SET_MANUFACTURERS:
      return { ...state, manufacturers: action.payload };
    case ManufacturerActions.ADD_MANUFACTURER:
      return {
        ...state,
        manufacturers: [...state.manufacturers, action.payload],
      };
    case ManufacturerActions.EDIT_MANUFACTURER:
      let manufacturers = [...state.manufacturers],
        index = manufacturers.findIndex((i) => i._id === action.payload._id);

      manufacturers[index] = action.payload;

      return { ...state, manufacturers };
    case ManufacturerActions.REMOVE_MANUFACTURER_BY_ID:
      return {
        ...state,
        manufacturers: state.manufacturers.filter(
          (p) => p._id !== action.payload
        ),
      };
    default:
      return state;
  }
};
