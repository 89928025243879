import React from "react";
import { TReduxError } from "../types/error";
import {
  DeleteButton,
  DropdownSelectedItemsWrapper,
  FlexCenterAlign,
} from "./common";
import Dropdown, { TDropdownItem } from "./dropdown";
import { useColors } from "../providers/theme/theme-provider";
import styled, { css } from "styled-components";

const DropdownMultiSelectWrapper = styled.div`
  ${({theme}) => css`
  `}
`;

interface IProps {
  list: TDropdownItem[];
  selectedDropdownIDs: string[];
  onSelectValue(value: any, id: string): void;
  onRemoveUniqueID(id: string): void;
  deleteTitle?: string;
  title?: string;
  disabled?: boolean;
  error?: TReduxError;
}

export const DropdownMultiSelect = ({
  disabled,
  list,
  selectedDropdownIDs,
  onSelectValue,
  onRemoveUniqueID,
  deleteTitle,
  title,
  error,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <DropdownMultiSelectWrapper>
      <Dropdown
        disabled={disabled}
        onSelectValue={(value, id) => onSelectValue(value, id!)}
        list={list}
        title={title || "Vyberte hodnotu"}
        error={error}
      />
      <DropdownSelectedItemsWrapper>
        {selectedDropdownIDs.map((id, i) => (
          <FlexCenterAlign
            key={id}
            style={{
              backgroundColor: colors.MAIN_400,
              justifyContent: "space-between",
              fontSize: 11,
            }}
          >
            <div>{list.find((i) => i.unique_id === id)?.content}</div>
            <DeleteButton
              disabled={disabled}
              data-title={deleteTitle || "Odstranit hodnotu"}
              size={16}
              style={{
                marginLeft: 8,
                backgroundColor: "unset",
                padding: 2,
              }}
              hoverBackgroundColor={colors.MAIN_300}
              onClick={() => onRemoveUniqueID(id)}
            />
          </FlexCenterAlign>
        ))}
      </DropdownSelectedItemsWrapper>
    </DropdownMultiSelectWrapper>
  );
};

export default DropdownMultiSelect;
