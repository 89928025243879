import { TGetError } from "../../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../../types/error";
import { IEditorShipping } from "./shipping-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.SHIPPING);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TShippingEditorErrorsObject = {
  title_empty?: TReduxError;
  symbol_empty?: TReduxError;
  Shipping_shortcut_empty?: TReduxError;
  Shipping_shortcut_exists?: TReduxError;
};

type TShippingEditorErrors = {
  errors: TShippingEditorErrorsObject;
  isValid: boolean;
};

export const getShippingEditorErrors = (
  getError: TGetError,
  shipping: IEditorShipping
): TShippingEditorErrors => {
  return {
    errors: {},
    isValid: true,
  };
};
