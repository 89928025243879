import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { getErrorTranslation } from "../../common/errors";
import { useLoggedIn, useUser } from "../../common/redux-hooks";
import { useSimplePopups } from "../../providers/simple-popup/simple-popup-provider";
import { LOGIN_USER } from "../../redux/actions/userActions";
import { useDispatch } from "../../redux/store";
import { ErrorType, TError, TReduxError } from "../../types/error";
import Button from "../../ui/button";
import colors from "../../ui/colors";
import { FlexCenterAll, InputsWrapper } from "../../ui/common";
import Input from "../../ui/input";
import { AddButtonStyles } from "./../../ui/common";
import { SimplePopupType } from "../../providers/simple-popup/data";

const LoginInputsWrapper = styled.div`
  text-align: center;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }

  input {
    width: 100%;
    font-size: 15px;
  }
`;

const LoginText = styled.div`
  font-weight: 500;
  letter-spacing: 0.02em;
`;

interface IProps {}

export const LoginPage = ({}: IProps): React.ReactElement<IProps> => {
  const logged = useLoggedIn();
  const dispatch = useDispatch();
  const { createSimplePopup } = useSimplePopups();

  const [errors, setErrors] = useState<TReduxError[]>([]);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  if (logged) {
    return <Navigate to="/" />;
  }

  const onLoginClick = async () => {
    if (!name) {
      setErrors([
        ...errors,
        {
          key: "empty_username",
          type: ErrorType.SERVER_ERROR,
        },
      ]);
      return;
    }

    if (!password) {
      setErrors([
        ...errors,
        {
          key: "empty_password",
          type: ErrorType.SERVER_ERROR,
        },
      ]);
      return;
    }

    const data = new FormData();

    data.append("name", name);
    data.append("password", password);

    try {
      const { payload, type } = await LOGIN_USER(data);

      dispatch({
        payload,
        type,
      });
    } catch (error: any) {
      createSimplePopup("Pokus o přihlášení nebyl úspěšný.", SimplePopupType.Error);
      setErrors([...errors, error]);
    }
  };

  const getError = (key: string) => {
    return errors.find(
      (e) => e && e.key === key && e.type === ErrorType.SERVER_ERROR
    );
  };

  return (
    <FlexCenterAll
      style={{
        height: "100vh",
      }}
    >
      <InputsWrapper
        style={{
          padding: 40,
          width: 400,
        }}
      >
        <LoginInputsWrapper>
          <LoginText>Přihlásit se</LoginText>
          <div>
            <Input
              error={
                (!name && getError("empty_username")) ||
                getError("invalid_login")
              }
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Přihlašovací jméno"
              type="text"
            />
          </div>
          <div>
            <Input
              error={
                (!password && getError("empty_password")) ||
                getError("invalid_login")
              }
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Heslo"
              type="password"
              onKeyDown={(e) => (e.key === "Enter" ? onLoginClick() : null)}
              warnAboutCapslock
            />
          </div>
          <FlexCenterAll>
            <Button
              onClick={onLoginClick}
              style={{
                ...AddButtonStyles,
              }}
            >
              Přihlásit se
            </Button>
          </FlexCenterAll>
        </LoginInputsWrapper>
      </InputsWrapper>
    </FlexCenterAll>
  );
};

export default LoginPage;
