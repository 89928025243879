import { TGetError } from "../../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../../types/error";
import { IEditorLanguage } from "./language-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.LANGUAGE);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TLanguageEditorErrorsObject = {
  locale?: TReduxError;
  locale_already_exists?: TReduxError;
  iso_key?: TReduxError;
  title?: TReduxError;
  country_name?: TReduxError;
  country_id?: TReduxError;
  currency_empty?: TReduxError;
};

type TLanguageEditorErrors = {
  errors: TLanguageEditorErrorsObject;
  isValid: boolean;
};

export const getLanguageEditorErrors = (
  getError: TGetError,
  language: IEditorLanguage
): TLanguageEditorErrors => {
  const locale = getErrorOfKey("locale", getError, language.locale === "");

  const locale_already_exists_params = getError(
    "locale_already_exists",
    ErrorType.LANGUAGE,
    {
      value: language.locale,
    }
  )?.params;

  const locale_already_exists = getErrorOfKey(
    "locale_already_exists",
    getError,
    !!(
      locale_already_exists_params &&
      locale_already_exists_params.value === language.locale
    )
  );

  const iso_key = getErrorOfKey("iso_key", getError, language.iso_key === "");
  const title = getErrorOfKey("title", getError, language.title === "");
  const country_name = getErrorOfKey(
    "country_name",
    getError,
    language.country_name === ""
  );
  const country_id = getErrorOfKey(
    "country_id",
    getError,
    language.country_id === ""
  );


  const currency_empty = getErrorOfKey(
    "currency_empty",
    getError,
    !language.currency
  );

  return {
    errors: {
      locale,
      locale_already_exists,
      iso_key,
      title,
      country_id,
      country_name,
      currency_empty,
    },
    isValid:
      !locale &&
      !locale_already_exists &&
      !iso_key &&
      !title &&
      !country_id &&
      !country_name &&
      !currency_empty,
  };
};
