import React, { useState } from "react";
import Input from "../../ui/input";
import ViewWrapper from "../view-wrapper";
import LanguagesActionButtons from "./languages-action-buttons";
import LanguagesList from "./list/languages-list";

interface IProps {}

export const Languages = ({}: IProps): React.ReactElement<IProps> => {
  const [search, setSearch] = useState("");

  return (
    <ViewWrapper>
      <LanguagesActionButtons />

      <Input
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vyhledat eshop"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <LanguagesList query={search} />
    </ViewWrapper>
  );
};

export default Languages;
