import React from "react";
import { useNavigateCatch } from "../../common/navigate";
import Button from "../../ui/button";

interface IProps {
  path?: string;
}

export const BackButton = ({ path }: IProps): React.ReactElement<IProps> => {
  const navigate = useNavigateCatch();

  return (
    <Button
      style={{
        marginRight: 8,
        fontSize: 15,
      }}
      onClick={() => {
        if (path) {
          navigate(path);
          return;
        }

        navigate(-1);
      }}
    >
      Zpět
    </Button>
  );
};

export default BackButton;
