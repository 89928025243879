import React, { useEffect, useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import styled from "styled-components";
import { useAppConfig } from "../../../common/redux-hooks";
import { useColors, useTheme } from "../../../providers/theme/theme-provider";
import { IThemeColors } from "../../../providers/theme/themes";
import { IPopulatedCategoryId } from "../../../types/category";
import IconButton from "../../../ui/icon-button";
import { useSelector } from "./../../../redux/store";
import { IReduxState } from "./../../../redux/types";
import { FlexCenterAlign, NoResultsFound } from "./../../../ui/common";
import Category from "./category";

const getColorByLevel = (level: number, colors: IThemeColors): string => {
  let levelColors = [
    colors.MAIN_400,
    colors.MAIN_350,
    colors.MAIN_300,
    colors.MAIN_250,
  ];

  return levelColors[level % levelColors.length];
};

interface IStyledCategoryWrapper {
  shown: boolean;
  level: number;
  isMainCategory?: boolean;
}

const StyledCategoryWrapper = styled.div<IStyledCategoryWrapper>`
  margin-left: ${({ level }) => level * 2}px;
  margin-bottom: ${({ isMainCategory }) => (isMainCategory ? 10 : 6)}px;
  border-radius: 4px;
  margin: ${({ level }) => (level === 0 ? 0 : 6)}px;
  margin-bottom: ${({ level }) => (level === 0 ? 6 : 0)}px;
  display: ${({ shown }) => (shown ? "block" : "none")};
  background-color: ${({ level, theme }) =>
    getColorByLevel(level, theme.colors)};

  & > div:first-child {
    border-radius: 4px;
    padding: 4px;
    padding-left: 8px;

    &:hover {
      background-color: ${({ level, theme }) =>
        getColorByLevel(level + 1, theme.colors)};
    }
  }
`;

export const getFontSizeByLevel = (level: number | undefined): number => {
  switch (level) {
    case 0:
      return 16;
    case 1:
      return 15;
    case 2:
      return 14;
    case 3:
      return 13;
    default:
      return 12;
  }
};

interface IGroupedCategory {
  category: IPopulatedCategoryId;
  subcategories: IGroupedCategory[];
  level: number;
}

const getGroupedCategories = (
  storeId: string,
  categories: IPopulatedCategoryId[],
  level: number,
  passThrough?: boolean
): IGroupedCategory[] => {
  const groupedCategories: IGroupedCategory[] = [];
  level++;

  categories.forEach((c) => {
    if ((c.isMainCategory || passThrough) && c.stores.includes(storeId)) {
      groupedCategories.push({
        category: c,
        subcategories:
          c.subcategories.length > 0
            ? getGroupedCategories(storeId, c.subcategories, level, true)
            : [],
        level,
      });
    }
  });

  return groupedCategories;
};

const isGroupedCategoryShownByQuery = (
  category: IGroupedCategory,
  query: string,
  locale: string
): boolean => {
  if (query === "") return true;

  if (
    category.category.name[locale].toLowerCase().includes(query.toLowerCase())
  )
    return true;

  if (category.subcategories.length > 0) {
    return category.subcategories
      .map((g) => isGroupedCategoryShownByQuery(g, query, locale))
      .some((v) => v);
  }

  return false;
};

const getMapped = (
  groupedCategories: IGroupedCategory[],
  query: string
): React.ReactNode => {
  return groupedCategories.map((groupedCategory, i) => (
    <MappedCategory query={query} category={groupedCategory} key={i} />
  ));
};

interface IMappedCategoryProps {
  category: IGroupedCategory;
  query: string;
}

const MappedCategory = ({ category, query }: IMappedCategoryProps) => {
  const { language } = useAppConfig();

  const [showChildren, setShowChildren] = useState(false);
  const shown = isGroupedCategoryShownByQuery(category, query, language.locale);

  useEffect(() => {
    if (query !== "") {
      setShowChildren(shown);
    } else {
      setShowChildren(false);
    }
  }, [shown, query]);

  const colors = useColors();

  return (
    <StyledCategoryWrapper
      shown={shown}
      isMainCategory={category.category.isMainCategory}
      level={category.level}
    >
      <FlexCenterAlign
        style={{
          cursor: category.subcategories.length > 0 ? "pointer" : "default",
        }}
        onClick={() => setShowChildren((s) => !s)}
      >
        {category.subcategories.length > 0 ? (
          <IconButton
            style={{
              backgroundColor: "unset",
              padding: 4,
            }}
          >
            {showChildren ? (
              <BiMinus
                style={{
                  color: colors.OPPOSITE_MAIN_400,
                }}
                size={15}
              />
            ) : (
              <BiPlus
                style={{
                  color: colors.OPPOSITE_MAIN_400,
                }}
                size={15}
              />
            )}
          </IconButton>
        ) : null}
        <div>
          <Category
            category={category.category}
            level={category.level}
            parents={
              "parents" in category.category ? category.category.parents : []
            }
          />
        </div>
      </FlexCenterAlign>

      <div
        style={{
          display:
            showChildren && category.subcategories.length > 0
              ? "block"
              : "none",
          paddingBottom: 4,
        }}
      >
        {category.subcategories.length > 0
          ? getMapped(category.subcategories, query)
          : null}
      </div>
    </StyledCategoryWrapper>
  );
};

interface IProps {
  showSubcategories: boolean;
  query: string;
}

export const CategoriesList = ({
  query,
  showSubcategories,
}: IProps): React.ReactElement<IProps> => {
  const { populatedCategories } = useSelector(
    (state: IReduxState) => state.categories
  );
  const { store } = useAppConfig();
  const groupedCategories = getGroupedCategories(
    store._id,
    populatedCategories.filter((c) => c.stores.includes(store._id)),
    -1
  );

  const colors = useColors();
  const theme = useTheme();

  if (populatedCategories.length === 0) {
    return (
      <div
        style={{
          marginTop: 40,
          fontSize: 22,
          color: colors.OPPOSITE_MAIN_300,
        }}
      >
        <NoResultsFound text="Nebyly nalezeny žádné kategorie" />
      </div>
    );
  }

  if (showSubcategories) {
    return (
      <div
        style={{
          marginBottom: 40,
        }}
      >
        {getMapped(groupedCategories, query)}
      </div>
    );
  }

  return (
    <div
      style={{
        marginBottom: 40,
      }}
    >
      {populatedCategories.map((c, i) => (
        <div
          style={{
            backgroundColor: i % 2 === 0 ? colors.MAIN_400 : colors.MAIN_350,
            borderRadius: theme.borderRadius,
            marginBottom: 6,
            padding: "3px 6px",
          }}
        >
          <Category key={i} category={c} level={0} parents={[]} />
        </div>
      ))}
    </div>
  );
};

export default CategoriesList;
