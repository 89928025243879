import React from "react";
import { Provider as Redux } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import Model from "./model/index";
import { CombinedProviders } from "./providers";
import { store } from "./redux/store";
import Router from "./router/app-router";
import LoadingWrapper from "./view/components/loading-wrapper";
import ServerStatusWrapper from "./server-status-wrapper";
import GlobalStyle from "./global-style";

interface IProps {}

export const App = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <Redux store={store}>
      {/* <PersistGate persistor={persistor} loading={null}> */}
      <CombinedProviders>
        <LoadingWrapper>
          <GlobalStyle />
          <Router />
        </LoadingWrapper>
      </CombinedProviders>
      {/* </PersistGate> */}
    </Redux>
  );
};

export default App;
