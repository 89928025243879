import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled, { css } from "styled-components";
import Button from "../../ui/button";
import colors from "../../ui/colors";
import { FlexCenterAll, InputsWrapper } from "../../ui/common";
import { useColors } from "../theme/theme-provider";

interface INotificationWrapper {
  active: boolean;
}

const NotificationWrapper = styled.div<INotificationWrapper>`
  position: fixed;
  right: 80px;
  bottom: 80px;
  visibility: hidden;
  opacity: 0;
  z-index: 998;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export type TNotificationButtonLabels = {
  ok: string;
  cancel: string;
};

export interface INotificationWindowRef {
  close(): void;
  show(
    title: React.ReactNode,
    onSubmit: () => void,
    onCancel?: () => void,
    buttonLabels?: TNotificationButtonLabels
  ): void;
}

interface IConfirmationHandlers {
  title: React.ReactNode;
  onSubmit: () => void;
  onCancel?: () => void;
  buttonLabels?: TNotificationButtonLabels;
}

const defaultHandlers: IConfirmationHandlers = {
  title: "",
  onSubmit() {},
};

interface IProps {}

export const NotificationWindow = forwardRef(
  (
    {}: IProps,
    ref: React.ForwardedRef<INotificationWindowRef>
  ): React.ReactElement<IProps> => {
    const [active, setActive] = useState(false);
    const [handlers, setHandlers] =
      useState<IConfirmationHandlers>(defaultHandlers);

    useImperativeHandle(
      ref,
      () => ({
        close() {
          setHandlers(defaultHandlers);
          setActive(false);
        },
        show(title, onSubmit, onCancel, buttonLabels) {
          setActive((a) => !a);
          setHandlers({
            title,
            onSubmit,
            onCancel,
            buttonLabels,
        });
        },
      }),
      []
    );

    const colors = useColors()

    return (
      <NotificationWrapper active={active}>
        <InputsWrapper
          style={{
            padding: "20px 30px",
          }}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: 500,
              textAlign: "center",
              marginBottom: 6,
              color: colors.OPPOSITE_MAIN_400,
              paddingTop: 12,
            }}
          >
            {handlers.title}
          </div>
          <FlexCenterAll
            style={{
              marginTop: 20,
            }}
          >
            <div
              style={{
                marginRight: 8,
              }}
            >
              <Button
                style={{
                  backgroundColor: colors.MAIN_300,
                  color: colors.OPPOSITE_MAIN_400,
                  fontSize: 13,
                  fontWeight: 400,
                }}
                hoverBackgroundColor={colors.MAIN_250}
                onClick={() => {
                  handlers.onSubmit();
                  setActive(false);
                }}
              >
                {handlers.buttonLabels?.ok || "OK"}
              </Button>
            </div>
            <div>
              <Button
                style={{
                  backgroundColor: colors.MAIN_400,
                  fontSize: 13,
                  border: "none",
                  fontWeight: 400,
                }}
                hoverBackgroundColor={colors.MAIN_350}
                onClick={() => {
                  handlers.onCancel?.();
                  setActive(false);
                }}
              >
                {handlers.buttonLabels?.cancel || "Storno"}
              </Button>
            </div>
          </FlexCenterAll>
        </InputsWrapper>
      </NotificationWrapper>
    );
  }
);

export default NotificationWindow;
