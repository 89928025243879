import axios from "../axios";
import { IParameterValueID } from "./../../types/parameter";

export interface IParameterValueModel {
  getParameterValues: () => Promise<IParameterValueID[]>;
  getParameterValueById: (id: string) => Promise<IParameterValueID>;
  addParameterValue: (data: FormData) => Promise<IParameterValueID>;
  editParameterValue: (data: FormData) => Promise<IParameterValueID>;
  removeParameterValueById: (id: string) => Promise<boolean>;
}

export const parameterValueModel: IParameterValueModel = {
  getParameterValues() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IParameterValueID[]>(
          `/parameter-values/get-all`
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getParameterValueById(_id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IParameterValueID>(
          `/parameter-values/get-by-id`,
          {
            params: {
              _id,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  addParameterValue(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IParameterValueID>(
          `/parameter-values/add`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editParameterValue(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IParameterValueID>(
          `/parameter-values/edit`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeParameterValueById(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(
          `/parameter-values/remove-by-id`,
          {
            _id,
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
