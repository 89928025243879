export interface ISelectedTimes {
  date: TSelectedDate;
  month: number;
  year: number;
}

export type TDate = {
  month: number;
  year: number;
  date: number;
};

export type TSelectedDate =
  | { date: TDate; type: SelectedDateType.Single }
  | { from: TDate | null; to: TDate | null; type: SelectedDateType.Range }
  | null;

export enum SelectedDateType {
  Single,
  Range,
}

export enum IsSelectedType {
  Full,
  Partly,
  None,
  Today
}

export const Months = [
    "Prosinec",
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
  ],
  Days = ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
  EnglishMonths = [
    "December",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
  ],
  EnglishDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
