import React, { DependencyList, useEffect } from "react";
import { generateSlug } from "../common/slugs";
import { TReduxError } from "../types/error";
import { TSlug } from "../types/slugs";
import Checkbox from "./checkbox";
import { FlexCenterAlign } from "./common";
import { InputTitle, InputWithTitle, InputWithTitleWrapper } from "./input-with-title";
import Input from "./input";

interface IProps {
  value: string;
  error?: TReduxError;
  slug: TSlug;
  setSlug(slug: TSlug): void;
  getAvailableSlug(slug: TSlug): Promise<TSlug>;
  deps?: DependencyList;
}

export const SlugInput = ({
  value,
  error,
  slug,
  setSlug,
  getAvailableSlug,
  deps = [],
}: IProps): React.ReactElement<IProps> => {
  const getNewSlug = async () => {
    const s = await getAvailableSlug(slug);
    setSlug(s);
  };

  useEffect(() => {
    let saveTimer = setTimeout(() => {
      if (!slug.userDefined) {
        getNewSlug();
      }
    }, 200);

    return () => {
      clearTimeout(saveTimer);
    };
  }, [value, ...deps]);

  const onCheckboxSetValue = (state: boolean) => {
    if (state) {
      getNewSlug();
    }

    setSlug({ ...slug, userDefined: !state });
  };

  return (
    <InputWithTitleWrapper>
      <InputTitle>URL slug</InputTitle>
      <Input
        data-title={slug.value}
        disabled={!slug.userDefined}
        error={error}
        style={{
          width: "100%",
        }}
        wrapperStyle={{
          width: "100%",
        }}
        value={slug.value}
        setValue={(value) => setSlug({ ...slug, value })}
      />
      <FlexCenterAlign
        style={{
          marginTop: 8,
          marginLeft: 4,
        }}
      >
        <Checkbox setValue={onCheckboxSetValue} value={!slug.userDefined} />
        <div
          style={{
            marginLeft: 8,
            fontSize: 12,
          }}
        >
          Generovat automaticky
        </div>
      </FlexCenterAlign>
    </InputWithTitleWrapper>
  );
};

export default SlugInput;
