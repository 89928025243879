import _ from "lodash";
import React, { useState } from "react";
import { MdAdminPanelSettings, MdPassword } from "react-icons/md";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useErrors, useUser, useUsers } from "../../../common/redux-hooks";
import { getTitleByRole } from "../../../locales/determineString";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useModal } from "../../../providers/modal/modal-provider";
import { SimplePopupType } from "../../../providers/simple-popup/data";
import { useSimplePopups } from "../../../providers/simple-popup/simple-popup-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADMIN_CHANGE_PASSWORD,
  ADMIN_EDIT_USER_INFO,
  ADMIN_EDIT_USER_PERMISSIONS,
  ADMIN_REMOVE_USER,
} from "../../../redux/actions/usersActions";
import { useDispatch } from "../../../redux/store";
import { IRole } from "../../../types/role";
import { IUserDB } from "../../../types/user";
import Button from "../../../ui/button";
import {
  DeleteButton,
  EditButton,
  FlexCenterAlign,
  FlexCenterAll,
} from "../../../ui/common";
import IconButton from "../../../ui/icon-button";
import Table, { TRow } from "../../../ui/table";
import UserPasswordEditor, {
  IPasswords,
} from "../../profile/components/change-password/user-password-editor";
import { getChangePasswordErrors } from "../../profile/components/change-password/user-password-editor-errors";
import UserInfoEditor, {
  IUserInfo,
} from "../../profile/components/change-user-info/user-info-editor";
import { getEditUserInfoErrors } from "../../profile/components/change-user-info/user-info-editor-errors";
import AdminAddUserModal from "./admin-add-user/admin-add-user-modal";
import UserPermissionsEditor from "./user-permissions-editor";

interface IEditUserInfoModalProps {
  user: IUserDB;
}

const EditUserInfoModal = ({ user }: IEditUserInfoModalProps) => {
  const me = useUser();
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName,
  });
  const { closeModal } = useModal();
  const colors = useColors();
  const { getError } = useErrors();
  const { isValid } = getEditUserInfoErrors(getError, userInfo);
  const { call } = useQueryWithPopupErrorHandling();
  const dispatch = useDispatch();

  const onEditUserInfoClick = async () => {
    if (
      userInfo.userName === user.userName &&
      userInfo.firstName === user.firstName &&
      userInfo.lastName === user.lastName
    ) {
      closeModal();
      return;
    }

    const data = new FormData();

    data.append("id", user._id);
    data.append("firstName", userInfo.firstName);
    data.append("lastName", userInfo.lastName);
    data.append("userName", userInfo.userName);

    call(
      () => ADMIN_EDIT_USER_INFO(data),
      "Změna osobních údajů nebyla úspěšná",
      "Změna osobních údajů byla úspěšná",
      (action) => {
        closeModal();
        dispatch(action);
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  return (
    <div>
      <UserInfoEditor setUserInfo={setUserInfo} userInfo={userInfo} />
      <FlexCenterAll
        style={{
          marginTop: 20,
        }}
      >
        <Button
          disabled={!isValid}
          onClick={onEditUserInfoClick}
          style={{
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
        >
          Změnit osobní údaje
        </Button>
      </FlexCenterAll>
    </div>
  );
};

interface IEditUserInfoModalProps {
  user: IUserDB;
}

const ChangePasswordModal = ({ user }: IEditUserInfoModalProps) => {
  const [passwords, setPasswords] = useState<IPasswords>({
    newPassword: "",
    newPasswordConfirm: "",
    oldPassword: "",
  });
  const { getError } = useErrors();
  const { isValid } = getChangePasswordErrors(getError, passwords);

  const { call } = useQueryWithPopupErrorHandling();
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const colors = useColors();

  const onChangePasswordClick = async () => {
    const data = new FormData();

    data.append("id", user._id);
    data.append("new_password", passwords.newPassword);

    call(
      () => ADMIN_CHANGE_PASSWORD(data),
      "Změna hesla nebyla úspěšná",
      "Změna hesla byla úspěšná",
      () => {
        closeModal();
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  return (
    <div>
      <UserPasswordEditor passwords={passwords} setPasswords={setPasswords} />
      <FlexCenterAll
        style={{
          marginTop: 20,
        }}
      >
        <Button
          disabled={!isValid || passwords.newPassword === ""}
          onClick={onChangePasswordClick}
          style={{
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
        >
          Změnit heslo
        </Button>
      </FlexCenterAll>
    </div>
  );
};

const EditPermissionsModal = ({ user }: IEditUserInfoModalProps) => {
  const [role, setRole] = useState<IRole>(user.role);

  const me = useUser();
  const { closeModal } = useModal();
  const { call } = useQueryWithPopupErrorHandling();
  const dispatch = useDispatch();
  const { createSimplePopup } = useSimplePopups();

  const onChangePermissionsClick = () => {
    if (_.isEqual(user.role, role)) {
      return closeModal();
    }

    const data = new FormData();

    data.append("id", user._id);
    data.append("role", JSON.stringify(role));

    call(
      () => ADMIN_EDIT_USER_PERMISSIONS(data),
      "Změna práv uživatele nebyla úspěšná",
      "Změna práv uživatele byla úspěšná",
      (action) => {
        dispatch(action);
        createSimplePopup(
          "Změna práv uživatele byla úspěšná",
          SimplePopupType.Success
        );
        closeModal();
      }
    );
  };

  return (
    <div>
      <UserPermissionsEditor role={role} setRole={setRole} />
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
          marginBottom: 4,
        }}
      >
        <Button onClick={onChangePermissionsClick}>
          Změnit práva uživatele
        </Button>
      </div>
    </div>
  );
};

interface IProps {}

export const UserList = ({}: IProps): React.ReactElement<IProps> => {
  const me = useUser();
  const users = useUsers();
  const { createModal } = useModal();
  const colors = useColors();
  const { call } = useQueryWithPopupErrorHandling();
  const dispatch = useDispatch();
  const { createConfirmation } = useConfirmation();
  const { createSimplePopup } = useSimplePopups();

  const onEditUserClick = (user: IUserDB) => {
    createModal(<EditUserInfoModal user={user} />);
  };

  const onRemoveUserClick = (user: IUserDB) => {
    createConfirmation("Opravdu chcete uživatele odstranit?", () =>
      call(
        () => ADMIN_REMOVE_USER(user._id),
        "Vymazání uživatele nebylo úspěšné",
        "Uživatel byl úspěšné odstraněn",
        (action) => {
          dispatch(action);
        }
      )
    );
  };

  const onChangePermissionsClick = (user: IUserDB) => {
    createModal(<EditPermissionsModal user={user} />);
  };

  const onChangePasswordClick = (user: IUserDB) => {
    createModal(<ChangePasswordModal user={user} />);
  };

  const onAddUserClick = () => {
    createModal(<AdminAddUserModal />);
  };

  const items: TRow[] = users.map((user) => ({
    cells: [
      {
        key: "actions",
        value: "",
        content: (
          <FlexCenterAlign>
            <div
              style={{
                marginRight: 6,
              }}
            >
              <EditButton
                onClick={() => onEditUserClick(user)}
                data-title="Upravit uživatele"
              />
            </div>
            <div
              style={{
                marginRight: 6,
              }}
            >
              <IconButton
                onClick={() => onChangePasswordClick(user)}
                data-title="Změnit heslo"
                style={{
                  padding: 4,
                  backgroundColor: colors.MAIN_100,
                }}
                hoverBackgroundColor={colors.MAIN_50}
              >
                <MdPassword size={17} color={colors.MAIN_500} />
              </IconButton>
            </div>
            <div
              style={{
                marginRight: 6,
              }}
            >
              <IconButton
                disabled={user.isDefaultAdmin}
                onClick={() => onChangePermissionsClick(user)}
                data-title="Upravit práva uživatele"
                style={{
                  padding: 4,
                  backgroundColor: colors.MAIN_200,
                }}
                hoverBackgroundColor={colors.MAIN_150}
              >
                <MdAdminPanelSettings
                  size={17}
                  color={colors.OPPOSITE_MAIN_400}
                />
              </IconButton>
            </div>
            <div>
              <DeleteButton
                disabled={user._id === me._id || user.isDefaultAdmin}
                onClick={() => onRemoveUserClick(user)}
                data-title="Vymazat uživatele"
              />
            </div>
          </FlexCenterAlign>
        ),
      },
      {
        content: user.firstName,
        key: "firstName",
        value: user.firstName,
      },
      {
        content: user.lastName,
        key: "lastName",
        value: user.lastName,
      },
      {
        content: user.userName,
        key: "userName",
        value: user.userName,
      },
      {
        content: getTitleByRole(user.role.role),
        key: "role",
        value: user.role.role,
      },
    ],
  }));

  return (
    <div style={{
      maxWidth: "80vw"
    }}>
      <Table
        headerKeys={[
          { content: "" },
          { content: "Jméno" },
          { content: "Příjmení" },
          { content: "Uživatelské jméno" },
          { content: "Role" },
        ]}
        rows={items}
      />
      <div
        style={{
          marginTop: 12,
        }}
      >
        <Button onClick={onAddUserClick}>Přidat nového uživatele</Button>
      </div>
    </div>
  );
};

export default UserList;
