import React from "react";
import { determineUnitType, TUnit } from "../../types/units";
import UnitDropdown from "./unit-dropdown";

interface IProps<T = TUnit> {
  unit: T;
  setUnit(unit: T): void;
}

export const UnitSelector = <T extends TUnit>({
  setUnit,
  unit,
}: IProps<T>): React.ReactElement<IProps<T>> => {
  return (
    <div>
      <UnitDropdown
        onSelectUnit={setUnit}
        unit={unit}
        unitType={determineUnitType(unit)}
      />
    </div>
  );
};

export default UnitSelector;
