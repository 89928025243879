import React, { useRef } from "react";
import { BsExclamation, BsQuestion } from "react-icons/bs";
import styled, { css } from "styled-components";
import { useColors } from "../../providers/theme/theme-provider";

const StyledInfoTooltip = styled.div`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_300};
    color: ${props.theme.colors.OPPOSITE_MAIN_400};
    border-radius: ${props.theme.borderRadius / 2}px;
  `}
  
  width: 140px;
  position: absolute;
  text-align: center;
  padding: 6px 8px;
  top: 24px;
  font-size: 11px;
  z-index: 44;

  opacity: 0;
  visibility: hidden;
  transition: visibility 150ms ease, opacity 150ms ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

interface IProps {
  info?: React.ReactNode;
  width?: number;
}

export const InfoTooltip = ({
  info,
  width,
}: IProps): React.ReactElement<IProps> => {
  const infoTooltipRef = useRef<HTMLDivElement>(null);
  const colors = useColors();

  const onMouseEnterInfo = () => {
    infoTooltipRef.current?.classList.add("active");
  };

  const onMouseLeaveInfo = () => {
    infoTooltipRef.current?.classList.remove("active");
  };

  if (!info) return <></>;

  return (
    <div
      style={{
        cursor: "default",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <BsQuestion
        onMouseEnter={onMouseEnterInfo}
        onMouseLeave={onMouseLeaveInfo}
        size={20}
        color={colors.OPPOSITE_MAIN_400}
      />
      <StyledInfoTooltip
        style={{
          width: width || 140,
        }}
        ref={infoTooltipRef}
      >
        {info}
      </StyledInfoTooltip>
    </div>
  );
};

export default InfoTooltip;
