import React from "react";
import ViewWrapper from "../view-wrapper";
import OrdersList from "./orders-list";

interface IProps {}

export const Orders = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <ViewWrapper>
      <OrdersList />
    </ViewWrapper>
  );
};

export default Orders;
