import _ from "lodash";
import { TGetError } from "../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../types/error";
import { IEditorCoupon } from "./coupon-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.COUPON);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TCouponEditorErrorsObject = {
  key_empty?: TReduxError;
  key_exists?: TReduxError;
  value_empty?: TReduxError;
  value_invalid?: TReduxError;
  type_empty?: TReduxError;
};

type TCouponEditorErrors = {
  errors: TCouponEditorErrorsObject;
  isValid: boolean;
};

export const getCouponEditorErrors = (
  getError: TGetError,
  coupon: IEditorCoupon,
  locale: string
): TCouponEditorErrors => {
  const key_empty = getErrorOfKey("key_empty", getError, coupon.key === "");
  const type_empty = getErrorOfKey("type_empty", getError, !coupon.type);

  const key_exists_params = getError("key_exists", ErrorType.COUPON, {
    value: coupon.key,
  })?.params!;
  const key_exists = getErrorOfKey(
    "key_exists",
    getError,
    key_exists_params && key_exists_params.value === coupon.key
  );

  const value_empty = getErrorOfKey(
    "value_empty",
    getError,
    !coupon.value[locale]
  );

  const value_invalid_params = getError("value_invalid", ErrorType.COUPON, {
    value: coupon.key,
  })?.params!;
  const value_invalid = getErrorOfKey(
    "value_invalid",
    getError,
    value_invalid_params && _.isEqual(value_invalid_params.value, coupon.value)
  );

  return {
    errors: {
      key_empty,
      key_exists,
      value_invalid,
      value_empty,
      type_empty,
    },
    isValid: !key_empty && !key_exists,
  };
};
