import { IParameterGroupID } from "../../types/parameter";
import { parameterGroupModel } from "../../model/query/parameterGroupModel";
import { ErrorsActions } from "./errorsActions";

export enum ParameterGroupActions {
  SET_PARAMETER_GROUPS = "SET_PARAMETER_GROUPS",
  ADD_PARAMETER_GROUP = "ADD_PARAMETER_GROUP",
  EDIT_PARAMETER_GROUP = "EDIT_PARAMETER_GROUP",
  REMOVE_PARAMETER_GROUP_BY_ID = "REMOVE_PARAMETER_GROUP_BY_ID",
}

type TSetParameterGroupsAction = {
  type: ParameterGroupActions.SET_PARAMETER_GROUPS;
  payload: IParameterGroupID[];
};

type TAddParameterGroupAction = {
  type: ParameterGroupActions.ADD_PARAMETER_GROUP;
  payload: IParameterGroupID;
};

type TEditParameterGroupAction = {
  type: ParameterGroupActions.EDIT_PARAMETER_GROUP;
  payload: IParameterGroupID;
};

type TRemoveParameterGroupByIdAction = {
  type: ParameterGroupActions.REMOVE_PARAMETER_GROUP_BY_ID;
  payload: string;
};

export type TParameterGroupAction =
  | TSetParameterGroupsAction
  | TAddParameterGroupAction
  | TEditParameterGroupAction
  | TRemoveParameterGroupByIdAction;

export const FETCH_AND_SET_PARAMETER_GROUPS = async () => {
  try {
    const parameterGroups = await parameterGroupModel.getParameterGroups();

    return {
      type: ParameterGroupActions.SET_PARAMETER_GROUPS,
      payload: parameterGroups,
    };
  } catch (err) {
    return false;
  }
};

export const ADD_PARAMETER_GROUP = async (data: FormData) => {
  try {
    const parameterGroup = await parameterGroupModel.addParameterGroup(data);

    return {
      type: ParameterGroupActions.ADD_PARAMETER_GROUP,
      payload: parameterGroup,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_PARAMETER_GROUP = async (data: FormData) => {
  try {
    const parameterGroup = await parameterGroupModel.editParameterGroup(data);

    return {
      type: ParameterGroupActions.EDIT_PARAMETER_GROUP,
      payload: parameterGroup,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_PARAMETER_GROUP_BY_ID = async (id: string) => {
  try {
    await parameterGroupModel.removeParameterGroupById(id);

    return {
      type: ParameterGroupActions.REMOVE_PARAMETER_GROUP_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw err;
  }
};
