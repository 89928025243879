import { ErrorType, TReduxError } from "../types/error";

export const getErrorTranslation = (error: TReduxError): string => {
  switch (error.type) {
    case ErrorType.CATEGORY:
      switch (error.key) {
        case "name":
          return "Název kategorie je prázdný.";
        default:
          return "";
      }
    case ErrorType.PRODUCT:
      switch (error.key) {
        case "name":
          return "Název produktu je prázdný.";
        case "ean":
          return "EAN je prázdný.";
        case "buy_price":
          return "Nákupní cena nesmí být 0 Kč.";
        case "price_vat":
          return "Prodávací cena nesmí být 0 Kč.";
        case "image":
          return "Hlavní fotografie produktu musí být definovaná.";
        case "description":
          return "Popis produktu je prádný.";
        default:
          return "";
      }
    case ErrorType.PARAMETER:
      switch (error.key) {
        case "canHaveAnyValue":
          return "Alespoň jedna hodnota musí být vyplněná, pokud parametr nemůže mít jakoukoliv hodnotu.";
        default:
          return "";
      }
    case ErrorType.SERVER_ERROR:
      switch (error.key) {
        case "invalid_login":
          return "Přihlašovací údaje nejsou správné.";
        case "empty_username":
          return "Přihlašovací jméno musí být vyplněné.";
        case "empty_password":
          return "Heslo musí být vyplněné.";
        default:
          return "";
      }
    default:
      return "";
  }
};
