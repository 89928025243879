import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useAppConfig, useErrors } from "../../../common/redux-hooks";
import { getShippingTypeTitle } from "../../../locales/determineString";
import { shippingModel } from "../../../model/query/shippingModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_SHIPPING,
  EDIT_SHIPPING,
  REMOVE_SHIPPING_BY_ID,
} from "../../../redux/actions/shippingActions";
import { useDispatch } from "../../../redux/store";
import {
  EditorType,
  TLanguageBoolean,
  TLanguageNumber,
} from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { IShippingID, ShippingType } from "../../../types/shipping";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
} from "../../../ui/common";
import Dropdown from "../../../ui/dropdown";
import { InputWithTitle } from "../../../ui/input-with-title";
import LanguageSelector from "../../../ui/language-selector";
import BackButton from "../../components/back-button";
import { getShippingEditorErrors } from "./editor-errors";
import { IStoreID } from "../../../types/store";

const mapShippingToEditorShipping = (
  shipping: IShippingID
): IEditorShipping => {
  const { type, stores, price, free_from, max_value } = shipping;

  return {
    type,
    price: price || {},
    stores: stores || [],
    free_from: free_from || {},
    max_value: max_value || {},
  };
};

const initialEditorShipping: IEditorShipping = {
  type: ShippingType.Zasilkovna,
  price: {},
  stores: [],
  free_from: {},
  max_value: {},
};

export interface IEditorShipping {
  type: ShippingType;
  price: TLanguageNumber;
  free_from: TLanguageNumber;
  max_value: TLanguageNumber;
  stores: IStoreID[];
}

interface IProps {
  type: EditorType;
}

export const ShippingEditor = ({
  type,
}: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const [shipping, setShipping] = useState<IEditorShipping>(
    initialEditorShipping
  );

  const { closeNotification } = useNotifications();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getShippingEditorErrors(getError, shipping);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useQueryWithPopupErrorHandling();
  const { createConfirmation } = useConfirmation();
  const colors = useColors();
  const appConfig = useAppConfig();
  const [language, setLanguage] = useState(appConfig.language);

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.SHIPPING);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getShipping = async (id: string) => {
      call(
        () => shippingModel.getShippingById(id),
        "Zobrazení dopravy nebylo úspěšné",
        null,
        (Shipping) => {
          const c = mapShippingToEditorShipping(Shipping);

          setShipping(c);
        },
        () => {
          navigate("/shippings");
        }
      );
    };

    if (_id && type === EditorType.Edit) {
      getShipping(_id);
    }
  }, [_id, type]);

  const appendShippingToForm = useCallback(
    (form: FormData) => {
      form.append("type", shipping.type);
      form.append("price", JSON.stringify(shipping.price));
      form.append(
        "stores",
        JSON.stringify(shipping.stores.map((store) => store._id))
      );
      form.append("free_from", JSON.stringify(shipping.free_from));
      form.append("max_value", JSON.stringify(shipping.max_value));
    },
    [shipping]
  );

  const addShipping = () => {
    const data = new FormData();

    appendShippingToForm(data);

    call(
      () => ADD_SHIPPING(data),
      "Přidání dopravy nebylo úspěšné",
      "Přidání dopravy bylo úspěšné",
      (newParameter) => {
        dispatch(newParameter);
        navigate("/shippings");
      },
      (err) => {
        if (err?.payload) {
          dispatch(err);
        }
      }
    );
  };

  const editShipping = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit dopravu", async () => {
      if (!_id) {
        return;
      }

      const data = new FormData();

      data.append("_id", _id);
      appendShippingToForm(data);

      call(
        () => EDIT_SHIPPING(data),
        "Úprava dopravy nebyla úspěšná",
        "Úprava dopravy byla úspěšná",
        (action) => {
          dispatch(action);

          if (exit) {
            navigate("/shippings");
          } else {
            setShipping(mapShippingToEditorShipping(action.payload));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeShipping = () => {
    createConfirmation("Potvrzení: Odstranit dopravu", async () => {
      if (!_id) {
        return;
      }

      call(
        () => REMOVE_SHIPPING_BY_ID(_id),
        "Odstranění dopravy nebylo úspěšné",
        "Odstranění dopravy bylo úspěšné",
        (remove) => {
          dispatch(remove);
          navigate("/shippings");
        }
      );
    });
  };

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!isValid}
          onClick={addShipping}
        >
          Přidat dopravu
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editShipping()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editShipping(true)}>
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removeShipping}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper
      node={node}
      deps={[isValid, appendShippingToForm]}
    >
      <div>
        <BackButton path="/shippings" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          {type === EditorType.Add ? "Přidání dopravy" : "Úprava dopravy"}
        </div>

        <FlexCenterAlign
          style={{
            margin: "20px 8px",
            marginBottom: 16,
          }}
        >
          <LanguageSelector
            onSelectLanguage={(language) => {
              setLanguage(language);
            }}
          />
        </FlexCenterAlign>

        <Flex>
          <div>
            <Flex>
              <InputsWrapper>
                <div
                  style={{
                    marginLeft: 4,
                    fontSize: 13,
                    marginBottom: 4,
                  }}
                >
                  Typ dopravy
                </div>
                <Dropdown
                  list={Object.values(ShippingType).map((type) => ({
                    content: getShippingTypeTitle(type),
                    value: type,
                    query: getShippingTypeTitle(type),
                    unique_id: type,
                  }))}
                  selectedUniqueId={shipping.type}
                  onSelectValue={(type) => setShipping((s) => ({ ...s, type }))}
                  isDeleteButtonDisabled
                />
                <InputWithTitle
                  inputProps={{
                    type: "number",
                    error: errors.symbol_empty,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={shipping.price[language.locale] || ""}
                  setValue={(price) => {
                    {
                      setShipping((c) => ({
                        ...c,
                        price: {
                          ...c.price,
                          [language.locale]: price as number,
                        },
                      }));
                    }
                  }}
                  title="Cena dopravy"
                  unit={language.currency.symbol}
                />
                <InputWithTitle
                  inputProps={{
                    type: "number",
                    error: errors.symbol_empty,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={shipping.free_from[language.locale] || ""}
                  setValue={(free_from) => {
                    {
                      setShipping((c) => ({
                        ...c,
                        free_from: {
                          ...c.free_from,
                          [language.locale]: free_from as number,
                        },
                      }));
                    }
                  }}
                  title="Doprava zdarma od"
                  unit={language.currency.symbol}
                />
                <InputWithTitle
                  inputProps={{
                    type: "number",
                    error: errors.symbol_empty,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={shipping.max_value[language.locale] || ""}
                  setValue={(max_value) => {
                    {
                      setShipping((c) => ({
                        ...c,
                        max_value: {
                          ...c.max_value,
                          [language.locale]: max_value as number,
                        },
                      }));
                    }
                  }}
                  title="Maximální cena objednávky"
                  unit={language.currency.symbol}
                />
                <FlexCenterAlign
                  style={{
                    marginTop: 16,
                  }}
                >
                  <Checkbox
                    value={shipping.stores.some(
                      (shippingStore) => shippingStore._id === appConfig.store._id
                    )}
                    setValue={(value) =>
                      setShipping((p) => ({
                        ...p,
                        stores: value ? [...p.stores, appConfig.store] : p.stores.filter((store) => store._id !== appConfig.store._id),
                      }))
                    }
                  />
                  <div
                    style={{
                      marginLeft: 12,
                      fontWeight: 400,
                      fontSize: 13,
                    }}
                  >
                    Dostupné v obchodě{" "}
                    <span
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {appConfig.store.title}
                    </span>
                  </div>
                </FlexCenterAlign>
              </InputsWrapper>
            </Flex>
          </div>
        </Flex>
      </div>
    </BottomFixedWrapperWrapper>
  );
};

export default ShippingEditor;
