import { IManufacturerID } from "./../../types/manufacturer";
import axios from "../axios";

export interface IManufacturerModel {
  getManufacturers: () => Promise<IManufacturerID[]>;
  getManufacturerById: (id: string) => Promise<IManufacturerID>;
  addManufacturer: (data: FormData) => Promise<IManufacturerID>;
  editManufacturer: (data: FormData) => Promise<IManufacturerID>;
  removeManufacturerById: (id: string) => Promise<boolean>;
}

export const manufacturerModel: IManufacturerModel = {
  getManufacturers() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IManufacturerID[]>(
          `/manufacturers/get-all`
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getManufacturerById(_id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IManufacturerID>(
          `/manufacturers/get-by-id`,
          {
            params: {
              _id,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  addManufacturer(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IManufacturerID>(
          `/manufacturers/add`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editManufacturer(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IManufacturerID>(
          `/manufacturers/edit`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeManufacturerById(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(
          `/manufacturers/remove-by-id`,
          {
            _id,
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
