import React, { createContext } from "react";

interface IOrdersListContext {
    fetch(): void;
}

export const OrdersListContext = createContext({} as IOrdersListContext)

export const useOrdersList = () => {
    return React.useContext(OrdersListContext)
}