import React from "react";
import ViewWrapper from "../view-wrapper";
import { AdsList } from "./list/ads-list";

interface IProps {}

export const Ads = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <ViewWrapper>
      <AdsList />
    </ViewWrapper>
  );
};

export default Ads;
