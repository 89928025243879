import { TGetError } from "../../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../../types/error";
import { IEditorPayment } from "./payment-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.PAYMENT);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TPaymentEditorErrorsObject = {
  title_empty?: TReduxError;
  symbol_empty?: TReduxError;
  payment_shortcut_empty?: TReduxError;
  payment_shortcut_exists?: TReduxError;
};

type TPaymentEditorErrors = {
  errors: TPaymentEditorErrorsObject;
  isValid: boolean;
};

export const getPaymentEditorErrors = (
  getError: TGetError,
  payment: IEditorPayment
): TPaymentEditorErrors => {
  return {
    errors: {},
    isValid: true,
  };
};
