import React, { useRef, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import colors from "../../ui/colors";

interface ITooltipProps {
  x: number;
  y: number;
  children: React.ReactNode;
}

const TooltipWrapper = styled.div<ITooltipProps>`
  ${(props) => css`
    background-color: ${props.theme.colors.SECONDARY_400};
    border-radius: ${props.theme.borderRadius / 3}px;

    color: #fff;
  `}

  position: absolute;
  z-index: 999999999;
  pointer-events: none;
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  letter-spacing: 0.025em;
  box-shadow: 4px 4px 8px #00000009;

  & > span {
    padding: 4px 12px;
  }

  ${({ x, y }) => css`
    top: ${y < 2 ? 2 : y}px;
    left: ${x < 2 ? 2 : x}px;
  `}
`;

const Tooltip = ({
  x,
  y,
  children,
}: ITooltipProps): React.ReactElement<ITooltipProps> => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [position, setPosition] = useState({
    x,
    y,
  });

  useEffect(() => {
    if (spanRef.current) {
      const bounding = spanRef.current.getBoundingClientRect();

      setPosition({
        x: x - bounding.width / 2,
        y,
      });
    }
  }, [children, x, y]);

  return (
    <TooltipWrapper
      x={position.x}
      y={position.y}
      style={{
        opacity: position.x === x ? 0 : 1,
      }}
    >
      <span ref={spanRef}>{children}</span>
    </TooltipWrapper>
  );
};

interface ITooltipContext {
  clearTooltip(): void;
}

const TooltipContext = React.createContext<ITooltipContext>(
  {} as ITooltipContext
);

export const useTooltipProvider = () => {
  return React.useContext(TooltipContext);
};

interface IProps {
  children: React.ReactNode;
}

const getTitleFromParentOrElement = (
  element: Element
): { element: Element; title: string } | null => {
  const title = element.getAttribute("data-title");

  if (title) {
    return { element, title };
  } else if (element.parentElement) {
    return getTitleFromParentOrElement(element.parentElement);
  }

  return null;
};

interface ITooltipState {
  element: React.ReactNode;
  title: string;
  x: number;
  y: number;
}

export const TooltipProvider = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const [tooltip, setTooltip] = useState<ITooltipState | null>(null);

  const tooltipContext: ITooltipContext = {
    clearTooltip() {
      setTooltip(null);
    },
  };

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (e.target instanceof Element) {
        const resolveTarget = getTitleFromParentOrElement(e.target);

        if (resolveTarget) {
          const { title, element } = resolveTarget,
            bounding = element.getBoundingClientRect(),
            { x, y, height, width } = bounding;

          if (
            tooltip?.title !== title &&
            (tooltip?.x !== x || tooltip?.y !== y)
          ) {
            const normalY = y + height + 8 + window.scrollY
            const isOverflowing = normalY + height + 2 > window.innerHeight + window.scrollY;

            const setY = isOverflowing ? y - height + window.scrollY : normalY

            setTooltip({
              element: (
                <Tooltip x={x + width / 2} y={setY}>
                  {title}
                </Tooltip>
              ),
              title,
              x,
              y,
            });
          }
        } else {
          setTooltip(null);
        }
      }
    };

    window.addEventListener("mouseover", handler);

    return () => {
      window.removeEventListener("mouseover", handler);
    };
  }, [tooltip]);

  return (
    <TooltipContext.Provider value={tooltipContext}>
      {tooltip?.element}
      {children}
    </TooltipContext.Provider>
  );
};

export default TooltipProvider;
