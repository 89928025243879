import React, { useEffect, useState } from "react";
import colors from "./colors";

interface IProps {}

export const CapslockWarning = ({}: IProps): React.ReactElement<IProps> => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      if (e?.getModifierState && e.getModifierState("CapsLock")) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener("keyup", callback);

    return () => {
      window.removeEventListener("keyup", callback);
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        right: 16,
        top: "calc(-1em - 4px)",
        fontSize: 10,
        fontWeight: 500,
        display: visible ? "block" : "none"
      }}
    >
      <span
        style={{
          color: colors.DarkerLightCoral,
        }}
      >
        *{" "}
      </span>
      Caps Lock je zapnutý
    </div>
  );
};

export default CapslockWarning;
