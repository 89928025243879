import React from "react";
import { Navigate } from "react-router-dom";
import { useAdminRegistration } from "../../admin-registration-provider";
import AdminAddUserModal from "../../../admin/users/admin-add-user/admin-add-user-modal";
import { Flex, FlexCenterAll, InputsWrapper } from "../../../../ui/common";

interface IProps {
  reloadStatus(): void;
}

export const DefaultAdminRegistration = ({
  reloadStatus,
}: IProps): React.ReactElement<IProps> => {
  const { adminRegistration } = useAdminRegistration();

  if (!adminRegistration.registrationKey) {
    return <Navigate to="/registration" />;
  }
  const onSuccess = () => {
    reloadStatus();
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 20,
          fontSize: 28,
          marginLeft: 8,
          opacity: 0.444,
        }}
      >
        Přidat výchozí admin účet
      </div>
      <FlexCenterAll>
        <InputsWrapper>
          <AdminAddUserModal
            onSuccess={onSuccess}
            registrationKey={adminRegistration.registrationKey}
            isForDefaultAdmin
          />
        </InputsWrapper>
      </FlexCenterAll>
    </div>
  );
};

export default DefaultAdminRegistration;
