import React, { useRef } from "react";
import NotificationWindow, {
  INotificationWindowRef, TNotificationButtonLabels
} from "./notification-window";

interface INotificationContext {
  closeNotification(): void;
  createNotification(
    title: React.ReactNode,
    onSubmit: () => void,
    onCancel?: () => void,
    buttonLabels?: TNotificationButtonLabels
  ): void;
}

const NotificationContext = React.createContext<INotificationContext>(
  {} as INotificationContext
);

export const useNotifications = () => {
  return React.useContext(NotificationContext);
};

interface IProps {
  children: React.ReactNode;
}

export const NotificationProvider = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const notificationRef = useRef<INotificationWindowRef>(null);

  const notificationContext: INotificationContext = {
    createNotification(title, onSubmit, onCancel, buttonLabels) {
      notificationRef.current?.show(title, onSubmit, onCancel, buttonLabels);
    },
    closeNotification() {
      notificationRef.current?.close();
    },
  };

  return (
    <NotificationContext.Provider value={notificationContext}>
      <NotificationWindow ref={notificationRef} />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
