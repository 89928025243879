import { useLoadingWithOverlay } from "./../providers/loading-with-overlay/loading-with-overlay-provider";
import { SimplePopupType } from "../providers/simple-popup/data";
import { useSimplePopups } from "../providers/simple-popup/simple-popup-provider";

export const useQueryWithPopupErrorHandling = () => {
  const { createSimplePopup } = useSimplePopups();
  const { showLoading, closeLoading } = useLoadingWithOverlay();

  return {
    call: async <T = any>(
      query: () => Promise<T>,
      errorTitle: string | null = null,
      successTitle: string | null = null,
      success: (data: T) => void,
      error?: (err: any) => void
    ) => {
      try {
        showLoading();
        const res = await query();
        closeLoading();

        if (successTitle) {
          createSimplePopup(successTitle, SimplePopupType.Success);
        }
        success?.(res);
        return;
      } catch (err: any) {
        if (errorTitle) {
          createSimplePopup(errorTitle, SimplePopupType.Error);
        }
        closeLoading();
        error?.(err);
      }
    },
  };
};
