import { isLanguageStringEmpty } from "../../../../common/functions";
import { TGetError } from "../../../../common/redux-hooks";
import { TLanguageString } from "../../../../types/common";
import { ErrorType, TReduxError } from "../../../../types/error";
import { IPasswords } from "./user-password-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.PASSWORD_CHANGE);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TChangePasswordErrorsObject = {
  old_password_empty?: TReduxError;
  old_password_wrong?: TReduxError;

  new_password_too_short?: TReduxError;
  new_password_too_easy?: TReduxError;

  password_empty?: TReduxError;
  new_password_confirm_not_same?: TReduxError;
};

type TChangePasswordErrors = {
  errors: TChangePasswordErrorsObject;
  isValid: boolean;
};

export const getChangePasswordErrors = (
  getError: TGetError,
  passwords: IPasswords
): TChangePasswordErrors => {
  const new_password_confirm_not_same: TReduxError | undefined =
    passwords.newPassword !== passwords.newPasswordConfirm
      ? {
          key: "new_password_not_same",
          type: ErrorType.PASSWORD_CHANGE,
        }
      : undefined;

  const old_password_wrong_params = getError(
    "old_password_wrong",
    ErrorType.PASSWORD_CHANGE,
    {
      value: passwords.oldPassword,
    }
  )?.params!;

  const old_password_wrong = getErrorOfKey(
    "old_password_wrong",
    getError,
    old_password_wrong_params &&
      old_password_wrong_params.value === passwords.oldPassword
  );

  const old_password_empty = getErrorOfKey(
    "old_password_empty",
    getError,
    passwords.oldPassword === ""
  );

  const password_empty = getErrorOfKey(
    "password_empty",
    getError,
    passwords.newPassword === ""
  );

  return {
    errors: {
      new_password_confirm_not_same,
      old_password_wrong,
      old_password_empty,
      password_empty,
    },
    isValid:
      !new_password_confirm_not_same &&
      !old_password_wrong &&
      !old_password_empty &&
      !password_empty,
  };
};
