import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BsCheck2, BsCheckLg } from "react-icons/bs";
import styled, { css } from "styled-components";
import Button from "../../ui/button";
import colors from "../../ui/colors";
import {
  DeleteButton,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
} from "../../ui/common";
import { useColors } from "../theme/theme-provider";
import { IThemeColors } from "../theme/themes";
import { SimplePopupType } from "./data";

interface ISimplePopupWrapper {
  active: boolean;
  type: SimplePopupType;
}

const getBackgroundColorByType = (
  type: SimplePopupType,
  colors: IThemeColors
): string => {
  switch (type) {
    case SimplePopupType.Error:
      return colors.ERROR_500;
    case SimplePopupType.Success:
      return colors.MAIN_350;
    case SimplePopupType.Warning:
      return colors.WARNING_400;
  }
};

const getIconByType = (
  type: SimplePopupType,
  colors: IThemeColors
): React.ReactNode => {
  switch (type) {
    case SimplePopupType.Error:
      return <AiOutlineExclamationCircle size={32} />;
    case SimplePopupType.Success:
      return <BsCheck2 size={48} color={colors.SUCCESS_400} />;
    case SimplePopupType.Warning:
      return <AiOutlineExclamationCircle size={32} />;
  }
};

const SimplePopupWrapper = styled.div<ISimplePopupWrapper>`
  position: fixed;
  padding: 18px 24px;
  box-shadow: 4px 4px 12px #00000033;
  max-width: 440px;

  color: ${colors.White};
  top: 60px;
  right: 40px;
  visibility: hidden;
  opacity: 0;
  z-index: 999999;
  transform: translateX(32px);
  transition: visibility 150ms ease, opacity 150ms ease, transform 150ms ease,
    top 100ms ease;

  ${({ type, theme }) => css`
    border-radius: ${theme.borderRadius * 2}px;

    background-color: ${getBackgroundColorByType(type, theme.colors)};
  `}

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateX(0px);

      transition: visibility 150ms ease, opacity 150ms ease,
        transform 150ms ease, top 100ms ease;
    `}
`;

export interface ISimplePopupWindowRef {
  close(): void;
  show(title: string, type: SimplePopupType): void;
}

interface ISimplePopupProps {
  title: string;
  type: SimplePopupType;
}

const defaultSimplePopupProps = {
  type: SimplePopupType.Error,
  title: "",
};

interface IPopupWindowProps {
  type: SimplePopupType;
  title: string;
  removeHandler(): void;
}

interface IPopupWindowRef {
  moveDown(height: number): void;
  getHeight(): number;
}

export const PopupWindow = forwardRef(
  (
    { type, title, removeHandler }: IPopupWindowProps,
    ref: React.ForwardedRef<IPopupWindowRef>
  ): React.ReactElement<IPopupWindowProps> => {
    const colors = useColors();
    const [active, setActive] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [top, setTop] = useState<number>(
      wrapperRef.current?.getBoundingClientRect().top || 60
    );

    const remove = () => {
      wrapperRef.current?.addEventListener("transitionend", removeHandler);
      setActive(false);
    };

    useImperativeHandle(
      ref,
      () => ({
        moveDown(height) {
          setTop((t) => (t += height));
        },
        getHeight() {
          return wrapperRef.current?.getBoundingClientRect().height || 100;
        },
      }),
      []
    );

    useEffect(() => {
      setTimeout(() => {
        setActive(true);
      }, 0);

      setTimeout(() => {
        remove();
      }, 3500);
    }, []);

    return (
      <SimplePopupWrapper
        style={{
          top,
        }}
        ref={wrapperRef}
        type={type}
        active={active}
      >
        <DeleteButton
          style={{
            position: "absolute",
            top: 12,
            right: 12,
            padding: 5,
            backgroundColor: "unset",
          }}
          hoverBackgroundColor={"#ffffff44"}
          onClick={remove}
        />
        <FlexCenterAll>{getIconByType(type, colors)}</FlexCenterAll>

        <FlexCenterAlign>
          <div
            style={{
              fontSize: 13,
              fontWeight: 400,
              color: "#fff",
              textAlign: "center",
              marginBottom: 6,
              paddingTop: 8,
              width: "85%",
              margin: "0 auto",
            }}
          >
            {title}
          </div>
        </FlexCenterAlign>
      </SimplePopupWrapper>
    );
  }
);

interface IProps {}

export const SimplePopupWindow = forwardRef(
  (
    {}: IProps,
    ref: React.ForwardedRef<ISimplePopupWindowRef>
  ): React.ReactElement<IProps> => {
    const [active, setActive] = useState(false);
    const [popupProps, setPopupProps] = useState<ISimplePopupProps>(
      defaultSimplePopupProps
    );
    const [windows, setWindows] = useState<JSX.Element[]>([]);
    const [refs, setRefs] = useState<
      { ref: IPopupWindowRef; element: JSX.Element; height: number }[]
    >([]);
    const id = useRef(0);

    useImperativeHandle(
      ref,
      () => ({
        close() {
          setPopupProps(defaultSimplePopupProps);
          setActive(false);
        },
        show(title, type) {
          const c = (
            <PopupWindow
              key={id.current}
              removeHandler={() => {
                setRefs((w) => w.filter((_w) => _w.element.key !== c.key));
                setWindows((w) => w.filter((_w) => _w.key !== c.key));
              }}
              ref={(r) => {
                if (r) {
                  refs.forEach((_ref) => {
                    _ref.ref.moveDown(r.getHeight() + 20 || 0);
                  });

                  const newRef = { element: c, ref: r, height: r.getHeight() };

                  setRefs((refs) => [...refs, newRef]);
                }
              }}
              title={title}
              type={type}
            />
          );

          id.current++;

          setWindows((w) => {
            return [...w, c];
          });
        },
      }),
      [refs, windows]
    );

    return <>{windows.map((w) => w)}</>;
  }
);

export default SimplePopupWindow;
