import React from "react";
import { useSelector } from "../redux/store";
import Button from "./button";
import { useModal } from "./../providers/modal/modal-provider";
import Gallery, { GalleryListMode } from "../view/gallery";
import { IImageDB, ImageType } from "./../types/gallery";
import { colors } from "./colors";
import { TReduxError } from "../types/error";
import { useColors } from "../providers/theme/theme-provider";

interface IGalleryPickerModalProps {
  onClickImage(image: IImageDB): void;
  type?: ImageType;
  disableSelectType?: boolean;
  mode?: GalleryListMode;
}

export const GalleryPickerModal = ({
  onClickImage,
  type,
  disableSelectType,
  mode,
}: IGalleryPickerModalProps): React.ReactElement<IGalleryPickerModalProps> => {
  const { closeModal } = useModal();

  const onClick = (image: IImageDB) => {
    closeModal();
    onClickImage(image);
  };

  return (
    <div
      style={{
        maxWidth: "70vw",
        maxHeight: "80vh",
        overflow: "auto"
      }}
    >
      <Gallery
        mode={mode ?? GalleryListMode.ChooseAndUpload}
        onClickImage={onClick}
        type={type}
        disableSelectType={disableSelectType}
      />
    </div>
  );
};

interface IProps {
  title: string;
  onChoose(image: IImageDB): void;
  type?: ImageType;
  error?: TReduxError;
  mode?: GalleryListMode;
  disabled?: boolean;
}

export const GalleryPicker = ({
  title,
  onChoose,
  type,
  error,
  mode,
  disabled,
}: IProps): React.ReactElement<IProps> => {
  const { createModal } = useModal();

  const onClickImage = (image: IImageDB) => {
    onChoose(image);
  };

  const onChooseFromGalleryClick = () => {
    createModal(
      <GalleryPickerModal
        onClickImage={onClickImage}
        type={type}
        disableSelectType
        mode={mode}
      />
    );
  };

  const colors = useColors()

  return (
    <div>
      <Button
        disabled={disabled}
        style={{
          backgroundColor: colors.MAIN_350,
        }}
        hoverBackgroundColor={colors.MAIN_300}
        onClick={onChooseFromGalleryClick}
        error={error}
      >
        {title}
      </Button>
    </div>
  );
};

export default GalleryPicker;
