import { useAnimate } from "framer-motion";
import React, { useEffect, useState } from "react";
import { getPrice } from "../../common/price";
import { analyticsModel } from "../../model/query/analyticsModel";
import { useColors } from "../../providers/theme/theme-provider";
import CalendarButton from "../../ui/calendar-button";
import { TOnSelectDate } from "../../ui/calendar/calendar";
import { SelectedDateType } from "../../ui/calendar/types";
import {
  DeleteButton,
  Flex,
  FlexCenterAlign,
  InputsWrapper,
} from "../../ui/common";
import Dropdown from "../../ui/dropdown";
import PositiveNegativeIndicator from "../../ui/positive-negative-indicator";
import { AnalyticsTimeScale } from "./types";
import { getDefaultTimeScaleText, getSalesTextFromTimeScale } from "./utils";
import { useAppConfig } from "../../common/redux-hooks";

interface IProps {}

export const Sales = ({}: IProps): React.ReactElement<IProps> => {
  const { store, language } = useAppConfig();
  const colors = useColors();
  const [, animate] = useAnimate();

  const [timeScaleDisabled, setTimeScaleDisabled] = useState(false);
  const [timeScale, setTimeScale] = useState<AnalyticsTimeScale>(
    AnalyticsTimeScale.Month
  );
  const [fromTo, setFromTo] = useState<TOnSelectDate | undefined>();

  const [salesValue, setSalesValue] = useState(0);
  const [previousSalesValue, setPreviousSalesValue] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    const getSalesByTimeScale = async () => {
      const timeScaleOrDate =
        fromTo?.type === SelectedDateType.Range
          ? { from: fromTo.from.getTime(), to: fromTo.to.getTime() }
          : fromTo?.type === SelectedDateType.Single
          ? { from: fromTo.date.getTime(), to: fromTo.date.getTime() }
          : timeScale;

      try {
        const { previousSalesByTimeScale, totalSales } =
          await analyticsModel.getSales(timeScaleOrDate, store._id, language._id);

        setPreviousSalesValue(previousSalesByTimeScale);

        animate(salesValue, totalSales, {
          onUpdate(latest) {
            setSalesValue(latest);
          },
        });
      } catch (err) {}
    };

    getSalesByTimeScale();
  }, [timeScale, fromTo, language, store]);

  const currencySymbol = language.currency.symbol;

  return (
    <Flex>
      <InputsWrapper>
        <div>
          <div
            style={{
              fontSize: 22,
              fontWeight: 500,
              marginLeft: 6,
              marginBottom: 8,
            }}
          >
            Prodej
          </div>

          <FlexCenterAlign>
            <Flex>
              <Dropdown
                style={{
                  opacity: timeScaleDisabled ? 0.5 : undefined,
                }}
                disabled={timeScaleDisabled}
                title="Vyberte časové rozmezí"
                list={Object.values(AnalyticsTimeScale)
                  .reverse()
                  .map((s) => ({
                    content: getDefaultTimeScaleText(s),
                    value: s,
                    unique_id: s,
                  }))}
                isDeleteButtonDisabled
                onSelectValue={setTimeScale}
                selectedUniqueId={timeScale}
              />
            </Flex>

            <FlexCenterAlign
              style={{
                marginLeft: 20,
                marginTop: -1,
              }}
            >
              <CalendarButton
                isRange
                selectedDate={fromTo}
                setSelectedDate={(date) => {
                  setTimeScaleDisabled(!!date);
                  setFromTo(date || undefined);
                }}
                buttonProps={{
                  style: {
                    backgroundColor: colors.MAIN_400,
                  },
                  hoverBackgroundColor: colors.MAIN_350,
                }}
              />
              {fromTo && (
                <DeleteButton
                  style={{
                    marginLeft: 8,
                  }}
                  data-title="Vymazat datum"
                  onClick={() => {
                    setTimeScaleDisabled(false);
                    setFromTo(undefined);
                  }}
                />
              )}
            </FlexCenterAlign>
          </FlexCenterAlign>
        </div>

        <div
          style={{
            fontWeight: 500,
            fontSize: 24,
            marginLeft: 8,
            marginTop: 12,
          }}
        >
          {getPrice(salesValue, currencySymbol)}
        </div>
        {previousSalesValue !== undefined && !fromTo && (
          <div
            style={{
              marginLeft: 8,
            }}
          >
            <PositiveNegativeIndicator
              value={salesValue}
              comparingValue={previousSalesValue}
              formatDiff={(value) => getPrice(value, currencySymbol)}
              text={getSalesTextFromTimeScale(timeScale)}
            />
          </div>
        )}
        {/* <LineChart data={exampleData} width={400} height={400}>
      <XAxis dataKey="name" />
      <Tooltip
        formatter={(value) => {
          return getPrice(value as number, currencySymbol);
        }}
        wrapperStyle={{
          outline: "none",
          borderRadius: borderRadius,
        }}
        contentStyle={{
          background: "#444",
          outline: "none",
          border: "none",
          borderRadius: borderRadius,
        }}
      />
      <CartesianGrid
        stroke="#ffffff"
      />
      <Line
        type="linear"
        dataKey="price"
        name="Obrat vč. DPH"
        stroke="#ffffff"
        strokeWidth={3}
        yAxisId={0}
      />
    </LineChart> */}
      </InputsWrapper>
    </Flex>
  );
};

export default Sales;
