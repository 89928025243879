import React, { PropsWithChildren, useState } from "react";

interface IPortalContext {
  addNode(node: React.ReactNode): void;
  removeNode(): void;
}

const PortalContext = React.createContext<IPortalContext>({} as IPortalContext);

export const usePortal = () => {
  return React.useContext(PortalContext);
};

export const PortalProvider = ({ children }: PropsWithChildren) => {
  const [node, setNode] = useState<React.ReactNode>(null);

  return (
    <PortalContext.Provider
      value={{ addNode: setNode, removeNode: () => setNode(null) }}
    >
      {node}
      {children}
    </PortalContext.Provider>
  );
};

export default PortalProvider;
