import React from "react";
import styled, { css } from "styled-components";
import { TaskPriority, TaskState } from "../../../types/tasks";
import colors from "../../../ui/colors";
import Dropdown, { TDropdownItem } from "../../../ui/dropdown";

interface IPriorityTextProps {
  isButton?: boolean;
}

const PriorityText = styled.div<IPriorityTextProps>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_450};
  `}

  font-weight: 500;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  font-size: 20px;
  width: 32px;
  height: 32px;
  user-select: none;

  ${({ isButton }) => css`
    cursor: ${isButton ? "pointer" : "default"};
  `}
`;

export const priorityArray = [
  TaskPriority.Low,
  TaskPriority.Medium,
  TaskPriority.High,
];

export const getPriorityComponent = (
  priority: TaskPriority,
  isButton?: boolean,
  onClick?: () => void,
  tooltipDisabled?: boolean
) => {
  switch (priority) {
    case TaskPriority.Low:
      return (
        <PriorityText
          onClick={onClick}
          isButton={isButton}
          data-title={tooltipDisabled ? undefined : "Nízká priorita"}
          style={{
            color: "#8ed83f",
          }}
        >
          !
        </PriorityText>
      );
    case TaskPriority.Medium:
      return (
        <PriorityText
          onClick={onClick}
          isButton={isButton}
          data-title={tooltipDisabled ? undefined : "Střední priorita"}
          style={{
            color: "#f4d01c",
          }}
        >
          !!
        </PriorityText>
      );
    case TaskPriority.High:
      return (
        <PriorityText
          onClick={onClick}
          isButton={isButton}
          data-title={tooltipDisabled ? undefined : "Vysoká priorita"}
          style={{
            color: "#d8513f",
          }}
        >
          !!!
        </PriorityText>
      );
  }
};

export const getTextByState = (state: TaskState): string => {
  switch (state) {
    case TaskState.New:
      return "Nové";
    case TaskState.InProgress:
      return "Zpracovává se";
    case TaskState.Done:
      return "Hotovo";
    case TaskState.Verified:
      return "Ověřeno";
    case TaskState.Invalid:
      return "Nevalidní";
  }
};

const StateText = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

const taskStateList: TDropdownItem[] = [
  {
    content: <StateText>{getTextByState(TaskState.Invalid)}</StateText>,
    value: TaskState.Invalid,
    unique_id: TaskState.Invalid,
    query: getTextByState(TaskState.Invalid),
  },
  {
    content: <StateText>{getTextByState(TaskState.Verified)}</StateText>,
    value: TaskState.Verified,
    unique_id: TaskState.Verified,
    query: getTextByState(TaskState.Verified),
  },
  {
    content: <StateText>{getTextByState(TaskState.Done)}</StateText>,
    value: TaskState.Done,
    unique_id: TaskState.Done,
    query: getTextByState(TaskState.Done),
  },
  {
    content: <StateText>{getTextByState(TaskState.InProgress)}</StateText>,
    value: TaskState.InProgress,
    unique_id: TaskState.InProgress,
    query: getTextByState(TaskState.InProgress),
  },
  {
    content: <StateText>{getTextByState(TaskState.New)}</StateText>,
    value: TaskState.New,
    unique_id: TaskState.New,
    query: getTextByState(TaskState.New),
  },
];

interface IProps {
  selectedState: TaskState;
  setSelectedState(state: TaskState): void;
}

export const TaskDropdown = ({
  selectedState,
  setSelectedState,
}: IProps): React.ReactElement<IProps> => {
  return (
    <Dropdown
      isDeleteButtonDisabled
      title="Stav"
      list={taskStateList}
      onSelectValue={(state) => setSelectedState(state)}
      selectedUniqueId={selectedState}
    />
  );
};

export default TaskDropdown;
