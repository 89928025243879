import { TLanguageNumber } from "./common";
import { IStoreID } from "./store";

export interface ICouponID {
  _id: string;
  key: string;
  value: TLanguageNumber;
  type: CouponType;
  validUntil: number;
  date: number;
  maxNumberOfUses: number;
  orders: string[];
  stores: IStoreID[];
}

export enum CouponType {
  Percent = "PERCENT",
  Value = "VALUE",
}

export const couponTypes = [CouponType.Percent, CouponType.Value]
