import React, { StyleHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { useColors } from "../providers/theme/theme-provider";

const LoadingWrapper = styled.div<{ radius: number; borderColor: string }>`
  &.lds-ring {
    display: inline-block;
    position: relative;
    width: ${(props) => props.radius}px;
    height: ${(props) => props.radius}px;
  }
  &.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => 0.8 * props.radius}px;
    height: ${(props) => 0.8 * props.radius}px;
    margin: 8px;
    border: 8px solid #fff;
    border: ${(props) => 0.1 * props.radius}px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => css`${props.borderColor} transparent transparent transparent;`}
  }
  &.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  &.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  &.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface IProps {
  borderColor?: string;
  radius?: number;
  style?: StyleHTMLAttributes<HTMLDivElement>;
}

export const Loading = ({
  radius,
  borderColor,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors()

  return (
    <LoadingWrapper
      className="lds-ring"
      radius={radius ?? 60}
      borderColor={borderColor ?? colors.MAIN_100}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingWrapper>
  );
};

export default Loading;
