import axios from "../axios";
import { ILanguageID } from "./../../types/language";

export interface ILanguageModel {
  getLanguages: () => Promise<ILanguageID[]>;
  getDefaultLanguage: () => Promise<ILanguageID>;
  getLanguageById: (id: string) => Promise<ILanguageID>;
  addLanguage: (data: FormData) => Promise<ILanguageID>;
  editLanguage: (data: FormData) => Promise<ILanguageID>;
  removeLanguageById: (id: string) => Promise<boolean>;
  editTermsAndConditions: (terms: string, _id: string) => Promise<ILanguageID>;
  editComplaintsInfo: (complaintsInfo: string, _id: string) => Promise<ILanguageID>;
  editPrivacyPolicy: (privacyPolicy: string, _id: string) => Promise<ILanguageID>;
}

export const languageModel: ILanguageModel = {
  getLanguages() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ILanguageID[]>(`/languages/get-all`);

        res(response.data);
      } catch (err) {
        rej(err);
      }
    });
  },
  getDefaultLanguage() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ILanguageID>(
          `/languages/get-default-language`
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getLanguageById(id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ILanguageID>(`/languages/get-by-id`, {
          params: {
            id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addLanguage(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILanguageID>(`/languages/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editLanguage(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILanguageID>(`/languages/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editTermsAndConditions(terms, _id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILanguageID>(
          `/languages/edit-terms-and-conditions`,
          { terms, _id }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editComplaintsInfo(complaintsInfo, _id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILanguageID>(
          `/languages/edit-complaints-info`,
          { complaintsInfo, _id }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editPrivacyPolicy(privacyPolicy, _id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ILanguageID>(
          `/languages/edit-privacy-policy`,
          { privacyPolicy, _id }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeLanguageById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/languages/remove-by-id`, {
          id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
