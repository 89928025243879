import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../common/redux-hooks";
import { useSelector } from "../redux/store";
import {
  canViewItemByRole,
  IPermissions,
  IRolePermissions,
  TViewPermission,
} from "../types/role";
import LoadingScreen from "../view/components/loading-screen";

interface IProps {
  children: ReactNode;
  permission?: TViewPermission;
  accessDeniedPath?: string;
  showByCondition?: boolean;
}

export const PermissionWrapper = ({
  showByCondition,
  permission,
  accessDeniedPath,
  children,
}: IProps) => {
  const { user, logged, isLoading } = useSelector(({ user }) => user);

  if (showByCondition) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (user && logged && !isLoading) {
    if (!permission || canViewItemByRole(user.role, permission)) {
      return <>{children}</>;
    }

    return <Navigate to={accessDeniedPath || "/"} />;
  }

  return <Navigate to="/login" />;
};

type TWrapPermission = {
  type: keyof IRolePermissions;
  permission?: (keyof IPermissions)[] | keyof IPermissions;
  accessDeniedPath?: string;
};

export const wrapWithPermission = (
  component: ReactNode,
  wrap?: TWrapPermission,
  showByCondition?: boolean
) => {
  return (
    <PermissionWrapper
      permission={
        wrap
          ? {
              type: wrap.type,
              permission: wrap.permission,
            }
          : undefined
      }
      accessDeniedPath={wrap?.accessDeniedPath}
      showByCondition={showByCondition}
    >
      {component}
    </PermissionWrapper>
  );
};

export default PermissionWrapper;
