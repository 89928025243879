import { HistoryActionType } from "../../../types/history";

export const getKnownObjectKeyTitle = (key: string) => {
  switch (key) {
    case "name":
      return "Název";
    case "categories":
      return "Kategorie";
    case "description":
      return "Popis";
    case "ean":
      return "EAN";
    case "image":
      return "Foto";
    case "params":
      return "Parametry";
    case "manufacturer":
      return "Výrobce";
    case "slug":
      return "URL slug";
    case "eshop_id":
      return "Kód";
    case "packaging":
      return "Balení";
    case "price_vat":
      return "Prodávací cena";
    case "stock":
      return "Počet kusů na skladě";
    case "gallery":
      return "Galerie";
    case "buy_price":
      return "Nákupní cena";
    case "available_on_eshop":
      return "Dostupné v obchodě";
    case "translation_needed":
      return "Je potřeba překlad";
    default:
      return key;
  }
};

export const getTitleForHistoryActionType = (
  type: HistoryActionType
): string => {
  switch (type) {
    case HistoryActionType.ADD:
      return "Přidání";
    case HistoryActionType.EDIT:
      return "Úprava";
    case HistoryActionType.REMOVE:
      return "Vymazání";
  }
};
