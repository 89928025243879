import React, { useEffect, useState } from "react";
import { IoImagesOutline } from "react-icons/io5";
import { getManufacturerName } from "../../../common/functions";
import {
  NonTranslatedString,
  nonTranslatedString,
} from "../../../constants/language";
import { galleryModel } from "../../../model/query/galleryModel";
import {
  TLanguageBoolean,
  TLanguageNumber,
  TLanguageSlug,
  TLanguageString,
} from "../../../types/common";
import { IImageDB } from "../../../types/gallery";
import { IPackaging } from "../../../types/products";
import colors from "../../../ui/colors";
import { Flex, FlexCenterAll, GalleryImage } from "../../../ui/common";
import {
  useAppConfig,
  useManufacturers,
  useParameterValues,
  useParameters,
} from "./../../../common/redux-hooks";
import { useSelector } from "./../../../redux/store";
import { getKnownObjectKeyTitle } from "./utils";

interface IImageChangeComponentProps {
  id: string;
}

const ImageChangeComponent = ({
  id,
}: IImageChangeComponentProps): React.ReactElement<IImageChangeComponentProps> => {
  const [image, setImage] = useState<IImageDB | null>(null);

  useEffect(() => {
    const getImage = async (id: string) => {
      const image = await galleryModel.getImageById(id);

      setImage(image);
    };

    if (id && typeof id === "string") {
      getImage(id);
    } else {
      setImage(null);
    }
  }, [id]);

  if (!image)
    return (
      <FlexCenterAll
        style={{
          height: 128,
          width: 128,
        }}
      >
        <IoImagesOutline size={64} color={colors.DarkerAliceBlue} />
      </FlexCenterAll>
    );

  return (
    <div>
      <GalleryImage
        style={{
          maxWidth: 128,
          margin: 0,
          height: "auto",
        }}
        src={image.url}
      />
    </div>
  );
};

interface ILanguageStringChangeComponentProps {
  languageString: TLanguageString;
}

const LanguageStringChangeComponent = ({
  languageString,
}: ILanguageStringChangeComponentProps): React.ReactElement<ILanguageStringChangeComponentProps> => {
  const languages = useSelector(({ languages }) => languages.languages);

  return (
    <div>
      {Object.keys(languageString).map((k, i) => (
        <div key={i}>
          <span
            style={{
              fontWeight: 500,
            }}
          >
            {languages.find((l) => l.locale === k)?.title || ""}
          </span>
          : {languageString[k]}
        </div>
      ))}
    </div>
  );
};

interface ILanguageStringHTMLChangeComponentProps {
  languageString: TLanguageString;
}

const LanguageStringHTMLChangeComponent = ({
  languageString,
}: ILanguageStringHTMLChangeComponentProps): React.ReactElement<ILanguageStringHTMLChangeComponentProps> => {
  const languages = useSelector(({ languages }) => languages.languages);

  return (
    <div>
      {Object.keys(languageString).map((k, i) => (
        <Flex key={i}>
          <span
            style={{
              fontWeight: 500,
              marginRight: 5,
            }}
          >
            {languages.find((l) => l.locale === k)?.title || ""}:
          </span>
          <div dangerouslySetInnerHTML={{ __html: languageString[k] }} />
        </Flex>
      ))}
    </div>
  );
};

interface ICategoriesChangeComponentProps {
  ids: { [key: string]: string };
}

const CategoriesChangeComponent = ({
  ids,
}: ICategoriesChangeComponentProps): React.ReactElement<ICategoriesChangeComponentProps> => {
  const categories = useSelector(({ categories }) => categories.categories);
  const { language } = useAppConfig();

  return (
    <div>
      {Object.keys(ids).map((key, i) => (
        <div key={i}>
          {categories.find((c) => c._id === ids[key])?.name[
            language.locale
          ] || <NonTranslatedString />}
        </div>
      ))}
    </div>
  );
};

interface IBooleanChangeComponent {
  value: boolean;
}

const BooleanChangeComponent = ({
  value,
}: IBooleanChangeComponent): React.ReactElement<IBooleanChangeComponent> => {
  return <div>{value ? "Ano" : "Ne"}</div>;
};

interface ISlugChangeComponentProps {
  slug: TLanguageSlug;
}

const SlugChangeComponent = ({
  slug,
}: ISlugChangeComponentProps): React.ReactElement<ISlugChangeComponentProps> => {
  const languages = useSelector(({ languages }) => languages.languages);

  return (
    <div>
      {Object.keys(slug).map((k, i) => (
        <div key={i}>
          <span
            style={{
              fontWeight: 500,
            }}
          >
            {languages.find((l) => l.locale === k)?.title || "(Nedefinováno)"}
          </span>
          : {slug[k]?.value}
        </div>
      ))}
    </div>
  );
};

interface IBooleanSlugChangeComponentProps {
  slug: TLanguageBoolean;
}

const BooleanSlugChangeComponent = ({
  slug,
}: IBooleanSlugChangeComponentProps): React.ReactElement<IBooleanSlugChangeComponentProps> => {
  const languages = useSelector(({ languages }) => languages.languages);

  return (
    <div>
      {Object.keys(slug).map((k, i) => (
        <div key={i}>
          <span
            style={{
              fontWeight: 500,
            }}
          >
            {languages.find((l) => l.locale === k)?.title || "(Nedefinováno)"}
          </span>
          : {slug?.[k] ? "Ano" : "Ne"}
        </div>
      ))}
    </div>
  );
};

interface INumberSlugChangeComponentProps {
  slug: TLanguageNumber;
  isCurrency?: boolean;
}

const NumberSlugChangeComponent = ({
  slug,
  isCurrency,
}: INumberSlugChangeComponentProps): React.ReactElement<INumberSlugChangeComponentProps> => {
  const languages = useSelector(({ languages }) => languages.languages);

  return (
    <div>
      {Object.keys(slug).map((k, i) => {
        const language = languages.find((l) => l.locale === k);

        return (
          <div key={i}>
            <span
              style={{
                fontWeight: 500,
              }}
            >
              {language?.title || "(Nedefinováno)"}
            </span>
            : {slug?.[k]}{" "}
            {isCurrency && language?.currency ? language.currency.symbol : null}
          </div>
        );
      })}
    </div>
  );
};

const getPackagingAttributeNameByKey = (key: string) => {
  switch (key) {
    case "brutto_weight":
      return "Hrubá váha";
    case "netto_weight":
      return "Čistá váha";
    case "height":
      return "Výška";
    case "length":
      return "Délka";
    case "width":
      return "Šířka";
    default:
      return "";
  }
};

interface IPackagingChangecomponent {
  packaging: IPackaging;
}

const PackagingChangeComponent = ({
  packaging,
}: IPackagingChangecomponent): React.ReactElement<IPackagingChangecomponent> => {
  return (
    <div>
      {Object.keys(packaging).map((key, i) => (
        <div key={i}>
          <span>{getPackagingAttributeNameByKey(key)}: </span>
          <span
            style={{
              fontWeight: 500,
            }}
          >
            {packaging[key as keyof IPackaging].value}{" "}
            {packaging[key as keyof IPackaging].unit}
          </span>
        </div>
      ))}
    </div>
  );
};

interface IChangeParam {
  parameter: string;
  value_id?: string;
  value_number?: number;
  unit?: string;
}

interface IParametersChangeComponentProps {
  params: IChangeParam[];
}

const ParametersChangeComponent = ({
  params,
}: IParametersChangeComponentProps): React.ReactElement<IParametersChangeComponentProps> => {
  const parameters = useParameters();
  const parameterValues = useParameterValues();
  const { language } = useAppConfig();

  return (
    <div>
      {params.map((p, i) => {
        const param = parameters.find((_p) => _p._id === p.parameter);
        const paramValue =
          p.value_id &&
          parameterValues.find((_p) => p.value_id && _p._id === p.value_id);

        return (
          <div key={i}>
            <span>
              {!param
                ? "Neznámý parametr"
                : param.title[language.locale] || nonTranslatedString}
            </span>
            <span
              style={{
                margin: "0 8px",
                fontWeight: 600,
              }}
            >
              {!paramValue
                ? p.value_number
                : paramValue.value[language.locale] || nonTranslatedString}
            </span>
            {p.unit && <span>{p.unit}</span>}
          </div>
        );
      })}
    </div>
  );
};

interface IManufacturerChangeComponentProps {
  id: string;
}

const ManufacturerChangeComponent = ({
  id,
}: IManufacturerChangeComponentProps): React.ReactElement<IManufacturerChangeComponentProps> => {
  const manufacturers = useManufacturers();
  const { language } = useAppConfig();

  const manufacturer = manufacturers.find((m) => m._id === id);

  if (!manufacturer && !id) {
    return <div>(Nedefinováno)</div>;
  }

  if (manufacturer) {
    return (
      <div>
        {getManufacturerName(manufacturer, language.locale) || (
          <NonTranslatedString />
        )}
      </div>
    );
  } else {
    return <div>(Výrobce nenalezen)</div>;
  }
};

const getChangesComponent = (value: any, key: string) => {
  switch (key) {
    case "name":
      return <LanguageStringChangeComponent languageString={value} />;
    case "categories":
      return <CategoriesChangeComponent ids={value} />;
    case "description":
      return <LanguageStringHTMLChangeComponent languageString={value} />;
    case "image":
      return <ImageChangeComponent id={value} />;
    case "gallery":
      return (
        <div>
          {value.map((id: string, i: number) => (
            <ImageChangeComponent key={i} id={id} />
          ))}
        </div>
      );
    case "manufacturer":
      return <ManufacturerChangeComponent id={value} />;
    case "params":
      return <ParametersChangeComponent params={value} />;
    case "slug":
      return <SlugChangeComponent slug={value} />;
    case "packaging":
      return <PackagingChangeComponent packaging={value} />;
    case "available_on_eshop":
      return <BooleanSlugChangeComponent slug={value} />;
    case "translation_needed":
      return <BooleanChangeComponent value={!!value} />;
    case "price_vat":
    case "buy_price":
      return <NumberSlugChangeComponent isCurrency slug={value} />;
    default:
      return value?.toString?.() || "(Nedefinováno)";
  }
};

interface IProps {
  changes: any;
}

export const HistoryActionChanges = ({
  changes,
}: IProps): React.ReactElement<IProps> => {
  if (!changes) {
    return <div>Prázdné</div>;
  }

  const keys = Object.keys(changes);

  return (
    <>
      {keys.map((k, i) => (
        <div
          key={i}
          style={{
            marginTop: i === 0 ? 0 : 12,
          }}
        >
          <div
            style={{
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            {getKnownObjectKeyTitle(k)}
          </div>
          <div
            style={{
              fontSize: 12,
            }}
          >
            {getChangesComponent(changes[k], k)}
          </div>
        </div>
      ))}
    </>
  );
};

export default HistoryActionChanges;
