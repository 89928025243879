import React, { useState, useEffect } from "react";
import { getBase64 } from "../../common/base64fileEncoders";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { useModal } from "../../providers/modal/modal-provider";
import { useColors } from "../../providers/theme/theme-provider";
import { UPLOAD_IMAGE } from "../../redux/actions/galleryActions";
import { useDispatch } from "../../redux/store";
import { IImageDB, ImageType } from "../../types/gallery";
import Button from "../../ui/button";
import colors from "../../ui/colors";
import { Flex, FlexCenterAll, GalleryImage } from "../../ui/common";
import Dropdown from "../../ui/dropdown";
import FileInput from "../components/file-input";
import Input from "../../ui/input";
import { parse } from "path-browserify";
import { addZeroToLessThan10 } from "../../common/string-manipulation";

type TSource = {
  file: File;
  name: string;
  src: string;
};

interface IProps {
  type?: ImageType;
  disableSelectType?: boolean;
  multiple?: boolean;
  defaultName?: string;
  addSuffixToName?: boolean;
  skipSuffixIndex?: number;
  onSuccess?(images: IImageDB[]): void;
}

export const ImageUploadModal = ({
  type,
  disableSelectType,
  multiple = true,
  defaultName,
  addSuffixToName,
  skipSuffixIndex,
  onSuccess
}: IProps): React.ReactElement<IProps> => {
  const [files, setFiles] = useState<File[]>([]);
  const [sources, setSources] = useState<TSource[]>([]);
  const [selectedType, setSelectedType] = useState(type);
  const { closeModal } = useModal();
  const colors = useColors();
  const dispatch = useDispatch();
  const { call } = useQueryWithPopupErrorHandling();

  const appendImageToForm = (
    form: FormData,
    sources: TSource[],
    type: ImageType
  ) => {
    for (const source of sources) {
      form.append("image", source.file);
    }
    form.append("names", JSON.stringify(sources.map((s) => s.name)));
    form.append("type", type);
  };

  const uploadImage = async (sources: TSource[], type?: ImageType) => {
    const data = new FormData();

    appendImageToForm(data, sources, type || ImageType.Product);

    call(
      () => UPLOAD_IMAGE(data),
      "Nahrání fotky nebylo úspěšné",
      "Nahrání fotky bylo úspěšné",
      (action) => {
        onSuccess?.(action.payload);
        dispatch(action);
      }
    );
  };

  useEffect(() => {
    const setSourcesFromFiles = async () => {
      if (files) {
        const newSources: TSource[] = [];

        for (const [index, file] of files.entries()) {
          const nameWithoutExtenstion = parse(file.name).name;
          let name = nameWithoutExtenstion;

          if (defaultName) {
            name = defaultName;

            if(addSuffixToName) {
              name = `${name}_${addZeroToLessThan10(index + 1 + (skipSuffixIndex ? skipSuffixIndex : 0))}`
            }
          }

          newSources.push({
            file,
            name,
            src: await getBase64(file),
          });
        }

        setSources(newSources);
      }
    };

    setSourcesFromFiles();
  }, [files]);

  return (
    <div
      style={{
        minWidth: 300,
        maxWidth: "90vw",
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <div>
        <div
          style={{
            margin: 2,
            marginBottom: 8,
          }}
        >
          <FileInput
            multiple={!!multiple}
            setFiles={setFiles}
            setFile={(file) => setFiles([file])}
          />
        </div>
        {!disableSelectType && (
          <Dropdown
            list={[
              {
                content: "Kategorie",
                unique_id: ImageType.Category,
                value: ImageType.Category,
              },
              {
                content: "Produkty",
                unique_id: ImageType.Product,
                value: ImageType.Product,
              },
              {
                content: "Výrobci",
                unique_id: ImageType.Manufacturer,
                value: ImageType.Manufacturer,
              },
              {
                content: "Emaily",
                unique_id: ImageType.Email,
                value: ImageType.Email,
              },
            ]}
            title="Typ obrázku"
            onSelectValue={(_, uniqueId) =>
              setSelectedType(uniqueId as ImageType)
            }
            selectedUniqueId={selectedType}
          />
        )}
      </div>

      <Flex
        style={{
          marginTop: 16,
          flexWrap: "wrap",
          maxHeight: "50%",
          overflow: "auto",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        {sources.map((s, i) => (
          <FlexCenterAll
            style={{
              margin: 4,
            }}
            key={i}
          >
            <div>
              <FlexCenterAll>
                <GalleryImage src={s.src} />
              </FlexCenterAll>
              <FlexCenterAll>
                <Input
                  value={s.name}
                  setValue={(value) => {
                    const newSources = [...sources];

                    newSources[i].name = value;

                    setSources(newSources);
                  }}
                />
              </FlexCenterAll>
            </div>
          </FlexCenterAll>
        ))}
      </Flex>

      <FlexCenterAll
        style={{
          marginTop: 16,
        }}
      >
        <Button
          style={{
            marginRight: 12,
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!files.length || !selectedType}
          onClick={() =>
            files.length &&
            selectedType &&
            (() => {
              uploadImage(sources, selectedType);
              closeModal();
            })()
          }
        >
          OK
        </Button>
        <Button onClick={closeModal}>Storno</Button>
      </FlexCenterAll>
    </div>
  );
};

export default ImageUploadModal;
