import { TReduxError } from "../../types/error";
import { ErrorsActions, TErrorsAction } from "../actions/errorsActions";

export interface IErrorsState {
  errors: TReduxError[];
}

const initialErrorsState: IErrorsState = {
  errors: [],
};

export const errorsReducer = (
  state: IErrorsState = initialErrorsState,
  action: TErrorsAction
): IErrorsState => {
  switch (action.type) {
    case ErrorsActions.SET_ERRORS:
      if (Array.isArray(action.payload)) {
        return { ...state, errors: action.payload };
      }

      return state;
    case ErrorsActions.CLEAR_ERRORS_OF_TYPE:
      return {
        ...state,
        errors: state.errors.filter((e) => e.type !== action.payload),
      };
    default:
      return state;
  }
};
