import { IEditorSubProduct } from "./subproduct-editor";

export const getSubproductErrors = (
  subproduct: IEditorSubProduct,
  errors: { [key: string]: string | undefined }
) => {
    const stock = errors.stock && subproduct.stock === 0 ? errors.stock : undefined;
    const subProductExists = errors.subproduct_exists;
    const parametersNotValid = errors.parameters_not_valid;
    const noParameters = errors.no_parameters;

  return {
    stock,
    subProductExists,
    parametersNotValid,
    noParameters,
  };
};
