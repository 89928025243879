import { TLanguageBoolean, TLanguageNumber } from "./common";
import { IStoreID } from "./store";

export enum PaymentType {
  Card = "CARD",
  Cash = "CASH",
  GooglePay = "GOOGLE_PAY",
  ApplePay = "APPLE_PAY",
  BankTransfer = "BANK_TRANSFER",
}

export interface IPayment {
  type: PaymentType;
  price?: TLanguageNumber;
  stores: IStoreID[];
}

export interface IPaymentID extends IPayment {
  _id: string;
}
