import React, { useState } from "react";
import { useQueryWithPopupErrorHandling } from "../../../../common/hooks";
import { useUser } from "../../../../common/redux-hooks";
import { ADMIN_PERMISSIONS, WRITER_PERMISSIONS } from "../../../../common/role";
import { useModal } from "../../../../providers/modal/modal-provider";
import { SimplePopupType } from "../../../../providers/simple-popup/data";
import { useSimplePopups } from "../../../../providers/simple-popup/simple-popup-provider";
import { useColors } from "../../../../providers/theme/theme-provider";
import {
  ADD_DEFAULT_ADMIN,
  ADMIN_ADD_USER,
} from "../../../../redux/actions/usersActions";
import { useDispatch } from "../../../../redux/store";
import { IRole, UserRole } from "../../../../types/role";
import Button from "../../../../ui/button";
import {
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  SelectedWindowStyled,
} from "../../../../ui/common";
import UserPasswordEditor, {
  IPasswords,
} from "../../../profile/components/change-password/user-password-editor";
import UserInfoEditor, {
  IUserInfo,
} from "../../../profile/components/change-user-info/user-info-editor";
import UserPermissionsEditor from "../user-permissions-editor";
import WindowSelector, { AdminAddUserSelectedWindow } from "./window-selector";

interface IProps {
  isForDefaultAdmin?: boolean;
  registrationKey?: string;
  onSuccess?(): void;
}

export const AdminAddUserModal = ({
  isForDefaultAdmin,
  registrationKey,
  onSuccess,
}: IProps): React.ReactElement<IProps> => {
  const [selectedWindow, setSelectedWindow] = useState(
    AdminAddUserSelectedWindow.Info
  );
  const colors = useColors();
  const { closeModal } = useModal();

  const [userInfo, setUserInfo] = useState<IUserInfo>({
    firstName: "",
    lastName: "",
    userName: "",
  });

  const [passwords, setPasswords] = useState<IPasswords>({
    newPassword: "",
    newPasswordConfirm: "",
    oldPassword: "",
  });

  const [role, setRole] = useState<IRole>({
    role: UserRole.Writer,
    permissions: WRITER_PERMISSIONS,
  });

  const dispatch = useDispatch();
  const { call } = useQueryWithPopupErrorHandling();
  const { createSimplePopup } = useSimplePopups();

  const onAddNewUserClick = () => {
    const data = new FormData();

    data.append(
      "role",
      JSON.stringify(
        isForDefaultAdmin
          ? { role: UserRole.Admin, permissions: ADMIN_PERMISSIONS }
          : role
      )
    );
    data.append("firstName", userInfo.firstName);
    data.append("lastName", userInfo.lastName);
    data.append("userName", userInfo.userName);
    data.append("password", passwords.newPassword);

    if (isForDefaultAdmin && registrationKey) {
      data.append("registration_key", registrationKey);
      data.append("isDefaultAdmin", JSON.stringify(true));
    }

    call(
      () =>
        isForDefaultAdmin ? ADD_DEFAULT_ADMIN(data) : ADMIN_ADD_USER(data),
      "Přidání uživatele nebylo úspěšné",
      "Přidání uživatele bylo úspěšné",
      (action) => {
        dispatch(action);
        if (isForDefaultAdmin) {
          createSimplePopup(
            "Byl přidán výchozí admin účet. Nyní se můžete přihlásit.",
            SimplePopupType.Success
          );
        } else {
          createSimplePopup(
            "Byl přidán nový uživatel",
            SimplePopupType.Success
          );
        }
        onSuccess?.();
        closeModal();
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  return (
    <div
      style={{
        minWidth: isForDefaultAdmin ? undefined : 350,
      }}
    >
      <WindowSelector
        selectedWindow={selectedWindow}
        setSelectedWindow={setSelectedWindow}
        isForDefaultAdmin={isForDefaultAdmin}
      />
      <Flex>
        <SelectedWindowStyled
          visible={selectedWindow === AdminAddUserSelectedWindow.Info}
        >
          <UserInfoEditor setUserInfo={setUserInfo} userInfo={userInfo} />
        </SelectedWindowStyled>
        <SelectedWindowStyled
          visible={selectedWindow === AdminAddUserSelectedWindow.Password}
        >
          <UserPasswordEditor
            passwords={passwords}
            setPasswords={setPasswords}
          />
        </SelectedWindowStyled>
        {!isForDefaultAdmin && (
          <SelectedWindowStyled
            visible={selectedWindow === AdminAddUserSelectedWindow.Permissions}
          >
            <div style={{ marginTop: 12 }}>
              <UserPermissionsEditor role={role} setRole={setRole} />
            </div>
          </SelectedWindowStyled>
        )}
      </Flex>
      <div>
        <FlexCenterAll
          style={{
            marginTop: 28,
            marginBottom: 12,
          }}
        >
          <Button
            onClick={onAddNewUserClick}
            style={{
              marginRight: 12,
              backgroundColor: colors.MAIN_300,
            }}
            hoverBackgroundColor={colors.MAIN_250}
          >
            Přidat
          </Button>
          {!isForDefaultAdmin && <Button onClick={closeModal}>Storno</Button>}
        </FlexCenterAll>
      </div>
    </div>
  );
};

export default AdminAddUserModal;
