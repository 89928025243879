import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import Loading from "../../ui/loading";
import LoadingScreen from "./loading-screen";
import { useSelector } from "../../redux/store";

const LoadingWrapperStyled = styled.div`
  .loading-enter {
    opacity: 0;
  }

  .loading-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .loading-exit {
    opacity: 1;
  }

  .loading-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }

  .component-enter {
    opacity: 0;
  }

  .component-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .component-exit {
    opacity: 1;
  }

  .component-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
`;

interface ILoadingWrapperProps {
  children: React.ReactNode;
}

export const LoadingWrapper = ({
  children,
}: ILoadingWrapperProps): React.ReactElement<ILoadingWrapperProps> => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const cb = () => {
      setIsLoading(false);
    };

    document.addEventListener("readystatechange", cb);

    return () => {
      document.removeEventListener("readystatechange", cb);
    };
  }, []);

  return (
    <LoadingWrapperStyled>
      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames="loading"
        mountOnEnter
        unmountOnExit
      >
        <LoadingScreen />
      </CSSTransition>
      <CSSTransition
        in={!isLoading}
        timeout={300}
        classNames="component"
        unmountOnExit
      >
        <div>{children}</div>
      </CSSTransition>
    </LoadingWrapperStyled>
  );
};

export default LoadingWrapper;
