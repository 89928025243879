import React, { ButtonHTMLAttributes } from "react";
import { GrFormClose } from "react-icons/gr";
import styled, { css, CSSProperties } from "styled-components";
import IconButton, { IIconButtonProps } from "./icon-button";
import { AiFillEdit, AiOutlinePlus, AiOutlineSave } from "react-icons/ai";
import { HiLanguage } from "react-icons/hi2";
import { colors } from "./colors";
import { IoClose } from "react-icons/io5";
import { BiTrashAlt, BiX } from "react-icons/bi";
import { TbZoomQuestion } from "react-icons/tb";
import Button, { IButtonProps } from "./button";
import { FiTrash2 } from "react-icons/fi";
import { useColors } from "../providers/theme/theme-provider";
import { HiOutlineExternalLink } from "react-icons/hi";
import LazyLoadingGalleryImage from "./lazy-loading-image";
import { InfoTooltip } from "./tooltips/info-tooltip";
import { Link } from "react-router-dom";

export const StyledLabel = styled.div`
  font-size: 13px;
  margin-bottom: 4px;
  opacity: 0.8;
`;

export const FlexCenterAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexCenterAlign = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexCenterAlignBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const AddButtonStyles = {
  fontSize: 16,
  padding: "0.5em 1.5em",
};

export const AddButtonActionStyles = {
  fontSize: 15,
};

export const RemoveButtonStyles = {
  fontSize: 16,
  padding: "0.5em 1.5em",
  color: "#fff",
};

export const RemoveButton = ({ children, ...props }: IButtonProps) => {
  const colors = useColors();

  return (
    <Button
      {...props}
      style={{
        backgroundColor: colors.ERROR_400,
        ...RemoveButtonStyles,
        ...props.style,
      }}
      hoverBackgroundColor={colors.ERROR_500}
    >
      <FlexCenterAlign>
        <FiTrash2
          color={"#fff"}
          style={{
            marginRight: 8,
          }}
          size={19}
        />
        {children}
      </FlexCenterAlign>
    </Button>
  );
};

export const LinkTo = ({ ...props }: IIconButtonProps) => {
  const colors = useColors();

  return (
    <IconButton
      style={{
        padding: 6,
        marginRight: 6,
        backgroundColor: colors.MAIN_150,
      }}
      {...props}
    >
      <HiOutlineExternalLink size={16} color={colors.OPPOSITE_MAIN_400} />
    </IconButton>
  );
};

export const AddButton = ({ children, ...props }: IButtonProps) => {
  const colors = useColors();

  return (
    <Button
      {...props}
      style={{
        backgroundColor: colors.MAIN_300,
        ...AddButtonStyles,
        ...props.style,
      }}
      hoverBackgroundColor={colors.MAIN_250}
    >
      <FlexCenterAlign>
        <AiOutlineSave
          color={colors.OPPOSITE_MAIN_400}
          style={{
            marginRight: 8,
          }}
          size={19}
        />
        {children}
      </FlexCenterAlign>
    </Button>
  );
};

export const AddPlusButton = ({ children, ...props }: IIconButtonProps) => {
  const colors = useColors();

  return (
    <IconButton
      {...props}
      style={{
        backgroundColor: colors.MAIN_300,
        padding: 6,
        ...props.style,
      }}
      hoverBackgroundColor={colors.MAIN_250}
    >
      <AiOutlinePlus color={colors.OPPOSITE_MAIN_400} size={17} />
      {children}
    </IconButton>
  );
};

export const DropdownSelectedItemsWrapper = styled.div`
  font-size: 13px;

  & > div {
    padding: 4px 12px;

    ${({ theme }) => css`
      border-radius: ${theme.borderRadius / 2}px;
    `}

    &:first-child {
      margin-top: 2px;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
`;

interface IInputsWrapperProps {
  hoverable?: boolean;
  hoverColor?: string;
}

export const InputsWrapper = styled.div<IInputsWrapperProps>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_450};
    border-radius: ${props.theme.borderRadius}px;
  `}

  padding: 16px;

  ${({ hoverable, theme, hoverColor }) =>
    hoverable &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${hoverColor || theme.colors.MAIN_400};
      }
    `}
`;

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: number;
  hoverBackgroundColor?: string;
}

export const DeleteButton = ({
  size = 17,
  hoverBackgroundColor,
  ...props
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <IconButton
      {...props}
      style={{
        backgroundColor: colors.ERROR_400,
        padding: 4,
        ...props.style,
      }}
      hoverBackgroundColor={hoverBackgroundColor || colors.ERROR_500}
    >
      <BiX size={size} color={"#fff"} />
    </IconButton>
  );
};

export const CloseButton = ({
  size = 17,
  ...props
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <IconButton
      {...props}
      style={{
        backgroundColor: colors.MAIN_300,
        color: colors.OPPOSITE_MAIN_300,
        boxShadow: "4px 4px 12px #00000014",
        ...props.style,
      }}
      hoverBackgroundColor={colors.MAIN_250}
    >
      <IoClose size={size} color={colors.OPPOSITE_MAIN_300} />
    </IconButton>
  );
};

interface IEditButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: number;
  path?: string;
  hoverBackgroundColor?: string;
}

export const EditButton = ({
  path,
  size = 17,
  ...props
}: IEditButtonProps): React.ReactElement<IEditButtonProps> => {
  const colors = useColors();

  if (path) {
    return (
      <Link to={path}>
        <IconButton
          hoverBackgroundColor={colors.MAIN_250}
          {...props}
          style={{
            backgroundColor: colors.MAIN_200,
            padding: 4,
            ...props.style,
          }}
        >
          <AiFillEdit size={size} color={colors.OPPOSITE_MAIN_450} />
        </IconButton>
      </Link>
    );
  }

  return (
    <IconButton
      hoverBackgroundColor={colors.MAIN_250}
      {...props}
      style={{
        backgroundColor: colors.MAIN_200,
        padding: 4,
        ...props.style,
      }}
    >
      <AiFillEdit size={size} color={colors.OPPOSITE_MAIN_450} />
    </IconButton>
  );
};

interface ILanguageIconProps {
  size?: number;
  color?: string;
}

export const LanguageIcon = ({ size = 16, color }: ILanguageIconProps) => {
  return <HiLanguage size={size} color={color || "#eee"} />;
};

export const GalleryImage = styled.img`
  max-width: 196px;
  max-height: 196px;
  border-radius: 4px;
  margin: 12px;
`;

interface IEditorImageProps {
  src: string;
  onDeleteClick(): void;
  style?: CSSProperties;
  preventDelete?: boolean;
}

export const EditorImage = ({
  src,
  onDeleteClick,
  style,
  preventDelete,
}: IEditorImageProps) => {
  return (
    <FlexCenterAll style={style}>
      <div
        style={{
          position: "relative",
        }}
      >
        {!preventDelete && (
          <div
            style={{
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            <DeleteButton
              data-title="Odstranit fotku"
              style={{
                padding: 4,
              }}
              onClick={onDeleteClick}
            />
          </div>
        )}
        <LazyLoadingGalleryImage src={src} />
      </div>
    </FlexCenterAll>
  );
};

export const NoResultsFound = ({ text }: { text?: string }) => {
  return (
    <FlexCenterAll>
      <TbZoomQuestion
        color={colors.LightGraniteGray}
        style={{
          opacity: 0.4,
          marginRight: 20,
        }}
        size={36}
      />
      <div
        style={{
          opacity: 0.6,
          fontSize: 21,
        }}
      >
        {text || "Nebyly nalezeny žádné výsledky"}
      </div>
    </FlexCenterAll>
  );
};

interface IProfileImageProps {
  src?: string;
}

export const ProfileImage = styled.img<IProfileImageProps>`
  ${(props) => css`
    border-radius: ${props.theme.borderRadius}px;
  `}

  width: 48px;
  height: 48px;
  box-shadow: 4px 4px 12px #00000018;
  object-fit: cover;
`;

interface IDefaultAvatarProps {
  letter: string;
}

export const DefaultAvatar = ({ letter }: IDefaultAvatarProps) => {
  return (
    <FlexCenterAll
      style={{
        width: 48,
        height: 48,
        borderRadius: "50%",
        boxShadow: "4px 4px 12px #00000018",
        backgroundColor: colors.DarkLavenderBlue,
        fontSize: 24,
        color: colors.White,
      }}
    >
      {letter}
    </FlexCenterAll>
  );
};

export const HeaderText = styled.div`
  font-size: 26px;
  opacity: 0.75;
`;

export const SelectedWindowStyled = styled.div<{ visible: boolean }>`
  ${({ visible }) => css`
    display: ${visible ? "block" : "none"};
  `}
`;

interface ITextWithInfoProps {
  info: string;
  text: string;
}

export const TextWithInfo = ({ info, text }: ITextWithInfoProps) => {
  return (
    <FlexCenterAlign
      style={{
        fontSize: 13,
        justifyContent: "space-between",
      }}
    >
      {text}
      <div
        style={{
          marginLeft: 20,
        }}
      >
        <InfoTooltip info={info} />
      </div>
    </FlexCenterAlign>
  );
};
