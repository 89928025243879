import React, { useRef } from "react";
import ConfirmationWindow, {
  IConfirmationWindowRef,
} from "./confirmation-window";

interface IConfirmationContext {
  createConfirmation(
    title: string,
    onSubmit: () => void,
    onCancel?: () => void
  ): void;
}

const ConfirmationContext = React.createContext<IConfirmationContext>(
  {} as IConfirmationContext
);

export const useConfirmation = () => {
  return React.useContext(ConfirmationContext);
};

interface IProps {
  children: React.ReactNode;
}

export const ConfirmationProvider = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const confirmationRef = useRef<IConfirmationWindowRef>(null);

  const confirmationContext: IConfirmationContext = {
    createConfirmation(title, onSubmit, onCancel) {
      confirmationRef.current?.show(title, onSubmit, onCancel);
    },
  };

  return (
    <ConfirmationContext.Provider value={confirmationContext}>
      <ConfirmationWindow ref={confirmationRef} />
      {children}
    </ConfirmationContext.Provider>
  );
};

export default ConfirmationProvider;
