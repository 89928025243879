import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useParameterGroups
} from "../../common/redux-hooks";
import { IParameterGroupID } from "../../types/parameter";
import Button from "../../ui/button";
import Checkbox from "../../ui/checkbox";
import {
  AddButtonActionStyles,
  EditButton,
  FlexCenterAll,
} from "../../ui/common";
import Input from "../../ui/input";
import Table from "../../ui/table";

interface IRowActionButtons {
  parameterGroup: IParameterGroupID;
}

const RowActionButtons = ({
  parameterGroup,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  return (
    <div>
      <EditButton
        path={`edit-parameter-group/${parameterGroup._id}`}
        data-title="Upravit parametrovou skupinu"
      />
    </div>
  );
};

interface IProps {}

export const ParameterGroupsList = ({}: IProps): React.ReactElement<IProps> => {
  const parameterGroups = useParameterGroups();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        maxWidth: 700,
      }}
    >
      <Button
        style={{ ...AddButtonActionStyles }}
        onClick={() => navigate("/parameter-groups/add-parameter-group")}
      >
        Přidat parametrovou skupinu
      </Button>
      <Input
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 300
        }}
        placeholder="Vyhledat parametrovou skupinu"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <Table
        query={search}
        headerKeys={[
          {
            content: "",
            width: "10%",
          },
          {
            content: "Název",
            width: "35F%",
            query_key: "title",
          },
          {
            content: "Počet parametrů",
            width: "15%",
            query_key: "parameters",
          },
          {
            content: (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                Generovat varianty
              </div>
            ),
            width: "20%",
          },
          {
            content: (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                Generovat související produkty
              </div>
            ),
            width: "20%",
          },
        ]}
        rows={parameterGroups.map((p) => ({
          cells: [
            {
              content: <RowActionButtons parameterGroup={p} />,
              key: "actions",
              value: "actions",
            },
            {
              key: "title",
              content: p.title,
              value: p.title,
            },
            {
              key: "parameters",
              content: p.parameters.length,
              value: p.parameters.length,
            },
            {
              content: (
                <FlexCenterAll>
                  <Checkbox
                    readonly
                    value={p.generateVariants}
                    setValue={() => null}
                  />
                </FlexCenterAll>
              ),
              key: "generateVariants",
              value: p.generateVariants,
            },
            {
                content: (
                  <FlexCenterAll>
                    <Checkbox
                      readonly
                      value={p.generateRelatedProducts}
                      setValue={() => null}
                    />
                  </FlexCenterAll>
                ),
                key: "generateRelatedProducts",
                value: p.generateRelatedProducts,
              },
          ],
        }))}
      />
    </div>
  );
};

export default ParameterGroupsList;
