import React, { useState } from "react";
import { IProductParameter } from "../../../types/parameter";
import ParameterValuesMultiSelect from "../../../ui/parameter-value-multiselect";
import { ParameterInput } from "../product-editor/components/parameters-input/parameters-input";
import ParametersInput from "../product-editor/components/parameters-input/parameters-input";
import styled from "styled-components";
import Button from "../../../ui/button";
import { FlexCenterAll } from "../../../ui/common";
import { useModal } from "../../../providers/modal/modal-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { productModel } from "../../../model/query/productModel";
import { TSelectedProduct } from "../selected-products";
import { filterAndMapValidParams } from "../product-editor/product-editor";
import CategoryMultiSelection from "../../../ui/category-multi-selection";
import { useSelector } from "../../../redux/store";

const Wrapper = styled.div`
  padding-right: 60px;
`;

interface IProps {
  selectedProducts: TSelectedProduct[];
  type: "add" | "remove";
}

export const CategoryGroupActionModal = ({
  selectedProducts,
  type,
}: IProps): React.ReactElement<IProps> => {
  const { closeModal } = useModal();
  const colors = useColors();
  const [categories, setCategories] = useState<string[]>([]);

  const { call } = useQueryWithPopupErrorHandling();

  const { populatedCategories } = useSelector(({ categories }) => categories);

  const confirm = () => {
    const data = new FormData();

    data.append("categories", JSON.stringify(categories));
    data.append("products", JSON.stringify(selectedProducts.map((p) => p.id)));

    if (type === "add") {
      call(
        () => productModel.groupAddCategories(data),
        "Hromadné přidání kategorií nebylo úspěšné",
        "Hromadné přidání kategorií bylo úspěšné",
        () => closeModal()
      );
    } else {
      call(
        () => productModel.groupRemoveCategories(data),
        "Hromadné odebrání kategorií nebylo úspěšné",
        "Hromadné odebrání kategorií bylo úspěšné",
        () => closeModal()
      );
    }
  };

  return (
    <Wrapper>
      <CategoryMultiSelection
        title="Vyberte kategorii"
        selectedIDs={categories}
        categories={populatedCategories}
        list={populatedCategories}
        addCategory={(id) => setCategories((c) => [...c, id])}
        removeCategory={(id) =>
          setCategories((c) => c.filter((_id) => _id !== id))
        }
      />
      <FlexCenterAll
        style={{
          marginTop: 20,
        }}
      >
        <Button
          style={{ backgroundColor: colors.MAIN_350, marginRight: 12 }}
          hoverBackgroundColor={colors.MAIN_300}
          onClick={confirm}
        >
          {type === "add" ? "Přidat kategorie" : "Odebrat kategorie"}
        </Button>
        <Button onClick={closeModal}>Storno</Button>
      </FlexCenterAll>
    </Wrapper>
  );
};

export default CategoryGroupActionModal;
