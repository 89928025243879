import React, { useState } from "react";
import FileInput from "../../../../components/file-input";
import Button from "../../../../../ui/button";
import { useColors } from "../../../../../providers/theme/theme-provider";
import { useModal } from "../../../../../providers/modal/modal-provider";
import { FlexCenterAll } from "../../../../../ui/common";
import { useQueryWithPopupErrorHandling } from "../../../../../common/hooks";
import { productModel } from "../../../../../model/query/productModel";
import { useAppConfig } from "../../../../../common/redux-hooks";

interface IProps {}

export const ImportProductsDialog =
  ({}: IProps): React.ReactElement<IProps> => {
    const { closeModal } = useModal();
    const colors = useColors();
    const [file, setFile] = useState<File | null>(null);
    const { call } = useQueryWithPopupErrorHandling();
    const { language, store } = useAppConfig();

    const importProducts = () => {
      if (!file) {
        return;
      }

      const formData = new FormData();

      formData.append("file", file);
      formData.append("language", language._id);
      formData.append("store", store._id);

      call(
        () => productModel.importProducts(formData),
        "Import produktů nebyl úspěšný",
        "Import produktů byl úspěšný",
        () => {
          closeModal();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      );
    };

    return (
      <div
        style={{
          padding: 20,
          paddingRight: 40,
        }}
      >
        <div
          style={{
            marginBottom: 12,
          }}
        >
          Import produktů
        </div>
        <FileInput
          label="Nahrát soubor (.csv, .xls, .xlsx)"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          setFile={setFile}
          multiple={false}
        />
        {file && (
          <div
            style={{
              marginTop: 12,
              marginLeft: 7,
              fontSize: 11,
              maxWidth: 200,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {file.name}
          </div>
        )}
        <FlexCenterAll
          style={{
            marginTop: 20,
          }}
        >
          <Button
            style={{
              marginRight: 12,
              backgroundColor: colors.MAIN_300,
            }}
            disabled={!file}
            hoverBackgroundColor={colors.MAIN_250}
            onClick={importProducts}
          >
            Importovat
          </Button>
          <Button onClick={closeModal}>Storno</Button>
        </FlexCenterAll>
      </div>
    );
  };

export default ImportProductsDialog;
