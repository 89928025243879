import { IPaymentID } from "../../types/payment";
import axios from "../axios";

export interface IPaymentModel {
  getPayments: () => Promise<IPaymentID[]>;
  getPaymentById: (id: string) => Promise<IPaymentID>;
  addPayment: (data: FormData) => Promise<IPaymentID>;
  editPayment: (data: FormData) => Promise<IPaymentID>;
  removePaymentById: (id: string) => Promise<boolean>;
}

export const paymentModel: IPaymentModel = {
  getPayments() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IPaymentID[]>(`/payments/get-all`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getPaymentById(_id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IPaymentID>(`/payments/get-by-id`, {
          params: {
            _id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  addPayment(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IPaymentID>(`/payments/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editPayment(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IPaymentID>(`/payments/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removePaymentById(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/payments/remove-by-id`, {
          _id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
