import { useNavigate } from "react-router-dom";

export const useNavigateCatch = () => {
  try {
    const n = useNavigate();

    return n;
  } catch (err) {
    return () => null;
  }
};
