import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { emailsModel } from "../../../model/query/emailModel";
import { IEmailID } from "../../../types/email";
import { FlexCenterAlign, HeaderText } from "../../../ui/common";
import Table, { TRow } from "../../../ui/table";
import ViewWrapper from "../../view-wrapper";
import EmailRecipientsList from "./email-recipients-list";
import IconButton from "../../../ui/icon-button";
import { FiEye } from "react-icons/fi";
import { useModal } from "../../../providers/modal/modal-provider";
import EmailView from "./email-view";
import UnfinishedEmailsList from "./unfinished-emails-list";

interface IProps {}

const TableWrapper = styled.div`
  width: 75%;
`;

export const EmailsList = ({}: IProps): React.ReactElement<IProps> => {
  const [isLoading, setIsLoading] = useState(true);
  const [emails, setEmails] = useState<IEmailID[]>([]);
  const { createModal } = useModal();

  const onViewEmailClick = (e: IEmailID) => {
    createModal(<EmailView email={e} />);
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        setIsLoading(true);
        const e = await emailsModel.getEmails();
        setIsLoading(false);

        setEmails(e);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };

    fetchEmails();
  }, []);
  const rows: TRow[] = emails.map((e) => ({
    cells: [
      {
        content: (
          <FlexCenterAlign>
            <IconButton
              onClick={() => onViewEmailClick(e)}
              data-title="Zobrazit email"
              style={{
                padding: 4,
              }}
            >
              <FiEye size={16} />
            </IconButton>
          </FlexCenterAlign>
        ),
        key: "actions",
        value: "actions",
      },
      {
        key: "subject",
        value: e.subject,
        content: e.subject,
      },
      {
        key: "date",
        content: new Date(e.date).toLocaleString(),
        value: e.date,
      },
    ],
  }));

  return (
    <ViewWrapper>
      <TableWrapper>
        <HeaderText
          style={{
            marginBottom: 16,
          }}
        >
          Odeslané emaily
        </HeaderText>
        <Table
          loading={isLoading}
          headerKeys={[
            { content: "", width: "50px" },
            { content: "Název" },
            { content: "Odesláno" },
          ]}
          rows={rows}
        />
        <HeaderText
          style={{
            marginBottom: 16,
            marginTop: 24,
          }}
        >
          Rozpracované emaily
        </HeaderText>
        <UnfinishedEmailsList />
        <EmailRecipientsList />
      </TableWrapper>
    </ViewWrapper>
  );
};

export default EmailsList;
