import React, { useState } from "react";
import Button from "../../ui/button";
import { GoPayPaymentState, IOrder } from "../../types/order";
import { orderModel } from "../../model/query/orderModel";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { useColors } from "../../providers/theme/theme-provider";
import GoPayPaymentStateComponent from "./gopay-payment-state";
import { FlexCenterAlign } from "../../ui/common";

interface IProps {
  order: IOrder;
}

export const CheckGoPayPaymentStateCell = ({
  order,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();
  const { call } = useQueryWithPopupErrorHandling();
  const [goPayState, setGoPayState] = useState<GoPayPaymentState | null>(null);

  const checkGoPayPaymentState = async () => {
    call(
      () => orderModel.checkGoPayState(order._id),
      null,
      null,
      (state) => setGoPayState(state)
    );
  };
  return (
    <div>
      {goPayState && (
        <GoPayPaymentStateComponent isForTable goPayState={goPayState} />
      )}
      <Button
        wrapperStyle={{
          width: "100%",
        }}
        onClick={checkGoPayPaymentState}
        style={{
          fontWeight: 400,
          width: "100%",
          fontSize: 11,
          backgroundColor: colors.MAIN_300,
        }}
        hoverBackgroundColor={colors.MAIN_250}
      >
        {goPayState
          ? "Aktualizovat stav platby"
          : "Zjistit aktuální stav platby"}
      </Button>
    </div>
  );
};

export default CheckGoPayPaymentStateCell;
