import { TGetError } from "../../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../../types/error";
import { IEditorCurrency } from "./currency-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.CURRENCY);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TCurrencyEditorErrorsObject = {
  title_empty?: TReduxError;
  symbol_empty?: TReduxError;
  currency_shortcut_empty?: TReduxError;
  currency_shortcut_exists?: TReduxError;
};

type TCurrencyEditorErrors = {
  errors: TCurrencyEditorErrorsObject;
  isValid: boolean;
};

export const getCurrencyEditorErrors = (
  getError: TGetError,
  currency: IEditorCurrency
): TCurrencyEditorErrors => {
  const title_empty = getErrorOfKey(
    "title_empty",
    getError,
    currency.title === ""
  );
  const currency_shortcut_empty = getErrorOfKey(
    "currency_shortcut_empty",
    getError,
    currency.currency_shortcut === ""
  );

  const currency_shortcut_exists_params = getError(
    "currency_shortcut_exists",
    ErrorType.CURRENCY,
    {
      value: currency.currency_shortcut,
    }
  )?.params!;
  const currency_shortcut_exists = getErrorOfKey(
    "currency_shortcut_exists",
    getError,
    currency_shortcut_exists_params &&
      currency_shortcut_exists_params.value === currency.currency_shortcut
  );

  const symbol_empty = getErrorOfKey(
    "symbol_empty",
    getError,
    currency.symbol === ""
  );

  return {
    errors: {
      title_empty,
      symbol_empty,
      currency_shortcut_empty,
      currency_shortcut_exists,
    },
    isValid:
      !symbol_empty &&
      !title_empty &&
      !currency_shortcut_empty &&
      !currency_shortcut_exists,
  };
};
