import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isLanguageStringEmpty } from "../../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import {
  useAppConfig,
  useErrors,
  useStores,
} from "../../../common/redux-hooks";
import { useModel } from "../../../model/provider";
import { slugModel } from "../../../model/query/slugModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_CATEGORY,
  EDIT_CATEGORY,
  REMOVE_CATEGORY_BY_ID,
} from "../../../redux/actions/categoryActions";
import {
  ICategory,
  ICategoryId,
  IPopulatedCategoryId,
} from "../../../types/category";
import {
  EditorType,
  TLanguageSlug,
  TLanguageString,
} from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { IImageDB, ImageType } from "../../../types/gallery";
import { defaultSlug } from "../../../types/slugs";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import CategoryMultiSelection from "../../../ui/category-multi-selection";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  EditorImage,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
  SelectedWindowStyled,
} from "../../../ui/common";
import {
  InputTitle,
  InputWithTitle,
  InputWithTitleWrapper,
} from "../../../ui/input-with-title";
import SlugInput from "../../../ui/slug-input";
import { BackButton } from "../../components/back-button";
import HistoryView from "../../components/history/history-view";
import { nonTranslatedString } from "./../../../constants/language";
import { useNotifications } from "./../../../providers/notifications/notification-provider";
import { useDispatch, useSelector } from "./../../../redux/store";
import GalleryPicker from "./../../../ui/gallery-picker";
import { LanguageSelector } from "./../../../ui/language-selector";
import { getCategoryEditorErrors } from "./editor-errors";
import {
  deleteSavedCategoryStateFromLocalStorage,
  useLocalStorageCategorySaving,
} from "./hooks";
import WindowSelector, { CategorySelectedWindow } from "./window-selector";
import RichTextEditor from "../../../ui/editorTinyMce";
import { IStoreID } from "../../../types/store";
import DropdownMultiSelect from "../../../ui/dropdown-multi-select";
import SpecialAttributesEditor from "../../../ui/special-attributes/special-attributes-editor";
import {
  ISpecialAttributeID,
  mapSpecialAttribute,
  validSpecialAttributeFilter,
} from "../../../types/special-attribute";

const mapCategoryToEditorCategory = (
  category: ICategoryId
): IEditorCategory => {
  const {
    _id,
    image,
    icon,
    name,
    slug,
    subcategories,
    isMainCategory,
    description,
    stores,
    available_in_stores,
    specialAttributes,
  } = category;

  return {
    image,
    icon: icon || null,
    isMainCategory: isMainCategory || false,
    name,
    description: description || {},
    slug: slug || {},
    subcategories,
    stores,
    available_in_stores,
    specialAttributes,
    _id,
  };
};

const doesCategorySubcategoriesContainID = (
  category: IPopulatedCategoryId,
  id: string
): boolean => {
  for (let i = 0; i < category.subcategories.length; i++) {
    let s = category.subcategories[i];

    if (s._id === id) {
      return true;
    } else if (s.subcategories.length > 0) {
      return doesCategorySubcategoriesContainID(s, id);
    }
  }

  return false;
};

interface IEditorCategoryWithID extends IEditorCategory {
  _id: string;
}

const canCategoryBeUsedInCurrent = (
  category: IPopulatedCategoryId,
  current: IEditorCategoryWithID
) => {
  return (
    !category.isMainCategory &&
    category._id !== current._id &&
    !doesCategorySubcategoriesContainID(category, current._id)
  );
};

export interface IEditorCategory {
  _id?: string;
  name: TLanguageString;
  description: TLanguageString;
  image: IImageDB | null;
  icon: IImageDB | null;
  subcategories: string[];
  slug: TLanguageSlug;
  stores: IStoreID[];
  available_in_stores: IStoreID[];
  isMainCategory: boolean;
  specialAttributes: ISpecialAttributeID[];
}

const categoryInitState: IEditorCategory = {
  image: null,
  icon: null,
  isMainCategory: false,
  name: {},
  description: {},
  subcategories: [],
  slug: {},
  stores: [],
  available_in_stores: [],
  specialAttributes: [],
};

interface IProps {
  type: EditorType;
}

export const CategoryEditor = ({
  type,
}: IProps): React.ReactElement<IProps> => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const { closeNotification } = useNotifications();
  const [selectedWindow, setSelectedWindow] = useState<CategorySelectedWindow>(
    CategorySelectedWindow.BasicInfo
  );
  const stores = useStores();

  const { populatedCategories } = useSelector(({ categories }) => categories);
  const dispatch = useDispatch();
  const colors = useColors();

  const appConfig = useAppConfig();
  const [language, setLanguage] = useState(appConfig.language);
  const { categoryModel } = useModel();
  const [category, setCategory] = useState<IEditorCategory>(categoryInitState);
  const [originalEditingCategory, setOriginalEditingCategory] =
    useState<IEditorCategory | null>(null);
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getCategoryEditorErrors(
    getError,
    category,
    language.locale
  );
  const { call } = useQueryWithPopupErrorHandling();

  useLocalStorageCategorySaving(
    category,
    categoryInitState,
    originalEditingCategory,
    type,
    setCategory
  );

  const { createConfirmation } = useConfirmation();

  const getCategoryById = async (id: string) => {
    call(
      () => categoryModel.getCategoryById(id),
      "Zobrazení kategorie nebylo úspěšné",
      null,
      (category) => {
        const c = mapCategoryToEditorCategory(category);

        setOriginalEditingCategory(c);
        setCategory(c);
      },
      () => {
        navigate("/categories");
      }
    );
  };

  useEffect(() => {
    if (_id && type === EditorType.Edit) {
      getCategoryById(_id);
    }
  }, [_id, type]);

  const appendCategoryToForm = useCallback(
    (form: FormData) => {
      if (!isLanguageStringEmpty(category.name)) {
        form.append("name", JSON.stringify(category.name));
      }
      if (category.image) {
        form.append("image", category.image._id);
      }
      if (category.icon) {
        form.append("icon", category.icon._id);
      }
      form.append(
        "specialAttributes",
        JSON.stringify(
          category.specialAttributes
            .filter(validSpecialAttributeFilter)
            .map(mapSpecialAttribute)
        )
      );
      form.append("subcategories", JSON.stringify(category.subcategories));
      form.append("isMainCategory", JSON.stringify(category.isMainCategory));
      form.append(
        "stores",
        JSON.stringify(category.stores.map((store) => store._id))
      );
      form.append(
        "available_in_stores",
        JSON.stringify(category.available_in_stores.map((store) => store._id))
      );

      if (
        category.description &&
        !isLanguageStringEmpty(category.description)
      ) {
        form.append("description", JSON.stringify(category.description));
      }

      Object.keys(category.slug).forEach((key) => {
        const slug = category.slug[key];

        if (!slug.userDefined && !slug.value && !category.name[key]) {
          delete category.slug[key];
        }
      });

      form.append("slug", JSON.stringify(category.slug));
    },
    [category]
  );

  const addCategory = async () => {
    const data = new FormData();

    appendCategoryToForm(data);

    call(
      () => ADD_CATEGORY(data),
      "Přidání kategorie nebylo úspěšné",
      "Přidání kategorie bylo úspěšné",
      (c) => {
        dispatch(c);
        navigate("/categories");
        deleteSavedCategoryStateFromLocalStorage();
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  const removeCategory = () => {
    createConfirmation(
      "Potvrzení: Odstranit kategorii a její použití",
      async () => {
        if (!_id) {
          return;
        }

        call(
          () => REMOVE_CATEGORY_BY_ID(_id),
          "Odstranění kategorie nebylo úspěšné",
          "Odstranění kategorie bylo úspěšné",
          (action) => {
            dispatch(action);
            navigate("/categories");
          }
        );
      }
    );
  };

  const editCategory = async (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit kategorii", async () => {
      if (!_id) {
        return;
      }

      const data = new FormData();

      data.append("_id", _id);
      appendCategoryToForm(data);

      call(
        () => EDIT_CATEGORY(data),
        "Úprava kategorie nebyla úspěšná",
        "Úprava kategorie byla úspěšná",
        (action) => {
          deleteSavedCategoryStateFromLocalStorage(_id);
          dispatch(action);

          if (exit) {
            navigate("/categories");
          } else {
            setCategory(mapCategoryToEditorCategory(action.payload));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.CATEGORY);
      closeNotification();
    };
  }, []);

  const node = (
    <FlexCenterAll
      style={{
        marginLeft: 16,
      }}
    >
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!isValid}
          onClick={addCategory}
        >
          Přidat kategorii
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editCategory()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editCategory(true)}>
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removeCategory}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper
      node={node}
      deps={[isValid, appendCategoryToForm]}
    >
      <div>
        <BackButton path="/categories" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
          }}
        >
          {type === EditorType.Add ? "Přidání kategorie" : "Úprava kategorie"}
        </div>
      </div>
      <FlexCenterAlign
        style={{
          margin: "20px 8px",
          marginBottom: 16,
        }}
      >
        <LanguageSelector onSelectLanguage={setLanguage} />
      </FlexCenterAlign>
      <WindowSelector
        editorType={type}
        selectedWindow={selectedWindow}
        setSelectedWindow={setSelectedWindow}
      />
      <div>
        <SelectedWindowStyled
          visible={selectedWindow === CategorySelectedWindow.BasicInfo}
        >
          <Flex
            style={{
              marginRight: 26,
              alignItems: "center",
            }}
          >
            <InputsWrapper>
              <InputWithTitle
                inputStyle={{
                  fontSize: 18,
                  width: 400,
                }}
                wrapperStyle={{ marginTop: 0 }}
                title="Název kategorie"
                inputProps={{
                  placeholder: !category.name[language.locale]
                    ? nonTranslatedString
                    : undefined,
                  error: errors.name,
                }}
                value={category.name[language.locale] || ""}
                setValue={(name) =>
                  setCategory((c) => ({
                    ...c,
                    name: { ...c.name, [language.locale]: name },
                  }))
                }
              />
              {category.slug && (
                <SlugInput
                  error={
                    errors.slug_value_empty ||
                    errors.slug_value_invalid ||
                    errors.slug_already_exists
                  }
                  slug={category.slug[language.locale] || defaultSlug}
                  setSlug={(slug) =>
                    setCategory((c) => ({
                      ...c,
                      slug: {
                        ...c.slug,
                        [language.locale]: slug,
                      },
                    }))
                  }
                  value={category.name[language.locale] || ""}
                  getAvailableSlug={async () => {
                    if (!category.name[language.locale]) {
                      return defaultSlug;
                    }

                    return await slugModel.getAvailableSlug(
                      category.name[language.locale],
                      language.locale,
                      category._id
                    );
                  }}
                />
              )}
            </InputsWrapper>
          </Flex>
          <Flex
            style={{
              marginTop: 16,
            }}
          >
            <InputsWrapper>
              <InputWithTitleWrapper>
                <InputTitle>Dostupné pro obchody</InputTitle>
                <DropdownMultiSelect
                  list={stores.map((c) => ({
                    content: c.title,
                    value: c,
                    query: c.title,
                    unique_id: c._id,
                    disabled: category.stores.some((s) => s._id === c._id),
                  }))}
                  onSelectValue={(store) =>
                    setCategory((category) => ({
                      ...category,
                      stores: [...category.stores, store],
                    }))
                  }
                  selectedDropdownIDs={category.stores.map(
                    (store) => store._id
                  )}
                  onRemoveUniqueID={(id) =>
                    setCategory((category) => ({
                      ...category,
                      stores: category.stores.filter((s) => s._id !== id),
                    }))
                  }
                />
              </InputWithTitleWrapper>
              <FlexCenterAlign style={{ marginTop: 16 }}>
                <Checkbox
                  value={category.available_in_stores.some(
                    (categoryStore) => categoryStore._id === appConfig.store._id
                  )}
                  setValue={(value) =>
                    setCategory((category) => ({
                      ...category,
                      available_in_stores: value
                        ? [...category.available_in_stores, appConfig.store]
                        : category.available_in_stores.filter(
                            (store) => store._id !== appConfig.store._id
                          ),
                    }))
                  }
                />
                <div
                  style={{
                    marginLeft: 12,
                    fontWeight: 400,
                    fontSize: 13,
                  }}
                >
                  Viditelné v obchodě{" "}
                  <span
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {appConfig.store.title}
                  </span>
                </div>
              </FlexCenterAlign>
            </InputsWrapper>
          </Flex>
          <Flex
            style={{
              marginTop: 12,
            }}
          >
            <InputsWrapper>
              <FlexCenterAlign>
                <Checkbox
                  value={category.isMainCategory}
                  setValue={(isMainCategory) =>
                    setCategory((c) => ({ ...c, isMainCategory }))
                  }
                />
                <div
                  style={{
                    marginLeft: 12,
                    fontWeight: 400,
                    fontSize: 13,
                  }}
                >
                  Je hlavní kategorie
                </div>
              </FlexCenterAlign>
            </InputsWrapper>
          </Flex>
          <Flex>
            <InputsWrapper
              style={{
                paddingTop: 0,
                padding: "8px 24px",
                marginTop: 20,
              }}
            >
              <FlexCenterAlign>
                <div
                  style={{
                    fontSize: 20,
                    opacity: 0.75,
                    marginRight: 12,
                  }}
                >
                  Hlavní fotografie
                </div>
                <GalleryPicker
                  onChoose={(image) => setCategory((c) => ({ ...c, image }))}
                  title="Vybrat"
                  type={ImageType.Category}
                />
              </FlexCenterAlign>

              {category.image && (
                <EditorImage
                  style={{
                    marginTop: 12,
                  }}
                  src={category.image.url}
                  onDeleteClick={() =>
                    setCategory((p) => ({
                      ...p,
                      image: null,
                    }))
                  }
                />
              )}
            </InputsWrapper>
          </Flex>
          <Flex>
            <InputsWrapper
              style={{
                paddingTop: 0,
                padding: "8px 24px",
                marginTop: 20,
              }}
            >
              <FlexCenterAlign>
                <div
                  style={{
                    fontSize: 20,
                    opacity: 0.75,
                    marginRight: 12,
                  }}
                >
                  Ikona
                </div>
                <GalleryPicker
                  onChoose={(icon) => setCategory((c) => ({ ...c, icon }))}
                  title="Vybrat"
                  type={ImageType.Category}
                />
              </FlexCenterAlign>

              {category.icon && (
                <EditorImage
                  style={{
                    marginTop: 12,
                  }}
                  src={category.icon.url}
                  onDeleteClick={() =>
                    setCategory((p) => ({
                      ...p,
                      icon: null,
                    }))
                  }
                />
              )}
            </InputsWrapper>
          </Flex>
        </SelectedWindowStyled>
        <SelectedWindowStyled
          visible={selectedWindow === CategorySelectedWindow.Subcategories}
        >
          <InputsWrapper
            style={{
              width: 320,
              marginTop: 12,
            }}
          >
            <CategoryMultiSelection
              addCategory={(id) => {
                if (!id || typeof id !== "string") return;

                setCategory((c) => ({
                  ...c,
                  subcategories: [...c.subcategories, id],
                }));
              }}
              categories={populatedCategories}
              selectedIDs={category.subcategories}
              list={populatedCategories.filter((c) => {
                if (type === EditorType.Edit) {
                  return (
                    category &&
                    _id &&
                    canCategoryBeUsedInCurrent(c, { ...category, _id }) &&
                    !category.subcategories.some((s) => s === c._id)
                  );
                }

                return !category.subcategories.some((s) => s === c._id);
              })}
              removeCategory={(id) =>
                setCategory((c) => ({
                  ...c,
                  subcategories: c.subcategories.filter((sub) => sub !== id),
                }))
              }
            />
          </InputsWrapper>
        </SelectedWindowStyled>
        {category._id && (
          <SelectedWindowStyled
            visible={selectedWindow === CategorySelectedWindow.History}
          >
            <HistoryView
              item_id={category._id}
              onRevertAction={() => {
                if (!category._id) {
                  return;
                }

                getCategoryById(category._id);
              }}
            />
          </SelectedWindowStyled>
        )}
        <SelectedWindowStyled
          visible={selectedWindow === CategorySelectedWindow.Description}
        >
          <InputsWrapper
            style={{
              width: "100%",
            }}
          >
            <FlexCenterAlign
              style={{
                marginBottom: 12,
                marginLeft: 10,
              }}
            >
              <div
                style={{
                  fontSize: 18,
                  opacity: 0.6,
                }}
              >
                Popis kategorie
              </div>
            </FlexCenterAlign>
            <div
              style={{
                width: "100%",
                minWidth: 1000,
              }}
            >
              <RichTextEditor
                onEditorChange={(description) => {
                  setCategory((p) => ({
                    ...p,
                    description: {
                      ...p.description,
                      [language.locale]: description,
                    },
                  }));
                }}
                value={category.description[language.locale] || ""}
              />
            </div>
          </InputsWrapper>
        </SelectedWindowStyled>
        <SelectedWindowStyled
          visible={selectedWindow === CategorySelectedWindow.SpecialAttributes}
        >
          <SpecialAttributesEditor
            setSpecialAttributes={(specialAttributes) =>
              setCategory((c) => ({ ...c, specialAttributes }))
            }
            specialAttributes={category.specialAttributes}
          />
        </SelectedWindowStyled>
      </div>
      <div
        style={{
          height: 70,
        }}
      />
    </BottomFixedWrapperWrapper>
  );
};

export default CategoryEditor;
