import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { usePortal } from "../providers/portal/portal-provider";
import { useColors } from "../providers/theme/theme-provider";
import { Animations } from "../types/theme";
import { useSidebar } from "../view/sidebar/sidebar";

const Wrapper = styled.div<{ sidebarWidth: number; active: boolean }>`
  ${({ sidebarWidth, theme: { colors } }) =>
    css`
      position: fixed;
      width: calc(100% - ${sidebarWidth}px);
      left: ${sidebarWidth}px;
      padding: 16px 0;
      z-index: 1;
      bottom: 0;
      background-color: ${colors.MAIN_450};
      visibility: ${sidebarWidth ? "visible" : "hidden"};
    `}

  ${(props) =>
    props.theme.animations === Animations.Pretty &&
    css`
      transform: translateY(80px);
      opacity: 0;
      transition: transform 250ms ease, opacity 100ms ease 50ms;

      ${props.active &&
      css`
        transform: translateX(0px);
        opacity: 1;
      `}
    `}
`;

interface IAppearingWrapperProps extends PropsWithChildren {
  sidebarWidth: number;
  onChangeHeight(height: number): void;
}

const AppearingWrapper = ({
  children,
  sidebarWidth,
  onChangeHeight,
}: IAppearingWrapperProps) => {
  const [active, setActive] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      onChangeHeight(wrapperRef.current.clientHeight);
      setTimeout(() => {
        setActive(true);
      }, 50);
    }
  }, [wrapperRef.current]);

  return (
    <Wrapper ref={wrapperRef} sidebarWidth={sidebarWidth} active={active}>
      {children}
    </Wrapper>
  );
};

export const useBottomFixedWrapper = (
  onChangeHeight: (height: number) => void,
  node: React.ReactNode,
  deps: React.DependencyList
) => {
  const { addNode, removeNode } = usePortal();
  const { sidebarWidth } = useSidebar();
  const colors = useColors();

  useEffect(() => {
    addNode(
      <AppearingWrapper
        onChangeHeight={onChangeHeight}
        sidebarWidth={sidebarWidth}
      >
        {node}
      </AppearingWrapper>
    );

    return () => {
      removeNode();
    };
  }, [...deps, colors, sidebarWidth]);
};
