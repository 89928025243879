import { isLanguageStringEmpty } from "../../../common/functions";
import { TGetError } from "../../../common/redux-hooks";
import {
  ISlugErrorsObject,
  getSlugErrors,
} from "../../../common/slug-validator";
import { ErrorType, TReduxError } from "../../../types/error";
import { IEditorCategory } from "./category-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.CATEGORY);

  if (err && condition) {
    return err;
  }

  return undefined;
};

interface ICategoryEditorErrorsObject extends ISlugErrorsObject {
  name?: TReduxError;
}

type TCategoryEditorErrors = {
  errors: ICategoryEditorErrorsObject;
  isValid: boolean;
};

export const getCategoryEditorErrors = (
  getError: TGetError,
  category: IEditorCategory,
  locale: string
): TCategoryEditorErrors => {
  const name = getErrorOfKey(
    "name",
    getError,
    isLanguageStringEmpty(category.name)
  );

  const { errors: slugErrors, isValid: slugIsValid } = getSlugErrors(
    category.slug,
    getError,
    locale
  );

  return {
    errors: {
      name,
      ...slugErrors,
    },
    isValid: !name && slugIsValid,
  };
};
