import React, { useEffect, useState } from "react";
import { AddButtonActionStyles, EditButton, HeaderText } from "../../ui/common";
import ViewWrapper from "../view-wrapper";
import Table, { TRow } from "../../ui/table";
import { useLanguages, useRole } from "../../common/redux-hooks";
import styled from "styled-components";
import { ICouponID } from "../../types/coupon";
import { couponsModel } from "../../model/query/couponModel";
import Button from "../../ui/button";
import { isDisabledByRole } from "../../types/role";
import { useNavigate } from "react-router-dom";

interface IProps {}

const TableWrapper = styled.div`
  width: 75%;
`;

export const Coupons = ({}: IProps): React.ReactElement<IProps> => {
  const languages = useLanguages();
  const [isLoading, setIsLoading] = useState(true);
  const [coupons, setCoupons] = useState<ICouponID[]>([]);

  useEffect(() => {
    const getCoupons = async () => {
      setIsLoading(true);
      const _coupons = await couponsModel.getCoupons();
      setIsLoading(false);
      setCoupons(_coupons);
    };

    getCoupons();
  }, []);

  const rows: TRow[] = coupons.map((l) => ({
    cells: [
      {
        key: "actions",
        value: "actions",
        content: (
          <>
            <EditButton
              data-title="Upravit slevový kupón"
              path={`/coupons/edit/${l._id}`}
            />
          </>
        ),
      },
      {
        key: "title",
        value: l.key,
        content: l.key,
      },
      {
        key: "stores",
        value: l.stores.map((s) => s.title).join(", "),
        content: l.stores.map((s) => s.title).join(", ")
      }
    ],
  }));

  const navigate = useNavigate();
  const role = useRole();

  return (
    <ViewWrapper>
      <HeaderText
        style={{
          marginBottom: 20,
        }}
      >
        Slevové kupóny
      </HeaderText>
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <Button
          disabled={isDisabledByRole(role, {
            type: "coupons",
            permission: "add",
          })}
          style={AddButtonActionStyles}
          onClick={() => navigate("/coupons/add")}
        >
          Přidat slevový kupón
        </Button>
      </div>
      <TableWrapper>
        <Table
          loading={isLoading}
          headerKeys={[
            { content: "", width: "50px" },
            { content: "Kód kupónu" },
            { content: "Obchody" },
          ]}
          rows={rows}
        />
      </TableWrapper>
    </ViewWrapper>
  );
};

export default Coupons;
