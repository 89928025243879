import React, { useState } from "react";
import {
  IOrder,
  ShippingType,
  TPPLShippingDB,
  TZasilkovnaShippingDB,
} from "../../types/order";
import { SectionProperty, SectionPropertyTitle } from "./view-order-modal";
import { Link } from "react-router-dom";
import { BiDownload, BiLink } from "react-icons/bi";
import { FlexCenterAlign } from "../../ui/common";
import styled from "styled-components";
import { useColors } from "../../providers/theme/theme-provider";
import Button from "../../ui/button";
import { orderModel } from "../../model/query/orderModel";
import { useSimplePopups } from "../../providers/simple-popup/simple-popup-provider";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";

const LinkTracking = styled.a`
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

interface IProps {
  order: IOrder;
}

export const ShippingInfo = ({ order }: IProps): React.ReactElement<IProps> => {
  const [shipmentNumber, setShipmentNumber] = useState<string>(
    order.shipping_details?.type === ShippingType.PPL
      ? order.shipping_details.shipmentNumber || ""
      : ""
  );
  const colors = useColors();
  const { call } = useQueryWithPopupErrorHandling();

  const downloadZasilkovnaPng = async () => {
    if (order.shipping_details?.type !== ShippingType.Zasilkovna) {
      return;
    }

    call(
      () =>
        orderModel.getZasilkovnaBarcodePng(
          (order.shipping_details as TZasilkovnaShippingDB).trackingId
        ),
      "Při vytváření čárového kódu se stala chyba.",
      null,
      (base64Data) => {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/octet-stream",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `zasilkovna_${order.custom_id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    );
  };

  const downloadPPLPng = async () => {
    if (order.shipping_details?.type !== ShippingType.PPL) {
      return;
    }

    call(
      () => orderModel.getPPLBarcodePng(order._id),
      "Při vytváření čárového kódu se stala chyba.",
      null,
      ({ label, shipmentNumber }) => {
        setShipmentNumber(shipmentNumber);
        
        if(order.shipping_details?.type === ShippingType.PPL) {
          order.shipping_details.shipmentNumber = shipmentNumber;
        }

        const byteCharacters = atob(label);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/octet-stream",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `ppl_${order.custom_id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    );
  };

  if (order.shipping_details?.type === ShippingType.Zasilkovna) {
    return (
      <>
        <SectionProperty>
          <SectionPropertyTitle>ID zásilky:</SectionPropertyTitle>
          <div>{order.shipping_details.trackingId}</div>
        </SectionProperty>
        <SectionProperty>
          <SectionPropertyTitle>Trasovací číslo:</SectionPropertyTitle>
          <div data-title="Přejít na zásilku">
            <LinkTracking
              href={order.shipping_details.trackingURL}
              target="_blank"
            >
              <BiLink
                size={18}
                color={colors.OPPOSITE_MAIN_450}
                style={{
                  marginRight: 4,
                }}
              />
              {order.shipping_details.trackingNumberFormatted}
            </LinkTracking>
          </div>
        </SectionProperty>
        <SectionProperty>
          <SectionPropertyTitle>Výdejní místo:</SectionPropertyTitle>
          <div>
            {order.shipping_details.point.place} <br />{" "}
            {order.shipping_details.point.name}
          </div>
        </SectionProperty>
        <SectionProperty>
          <Button
            onClick={downloadZasilkovnaPng}
            style={{
              paddingLeft: 8,
              fontWeight: 500,
              marginTop: 6,
              backgroundColor: colors.MAIN_350,
            }}
            hoverBackgroundColor={colors.MAIN_300}
          >
            <FlexCenterAlign>
              <BiDownload
                size={22}
                style={{
                  marginRight: 8,
                }}
              />
              Stáhnout čárový kód pro tisk
            </FlexCenterAlign>
          </Button>
        </SectionProperty>
      </>
    );
  }

  if (order.shipping_details?.type === ShippingType.PPL) {
    return (
      <>
        <SectionProperty>
          <SectionPropertyTitle>PPL ID zásilky:</SectionPropertyTitle>
          <div>{order.shipping_details.batchId}</div>
        </SectionProperty>
        {shipmentNumber && (
          <>
            <SectionProperty>
              <SectionPropertyTitle>Trasovací číslo:</SectionPropertyTitle>
              <div data-title="Přejít na zásilku">
                <LinkTracking
                  href={`https://www.ppl.cz/vyhledat-zasilku?shipmentId=${shipmentNumber}`}
                  target="_blank"
                >
                  <BiLink
                    size={18}
                    color={colors.OPPOSITE_MAIN_450}
                    style={{
                      marginRight: 4,
                    }}
                  />
                  {shipmentNumber}
                </LinkTracking>
              </div>
            </SectionProperty>
          </>
        )}
        <SectionProperty>
          <Button
            onClick={downloadPPLPng}
            style={{
              paddingLeft: 8,
              fontWeight: 500,
              marginTop: 6,
              backgroundColor: colors.MAIN_350,
            }}
            hoverBackgroundColor={colors.MAIN_300}
          >
            <FlexCenterAlign>
              <BiDownload
                size={22}
                style={{
                  marginRight: 8,
                }}
              />
              Stáhnout čárový kód pro tisk
            </FlexCenterAlign>
          </Button>
        </SectionProperty>
      </>
    );
  }

  return <></>;
};

export default ShippingInfo;
