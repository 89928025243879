import { IEditorParameterValue } from "./parameter-value-editor";
import { isLanguageStringEmpty } from "../../../common/functions";
import { TGetError } from "../../../common/redux-hooks";
import { TLanguageString } from "../../../types/common";
import { ErrorType, TReduxError } from "../../../types/error";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.PARAMETER_VALUE);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TParameterValueEditorErrorsObject = {
  value?: TReduxError;
};

type TParameterValueEditorErrors = {
  errors: TParameterValueEditorErrorsObject;
  isValid: boolean;
};

export const getParameterValueEditorErrors = (
  getError: TGetError,
  parameter: IEditorParameterValue
): TParameterValueEditorErrors => {
  const value = getErrorOfKey(
    "value",
    getError,
    isLanguageStringEmpty(parameter.value)
  );

  return {
    errors: {
      value,
    },
    isValid: !value,
  };
};
