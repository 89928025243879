import React from "react";
import { ITask, ITaskDB } from "./../../../types/tasks";

interface ITasksContext {
    addTask(task: ITask): void;
    removeTask(id: string): void;
    changeTask(task: ITaskDB): void;
}

export const TasksContext = React.createContext<ITasksContext>({} as ITasksContext)

export const useTasksContext = () => {
    return React.useContext(TasksContext)
}