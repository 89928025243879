import { TAction } from ".";
import { IProduct } from "../../types/products";
import { ProductsActions, TProductsAction } from "../actions/productsActions";

export interface IProductsState {
  products: IProduct[];
  loading: boolean;
}

const initialProductsState: IProductsState = {
  products: [],
  loading: true,
};

export const productsReducer = (
  state: IProductsState = initialProductsState,
  action: TProductsAction
): IProductsState => {
  switch (action.type) {
    case ProductsActions.SET_LOADING:
      return { ...state, loading: action.payload };
    case ProductsActions.SET_PRODUCTS:
      return { ...state, products: action.payload };
    case ProductsActions.ADD_PRODUCT:
      return { ...state, products: [...state.products, action.payload] };
    case ProductsActions.EDIT_PRODUCT:
      let products = [...state.products],
        index = products.findIndex((i) => i._id === action.payload._id);

      products[index] = action.payload;

      return { ...state, products };
    case ProductsActions.REMOVE_PRODUCT_BY_ID:
      return {
        ...state,
        products: state.products.filter((p) => p._id !== action.payload),
      };
    default:
      return state;
  }
};
