import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useNavigateCatch } from "../../../common/navigate";
import { useCurrencies, useErrors } from "../../../common/redux-hooks";
import { adminRegistrationModel } from "../../../model/query/adminRegistrationModel";
import { languageModel } from "../../../model/query/languageModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_DEFAULT_LANGUAGE,
  ADD_LANGUAGE,
  EDIT_DEFAULT_LANGUAGE,
  EDIT_LANGUAGE,
  REMOVE_LANGUAGE_BY_ID,
} from "../../../redux/actions/languagesActions";
import { useDispatch, useSelector } from "../../../redux/store";
import { EditorType } from "../../../types/common";
import { ICurrencyID } from "../../../types/currency";
import { ErrorType } from "../../../types/error";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
} from "../../../ui/common";
import Dropdown from "../../../ui/dropdown";
import { InputWithTitle } from "../../../ui/input-with-title";
import BackButton from "../../components/back-button";
import { ILanguageID } from "./../../../types/language";
import { getLanguageEditorErrors } from "./editor-errors";

const mapLanguageToEditorLanguage = (
  language: ILanguageID
): IEditorLanguage => {
  const {
    currency,
    country_id,
    country_name,
    isDefaultLanguage,
    iso_key,
    locale,
    title,
  } = language;

  return {
    currency,
    country_id,
    country_name,
    isDefaultLanguage,
    iso_key,
    locale,
    title,
  };
};

export interface IEditorLanguage {
  locale: string;
  iso_key: string;
  country_name: string;
  country_id: string;
  title: string;
  currency: ICurrencyID | null;
  isDefaultLanguage: boolean;
}

const initialLanguageState: IEditorLanguage = {
  locale: "",
  iso_key: "",
  country_name: "",
  country_id: "",
  title: "",
  currency: null,
  isDefaultLanguage: false,
};

interface IProps {
  type: EditorType;
  isForDefaultLanguage?: boolean;
  registrationKey?: string;
  onSuccess?(language: ILanguageID): void;
  outerId?: string;
}

export const LanguageEditor = ({
  type,
  isForDefaultLanguage,
  registrationKey,
  onSuccess,
  outerId,
}: IProps): React.ReactElement<IProps> => {
  const [originalLanguage, setOriginalLanguage] = useState<ILanguageID | null>(
    null
  );
  const [language, setLanguage] =
    useState<IEditorLanguage>(initialLanguageState);

  const languages = useSelector(({ languages }) => languages.languages);
  const { createConfirmation } = useConfirmation();
  const navigate = useNavigateCatch();
  const { _id } = useParams();
  const dispatch = useDispatch();
  const colors = useColors();
  const { call } = useQueryWithPopupErrorHandling();
  const currencies = useCurrencies();

  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getLanguageEditorErrors(getError, language);

  const appendLanguageToForm = useCallback(
    (form: FormData) => {
      form.append("locale", language.locale);
      form.append("iso_key", language.iso_key);
      form.append("title", language.title);
      form.append("country_name", language.country_name);
      form.append("country_id", language.country_id);

      if (language.currency?._id) {
        form.append("currency", language.currency?._id);
      }

      form.append(
        "isDefaultLanguage",
        JSON.stringify(isForDefaultLanguage ? true : language.isDefaultLanguage)
      );

      if (registrationKey) {
        form.append("registration_key", registrationKey);
      }
    },
    [language, registrationKey, isForDefaultLanguage]
  );

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.LANGUAGE);
    };
  }, []);

  const addLanguageFromData = async () => {
    const form = new FormData();

    appendLanguageToForm(form);

    call(
      () =>
        isForDefaultLanguage ? ADD_DEFAULT_LANGUAGE(form) : ADD_LANGUAGE(form),
      "Přidání jazyku nebylo úspěšné",
      "Přidání jazyku bylo úspěšné",
      (action) => {
        dispatch(action);
        onSuccess?.(action.payload);
        navigate("/languages");
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  const editLanguageFromData = async (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit jazyk", async () => {
      const form = new FormData();

      if (!originalLanguage) return;

      form.append("_id", originalLanguage._id);

      appendLanguageToForm(form);

      call(
        () =>
          isForDefaultLanguage
            ? EDIT_DEFAULT_LANGUAGE(form)
            : EDIT_LANGUAGE(form),
        "Úprava jazyku nebyla úspěšná",
        "Úprava jazyku byla úspěšná",
        (action) => {
          dispatch(action);
          onSuccess?.(action.payload);

          if (exit) {
            navigate("/languages");
          } else {
            setLanguage(mapLanguageToEditorLanguage(action.payload));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeLanguage = async () => {
    if (languages.length === 1 || language.isDefaultLanguage) {
      return;
    }

    createConfirmation("Potvrzení: Odstranit jazyk", async () => {
      if (!originalLanguage?._id) {
        return;
      }

      call(
        () => REMOVE_LANGUAGE_BY_ID(originalLanguage._id),
        "Odstranění jazyku nebylo úspěšné",
        "Odstranění jazyku bylo úspěšné",
        (action) => {
          dispatch(action);
          navigate("/languages");
        }
      );
    });
  };

  const getLanguageByIdCall = async (id: string) => {
    call(
      () =>
        isForDefaultLanguage && registrationKey
          ? adminRegistrationModel.getDefaultLanguage(registrationKey)
          : languageModel.getLanguageById(id),
      "Zobrazení jazyku nebylo úspěšné",
      null,
      (language) => {
        const l = mapLanguageToEditorLanguage(language);

        setOriginalLanguage(language);
        setLanguage(l);
      },
      () => {
        navigate("/languages");
      }
    );
  };

  useEffect(() => {
    const id = _id || outerId;

    if (type === EditorType.Edit && id) {
      getLanguageByIdCall(id);
    }
  }, [_id, type, outerId]);

  const node =
    type === EditorType.Add ? (
      <FlexCenterAll>
        <Button
          disabled={!isValid}
          style={{
            ...AddButtonStyles,
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
          onClick={addLanguageFromData}
        >
          Přidat jazyk
        </Button>
      </FlexCenterAll>
    ) : (
      <FlexCenterAll>
        <div
          style={{
            marginRight: 16,
          }}
        >
          <AddButton disabled={!isValid} onClick={() => editLanguageFromData()}>
            Uložit
          </AddButton>
        </div>
        <div
          style={{
            marginRight: 16,
          }}
        >
          <AddButton
            disabled={!isValid}
            onClick={() => editLanguageFromData(true)}
          >
            Uložit a zavřít
          </AddButton>
        </div>
        <div>
          <RemoveButton
            data-title={
              languages.length === 1
                ? "Jazyk nelze odstranit jelikož je jediný vytvořený"
                : language.isDefaultLanguage
                ? "jazyk nelze odstranit jelikož je zvolen jako výchozí"
                : undefined
            }
            disabled={languages.length === 1 || language.isDefaultLanguage}
            onClick={removeLanguage}
          >
            Odstranit
          </RemoveButton>
        </div>
      </FlexCenterAll>
    );

  return (
    <BottomFixedWrapperWrapper
      node={isForDefaultLanguage ? <></> : node}
      deps={[type, appendLanguageToForm, isValid]}
    >
      {!isForDefaultLanguage && <BackButton />}
      <div
        style={{
          marginTop: 40,
          fontSize: 36,
          marginLeft: 8,
          opacity: 0.444,
        }}
      >
        {type === EditorType.Add
          ? isForDefaultLanguage
            ? "Přidat výchozí jazyk"
            : "Přidat nový jazyk"
          : isForDefaultLanguage
          ? "Upravit výchozí jazyk"
          : "Upravit jazyk"}
      </div>
      <div
        style={{
          marginTop: 20,
          display: isForDefaultLanguage ? "flex" : "block",
          justifyContent: isForDefaultLanguage ? "center" : undefined,
        }}
      >
        <Flex>
          <div>
            <InputsWrapper>
              <InputWithTitle
                inputProps={{
                  error: errors.locale || errors.locale_already_exists,
                }}
                wrapperStyle={{
                  marginTop: 0,
                }}
                value={language.locale}
                setValue={(locale) => setLanguage((l) => ({ ...l, locale }))}
                title="Locale parametr (cs_CZ, en_US)"
              />
              <InputWithTitle
                inputProps={{
                  error: errors.iso_key,
                }}
                value={language.iso_key}
                setValue={(iso_key) => setLanguage((l) => ({ ...l, iso_key }))}
                title="ISO klíč jazyka (cs, en)"
              />
              <InputWithTitle
                inputProps={{
                  error: errors.title,
                }}
                value={language.title}
                setValue={(title) => setLanguage((l) => ({ ...l, title }))}
                title="Název jazyka (Čeština)"
              />
              <InputWithTitle
                inputProps={{
                  error: errors.country_name,
                }}
                value={language.country_name}
                setValue={(country_name) =>
                  setLanguage((l) => ({ ...l, country_name }))
                }
                title="Název země (Česko)"
              />
              <InputWithTitle
                inputProps={{
                  error: errors.country_id,
                }}
                value={language.country_id}
                setValue={(country_id) =>
                  setLanguage((l) => ({ ...l, country_id }))
                }
                title="ID země (CZ)"
              />

              <div>
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 4,
                    marginLeft: 4,
                    fontSize: 13,
                  }}
                >
                  Měna
                </div>
                <Dropdown
                  error={errors.currency_empty}
                  list={currencies.map((c) => ({
                    content: c.title + " - " + c.symbol,
                    value: c,
                    query: c.title + " " + c.symbol,
                    unique_id: c._id,
                  }))}
                  onSelectValue={(currency) =>
                    setLanguage((l) => ({ ...l, currency }))
                  }
                  selectedUniqueId={language.currency?._id}
                  isDeleteButtonDisabled
                />
              </div>

              <FlexCenterAlign
                style={{
                  marginTop: 8,
                }}
              >
                <Checkbox
                  disabled={isForDefaultLanguage}
                  value={
                    isForDefaultLanguage ? true : language.isDefaultLanguage
                  }
                  setValue={(isDefaultLanguage) =>
                    setLanguage((l) => ({ ...l, isDefaultLanguage }))
                  }
                />
                <div
                  style={{
                    marginLeft: 8,
                    fontWeight: 400,
                    fontSize: 13,
                  }}
                >
                  Je výchozí jazyk
                </div>
              </FlexCenterAlign>
            </InputsWrapper>
          </div>
        </Flex>
      </div>
      {isForDefaultLanguage ? (
        type === EditorType.Add ? (
          <FlexCenterAll style={{ marginTop: 16 }}>
            <Button
              disabled={!isValid}
              style={{
                ...AddButtonStyles,
                backgroundColor: colors.MAIN_300,
              }}
              hoverBackgroundColor={colors.MAIN_250}
              onClick={addLanguageFromData}
            >
              Přidat jazyk
            </Button>
          </FlexCenterAll>
        ) : (
          <FlexCenterAll style={{ marginTop: 16 }}>
            <AddButton
              disabled={!isValid}
              onClick={() => editLanguageFromData()}
            >
              Uložit
            </AddButton>
          </FlexCenterAll>
        )
      ) : null}
    </BottomFixedWrapperWrapper>
  );
};

export default LanguageEditor;
