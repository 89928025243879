import React from "react";
import { Flex } from "../../ui/common";
import ViewWrapper from "../view-wrapper";
import ParameterGroupsList from "./parameter-groups-list";

interface IProps {}

export const ParameterGroups = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <ViewWrapper
      style={{
        marginBottom: 40,
      }}
    >
      <Flex>
        <ParameterGroupsList />
      </Flex>
    </ViewWrapper>
  );
};

export default ParameterGroups;
