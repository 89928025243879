import { userModel } from "./../../model/query/userModel";
import { usersModel } from "../../model/query/usersModel";
import { IUserDB } from "../../types/user";
import { ErrorsActions } from "./errorsActions";
import { adminRegistrationModel } from "../../model/query/adminRegistrationModel";

export enum UsersActions {
  SET_USERS = "SET_USERS",
  EDIT_USER = "EDIT_USER",
  ADD_USER = "ADD_USER",
  REMOVE_USER = "REMOVE_USER",
}

type TRemoveUserAction = {
  type: UsersActions.REMOVE_USER;
  payload: string;
};

type TSetUsersAction = {
  type: UsersActions.SET_USERS;
  payload: IUserDB[];
};

type TEditUserAction = {
  type: UsersActions.EDIT_USER;
  payload: IUserDB;
};

type TAddUserAction = {
  type: UsersActions.ADD_USER;
  payload: IUserDB;
};

export type TUsersAction =
  | TSetUsersAction
  | TEditUserAction
  | TAddUserAction
  | TRemoveUserAction;

export const FETCH_AND_SET_USERS = async () => {
  const users = await usersModel.getUsers();

  return {
    type: UsersActions.SET_USERS,
    payload: users,
  };
};

export const ADMIN_ADD_USER = async (data: FormData) => {
  try {
    const newUser = await userModel.adminAddUser(data);

    return {
      type: UsersActions.ADD_USER,
      payload: newUser,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const ADD_DEFAULT_ADMIN = async (data: FormData) => {
  try {
    const newUser = await adminRegistrationModel.addDefaultAdmin(data);

    return {
      type: UsersActions.ADD_USER,
      payload: newUser,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const ADMIN_EDIT_USER_INFO = async (data: FormData) => {
  try {
    const editedUser = await userModel.adminEditUserInfo(data);

    return {
      type: UsersActions.EDIT_USER,
      payload: editedUser,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const ADMIN_EDIT_USER_PERMISSIONS = async (data: FormData) => {
  try {
    const editedUser = await userModel.adminEditUserPermissions(data);

    return {
      type: UsersActions.EDIT_USER,
      payload: editedUser,
    };
  } catch (err) {
    throw err;
  }
};

export const ADMIN_REMOVE_USER = async (_id: string) => {
  try {
    await userModel.adminRemoveUser(_id);

    return {
      type: UsersActions.REMOVE_USER,
      payload: _id,
    };
  } catch (err) {
    throw err;
  }
};

export const ADMIN_CHANGE_PASSWORD = async (data: FormData) => {
  try {
    return await userModel.adminChangePassword(data);
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};
