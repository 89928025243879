import { adminRegistrationModel } from "../../model/query/adminRegistrationModel";
import { storeModel } from "../../model/query/storeModel";
import { IStoreID } from "../../types/store";
import { ErrorsActions } from "./errorsActions";

export enum StoreActions {
  SET_STORES = "SET_STORES",
  ADD_STORE = "ADD_STORE",
  EDIT_STORE = "EDIT_STORE",
  REMOVE_STORE_BY_ID = "REMOVE_STORE_BY_ID",
}

type TSetStoresAction = {
  type: StoreActions.SET_STORES;
  payload: IStoreID[];
};

type TAddStoreAction = {
  type: StoreActions.ADD_STORE;
  payload: IStoreID;
};

type TEditStoreAction = {
  type: StoreActions.EDIT_STORE;
  payload: IStoreID;
};

type TRemoveStoreByIdAction = {
  type: StoreActions.REMOVE_STORE_BY_ID;
  payload: string;
};

export type TStoreAction =
  | TSetStoresAction
  | TAddStoreAction
  | TEditStoreAction
  | TRemoveStoreByIdAction;

export const FETCH_AND_SET_STORES = async () => {
  try {
    const stores = await storeModel.getStores();

    return {
      type: StoreActions.SET_STORES,
      payload: stores,
    };
  } catch (err) {
    throw err;
  }
};


export const SET_STORES = (stores: IStoreID[]) => {
  return {
    type: StoreActions.SET_STORES,
    payload: stores,
  };
};

export const ADD_STORE = async (data: FormData) => {
  try {
    const Store = await storeModel.addStore(data);

    return {
      type: StoreActions.ADD_STORE,
      payload: Store,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const ADD_DEFAULT_STORE = async (data: FormData) => {
  try {
    const Store = await adminRegistrationModel.addDefaultStore(data);

    return {
      type: StoreActions.ADD_STORE,
      payload: Store,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const EDIT_STORE = async (data: FormData) => {
  try {
    const Store = await storeModel.editStore(data);

    return {
      type: StoreActions.EDIT_STORE,
      payload: Store,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};


export const EDIT_DEFAULT_STORE = async (data: FormData) => {
  try {
    const Store = await adminRegistrationModel.editDefaultStore(data);

    return {
      type: StoreActions.EDIT_STORE,
      payload: Store,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const EDIT_TERMS_AND_CONDITIONS = async (terms: string, _id: string) => {
  try {
    const Store = await storeModel.editTermsAndConditions(terms, _id);

    return {
      type: StoreActions.EDIT_STORE,
      payload: Store,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const EDIT_COMPLAINTS_INFO = async (
  complaintsInfo: string,
  _id: string
) => {
  try {
    const Store = await storeModel.editComplaintsInfo(complaintsInfo, _id);

    return {
      type: StoreActions.EDIT_STORE,
      payload: Store,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const EDIT_PRIVACY_POLICY = async (
  privacyPolicy: string,
  _id: string
) => {
  try {
    const Store = await storeModel.editPrivacyPolicy(privacyPolicy, _id);

    return {
      type: StoreActions.EDIT_STORE,
      payload: Store,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const REMOVE_STORE_BY_ID = async (id: string) => {
  try {
    await storeModel.removeStoreById(id);

    return {
      type: StoreActions.REMOVE_STORE_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: [],
    };
  }
};
