import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/button";
import { AddButtonActionStyles, FlexCenterAlign } from "../../ui/common";
import ViewWrapper from "../view-wrapper";
import ManufacturersList from "./manufacturers-list";

export const ManufacturerActionButtons =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();

    return (
      <FlexCenterAlign>
        <Button
          style={AddButtonActionStyles}
          onClick={() => navigate("/manufacturers/add-manufacturer")}
        >
          Přidat výrobce
        </Button>
      </FlexCenterAlign>
    );
  };

interface IProps {}

export const Manufacturers = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <ViewWrapper>
      <ManufacturerActionButtons />

      <ManufacturersList />
    </ViewWrapper>
  );
};

export default Manufacturers;
