import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { emailsModel } from "../../../model/query/emailModel";
import { IEmailID } from "../../../types/email";
import { Flex, HeaderText } from "../../../ui/common";
import Table, { ITEMS_PER_PAGE, TRow } from "../../../ui/table";
import ViewWrapper from "../../view-wrapper";
import Dropdown from "../../../ui/dropdown";
import { AdKey, IAdID } from "../../../types/ad";
import { InputTitle } from "../../../ui/input-with-title";
import { adModel } from "../../../model/query/adModel";
import { getLocaleDateStringWithHoursAndMinutes, getLocaleDateStringWithHoursAndMinutesAndSeconds } from "../../../common/date";
import LazyTable from "../../../ui/lazy-table";

const getTitleByAdKey = (adKey: AdKey) => {
  switch (adKey) {
    case AdKey.Meta:
      return "Meta Ads";
  }
};

interface IProps {}

const TableWrapper = styled.div`
  width: 75%;
`;

export const AdsList = ({}: IProps): React.ReactElement<IProps> => {
  const [isLoading, setIsLoading] = useState(true);
  const [adEntries, setAdEntries] = useState<IAdID[]>([]);
  const [selectedAdKey, setSelectedAdKey] = useState<AdKey>(AdKey.Meta);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const fetchAds = async () => {
    try {
      setIsLoading(true);
      const { adEntries, totalCount } = await adModel.getAdEntries(
        selectedAdKey,
        page * ITEMS_PER_PAGE
      );
      setIsLoading(false);

      setTotalCount(totalCount);
      setAdEntries(adEntries);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAds();
  }, [page]);
  const rows: TRow[] = adEntries.map((e) => ({
    cells: [
      {
        key: "empty",
        value: "empty",
        content: "",
      },
      {
        key: "date",
        value: e.date,
        content: (
          <span>
            {getLocaleDateStringWithHoursAndMinutesAndSeconds(new Date(e.date))}
          </span>
        ),
      },
      {
        key: "key",
        value: e.key,
        content: getTitleByAdKey(e.key),
      },
    ],
  }));

  const onSetPage = (page: number) => {
    setPage(page);
  };

  return (
    <ViewWrapper>
      <HeaderText
        style={{
          marginBottom: 20,
        }}
      >
        Kliknutí na reklamu
      </HeaderText>
      <Flex style={{ marginBottom: 20 }}>
        <div>
          <InputTitle>Vyberte typ reklamy</InputTitle>
          <Dropdown
            list={[
              {
                content: getTitleByAdKey(AdKey.Meta),
                value: AdKey.Meta,
                unique_id: AdKey.Meta,
              },
            ]}
            onSelectValue={(value: AdKey) => setSelectedAdKey(value)}
            selectedUniqueId={selectedAdKey}
            isDeleteButtonDisabled
          />
        </div>
      </Flex>
      <TableWrapper>
        <LazyTable
          onSetPage={onSetPage}
          totalCount={totalCount}
          isLoading={isLoading}
          headerKeys={[
            { content: "", width: "0px" },
            { content: "Čas kliknutí na reklamu" },
            { content: "Typ reklamy" },
          ]}
          rows={rows}
        />
      </TableWrapper>
    </ViewWrapper>
  );
};

export default AdsList;
