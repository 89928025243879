import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillShopping } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { MdOutlineRefresh } from "react-icons/md";
import { getOrderFullPriceVat } from "../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import {
  useAppConfig,
  usePayments,
  useShippings,
} from "../../common/redux-hooks";
import {
  getOrderStateTitle,
  getPaymentTypeTitle,
  getShippingTypeTitle,
} from "../../locales/determineString";
import { orderModel } from "../../model/query/orderModel";
import { useConfirmation } from "../../providers/confirmation/confirmation-provider";
import { useModal } from "../../providers/modal/modal-provider";
import { useColors } from "../../providers/theme/theme-provider";
import { IOrder, OrderState } from "../../types/order";
import {
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  HeaderText,
  InputsWrapper,
} from "../../ui/common";
import Dropdown from "../../ui/dropdown";
import IconButton from "../../ui/icon-button";
import Input from "../../ui/input";
import LazyTable, { TSelectRowId } from "../../ui/lazy-table";
import {
  ITEMS_PER_PAGE,
  SortByType,
  THeaderKey,
  TRow,
  TSortBy,
} from "../../ui/table";
import OrderStateChangeModal from "./order-state-change/order-state-change-modal";
import { OrdersListContext, useOrdersList } from "./orders-list-context";
import ViewOrder from "./view-order-modal";
import SelectedOrders from "./selected-orders";
import CheckGoPayPaymentStateCell from "./check-gopay-payment-state-cell";
import { PaymentType } from "../../types/payment";
import Checkbox from "../../ui/checkbox";
import { BsTruck } from "react-icons/bs";
import { ShippingType } from "../../types/shipping";

const mapOrdersToTable = (orders: IOrder[]): TRow[] => {
  return orders.map((o) => ({
    id: o._id,
    value: o,
    selectable: true,
    rememberClick: true,
    cells: [
      {
        content: <RowActionButtons order={o} />,
        key: "actions",
        value: "actions",
      },
      {
        key: "custom_id",
        content: o.custom_id,
        value: o.custom_id,
      },
      {
        key: "state",
        content: (
          <span data-title={getOrderStateTitle(o.state)}>
            {getOrderStateTitle(o.state)}
          </span>
        ),
        value: o.state,
      },
      {
        key: "payment_state",
        content:
          o.payment.type !== PaymentType.Cash ? (
            <CheckGoPayPaymentStateCell order={o} />
          ) : (
            <FlexCenterAll
              style={{
                fontSize: 11,
              }}
            >
              Placeno hotově
            </FlexCenterAll>
          ),
        value: o.payment,
      },
      {
        key: "date",
        content: new Date(o.date).toLocaleString(),
        value: o.date,
      },
      {
        key: "shipping",
        content: getShippingTypeTitle(o.shipping.type),
        value: o.shipping,
      },
      {
        key: "items_count",
        value: o.itemCount,
        content: o.itemCount,
      },
      {
        key: "items_sum",
        value: getOrderFullPriceVat(o) + " " + o.language.currency.symbol,
        content: getOrderFullPriceVat(o) + " " + o.language.currency.symbol,
      },
    ],
  }));
};

const ORDER_TABLE_HEADER: THeaderKey[] = [
  {
    content: "",
    width: "100px",
  },
  {
    content: "Číslo objednávky",
    query_key: "custom_id",
    width: "120px",
  },
  {
    content: "Stav objednávky",
    query_key: "state",
    sort_by_key: "state",
    width: "150px",
  },
  {
    content: "Stav platby",
    query_key: "payment_state",
    width: "15%",
  },
  { content: "Vznik", query_key: "date", sort_by_key: "date", width: "15%" },
  {
    content: "Způsob dopravy",
    query_key: "shipping",
    sort_by_key: "shipping",
  },
  {
    content: "Počet položek",
    sort_by_key: "itemCount",
    width: "110px",
  },
  {
    content: "Cena celkem vč. DPH",
    sort_by_key: "totalPrice",
    width: "110px",
  },
];

interface IRowActionButtons {
  order: IOrder;
}

const RowActionButtons = ({
  order,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  const colors = useColors();
  const { createModal } = useModal();
  const { createConfirmation } = useConfirmation();
  const { call } = useQueryWithPopupErrorHandling();
  const { fetch } = useOrdersList();

  const onEditStateClick = () => {
    createModal(<OrderStateChangeModal fetch={fetch} order={order} />);
  };

  const onViewOrderClick = () => {
    createModal(<ViewOrder fetchOrder={fetch} order={order} />);
  };

  const removeOrder = () => {
    createConfirmation("Opravdu chcete odstranit objednávku?", () => {
      call(
        () => orderModel.removeOrderById(order._id),
        "Odstranění objednávky nebylo úspěšné",
        "Objednávka byla úspěšně odstraněna",
        () => {}
      );
    });
  };

  const onOrderTrackClick = () => {
    switch (order.shipping_details?.type) {
      case ShippingType.PPL:
        if (order.shipping_details.shipmentNumber) {
          window.open(
            `https://www.ppl.cz/vyhledat-zasilku?shipmentId=${order.shipping_details.shipmentNumber}`
          );
        }
        break;
      case ShippingType.Zasilkovna:
        window.open(order.shipping_details.trackingURL);
        break;
      default:
        break;
    }
  };

  return (
    <FlexCenterAlign>
      <IconButton
        onClick={onViewOrderClick}
        data-title="Zobrazit objednávku"
        hoverBackgroundColor={colors.MAIN_250}
        style={{
          padding: 4,
          backgroundColor: colors.MAIN_300,
        }}
      >
        <FiEye size={16} color={colors.OPPOSITE_MAIN_450} />
      </IconButton>
      <IconButton
        onClick={onEditStateClick}
        data-title="Změnit stav objednávky"
        style={{
          padding: 4,
          marginLeft: 4,
        }}
      >
        <AiFillShopping size={16} color={colors.MAIN_300} />
      </IconButton>
      <div
        data-title={
          order.shipping_details?.type === ShippingType.PPL &&
          !order.shipping_details.shipmentNumber
            ? "Pro sledovací číslo vytiskněte etiketu"
            : "Sledovat zásilku"
        }
      >
        <IconButton
          disabled={
            order.shipping_details?.type === ShippingType.PPL &&
            !order.shipping_details.shipmentNumber
          }
          onClick={onOrderTrackClick}
          style={{
            padding: 4,
            marginLeft: 4,
            marginRight: 4,
            backgroundColor: colors.MAIN_300,
          }}
        >
          <BsTruck size={16} color={colors.OPPOSITE_MAIN_400} />
        </IconButton>
      </div>
    </FlexCenterAlign>
  );
};

interface IProps {}

export const OrdersList = ({}: IProps): React.ReactElement<IProps> => {
  const { language, store } = useAppConfig();
  const [search, setSearch] = useState("");
  const [state, setState] = useState<OrderState | null>(null);
  const shippings = useShippings();
  const payments = usePayments();
  const colors = useColors();

  const [shippingFilter, setShippingFilter] = useState<string | undefined>(
    undefined
  );
  const [paymentFilter, setPaymentFilter] = useState<string | undefined>(
    undefined
  );

  const [showTesting, setShowTesting] = useState(false);

  const [rows, setRows] = useState<TRow[]>([]);

  const [queryLoading, setQueryLoading] = useState(true);
  const [sortBy, setSortBy] = useState<TSortBy>({
    key: "",
    type: SortByType.None,
  });
  const [totalCount, setTotalCount] = useState(0);

  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [search]);

  const getFilteredOrders = async () => {
    try {
      setQueryLoading(true);
      const offset = page * ITEMS_PER_PAGE;
      const { orders, totalCount } = await orderModel.getFilteredOrders({
        offset,
        query: search,
        sort: sortBy,
        language: language._id,
        store: store._id,
        shipping: shippingFilter,
        payment: paymentFilter,
        showTesting,
        state,
      });
      setQueryLoading(false);

      setTotalCount(totalCount);
      setRows(mapOrdersToTable(orders));
    } catch (err) {
      setQueryLoading(false);
    }
  };

  const onSelectRowId = (rowId: TSelectRowId) => {
    if (selectedRows.some((id) => id.id === rowId.id)) {
      setSelectedRows((rows) => rows.filter((id) => id.id !== rowId.id));
      return;
    }

    setSelectedRows((rows) => [...rows, rowId]);
  };

  const [selectedRows, setSelectedRows] = useState<TSelectRowId[]>([]);

  useEffect(() => {
    getFilteredOrders();
  }, [
    search,
    state,
    sortBy,
    store,
    language,
    shippingFilter,
    paymentFilter,
    showTesting,
    page,
  ]);

  return (
    <OrdersListContext.Provider value={{ fetch: getFilteredOrders }}>
      <div
        style={{
          maxWidth: "98%",
        }}
      >
        <HeaderText
          style={{
            fontSize: 18,
            marginBottom: 12,
          }}
        >
          Objednávky pro obchod:{" "}
          <b
            style={{
              fontWeight: 500,
            }}
          >
            {store.title}
          </b>
        </HeaderText>
        <FlexCenterAlign
          style={{
            marginBottom: 20,
          }}
        >
          <div
            style={{
              marginRight: 16,
            }}
          >
            <Input
              placeholder="Vyhledat objednávku"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>

          <FlexCenterAlign
            style={{
              marginRight: 16,
            }}
          >
            <Dropdown
              list={Object.values(OrderState).map((state) => ({
                content: getOrderStateTitle(state),
                value: state,
                unique_id: state,
              }))}
              onSelectValue={(state) => setState(state)}
              selectedUniqueId={state || ""}
              title="Stav objednávky"
            />
          </FlexCenterAlign>

          <FlexCenterAlign
            style={{
              marginRight: 16,
            }}
          >
            <Dropdown
              list={shippings.map((s) => ({
                content: getShippingTypeTitle(s.type),
                value: s._id,
                unique_id: s._id,
              }))}
              onSelectValue={(shipping) => setShippingFilter(shipping)}
              selectedUniqueId={shippingFilter || ""}
              title="Doprava"
            />
          </FlexCenterAlign>

          <FlexCenterAlign>
            <Dropdown
              list={payments.map((p) => ({
                content: getPaymentTypeTitle(p.type),
                value: p._id,
                unique_id: p._id,
              }))}
              onSelectValue={(payment) => setPaymentFilter(payment)}
              selectedUniqueId={paymentFilter || ""}
              title="Platební metoda"
            />
          </FlexCenterAlign>

          <FlexCenterAlign
            style={{
              marginLeft: 16,
              fontSize: 12,
              whiteSpace: "nowrap",
            }}
          >
            <Checkbox value={showTesting} setValue={setShowTesting} />
            <div
              style={{
                marginLeft: 8,
              }}
            >
              Zobrazit testovací objednávky
            </div>
          </FlexCenterAlign>

          <Flex
            style={{
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              data-title="Znovu načíst"
              onClick={() => getFilteredOrders()}
              style={{
                backgroundColor: colors.MAIN_200,
                padding: 6,
              }}
              hoverBackgroundColor={colors.MAIN_150}
            >
              <MdOutlineRefresh size={24} color={colors.OPPOSITE_MAIN_400} />
            </IconButton>
          </Flex>
        </FlexCenterAlign>
        <SelectedOrders
          selectedOrders={selectedRows}
          setSelectedOrders={setSelectedRows}
        />
        <LazyTable
          selectableRowWidth={"50px"}
          onSelectRowId={onSelectRowId}
          selectedRowIDs={selectedRows}
          selectRows={(rows) =>
            setSelectedRows((selectedRows) => {
              const arr = [...selectedRows];

              rows.forEach((r) => {
                if (arr.some((s) => s.id === r.id)) {
                  return;
                }

                arr.push(r);
              });

              return arr;
            })
          }
          deselectRows={(rows) =>
            setSelectedRows((selectedRows) =>
              selectedRows.filter((row) => !rows.some((r) => r.id === row.id))
            )
          }
          customRowHeight={10}
          isLoading={queryLoading}
          totalCount={totalCount}
          onSortByChange={setSortBy}
          onSetPage={setPage}
          headerKeys={ORDER_TABLE_HEADER}
          rows={rows}
        />
      </div>
    </OrdersListContext.Provider>
  );
};

export default OrdersList;
