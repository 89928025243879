import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/button";
import { AddButtonActionStyles, FlexCenterAlign } from "../../ui/common";
import ViewWrapper from "../view-wrapper";
import ShippingsList from "./shippings-list";

export const ShippingsActionButtons =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();

    return (
      <FlexCenterAlign>
        <Button
          style={AddButtonActionStyles}
          onClick={() => navigate("/shippings/add-shipping")}
        >
          Přidat dopravu
        </Button>
      </FlexCenterAlign>
    );
  };

interface IProps {}

export const Shippings = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <ViewWrapper>
      <ShippingsActionButtons />

      <ShippingsList />
    </ViewWrapper>
  );
};

export default Shippings;
