import { GoPayPaymentState, IOrder, OrderState } from "../../types/order";
import { TSortBy } from "../../ui/table";
import axios from "../axios";

export type TOrdersFilter = {
  state: OrderState | null;
  query: string;
  sort: TSortBy;
  offset: number;
  showTesting?: boolean;
  language: string;
  store: string;
  products?: string[];
  shipping?: string;
  payment?: string;
};

export interface IFilteredOrdersResult {
  orders: IOrder[];
  totalCount: number;
}

interface IGetPPLBarcodeResponse {
  label: string;
  shipmentNumber: string;
}

export interface IOrderModel {
  getFilteredOrders: (filter: TOrdersFilter) => Promise<IFilteredOrdersResult>;
  getOrderById: (id: string) => Promise<IOrder>;
  checkGoPayState: (id: string) => Promise<GoPayPaymentState>;
  editOrder: (data: FormData) => Promise<IOrder>;
  editOrderState: (data: FormData) => Promise<IOrder>;
  removeOrderById: (id: string) => Promise<boolean>;
  getZasilkovnaBarcodePng: (trackingBarcode: string) => Promise<string>;
  getPPLBarcodePng: (orderId: string) => Promise<IGetPPLBarcodeResponse>;
  setOrdersAsTesting: (data: FormData) => Promise<boolean>;
}

export const orderModel: IOrderModel = {
  getZasilkovnaBarcodePng(trackingBarcode) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<string>(
          `/orders/get-zasilkovna-barcode-png`,
          {
            params: {
              trackingBarcode,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  checkGoPayState(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<GoPayPaymentState>(
          `/orders/check-gopay-state`,
          {
            params: {
              id,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getPPLBarcodePng(orderId) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IGetPPLBarcodeResponse>(
          `/orders/get-ppl-barcode-png`,
          {
            params: {
              orderId,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getFilteredOrders(filter: TOrdersFilter) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IFilteredOrdersResult>(
          `/orders/get-filtered`,
          {
            params: {
              state: filter.state || undefined,
              query: filter.query,
              sort: JSON.stringify(filter.sort),
              offset: filter.offset,
              products: filter.products
                ? JSON.stringify(filter.products)
                : undefined,
              shipping: filter.shipping || undefined,
              payment: filter.payment || undefined,
              language: filter.language,
              store: filter.store,
              showTesting: filter.showTesting
                ? JSON.stringify(filter.showTesting)
                : undefined,
            },
          }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getOrderById(_id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IOrder>(`/orders/get-by-id`, {
          params: {
            _id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  editOrder(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IOrder>(`/orders/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editOrderState(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IOrder>(`/orders/edit-state`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeOrderById(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/orders/remove-by-id`, {
          _id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  setOrdersAsTesting(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(
          `/orders/set-orders-as-testing`,
          data
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
