import React from "react";
import styled from "styled-components";
import Tasks from "../components/tasks/tasks";
import ViewWrapper from "../view-wrapper";
import ActionButtons from "./action-buttons";

interface IProps {}

export const LandingPage = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <ViewWrapper>
        <Tasks />
    </ViewWrapper>
  );
};

export default LandingPage;
