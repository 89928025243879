import React from "react";
import styled from "styled-components";
import { useColors } from "../../providers/theme/theme-provider";
import { IThemeColors } from "../../providers/theme/themes";
import Button from "../../ui/button";
import { FlexCenterAlign } from "../../ui/common";

export enum AdminSelectedWindow {
  Users,
  Actions,
}

const WindowSelectorButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 8px;
  }
`;

interface IProps {
  selectedWindow: AdminSelectedWindow;
  setSelectedWindow(window: AdminSelectedWindow): void;
}

const getBackgroundColor = (
  selectedWindow: AdminSelectedWindow,
  is: AdminSelectedWindow,
  colors: IThemeColors
): string | undefined => {
  return selectedWindow === is ? colors.MAIN_250 : undefined;
};

export const WindowSelector = ({
  selectedWindow,
  setSelectedWindow,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <FlexCenterAlign
      style={{
        margin: "0px 8px",
        marginBottom: 20,
      }}
    >
      <WindowSelectorButtonsWrapper>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              AdminSelectedWindow.Users,
              colors
            ),
          }}
          onClick={() => setSelectedWindow(AdminSelectedWindow.Users)}
        >
          Uživatelé
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              AdminSelectedWindow.Actions,
              colors
            ),
          }}
          onClick={() => setSelectedWindow(AdminSelectedWindow.Actions)}
        >
          Historie akcí
        </Button>
      </WindowSelectorButtonsWrapper>
    </FlexCenterAlign>
  );
};

export default WindowSelector;
