import { IImageDB } from "../../types/gallery";
import { GalleryActions, TGalleryAction } from "../actions/galleryActions";

export interface IGalleryState {
  images: IImageDB[];
}

const initialGalleryState: IGalleryState = {
  images: [],
};

export const galleryReducer = (
  state: IGalleryState = initialGalleryState,
  action: TGalleryAction
): IGalleryState => {
  switch (action.type) {
    case GalleryActions.SET_IMAGES:
      return { ...state, images: action.payload };
    case GalleryActions.ADD_IMAGES:
      return { ...state, images: [...state.images, ...action.payload] };
    case GalleryActions.EDIT_IMAGE:
      let images = [...state.images],
        index = images.findIndex((i) => i._id === action.payload._id);

      images[index] = action.payload;

      return { ...state, images };
    case GalleryActions.REMOVE_IMAGE_BY_ID:
      return {
        ...state,
        images: state.images.filter((img) => img._id !== action.payload),
      };
    default:
      return state;
  }
};
