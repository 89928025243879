import { TGetError } from "../../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../../types/error";
import { IEditorStore } from "./store-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.STORE);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TStoreEditorErrorsObject = {
  key?: TReduxError;
  key_already_exists?: TReduxError;
  url?: TReduxError;
  title?: TReduxError;
  title_lowercase?: TReduxError;
  eshop_identity?: TReduxError;
  instagram_name?: TReduxError;
  email_subject?: TReduxError;
  default_email?: TReduxError;
  smtp_password?: TReduxError;
  languages_empty?: TReduxError;
};

type TStoreEditorErrors = {
  errors: TStoreEditorErrorsObject;
  isValid: boolean;
};

export const getStoreEditorErrors = (
  getError: TGetError,
  store: IEditorStore
): TStoreEditorErrors => {
  const key = getErrorOfKey("key", getError, store.key === "");

  const key_already_exists_params = getError(
    "key_already_exists",
    ErrorType.STORE,
    {
      value: store.key,
    }
  )?.params;

  const key_already_exists = getErrorOfKey(
    "key_already_exists",
    getError,
    !!(
      key_already_exists_params &&
      key_already_exists_params.value === store.key
    )
  );

  const url = getErrorOfKey("url", getError, store.url === "");
  const title = getErrorOfKey("title", getError, store.title === "");
  const title_lowercase = getErrorOfKey(
    "title_lowercase",
    getError,
    store.title_lowercase === ""
  );
  const eshop_identity = getErrorOfKey(
    "eshop_identity",
    getError,
    store.eshop_identity === ""
  );
  const instagram_name = getErrorOfKey(
    "instagram_name",
    getError,
    store.instagram_name === ""
  );
  const email_subject = getErrorOfKey(
    "email_subject",
    getError,
    store.email_subject === ""
  );
  const default_email = getErrorOfKey(
    "default_email",
    getError,
    store.default_email === ""
  );
  const smtp_password = getErrorOfKey(
    "smtp_password",
    getError,
    store.smtp_password === ""
  );

  const languages_empty = getErrorOfKey(
    "languages_empty",
    getError,
    store.languages.length === 0
  );

  return {
    errors: {
      key,
      key_already_exists,
      url,
      title,
      title_lowercase,
      eshop_identity,
      instagram_name,
      email_subject,
      default_email,
      smtp_password,
      languages_empty,
    },
    isValid:
      !key &&
      !key_already_exists &&
      !url &&
      !title &&
      !title_lowercase &&
      !eshop_identity &&
      !instagram_name &&
      !email_subject &&
      !default_email &&
      !smtp_password &&
      !languages_empty,
  };
};
