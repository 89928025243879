import { TSlug } from "../../types/slugs";
import axios from "../axios";

export interface ISlugModel {
  getAvailableSlug: (
    name: string,
    locale: string,
    _id?: string
  ) => Promise<TSlug>;
}

export const slugModel: ISlugModel = {
  getAvailableSlug(value, locale, _id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<TSlug>(`/slugs/get-available-slug`, {
          params: {
            value,
            locale,
            _id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
