import { TLanguageNumber } from "./common";
import { IStoreID } from "./store";

export enum ShippingType {
  Zasilkovna = "ZASILKOVNA",
  PPL = "PPL",
}

export interface IShipping {
  type: ShippingType;
  price?: TLanguageNumber;
  stores: IStoreID[];
  free_from?: TLanguageNumber;
  max_value?: TLanguageNumber;
}

export interface IShippingID extends IShipping {
  _id: string;
}
