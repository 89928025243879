import { couponsModel } from "../../model/query/couponModel";
import { ErrorsActions } from "./errorsActions";

export const ADD_COUPON = async (data: FormData) => {
  try {
    const coupon = await couponsModel.addCoupon(data);

    return coupon;
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_COUPON = async (data: FormData) => {
  try {
    const coupon = await couponsModel.editCoupon(data);

    return coupon;
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_COUPON_BY_ID = async (id: string) => {
  try {
    await couponsModel.removeCoupon(id);

    return true;
  } catch (err) {
    throw err;
  }
};
