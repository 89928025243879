import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getManufacturerName,
  isLanguageStringEmpty,
} from "../../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useAppConfig, useErrors } from "../../../common/redux-hooks";
import {
  defaultLanguageKey,
  nonTranslatedString,
} from "../../../constants/language";
import { manufacturerModel } from "../../../model/query/manufacturerModel";
import { slugModel } from "../../../model/query/slugModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_MANUFACTURER,
  EDIT_MANUFACTURER,
  REMOVE_MANUFACTURER_BY_ID,
} from "../../../redux/actions/manufacturerActions";
import { useDispatch } from "../../../redux/store";
import {
  EditorType,
  TLanguageSlug,
  TLanguageString,
} from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { IImageDB, ImageType } from "../../../types/gallery";
import { IManufacturerID } from "../../../types/manufacturer";
import { defaultSlug } from "../../../types/slugs";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  EditorImage,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
} from "../../../ui/common";
import RichTextEditor from "../../../ui/editorTinyMce";
import GalleryPicker from "../../../ui/gallery-picker";
import { InputWithTitle } from "../../../ui/input-with-title";
import LanguageSelector from "../../../ui/language-selector";
import SlugInput from "../../../ui/slug-input";
import ErrorTooltip from "../../../ui/tooltips/error-tooltip";
import BackButton from "../../components/back-button";
import { getManufacturerEditorErrors } from "./editor-errors";

const mapManufacturerToEditorManufacturer = (
  manufacturer: IManufacturerID
): IEditorManufacturer => {
  const {
    _id,
    name,
    nameIsSameForAllLanguages,
    visible,
    description,
    image,
    slug,
  } = manufacturer;

  return {
    description,
    name,
    nameIsSameForAllLanguages,
    visible,
    _id,
    image,
    slug,
  };
};

const initialEditorManufacturer: IEditorManufacturer = {
  name: {},
  nameIsSameForAllLanguages: true,
  visible: true,
  description: {},
  slug: {},
};

export interface IEditorManufacturer {
  _id?: string;
  name: TLanguageString;
  nameIsSameForAllLanguages: boolean;
  visible: boolean;
  image?: IImageDB;
  slug: TLanguageSlug;
  description: TLanguageString;
}

interface IProps {
  type: EditorType;
}

export const ManufacturerEditor = ({
  type,
}: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const appConfig = useAppConfig();
  const [language, setLanguage] = useState(appConfig.language);
  const locale = language.locale;
  const [manufacturer, setManufacturer] = useState<IEditorManufacturer>(
    initialEditorManufacturer
  );

  const { closeNotification } = useNotifications();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getManufacturerEditorErrors(
    getError,
    manufacturer,
    language.locale
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useQueryWithPopupErrorHandling();
  const { createConfirmation } = useConfirmation();
  const colors = useColors();

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.MANUFACTURER);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getManufacturer = async (id: string) => {
      call(
        () => manufacturerModel.getManufacturerById(id),
        "Zobrazení výrobce nebylo úspěšné",
        null,
        (manufacturer) => {
          setManufacturer(mapManufacturerToEditorManufacturer(manufacturer));
        },
        () => {
          navigate("/manufacturers");
        }
      );
    };

    if (_id && type === EditorType.Edit) {
      getManufacturer(_id);
    }
  }, [_id, type]);

  const appendManufacturerToForm = useCallback(
    (form: FormData) => {
      if (!isLanguageStringEmpty(manufacturer.name)) {
        let name = { ...manufacturer.name };
        if (manufacturer.nameIsSameForAllLanguages) {
          name = {
            [defaultLanguageKey]: name[defaultLanguageKey],
          };
        } else if (name[defaultLanguageKey] !== undefined) {
          delete name[defaultLanguageKey];
        }

        form.append("name", JSON.stringify(name));
      }

      form.append(
        "nameIsSameForAllLanguages",
        JSON.stringify(manufacturer.nameIsSameForAllLanguages)
      );

      form.append("visible", JSON.stringify(manufacturer.visible));

      if (!isLanguageStringEmpty(manufacturer.description)) {
        form.append("description", JSON.stringify(manufacturer.description));
      }

      if (manufacturer.image) {
        form.append("image", JSON.stringify(manufacturer.image._id));
      }

      let slug = { ...manufacturer.slug };

      if (manufacturer.nameIsSameForAllLanguages) {
        slug = {
          [defaultLanguageKey]: slug[defaultLanguageKey],
        };
      } else if (slug[defaultLanguageKey] !== undefined) {
        delete slug[defaultLanguageKey];
      }

      Object.keys(slug).forEach((key) => {
        const s = slug[key];

        if (!s || (!s.userDefined && !s.value && !manufacturer.name[key])) {
          delete slug[key];
        }
      });

      form.append("slug", JSON.stringify(slug));
    },
    [manufacturer]
  );

  const addManufacturer = () => {
    const data = new FormData();

    appendManufacturerToForm(data);

    call(
      () => ADD_MANUFACTURER(data),
      "Přidání výrobce nebylo úspěšné",
      "Přidání výrobce bylo úspěšné",
      (action) => {
        dispatch(action);
        navigate("/manufacturers");
      },
      (err) => {
        if (err?.payload) {
          dispatch(err);
        }
      }
    );
  };

  const editManufacturer = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit výrobce", async () => {
      if (!_id) {
        return;
      }

      const data = new FormData();

      data.append("_id", _id);
      appendManufacturerToForm(data);

      call(
        () => EDIT_MANUFACTURER(data),
        "Úprava výrobce nebyla úspěšná",
        "Úprava výrobce byla úspěšná",
        (action) => {
          dispatch(action);

          if (exit) {
            navigate("/manufacturers");
          } else {
            setManufacturer(
              mapManufacturerToEditorManufacturer(action.payload)
            );
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeManufacturer = () => {
    createConfirmation(
      "Potvrzení: Odstranit výrobce a jeho použití",
      async () => {
        if (!_id) {
          return;
        }

        call(
          () => REMOVE_MANUFACTURER_BY_ID(_id),
          "Odstranění výrobce nebylo úspěšné",
          "Odstranění výrobce bylo úspěšné",
          (action) => {
            dispatch(action);
            navigate("/manufacturers");
          }
        );
      }
    );
  };

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!isValid}
          onClick={addManufacturer}
        >
          Přidat výrobce
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editManufacturer()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton
              disabled={!isValid}
              onClick={() => editManufacturer(true)}
            >
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removeManufacturer}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper
      node={node}
      deps={[isValid, appendManufacturerToForm]}
    >
      <div>
        <BackButton path="/manufacturers" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          {type === EditorType.Add ? "Přidání výrobce" : "Úprava výrobce"}
        </div>
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <LanguageSelector onSelectLanguage={setLanguage} />
        </div>
        <Flex>
          <div>
            <Flex>
              <InputsWrapper>
                <InputWithTitle
                  required
                  inputProps={{
                    placeholder:
                      manufacturer.nameIsSameForAllLanguages &&
                      !manufacturer.name[defaultLanguageKey]
                        ? "Nevyplněno"
                        : !manufacturer.name[locale]
                        ? nonTranslatedString
                        : undefined,
                    error: errors.name,
                  }}
                  wrapperStyle={{
                    marginTop: 0,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={getManufacturerName(manufacturer, locale) || ""}
                  setValue={(name) => {
                    {
                      setManufacturer((m) => ({
                        ...m,
                        name: {
                          ...m.name,
                          [m.nameIsSameForAllLanguages
                            ? defaultLanguageKey
                            : locale]: name,
                        },
                      }));
                    }
                  }}
                  title="Název výrobce"
                />
                <SlugInput
                  deps={[manufacturer.nameIsSameForAllLanguages]}
                  error={
                    errors.slug_value_empty ||
                    errors.slug_value_invalid ||
                    errors.slug_already_exists
                  }
                  slug={
                    manufacturer.nameIsSameForAllLanguages
                      ? manufacturer.slug?.[defaultLanguageKey] || {}
                      : manufacturer.slug?.[locale] || defaultSlug
                  }
                  setSlug={(slug) =>
                    setManufacturer((c) => ({
                      ...c,
                      slug: {
                        ...c.slug,
                        [manufacturer.nameIsSameForAllLanguages
                          ? defaultLanguageKey
                          : locale]: slug,
                      },
                    }))
                  }
                  value={getManufacturerName(manufacturer, locale) || ""}
                  getAvailableSlug={async () => {
                    if (!getManufacturerName(manufacturer, locale)) {
                      return defaultSlug;
                    }

                    return await slugModel.getAvailableSlug(
                      getManufacturerName(manufacturer, locale) || "",
                      locale,
                      manufacturer._id
                    );
                  }}
                />
              </InputsWrapper>
            </Flex>
            <Flex
              style={{
                marginTop: 20,
              }}
            >
              <InputsWrapper>
                <FlexCenterAlign>
                  <Checkbox
                    value={manufacturer.nameIsSameForAllLanguages}
                    setValue={(nameIsSameForAllLanguages) => {
                      setManufacturer((m) => ({
                        ...m,
                        name: {
                          ...m.name,
                          [nameIsSameForAllLanguages
                            ? defaultLanguageKey
                            : locale]:
                            m.name[
                              nameIsSameForAllLanguages
                                ? locale
                                : defaultLanguageKey
                            ],
                        },
                        nameIsSameForAllLanguages,
                      }));
                    }}
                  />
                  <div
                    style={{
                      fontSize: 12,
                      marginLeft: 8,
                    }}
                  >
                    Název výrobce je stejný pro všechny jazyky
                  </div>
                </FlexCenterAlign>
                <FlexCenterAlign
                  style={{
                    marginTop: 12,
                  }}
                >
                  <Checkbox
                    value={manufacturer.visible}
                    setValue={(visible) =>
                      setManufacturer((m) => ({
                        ...m,
                        visible,
                      }))
                    }
                  />
                  <div
                    style={{
                      fontSize: 12,
                      marginLeft: 8,
                    }}
                  >
                    Zobrazovat výrobce u produktů
                  </div>
                </FlexCenterAlign>
              </InputsWrapper>
            </Flex>
            <Flex
              style={{
                marginTop: 20,
              }}
            >
              <InputsWrapper
                style={{
                  width: 240,
                }}
              >
                <GalleryPicker
                  type={ImageType.Manufacturer}
                  title="Vyberte foto"
                  onChoose={(image) =>
                    setManufacturer((m) => ({ ...m, image }))
                  }
                />
                {manufacturer.image && (
                  <EditorImage
                    style={{
                      marginTop: 12,
                    }}
                    src={manufacturer.image?.url}
                    onDeleteClick={() =>
                      setManufacturer((m) => ({ ...m, image: undefined }))
                    }
                  />
                )}
              </InputsWrapper>
            </Flex>
          </div>
          <InputsWrapper
            style={{
              marginLeft: 20,
            }}
          >
            <FlexCenterAlign
              style={{
                marginBottom: 12,
                marginLeft: 10,
              }}
            >
              <div
                style={{
                  fontSize: 18,
                  color: errors.description
                    ? colors.ERROR_500
                    : colors.OPPOSITE_MAIN_400,
                  opacity: 0.6,
                }}
              >
                Popis výrobce
              </div>
              <ErrorTooltip error={errors.description} />
            </FlexCenterAlign>
            <RichTextEditor
              value={manufacturer.description[locale] || ""}
              onEditorChange={(description) =>
                setManufacturer((m) => ({
                  ...m,
                  description: { ...m.description, [locale]: description },
                }))
              }
            />
          </InputsWrapper>
        </Flex>
      </div>
    </BottomFixedWrapperWrapper>
  );
};

export default ManufacturerEditor;
