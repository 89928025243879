import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import {
  useAppConfig,
  useErrors,
  useParameters,
} from "../../../common/redux-hooks";
import {
  NonTranslatedString,
  nonTranslatedString,
} from "../../../constants/language";
import { parameterGroupModel } from "../../../model/query/parameterGroupModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_PARAMETER_GROUP,
  EDIT_PARAMETER_GROUP,
  REMOVE_PARAMETER_GROUP_BY_ID,
} from "../../../redux/actions/parameterGroupsActions";
import { useDispatch } from "../../../redux/store";
import { EditorType } from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { IParameterGroupID, IParameterID } from "../../../types/parameter";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
} from "../../../ui/common";
import DropdownMultiSelect from "../../../ui/dropdown-multi-select";
import { InputWithTitle } from "../../../ui/input-with-title";
import BackButton from "../../components/back-button";
import { getParameterGroupEditorErrors } from "./editor-errors";

const mapParameterGroupToEditorParameterGroup = (
  parameterGroup: IParameterGroupID
): IEditorParameterGroup => {
  const { _id, generateRelatedProducts, generateVariants, parameters, title } =
    parameterGroup;

  return {
    _id,
    generateRelatedProducts,
    generateVariants,
    parameters,
    title,
  };
};

const initialEditorParameterGroup: IEditorParameterGroup = {
  title: "",
  parameters: [],
  generateRelatedProducts: true,
  generateVariants: true,
};

export interface IEditorParameterGroup {
  _id?: string;
  title: string;
  parameters: IParameterID[];
  generateRelatedProducts: boolean;
  generateVariants: boolean;
}

interface IProps {
  type: EditorType;
}

export const ParameterGroupEditor = ({
  type,
}: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const { language } = useAppConfig();
  const [parameterGroup, setParameterGroup] = useState<IEditorParameterGroup>(
    initialEditorParameterGroup
  );

  const parameters = useParameters();

  const colors = useColors();
  const { closeNotification } = useNotifications();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getParameterGroupEditorErrors(
    getError,
    parameterGroup
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useQueryWithPopupErrorHandling();
  const { createConfirmation } = useConfirmation();

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.PARAMETER_GROUP);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getParameterGroup = async (id: string) => {
      call(
        () => parameterGroupModel.getParameterGroupById(id),
        "Zobrazení parametrové skupiny nebylo úspěšné",
        null,
        (parameterGroup) => {
          setParameterGroup(
            mapParameterGroupToEditorParameterGroup(parameterGroup)
          );
        },
        () => {
          navigate("/parameter-groups");
        }
      );
    };

    if (_id && type === EditorType.Edit) {
      getParameterGroup(_id);
    }
  }, [_id, type]);

  const appendParameterGroupToForm = useCallback(
    (form: FormData) => {
      form.append("title", parameterGroup.title);
      form.append(
        "parameters",
        JSON.stringify(parameterGroup.parameters.map((p) => p._id))
      );
      form.append(
        "generateVariants",
        JSON.stringify(parameterGroup.generateVariants)
      );
      form.append(
        "generateRelatedProducts",
        JSON.stringify(parameterGroup.generateRelatedProducts)
      );
    },
    [parameterGroup]
  );

  const addParameterGroup = () => {
    const data = new FormData();

    appendParameterGroupToForm(data);

    call(
      () => ADD_PARAMETER_GROUP(data),
      "Přidání parametrové skupiny nebylo úspěšné",
      "Přidání parametrové skupiny bylo úspěšné",
      (action) => {
        dispatch(action);
        navigate("/parameter-groups");
      },
      (err) => {
        if (err?.payload) {
          dispatch(err);
        }
      }
    );
  };

  const editParameterGroup = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit parametrovou skupinu", async () => {
      if (!parameterGroup._id) {
        return;
      }

      const data = new FormData();

      data.append("_id", parameterGroup._id);
      appendParameterGroupToForm(data);

      call(
        () => EDIT_PARAMETER_GROUP(data),
        "Úprava parametrové skupiny nebyla úspěšná",
        "Úprava parametrové skupiny byla úspěšná",
        (action) => {
          dispatch(action);

          if (exit) {
            navigate("/parameter-groups");
          } else {
            setParameterGroup(
              mapParameterGroupToEditorParameterGroup(action.payload)
            );
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeParameterGroup = () => {
    createConfirmation(
      "Potvrzení: Odstranit parametrovou skupinu a její použití",
      async () => {
        if (!_id) {
          return;
        }

        call(
          () => REMOVE_PARAMETER_GROUP_BY_ID(_id),
          "Odstranění parametrové skupiny nebylo úspěšné",
          "Odstranění parameterové skupiny bylo úspěšné",
          (remove) => {
            dispatch(remove);
            navigate("/parameter-groups");
          }
        );
      }
    );
  };

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!isValid}
          onClick={addParameterGroup}
        >
          Přidat parametrovou skupinu
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editParameterGroup()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton
              disabled={!isValid}
              onClick={() => editParameterGroup(true)}
            >
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removeParameterGroup}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper
      node={node}
      deps={[isValid, appendParameterGroupToForm]}
    >
      <div>
        <BackButton path="/parameter-groups" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          {type === EditorType.Add
            ? "Přidání parametrové skupiny"
            : "Úprava parametrové skupiny"}
        </div>
        <Flex>
          <InputsWrapper>
            <InputWithTitle
              wrapperStyle={{
                marginTop: 0,
              }}
              title="Název parametrové skupiny"
              setValue={(title) => setParameterGroup((p) => ({ ...p, title }))}
              value={parameterGroup.title}
              inputProps={{
                error: errors.title_empty,
              }}
              inputStyle={{
                width: 320,
              }}
            />
          </InputsWrapper>
        </Flex>
        <Flex
          style={{
            marginTop: 20,
          }}
        >
          <InputsWrapper>
            <DropdownMultiSelect
              deleteTitle="Odstranit parametr"
              title="Přidat parametr do skupiny"
              list={parameters.map((p) => ({
                content: p.title[language.locale] || <NonTranslatedString />,
                value: p,
                unique_id: p._id,
                query: p.title[language.locale] || nonTranslatedString,
                disabled: parameterGroup.parameters.includes(p),
              }))}
              onRemoveUniqueID={(id) =>
                setParameterGroup((p) => ({
                  ...p,
                  parameters: p.parameters.filter((_p) => _p._id !== id),
                }))
              }
              onSelectValue={(parameter) =>
                setParameterGroup((p) => ({
                  ...p,
                  parameters: [...p.parameters, parameter],
                }))
              }
              error={errors.parameters_empty}
              selectedDropdownIDs={parameterGroup.parameters.map((p) => p._id)}
            />
          </InputsWrapper>
        </Flex>
        <Flex
          style={{
            marginTop: 20,
          }}
        >
          <InputsWrapper>
            <FlexCenterAlign>
              <Checkbox
                value={parameterGroup.generateVariants}
                setValue={(generateVariants) =>
                  setParameterGroup((p) => ({ ...p, generateVariants }))
                }
              />
              <div
                style={{
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                Generovat varianty z parametrové skupiny
              </div>
            </FlexCenterAlign>
            <FlexCenterAlign
              style={{
                marginTop: 16,
              }}
            >
              <Checkbox
                value={parameterGroup.generateRelatedProducts}
                setValue={(generateRelatedProducts) =>
                  setParameterGroup((p) => ({ ...p, generateRelatedProducts }))
                }
              />
              <div
                style={{
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                Generovat související produkty z parametrové skupiny
              </div>
            </FlexCenterAlign>
          </InputsWrapper>
        </Flex>
      </div>
    </BottomFixedWrapperWrapper>
  );
};

export default ParameterGroupEditor;
