import { initialProductFilters, IProductFilters } from "../../types/products";
import { FiltersActions, TFiltersAction } from "../actions/filterActions";

export type TProductFilterState = {
  filters: IProductFilters | null;
  query: string;
};

export interface IFiltersState {
  products: TProductFilterState;
}

const initialFilterState: IFiltersState = {
  products: {
    filters: null,
    query: "",
  },
};

export const filtersReducer = (
  state: IFiltersState = initialFilterState,
  action: TFiltersAction
): IFiltersState => {
  switch (action.type) {
    case FiltersActions.SET_PRODUCT_FILTERS:
      return {
        ...state,
        products: { ...state.products, filters: action.payload },
      };
    case FiltersActions.SET_PRODUCT_QUERY:
      return {
        ...state,
        products: { ...state.products, query: action.payload },
      };
    default:
      return state;
  }
};
