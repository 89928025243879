import { AnalyticsTimeScale } from "./types";

export const getSalesTextFromTimeScale = (
  timeScale: AnalyticsTimeScale
): string => {
  switch (timeScale) {
    case AnalyticsTimeScale.Hour:
      return "oproti předchozí hodině";
    case AnalyticsTimeScale.Day:
      return "oproti včerejšku";
    case AnalyticsTimeScale.Week:
      return "oproti minulému týdnu";
    case AnalyticsTimeScale.Month:
      return "oproti minulému měsíci";
    case AnalyticsTimeScale.Year:
      return "oproti minulému roku";
    default:
      return "";
  }
};

export const getDefaultTimeScaleText = (
  timeScale: AnalyticsTimeScale
): string => {
  switch (timeScale) {
    case AnalyticsTimeScale.All:
      return "Za celý čas";
    case AnalyticsTimeScale.Hour:
      return "Za poslední hodinu";
    case AnalyticsTimeScale.Day:
      return "Za tento den";
    case AnalyticsTimeScale.Week:
      return "Za tento týden";
    case AnalyticsTimeScale.Month:
      return "Za tento měsíc";
    case AnalyticsTimeScale.Year:
      return "Za tento rok";
  }
};
