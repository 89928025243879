import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import styled, { css } from "styled-components";
import { useColors } from "../providers/theme/theme-provider";
import { FlexCenterAlign, NoResultsFound } from "./common";
import IconButton from "./icon-button";
import Loading from "./loading";

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  ${({ theme }) => css`
    thead {
      tr {
        th {
          padding: 14px 12px;
          text-align: left;
          background-color: ${theme.colors.MAIN_300};
          font-weight: 500;
          color: ${theme.colors.OPPOSITE_MAIN_300};
          font-size: 14px;
          letter-spacing: 0.0125em;

          &:first-child {
            border-top-left-radius: ${theme.borderRadius / 2}px;
          }

          &:last-child {
            border-top-right-radius: ${theme.borderRadius / 2}px;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n) {
          td {
            background-color: ${theme.colors.MAIN_350};
          }
        }

        td {
          background-color: ${theme.colors.MAIN_400};
          padding: 4px 12px;
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media (max-width: 800px) {
            overflow: auto !important;
          }
        }
      }
    }

    tfoot {
      tr {
        &:last-child {
          td {
            padding: 16px;
            background-color: ${theme.colors.MAIN_300};

            &:first-child {
              border-bottom-left-radius: ${theme.borderRadius / 2}px;
            }

            &:last-child {
              border-bottom-right-radius: ${theme.borderRadius / 2}px;
            }
          }
        }
      }
    }
  `}
`;

export type TCell = {
  key: string;
  content: ReactNode;
  value: any;
  width?: string;
  colSpan?: number;
};

export type TRow = {
  value?: any;
  disabled?: boolean;
  cells: TCell[];
  selectable?: boolean;
  id?: string;
  rememberClick?: boolean;
};

const filterRowsByQuery = (
  rows: TRow[],
  query: string,
  headerKeys: THeaderKey[],
  includeInQuery?: string[]
): TRow[] => {
  const indexesToQuery = includeInQuery?.map((query_key) =>
    headerKeys.findIndex((h) => h.query_key === query_key)
  );

  const trimmedQuery = query.trim();

  return rows.filter((row) => {
    return row.cells.some(
      (cell, i) =>
        (cell.content
          ?.toString()
          .toLowerCase()
          .includes(trimmedQuery.toLowerCase()) ||
          (cell.value?.toString &&
            cell.value
              .toString()
              .toLowerCase()
              .includes(trimmedQuery.toLowerCase()))) &&
        (!indexesToQuery ||
          indexesToQuery.length === 0 ||
          indexesToQuery.includes(i))
    );
  });
};

export const TableHeadCell = styled.th<{ canSort: boolean }>`
  .sort-by {
    opacity: 0;
  }

  ${({ canSort, theme }) =>
    canSort &&
    css`
      &:hover {
        background-color: ${theme.colors.MAIN_200};

        .sort-by {
          opacity: 0.5;
        }
      }
    `}
`;

export const ITEMS_PER_PAGE = 20;

interface ITableRowProps {
  selectable: boolean;
  disabled?: boolean;
  $isRemembered?: boolean;
}

export const TableRow = styled.tr<ITableRowProps>`
  ${({ selectable, theme }) =>
    selectable &&
    css`
      &:hover {
        & > td {
          background-color: ${theme.colors.MAIN_250} !important;
          cursor: pointer;
        }
      }
    `}

  ${({ theme, $isRemembered }) =>
    $isRemembered &&
    css`
      td {
        background-color: ${theme.colors.MAIN_100} !important;
        color: ${theme.colors.OPPOSITE_MAIN_500} !important;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      & > td {
        pointer-events: none;
        opacity: 0.5;
      }
    `}
`;

export type THeaderKey = {
  content: React.ReactNode;
  query_key?: string;
  width?: string;
  colSpan?: number;
  sort_by_key?: string;
};

export enum SortByType {
  None = "NONE",
  Ascending = "ASC",
  Descending = "DESC",
}

export type TSortBy = {
  key: string;
  type: SortByType;
};

interface IProps {
  rows: TRow[];
  headerKeys: THeaderKey[];
  includeInQuery?: string[];
  query?: string;
  loading?: boolean;
  loadingText?: string;
  customRowHeight?: number;
  onSelectRow?(row: TRow): void;
  onSortByChange?(sortBy: TSortBy): void;
  emptyColSpan?: number;
}

export const Table = ({
  query,
  includeInQuery,
  headerKeys,
  rows,
  loading,
  loadingText,
  customRowHeight,
  onSelectRow,
  onSortByChange,
  emptyColSpan,
}: IProps): React.ReactElement<IProps> => {
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState<TRow[]>([]);
  const maxPage = Math.ceil(filteredRows.length / ITEMS_PER_PAGE);
  const colors = useColors();
  const ROW_HEIGHT = customRowHeight || 35;

  const [sortBy, setSortBy] = useState<TSortBy>({
    type: SortByType.None,
    key: "",
  });

  const setFilteredRowsRef =
    useRef<
      (
        rows: TRow[],
        headerKeys: THeaderKey[],
        query?: string,
        includeInQuery?: string[]
      ) => void
    >();

  setFilteredRowsRef.current = useCallback(
    (
      rows: TRow[],
      headerKeys: THeaderKey[],
      query?: string,
      includeInQuery?: string[]
    ) => {
      if (query && query.length > 0) {
        setFilteredRows(
          filterRowsByQuery(rows, query, headerKeys, includeInQuery)
        );
      } else {
        setFilteredRows(rows);
      }
    },
    [rows.length]
  );

  useEffect(() => {
    if (rows.length === 0) {
      setFilteredRows(rows);
      return;
    }

    setFilteredRowsRef.current?.(rows, headerKeys, query, includeInQuery);
  }, [rows, query, headerKeys, includeInQuery]);

  const nextPage = () => {
    if (page + 2 > maxPage) {
      return;
    }
    setPage((p) => p + 1);
  };

  const previousPage = () => {
    if (page - 1 < 0) {
      return;
    }
    setPage((p) => p - 1);
  };

  const filteredRowsToRender = filteredRows
      .slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE)
      .map((r, ri) => (
        <TableRow
          disabled={r.disabled}
          key={ri}
          selectable={!!onSelectRow}
          onClick={() => onSelectRow?.(r)}
        >
          {r.cells.map((c, ci) => (
            <td
              colSpan={c.colSpan}
              key={ci}
              style={{
                width: c.width,
              }}
            >
              {c.content}
            </td>
          ))}
        </TableRow>
      )),
    arr = new Array(ITEMS_PER_PAGE - filteredRowsToRender.length).fill(null),
    rowsToRender =
      filteredRowsToRender.length < ITEMS_PER_PAGE
        ? [
            ...filteredRowsToRender,
            ...arr.map((_, i) => (
              <tr
                key={`empty-row-${i}`}
                style={{
                  height: ROW_HEIGHT,
                }}
              >
                <td
                  colSpan={emptyColSpan || headerKeys.length}
                  style={{
                    backgroundColor:
                      filteredRowsToRender.length % 2 === 0
                        ? colors.MAIN_400
                        : colors.MAIN_350,
                  }}
                />
              </tr>
            )),
          ]
        : filteredRowsToRender;

  const handleSortBy = (key: string) => {
    if (sortBy.key === key) {
      if (sortBy.type === SortByType.Descending) {
        setSortBy({
          key,
          type: SortByType.Ascending,
        });
      } else if (sortBy.type === SortByType.Ascending) {
        setSortBy({
          key: "",
          type: SortByType.None,
        });
      }
    } else {
      setSortBy({
        key,
        type: SortByType.Descending,
      });
    }
  };

  useEffect(() => {
    onSortByChange?.(sortBy);
  }, [sortBy.type, sortBy.key]);

  return (
    <StyledTable>
      <thead>
        <tr>
          {headerKeys.map((key, i) => (
            <TableHeadCell
              colSpan={key.colSpan}
              canSort={!!key.sort_by_key}
              key={i}
              style={{
                width: key.width,
                cursor: key.sort_by_key ? "pointer" : "default",
              }}
              onClick={
                key.sort_by_key
                  ? () => key.sort_by_key && handleSortBy(key.sort_by_key)
                  : undefined
              }
            >
              <FlexCenterAlign
                style={{
                  position: "relative",
                }}
              >
                {key.content}

                {key.sort_by_key && (
                  <div
                    className="sort-by"
                    style={{
                      marginLeft: 8,
                      opacity: sortBy.key === key.sort_by_key ? 1 : undefined,
                    }}
                  >
                    {sortBy.type === SortByType.Ascending ? (
                      <FiChevronUp size={24} />
                    ) : (
                      <FiChevronDown size={24} />
                    )}
                  </div>
                )}
              </FlexCenterAlign>
            </TableHeadCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td
              style={{
                padding: "120px 0",
                textAlign: "center",
                fontSize: 18,
                color: colors.OPPOSITE_MAIN_150,
              }}
              colSpan={headerKeys.length}
            >
              <div>
                <Loading />
              </div>
              <div
                style={{
                  marginTop: 16,
                }}
              >
                {loadingText || "Načítání položek"}
              </div>
            </td>
          </tr>
        ) : !filteredRows.length || !rows.length ? (
          <tr>
            <td
              style={{
                padding: "60px 0",
                textAlign: "center",
                fontSize: 18,
                color: colors.OPPOSITE_MAIN_150,
              }}
              colSpan={emptyColSpan || headerKeys.length}
            >
              <NoResultsFound />
            </td>
          </tr>
        ) : (
          rowsToRender
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={headerKeys.length <= 3 ? 2 : 3}>
            <div
              style={{
                fontSize: 14,
                color: colors.OPPOSITE_MAIN_150,
              }}
            >
              Počet výsledků:{" "}
              <span
                style={{
                  fontWeight: 500,
                }}
              >
                {filteredRows.length}
              </span>
            </div>
          </td>
          <td colSpan={headerKeys.length - 3}>
            <FlexCenterAlign
              style={{
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                data-title="Předchozí stránka"
                onClick={previousPage}
                style={{
                  padding: 4,
                }}
                disabled={page - 1 < 0}
              >
                <MdNavigateBefore size={28} color={colors.MAIN_150} />
              </IconButton>
              <div
                style={{
                  margin: "0 40px",
                  color: colors.OPPOSITE_MAIN_150,
                  fontSize: 13,
                  textAlign: "center",
                }}
              >
                stránka {page + 1} z {maxPage === 0 ? 1 : maxPage}
              </div>
              <IconButton
                data-title="Další stránka"
                onClick={nextPage}
                style={{
                  marginRight: 40,
                  padding: 4,
                }}
                disabled={page + 2 > maxPage}
              >
                <MdNavigateNext size={28} color={colors.MAIN_150} />
              </IconButton>
            </FlexCenterAlign>
          </td>
        </tr>
      </tfoot>
    </StyledTable>
  );
};

export default Table;
