import React from "react";
import { useDispatch } from "react-redux";
import { useAppConfig } from "../common/redux-hooks";
import {
  SET_APP_CONFIG
} from "../redux/actions/appConfigActions";
import Dropdown from "../ui/dropdown";

interface IProps {}

export const GlobalLanguageSelector =
  ({}: IProps): React.ReactElement<IProps> => {
    const { store, language } = useAppConfig();
    const dispatch = useDispatch();

    return (
      <div>
        <Dropdown
          list={store.languages.map((l) => ({
            content: l.title,
            value: l,
            unique_id: l._id,
          }))}
          onSelectValue={(language) =>
            dispatch(SET_APP_CONFIG({ language, store }))
          }
          selectedUniqueId={language._id}
          isDeleteButtonDisabled
        />
      </div>
    );
  };

export default GlobalLanguageSelector;
