import axios from "../axios";

interface IStatusResponse {
  hasDefaultStore: boolean;
  hasDefaultLanguage: boolean;
  hasDefaultAdmin: boolean;
  hasDefaultCurrency: boolean;
}

export interface IstatusModel {
  getStatus: () => Promise<IStatusResponse | "OK">;
}

export const statusModel: IstatusModel = {
  getStatus() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IStatusResponse>(`/status`);

        res(response.data);
      } catch (err: any) {
        if (err?.response?.data?.hasDefaultStore !== undefined) {
          res(err.response.data);
          return;
        }

        rej(err);
      }
    });
  },
};
