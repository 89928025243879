import React from "react";
import { EditButton, HeaderText } from "../../ui/common";
import ViewWrapper from "../view-wrapper";
import Table, { TRow } from "../../ui/table";
import { useLanguages, useStores } from "../../common/redux-hooks";
import styled from "styled-components";

interface IProps {}

const TableWrapper = styled.div`
  width: 75%;
`;

export const TermsAndConditions = ({}: IProps): React.ReactElement<IProps> => {
  const stores = useStores();

  const rows: TRow[] = stores.map((store) => ({
    cells: [
      {
        key: "actions",
        value: "actions",
        content: (
          <>
            <EditButton
              data-title="Upravit obchodní podmínky"
              path={`/terms-and-conditions/edit/${store.key}`}
            />
          </>
        ),
      },
      {
        key: "title",
        value: store.title + " - " + store.key.toUpperCase(),
        content: store.title + " - " + store.key.toUpperCase(),
      },
      {
        key: "termsAndConditions",
        value: store.termsAndConditions ? "Ano" : "Ne",
        content: store.termsAndConditions ? "Ano" : "Ne",
      },
    ],
  }));

  return (
    <ViewWrapper>
      <HeaderText style={{
        marginBottom: 20
      }}>Obchodní podmínky</HeaderText>
      <TableWrapper>
        <Table
          headerKeys={[
            { content: "", width: "50px" },
            { content: "Eshop" },
            { content: "Má obchodní podmínky" },
          ]}
          rows={rows}
        />
      </TableWrapper>
    </ViewWrapper>
  );
};

export default TermsAndConditions;
