import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";
import { ILanguageID } from "../../types/language";
import { IStoreID } from "../../types/store";
import { IUserDB } from "../../types/user";
import { ICurrencyID } from "../../types/currency";

interface IAdminRegistration {
  registrationKey: string;
  store: IStoreID | null;
  currency: ICurrencyID | null;
  language: ILanguageID | null;
  user: IUserDB | null;
}

interface IAdminRegistrationContext {
  adminRegistration: IAdminRegistration;
  setAdminRegistration: Dispatch<SetStateAction<IAdminRegistration>>;
}

export const AdminRegistrationContext =
  React.createContext<IAdminRegistrationContext>(
    {} as IAdminRegistrationContext
  );

export const useAdminRegistration = () => {
  return React.useContext(AdminRegistrationContext);
};

export const AdminRegistrationProvider = ({
  children,
}: PropsWithChildren): React.ReactElement<PropsWithChildren> => {
  const [adminRegistration, setAdminRegistration] =
    useState<IAdminRegistration>({
      registrationKey: "",
      currency: null,
      language: null,
      store: null,
      user: null,
    });

  return (
    <AdminRegistrationContext.Provider
      value={{ adminRegistration, setAdminRegistration }}
    >
      {children}
    </AdminRegistrationContext.Provider>
  );
};
