import { HiExternalLink } from "react-icons/hi";
import styled from "styled-components";
import { getManufacturerName } from "../../../../common/functions";
import { useLanguageContext } from "../../../../common/languange-context";
import {
  useAppConfig,
  useLanguages,
  useManufacturers,
  useStores,
} from "../../../../common/redux-hooks";
import {
  NonTranslatedString,
  nonTranslatedString,
} from "../../../../constants/language";
import { slugModel } from "../../../../model/query/slugModel";
import { useModal } from "../../../../providers/modal/modal-provider";
import { useColors } from "../../../../providers/theme/theme-provider";
import { useSelector } from "../../../../redux/store";
import { TReduxError } from "../../../../types/error";
import { ImageType } from "../../../../types/gallery";
import { IEditorProduct, IVariantProduct } from "../../../../types/products";
import { defaultSlug } from "../../../../types/slugs";
import Button from "../../../../ui/button";
import Checkbox from "../../../../ui/checkbox";
import {
  DeleteButton,
  EditorImage,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  LinkTo,
} from "../../../../ui/common";
import Dropdown from "../../../../ui/dropdown";
import RichTextEditor from "../../../../ui/editorTinyMce";
import GalleryPicker from "../../../../ui/gallery-picker";
import IconButton from "../../../../ui/icon-button";
import {
  InputTitle,
  InputWithTitle,
  InputWithTitleWrapper,
} from "../../../../ui/input-with-title";
import LazyLoadingGalleryImage from "../../../../ui/lazy-loading-image";
import SlugInput from "../../../../ui/slug-input";
import ErrorTooltip from "../../../../ui/tooltips/error-tooltip";
import ImageUploadModal from "../../../gallery/image-upload-modal";
import { ProductSearchModal } from "../../product-search";
import { useProductEditorErrors } from "../error-context";
import PackagingInput from "./packaging-input";
import ParameterGroupsInput from "./parameter-groups-input";
import ParametersInput from "./parameters-input/parameters-input";
import ProductCategories from "./product-categories";
import ProductGallery from "./product-gallery";
import DropdownMultiSelect from "../../../../ui/dropdown-multi-select";

interface IProductEditorComponentSharedProps {
  product: IEditorProduct;
  setProduct: React.Dispatch<React.SetStateAction<IEditorProduct>>;
}

interface IBasicInfoProps extends IProductEditorComponentSharedProps {}

export const BasicInfo = ({ product, setProduct }: IBasicInfoProps) => {
  const { store } = useAppConfig();
  const language = useLanguageContext();
  const locale = language.locale;
  const errors = useProductEditorErrors();
  const colors = useColors();
  const stores = useStores();

  return (
    <Flex
      style={{
        alignItems: "flex-start",
      }}
    >
      <InputsWrapper>
        <InputWithTitle
          inputProps={{
            error: errors.ean,
          }}
          wrapperStyle={{
            marginTop: 0,
          }}
          inputStyle={{
            width: 320,
          }}
          value={product.ean}
          setValue={(ean) => setProduct((p) => ({ ...p, ean }))}
          title="EAN"
        />

        <InputWithTitle
          info="Vlastní kód obchodu (např. HAB123)"
          required
          inputProps={{
            error: errors.eshop_id || errors.eshop_id_exists,
          }}
          inputStyle={{
            width: 320,
          }}
          value={product.eshop_id}
          setValue={(eshop_id) => setProduct((p) => ({ ...p, eshop_id }))}
          title="Kód"
        />

        <InputWithTitle
          inputProps={{
            placeholder: !product.name[locale]
              ? nonTranslatedString
              : undefined,
            error: errors.name,
          }}
          inputStyle={{
            width: 320,
          }}
          value={product.name[locale] || ""}
          setValue={(name) =>
            setProduct((p) => ({
              ...p,
              name: { ...p.name, [locale]: name },
            }))
          }
          title="Název"
        />

        <InputWithTitle
          inputStyle={{
            width: 320,
          }}
          value={product.shortName?.[locale] || ""}
          setValue={(shortName) =>
            setProduct((p) => ({
              ...p,
              shortName: { ...p.shortName, [locale]: shortName },
            }))
          }
          title="Krátký název"
        />

        {product.slug && (
          <SlugInput
            error={
              errors.slug_value_empty ||
              errors.slug_value_invalid ||
              errors.slug_already_exists
            }
            slug={product.slug[locale] || defaultSlug}
            setSlug={(slug) =>
              setProduct((p) => ({
                ...p,
                slug: {
                  ...p.slug,
                  [locale]: slug,
                },
              }))
            }
            value={product.name[locale] || ""}
            getAvailableSlug={async () => {
              if (!product.name[locale]) {
                return defaultSlug;
              }

              return await slugModel.getAvailableSlug(
                product.name[locale],
                locale,
                product._id
              );
            }}
          />
        )}

        <InputWithTitle
          value={
            product.subproducts.length > 0 ? "(Nelze definovat)" : product.stock
          }
          dataTitle={
            product.subproducts.length > 0
              ? "Počet kusů skladem nelze definovat jelikož produkt obsahuje podprodukty"
              : undefined
          }
          inputProps={{
            type: "number",
            disabled: product.subproducts.length > 0,
          }}
          setValue={(stock) => {
            setProduct((p) => ({ ...p, stock: stock as number }));
          }}
          title="Počet kusů skladem"
        />
        <InputWithTitle
          value={product.buy_price[locale] || ""}
          inputProps={{
            type: "number",
            error: errors.buy_price,
          }}
          setValue={(buy_price) =>
            setProduct((p) => ({
              ...p,
              buy_price: { ...p.buy_price, [locale]: buy_price as number },
            }))
          }
          title="Nákupní cena"
          unit={language.currency.symbol}
        />
        <InputWithTitle
          value={product.price_vat[locale] || ""}
          inputProps={{
            type: "number",
            error: errors.price_vat,
          }}
          setValue={(price_vat) =>
            setProduct((p) => ({
              ...p,
              price_vat: { ...p.price_vat, [locale]: price_vat as number },
            }))
          }
          title="Prodávací cena (vč. DPH)"
          unit={language.currency.symbol}
        />

        <InputWithTitle
          value={product.sale_price?.[locale] || ""}
          inputProps={{
            type: "number",
          }}
          setValue={(sale_price) =>
            setProduct((p) => ({
              ...p,
              sale_price: { ...p.sale_price, [locale]: sale_price as number },
            }))
          }
          title="Akční cena (vč. DPH)"
          unit={language.currency.symbol}
        />
      </InputsWrapper>
      <InputsWrapper
        style={{
          marginLeft: 12,
        }}
      >
        <InputWithTitleWrapper style={{ marginTop: 0 }}>
          <InputTitle>Dostupné pro obchody</InputTitle>
          <DropdownMultiSelect
            list={stores.map((c) => ({
              content: c.title,
              value: c,
              query: c.title,
              unique_id: c._id,
              disabled: product.stores.some((s) => s._id === c._id),
            }))}
            onSelectValue={(store) =>
              setProduct((product) => ({
                ...product,
                stores: [...product.stores, store],
              }))
            }
            selectedDropdownIDs={product.stores.map((store) => store._id)}
            onRemoveUniqueID={(id) =>
              setProduct((product) => ({
                ...product,
                stores: product.stores.filter((s) => s._id !== id),
              }))
            }
          />
        </InputWithTitleWrapper>
        <FlexCenterAlign style={{ marginTop: 16 }}>
          <Checkbox
            value={product.available_in_stores.some(
              (productStore) => productStore._id === store._id
            )}
            setValue={(value) =>
              setProduct((p) => ({
                ...p,
                available_in_stores: value
                  ? [...p.available_in_stores, store]
                  : p.available_in_stores.filter(
                      (store) => store._id !== store._id
                    ),
              }))
            }
          />
          <div
            style={{
              marginLeft: 12,
              fontWeight: 400,
              fontSize: 13,
            }}
          >
            Viditelné v obchodě{" "}
            <span
              style={{
                fontWeight: 500,
              }}
            >
              {store.title}
            </span>
          </div>
        </FlexCenterAlign>
        <FlexCenterAlign
          style={{
            marginTop: 12,
          }}
        >
          <Checkbox
            value={product.isInExternalStock || false}
            setValue={(isInExternalStock) =>
              setProduct((p) => ({
                ...p,
                isInExternalStock,
              }))
            }
          />
          <div
            style={{
              marginLeft: 12,
              fontWeight: 400,
              fontSize: 13,
            }}
          >
            Skladem u dodavatele
          </div>
        </FlexCenterAlign>
        <FlexCenterAlign
          style={{
            alignItems: "flex-end",
          }}
        >
          <InputWithTitle
            info="Odkaz na Aliexpress nebo něco podobného"
            inputStyle={{
              width: 320,
            }}
            optional
            value={product.external_link || ""}
            setValue={(external_link) =>
              setProduct((p) => ({ ...p, external_link }))
            }
            title="Externí odkaz"
            inputAdditionalNode={
              <div
                data-title={
                  product.external_link &&
                  !product.external_link.startsWith("https://")
                    ? "Odkaz musí začínat s https://"
                    : ""
                }
              >
                <IconButton
                  disabled={
                    !product.external_link ||
                    !product.external_link.startsWith("https://")
                  }
                  data-title={
                    product.external_link
                      ? !product.external_link.startsWith("https://")
                        ? "Odkaz musí začínat s https://"
                        : "Otevřít v novém okně"
                      : ""
                  }
                  onClick={() => window.open(product.external_link)}
                  style={{
                    backgroundColor: colors.MAIN_300,
                    color: colors.OPPOSITE_MAIN_500,
                  }}
                  hoverBackgroundColor={colors.MAIN_200}
                >
                  <HiExternalLink />
                </IconButton>
              </div>
            }
          />
        </FlexCenterAlign>

        {/* <FlexCenterAlign style={{ marginTop: 24 }}>
          <Checkbox
            value={product.translation_needed}
            setValue={(translation_needed) =>
              setProduct((p) => ({ ...p, translation_needed }))
            }
          />
          <div
            style={{
              marginLeft: 12,
              fontWeight: 400,
              fontSize: 13,
            }}
          >
            Je potřeba překlad
          </div>
        </FlexCenterAlign> */}
      </InputsWrapper>
    </Flex>
  );
};

export const Manufacturer = ({
  product,
  setProduct,
}: IProductEditorComponentSharedProps) => {
  const manufacturers = useManufacturers();
  const language = useLanguageContext();

  return (
    <div>
      <div
        style={{
          fontSize: 13,
          marginBottom: 4,
          marginLeft: 4,
        }}
      >
        Vyberte výrobce
      </div>
      <Dropdown
        list={manufacturers.map((m) => ({
          content: getManufacturerName(m, language.locale) || (
            <NonTranslatedString />
          ),
          value: m,
          unique_id: m._id,
        }))}
        onSelectValue={(manufacturer) =>
          setProduct((p) => ({ ...p, manufacturer }))
        }
        selectedUniqueId={product.manufacturer?._id}
      />
    </div>
  );
};

export const Packaging = ({
  product,
  setProduct,
}: IProductEditorComponentSharedProps) => {
  return (
    <div>
      <PackagingInput
        packaging={product.packaging}
        setPackaging={(packaging) => setProduct((p) => ({ ...p, packaging }))}
      />
    </div>
  );
};

export const Parameters = ({
  product,
  setProduct,
}: IProductEditorComponentSharedProps) => {
  return (
    <div>
      <ParametersInput
        params={product.params}
        setParams={(params) => setProduct((p) => ({ ...p, params }))}
      />
      <div
        style={{
          marginTop: 16,
        }}
      >
        <ParameterGroupsInput
          parameterGroups={product.parameter_groups}
          setParameterGroups={(parameter_groups) =>
            setProduct((p) => ({ ...p, parameter_groups }))
          }
        />
      </div>
    </div>
  );
};

export const RelatedProducts = ({
  product,
  setProduct,
}: IProductEditorComponentSharedProps) => {
  const language = useLanguageContext(),
    { createModal } = useModal();

  const onAddRelatedProductClick = () => {
    createModal(
      <ProductSearchModal
        onSelect={(product) =>
          setProduct((p) => ({
            ...p,
            related_products: [
              ...p.related_products,
              {
                products: [
                  {
                    _id: product._id,
                    name: product.name,
                    image: product.image,
                  },
                ],
                generated: false,
              },
            ],
          }))
        }
        disabledIDs={product.related_products.map((v) => v.products[0]._id)}
      />
    );
  };

  return (
    <div>
      <div>
        {product.related_products.map((v) => (
          <Flex
            style={{
              marginTop: 8,
              alignItems: "flex-start",
            }}
          >
            <InputsWrapper
              style={{
                position: "relative",
              }}
            >
              {v.generated && (
                <div
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    fontSize: 12,
                    opacity: 0.4,
                  }}
                >
                  Automaticky generované
                </div>
              )}
              <Flex
                style={{
                  justifyContent: "space-between",
                }}
              >
                <FlexCenterAlign>
                  <FlexCenterAll
                    style={{
                      width: 128,
                    }}
                  >
                    <LazyLoadingGalleryImage
                      style={{
                        maxWidth: 96,
                        maxHeight: 96,
                      }}
                      src={v.products[0].image?.url}
                    />
                  </FlexCenterAll>

                  <div
                    style={{
                      fontSize: 14,
                      marginLeft: 8,
                      width: 300,
                    }}
                  >
                    {v.products[0].name[language.locale] || (
                      <NonTranslatedString />
                    )}
                  </div>
                </FlexCenterAlign>
                <FlexCenterAlign>
                  <LinkTo
                    data-title="Otevřít produkt v novém okně"
                    onClick={() =>
                      window.open(
                        `/products/edit-product/${v.products[0]._id}`,
                        "_blank"
                      )
                    }
                  />
                </FlexCenterAlign>
              </Flex>
            </InputsWrapper>
            {!v.generated && (
              <DeleteButton
                style={{
                  marginLeft: 8,
                }}
                data-title="Odstranit společný produkt"
                onClick={() =>
                  setProduct((p) => ({
                    ...p,
                    related_products: p.related_products.filter(
                      (_p) => _p.products[0]._id !== v.products[0]._id
                    ),
                  }))
                }
              />
            )}
          </Flex>
        ))}
      </div>
      <div
        style={{
          marginTop: 12,
        }}
      >
        <Button onClick={onAddRelatedProductClick}>
          Přidat společný produkt
        </Button>
      </div>
    </div>
  );
};

interface IVariantsEditorComponent extends IProductEditorComponentSharedProps {
  objectKey: "variants" | "related_products";
}

export const Variants = ({
  product,
  setProduct,
  objectKey,
}: IVariantsEditorComponent) => {
  const language = useLanguageContext(),
    { createModal } = useModal();

  const disabledProductIDs: string[] = ([] as IVariantProduct[])
    .concat(...product[objectKey].map((v) => v.products))
    .map((p) => p._id);

  if (product._id) {
    disabledProductIDs.push(product._id);
  }

  const onAddVariantClick = () => {
    createModal(
      <ProductSearchModal
        onSelect={(product) =>
          setProduct((p) => ({
            ...p,
            [objectKey]: [
              ...p[objectKey],
              {
                products: [
                  {
                    _id: product._id,
                    name: product.name,
                    image: product.image,
                  },
                ],
                generated: false,
              },
            ],
          }))
        }
        disabledIDs={disabledProductIDs}
      />
    );
  };

  return (
    <div>
      <div>
        {product[objectKey].map((v, i) => (
          <Flex
            key={i}
            style={{
              marginTop: 8,
              alignItems: "flex-start",
            }}
          >
            <InputsWrapper
              style={{
                position: "relative",
              }}
            >
              {v.generated && (
                <div
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    fontSize: 12,
                    opacity: 0.4,
                  }}
                >
                  Automaticky generované
                </div>
              )}
              {v.products.map((p, i) => (
                <Flex
                  key={`p-${i}`}
                  style={{
                    justifyContent: "space-between",
                    marginTop: i !== 0 ? 12 : 0,
                  }}
                >
                  <FlexCenterAlign>
                    <FlexCenterAll
                      style={{
                        width: 128,
                      }}
                    >
                      <LazyLoadingGalleryImage
                        style={{
                          maxWidth: 96,
                          maxHeight: 96,
                        }}
                        src={p.image?.url}
                      />
                    </FlexCenterAll>

                    <div
                      style={{
                        fontSize: 14,
                        marginLeft: 8,
                        width: 300,
                      }}
                    >
                      {p.name[language.locale] || <NonTranslatedString />}
                    </div>
                  </FlexCenterAlign>
                  <FlexCenterAlign>
                    <LinkTo
                      data-title="Otevřít produkt v novém okně"
                      onClick={() =>
                        window.open(`/products/edit-product/${p._id}`, "_blank")
                      }
                    />
                  </FlexCenterAlign>
                </Flex>
              ))}
            </InputsWrapper>
            {!v.generated && (
              <DeleteButton
                style={{
                  marginLeft: 8,
                }}
                data-title={`Odstranit ${
                  objectKey === "variants"
                    ? "variantu produkt"
                    : "související produkt"
                }`}
                onClick={() =>
                  setProduct((p) => ({
                    ...p,
                    [objectKey]: p[objectKey].filter(
                      (_p) => _p.products[0]._id !== v.products[0]._id
                    ),
                  }))
                }
              />
            )}
          </Flex>
        ))}
      </div>
      <div
        style={{
          marginTop: 12,
        }}
      >
        <Button onClick={onAddVariantClick}>
          Přidat{" "}
          {objectKey === "variants"
            ? "variantu produktu"
            : "související produkt"}
        </Button>
      </div>
    </div>
  );
};

export const Categories = ({
  product,
  setProduct,
}: IProductEditorComponentSharedProps) => {
  const { populatedCategories } = useSelector(({ categories }) => categories);

  return (
    <div
      style={{
        minWidth: 450,
      }}
    >
      <ProductCategories
        productCategories={product.categories}
        categories={populatedCategories}
        setCategories={(categories) =>
          setProduct((p) => ({ ...p, categories }))
        }
      />
    </div>
  );
};

const FileInputWrapper = styled.div`
  margin-left: 8px;
  button {
    font-size: 11px;
  }
`;

export const Gallery = ({
  product,
  setProduct,
}: IProductEditorComponentSharedProps) => {
  const errors = useProductEditorErrors();
  const { createModal } = useModal();

  const uploadNewMainPhoto = () => {
    createModal(
      <ImageUploadModal
        onSuccess={(images) => setProduct((p) => ({ ...p, image: images[0] }))}
        defaultName={product.eshop_id}
        multiple={false}
        disableSelectType
        type={ImageType.Product}
      />
    );
  };

  return (
    <div>
      <InputsWrapper>
        <Flex>
          <div>
            <FlexCenterAlign
              style={{
                marginBottom: 12,
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  opacity: 0.75,
                  marginRight: 12,
                }}
              >
                Hlavní foto
              </div>
              <GalleryPicker
                title={"Vybrat fotografii z galerie"}
                onChoose={(image) => setProduct((p) => ({ ...p, image }))}
                type={ImageType.Product}
                error={errors.image}
              />
              <FileInputWrapper>
                <Button onClick={uploadNewMainPhoto}>Nahrát fotografii</Button>
              </FileInputWrapper>
            </FlexCenterAlign>
            {product.image && (
              <Flex>
                <EditorImage
                  src={product.image.url}
                  onDeleteClick={() =>
                    setProduct((p) => ({
                      ...p,
                      image: null,
                    }))
                  }
                />
              </Flex>
            )}
          </div>
        </Flex>
        <ProductGallery
          product={product}
          setProduct={setProduct}
          addImage={(image) =>
            setProduct((p) => ({
              ...p,
              gallery: [...p.gallery, image],
            }))
          }
          removeImage={(image) => {
            setProduct((p) => ({
              ...p,
              gallery: p.gallery.filter((i) => image._id !== i._id),
            }));
          }}
          gallery={[...product.gallery]}
        />
      </InputsWrapper>
    </div>
  );
};

interface IProductEditorDescriptionProps
  extends IProductEditorComponentSharedProps {
  error?: TReduxError;
}

export const Description = ({
  product,
  setProduct,
  error,
}: IProductEditorDescriptionProps) => {
  const language = useLanguageContext();

  return (
    <InputsWrapper
      style={{
        width: "100%",
      }}
    >
      <FlexCenterAlign
        style={{
          marginBottom: 12,
          marginLeft: 10,
        }}
      >
        <div
          style={{
            fontSize: 18,
            opacity: 0.6,
          }}
        >
          Popis produktu
        </div>
        <ErrorTooltip error={error} />
      </FlexCenterAlign>
      <div
        style={{
          width: "100%",
          minWidth: 1000,
        }}
      >
        <RichTextEditor
          onEditorChange={(description) => {
            setProduct((p) => ({
              ...p,
              description: {
                ...p.description,
                [language.locale]: description,
              },
            }));
          }}
          value={product.description[language.locale] || ""}
        />
      </div>
    </InputsWrapper>
  );
};
