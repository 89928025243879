import { TGetError } from "../../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../../types/error";
import { IEditorOrder } from "./order-editor";

const getErrorOfKey = (
  key: string,
  getError: TGetError,
  condition: boolean
) => {
  const err = getError(key, ErrorType.ORDER);

  if (err && condition) {
    return err;
  }

  return undefined;
};

export type TOrderEditorErrorsObject = {
  title_empty?: TReduxError;
  symbol_empty?: TReduxError;
};

type TOrderEditorErrors = {
  errors: TOrderEditorErrorsObject;
  isValid: boolean;
};

export const getOrderEditorErrors = (
  getError: TGetError,
  order: IEditorOrder
): TOrderEditorErrors => {
  return {
    errors: {},
    isValid: true,
  };
};
