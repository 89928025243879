import React, { useState } from "react";
import {
  useParameterGroups
} from "../../../../common/redux-hooks";
import { useModal } from "../../../../providers/modal/modal-provider";
import { useColors } from "../../../../providers/theme/theme-provider";
import {
  IParameterGroupID,
  IProductParameterGroup
} from "../../../../types/parameter";
import {
  DeleteButton,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
} from "../../../../ui/common";
import Dropdown from "../../../../ui/dropdown";
import { Button } from "./../../../../ui/button";
import { InputsWrapper } from "./../../../../ui/common";
import { ParameterInput } from "./parameters-input/parameters-input";

interface ISelectParameterGroupModalProps {
  selectedGroups: string[];
  onSelectGroup(group: IParameterGroupID): void;
}

const SelectParameterGroupModal = ({
  selectedGroups,
  onSelectGroup,
}: ISelectParameterGroupModalProps): React.ReactElement<ISelectParameterGroupModalProps> => {
  const [selectedGroup, setSelectedGroup] = useState<IParameterGroupID | null>(
    null
  );

  const groups = useParameterGroups();
  const colors = useColors();
  const { closeModal } = useModal();

  return (
    <div
      style={{
        paddingRight: 50,
        paddingTop: 10,
      }}
    >
      <div
        style={{
          marginLeft: 4,
          fontSize: 13,
          marginBottom: 4,
        }}
      >
        Vyberte parametrovou skupinu
      </div>
      <Dropdown
        list={groups.map((g) => ({
          content: g.title,
          value: g,
          query: g.title,
          unique_id: g._id,
          disabled: selectedGroups.includes(g._id),
        }))}
        selectedUniqueId={selectedGroup?._id}
        onSelectValue={(group) => {
          setSelectedGroup(group);
        }}
      />
      <FlexCenterAll
        style={{
          marginTop: 16,
        }}
      >
        <Button
          style={{
            marginRight: 12,
            backgroundColor: colors.MAIN_300,
          }}
          hoverBackgroundColor={colors.MAIN_250}
          onClick={() => {
            if (selectedGroup) {
              onSelectGroup(selectedGroup);
            }
            closeModal();
          }}
        >
          OK
        </Button>
        <Button onClick={closeModal}>Storno</Button>
      </FlexCenterAll>
    </div>
  );
};

interface IProps {
  parameterGroups: IProductParameterGroup[];
  setParameterGroups(parameterGroups: IProductParameterGroup[]): void;
}

export const ParameterGroupsInput = ({
  parameterGroups,
  setParameterGroups,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors(),
    { createModal } = useModal();

  const addParameterGroupClick = () => {
    createModal(
      <SelectParameterGroupModal
        selectedGroups={parameterGroups.map((g) => g.group._id)}
        onSelectGroup={(group) =>
          setParameterGroups([
            ...parameterGroups,
            {
              group,
              parameters: group.parameters.map((p) => ({
                parameter: p,
              })),
            },
          ])
        }
      />
    );
  };

  return (
    <Flex>
      <InputsWrapper>
        {parameterGroups.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              marginBottom: 16,
              padding: "0 16px",
            }}
          >
            <div
              style={{
                marginBottom: 6,
                opacity: 0.6,
                fontSize: 13,
              }}
            >
              Nebyly nalezeny žádné parametrové skupiny
            </div>
            <div
              style={{
                opacity: 0.8,
                fontSize: 11,
              }}
            >
              Přidejte parametrovou skupinu stisknutím tlačítka
            </div>
          </div>
        ) : (
          <div>
            {parameterGroups.map(({ group, parameters }, i) => (
              <InputsWrapper key={i}>
                <Flex
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <FlexCenterAlign
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 8,
                        marginRight: 8,
                      }}
                    >
                      {group.title}
                    </div>
                    <DeleteButton
                      onClick={() =>
                        setParameterGroups(
                          parameterGroups.filter(
                            (_g) => _g.group._id !== group._id
                          )
                        )
                      }
                      data-title="Odstranit skupinu"
                    />
                  </FlexCenterAlign>
                  <div
                    style={{
                      fontSize: 10,
                      opacity: 0.5,
                      marginBottom: 12,
                    }}
                  >
                    {group.generateVariants && (
                      <div>* varianty budou generovány</div>
                    )}
                    {group.generateRelatedProducts && (
                      <div>* související produkty budou generovány</div>
                    )}
                  </div>
                </Flex>

                {parameters.map((p, i) => (
                  <ParameterInput
                    key={`p-${i}`}
                    parameterGroupId={group._id}
                    parameter={p}
                    setParameter={(p) => {
                      setParameterGroups(
                        (() => {
                          const index = parameterGroups.findIndex(
                              (_g) => _g.group._id === group._id
                            ),
                            groups = [...parameterGroups];

                          if (index !== -1) {
                            const parameterIndex = groups[
                              index
                            ].parameters.findIndex(
                              (_p) => _p.parameter === p.parameter
                            );

                            groups[index].parameters[parameterIndex] = p;
                            return groups;
                          }

                          return parameterGroups;
                        })()
                      );
                    }}
                  />
                ))}
              </InputsWrapper>
            ))}
          </div>
        )}
        <FlexCenterAll
          style={{
            width: "100%",
            marginTop: 8,
          }}
        >
          <Button
            style={{
              backgroundColor: colors.MAIN_300,
              padding: "0.6em 1.5em",
              marginLeft: 12,
            }}
            hoverBackgroundColor={colors.MAIN_250}
            onClick={addParameterGroupClick}
          >
            Přidat parametrovou skupinu
          </Button>
        </FlexCenterAll>
      </InputsWrapper>
    </Flex>
  );
};

export default ParameterGroupsInput;
