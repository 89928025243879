import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { FlexCenterAlign, FlexCenterAlignBetween } from "./common";
import Input, { IInputProps } from "./input";
import InfoTooltip from "./tooltips/info-tooltip";
import { IValueWithUnitInputProps } from "./value-with-unit-input";

export const InputWithTitleWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 16px;
    padding: 8px;
    border: 1px solid ${theme.colors.MAIN_400};
    border-radius: ${theme.borderRadius / 2}px;
  `}
`;

export const InputTitle = styled.div`
  margin-left: 4px;
  margin-bottom: 4px;
  font-size: 13px;
`;

interface IInputWithTitleProps<T = string | number> {
  title: string;
  value: T;
  setValue: (value: T) => void;
  wrapperStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  inputProps?: Omit<IInputProps<T>, "value">;
  info?: React.ReactNode;
  infoWidth?: number;
  required?: boolean;
  optional?: boolean;
  valueWithUnitInputProps?: IValueWithUnitInputProps;
  unit?: string;
  dataTitle?: string;
  inputAdditionalNode?: React.ReactNode;
}

export const InputWithTitle = <T extends string | number>({
  title,
  value,
  setValue,
  wrapperStyle,
  titleStyle,
  inputStyle,
  inputProps,
  info,
  infoWidth,
  required,
  optional,
  unit,
  dataTitle,
  inputAdditionalNode,
}: IInputWithTitleProps<T>) => {
  return (
    <InputWithTitleWrapper style={wrapperStyle}>
      <InputTitle>
        <FlexCenterAlignBetween>
          <div style={titleStyle}>
            {title}

            {(required || optional) && (
              <span
                style={{
                  fontSize: 12,
                  opacity: 0.5,
                  marginLeft: "0.5em",
                  letterSpacing: 0.3,
                }}
              >
                {required ? "(povinné)" : "(nepovinné)"}
              </span>
            )}
          </div>
          <div
            style={{
              marginRight: inputProps?.error ? 26 : 0,
            }}
          >
            <InfoTooltip info={info} width={infoWidth} />
          </div>
        </FlexCenterAlignBetween>
      </InputTitle>
      <FlexCenterAlign style={wrapperStyle}>
        <Input
          data-title={dataTitle}
          style={inputStyle}
          value={value}
          setValue={(value: T) => setValue(value)}
          wrapperStyle={wrapperStyle}
          {...inputProps}
        />
        <div
          style={{
            fontSize: 12,
            marginLeft: 8,
          }}
        >
          {unit}
        </div>
        {inputAdditionalNode}
      </FlexCenterAlign>
    </InputWithTitleWrapper>
  );
};
