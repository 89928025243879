import React, { useCallback, useEffect, useState } from "react";
import RichTextEditor from "../../../ui/editorTinyMce";
import Button from "../../../ui/button";
import { emailsModel } from "../../../model/query/emailModel";
import { useAppConfig } from "../../../common/redux-hooks";
import {
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  HeaderText,
  InputsWrapper,
  RemoveButton,
  StyledLabel,
} from "../../../ui/common";
import Input from "../../../ui/input";
import { InputWithTitle } from "../../../ui/input-with-title";
import { ImageType } from "../../../types/gallery";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import BackButton from "../../components/back-button";
import { ErrorType } from "../../../types/error";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useSimplePopups } from "../../../providers/simple-popup/simple-popup-provider";
import { SimplePopupType } from "../../../providers/simple-popup/data";
import { AiOutlineSave, AiOutlineSend } from "react-icons/ai";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import { FiSend } from "react-icons/fi";
import { useColors } from "../../../providers/theme/theme-provider";
import { useNavigate, useParams } from "react-router-dom";
import { IEmailID } from "../../../types/email";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";

interface IProps {}

export const SendEmail = ({}: IProps): React.ReactElement<IProps> => {
  const { store } = useAppConfig();
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [testMailSend, setTestMailSend] = useState("lukaspavel0x@gmail.com");
  const [isTestButtonDisabled, setIsTestButtonDisabled] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const { createSimplePopup } = useSimplePopups();
  const { createConfirmation } = useConfirmation();
  const [subjectError, setSubjectError] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [editingEmail, setEditingEmail] = useState<IEmailID | null>(null);

  useEffect(() => {
    const fetchEmailById = async (id: string) => {
      const email = await emailsModel.getEmailById(id);

      if (!email) {
        navigate("/emails");
        return;
      }

      setEditingEmail(email);
      setContent(email.content);
      setSubject(email.subject);
    };

    if (params.id) {
      fetchEmailById(params.id);
    }
  }, [params]);

  const checkRequiredFields = (isForSave?: boolean) => {
    if (!subject) {
      createSimplePopup(
        "Předmět emailu musí být vyplněný",
        SimplePopupType.Error
      );
      setSubjectError(true);
      return false;
    }

    const isContentLengthWrong = !isForSave && content.length < 100;

    if (!content || isContentLengthWrong) {
      createSimplePopup(
        !content
          ? "Obsah emailu musí být vyplněný"
          : "Obsah emailu musí mít nejméně 100 znaků",
        SimplePopupType.Error
      );
      return false;
    }

    return true;
  };

  const sendEmail = () => {
    const validation = checkRequiredFields();

    if (validation) {
      createConfirmation(
        "Opravdu chcete poslat e-mail všem příjemcům?",
        async () => {
          setIsSendButtonDisabled(true);
          const data = new FormData();

          data.append("content", content);
          data.append("subject", subject);
          data.append("store", store._id);

          if (editingEmail) {
            data.append("id", editingEmail._id);
          }

          try {
            await emailsModel.sendEmail(data);
            setContent("");
            setSubject("");
            createSimplePopup(
              "Email byl úspěšně odeslán všem příjemcům",
              SimplePopupType.Success
            );
            navigate("/emails");
            setTimeout(() => {
              setIsSendButtonDisabled(false);
            }, 500);
          } catch (err) {
            console.error(err);
            createSimplePopup(
              "Nastala chyba při odesílání emailu",
              SimplePopupType.Error
            );
            setIsSendButtonDisabled(false);
          }
        }
      );
    }
  };

  const colors = useColors();

  const sendTestEmail = async () => {
    const validation = checkRequiredFields();

    if (validation) {
      setIsTestButtonDisabled(true);
      const data = new FormData();

      data.append("content", content);
      data.append("subject", subject);
      data.append("recipient", testMailSend);
      data.append("store", store._id);

      try {
        await emailsModel.sendTestEmail(data);
        createSimplePopup(
          "Testovací email byl úspěšně odeslán",
          SimplePopupType.Success
        );
        setTimeout(() => {
          setIsTestButtonDisabled(false);
        }, 500);
      } catch (err) {
        createSimplePopup(
          "Nastala chyba při odesílání testovacího emailu",
          SimplePopupType.Error
        );
        console.error(err);
        setIsTestButtonDisabled(false);
      }
    }
  };

  const saveEmail = async () => {
    const validation = checkRequiredFields(true);

    if (validation) {
      setIsSendButtonDisabled(true);
      const data = new FormData();

      data.append("content", content);
      data.append("subject", subject);
      data.append("store", store._id);

      if (editingEmail) {
        data.append("id", editingEmail._id);
      }

      try {
        await emailsModel.saveEmail(data);
        setContent("");
        setSubject("");
        createSimplePopup(
          "Email byl úspěšně uložen jako rozpracovaný",
          SimplePopupType.Success
        );
        navigate("/emails");
        setTimeout(() => {
          setIsSendButtonDisabled(false);
        }, 500);
      } catch (err) {
        console.error(err);
        createSimplePopup(
          "Nastala chyba při ukládání emailu",
          SimplePopupType.Error
        );
        setIsSendButtonDisabled(false);
      }
    }
  };

  const { call } = useQueryWithPopupErrorHandling();

  const removeEmail = () => {
    if (!editingEmail) {
      return;
    }

    createConfirmation("Potvrzení: Odstranit rozpracovaný email", async () => {
      call(
        () => emailsModel.removeEmail(editingEmail._id),
        "Při odstraňování emailu nastala chyba",
        "Email byl úspěšně odstraněn",
        () => navigate("/emails")
      );
    });
  };

  const node = (
    <FlexCenterAll>
      <Button
        onClick={saveEmail}
        disabled={isSendButtonDisabled}
        style={{
          fontSize: 14,
          color: colors.MAIN_500,
          backgroundColor: colors.OPPOSITE_MAIN_450,
        }}
        hoverBackgroundColor={colors.OPPOSITE_MAIN_300}
      >
        <FlexCenterAlign>
          <AiOutlineSave
            style={{
              marginRight: 8,
            }}
            size={19}
          />
          Uložit rozpracovaný email
        </FlexCenterAlign>
      </Button>
      {editingEmail && (
        <RemoveButton
          style={{ fontSize: 14, marginLeft: 12 }}
          onClick={removeEmail}
        >
          Odstranit
        </RemoveButton>
      )}
      <Button
        onClick={sendEmail}
        disabled={isSendButtonDisabled}
        style={{ fontSize: 16, marginLeft: 12 }}
      >
        <FlexCenterAlign>
          <FiSend
            style={{
              marginRight: 8,
            }}
            size={19}
          />
          Odeslat email všem příjemcům
        </FlexCenterAlign>
      </Button>
    </FlexCenterAll>
  );

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <BackButton path="/emails" />
      </div>
      <BottomFixedWrapperWrapper
        node={node}
        deps={[isSendButtonDisabled, sendEmail, saveEmail, editingEmail]}
      >
        <div
          style={{
            paddingBottom: 80,
          }}
        >
          <Flex>
            <InputsWrapper style={{ marginBottom: 16 }}>
              <InputWithTitle
                wrapperStyle={{ marginTop: 0 }}
                inputProps={{
                  error:
                    subjectError && !subject
                      ? { key: "SUBJECT_ERROR", type: ErrorType.CATEGORY }
                      : "",
                }}
                title="Předmět emailu"
                value={subject}
                setValue={setSubject}
              />
            </InputsWrapper>
          </Flex>
          <InputsWrapper style={{ width: 680 }}>
            <StyledLabel style={{ marginBottom: 8 }}>Obsah emailu</StyledLabel>
            <RichTextEditor
              imageType={ImageType.Email}
              onEditorChange={(content) => setContent(content)}
              value={content}
            />
          </InputsWrapper>
          <Flex>
            <InputsWrapper style={{ marginTop: 16 }}>
              <FlexCenterAlign>
                <InputWithTitle
                  wrapperStyle={{ marginTop: 0 }}
                  title="Příjemce testovací emailu"
                  value={testMailSend}
                  setValue={setTestMailSend}
                />
                <Button
                  disabled={isTestButtonDisabled}
                  style={{ marginLeft: 20 }}
                  onClick={sendTestEmail}
                >
                  Odeslat testovací email
                </Button>
              </FlexCenterAlign>
            </InputsWrapper>
          </Flex>
        </div>
      </BottomFixedWrapperWrapper>
    </>
  );
};

export default SendEmail;
