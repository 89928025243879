import React from "react";
import { IImageDB, ImageType } from "../../../../types/gallery";
import { FlexCenterAlign, FlexCenterAll } from "../../../../ui/common";
import GalleryPicker from "../../../../ui/gallery-picker";
import { EditorImage } from "./../../../../ui/common";
import { useModal } from "../../../../providers/modal/modal-provider";
import ImageUploadModal from "../../../gallery/image-upload-modal";
import { IEditorProduct, IProduct } from "../../../../types/products";
import styled from "styled-components";
import Button from "../../../../ui/button";

interface IProps {
  gallery: IImageDB[];
  removeImage(image: IImageDB): void;
  addImage(image: IImageDB): void;
  product: IEditorProduct;
  setProduct: React.Dispatch<React.SetStateAction<IEditorProduct>>;
}

const FileInputWrapper = styled.div`
  margin-left: 8px;
  button {
    font-size: 11px;
  }
`;

export const ProductGallery = ({
  gallery,
  removeImage,
  addImage,
  setProduct,
  product,
}: IProps): React.ReactElement<IProps> => {
  const { createModal } = useModal();

  const uploadNewPhotos = () => {
    createModal(
      <ImageUploadModal
        onSuccess={(images) =>
          setProduct((p) => ({ ...p, gallery: [...p.gallery, ...images] }))
        }
        addSuffixToName
        skipSuffixIndex={product.gallery.length}
        defaultName={product.eshop_id}
        multiple={true}
        disableSelectType
        type={ImageType.Product}
      />
    );
  };

  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <FlexCenterAlign>
        <div
          style={{
            fontSize: 20,
            opacity: 0.75,
            marginRight: 12,
          }}
        >
          Galerie
        </div>
        <GalleryPicker
          title="Přidat fotografii z galerie"
          onChoose={(image) => addImage(image)}
          type={ImageType.Product}
        />
        <FileInputWrapper>
          <Button onClick={uploadNewPhotos}>Nahrát fotografie</Button>
        </FileInputWrapper>
      </FlexCenterAlign>

      {gallery.length > 0 && (
        <FlexCenterAlign
          style={{
            marginTop: 12,
          }}
        >
          {gallery.map((image, i) => (
            <EditorImage
              style={{
                marginRight: i !== gallery.length - 1 ? 12 : 0,
              }}
              key={i}
              src={image.url}
              onDeleteClick={() => removeImage(image)}
            />
          ))}
        </FlexCenterAlign>
      )}
    </div>
  );
};

export default ProductGallery;
