import React, { useState, useEffect } from "react";
import { IoImagesOutline } from "react-icons/io5";
import styled, { CSSProperties } from "styled-components";
import { useImageWindow } from "../providers/image-window/image-window-provider";
import { useColors } from "../providers/theme/theme-provider";
import { FlexCenterAll } from "./common";
import Loading from "./loading";

const GalleryImage = styled.img`
  max-width: 100%;
  max-height: 196px;
  border-radius: 4px;
`;

interface IProps {
  src?: string;
  style?: CSSProperties;
  disableEnlargement?: boolean;
}

export const LazyLoadingGalleryImage = ({
  src,
  style,
  disableEnlargement,
}: IProps): React.ReactElement<IProps> => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { createImageWindow, isImageWindowOpened, closeImageWindow } =
    useImageWindow();

  useEffect(() => {
    setHasError(false);

    if (isLoading && src) {
      const image = new window.Image();

      image.src = src;
      image.onload = () => {
        setIsLoading(false);
      };
      image.onerror = () => {
        setHasError(true);
      };
    }
  }, [src, isLoading]);

  const colors = useColors()
  
  return (
    <>
      {(isLoading || hasError || !src) && (
        <FlexCenterAll
          style={{
            height: 196,
            width: 196,
            ...style,
          }}
        >
          {hasError || !src ? (
            <IoImagesOutline size={64} color={colors.MAIN_200} />
          ) : (
            <Loading />
          )}
        </FlexCenterAll>
      )}
      <GalleryImage
        onClick={
          disableEnlargement
            ? undefined
            : src
            ? () => {
                if (isImageWindowOpened) {
                  closeImageWindow();
                  return;
                }

                createImageWindow(src);
              }
            : undefined
        }
        style={{
          display: isLoading || hasError || !src ? "none" : "block",
          cursor: disableEnlargement
            ? undefined
            : !isImageWindowOpened
            ? "zoom-in"
            : "zoom-out",
          ...style,
        }}
        src={src}
      />
    </>
  );
};

export default LazyLoadingGalleryImage;
