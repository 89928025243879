import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div<{ active: boolean }>`
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 500ms ease 200ms, visibility 500ms ease 200ms;

  ${({ active }) =>
    active &&
    css`
      transition: opacity 0ms ease, visibility 0ms ease;
      opacity: 1;
      visibility: visible;
    `}
`;

const StyledBar = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0px;
    height: 2px;
    background-color: ${theme.colors.SUCCESS_400};

    &.with-transition {
      transition: width 300ms ease;
    }
  `}
`;

export interface ILoadingBarRef {
  finish(): void;
  start(): void;
}

interface IProps {}

export const LoadingBar = forwardRef(
  (
    {}: IProps,
    ref: React.ForwardedRef<ILoadingBarRef>
  ): React.ReactElement<IProps> => {
    const [active, setActive] = useState(false);
    const barRef = useRef<HTMLDivElement>(null);
    const shouldAnimate = useRef(false);

    const startAnimation = () => {
      let width = 0;
      let speed = 1;

      function animate() {
        if (barRef.current && shouldAnimate.current) {
          width += speed;
          speed *= 0.988;
          barRef.current.style.width = width + "%";
          requestAnimationFrame(animate);
        }
      }

      animate();
    };

    useImperativeHandle(
      ref,
      () => ({
        start() {
          if (barRef.current) {
            barRef.current.classList.remove("with-transition");
            barRef.current.style.width = "0%";
            shouldAnimate.current = true;
            setActive(true);
            startAnimation();
          }
        },
        finish() {
          if (barRef.current) {
            shouldAnimate.current = false;
            barRef.current.classList.add("with-transition");
            barRef.current.style.width = "100%";
            setActive(false);
          }
        },
      }),
      [barRef.current, active]
    );

    return (
      <Wrapper active={active}>
        <StyledBar ref={barRef} />
      </Wrapper>
    );
  }
);

export default LoadingBar;
