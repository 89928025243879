import axios from "../axios";
import { IParameterID } from "./../../types/parameter";

export interface IParameterModel {
  getParameters: () => Promise<IParameterID[]>;
  getParameterById: (id: string) => Promise<IParameterID>;
  addParameter: (data: FormData) => Promise<IParameterID>;
  editParameter: (data: FormData) => Promise<IParameterID>;
  removeParameterById: (id: string) => Promise<boolean>;
}

export const parameterModel: IParameterModel = {
  getParameters() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IParameterID[]>(`/parameters/get-all`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getParameterById(_id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IParameterID>(`/parameters/get-by-id`, {
          params: {
            _id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  addParameter(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IParameterID>(`/parameters/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editParameter(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IParameterID>(`/parameters/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeParameterById(_id) {
    return new Promise(async (res, rej) => {
      const response = await axios.post<boolean>(`/parameters/remove-by-id`, {
        _id,
      });

      res(response.data);
    });
  },
};
