import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  determineRoleByPermissions,
  getPermissionsByRole
} from "../../../common/role";
import { getTitleByRole } from "../../../locales/determineString";
import { IRole, UserRole } from "../../../types/role";
import Dropdown from "../../../ui/dropdown";
import EditSinglePermission from "./edit-single-permission";

interface IProps {
  role: IRole;
  setRole: Dispatch<SetStateAction<IRole>>;
}

export const UserPermissionsEditor = ({
  role,
  setRole,
}: IProps): React.ReactElement<IProps> => {
  useEffect(() => {
    const userRole = determineRoleByPermissions(role.permissions);

    setRole((r) => ({ ...r, role: userRole }));
  }, [role.permissions]);

  useEffect(() => {
    const permissions = getPermissionsByRole(role.role);

    if (permissions) {
      setRole((r) => ({ ...r, permissions }));
    }
  }, [role.role]);

  return (
    <div
      style={{
        marginRight: 40,
        paddingLeft: 4,
        paddingRight: 20,
        paddingTop: 10,
        maxHeight: 500,
        minWidth: 300,
        overflow: "auto",
      }}
    >
      <div>
        <Dropdown
          list={Object.values(UserRole).map((r) => ({
            content: getTitleByRole(r),
            value: r,
            unique_id: r,
            query: getTitleByRole(r),
          }))}
          isDeleteButtonDisabled
          onSelectValue={(role: UserRole) => {
            setRole((r) => ({ ...r, role }));
          }}
          selectedUniqueId={role.role}
        />
      </div>
      <div
        style={{
          marginTop: 12,
        }}
      >
        <EditSinglePermission
          title="Produkt"
          setPermission={(product) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, product },
            }))
          }
          permission={role.permissions.product}
          canTranslate
        />
        <EditSinglePermission
          title="Kategorie"
          setPermission={(category) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, category },
            }))
          }
          permission={role.permissions.category}
          canTranslate
        />
        <EditSinglePermission
          title="Parametr"
          setPermission={(parameter) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, parameter },
            }))
          }
          permission={role.permissions.parameter}
          canTranslate
        />
        <EditSinglePermission
          title="Hodnota"
          setPermission={(parameter_value) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, parameter_value },
            }))
          }
          permission={role.permissions.parameter_value}
          canTranslate
        />
        <EditSinglePermission
          title="Výrobce"
          setPermission={(manufacturer) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, manufacturer },
            }))
          }
          permission={role.permissions.manufacturer}
          canTranslate
        />

        <EditSinglePermission
          title="Galerie"
          setPermission={(gallery) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, gallery },
            }))
          }
          permission={role.permissions.gallery}
        />

        <EditSinglePermission
          title="Úkoly"
          setPermission={(tasks) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, tasks },
            }))
          }
          permission={role.permissions.tasks}
        />

        <EditSinglePermission
          title="Jazyky"
          setPermission={(language) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, language },
            }))
          }
          permission={role.permissions.language}
        />

        <EditSinglePermission
          title="Uživatelé"
          setPermission={(user) =>
            setRole((r) => ({
              ...r,
              permissions: { ...r.permissions, user },
            }))
          }
          permission={role.permissions.user}
          canChangePermissions
        />
      </div>
    </div>
  );
};

export default UserPermissionsEditor;
