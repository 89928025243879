import React from "react";
import { useUser } from "../../common/redux-hooks";
import IconButton from "../../ui/icon-button";
import { FaUserAlt } from "react-icons/fa";
import { colors } from "./../../ui/colors";
import { getFullName } from "../../common/functions";
import { FlexCenterAlign, FlexCenterAll, ProfileImage } from "../../ui/common";
import styled from "styled-components";
import { getTitleByRole } from "./../../locales/determineString";
import { useNavigate } from "react-router-dom";
import { DefaultAvatar } from "../../ui/common";

const UserTitle = styled.div`
  font-size: 11px;
  letter-spacing: 0.05em;
  font-weight: 500;
  margin-left: 10px;
  opacity: 0.8;
  margin-bottom: 3px;
`;

const UserFullName = styled.div`
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 500;
  margin-left: 10px;
`;

interface IProps {}

export const SidebarUser = ({}: IProps): React.ReactElement<IProps> => {
  const navigate = useNavigate();
  const user = useUser();

  return (
    <div
      style={{
        marginBottom: 60,
        marginLeft: 6,
      }}
    >
      <FlexCenterAlign>
        <IconButton
          data-title="Zobrazit profil"
          onClick={() => navigate("/profile")}
          style={{
            padding: 0,
            border: "unset",
            backgroundColor: "unset"
          }}
        >
          {user.image ? (
            <ProfileImage src={user.image.url} style={{
              width: 40,
              height: 40
            }} />
          ) : (
            <DefaultAvatar letter={user.firstName.charAt(0)} />
          )}
        </IconButton>
        <div>
          <UserTitle>{getTitleByRole(user.role.role)}</UserTitle>
          <UserFullName>{getFullName(user)}</UserFullName>
        </div>
      </FlexCenterAlign>
    </div>
  );
};

export default SidebarUser;
