import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isLanguageStringEmpty } from "../../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useAppConfig, useErrors } from "../../../common/redux-hooks";
import {
  defaultLanguageKey,
  nonTranslatedString,
} from "../../../constants/language";
import { getParameterValueTypeTitle } from "../../../locales/determineString";
import { parameterValueModel } from "../../../model/query/parameterValueModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useSimplePopups } from "../../../providers/simple-popup/simple-popup-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_PARAMETER_VALUE,
  EDIT_PARAMETER_VALUE,
  REMOVE_PARAMETER_VALUE_BY_ID,
} from "../../../redux/actions/parameterValueActions";
import { useDispatch } from "../../../redux/store";
import { EditorType, TLanguageString } from "../../../types/common";
import { ErrorType } from "../../../types/error";
import {
  IParameterValueID,
  ParameterValueType,
  TParameterValueParams,
} from "../../../types/parameter";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
  TextWithInfo,
} from "../../../ui/common";
import Dropdown from "../../../ui/dropdown";
import { InputWithTitle } from "../../../ui/input-with-title";
import LanguageSelector from "../../../ui/language-selector";
import BackButton from "../../components/back-button";
import { getParameterValueEditorErrors } from "./editor-errors";
import { getComponentByParameterValueType } from "./parameter-value-type/components";

const mapParameterValueToEditorParameterValue = (
  parameterValue: IParameterValueID
): IEditorParameterValue => {
  const { _id, type, value, valueIsSameForAllLanguages, params, sortIndex } =
    parameterValue;

  return {
    type,
    value,
    valueIsSameForAllLanguages,
    _id,
    params,
    sortIndex: sortIndex || 0,
  };
};

const initialEditorParameterValue: IEditorParameterValue = {
  value: {},
  valueIsSameForAllLanguages: false,
  type: ParameterValueType.Text,
  sortIndex: 0,
};

export interface IEditorParameterValue {
  _id?: string;
  type: ParameterValueType;
  value: TLanguageString;
  valueIsSameForAllLanguages: boolean;
  params?: TParameterValueParams;
  sortIndex: number;
}

interface IProps {
  type: EditorType;
}

export const ParameterValueEditor = ({
  type,
}: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const appConfig = useAppConfig();
  const [language, setLanguage] = useState(appConfig.language);
  const [parameterValue, setParameterValue] = useState<IEditorParameterValue>(
    initialEditorParameterValue
  );

  const colors = useColors();
  const { closeNotification } = useNotifications();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getParameterValueEditorErrors(
    getError,
    parameterValue
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useQueryWithPopupErrorHandling();
  const { createConfirmation } = useConfirmation();
  const { createSimplePopup } = useSimplePopups();

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.PARAMETER_VALUE);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getParameterValue = async (id: string) => {
      call(
        () => parameterValueModel.getParameterValueById(id),
        "Zobrazení hodnoty nebylo úspěšné",
        null,
        (parameterValue) => {
          setParameterValue(
            mapParameterValueToEditorParameterValue(parameterValue)
          );
        },
        () => {
          navigate("/parameters");
        }
      );
    };

    if (_id && type === EditorType.Edit) {
      getParameterValue(_id);
    }
  }, [_id, type]);

  const appendParameterValueToForm = useCallback(
    (form: FormData) => {
      if (!isLanguageStringEmpty(parameterValue.value)) {
        let value = { ...parameterValue.value };

        if (parameterValue.valueIsSameForAllLanguages) {
          value = {
            [defaultLanguageKey]: value[defaultLanguageKey],
          };
        } else if (value[defaultLanguageKey] !== undefined) {
          delete value[defaultLanguageKey];
        }

        form.append("value", JSON.stringify(value));
        form.append("type", parameterValue.type);

        if (
          typeof parameterValue.sortIndex === "number" &&
          !isNaN(parameterValue.sortIndex)
        ) {
          form.append("sortIndex", parameterValue.sortIndex.toString());
        } else {
          form.append("sortIndex", "0");
        }

        if (parameterValue.params) {
          form.append("params", JSON.stringify(parameterValue.params));
        }
      }
      form.append(
        "valueIsSameForAllLanguages",
        JSON.stringify(parameterValue.valueIsSameForAllLanguages)
      );
    },
    [parameterValue]
  );

  const addParameterValue = () => {
    const data = new FormData();

    appendParameterValueToForm(data);

    call(
      () => ADD_PARAMETER_VALUE(data),
      "Přidání hodnoty nebylo úspěšné",
      "Přidání hodnoty bylo úspěšné",
      (newParameter) => {
        dispatch(newParameter);
        navigate("/parameters");
      },
      (err) => {
        if (err?.payload) {
          dispatch(err);
        }
      }
    );
  };

  const editParameterValue = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit hodnotu", async () => {
      if (!_id) {
        return;
      }

      const data = new FormData();

      data.append("_id", _id);
      appendParameterValueToForm(data);

      call(
        () => EDIT_PARAMETER_VALUE(data),
        "Úprava hodnoty nebyla úspěšná",
        "Úprava hodnoty byla úspěšná",
        (action) => {
          dispatch(action);

          if (exit) {
            navigate("/parameters");
          } else {
            setParameterValue(
              mapParameterValueToEditorParameterValue(action.payload)
            );
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeParameterValue = () => {
    createConfirmation(
      "Potvrzení: Odstranit hodnotu a její použití",
      async () => {
        if (!_id) {
          return;
        }

        call(
          () => REMOVE_PARAMETER_VALUE_BY_ID(_id),
          "Odstranění hodnoty nebylo úspěšné",
          "Odstranění hodnoty bylo úspěšné",
          (remove) => {
            dispatch(remove);
            navigate("/parameters");
          }
        );
      }
    );
  };

  const setParams = (params: TParameterValueParams) => {
    setParameterValue((p) => ({ ...p, params }));
  };

  const parameterValueTypeComponent = getComponentByParameterValueType(
    parameterValue.type,
    setParams,
    parameterValue.params
  );

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!isValid}
          onClick={addParameterValue}
        >
          Přidat hodnotu
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editParameterValue()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton
              disabled={!isValid}
              onClick={() => editParameterValue(true)}
            >
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removeParameterValue}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper
      node={node}
      deps={[isValid, appendParameterValueToForm]}
    >
      <div>
        <BackButton path="/parameters" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          {type === EditorType.Add ? "Přidání hodnoty" : "Úprava hodnoty"}
        </div>
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <LanguageSelector
            disabled={parameterValue.valueIsSameForAllLanguages}
            onSelectLanguage={setLanguage}
          />
        </div>
        <Flex>
          <InputsWrapper>
            <InputWithTitle
              info="Hodnota, může být číslo, slovo - cokoliv, co lze použít k parametru"
              required
              inputProps={{
                placeholder:
                  parameterValue.valueIsSameForAllLanguages &&
                  !parameterValue.value[defaultLanguageKey]
                    ? "Nevyplněno"
                    : !parameterValue.value[language.locale]
                    ? nonTranslatedString
                    : undefined,
                error: errors.value,
              }}
              wrapperStyle={{
                marginTop: 0,
              }}
              inputStyle={{
                width: 320,
              }}
              value={
                parameterValue.valueIsSameForAllLanguages
                  ? parameterValue.value[defaultLanguageKey] || ""
                  : parameterValue.value[language.locale] || ""
              }
              setValue={(value) => {
                {
                  setParameterValue((p) => ({
                    ...p,
                    value: {
                      ...p.value,
                      [p.valueIsSameForAllLanguages
                        ? defaultLanguageKey
                        : language.locale]: value,
                    },
                  }));
                }
              }}
              title="Hodnota"
            />
            <div
              style={{
                marginTop: 10,
              }}
            >
              <InputWithTitle
                info="Vyšší číslo znamená že se parametr zobrazí jako první"
                inputProps={{
                  type: "number",
                }}
                wrapperStyle={{
                  marginTop: 0,
                }}
                inputStyle={{
                  width: 320,
                }}
                value={
                  parameterValue.sortIndex === undefined
                    ? 0
                    : parameterValue.sortIndex
                }
                setValue={(sortIndex) =>
                  setParameterValue((p) => ({
                    ...p,
                    sortIndex,
                  }))
                }
                title="Index seřazení"
              />
            </div>
          </InputsWrapper>
        </Flex>

        <Flex
          style={{
            marginTop: 16,
          }}
        >
          <InputsWrapper>
            <div
              style={{
                marginLeft: 8,
                marginBottom: 4,
              }}
            >
              <TextWithInfo
                info="Typ hodnoty, umožní generování barev/datumů/skupiny číslic"
                text="Typ hodnoty"
              />
            </div>
            <Dropdown
              list={Object.values(ParameterValueType).map((s) => ({
                content: getParameterValueTypeTitle(s),
                value: s,
                unique_id: s,
                query: getParameterValueTypeTitle(s),
              }))}
              onSelectValue={(type) =>
                setParameterValue((p) => ({ ...p, type }))
              }
              isDeleteButtonDisabled
              selectedUniqueId={parameterValue.type}
              title="Typ parameteru"
            />
          </InputsWrapper>
        </Flex>

        {parameterValueTypeComponent && (
          <Flex
            style={{
              marginTop: 16,
            }}
          >
            <InputsWrapper>{parameterValueTypeComponent}</InputsWrapper>
          </Flex>
        )}
        <Flex
          style={{
            marginTop: 20,
          }}
        >
          <InputsWrapper>
            <FlexCenterAlign>
              <Checkbox
                value={parameterValue.valueIsSameForAllLanguages}
                setValue={(valueIsSameForAllLanguages) => {
                  setParameterValue((p) => ({
                    ...p,
                    value: {
                      ...p.value,
                      [valueIsSameForAllLanguages
                        ? defaultLanguageKey
                        : language.locale]:
                        p.value[
                          valueIsSameForAllLanguages
                            ? language.locale
                            : defaultLanguageKey
                        ],
                    },
                    valueIsSameForAllLanguages,
                  }));
                }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                Hodnota je stejná pro všechny jazyky
              </div>
            </FlexCenterAlign>
          </InputsWrapper>
        </Flex>
      </div>
    </BottomFixedWrapperWrapper>
  );
};

export default ParameterValueEditor;
