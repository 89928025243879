import _ from "lodash";
import { IRolePermissions, UserRole } from "./../types/role";

const comparePermissions = (
  permissions1: IRolePermissions,
  permissions2: IRolePermissions
) => {
  return _.isEqual(permissions1, permissions2);
};

export const determineRoleByPermissions = (
  permissions: IRolePermissions
): UserRole => {
  if (comparePermissions(permissions, ADMIN_PERMISSIONS)) {
    return UserRole.Admin;
  }

  if (comparePermissions(permissions, WRITER_PERMISSIONS)) {
    return UserRole.Writer;
  }

  if (comparePermissions(permissions, MANAGER_PERMISSIONS)) {
    return UserRole.Manager;
  }

  if (comparePermissions(permissions, TRANSLATOR_PERMISSIONS)) {
    return UserRole.Translator;
  }

  if (comparePermissions(permissions, TRANSLATOR_ADMIN_PERMISSIONS)) {
    return UserRole.TranslatorAdmin;
  }

  return UserRole.Custom;
};

export const getPermissionsByRole = (
  role: UserRole
): IRolePermissions | null => {
  switch (role) {
    case UserRole.Admin:
      return ADMIN_PERMISSIONS;
    case UserRole.Manager:
      return MANAGER_PERMISSIONS;
    case UserRole.Translator:
      return TRANSLATOR_PERMISSIONS;
    case UserRole.TranslatorAdmin:
      return TRANSLATOR_ADMIN_PERMISSIONS;
    case UserRole.Writer:
      return WRITER_PERMISSIONS;
    default:
      return null;
  }
};

export const ADMIN_PERMISSIONS: IRolePermissions = {
  category: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  gallery: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  language: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  manufacturer: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  parameter: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  parameter_value: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  product: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  tasks: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  user: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
    edit_permissions: true,
  },
  currency: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  parameter_group: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  payment: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  shipping: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  order: {
    view: true,
    add: false,
    edit: true,
    remove: true,
    edit_state: true,
    edit_language: false,
  },
  terms_and_conditions: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  coupons: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  store: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  email: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
};

const MANAGER_PERMISSIONS: IRolePermissions = {
  category: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  gallery: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  language: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  manufacturer: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  parameter: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  parameter_value: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  product: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  tasks: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  user: {
    view: true,
    add: true,
    edit: true,
    remove: false,
    edit_language: false,
  },
  currency: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  parameter_group: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  payment: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  shipping: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  order: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_state: true,
    edit_language: false,
  },
  terms_and_conditions: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  coupons: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  store: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  email: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
};

const TRANSLATOR_PERMISSIONS: IRolePermissions = {
  category: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  gallery: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  language: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  manufacturer: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  parameter: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  parameter_value: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  product: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  tasks: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  user: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  currency: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  parameter_group: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  payment: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  shipping: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  order: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_state: false,
    edit_language: false,
  },
  terms_and_conditions: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  coupons: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  store: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  email: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
};

const TRANSLATOR_ADMIN_PERMISSIONS: IRolePermissions = {
  category: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  gallery: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  language: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  manufacturer: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  parameter: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  parameter_value: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  product: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: true,
  },
  tasks: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  user: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  currency: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  parameter_group: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  payment: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  shipping: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  order: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_state: false,
    edit_language: false,
  },
  terms_and_conditions: {
    view: true,
    add: false,
    edit: true,
    remove: false,
    edit_language: true,
  },
  coupons: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  store: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  email: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
};

export const WRITER_PERMISSIONS: IRolePermissions = {
  category: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  gallery: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  language: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  manufacturer: {
    add: false,
    edit: false,
    remove: false,
    view: true,
    edit_language: false,
  },
  parameter: {
    add: false,
    edit: false,
    remove: false,
    view: true,
    edit_language: false,
  },
  parameter_value: {
    add: false,
    edit: false,
    remove: false,
    view: true,
    edit_language: false,
  },
  product: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: true,
  },
  tasks: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    edit_language: false,
  },
  user: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  currency: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  parameter_group: {
    view: true,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  payment: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  shipping: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  order: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_state: false,
    edit_language: false,
  },
  terms_and_conditions: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  coupons: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  store: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
  email: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    edit_language: false,
  },
};
