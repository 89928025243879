import React, { useRef, useState } from "react";
import LoadingWithOverlayWindow, {
  ILoadingWithOverlayWindowRef,
} from "./loading-with-overlay-window";

interface ILoadingWithOverlayContext {
  closeLoading(): void;
  showLoading(): void;
}

const LoadingWithOverlayContext =
  React.createContext<ILoadingWithOverlayContext>(
    {} as ILoadingWithOverlayContext
  );

export const useLoadingWithOverlay = () => {
  return React.useContext(LoadingWithOverlayContext);
};

interface IProps {
  children: React.ReactNode;
}

export const LoadingWithOverlayProvider = ({
  children,
}: IProps): React.ReactElement<IProps> => {
  const loadingWithOverlayWindowRef =
    useRef<ILoadingWithOverlayWindowRef>(null);

  const loadingWithOverlayContext: ILoadingWithOverlayContext = {
    showLoading() {
      loadingWithOverlayWindowRef.current?.show();
    },
    closeLoading() {
      loadingWithOverlayWindowRef.current?.close();
    },
  };

  return (
    <LoadingWithOverlayContext.Provider value={loadingWithOverlayContext}>
      <LoadingWithOverlayWindow ref={loadingWithOverlayWindowRef} />
      {children}
    </LoadingWithOverlayContext.Provider>
  );
};

export default LoadingWithOverlayProvider;
