import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isLanguageStringEmpty } from "../../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { LanguageContext } from "../../../common/languange-context";
import { useAppConfig, useErrors } from "../../../common/redux-hooks";
import { useModel } from "../../../model/provider";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useColors, useTheme } from "../../../providers/theme/theme-provider";
import {
  ADD_PRODUCT,
  EDIT_PRODUCT,
  REMOVE_PRODUCT_BY_ID,
} from "../../../redux/actions/productsActions";
import { useDispatch } from "../../../redux/store";
import { EditorType } from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { IHistoryAction } from "../../../types/history";
import { ILanguageID } from "../../../types/language";
import {
  IProductParameter,
  IProductParameterGroup,
} from "../../../types/parameter";
import {
  IEditorProduct,
  IProduct,
  productInitState,
} from "../../../types/products";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  RemoveButton,
  SelectedWindowStyled,
} from "../../../ui/common";
import LanguageSelector from "../../../ui/language-selector";
import BackButton from "../../components/back-button";
import HistoryView from "../../components/history/history-view";
import {
  BasicInfo,
  Categories,
  Description,
  Gallery,
  Manufacturer,
  Packaging,
  Parameters,
  Variants,
} from "./components/product-editor-components";
import WindowSelector from "./components/window-selector";
import { getProductEditorErrors, hasParameterErrors } from "./editor-errors";
import { ProductEditorErrorsContext } from "./error-context";
import {
  deleteSavedProductStateFromLocalStorage,
  useLocalStorageProductSaving,
} from "./hooks";
import SubproductsView from "./subproducts/subproducts-view";
import { ISubProduct } from "../../../types/subproduct";

const mapProductToEditorProduct = (product: IProduct): IEditorProduct => {
  const {
    ean,
    _id,
    buy_price,
    shortName,
    description,
    gallery,
    image,
    name,
    packaging,
    params,
    price_vat,
    stock,
    isInExternalStock,
    categories,
    slug,
    eshop_id,
    manufacturer,
    stores,
    translation_needed,
    parameter_groups,
    variants,
    related_products,
    sale_price,
    external_link,
    available_in_stores,
    subproducts,
  } = product;

  return {
    buy_price,
    description: description || {},
    ean,
    gallery,
    image,
    shortName,
    name,
    packaging,
    params,
    price_vat,
    stock,
    isInExternalStock: !!isInExternalStock,
    _id,
    categories: categories.map((c) => c._id),
    slug: slug || {},
    eshop_id,
    manufacturer,
    stores,
    available_in_stores,
    translation_needed,
    parameter_groups,
    variants,
    related_products,
    sale_price,
    external_link,
    subproducts,
  };
};

export const filterAndMapValidParams = (
  params: IProductParameter[]
): IProductParameter[] | ISubProduct[] => {
  return params.filter((p) => !!p.parameter);
};

const mapParameterGroups = (
  groups: IProductParameterGroup[]
): { group: string; parameters: IProductParameter[] }[] => {
  return groups.map((g) => ({ group: g.group._id, parameters: g.parameters }));
};

export enum ProductEditorSelectedWindow {
  BasicInfo,
  Packaging,
  Parameters,
  Variants,
  RelatedProducts,
  SubProducts,
  Gallery,
  Categories,
  Description,
  History,
  Manufacturer,
}

interface IProps {
  type: EditorType;
}

export const ProductEditor = ({ type }: IProps): React.ReactElement<IProps> => {
  const {
    productModel: { getProductById },
  } = useModel();
  const { createConfirmation } = useConfirmation();
  const { closeNotification } = useNotifications();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _id } = useParams();

  const [product, setProduct] = useState<IEditorProduct>(productInitState);
  const [originalEditingProduct, setOriginalEditingProduct] =
    useState<IEditorProduct | null>(null);

  const appConfig = useAppConfig();

  const [language, setLanguage] = useState<ILanguageID>(appConfig.language);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useLocalStorageProductSaving(
    product,
    productInitState,
    originalEditingProduct,
    type,
    setProduct
  );

  const { getError, clearErrorsOfType } = useErrors();
  const hasParameterError = hasParameterErrors(
    product.params,
    product.parameter_groups,
    getError
  );
  const { errors, isValid } = getProductEditorErrors(
    getError,
    product,
    language.locale
  );

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.PRODUCT);
      closeNotification();
    };
  }, []);

  const appendProductToForm = useCallback(
    (form: FormData) => {
      form.append("ean", product.ean);
      form.append("eshop_id", product.eshop_id);
      if (!isLanguageStringEmpty(product.name)) {
        form.append("name", JSON.stringify(product.name));
      }
      if (product.shortName && !isLanguageStringEmpty(product.shortName)) {
        form.append("shortName", JSON.stringify(product.shortName));
      }
      if (!isLanguageStringEmpty(product.description)) {
        form.append("description", JSON.stringify(product.description));
      }
      form.append("stock", product.stock.toString());
      form.append("isInExternalStock", product.isInExternalStock.toString());
      form.append("price_vat", JSON.stringify(product.price_vat));
      form.append("sale_price", JSON.stringify(product.sale_price));
      form.append("buy_price", JSON.stringify(product.buy_price));
      form.append(
        "translation_needed",
        JSON.stringify(product.translation_needed)
      );
      form.append(
        "stores",
        JSON.stringify(product.stores.map((store) => store._id))
      );
      form.append(
        "available_in_stores",
        JSON.stringify(product.available_in_stores.map((store) => store._id))
      );

      form.append("variants", JSON.stringify(product.variants));
      form.append("related_products", JSON.stringify(product.related_products));

      form.append(
        "params",
        JSON.stringify(filterAndMapValidParams(product.params))
      );

      form.append(
        "parameter_groups",
        JSON.stringify(mapParameterGroups(product.parameter_groups))
      );

      form.append("packaging", JSON.stringify(product.packaging));
      form.append(
        "categories",
        JSON.stringify(product.categories.filter((c) => !!c))
      );

      form.append("subproducts", JSON.stringify(product.subproducts));

      if (product.manufacturer) {
        form.append("manufacturer", product.manufacturer._id);
      }

      if (product.external_link) {
        form.append("external_link", product.external_link);
      }

      Object.keys(product.slug).forEach((key) => {
        const slug = product.slug[key];

        if (!slug.userDefined && !slug.value && !product.name[key]) {
          delete product.slug[key];
        }
      });

      form.append("slug", JSON.stringify(product.slug));

      if (product.image) {
        form.append("image", product.image._id);
      }

      for (const image of product.gallery) {
        form.append("gallery", image._id);
      }
    },
    [product]
  );

  const { call } = useQueryWithPopupErrorHandling();

  const addProductFromData = async () => {
    const form = new FormData();

    appendProductToForm(form);

    call(
      () => ADD_PRODUCT(form),
      "Přidání produktu nebylo úspěšné",
      "Úprava produktu byla úspěšná",
      (action) => {
        dispatch(action);
        deleteSavedProductStateFromLocalStorage();
        navigate("/products");
      },
      (err) => {
        dispatch(err);
      }
    );
  };

  const editProductFromData = async (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit produkt", async () => {
      const form = new FormData();

      if (!product._id) return;

      form.append("_id", product._id);

      appendProductToForm(form);

      call(
        () => EDIT_PRODUCT(form),
        "Úprava produktu nebyla úspěšná",
        "Úprava produktu byla úspěšná",
        (action) => {
          dispatch(action);
          deleteSavedProductStateFromLocalStorage(product._id);

          if (exit) {
            navigate("/products");
          } else {
            setProduct(mapProductToEditorProduct(action.payload));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removeProduct = async () => {
    createConfirmation("Potvrzení: Odstranit produkt", async () => {
      if (!product._id) {
        return;
      }

      const _id = product._id;

      call(
        () => REMOVE_PRODUCT_BY_ID(_id),
        "Odstranění produktu nebylo úspěšné",
        "Odstranění produktu bylo úspěšné",
        (action) => {
          dispatch(action);
          deleteSavedProductStateFromLocalStorage(product._id);
          navigate("/products");
        }
      );
    });
  };

  const getProductByIdCall = async (id: string) => {
    call(
      () => getProductById(id),
      "Zobrazení produktu nebylo úspěšné",
      null,
      (product) => {
        const p = mapProductToEditorProduct(product);

        setProduct(p);
        setOriginalEditingProduct(p);
      },
      () => {
        navigate("/products");
      }
    );
  };

  useEffect(() => {
    if (type === EditorType.Edit && !_id) {
      navigate("/products");
    }

    if (type === EditorType.Edit && _id) {
      getProductByIdCall(_id);
    }
  }, [_id, type]);

  const theme = useTheme();
  const colors = useColors();

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <>
          <Button
            disabled={!isValid || hasParameterError}
            style={{
              ...AddButtonStyles,
              backgroundColor: colors.MAIN_300,
            }}
            hoverBackgroundColor={colors.MAIN_250}
            onClick={addProductFromData}
          >
            Přidat produkt
          </Button>
        </>
      ) : (
        <FlexCenterAll>
          <div>
            <AddButton
              style={{
                marginRight: 16,
              }}
              disabled={!isValid || hasParameterError}
              onClick={() => editProductFromData()}
              data-title="Uloží aktuální produkt"
            >
              Uložit
            </AddButton>

            <AddButton
              style={{
                marginRight: 16,
              }}
              disabled={!isValid || hasParameterError}
              onClick={() => editProductFromData(true)}
              data-title="Uloží aktuální produkt a zavře záložku"
            >
              Uložit a zavřít
            </AddButton>

            <RemoveButton
              onClick={removeProduct}
              data-title="Odstraní aktuální produkt"
            >
              Odstranit
            </RemoveButton>
          </div>
          {product.image?.url && (
            <div
              style={{
                position: "absolute",
                right: 16,
                bottom: 80,
                width: 128,
                height: 128,
                pointerEvents: "none",
              }}
            >
              <img
                style={{
                  width: 128,
                  height: 128,
                  borderRadius: theme.borderRadius,
                }}
                src={product.image.url}
              />
            </div>
          )}
        </FlexCenterAll>
      )}
    </FlexCenterAll>
  );

  const [selectedWindow, setSelectedWindow] =
    useState<ProductEditorSelectedWindow>(
      ProductEditorSelectedWindow.BasicInfo
    );

  return (
    <LanguageContext.Provider value={language}>
      <ProductEditorErrorsContext.Provider value={errors}>
        <BottomFixedWrapperWrapper
          node={node}
          deps={[isValid, hasParameterError, appendProductToForm]}
        >
          <BackButton path="/products" />
          <div
            style={{
              marginTop: 40,
              fontSize: 36,
              marginLeft: 8,
              opacity: 0.444,
            }}
          >
            {type === EditorType.Add
              ? "Přidání produktu"
              : `Upravit produkt - ${product.name[language.locale]}`}
          </div>

          <FlexCenterAlign
            style={{
              margin: "20px 8px",
              marginBottom: 16,
            }}
          >
            <LanguageSelector
              onSelectLanguage={(language) => setLanguage(language)}
            />
          </FlexCenterAlign>

          <WindowSelector
            mode={type}
            errors={errors}
            hasParameterErrors={hasParameterError}
            selectedWindow={selectedWindow}
            setSelectedWindow={(window) => {
              setSelectedWindow(window);
            }}
          />

          <Flex
            style={{
              marginLeft: 8,
            }}
          >
            <SelectedWindowStyled
              visible={selectedWindow === ProductEditorSelectedWindow.BasicInfo}
            >
              <BasicInfo product={product} setProduct={setProduct} />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={
                selectedWindow === ProductEditorSelectedWindow.Categories
              }
            >
              <Categories product={product} setProduct={setProduct} />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={selectedWindow === ProductEditorSelectedWindow.Gallery}
            >
              <Gallery product={product} setProduct={setProduct} />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={selectedWindow === ProductEditorSelectedWindow.Packaging}
            >
              <Packaging product={product} setProduct={setProduct} />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={
                selectedWindow === ProductEditorSelectedWindow.Parameters
              }
            >
              <Parameters product={product} setProduct={setProduct} />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={selectedWindow === ProductEditorSelectedWindow.Variants}
            >
              <Variants
                objectKey="variants"
                product={product}
                setProduct={setProduct}
              />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={
                selectedWindow === ProductEditorSelectedWindow.RelatedProducts
              }
            >
              <Variants
                objectKey="related_products"
                product={product}
                setProduct={setProduct}
              />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={
                selectedWindow === ProductEditorSelectedWindow.SubProducts
              }
            >
              <SubproductsView product={product} setProduct={setProduct} />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={
                selectedWindow === ProductEditorSelectedWindow.Description
              }
            >
              <Description
                product={product}
                setProduct={setProduct}
                error={errors.description}
              />
            </SelectedWindowStyled>
            <SelectedWindowStyled
              visible={
                selectedWindow === ProductEditorSelectedWindow.Manufacturer
              }
            >
              <Manufacturer product={product} setProduct={setProduct} />
            </SelectedWindowStyled>
            {product._id && (
              <SelectedWindowStyled
                visible={selectedWindow === ProductEditorSelectedWindow.History}
              >
                <HistoryView
                  item_id={product._id}
                  onRevertAction={() => {
                    if (!product._id) {
                      return;
                    }

                    getProductByIdCall(product._id);
                  }}
                />
              </SelectedWindowStyled>
            )}
          </Flex>
          <div
            style={{
              height: 70,
            }}
          />
        </BottomFixedWrapperWrapper>
      </ProductEditorErrorsContext.Provider>
    </LanguageContext.Provider>
  );
};

export default ProductEditor;
