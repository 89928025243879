import React, { MouseEvent } from "react";
import { useAppConfig } from "../../../common/redux-hooks";
import { NonTranslatedString } from "../../../constants/language";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useDispatch } from "../../../redux/store";
import { IPopulatedCategoryId } from "../../../types/category";
import { DeleteButton, EditButton } from "../../../ui/common";
import { REMOVE_CATEGORY_FROM_SUBCATEGORIES } from "./../../../redux/actions/categoryActions";
import { getFontSizeByLevel } from "./categories-list";

interface IProps {
  category: IPopulatedCategoryId;
  level?: number;
  parents?: string[];
}

export const Category = ({
  category,
  level,
  parents,
}: IProps): React.ReactElement<IProps> => {
  const { createConfirmation } = useConfirmation();
  const dispatch = useDispatch();

  const { language } = useAppConfig();

  const removeCategoryFromSubcategoriesClick = async (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();

    if (!parents || parents.length === 0) {
      return;
    }

    createConfirmation("Potvrzení: Odstranění podkategorie", async () => {
      dispatch(
        await REMOVE_CATEGORY_FROM_SUBCATEGORIES(
          parents[parents.length - 1],
          category._id
        )
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "2px 10px",
      }}
    >
      <div
        style={{
          fontSize: getFontSizeByLevel(level),
          marginRight: 12,
          fontWeight: 400,
        }}
      >
        {category.name[language.locale] || <NonTranslatedString />}
      </div>

      {level !== 0 ? (
        <DeleteButton
          data-title="Odstranit kategorii z nadkategorie"
          style={{
            padding: 3,
            marginRight: 4,
          }}
          onClick={removeCategoryFromSubcategoriesClick}
        />
      ) : null}

      <EditButton
        path={`edit-category/${category._id}`}
        data-title="Upravit kategorii"
        style={{
          padding: 4,
        }}
        size={16}
      />
    </div>
  );
};

export default Category;
