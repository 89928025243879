import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/button";
import { AddButtonActionStyles } from "./../../ui/common";

interface IProps {}

export const LanguagesActionButtons =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();

    return (
      <div>
        <Button style={AddButtonActionStyles} onClick={() => navigate("/languages/add-language")}>
          Přidat jazyk
        </Button>
      </div>
    );
  };

export default LanguagesActionButtons;
