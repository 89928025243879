import { IShippingID } from "../../types/shipping";
import axios from "../axios";

export interface IShippingModel {
  getShippings: () => Promise<IShippingID[]>;
  getShippingById: (id: string) => Promise<IShippingID>;
  addShipping: (data: FormData) => Promise<IShippingID>;
  editShipping: (data: FormData) => Promise<IShippingID>;
  removeShippingById: (id: string) => Promise<boolean>;
}

export const shippingModel: IShippingModel = {
  getShippings() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IShippingID[]>(`/shippings/get-all`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getShippingById(_id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IShippingID>(`/shippings/get-by-id`, {
          params: {
            _id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  addShipping(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IShippingID>(`/shippings/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editShipping(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IShippingID>(`/shippings/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeShippingById(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/shippings/remove-by-id`, {
          _id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
