import React from "react";
import styled from "styled-components";
import { ProductEditorSelectedWindow } from "../product-editor";
import Button from "../../../../ui/button";
import colors from "../../../../ui/colors";
import { FlexCenterAlign } from "../../../../ui/common";
import { useColors } from "../../../../providers/theme/theme-provider";
import { IThemeColors } from "../../../../providers/theme/themes";
import { IProductEditorErrorsObject } from "../editor-errors";
import { EditorType } from "../../../../types/common";

const WindowSelectorButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 8px;
  }
`;

interface IProps {
  errors: IProductEditorErrorsObject;
  hasParameterErrors: boolean;
  selectedWindow: ProductEditorSelectedWindow;
  setSelectedWindow(window: ProductEditorSelectedWindow): void;
  mode: EditorType;
}

const getBackgroundColor = (
  selectedWindow: ProductEditorSelectedWindow,
  is: ProductEditorSelectedWindow,
  colors: IThemeColors
): string | undefined => {
  return selectedWindow === is ? colors.MAIN_250 : undefined;
};

export const WindowSelector = ({
  errors,
  hasParameterErrors,
  selectedWindow,
  setSelectedWindow,
  mode,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <FlexCenterAlign
      style={{
        margin: "0px 8px",
        marginBottom: 20,
      }}
    >
      <WindowSelectorButtonsWrapper>
        <Button
          errorTooltipDisabled
          error={
            errors.ean ||
            errors.eshop_id ||
            errors.eshop_id_exists ||
            errors.name ||
            errors.slug_already_exists ||
            errors.slug_value_empty ||
            errors.slug_value_invalid ||
            errors.buy_price ||
            errors.price_vat
          }
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.BasicInfo,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.BasicInfo)
          }
        >
          Základní info
        </Button>
        <Button
          error={errors.description}
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.Description,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.Description)
          }
        >
          Popis
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.Packaging,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.Packaging)
          }
        >
          Balení
        </Button>
        <Button
          error={hasParameterErrors ? hasParameterErrors : undefined}
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.Parameters,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.Parameters)
          }
        >
          Parametry
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.Variants,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.Variants)
          }
        >
          Varianty
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.RelatedProducts,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.RelatedProducts)
          }
        >
          Související produkty
        </Button>
        <Button
          disabled={mode === EditorType.Add}
          data-title={
            mode === EditorType.Add
              ? "Podprodukty lze přidávat až u vytvořeného produktu"
              : undefined
          }
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.SubProducts,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.SubProducts)
          }
        >
          Podprodukty
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.Gallery,
              colors
            ),
          }}
          onClick={() => setSelectedWindow(ProductEditorSelectedWindow.Gallery)}
        >
          Galerie
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.Categories,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.Categories)
          }
        >
          Kategorie
        </Button>
        <Button
          style={{
            backgroundColor: getBackgroundColor(
              selectedWindow,
              ProductEditorSelectedWindow.Manufacturer,
              colors
            ),
          }}
          onClick={() =>
            setSelectedWindow(ProductEditorSelectedWindow.Manufacturer)
          }
        >
          Výrobce
        </Button>
        {mode !== EditorType.Add && (
          <Button
            style={{
              backgroundColor: getBackgroundColor(
                selectedWindow,
                ProductEditorSelectedWindow.History,
                colors
              ),
            }}
            onClick={() =>
              setSelectedWindow(ProductEditorSelectedWindow.History)
            }
          >
            Historie
          </Button>
        )}
      </WindowSelectorButtonsWrapper>
    </FlexCenterAlign>
  );
};

export default WindowSelector;
