import styled, { css } from "styled-components";

interface IOverlay {
  active?: boolean;
  zIndex?: number;
}

export const Overlay = styled.div<IOverlay>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  background-color: #000000a0;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 200ms ease, visibility 200ms ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  ${({zIndex}) => css`
    z-index: ${99999 + (zIndex ? zIndex : 0)};
  `}

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export default Overlay;
