import React from "react";
import { useColors } from "../../../providers/theme/theme-provider";
import { IPermissions } from "../../../types/role";
import AppleSwitcher from "../../../ui/apple-switcher";
import { FlexCenterAlign, InputsWrapper } from "../../../ui/common";

interface IProps {
  title: string;
  permission: IPermissions;
  setPermission(permission: IPermissions): void;
  canTranslate?: boolean;
  canChangePermissions?: boolean;
}

export const EditSinglePermission = ({
  title,
  permission,
  setPermission,
  canTranslate,
  canChangePermissions
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <InputsWrapper
      style={{
        marginBottom: 16,
        backgroundColor: colors.MAIN_400,
      }}
    >
      <div
        style={{
          fontSize: 14,
          fontWeight: 500,
          marginBottom: 4,
        }}
      >
        {title}
      </div>
      <FlexCenterAlign
        style={{
          marginBottom: 6,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: 13,
            marginRight: 12,
          }}
        >
          Zobrazit
        </div>
        <AppleSwitcher
          setValue={(view) => {
            if (!view) {
              setPermission({
                add: false,
                edit: false,
                remove: false,
                edit_language: false,
                view: false,
              });
            } else {
              setPermission({
                add: false,
                edit: false,
                remove: false,
                edit_language: false,
                view: true,
              });
            }
          }}
          value={
            permission.view ||
            permission.add ||
            permission.edit ||
            permission.edit_language ||
            permission.remove ||
            false
          }
        />
      </FlexCenterAlign>
      <FlexCenterAlign
        style={{
          marginBottom: 6,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: 13,
            marginRight: 12,
          }}
        >
          Přidat
        </div>
        <AppleSwitcher
          setValue={(add) => setPermission({ ...permission, add })}
          value={permission.add}
        />
      </FlexCenterAlign>
      <FlexCenterAlign
        style={{
          marginBottom: 6,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: 13,
            marginRight: 12,
          }}
        >
          Upravit
        </div>
        <AppleSwitcher
          setValue={(edit) =>
            setPermission({
              ...permission,
              edit,
            })
          }
          value={permission.edit}
        />
      </FlexCenterAlign>
      <FlexCenterAlign
        style={{
          marginBottom: 6,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: 13,
            marginRight: 12,
          }}
        >
          Vymazat
        </div>
        <AppleSwitcher
          setValue={(remove) => setPermission({ ...permission, remove })}
          value={permission.remove}
        />
      </FlexCenterAlign>
      {canTranslate && (
        <FlexCenterAlign
          style={{
            marginBottom: 6,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: 13,
              marginRight: 12,
            }}
          >
            Přeložit
          </div>
          <AppleSwitcher
            setValue={(edit_language) =>
              setPermission({ ...permission, edit_language })
            }
            value={permission.edit_language}
          />
        </FlexCenterAlign>
      )}
      {canChangePermissions && (
        <FlexCenterAlign
          style={{
            marginBottom: 6,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: 13,
              marginRight: 12,
            }}
          >
            Upravit práva
          </div>
          <AppleSwitcher
            setValue={(edit_permissions) =>
              setPermission({ ...permission, edit_permissions: edit_permissions || undefined })
            }
            value={!!permission.edit_permissions}
          />
        </FlexCenterAlign>
      )}
    </InputsWrapper>
  );
};

export default EditSinglePermission;
