import { IPaymentID } from "../../types/payment";
import { paymentModel } from "./../../model/query/paymentModel";
import { ErrorsActions } from "./errorsActions";

export enum PaymentActions {
  SET_PAYMENTS = "SET_PAYMENTS",
  ADD_PAYMENT = "ADD_PAYMENT",
  EDIT_PAYMENT = "EDIT_PAYMENT",
  REMOVE_PAYMENT_BY_ID = "REMOVE_PAYMENT_BY_ID",
}

type TSetPaymentsAction = {
  type: PaymentActions.SET_PAYMENTS;
  payload: IPaymentID[];
};

type TAddPaymentAction = {
  type: PaymentActions.ADD_PAYMENT;
  payload: IPaymentID;
};

type TEditPaymentAction = {
  type: PaymentActions.EDIT_PAYMENT;
  payload: IPaymentID;
};

type TRemovePaymentByIdAction = {
  type: PaymentActions.REMOVE_PAYMENT_BY_ID;
  payload: string;
};

export type TPaymentAction =
  | TSetPaymentsAction
  | TAddPaymentAction
  | TEditPaymentAction
  | TRemovePaymentByIdAction;

export const FETCH_AND_SET_PAYMENTS = async () => {
  try {
    const currencies = await paymentModel.getPayments();

    return {
      type: PaymentActions.SET_PAYMENTS,
      payload: currencies,
    };
  } catch (err) {
    throw err;
  }
};

export const ADD_PAYMENT = async (data: FormData) => {
  try {
    const Payment = await paymentModel.addPayment(data);

    return {
      type: PaymentActions.ADD_PAYMENT,
      payload: Payment,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_PAYMENT = async (data: FormData) => {
  try {
    const Payment = await paymentModel.editPayment(data);

    return {
      type: PaymentActions.EDIT_PAYMENT,
      payload: Payment,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_PAYMENT_BY_ID = async (id: string) => {
  try {
    await paymentModel.removePaymentById(id);

    return {
      type: PaymentActions.REMOVE_PAYMENT_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw err;
  }
};
