import React, { useCallback, useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import { RiArrowGoBackLine } from "react-icons/ri";
import { getFullName } from "../../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import {
  NonTranslatedString,
  nonTranslatedString,
  translateHistoryActionItemType,
  translateHistoryActionType,
} from "../../../constants/language";
import { historyActionsModel } from "../../../model/query/historyActionsModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { SimplePopupType } from "../../../providers/simple-popup/data";
import { useSimplePopups } from "../../../providers/simple-popup/simple-popup-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  HistoryActionItemType,
  HistoryActionType,
  IHistoryAction,
  RevertActionResult,
} from "../../../types/history";
import { FlexCenterAlign } from "../../../ui/common";
import IconButton from "../../../ui/icon-button";
import { ITEMS_PER_PAGE, TCell, TRow, Table } from "../../../ui/table";
import { getLocaleDateStringWithHoursAndMinutes } from "./../../../common/date";
import { useModal } from "./../../../providers/modal/modal-provider";
import { useAppConfig } from "../../../common/redux-hooks";
import HistoryViewChanges from "../../components/history/history-view-changes";
import LazyTable from "../../../ui/lazy-table";

const getHeaderKeys = (knownItem?: boolean) => {
  if (!knownItem) {
    return [
      { content: "", width: "124px" },
      { content: "Položka", width: "35%" },
      { content: "Typ", width: "15%" },
      { content: "Akce", width: "17.5%" },
      { content: "Vytvořil(a)", width: "20%" },
      { content: "Datum", width: "25%" },
    ];
  }

  return [
    { content: "", width: "100px" },
    { content: "Akce", width: "30%" },
    { content: "Vytvořil(a)", width: "35%" },
    { content: "Datum", width: "35%" },
  ];
};

const getCellsByAction = (
  action: IHistoryAction,
  appLanguage: string,
  knownItem?: boolean
): TCell[] => {
  const cells: TCell[] = [];

  if (!knownItem) {
    cells.push({
      content: (
        <span data-title={action.item_name[appLanguage] || nonTranslatedString}>
          {action.item_name[appLanguage] || <NonTranslatedString />}
        </span>
      ),
      key: "item_name",
      value: action.item_name,
    });

    cells.push({
      content: (
        <span
          style={{
            fontSize: 12,
          }}
        >
          {translateHistoryActionItemType(action.item_type)}
        </span>
      ),
      key: "item_type",
      value: action.item_type,
    });
  }

  return [
    ...cells,
    ...[
      {
        content: (
          <span
            style={{
              fontSize: 12,
            }}
          >
            {translateHistoryActionType(action.type)}
          </span>
        ),
        key: "type",
        value: action.type,
      },
      {
        content: (
          <span
            style={{
              fontSize: 12,
              opacity: 0.8,
            }}
          >
            {action.created_by
              ? getFullName(action.created_by)
              : "Neznámý uživatel"}
          </span>
        ),
        key: "created_by",
        value: action.created_by,
      },
      {
        content: (
          <span
            style={{
              fontSize: 12,
              opacity: 0.8,
            }}
          >
            {getLocaleDateStringWithHoursAndMinutes(new Date(action.date))}
          </span>
        ),
        key: "date",
        value: action.date,
      },
    ],
  ];
};

interface IProps {
  item_id?: string;
  onRevertAction?(): void;
}

export const HistoryView = ({
  item_id,
  onRevertAction,
}: IProps): React.ReactElement<IProps> => {
  const { createModal } = useModal();
  const { language } = useAppConfig();
  const colors = useColors();
  const [totalCount, setTotalCount] = useState(0);
  const [historyActions, setHistoryActions] = useState<IHistoryAction[]>([]);
  const knownItem = !!item_id;
  const { call } = useQueryWithPopupErrorHandling();

  const onSetPage = useCallback(
    (page: number) => {
      if (item_id) {
        call(
          () =>
            historyActionsModel.getHistoryActionsByItemId(
              item_id,
              page * ITEMS_PER_PAGE,
              ITEMS_PER_PAGE
            ),
          "Při získávání historie nastala chyba",
          null,
          ({ historyActions, totalCount }) => {
            setHistoryActions(historyActions);
            setTotalCount(totalCount);
          }
        );
      } else {
        call(
          () =>
            historyActionsModel.getAllHistoryActions(
              page * ITEMS_PER_PAGE,
              ITEMS_PER_PAGE
            ),
          "Při získávání historie nastala chyba",
          null,
          ({ historyActions, totalCount }) => {
            setHistoryActions(historyActions);
            setTotalCount(totalCount);
          }
        );
      }
    },
    [item_id]
  );

  useEffect(() => {
    onSetPage(0);
  }, [item_id]);

  const viewAction = (action: IHistoryAction) => {
    createModal(<HistoryViewChanges action={action} />);
  };

  const { createConfirmation } = useConfirmation();
  const { createSimplePopup } = useSimplePopups();

  const revertAction = (action: IHistoryAction) => {
    createConfirmation("Opravdu chcete tuto změnu vrátit?", () => {
      call(
        () => historyActionsModel.revertHistoryAction(action._id),
        "Vrácení změny nebylo úspěšné",
        null,
        (result) => {
          if (result === RevertActionResult.SUCCESS) {
            createSimplePopup(
              "Vrácení změny bylo úspěšné.",
              SimplePopupType.Success
            );
          }

          if (result === RevertActionResult.HAS_SOME_UNAVAILABLE_CHANGES) {
            createSimplePopup(
              "Vrácení změny bylo úspěšné. Některé změny nebylo možné vrátit, protože již nejsou dostupné.",
              SimplePopupType.Warning
            );
          }

          if (result === RevertActionResult.IS_SAME) {
            createSimplePopup(
              "Vrácení změny nepřineslo žádné změny.",
              SimplePopupType.Warning
            );
          }

          if (
            result ===
            RevertActionResult.IS_SAME_WITH_UNAVAILABLE_CHANGES_REMOVED
          ) {
            createSimplePopup(
              "Vrácení změny nepřineslo žádné změny. Změny, které již nejsou dostupné, byly vymazány.",
              SimplePopupType.Warning
            );
          }

          if (result === RevertActionResult.IS_SAME_ACTION_REMOVED) {
            createSimplePopup(
              "Vrácení změny nepřineslo žádné změny. Změna byla vymazána, protože před a po je stejné.",
              SimplePopupType.Warning
            );
          }

          onRevertAction?.();
        }
      );
    });
  };

  const goToItem = (action: IHistoryAction) => {
    if (action.type === HistoryActionType.REMOVE || !action.item_id?._id) {
      return;
    }

    if (action.item_type === HistoryActionItemType.PRODUCT) {
      window.open(`/products/edit-product/${action.item_id._id}`);
    }

    if (action.item_type === HistoryActionItemType.CATEGORY) {
      window.open(`/categories/edit-category/${action.item_id._id}`);
    }
  };

  const items: TRow[] = historyActions
    .sort((a, b) => b.date - a.date)
    .map((action) => ({
      cells: [
        {
          key: "actions",
          value: "",
          content: (
            <FlexCenterAlign>
              <IconButton
                onClick={() => viewAction(action)}
                data-title="Zobrazit změny"
                style={{
                  padding: 5,
                  backgroundColor: colors.MAIN_200,
                  marginRight: 6,
                }}
                hoverBackgroundColor={colors.MAIN_150}
              >
                <FiEye size={18} color={colors.OPPOSITE_MAIN_400} />
              </IconButton>
              <IconButton
                style={{
                  padding: 6,
                  backgroundColor: colors.ERROR_400,
                  marginRight: 6,
                }}
                hoverBackgroundColor={colors.ERROR_500}
                data-title="Vrátit zpět"
                onClick={() => revertAction(action)}
              >
                <RiArrowGoBackLine size={16} color="#fff" />
              </IconButton>
              {!knownItem && (
                <IconButton
                  disabled={
                    action.type === HistoryActionType.REMOVE ||
                    !action.item_id?._id
                  }
                  style={{
                    padding: 6,
                    backgroundColor: colors.MAIN_300,
                    marginRight: 6,
                  }}
                  hoverBackgroundColor={colors.MAIN_250}
                  data-title="Přejít na položku"
                  onClick={() => goToItem(action)}
                >
                  <HiOutlineExternalLink size={16} color="#fff" />
                </IconButton>
              )}
            </FlexCenterAlign>
          ),
        },
        ...getCellsByAction(action, language.locale, knownItem),
      ],
    }));

  return (
    <div
      style={{
        width: 800,
      }}
    >
      <LazyTable
        onSetPage={onSetPage}
        totalCount={totalCount}
        headerKeys={getHeaderKeys(knownItem)}
        rows={items}
      />
    </div>
  );
};

export default HistoryView;
