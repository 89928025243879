import axios from "../axios";
import { IStore, IStoreID } from "../../types/store";

export interface IStoreModel {
  getStores: () => Promise<IStore[]>;
  getDefaultStore: () => Promise<IStore>;
  getStoreById: (id: string) => Promise<IStoreID>;
  addStore: (data: FormData) => Promise<IStoreID>;
  editStore: (data: FormData) => Promise<IStoreID>;
  removeStoreById: (id: string) => Promise<boolean>;
  editTermsAndConditions: (terms: string, _id: string) => Promise<IStoreID>;
  editComplaintsInfo: (complaintsInfo: string, _id: string) => Promise<IStoreID>;
  editPrivacyPolicy: (privacyPolicy: string, _id: string) => Promise<IStoreID>;
}

export const storeModel: IStoreModel = {
  getStores() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IStoreID[]>(`/stores/get-all`);

        res(response.data);
      } catch (err) {
        rej(err);
      }
    });
  },
  getDefaultStore() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IStoreID>(
          `/stores/get-default-store`
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getStoreById(id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IStoreID>(`/stores/get-by-id`, {
          params: {
            id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  addStore(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IStoreID>(`/stores/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editStore(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IStoreID>(`/stores/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editTermsAndConditions(terms, _id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IStoreID>(
          `/stores/edit-terms-and-conditions`,
          { terms, _id }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editComplaintsInfo(complaintsInfo, _id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IStoreID>(
          `/stores/edit-complaints-info`,
          { complaintsInfo, _id }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editPrivacyPolicy(privacyPolicy, _id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IStoreID>(
          `/stores/edit-privacy-policy`,
          { privacyPolicy, _id }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeStoreById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/stores/remove-by-id`, {
          id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
