import React, { useEffect, useState } from "react";
import {
  useAppConfig,
  useProductFilterQuery,
  useProductFilters,
} from "../../common/redux-hooks";
import {
  NonTranslatedString,
  nonTranslatedString,
} from "../../constants/language";
import {
  IQueryFilters,
  ProductsSortBy,
  productModel,
} from "../../model/query/productModel";
import {
  SET_PRODUCT_FILTERS,
  SET_PRODUCT_QUERY,
} from "../../redux/actions/filterActions";
import { useDispatch } from "../../redux/store";
import { ICurrencyID } from "../../types/currency";
import { IProduct, IUnpopulatedProduct } from "../../types/products";
import Checkbox from "../../ui/checkbox";
import { EditButton, FlexCenterAll } from "../../ui/common";
import LazyLoadingGalleryImage from "../../ui/lazy-loading-image";
import LazyTable, { TSelectRowId } from "../../ui/lazy-table";
import { SortByType, THeaderKey, TRow, TSortBy } from "../../ui/table";
import SelectedProducts from "./selected-products";
import { IStoreID } from "../../types/store";

interface IRowActionButtons {
  product: IUnpopulatedProduct;
}

const RowActionButtons = ({
  product,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  return (
    <FlexCenterAll>
      <EditButton
        path={`/products/edit-product/${product._id}`}
        data-title="Upravit produkt"
      />
    </FlexCenterAll>
  );
};

const mapProductsToRows = (
  products: IUnpopulatedProduct[],
  locale: string,
  store: IStoreID,
  selectable: boolean,
  currency: ICurrencyID | null,
  disabledIDs?: string[]
): TRow[] => {
  return products.map((p) => {
    return {
      value: p,
      selectable: true,
      id: p._id,
      disabled: disabledIDs?.includes(p._id),
      cells: [
        {
          content: !selectable ? <RowActionButtons product={p} /> : null,
          key: "actions",
          value: null,
        },
        {
          content: (
            <FlexCenterAll>
              <LazyLoadingGalleryImage
                style={{
                  maxHeight: 96,
                  minHeight: 96,
                  width: "auto",
                  minWidth: "auto",
                  objectFit: "contain",
                }}
                src={p.image?.url || ""}
              />
            </FlexCenterAll>
          ),
          key: "image",
          value: p.image,
        },
        {
          content: p.eshop_id,
          key: "eshop_id",
          value: p.eshop_id,
        },
        {
          content: (
            <div
              style={{
                fontSize: 14,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {p.name[locale] || <NonTranslatedString />}
            </div>
          ),
          key: "name",
          value: p.name[locale] || nonTranslatedString,
        },
        {
          content: (
            <FlexCenterAll
              style={{
                marginLeft: -16,
              }}
            >
              <Checkbox
                readonly
                value={p.stores.includes(store._id)}
                setValue={() => null}
              />
            </FlexCenterAll>
          ),
          key: "available_on_eshop",
          value: p.stores.includes(store._id),
        },
        {
          content: (
            <div
              style={{
                fontSize: 12,
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              {p.stock}
            </div>
          ),
          key: "stock",
          value: p.stock,
        },
        {
          content: (
            <div
              style={{
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              {p.price_vat[locale]} {currency?.symbol}
            </div>
          ),
          key: "price_vat",
          value: p.price_vat,
        },
        {
          content: new Date(p.added_date).toLocaleDateString(),
          key: "added_date",
          value: p.added_date,
        },
      ],
    };
  });
};

const PRODUCT_TABLE_HEADER_KEYS: THeaderKey[] = [
  { content: "", query_key: "", width: "80px" },
  {
    content: <div style={{ textAlign: "center", fontSize: 12 }}>Foto</div>,
    query_key: "image",
    width: "120px",
  },
  {
    content: "Kód",
    width: "140px",
    query_key: "eshop_id",
    sort_by_key: ProductsSortBy.EshopID,
  },
  {
    content: "Název produktu",
    query_key: "name",
    sort_by_key: ProductsSortBy.Name,
  },
  {
    content: (
      <div
        style={{
          textAlign: "center",
          fontSize: 12,
        }}
      >
        Dostupné v obchodě
      </div>
    ),
    width: "120px",
    query_key: "available_on_eshop",
    sort_by_key: ProductsSortBy.AvailableOnEshop,
  },
  {
    content: (
      <div
        style={{
          textAlign: "center",
          fontSize: 12,
        }}
      >
        Počet kusů na skladě
      </div>
    ),
    width: "120px",
    query_key: "stock",
    sort_by_key: ProductsSortBy.Stock,
  },
  {
    content: "Cena vč. DPH",
    query_key: "price_vat",
    sort_by_key: ProductsSortBy.PriceVat,
  },
  {
    content: "Přidáno",
    query_key: "added_date",
    sort_by_key: ProductsSortBy.AddedDate,
  },
];

const productListLocalStorageKey = "__product-list-filters";

const saveQueryFiltersToLocalStorage = (filters: IQueryFilters) => {
  localStorage.setItem(productListLocalStorageKey, JSON.stringify(filters));
};

const getQueryFiltersFromLocalStorage = (): IQueryFilters | null => {
  try {
    const localStorageItem = localStorage.getItem(productListLocalStorageKey);

    if (!localStorageItem) {
      return null;
    }

    return JSON.parse(localStorageItem);
  } catch (err) {
    localStorage.removeItem(productListLocalStorageKey);
    return null;
  }
};

interface IProps {
  mustExactlyMatchQuery: boolean;
  selectedStoreOnly: boolean;
  onSelect?(product: IProduct): void;
  disabledIDs?: string[];
}

export const ProductsList = ({
  mustExactlyMatchQuery,
  selectedStoreOnly,
  onSelect,
  disabledIDs,
}: IProps): React.ReactElement<IProps> => {
  const [queryLoading, setQueryLoading] = useState(true);
  const [sortBy, setSortBy] = useState<TSortBy>({
    key: "",
    type: SortByType.None,
  });
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();

  const { language, store } = useAppConfig();

  const filters = useProductFilters();
  const query = useProductFilterQuery();

  const [page, setPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState<TSelectRowId[]>([]);
  const [rows, setRows] = useState<TRow[]>([]);
  const [canFetchProducts, setCanFetchProducts] = useState(false);

  useEffect(() => {
    setPage(0);
  }, [query]);

  useEffect(() => {
    const queryFilters = getQueryFiltersFromLocalStorage();

    if (queryFilters) {
      const { filters, page, query, sortBy } = queryFilters;

      if (sortBy) {
        setSortBy(sortBy);
      }

      if (page) {
        setPage(page);
      }

      if (query) {
        dispatch(SET_PRODUCT_QUERY(query));
      }

      if (filters) {
        dispatch(SET_PRODUCT_FILTERS(filters));
      }

      setTimeout(() => {
        setCanFetchProducts(true);
      }, 10);
    }

    setCanFetchProducts(true);
  }, []);

  const getFilteredProducts = async () => {
    if (!canFetchProducts) {
      return;
    }

    setQueryLoading(true);

    const queryFilters: IQueryFilters = {
      query,
      mustMatchExactly: mustExactlyMatchQuery,
      language: language._id,
      store: selectedStoreOnly ? store._id : undefined,
      filters,
      sortBy: sortBy.type !== SortByType.None ? sortBy : undefined,
      page,
    };

    saveQueryFiltersToLocalStorage(queryFilters);
    const { products, totalCount } = await productModel.getFilteredProducts(
      queryFilters
    );

    setQueryLoading(false);

    setTotalCount(totalCount);
    setRows(
      mapProductsToRows(
        products,
        language.locale,
        store,
        !!onSelect,
        language.currency,
        disabledIDs
      )
    );
  };

  useEffect(() => {
    getFilteredProducts();
  }, [
    query,
    mustExactlyMatchQuery,
    language,
    filters,
    sortBy,
    page,
    selectedStoreOnly,
    store,
    canFetchProducts,
  ]);

  const onSelectRowId = (rowId: TSelectRowId) => {
    if (selectedRows.some((id) => id.id === rowId.id)) {
      setSelectedRows((rows) => rows.filter((id) => id.id !== rowId.id));
      return;
    }

    setSelectedRows((rows) => [...rows, rowId]);
  };

  return (
    <div
      style={{
        marginRight: 20,
        marginBottom: 40,
      }}
    >
      <SelectedProducts
        selectedProducts={selectedRows}
        setSelectedProducts={setSelectedRows}
      />
      <LazyTable
        selectableRowWidth={"50px"}
        selectedPage={page}
        selectedSortBy={sortBy}
        customRowHeight={104}
        totalCount={totalCount}
        onSetPage={(page) => setPage(page)}
        onSortByChange={setSortBy}
        isLoading={queryLoading}
        onSelectRow={onSelect ? (row) => onSelect(row.value) : undefined}
        headerKeys={PRODUCT_TABLE_HEADER_KEYS}
        rows={rows}
        onSelectRowId={onSelectRowId}
        selectedRowIDs={selectedRows}
        selectRows={(rows) =>
          setSelectedRows((selectedRows) => {
            const arr = [...selectedRows];

            rows.forEach((r) => {
              if (arr.some((s) => s.id === r.id)) {
                return;
              }

              arr.push(r);
            });

            return arr;
          })
        }
        deselectRows={(rows) =>
          setSelectedRows((selectedRows) =>
            selectedRows.filter((row) => !rows.some((r) => r.id === row.id))
          )
        }
      />
    </div>
  );
};

export default ProductsList;
