import React, { useCallback, useEffect, useState } from "react";
import { useConfirmation } from "../../../../providers/confirmation/confirmation-provider";
import { useModal } from "../../../../providers/modal/modal-provider";
import { useNotifications } from "../../../../providers/notifications/notification-provider";
import { useColors } from "../../../../providers/theme/theme-provider";
import { EditorType } from "../../../../types/common";
import {
  IParameterID,
  IParameterValueID,
  IProductParameter,
} from "../../../../types/parameter";
import { IEditorProduct } from "../../../../types/products";
import { ISubProduct } from "../../../../types/subproduct";
import Button from "../../../../ui/button";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
} from "../../../../ui/common";
import { InputWithTitle } from "../../../../ui/input-with-title";
import ParametersInput, {
  ParameterInput,
} from "../components/parameters-input/parameters-input";
import { getSubproductErrors } from "./subproduct-editor-errors";

const mapSubProductToEditorSubProduct = (
  subProduct: ISubProduct
): IEditorSubProduct => {
  const { parameters, stock } = subProduct;

  return {
    parameters,
    stock,
  };
};

const initialEditorSubProduct: IEditorSubProduct = {
  parameters: [],
  stock: 0,
};

export interface IEditorSubProduct {
  parameters: IProductParameter[];
  stock: number;
}

interface IProps {
  type: EditorType;
  product: IEditorProduct;
  setProduct: React.Dispatch<React.SetStateAction<IEditorProduct>>;
  initialSubProduct?: ISubProduct;
  index?: number;
}

export const SubProductEditor = ({
  type,
  product,
  setProduct,
  initialSubProduct,
  index,
}: IProps): React.ReactElement<IProps> => {
  const [subProduct, setSubProduct] = useState<IEditorSubProduct>(
    initialSubProduct
      ? mapSubProductToEditorSubProduct(initialSubProduct)
      : initialEditorSubProduct
  );

  const { closeNotification } = useNotifications();

  const { createConfirmation } = useConfirmation();
  const colors = useColors();
  const { closeModal } = useModal();

  useEffect(() => {
    return () => {
      closeNotification();
    };
  }, []);

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {}
  );
  const subProductErrors = getSubproductErrors(subProduct, errors);

  const validateSubProduct = () => {
    if (subProduct.parameters.length === 0) {
      setErrors((e) => ({
        ...e,
        no_parameters: "Nejsou zvolené žádné parametry",
      }));
      return false;
    }

    if (
      !subProduct.parameters.every((p) => {
        if (!p.parameter) {
          return false;
        }

        if (!p.value_id && !p.value_number) {
          return false;
        }

        return true;
      })
    ) {
      setErrors((e) => ({
        ...e,
        parameters_not_valid: "Zvolené parametry nejsou validní",
      }));
      return false;
    }

    if (
      subProduct.stock === undefined ||
      isNaN(subProduct.stock) ||
      subProduct.stock < 0
    ) {
      setErrors((e) => ({
        ...e,
        stock: "Počet kusů skladem musí definován",
      }));
      return false;
    }

    return true;
  };

  const addSubProduct = () => {
    const isValid = validateSubProduct();

    if (!isValid) {
      return;
    }

    if (
      product.subproducts.some((s) => {
        if (s.parameters.length === subProduct.parameters.length) {
          if (
            s.parameters.every((param) =>
              subProduct.parameters.find((p) => {
                if (
                  param.parameter?._id === p.parameter?._id &&
                  param.value_id?._id === p.value_id?._id &&
                  param.value_number === p.value_number
                ) {
                  return true;
                }

                return false;
              })
            )
          ) {
            return true;
          }
        }

        return false;
      })
    ) {
      setErrors((e) => ({
        ...e,
        subproduct_exists: "Podprodukt se stejnými parametry již existuje",
      }));
      return;
    }

    setProduct((p) => ({
      ...p,
      subproducts: [...p.subproducts, subProduct as ISubProduct],
    }));
    closeModal();
  };

  useEffect(() => {
    setErrors((e) => ({
      ...e,
      subproduct_exists: undefined,
      parameters_not_valid: undefined,
      no_parameters: undefined,
    }));
  }, [subProduct.parameters]);

  const editSubProduct = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit podprodukt", async () => {
      const isValid = validateSubProduct();

      if (
        product.subproducts.some((s, i) => {
          if (i === index) {
            return false;
          }

          if (s.parameters.length === subProduct.parameters.length) {
            if (
              s.parameters.every((param) =>
                subProduct.parameters.find((p) => {
                  if (
                    param.parameter?._id === p.parameter?._id &&
                    param.value_id?._id === p.value_id?._id &&
                    param.value_number === p.value_number
                  ) {
                    return true;
                  }

                  return false;
                })
              )
            ) {
              return true;
            }
          }

          return false;
        })
      ) {
        setErrors((e) => ({
          ...e,
          subproduct_exists: "Podprodukt se stejnými parametry již existuje",
        }));
        return;
      }

      if (!isValid || !initialSubProduct) {
        return;
      }

      setProduct((p) => {
        const newSubproducts = [...p.subproducts];

        if (index === undefined) {
          return p;
        }

        newSubproducts[index] = subProduct as ISubProduct;

        return {
          ...p,
          subproducts: newSubproducts,
        };
      });

      if (exit) {
        closeModal();
      }
    });
  };

  const removeSubProduct = () => {
    createConfirmation("Potvrzení: Odstranit podprodukt", async () => {
      if (!initialSubProduct) {
        return;
      }

      setProduct((p) => {
        if (index === undefined) {
          return p;
        }

        return {
          ...p,
          subproducts: p.subproducts.filter((_, i) => i !== index),
        };
      });
      closeModal();
    });
  };

  const isValid = !(
    Object.values(subProductErrors).filter((e) => !!e).length > 0
  );

  return (
    <div>
      <Button onClick={closeModal}>Zavřít</Button>
      <div
        style={{
          marginTop: 40,
          fontSize: 28,
          marginLeft: 16,
          opacity: 0.444,
          marginBottom: 10,
        }}
      >
        {type === EditorType.Add ? "Přidání podproduktu" : "Úprava podproduktu"}
      </div>

      <Flex>
        <div>
          <Flex>
            <InputsWrapper>
              <div
                style={{
                  marginLeft: 4,
                  fontSize: 13,
                  marginBottom: 8,
                }}
              >
                Parametr podproduktu
              </div>
              <ParametersInput
                params={subProduct.parameters}
                setParams={(parameters) =>
                  setSubProduct((s) => ({ ...s, parameters }))
                }
              />
              <div style={{ marginBottom: 20 }}>
                <InputWithTitle
                  inputProps={{ type: "number", error: subProductErrors.stock }}
                  setValue={(stock) => setSubProduct((s) => ({ ...s, stock }))}
                  title="Počet kusů skladem"
                  value={subProduct.stock}
                />
              </div>
            </InputsWrapper>
          </Flex>
        </div>
      </Flex>
      <FlexCenterAll
        style={{
          marginBottom: 20,
        }}
      >
        {type === EditorType.Add ? (
          <Button
            style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
            error={
              subProductErrors.subProductExists ||
              subProductErrors.parametersNotValid ||
              subProductErrors.noParameters
            }
            hoverBackgroundColor={colors.MAIN_250}
            disabled={!isValid}
            onClick={addSubProduct}
          >
            Přidat podprodukt
          </Button>
        ) : (
          <FlexCenterAlign>
            <div
              style={{
                marginRight: 16,
              }}
            >
              <AddButton
                error={
                  subProductErrors.subProductExists ||
                  subProductErrors.parametersNotValid ||
                  subProductErrors.noParameters
                }
                disabled={!isValid}
                onClick={() => editSubProduct()}
              >
                Uložit
              </AddButton>
            </div>
            <div
              style={{
                marginRight: 16,
              }}
            >
              <AddButton
                disabled={!isValid}
                error={
                  subProductErrors.subProductExists ||
                  subProductErrors.parametersNotValid ||
                  subProductErrors.noParameters
                }
                onClick={() => editSubProduct(true)}
              >
                Uložit a zavřít
              </AddButton>
            </div>
            <RemoveButton
              style={{
                ...RemoveButtonStyles,
                display: "block",
              }}
              onClick={removeSubProduct}
            >
              Odstranit
            </RemoveButton>
          </FlexCenterAlign>
        )}
      </FlexCenterAll>
    </div>
  );
};

export default SubProductEditor;
