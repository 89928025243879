import React from "react";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../common/redux-hooks";
import { isDisabledByRole } from "../../types/role";
import Button from "../../ui/button";
import { AddButtonActionStyles } from "../../ui/common";

interface IProps {}

export const CategoryActionButtons =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();
    const role = useRole();

    return (
      <div>
        <Button
          disabled={isDisabledByRole(role, {
            type: "category",
            permission: "add",
          })}
          style={AddButtonActionStyles}
          onClick={() => navigate("/categories/add-category")}
        >
          Přidat kategorii
        </Button>
      </div>
    );
  };

export default CategoryActionButtons;
