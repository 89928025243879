import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useQueryWithPopupErrorHandling } from "../../../../common/hooks";
import { useErrors, useUser } from "../../../../common/redux-hooks";
import { useModal } from "../../../../providers/modal/modal-provider";
import { useColors } from "../../../../providers/theme/theme-provider";
import { useDispatch } from "../../../../redux/store";
import { ErrorType } from "../../../../types/error";
import { FlexCenterAll } from "../../../../ui/common";
import { InputWithTitle } from "../../../../ui/input-with-title";
import { getEditUserInfoErrors } from "./user-info-editor-errors";

export enum UserInfoEditorType {
  Me,
  Edit,
  Add,
}

export interface IUserInfo {
  userName: string;
  firstName: string;
  lastName: string;
}

interface IProps {
  userInfo: IUserInfo;
  setUserInfo: Dispatch<SetStateAction<IUserInfo>>;
}

export const UserInfoEditor = ({
  userInfo,
  setUserInfo,
}: IProps): React.ReactElement<IProps> => {
  const { getError, clearErrorsOfType } = useErrors();
  const { errors } = getEditUserInfoErrors(getError, userInfo);

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.EDIT_USER_INFO);
    };
  }, []);

  return (
    <FlexCenterAll
      style={{
        padding: 12,
      }}
    >
      <form autoComplete="off">
        <div>
          <InputWithTitle
            title="Křestní jméno"
            inputProps={{
              style: {
                width: 190,
              },
              wrapperStyle: {
                width: 190,
              },
              error: errors.first_name_empty,
            }}
            setValue={(firstName) => setUserInfo((u) => ({ ...u, firstName }))}
            value={userInfo.firstName}
          />

          <InputWithTitle
            title="Příjmení"
            inputProps={{
              style: {
                width: 190,
              },
              wrapperStyle: {
                width: 190,
              },
              error: errors.last_name_empty,
            }}
            setValue={(lastName) => setUserInfo((u) => ({ ...u, lastName }))}
            value={userInfo.lastName}
          />

          <InputWithTitle
            title="Přihlašovací jméno"
            inputProps={{
              style: {
                width: 190,
              },
              wrapperStyle: {
                width: 190,
              },
              error: errors.user_name_empty || errors.user_name_already_exists,
            }}
            setValue={(userName) => setUserInfo((u) => ({ ...u, userName }))}
            value={userInfo.userName}
          />
        </div>
      </form>
    </FlexCenterAll>
  );
};

export default UserInfoEditor;
