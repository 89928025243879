import { ICurrencyID } from "../../types/currency";
import { adminRegistrationModel } from "./../../model/query/adminRegistrationModel";
import { currencyModel } from "./../../model/query/currencyModel";
import { ErrorsActions } from "./errorsActions";

export enum CurrencyActions {
  SET_CURRENCIES = "SET_CURRENCIES",
  ADD_CURRENCY = "ADD_CURRENCY",
  EDIT_CURRENCY = "EDIT_CURRENCY",
  REMOVE_CURRENCY_BY_ID = "REMOVE_CURRENCY_BY_ID",
}

type TSetCurrenciesAction = {
  type: CurrencyActions.SET_CURRENCIES;
  payload: ICurrencyID[];
};

type TAddCurrencyAction = {
  type: CurrencyActions.ADD_CURRENCY;
  payload: ICurrencyID;
};

type TEditCurrencyAction = {
  type: CurrencyActions.EDIT_CURRENCY;
  payload: ICurrencyID;
};

type TRemoveCurrencyByIdAction = {
  type: CurrencyActions.REMOVE_CURRENCY_BY_ID;
  payload: string;
};

export type TCurrencyAction =
  | TSetCurrenciesAction
  | TAddCurrencyAction
  | TEditCurrencyAction
  | TRemoveCurrencyByIdAction;

export const FETCH_AND_SET_CURRENCIES = async () => {
  try {
    const currencies = await currencyModel.getCurrencies();

    return {
      type: CurrencyActions.SET_CURRENCIES,
      payload: currencies,
    };
  } catch (err) {
    return false;
  }
};

export const SET_CURRENCIES = (currencies: ICurrencyID[]) => {
  return {
    type: CurrencyActions.SET_CURRENCIES,
    payload: currencies,
  };
};

export const ADD_CURRENCY = async (data: FormData) => {
  try {
    const Currency = await currencyModel.addCurrency(data);

    return {
      type: CurrencyActions.ADD_CURRENCY,
      payload: Currency,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_CURRENCY = async (data: FormData) => {
  try {
    const Currency = await currencyModel.editCurrency(data);

    return {
      type: CurrencyActions.EDIT_CURRENCY,
      payload: Currency,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const ADD_DEFAULT_CURRENCY = async (data: FormData) => {
  try {
    const Currency = await adminRegistrationModel.addDefaultCurrency(data);

    return {
      type: CurrencyActions.ADD_CURRENCY,
      payload: Currency,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_DEFAULT_CURRENCY = async (data: FormData) => {
  try {
    const Currency = await adminRegistrationModel.editDefaultCurrency(data);

    return {
      type: CurrencyActions.EDIT_CURRENCY,
      payload: Currency,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_CURRENCY_BY_ID = async (id: string) => {
  try {
    await currencyModel.removeCurrencyById(id);

    return {
      type: CurrencyActions.REMOVE_CURRENCY_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw err;
  }
};
