import React, { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface IIconButtonStyled {
  hoverBackgroundColor?: string;
  hoverColor?: string;
}

export const IconStyledWrapper = styled.div`
  ${(props) => css`
    background-color: ${props.theme.colors.OPPOSITE_MAIN_350};
  `}

  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  padding: 8px;
  transition: opacity 100ms ease;
`;

const IconButtonStyled = styled.button<IIconButtonStyled>`
  ${(props) => css`
    background-color: ${props.theme.colors.OPPOSITE_MAIN_350};
    border-radius: ${props.theme.borderRadius}px;
  `}

  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  padding: 8px;
  transition: opacity 100ms ease;

  svg {
    color: inherit;
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ${({ hoverColor, hoverBackgroundColor }) => css`
    &:hover {
      background-color: ${`${hoverBackgroundColor} !important` || "#eee"};
      color: ${`${hoverColor} !important` || "unset"};
    }
  `}
`;

export interface IIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  hoverBackgroundColor?: string;
  hoverColor?: string;
  children?: React.ReactNode;
}

export const IconButton = ({
  children,
  ...props
}: IIconButtonProps): React.ReactElement<IIconButtonProps> => {
  return <IconButtonStyled {...props}>{children}</IconButtonStyled>;
};

export default IconButton;
