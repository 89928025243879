import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import {
  useAppConfig,
  useErrors,
  useLanguages,
  useStores,
} from "../../common/redux-hooks";
import { couponsModel } from "../../model/query/couponModel";
import { useConfirmation } from "../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../providers/notifications/notification-provider";
import { useColors } from "../../providers/theme/theme-provider";
import { useDispatch } from "../../redux/store";
import {
  EditorType,
  TLanguageBoolean,
  TLanguageNumber,
} from "../../types/common";
import { CouponType, ICouponID, couponTypes } from "../../types/coupon";
import { ErrorType } from "../../types/error";
import { ILanguageID } from "../../types/language";
import BottomFixedWrapperWrapper from "../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../ui/button";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
} from "../../ui/common";
import { InputWithTitle } from "../../ui/input-with-title";
import BackButton from "../components/back-button";
import { getCouponEditorErrors } from "./editor-errors";
import Dropdown from "../../ui/dropdown";
import { translateCouponType } from "../../constants/language";
import LanguageSelector from "../../ui/language-selector";
import { generateCouponCode } from "./generate-coupon";
import Checkbox from "../../ui/checkbox";
import { ADD_COUPON, EDIT_COUPON } from "../../redux/actions/couponsActions";
import CalendarButton from "../../ui/calendar-button";
import { SelectedDateType } from "../../ui/calendar/types";
import { IStoreID } from "../../types/store";
import DropdownMultiSelect from "../../ui/dropdown-multi-select";

const mapCouponToEditorCoupon = (coupon: ICouponID): IEditorCoupon => {
  const { stores, key, validUntil, value, type, maxNumberOfUses } = coupon;

  return {
    stores,
    key,
    validUntil,
    value,
    type,
    maxNumberOfUses,
  };
};

const initialEditorCoupon: IEditorCoupon = {
  stores: [],
  key: "",
  validUntil: -1,
  type: CouponType.Percent,
  value: {},
  maxNumberOfUses: 1,
};

export interface IEditorCoupon {
  key: string;
  value: TLanguageNumber;
  validUntil: number;
  type: CouponType;
  stores: IStoreID[];
  maxNumberOfUses: number;
}

interface IProps {
  type: EditorType;
}

export const CouponEditor = ({ type }: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const [originalEditingCoupon, setOriginalEditingCoupon] =
    useState<ICouponID | null>(null);
  const [coupon, setCoupon] = useState<IEditorCoupon>(initialEditorCoupon);
  const [changeEditorValidUntil, setChangeEditorValidUntil] = useState(false);
  const stores = useStores();
  const appConfig = useAppConfig();
  const [language, setLanguage] = useState(appConfig.language);
  const { closeNotification } = useNotifications();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getCouponEditorErrors(
    getError,
    coupon,
    language.locale
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useQueryWithPopupErrorHandling();
  const { createConfirmation } = useConfirmation();
  const colors = useColors();

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.COUPON);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getCoupon = async (id: string) => {
      call(
        () => couponsModel.getCouponById(id),
        "Zobrazení slevového kupónu nebylo úspěšné",
        null,
        (Coupon) => {
          const c = mapCouponToEditorCoupon(Coupon);

          setOriginalEditingCoupon(Coupon);
          setChangeEditorValidUntil(c.validUntil !== -1);
          setCoupon(c);
        },
        () => {
          navigate("/coupons");
        }
      );
    };

    if (_id && type === EditorType.Edit) {
      getCoupon(_id);
    }
  }, [_id, type]);

  const appendCouponToForm = useCallback(
    (form: FormData) => {
      form.append("key", coupon.key);
      form.append("value", JSON.stringify(coupon.value));
      form.append("type", coupon.type);
      form.append("validUntil", coupon.validUntil.toString());
      form.append("maxNumberOfUses", coupon.maxNumberOfUses.toString());
      form.append(
        "stores",
        JSON.stringify(coupon.stores.map((store) => store._id))
      );
    },
    [coupon]
  );

  const addCoupon = () => {
    const data = new FormData();

    appendCouponToForm(data);

    call(
      () => ADD_COUPON(data),
      "Přidání slevového kupónu nebylo úspěšné",
      "Přidání slevového kupónu bylo úspěšné",
      () => {
        navigate("/coupons");
      },
      (err) => {
        if (err?.payload) {
          dispatch(err);
        }
      }
    );
  };

  const editCoupon = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit slevový kupón", async () => {
      if (!_id) {
        return;
      }

      const data = new FormData();

      data.append("_id", _id);
      appendCouponToForm(data);

      call(
        () => EDIT_COUPON(data),
        "Úprava slevového kupónu nebyla úspěšná",
        "Úprava slevového kupónu byla úspěšná",
        (coupon) => {
          if (exit) {
            navigate("/coupons");
          } else {
            setCoupon(mapCouponToEditorCoupon(coupon));
          }
        },
        (err) => {
          if (err?.payload) {
            dispatch(err);
          }
        }
      );
    });
  };

  const removeCoupon = () => {
    createConfirmation("Potvrzení: Odstranit slevový kupón", async () => {
      if (!_id) {
        return;
      }

      call(
        () => couponsModel.removeCoupon(_id),
        "Odstranění slevového kupónu nebylo úspěšné",
        "Odstranění slevového kupónu bylo úspěšné",
        () => {
          navigate("/coupons");
        }
      );
    });
  };

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          disabled={!isValid}
          onClick={addCoupon}
        >
          Přidat slevový kupón
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editCoupon()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editCoupon(true)}>
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removeCoupon}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  const generateKey = () => {
    setCoupon((c) => ({ ...c, key: generateCouponCode() }));
  };

  return (
    <BottomFixedWrapperWrapper node={node} deps={[isValid, appendCouponToForm]}>
      <div>
        <BackButton path="/coupons" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          {type === EditorType.Add
            ? "Přidání slevového kupónu"
            : "Úprava slevového kupónu"}
        </div>
        <FlexCenterAlign
          style={{
            margin: "20px 8px",
            marginBottom: 16,
          }}
        >
          <LanguageSelector onSelectLanguage={setLanguage} />
        </FlexCenterAlign>
        <Flex>
          <div>
            <Flex>
              <InputsWrapper>
                {originalEditingCoupon?.orders ? (
                  <div
                    style={{
                      marginBottom: 12,
                      fontSize: 14,
                      marginLeft: 4,
                      fontWeight: 500,
                    }}
                  >
                    Počet objednávek s kupónem:{" "}
                    {originalEditingCoupon.orders.length}
                  </div>
                ) : null}

                <InputWithTitle
                  inputProps={{
                    error: errors.key_empty || errors.key_exists,
                  }}
                  wrapperStyle={{
                    marginTop: 0,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={coupon.key}
                  setValue={(key) => {
                    {
                      setCoupon((c) => ({
                        ...c,
                        key,
                      }));
                    }
                  }}
                  title="Kód slevového kupónu"
                />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  <Button onClick={generateKey}>Vygenerovat kód</Button>
                </div>
                <div>
                  <div
                    style={{
                      marginTop: 16,
                      marginBottom: 4,
                      marginLeft: 4,
                      fontSize: 13,
                    }}
                  >
                    Typ slevového kupónu
                  </div>
                  <Dropdown
                    error={errors.type_empty}
                    selectedUniqueId={coupon.type}
                    onSelectValue={(type) =>
                      setCoupon((c) => ({ ...c, type, value: {} }))
                    }
                    isDeleteButtonDisabled
                    list={couponTypes.map((type) => ({
                      content: translateCouponType(type),
                      value: type,
                      unique_id: type,
                    }))}
                  />
                </div>
                <FlexCenterAlign
                  style={{
                    alignItems: "flex-end",
                  }}
                >
                  <InputWithTitle
                    inputProps={{
                      error: errors.value_empty || errors.value_invalid,
                      type: "number",
                      min: 0,
                      max: coupon.type === CouponType.Percent ? 99 : 10000,
                    }}
                    inputStyle={{
                      width: 320,
                    }}
                    value={coupon.value[language.locale] || 0}
                    setValue={(value) => {
                      {
                        setCoupon((c) => ({
                          ...c,
                          value: {
                            ...c.value,
                            [language.locale]: value as number,
                          },
                        }));
                      }
                    }}
                    title="Hodnota slevového kupónu"
                  />
                  <div
                    style={{
                      fontSize: 13,
                      marginBottom: 4,
                    }}
                  >
                    {coupon.type === CouponType.Percent
                      ? "%"
                      : language.currency.symbol}
                  </div>
                </FlexCenterAlign>
                <InputWithTitle
                  inputProps={{
                    type: "number",
                    min: 1,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={coupon.maxNumberOfUses}
                  setValue={(maxNumberOfUses) =>
                    setCoupon((c) => ({
                      ...c,
                      maxNumberOfUses,
                    }))
                  }
                  title="Maximální počet použití"
                />

                <div>
                  <FlexCenterAlign
                    style={{
                      marginTop: 16,
                    }}
                  >
                    <Checkbox
                      value={changeEditorValidUntil}
                      setValue={(value) => {
                        if (value) {
                          setCoupon((c) => ({
                            ...c,
                            validUntil: new Date().getTime(),
                          }));
                        } else {
                          setCoupon((c) => ({
                            ...c,
                            validUntil: -1,
                          }));
                        }
                        setChangeEditorValidUntil(value);
                      }}
                    />

                    <div
                      style={{
                        marginLeft: 12,
                        fontWeight: 400,
                        fontSize: 13,
                      }}
                    >
                      Chci upravit platnost kupónu
                    </div>
                  </FlexCenterAlign>
                  {changeEditorValidUntil && (
                    <FlexCenterAlign
                      style={{
                        marginTop: 16,
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: 13,
                          marginRight: 8,
                        }}
                      >
                        Platný do:
                      </div>
                      <CalendarButton
                        minDate={new Date()}
                        selectedDate={{
                          date: new Date(coupon.validUntil),
                          type: SelectedDateType.Single,
                        }}
                        setSelectedDate={(date) => {
                          if (date?.type === SelectedDateType.Single) {
                            setCoupon((t) => ({
                              ...t,
                              validUntil: date.date.getTime(),
                            }));
                          }
                        }}
                      />
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          marginLeft: 8,
                        }}
                      >
                        23:59
                      </div>
                    </FlexCenterAlign>
                  )}
                </div>
                <div>
                  <div
                    style={{
                      marginTop: 16,
                      marginBottom: 4,
                      marginLeft: 4,
                      fontSize: 13,
                    }}
                  >
                    Dostupné v obchodech
                  </div>
                  <DropdownMultiSelect
                    list={stores.map((c) => ({
                      content: c.title,
                      value: c,
                      query: c.title,
                      unique_id: c._id,
                    }))}
                    onSelectValue={(store) =>
                      setCoupon((coupon) => ({
                        ...coupon,
                        stores: [...coupon.stores, store],
                      }))
                    }
                    selectedDropdownIDs={coupon.stores.map((store) => store._id)}
                    onRemoveUniqueID={(id) =>
                      setCoupon((coupon) => ({
                        ...coupon,
                        stores: coupon.stores.filter((s) => s._id !== id),
                      }))
                    }
                  />
                </div>
              </InputsWrapper>
            </Flex>
          </div>
        </Flex>
      </div>
    </BottomFixedWrapperWrapper>
  );
};

export default CouponEditor;
