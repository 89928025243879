import React from "react";
import { useAppConfig } from "../common/redux-hooks";
import {
  NonTranslatedString,
  nonTranslatedString,
} from "../constants/language";
import { IPopulatedCategoryId } from "./../types/category";
import {
  DeleteButton,
  DropdownSelectedItemsWrapper,
  FlexCenterAlign,
} from "./common";
import Dropdown from "./dropdown";

const getCategoryIDsWithChildSubcategories = (
  selected: string[],
  allCategories: IPopulatedCategoryId[]
): string[] => {
  let ids: string[] = [];

  selected.forEach((id) => {
    const s = allCategories.find((c) => c._id === id);

    if (s && s.subcategories.length > 0) {
      selected.push(
        ...getCategoryIDsWithChildSubcategories(
          s.subcategories.map((c) => c._id),
          allCategories
        )
      );
    }
  });

  for (let i = 0; i < selected.length; i++) {
    if (!ids.includes(selected[i])) {
      ids.push(selected[i]);
    }
  }

  return ids;
};

export const mainCategoryString = "(hlavní kategorie)";

interface IProps {
  list: IPopulatedCategoryId[];
  categories: IPopulatedCategoryId[];
  selectedIDs: string[];
  addCategory(id: string): void;
  removeCategory(id: string): void;
  deleteTitle?: string;
  title?: string;
  showSubcategoriesUnderSelectedCategory?: boolean;
  disabled?: boolean;
}

export const CategoryMultiSelection = ({
  list,
  categories,
  selectedIDs,
  addCategory,
  removeCategory,
  deleteTitle,
  title,
  showSubcategoriesUnderSelectedCategory,
  disabled,
}: IProps): React.ReactElement<IProps> => {
  const { language, store } = useAppConfig();

  return (
    <div>
      <Dropdown
        disabled={disabled}
        onSelectValue={addCategory}
        list={list
          .filter((c) => c.stores.some((s) => s === store._id))
          .map((c, i) => {
            return {
              content: (
                <span key={i}>
                  {c.name[language.locale] || <NonTranslatedString />}
                  {c.isMainCategory && (
                    <span style={{ opacity: 0.5, marginLeft: 10 }}>
                      {mainCategoryString}
                    </span>
                  )}
                </span>
              ),
              query: c.name[language.locale]
                ? c.name[language.locale] +
                  (c.isMainCategory ? mainCategoryString : "")
                : nonTranslatedString,
              value: c._id,
              disabled: selectedIDs.includes(c._id),
            };
          })}
        title={title || "Vyberte podkategorii"}
      />
      <DropdownSelectedItemsWrapper>
        {selectedIDs.map((id, i) => (
          <div key={i}>
            <FlexCenterAlign
              style={{
                justifyContent: "space-between",
              }}
            >
              <div>
                {categories.find((c) => c._id === id)?.name[
                  language.locale
                ] || <NonTranslatedString />}
              </div>
              <DeleteButton
                data-title={deleteTitle || "Odstranit podkategorii"}
                size={13}
                style={{
                  marginLeft: 8,
                  padding: 3,
                }}
                onClick={() => removeCategory(id)}
              />
            </FlexCenterAlign>
            {showSubcategoriesUnderSelectedCategory && (
              <div
                style={{
                  marginLeft: 4,
                  fontSize: 12,
                  opacity: 0.75,
                }}
              >
                {getCategoryIDsWithChildSubcategories([id], categories)
                  .filter((_id) => _id !== id)
                  .map((id, i) => (
                    <div
                      key={i}
                      style={{
                        marginTop: 2,
                      }}
                    >
                      {categories.find((c) => c._id === id)?.name[
                        language.locale
                      ] || <NonTranslatedString />}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </DropdownSelectedItemsWrapper>
    </div>
  );
};

export default CategoryMultiSelection;
