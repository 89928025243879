export const resolveTitleByPathname = (pathname: string): string => {
  const prefix = "Admin |";

  switch (pathname) {
    case "/":
      return `${prefix} Domov`;
    case "/products":
      return `${prefix} Produkty`;
    case "/categories":
      return `${prefix} Kategorie`;
    case "/parameters":
      return `${prefix} Parametry`;
    case "/parameter-groups":
      return `${prefix} Parametrové skupiny`;
    case "/manufacturers":
      return `${prefix} Výrobci`;
    case "/gallery":
      return `${prefix} Galerie`;
    case "/languages":
      return `${prefix} Jazyky`;
    case "/stores":
      return `${prefix} Obchody`;
    case "/currencies":
      return `${prefix} Měny`;
    case "/testing":
      return `${prefix} Testování`;
    case "/admin":
      return `${prefix} Administrace`;
    case "/settings":
      return `${prefix} Nastavení aplikace`;
    case "/profile":
      return `${prefix} Profil`;

    case "/products/add-product":
      return `${prefix} Přidání produktu`;
    case "/categories/add-category":
      return `${prefix} Přidání kategorie`;
    case "/parameters/add-parameter":
      return `${prefix} Přidání parametru`;
    case "/parameters/add-parameter-group":
      return `${prefix} Přidání parametrové skupiny`;
    case "/parameters/add-value":
      return `${prefix} Přidání hodnoty`;
    case "/manufacturers/add-manufacturer":
      return `${prefix} Přidání výrobce`;
    case "/languages/add-language":
      return `${prefix} Přidání jazyka`;
    case "/stores/add-store":
      return `${prefix} Přidání obchodu`;
    case "/currencies/add-currency":
      return `${prefix} Přidání měny`;
    default:
      if (pathname.includes("edit-product")) {
        return `${prefix} Úprava produktu`;
      }
      if (pathname.includes("edit-category")) {
        return `${prefix} Úprava kategorie`;
      }
      if (pathname.includes("edit-parameter-group")) {
        return `${prefix} Úprava parametrové skupiny`;
      }
      if (pathname.includes("edit-parameter")) {
        return `${prefix} Úprava parametru`;
      }
      if (pathname.includes("edit-value")) {
        return `${prefix} Úprava hodnoty`;
      }
      if (pathname.includes("edit-manufacturer")) {
        return `${prefix} Úprava výrobce`;
      }
      if (pathname.includes("edit-language")) {
        return `${prefix} Úprava jazyka`;
      }
      if (pathname.includes("edit-store")) {
        return `${prefix} Úprava obchodu`;
      }
      if (pathname.includes("edit-currency")) {
        return `${prefix} Úprava měny`;
      }

      return "Admin";
  }
};
