import React from "react";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../common/redux-hooks";
import { isDisabledByRole } from "../../types/role";
import Button from "../../ui/button";
import { AddButtonActionStyles } from "../../ui/common";
import { useModal } from "../../providers/modal/modal-provider";
import ImportProductsDialog from "./product-editor/components/import-products/import-products-dialog";

interface IProps {}

export const ProductActionButtons =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();
    const role = useRole();
    const { createModal } = useModal();

    const createImportProductsModal = () => {
      createModal(<ImportProductsDialog />);
    };

    return (
      <div>
        <Button
          disabled={isDisabledByRole(role, {
            type: "product",
            permission: "add",
          })}
          style={AddButtonActionStyles}
          onClick={() => navigate("/products/add-product")}
        >
          Přidat produkt
        </Button>
        <Button
          disabled={isDisabledByRole(role, {
            type: "product",
            permission: "add",
          })}
          style={{ ...AddButtonActionStyles, fontSize: 13, marginLeft: 12 }}
          onClick={createImportProductsModal}
        >
          Import produktů
        </Button>
      </div>
    );
  };

export default ProductActionButtons;
