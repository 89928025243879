export type TError = {
  key: string;
  message: string;
};

export enum ErrorType {
  SERVER_ERROR = "SERVER_ERROR",
  PRODUCT = "PRODUCT",
  CATEGORY = "CATEGORY",
  PARAMETER = "PARAMETER",
  PARAMETER_VALUE = "PARAMETER_VALUE",
  PARAMETER_GROUP = "PARAMETER_GROUP",
  CURRENCY = "CURRENCY",
  LANGUAGE = "LANGUAGE",
  TASK = "TASK",
  MANUFACTURER = "MANUFACTURER",
  PASSWORD_CHANGE = "PASSWORD_CHANGE",
  EDIT_USER_INFO = "EDIT_USER_INFO",
  SHIPPING = "SHIPPING",
  PAYMENT = "PAYMENT",
  ORDER = "ORDER",
  SLUG = "SLUG",
  COUPON = "COUPON",
  STORE = "STORE",
}

export type TReduxError = {
  type: ErrorType;
  key: string;
  params?: {
    [key: string]: any;
  };
};
