import {
  ParameterActions,
  TParameterAction,
} from "./../actions/parameterActions";
import { IParameterID } from "../../types/parameter";

export interface IParametersState {
  parameters: IParameterID[];
}

const initialParametersState: IParametersState = {
  parameters: [],
};

export const parametersReducer = (
  state: IParametersState = initialParametersState,
  action: TParameterAction
): IParametersState => {
  switch (action.type) {
    case ParameterActions.SET_PARAMETERS:
      return { ...state, parameters: action.payload };
    case ParameterActions.ADD_PARAMETER:
      return { ...state, parameters: [...state.parameters, action.payload] };
    case ParameterActions.EDIT_PARAMETER:
      let parameters = [...state.parameters],
        index = parameters.findIndex((i) => i._id === action.payload._id);

      parameters[index] = action.payload;

      return { ...state, parameters };
    case ParameterActions.REMOVE_PARAMETER_BY_ID:
      return {
        ...state,
        parameters: state.parameters.filter((p) => p._id !== action.payload),
      };
    default:
      return state;
  }
};
