import { TGetError } from "../../../common/redux-hooks";
import { ErrorType, TReduxError } from "../../../types/error";
import { ITask } from "../../../types/tasks";

export const getTaskTitleInputError = (
    getError: TGetError,
    value: string
  ): TReduxError | undefined => {
    const title_empty = getError("title", ErrorType.TASK);
  
    if (title_empty && value === "") {
      return title_empty;
    }
  
    return undefined;
  };
  
  type TCategoryEditorErrors = {
    errors: { title?: TReduxError };
    isValid: boolean;
  };
  
  export const getTaskEditorErrors = (
    getError: TGetError,
    task: ITask
  ): TCategoryEditorErrors => {
    const title = getTaskTitleInputError(getError, task.title);
  
    return {
      errors: {
        title,
      },
      isValid: !title,
    };
  };
  