import { ICurrencyID } from "./currency";
import { ILanguageID } from "./language";
import { IPaymentID } from "./payment";
import { IProduct } from "./products";
import { IShippingID } from "./shipping";

export interface IOrder {
  _id: string;
  custom_id: string;
  checkout: ICheckout;
  language: ILanguageID;
  shipping: IShippingID;
  shipping_price: number;
  shipping_details?: TShippingDetails;
  payment: IPaymentID;
  payment_price: number;
  state: OrderState;
  items: IOrderItem[];
  invoice_url: string;
  date: number;
  heurekaEmailSent: boolean;
  completedEmailSent: boolean;
  coupon: IOrderCoupon | null;
  itemCount?: number;
}

export enum CouponType {
  Percent = "PERCENT",
  Value = "VALUE",
}

export interface IOrderCoupon {
  key: string;
  type: CouponType;
  value: number;
}

type TZasilkovnaPhoto = {
  thumbnail: string;
  normal: string;
};

type TZasilkovnaGPS = {
  lat: number;
  lon: number;
};

type TZasilkovnaOpeningHoursException = {
  date: string;
  hours: string;
};

type TZasilkovnaOpeningHoursExceptions = TZasilkovnaOpeningHoursException[][];

type TZasilkovnaOpeningHours = {
  compactShort: string;
  compactLong: string;
  exceptions: TZasilkovnaOpeningHoursExceptions;
  regular: {
    [key: string]: string;
  };
};

export interface IZasilkovnaPoint {
  businessDaysOpenLunchtime: boolean;
  businessDaysOpenUpTo: number;
  city: string;
  claimAssistant: boolean;
  country: string;
  creditCardPayment: boolean;
  currency: string;
  directions: string;
  directionsCar: string;
  directionsPublic: string;
  error: null | Error;
  gps: TZasilkovnaGPS;
  holidayStart: string;
  id: string;
  isNew: boolean;
  maxWeight: number;
  name: string;
  nameStreet: string;
  openingHours: TZasilkovnaOpeningHours;
  packetConsignment: boolean;
  photo: TZasilkovnaPhoto[];
  place: string;
  recommended: string;
  saturdayOpenTo: number;
  special: string;
  street: string;
  sundayOpenTo: number;
  url: string;
  branchCode: string;
  warning: null | string;
  wheelchairAccessible: boolean;
  zip: string;
  pickupPointType: string;
  carrierPickupPointId: null | string;
  carrierId: null | string;
  routingCode: string;
  routingName: string;
  exchangePointId: string;
  formatedValue: string;
}

export enum ShippingType {
  Zasilkovna = "ZASILKOVNA",
  PPL = "PPL",
}

export const SHIPPING_OPTIONS: ShippingType[] = [ShippingType.Zasilkovna];

export type TZasilkovnaShippingDB = {
  type: ShippingType.Zasilkovna;
  point: IZasilkovnaPoint;
  trackingNumber: string;
  trackingNumberFormatted: string;
  trackingId: string;
  trackingURL: string;
};

export type TPPLShippingDB = {
  type: ShippingType.PPL;
  batchId: string;
  batchUrl: string;
  shipmentNumber?: string;
};

export type TShippingDetails = TZasilkovnaShippingDB | TPPLShippingDB;

export enum PaymentType {
  Card = "CARD",
  GooglePay = "GOOGLE_PAY",
  ApplePay = "APPLE_PAY",
  Cash = "CASH",
  BankTransfer = "BANK_TRANSFER",
}

export const PAYMENT_OPTIONS: PaymentType[] = [
  PaymentType.Card,
  PaymentType.GooglePay,
  PaymentType.ApplePay,
  PaymentType.Cash,
  PaymentType.BankTransfer,
];

export type TPhoneNumber = {
  country: string;
  prefix: string;
  value: string;
};

export interface ICheckout {
  email: string;
  firstName: string;
  lastName: string;
  zip: string;
  address: string;
  city: string;
  phoneNumber: TPhoneNumber;
}

export enum OrderState {
  New = "NEW",
  WaitingForPayment = "WAITING_FOR_PAYMENT",
  InProgress = "IN_PROGRESS",
  HandedToCarrier = "HANDED_TO_CARRIER", 
  Completed = "COMPLETED",
  Canceled = "CANCELED",
  ReturnConfirmedByUser = "RETURN_CONFIRMED_BY_USER",
  ReturnArrived = "RETURN_ARRIVED",
}

export interface IOrderItem {
  product: IProduct | null;
  name: string;
  price_vat: number;
  quantity: number;
  sale_price?: number;
  netto_weight_kg: number;
}

export enum GoPayPaymentState {
  CREATED = "CREATED",
  PAID = "PAID",
  CANCELED = "CANCELED",
  PAYMENT_METHOD_CHOSEN = "PAYMENT_METHOD_CHOSEN",
  TIMEOUTED = "TIMEOUTED",
  AUTHORIZED = "AUTHORIZED",
  REFUNDED = "REFUNDED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
}
