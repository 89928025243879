import React from "react";

import styled from "styled-components";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { orderModel } from "../../model/query/orderModel";
import { useConfirmation } from "../../providers/confirmation/confirmation-provider";
import { useModal } from "../../providers/modal/modal-provider";
import { IOrder } from "../../types/order";
import Button from "../../ui/button";
import { FlexCenterAlign } from "../../ui/common";
import {
  SelectedProductItem,
  SelectedProductsWrapper,
} from "../products/selected-products";
import { useAppConfig } from "../../common/redux-hooks";

const ActionButtonsWrapper = styled.div`
  margin-top: 16px;

  & > div {
    margin-bottom: 12px;

    & > div {
      margin-right: 8px;
    }
  }
`;

export type TSelectedOrder = {
  id: string;
  value: IOrder;
};

interface IProps {
  selectedOrders: TSelectedOrder[];
  setSelectedOrders(Orders: TSelectedOrder[]): void;
  fetch(): void;
}

export const GroupActionsModal = ({
  selectedOrders,
  fetch,
  setSelectedOrders,
}: IProps): React.ReactElement<IProps> => {
  const { language } = useAppConfig();
  const { closeModal } = useModal();
  const { createConfirmation } = useConfirmation();
  const { call } = useQueryWithPopupErrorHandling();

  const setIsTestingClick = (state: boolean) => {
    createConfirmation(
      "Opravdu chcete vybrané objednávky označit jako " +
        (state ? "testovací?" : "produkční?"),
      () => {
        const data = new FormData();

        data.append(
          "orders",
          JSON.stringify(selectedOrders.map((p) => p.value._id))
        );
        data.append("state", JSON.stringify(state));
        data.append("language", language._id);

        call(
          () => orderModel.setOrdersAsTesting(data),
          "Označení objednávek jako testovací/produkční nebylo úspěšné",
          "Označení objednávek jako testovací/produkční bylo úspěšné",
          () => {
            setSelectedOrders([]);
            closeModal();
            fetch();
          }
        );
      }
    );
  };

  return (
    <div
      style={{
        paddingRight: 50,
      }}
    >
      <div
        style={{
          fontSize: 13,
          marginBottom: 8,
          marginLeft: 6,
        }}
      >
        Pro vybrané objednávky
      </div>
      <SelectedProductsWrapper>
        {selectedOrders.map((p, i) => (
          <SelectedProductItem key={i}>{p.value.custom_id}</SelectedProductItem>
        ))}
      </SelectedProductsWrapper>

      <div
        style={{
          fontSize: 13,
          marginBottom: 8,
          marginTop: 20,
          marginLeft: 6,
        }}
      >
        Zvolte hromadnou akci
      </div>

      <ActionButtonsWrapper>
        <FlexCenterAlign>
          <Button onClick={() => setIsTestingClick(true)}>
            Označit jako testovací
          </Button>
          <Button onClick={() => setIsTestingClick(false)}>
            Označit jako produkční
          </Button>
        </FlexCenterAlign>
      </ActionButtonsWrapper>
    </div>
  );
};

export default GroupActionsModal;
