import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { resolveTitleByPathname } from "../common/location";
import { useGlobalError } from "../providers/global-error/global-error-provider";

export const AppLogicResolver = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = resolveTitleByPathname(location.pathname);
  }, [location.pathname]);

  return <></>;
};

export default AppLogicResolver;
