import React from "react";
import { AiFillInteraction } from "react-icons/ai";
import { BiX } from "react-icons/bi";
import styled, { css } from "styled-components";
import { useModal } from "../../providers/modal/modal-provider";
import { useColors } from "../../providers/theme/theme-provider";
import { IOrder } from "../../types/order";
import Button from "../../ui/button";
import { FlexCenterAlign } from "../../ui/common";
import GroupActions from "./group-actions";
import { useOrdersList } from "./orders-list-context";

export type TSelectedOrder = {
  id: string;
  value: IOrder;
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const SelectedOrdersWrapper = styled(FlexCenterAlign)`
  flex-wrap: wrap;
  max-width: 400px;

  & > div {
    margin-right: 8px;
    margin-top: 8px;
  }
`;

export const SelectedOrderItem = styled.div`
  ${({ theme }) => css`
    font-size: 13px;
    background-color: ${theme.colors.MAIN_400};
    padding: 6px 10px;
    border-radius: ${theme.borderRadius}px;
  `}
`;

interface IProps {
  selectedOrders: TSelectedOrder[];
  setSelectedOrders(Orders: TSelectedOrder[]): void;
}

export const SelectedOrders = ({
  selectedOrders,
  setSelectedOrders,
}: IProps): React.ReactElement<IProps> => {
  const { createModal } = useModal();
  const { fetch } = useOrdersList();

  const groupActionClick = () => {
    createModal(<GroupActions fetch={fetch} setSelectedOrders={setSelectedOrders} selectedOrders={selectedOrders} />);
  };

  const colors = useColors();

  if (!selectedOrders.length) {
    return <></>;
  }

  return (
    <Wrapper>
      <div
        style={{
          fontSize: 13,
          marginBottom: 12,
          marginLeft: 2,
        }}
      >
        Vybrané objednávky
      </div>
      <FlexCenterAlign>
        <SelectedOrdersWrapper>
          {selectedOrders.map((p, i) => (
            <SelectedOrderItem key={i}>{p.value.custom_id}</SelectedOrderItem>
          ))}
        </SelectedOrdersWrapper>
        <Button
          onClick={groupActionClick}
          style={{
            marginLeft: 12,
            paddingLeft: 8,
          }}
        >
          <FlexCenterAlign>
            <AiFillInteraction
              size={21}
              style={{
                marginRight: 6,
              }}
              color={colors.OPPOSITE_MAIN_400}
            />
            Hromadné akce
          </FlexCenterAlign>
        </Button>
      </FlexCenterAlign>
      <Button
        style={{
          marginTop: 20,
          padding: "4px 12px",
        }}
        onClick={() => setSelectedOrders([])}
      >
        <FlexCenterAlign>
          <BiX
            size={21}
            style={{
              marginRight: 4,
            }}
            color={colors.OPPOSITE_MAIN_450}
          />
          Zrušit výběr objednávek
        </FlexCenterAlign>
      </Button>
    </Wrapper>
  );
};

export default SelectedOrders;
