import { WRITER_PERMISSIONS } from "../common/role";

export interface IPermissions {
  view: boolean;
  add: boolean;
  edit: boolean;
  remove: boolean;
  edit_language: boolean;
  edit_permissions?: boolean;
  edit_state?: boolean;
}

export enum UserRole {
  Admin = "Admin",
  Manager = "Manager",
  TranslatorAdmin = "TranslatorAdmin",
  Translator = "Translator",
  Writer = "Writer",
  Custom = "Custom",
}

export interface IRolePermissions {
  product: IPermissions;
  category: IPermissions;
  parameter: IPermissions;
  parameter_value: IPermissions;
  gallery: IPermissions;
  language: IPermissions;
  user: IPermissions;
  tasks: IPermissions;
  manufacturer: IPermissions;
  currency: IPermissions;
  parameter_group: IPermissions;
  payment: IPermissions;
  shipping: IPermissions;
  order: IPermissions;
  terms_and_conditions: IPermissions;
  coupons: IPermissions;
  store: IPermissions;
  email: IPermissions;
}

export interface IRole {
  permissions: IRolePermissions;
  role: UserRole;
}

export type TViewPermission = {
  type: keyof IRolePermissions;
  permission?: (keyof IPermissions)[] | keyof IPermissions;
};

export const isDisabledByRole = (role: IRole, view: TViewPermission) => {
  return !canViewItemByRole(role, view);
};

export const canViewItemByRole = (role: IRole, view: TViewPermission) => {
  if (!role || !role?.permissions || !role.permissions?.[view.type]) {
    return false;
  }

  let item = role.permissions[view.type];

  if (view.permission) {
    // returns key of item => add, edit, remove, edit_language

    if (Array.isArray(view.permission)) {
      return view.permission.some((key) => item[key]);
    }

    return item[view.permission];
  }

  // can view any, permission not defined
  return (
    item.add || item.edit || item.edit_language || item.remove || item.view
  );
};

export const getDefaultRole = (): IRole => {
  return {
    permissions: WRITER_PERMISSIONS,
    role: UserRole.Writer,
  };
};
