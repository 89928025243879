import { TLanguageSlug, TLanguageString } from "./common";

export interface IParameter {
  title: TLanguageString;
  canHaveAnyValue: boolean;
  canEditWithNumber: boolean;
  values: IParameterValueID[];
  type: ParameterType;
  slug: TLanguageSlug;
  isVisibleOnProductPage?: boolean;
  isVisibleInFilter?: boolean;
  sortIndex?: number;
  generateVariants?: boolean;
  generateRelatedProducts?: boolean;
}

export interface IParameterID extends IParameter {
  _id: string;
}

export interface IParameterValue {
  type: ParameterValueType;
  value: TLanguageString;
  valueIsSameForAllLanguages: boolean;
  params?: TParameterValueParams;
  sortIndex?: number;
}

export interface IParameterValueID extends IParameterValue {
  _id: string;
}

export interface IProductParameter {
  parameter?: IParameterID;
  value_id?: IParameterValueID;
  value_number?: number;
  unit?: string;
}

export interface IProductParameterGroup {
  group: IParameterGroupID;
  parameters: IProductParameter[];
}

export interface IParameterGroup {
  title: string;
  parameters: IParameterID[];
  generateRelatedProducts: boolean;
  generateVariants: boolean;
}

export interface IParameterGroupID extends IParameterGroup {
  _id: string;
}

export enum ParameterType {
  None = "NONE",
  Colors = "COLORS",
  Memory = "MEMORY",
  ClothingSizes = "CLOTHING_SIZES",
  Manufacturers = "MANUFACTURER"
}

export enum ParameterValueType {
  Text = "TEXT",
  Color = "COLOR",
  ClothingSize = "CLOTHING_SIZE"
}

export type TParameterValueColorParams = {
  color: string;
}

export type TParameterValueParams = TParameterValueColorParams;