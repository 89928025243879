import { parameterModel } from "../../model/query/parameterModel";
import { IParameterID } from "../../types/parameter";
import { ErrorsActions } from "./errorsActions";

export enum ParameterActions {
  SET_PARAMETERS = "SET_PARAMETERS",
  ADD_PARAMETER = "ADD_PARAMETER",
  EDIT_PARAMETER = "EDIT_PARAMETER",
  REMOVE_PARAMETER_BY_ID = "REMOVE_PARAMETER_BY_ID",
}

type TSetParametersAction = {
  type: ParameterActions.SET_PARAMETERS;
  payload: IParameterID[];
};

type TAddParameterAction = {
  type: ParameterActions.ADD_PARAMETER;
  payload: IParameterID;
};

type TEditParameterAction = {
  type: ParameterActions.EDIT_PARAMETER;
  payload: IParameterID;
};

type TRemoveParameterByIdAction = {
  type: ParameterActions.REMOVE_PARAMETER_BY_ID;
  payload: string;
};

export type TParameterAction =
  | TSetParametersAction
  | TAddParameterAction
  | TEditParameterAction
  | TRemoveParameterByIdAction;

export const FETCH_AND_SET_PARAMETERS = async () => {
  try {
    const parameters = await parameterModel.getParameters();

    return {
      type: ParameterActions.SET_PARAMETERS,
      payload: parameters,
    };
  } catch (err) {
    return false;
  }
};

export const ADD_PARAMETER = async (data: FormData) => {
  try {
    const parameter = await parameterModel.addParameter(data);

    return {
      type: ParameterActions.ADD_PARAMETER,
      payload: parameter,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_PARAMETER = async (data: FormData) => {
  try {
    const parameter = await parameterModel.editParameter(data);

    return {
      type: ParameterActions.EDIT_PARAMETER,
      payload: parameter,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_PARAMETER_BY_ID = async (id: string) => {
  const isRemoved = await parameterModel.removeParameterById(id);

  return {
    type: ParameterActions.REMOVE_PARAMETER_BY_ID,
    payload: isRemoved ? id : null,
  };
};
