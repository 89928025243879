import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { useStores } from "../../common/redux-hooks";
import { useConfirmation } from "../../providers/confirmation/confirmation-provider";
import { SimplePopupType } from "../../providers/simple-popup/data";
import { useSimplePopups } from "../../providers/simple-popup/simple-popup-provider";
import { EDIT_COMPLAINTS_INFO } from "../../redux/actions/storesActions";
import { useDispatch } from "../../redux/store";
import { IStoreID } from "../../types/store";
import { BottomFixedWrapperWrapper } from "../../ui/bottom-fixed-wrapper-wrapper";
import {
  AddButton,
  FlexCenterAlign,
  FlexCenterAll,
  HeaderText,
} from "../../ui/common";
import RichTextEditor from "../../ui/editorTinyMce";
import BackButton from "../components/back-button";

const EditorWrapper = styled.div`
  margin-top: 20px;
  width: 75%;
`;

interface IProps {}

export const ComplaintsInfoEditor =
  ({}: IProps): React.ReactElement<IProps> => {
    const stores = useStores();
    const { store: storeParam } = useParams();
    const navigate = useNavigate();
    const [store, setStore] = useState<IStoreID | null>(null);
    const [complaintsInfo, setComplaintsInfo] = useState<string>("");
    
    const dispatch = useDispatch();
    const { createConfirmation } = useConfirmation();
    const { call } = useQueryWithPopupErrorHandling();
    const { createSimplePopup } = useSimplePopups();

    useEffect(() => {
      const knownStore = stores.find((store) => store.key === storeParam);

      if (!stores || !storeParam || !knownStore) {
        navigate("/complaints-info");
      } else {
        setStore(knownStore);
        setComplaintsInfo(knownStore.complaintsInfo || "");
      }
    }, [storeParam, stores]);

    const editComplaintsInfo = useCallback(
      (closeOnSuccess?: boolean) => {
        createConfirmation("Opravdu chcete upravit reklamační řád?", () => {
          if (!store) {
            createSimplePopup("Eshop není definován", SimplePopupType.Error);
            return;
          }

          call(
            () => EDIT_COMPLAINTS_INFO(complaintsInfo, store._id),
            "Úprava reklamačního řádu nebyla úspěšná",
            "Reklamační řád byl úspěšně upraven",
            (action) => {
              dispatch(action);

              if (closeOnSuccess) {
                navigate("/complaints-info");
              }
            }
          );
        });
      },
      [complaintsInfo]
    );

    const node = (
      <FlexCenterAll
        style={{
          marginLeft: 16,
        }}
      >
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton onClick={() => editComplaintsInfo()}>
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton onClick={() => editComplaintsInfo(true)}>
              Uložit a zavřít
            </AddButton>
          </div>
        </FlexCenterAlign>
      </FlexCenterAll>
    );

    if (!store) {
      return <></>;
    }

    return (
      <BottomFixedWrapperWrapper node={node} deps={[editComplaintsInfo]}>
        <BackButton path="/complaints-info" />
        <HeaderText
          style={{
            marginTop: 20,
          }}
        >
          Reklamační řád pro eshop {store.title}
        </HeaderText>
        <EditorWrapper>
          <RichTextEditor
            value={complaintsInfo}
            onEditorChange={(complaintsInfo) => setComplaintsInfo(complaintsInfo)}
          />
        </EditorWrapper>
      </BottomFixedWrapperWrapper>
    );
  };

export default ComplaintsInfoEditor;
