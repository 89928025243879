import { Dispatch } from "@reduxjs/toolkit";
import { productModel } from "../../model/query/productModel";
import { IProduct } from "../../types/products";
import { ErrorsActions } from "./errorsActions";

export enum ProductsActions {
  SET_LOADING = "SET_LOADING",
  SET_PRODUCTS = "SET_PRODUCTS",
  ADD_PRODUCT = "ADD_PRODUCT",
  EDIT_PRODUCT = "EDIT_PRODUCT",
  REMOVE_PRODUCT_BY_ID = "REMOVE_PRODUCT_BY_ID",
}

type TSetProductsLoadingAction = {
  type: ProductsActions.SET_LOADING;
  payload: boolean;
};

type TSetProductsAction = {
  type: ProductsActions.SET_PRODUCTS;
  payload: IProduct[];
};

type TAddProductAction = {
  type: ProductsActions.ADD_PRODUCT;
  payload: IProduct;
};

type TEditProductAction = {
  type: ProductsActions.EDIT_PRODUCT;
  payload: IProduct;
};

type TRemoveProductByIdAction = {
  type: ProductsActions.REMOVE_PRODUCT_BY_ID;
  payload: string;
};

export type TProductsAction =
  | TSetProductsAction
  | TAddProductAction
  | TEditProductAction
  | TRemoveProductByIdAction
  | TSetProductsLoadingAction;

export const ADD_PRODUCT = async (data: FormData) => {
  try {
    const product = await productModel.addProduct(data);

    return {
      type: ProductsActions.ADD_PRODUCT,
      payload: product,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const EDIT_PRODUCT = async (data: FormData) => {
  try {
    const product = await productModel.editProduct(data);

    return {
      type: ProductsActions.EDIT_PRODUCT,
      payload: product,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};

export const REMOVE_PRODUCT_BY_ID = async (id: string) => {
  try {
    await productModel.removeProductById(id);

    return {
      type: ProductsActions.REMOVE_PRODUCT_BY_ID,
      payload: id,
    };
  } catch (errors) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: errors,
    };
  }
};
