import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useErrors } from "../../../../common/redux-hooks";
import { ErrorType } from "../../../../types/error";
import { FlexCenterAll } from "../../../../ui/common";
import { InputWithTitle } from "../../../../ui/input-with-title";
import { getChangePasswordErrors } from "./user-password-editor-errors";

export interface IPasswords {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

interface IProps {
  passwords: IPasswords;
  setPasswords: Dispatch<SetStateAction<IPasswords>>;
  oldPasswordEnabled?: boolean;
}

export const UserPasswordEditor = ({
  passwords,
  setPasswords,
  oldPasswordEnabled,
}: IProps): React.ReactElement<IProps> => {
  const { getError, clearErrorsOfType } = useErrors();
  const { errors } = getChangePasswordErrors(getError, passwords);

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.PASSWORD_CHANGE);
    };
  }, []);

  return (
    <FlexCenterAll
      style={{
        padding: 12,
      }}
    >
      <div>
        {oldPasswordEnabled && (
          <InputWithTitle
            title="Staré heslo"
            inputProps={{
              style: {
                width: 190,
              },
              wrapperStyle: {
                width: 190,
              },
              type: "password",
              error: errors.old_password_empty || errors.old_password_wrong,
            }}
            setValue={(oldPassword) =>
              setPasswords((p) => ({ ...p, oldPassword }))
            }
            value={passwords.oldPassword}
          />
        )}

        <InputWithTitle
          title="Nové heslo"
          inputProps={{
            type: "password",
            style: {
              width: 190,
            },
            wrapperStyle: {
              width: 190,
            },
            error: errors.password_empty,
          }}
          setValue={(newPassword) =>
            setPasswords((p) => ({ ...p, newPassword }))
          }
          value={passwords.newPassword}
        />

        <InputWithTitle
          title="Potvrďte nové heslo"
          inputProps={{
            type: "password",
            error: errors.new_password_confirm_not_same,
            style: {
              width: 190,
            },
            wrapperStyle: {
              width: 190,
            },
          }}
          setValue={(newPasswordConfirm) =>
            setPasswords((p) => ({ ...p, newPasswordConfirm }))
          }
          value={passwords.newPasswordConfirm}
        />
      </div>
    </FlexCenterAll>
  );
};

export default UserPasswordEditor;
