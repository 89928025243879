import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrencies, useRole } from "../../common/redux-hooks";
import { ICurrencyID } from "../../types/currency";
import { EditButton } from "../../ui/common";
import Input from "../../ui/input";
import Table from "../../ui/table";
import { isDisabledByRole } from "../../types/role";

interface IRowActionButtons {
  currency: ICurrencyID;
}

const RowActionButtons = ({
  currency,
}: IRowActionButtons): React.ReactElement<IRowActionButtons> => {
  const role = useRole();

  return (
    <div>
      {!isDisabledByRole(role, { type: "currency", permission: "edit" }) && (
        <EditButton
          path={`edit-currency/${currency._id}`}
          data-title="Upravit měnu"
        />
      )}
    </div>
  );
};

interface IProps {}

export const CurrenciesList = ({}: IProps): React.ReactElement<IProps> => {
  const currencies = useCurrencies();
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        maxWidth: 700,
      }}
    >
      <Input
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vyhledat měnu"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <Table
        query={search}
        headerKeys={[
          {
            content: "",
            width: "10%",
          },
          {
            content: "Název měny",
            width: "30%",
            query_key: "title",
          },
          {
            content: "Symbol",
            width: "30%",
            query_key: "symbol",
          },
          {
            content: "Měnová zkratka",
            width: "30%",
            query_key: "currency_shortcut",
          },
        ]}
        rows={currencies.map((c) => ({
          cells: [
            {
              content: <RowActionButtons currency={c} />,
              key: "actions",
              value: "actions",
            },
            {
              content: c.title,
              key: "title",
              value: c.title,
            },
            {
              content: c.symbol,
              key: "symbol",
              value: c.symbol,
            },
            {
              content: c.currency_shortcut,
              key: "currency_shortcut",
              value: c.currency_shortcut,
            },
          ],
        }))}
      />
    </div>
  );
};

export default CurrenciesList;
