import React, { useEffect } from "react";
import { CSSProperties } from "styled-components";
import { parseUnitFromString } from "../common/functions";
import { TUnit } from "../types/units";
import { FlexCenterAlign } from "./common";
import Input from "./input";
import UnitSelector from "./unit-selector/unit-selector";

export interface IValueWithUnitInputProps<T = TUnit> {
  value: number;
  setValue(value: number): void;
  unit: T;
  setUnit(unit: T): void;
  saveToLocalStorageKey?: string;
  inputStyle?: CSSProperties;
}

export const ValueWithUnitInput = <T extends TUnit>({
  setUnit,
  setValue,
  unit,
  value,
  saveToLocalStorageKey,
  inputStyle,
}: IValueWithUnitInputProps<T>): React.ReactElement<
  IValueWithUnitInputProps<T>
> => {
  useEffect(() => {
    if (saveToLocalStorageKey) {
      const item = localStorage.getItem(saveToLocalStorageKey);

      if (item) {
        const parsedUnit = parseUnitFromString(item);

        if (parsedUnit) {
          setUnit(parsedUnit as T);
        }
      }
    }
  }, [saveToLocalStorageKey]);

  const handleSetUnit = (unit: T) => {
    if (saveToLocalStorageKey) {
      localStorage.setItem(saveToLocalStorageKey, unit);
    }

    setUnit(unit);
  };

  return (
    <FlexCenterAlign>
      <Input
        setValue={setValue}
        value={value}
        type="number"
        style={inputStyle}
      />
      <div
        style={{
          marginLeft: 8,
        }}
      >
        <UnitSelector unit={unit} setUnit={handleSetUnit} />
      </div>
    </FlexCenterAlign>
  );
};

export default ValueWithUnitInput;
