import { useEffect } from "react";
import { findDOMNode } from "react-dom";

export const useClickOutside = (
  ref: React.MutableRefObject<any> | React.MutableRefObject<any>[],
  clickOutsideHandler: () => void,
  ignoreRefs?: React.MutableRefObject<any> | React.MutableRefObject<any>[]
) => {
  const isInIgnoreRefs = (element: Element) => {
    if (ignoreRefs) {
      if (Array.isArray(ignoreRefs)) {
        if (ignoreRefs.length === 0) {
          return false;
        }

        for(let i = 0; i < ignoreRefs.length; i++) {
          const dom = findDOMNode(ignoreRefs[i].current);

          if (dom && dom === element || dom?.contains(element)) {
            return true;
          }
        }
      } else {
        const dom = findDOMNode(ignoreRefs.current);

        if (dom) {
          return dom === element || dom.contains(element);
        }
      }
    }

    return false;
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      e.stopPropagation();

      if(isInIgnoreRefs(e.target as Element)) {
        return;
      }


      if (Array.isArray(ref)) {
        let outside = true;

        ref.forEach((ref) => {
          if (ref.current && ref.current.contains(e.target)) {
            outside = false;
            return;
          }
        });

        if (outside) {
          return clickOutsideHandler();
        }
      } else if (ref.current) {
        const domNode = findDOMNode(ref.current);

        if (
          domNode instanceof Element &&
          !domNode.contains(e.target as Element) &&
          !isInIgnoreRefs(domNode)
        ) {
          clickOutsideHandler();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);
};
