import { useEffect } from "react";

import {
  getProductChangedFields
} from "../../../common/functions";
import {
  AddProductStateItemKey,
  EditProductStateItemKey,
  LocalStorageSavedDateKey
} from "../../../common/local-storage";
import { useModal } from "../../../providers/modal/modal-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { EditorType } from "../../../types/common";
import { IEditorProduct } from "../../../types/products";
import ProductChangesModal from "./components/product-changes-modal";

export const deleteSavedProductStateFromLocalStorage = (productId?: string) => {
  if (!productId) {
    localStorage.removeItem(AddProductStateItemKey);
    return;
  }

  localStorage.removeItem(EditProductStateItemKey + productId);
};

const saveProductStateToLocalStorage = (json: string, productId?: string) => {
  if (!productId) {
    localStorage.setItem(AddProductStateItemKey, json);
    return;
  }

  localStorage.setItem(EditProductStateItemKey + productId, json);
};

const getProductStateFromLocalStorage = (productId?: string): any | null => {
  const productState = localStorage.getItem(
    productId ? EditProductStateItemKey + productId : AddProductStateItemKey
  );

  if (!productState) return null;

  try {
    const parsed = JSON.parse(productState);

    delete parsed[LocalStorageSavedDateKey];

    return parsed;
  } catch (err) {
    return null;
  }
};

const combineProductFromStateWithProduct = (
  state: IEditorProduct,
  product: IEditorProduct
): IEditorProduct => {
  const mergedProduct: IEditorProduct = { ...product };

  Object.keys(state).forEach((key) => {
    const k = key as keyof IEditorProduct;

    switch (k) {
      case "name":
        mergedProduct[k] = { ...product[k], ...state[k] };
        break;
      case "description":
        mergedProduct[k] = { ...product[k], ...state[k] };
        break;
      case "packaging":
        mergedProduct[k] = { ...product[k], ...state[k] };
        break;
      default:
        (mergedProduct[k] as any) = state[k];
        break;
    }
  });

  return mergedProduct;
};

const getStringifiedProductOfFieldsThatAreDifferent = (
  currentProduct: IEditorProduct,
  originalProduct: IEditorProduct
): string => {
  const changes = getProductChangedFields(originalProduct, currentProduct);

  return JSON.stringify({
    ...changes.after,
    [LocalStorageSavedDateKey]: Date.now(),
  });
};

export const useLocalStorageProductSaving = (
  product: IEditorProduct,
  productInitState: IEditorProduct,
  originalEditingProduct: IEditorProduct | null,
  type: EditorType,
  setProduct: (p: IEditorProduct) => void
): void => {
  const saveToLocalStorage = async () => {
    const save = () => {
      if (type === EditorType.Edit && originalEditingProduct) {
        saveProductStateToLocalStorage(
          getStringifiedProductOfFieldsThatAreDifferent(
            product,
            originalEditingProduct
          ),
          product._id
        );
        return;
      }

      saveProductStateToLocalStorage(
        getStringifiedProductOfFieldsThatAreDifferent(product, productInitState)
      );
    };

    if (type === EditorType.Edit && originalEditingProduct) {
      if (
        Object.keys(
          getProductChangedFields(originalEditingProduct, product).after
        ).length === 0
      ) {
        deleteSavedProductStateFromLocalStorage(originalEditingProduct._id);
        return;
      }
    } else if (
      Object.keys(getProductChangedFields(productInitState, product)).length ===
      0
    ) {
      deleteSavedProductStateFromLocalStorage();
      return;
    }

    save();
  };

  useEffect(() => {
    let saveTimer = setTimeout(() => {
      saveToLocalStorage();
    }, 500);

    return () => {
      clearTimeout(saveTimer);
    };
  });

  const { createNotification } = useNotifications();
  const modalContext = useModal();

  useEffect(() => {
    const getProductState = () => {
      let state = null;

      if (type === EditorType.Edit && product._id) {
        state = getProductStateFromLocalStorage(product._id);
      } else if (type === EditorType.Add) {
        state = getProductStateFromLocalStorage();
      }

      if (state) {
        const combinedProduct = combineProductFromStateWithProduct(
          state,
          product
        );
        const changedFields = getProductChangedFields(product, combinedProduct);

        if (
          Object.keys(changedFields.after).length === 0 ||
          Object.keys(changedFields.before).length === 0
        ) {
          return;
        }

        createNotification(
          <ProductChangesModal
            modalContext={modalContext}
            changed={changedFields}
          />,
          () => {
            setProduct(combinedProduct);
          },
          () => {
            deleteSavedProductStateFromLocalStorage(product._id);
          },
          {
            ok: "Ano",
            cancel: "Ne (vymaže uložený stav)",
          }
        );
      }
    };

    getProductState();
  }, [type, product._id]);
};
