import React, { useState } from "react";
import { IPackaging } from "../../../../types/products";
import { Flex } from "../../../../ui/common";
import NumberWithUnitInputWithTitle from "../../../../ui/number-with-unit-input-with-title";
import ValueWithUnitInput from "../../../../ui/value-with-unit-input";
import { InputsWrapper } from "./../../../../ui/common";
import { InputWithTitle } from "./../../../../ui/input-with-title";

interface IProps {
  packaging: IPackaging;
  setPackaging(packaging: IPackaging): void;
}

export const PackagingInput = ({
  packaging,
  setPackaging,
}: IProps): React.ReactElement<IProps> => {
  return (
    <Flex>
      <InputsWrapper>
        <NumberWithUnitInputWithTitle
          wrapperStyle={{
            marginTop: 0
          }}
          title="Šířka"
          value={packaging.width.value}
          setValue={(value) =>
            setPackaging({ ...packaging, width: { ...packaging.width, value } })
          }
          unit={packaging.width.unit}
          setUnit={(unit) =>
            setPackaging({ ...packaging, width: { ...packaging.width, unit } })
          }
        />
        <NumberWithUnitInputWithTitle
          title="Výška"
          value={packaging.height.value}
          setValue={(value) =>
            setPackaging({
              ...packaging,
              height: { ...packaging.height, value },
            })
          }
          unit={packaging.height.unit}
          setUnit={(unit) =>
            setPackaging({
              ...packaging,
              height: { ...packaging.height, unit },
            })
          }
        />
        <NumberWithUnitInputWithTitle
          title="Délka"
          value={packaging.length.value}
          setValue={(value) =>
            setPackaging({
              ...packaging,
              length: { ...packaging.length, value },
            })
          }
          unit={packaging.length.unit}
          setUnit={(unit) =>
            setPackaging({
              ...packaging,
              length: { ...packaging.length, unit },
            })
          }
        />
        <NumberWithUnitInputWithTitle
          title="Hrubá hmotnost (brutto)"
          value={packaging.brutto_weight.value}
          setValue={(value) =>
            setPackaging({
              ...packaging,
              brutto_weight: { ...packaging.brutto_weight, value },
            })
          }
          unit={packaging.brutto_weight.unit}
          setUnit={(unit) =>
            setPackaging({
              ...packaging,
              brutto_weight: { ...packaging.brutto_weight, unit },
            })
          }
        />
        <NumberWithUnitInputWithTitle
          title="Čistá váha (netto)"
          value={packaging.netto_weight.value}
          setValue={(value) =>
            setPackaging({
              ...packaging,
              netto_weight: { ...packaging.netto_weight, value },
            })
          }
          unit={packaging.netto_weight.unit}
          setUnit={(unit) =>
            setPackaging({
              ...packaging,
              netto_weight: { ...packaging.netto_weight, unit },
            })
          }
        />
      </InputsWrapper>
    </Flex>
  );
};

export default PackagingInput;
