import { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  ${(props) => css`
    body {
      width: 100%;
      height: 100%;
      background-color: ${props.theme.colors.MAIN_500};
      color: ${props.theme.colors.OPPOSITE_MAIN_500};
      font-family: "Roboto", sans-serif;
      padding: 0;
      margin: 0;
    }

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;

      transition: background-color 250ms ease;

      &:focus {
        box-shadow: 0 0 0 2px ${props.theme.colors.MAIN_150} !important;
      }
    }

    a {
      color: inherit;
      text-decoration: none;

      &:focus {
        color: inherit;
      }
    }

    ::-webkit-scrollbar {
      width: 9px;
    }

    ::-webkit-scrollbar-track {
      background: ${props.theme.colors.MAIN_250};
    }

    ::-webkit-scrollbar-thumb {
      background: ${props.theme.colors.MAIN_50};
      border-radius: ${props.theme.borderRadius / 2}px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${props.theme.colors.SECONDARY_350};
    }

    button {
      font-family: inherit;
      border: none;
      cursor: pointer;
    }

    button:focus {
      outline: none;
    }

    ::selection {
      background-color: ${props.theme.colors.OPPOSITE_MAIN_500};
      color: ${props.theme.colors.MAIN_500};
    }

    br::selection {
      background-color: transparent !important;
      color: unset !important;
    }

    input,
    textarea {
      font-family: inherit;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    .tox.tox-tinymce-aux {
      z-index: 9999;
    }
  `}
`;

export default GlobalStyle;
