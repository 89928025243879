import { IProductFilters } from "./../../types/products";

export enum FiltersActions {
  SET_PRODUCT_FILTERS = "SET_PRODUCT_FILTERS",
  SET_PRODUCT_QUERY = "SET_PRODUCT_QUERY",
}

type TSetProductFilterAction = {
  type: FiltersActions.SET_PRODUCT_FILTERS;
  payload: IProductFilters | null;
};

type TSetProductQueryAction = {
  type: FiltersActions.SET_PRODUCT_QUERY;
  payload: string;
};

export type TFiltersAction = TSetProductFilterAction | TSetProductQueryAction;

export const SET_PRODUCT_FILTERS = (productFilters: IProductFilters | null) => {
  return {
    type: FiltersActions.SET_PRODUCT_FILTERS,
    payload: productFilters,
  };
};

export const SET_PRODUCT_QUERY = (query: string) => {
  return {
    type: FiltersActions.SET_PRODUCT_QUERY,
    payload: query,
  };
};
