import { ICurrencyID } from "../../types/currency";
import axios from "../axios";

export interface ICurrencyModel {
  getCurrencies: () => Promise<ICurrencyID[]>;
  getCurrencyById: (id: string) => Promise<ICurrencyID>;
  addCurrency: (data: FormData) => Promise<ICurrencyID>;
  editCurrency: (data: FormData) => Promise<ICurrencyID>;
  removeCurrencyById: (id: string) => Promise<boolean>;
}

export const currencyModel: ICurrencyModel = {
  getCurrencies() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ICurrencyID[]>(`/currencies/get-all`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getCurrencyById(_id: string) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<ICurrencyID>(`/currencies/get-by-id`, {
          params: {
            _id,
          },
        });

        res(response.data);
      } catch (err: any) {
        rej(err);
      }
    });
  },
  addCurrency(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICurrencyID>(`/currencies/add`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  editCurrency(data) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<ICurrencyID>(`/currencies/edit`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeCurrencyById(_id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/currencies/remove-by-id`, {
          _id,
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
