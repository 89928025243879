import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import styled, { css } from "styled-components";
import { useColors } from "../providers/theme/theme-provider";
import { FlexCenterAlign } from "./common";

interface IProps {
  value: number;
  comparingValue: number;
  formatDiff(value: number): string;
  text: string;
}

const PositiveDiff = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.SUCCESS_400};
  `}
`;

const Text = styled.div`
  margin-left: 8px;
  font-size: 13px;

  ${({ theme }) => css`
    color: ${theme.colors.OPPOSITE_MAIN_450};
  `}
`;

const Diff = styled.div`
  margin-left: 2px;
  font-weight: 500;
  font-size: 13px;
`;

const NegativeDiff = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.ERROR_400};
  `}
`;

export const PositiveNegativeIndicator = ({
  value,
  comparingValue,
  formatDiff,
  text,
}: IProps): React.ReactElement<IProps> => {
  if (value > comparingValue) {
    return (
      <PositiveDiff>
        <AiFillCaretUp />
        <Diff>{formatDiff(value - comparingValue)}</Diff>
        <Text>{text}</Text>
      </PositiveDiff>
    );
  }

  return (
    <NegativeDiff>
      <AiFillCaretDown />
      <Diff>{formatDiff(comparingValue - value)}</Diff>
      <Text>{text}</Text>
    </NegativeDiff>
  );
};

export default PositiveNegativeIndicator;
