const base64Regex =
  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

export const getBase64OfFileOrNull = async (
  file: File | string | null
): Promise<string | null> => {
  if (file instanceof File) {
    return await getBase64(file);
  }

  return file;
};

export const getBase64ArrayOfFileOrNull = async (
  files: (File | string | null)[]
): Promise<(string | null)[]> => {
  const arr: (string | null)[] = [];

  for (const file of files) {
    arr.push(await getBase64OfFileOrNull(file));
  }

  return arr;
};

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = function ({ target }) {
      if (target) {
        res(target.result as string);
      }
    };

    reader.readAsDataURL(file);
  });
};

export const dataUrlToFile = async (
  dataUrl: string,
  fileName: string
): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();

  return new File([blob], fileName, { type: "image/png" });
};

export function isValidBase64Image(str: string): boolean {
  const base64Regex = /^data:image\/[A-Za-z+/]+;base64,[A-Za-z0-9+/]*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  
  return base64Regex.test(str);
}

export const getFileOrURLFromBase64 = async (
  base64orURL: string
): Promise<File | string | null> => {
  if (!base64orURL) return null;

  if (base64Regex.test(base64orURL)) {
    return await dataUrlToFile(base64orURL, "aa");
  }

  return base64orURL;
};

export const getFilesOrURLsArrayFromBase64Array = async (
  base64arr: (string | null)[]
): Promise<(string | File)[]> => {
  const arr: (File | string)[] = [];

  for (const base64orURL of base64arr) {
    if (base64orURL) {
      const file = await getFileOrURLFromBase64(base64orURL);

      if (file) {
        arr.push(file);
      }
    }
  }

  return arr;
};


export async function fileListToBase64(fileList: (File | string)[]) {
  const promises: Promise<string>[] = [];

  for (let i = 0; i < fileList.length; i++) {
    let file = fileList[i];

    if (file instanceof File) {
      promises.push(getBase64(file));
    } else if (typeof file === "string") {
      promises.push(
        new Promise((res) => {
          res(file as string);
        })
      );
    }
  }

  return await Promise.all(promises);
}