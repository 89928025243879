import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTheme } from "styled-components";
import { useColors } from "../providers/theme/theme-provider";
import { TRange } from "./number-scroller";

interface IStartPosition {
  x: number;
  left: number;
}

interface ISingleScroller {
  value: number;
  setValue(value: number): void;
  range: TRange;
}

const POINT_WIDTH = 22;
const POINT_HEIGHT = 22;

export const SingleScroller2 = ({
  value,
  setValue,
  range,
}: ISingleScroller): React.ReactElement<ISingleScroller> => {
  const touchPoint = useRef<HTMLDivElement>(null),
    parentRef = useRef<HTMLDivElement>(null),
    [left, setLeft] = useState(0),
    [moving, setMoving] = useState(false);

  const getLeftByValue = (parentWidth: number): number => {
    const { max } = range;

    const percentValue = value / max;

    return percentValue * parentWidth;
  };

  useEffect(() => {
    const parentWidth = parentRef.current?.clientWidth;

    if (parentWidth) {
      setLeft(getLeftByValue(parentWidth - POINT_WIDTH));
    }
  }, [parentRef.current?.clientWidth, value]);

  const handlePointMouseDown = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const touchScreenX = e.screenX,
      parentWidth = parentRef.current?.clientWidth;

    const mouseMove = (e: MouseEvent | TouchEvent) => {
      if (!parentWidth) {
        return;
      }

      setMoving(true);

      const screenX =
        e instanceof MouseEvent ? e.screenX : e.touches[0].screenX;

      const pos = screenX - touchScreenX + left;

      const percentPos = pos / (parentWidth - 21);

      if (percentPos > 1) {
        setValue(range.max);
        return;
      }

      if (percentPos <= 0) {
        setValue(range.min);
        return;
      }

      setValue(percentPos * range.max);
    };

    const mouseUp = () => {
      setMoving(false);
      window.removeEventListener("mousemove", mouseMove);
    };

    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", mouseUp);

    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mouseup", mouseUp);
  };

  const colors = useColors();

  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: colors.MAIN_350,
        position: "relative",
        height: 22,
        borderRadius: theme.borderRadius,
      }}
      ref={parentRef}
    >
      <div
        onMouseDown={handlePointMouseDown}
        ref={touchPoint}
        style={{
          position: "absolute",
          height: POINT_HEIGHT,
          width: POINT_WIDTH,
          top: 0,
          left,
          userSelect: "none",
          backgroundColor: colors.OPPOSITE_MAIN_200,
          borderRadius: theme.borderRadius,
          cursor: moving ? "grabbing" : "grab",
        }}
      />

      {/* <div
            style={{
              backgroundColor: colors.MAIN_50,
              borderRadius: 24,
              height: 3,
              userSelect: "none",
              position: "absolute",
              left: 0,
              top: 9,
            }}
          /> */}
    </div>
  );
};
