import { ReactNode } from "react";
import { useTheme } from "styled-components";
import { getParameterValueValue } from "../../../../../common/parameter-value";
import {
  IParameterValueID,
  ParameterValueType,
} from "../../../../../types/parameter";
import { FlexCenterAlign } from "../../../../../ui/common";
import { useAppConfig } from "../../../../../common/redux-hooks";

interface IColorContentProps {
  parameterValue: IParameterValueID;
}

const ColorContent = ({ parameterValue }: IColorContentProps) => {
  const { borderRadius } = useTheme();
  const { language } = useAppConfig();
  const color = parameterValue.params?.color;

  return (
    <FlexCenterAlign>
      {color && (
        <div
          style={{
            width: 16,
            height: 16,
            marginRight: 6,
            backgroundColor: color,
            borderRadius: borderRadius / 1.5,
          }}
        />
      )}
      {getParameterValueValue(parameterValue, language.locale)}
    </FlexCenterAlign>
  );
};

export const getContentForParameterValue = (
  parameterValue: IParameterValueID,
  language: string
): ReactNode | string => {
  switch (parameterValue.type) {
    case ParameterValueType.Color:
      return <ColorContent parameterValue={parameterValue} />;
    default:
      return getParameterValueValue(parameterValue, language);
  }
};
