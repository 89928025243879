import React, { useRef } from "react";
import { BsExclamation } from "react-icons/bs";
import styled, { css } from "styled-components";
import { getErrorTranslation } from "../../common/errors";
import { useColors } from "../../providers/theme/theme-provider";
import { TReduxError } from "../../types/error";

interface IStyledErrorTooltipProps {
  hidden?: boolean;
}

const StyledErrorTooltip = styled.div<IStyledErrorTooltipProps>`
  ${(props) => css`
    background-color: ${props.theme.colors.ERROR_400};
  `}
  width: 110px;
  color: #fff;
  position: absolute;
  text-align: center;
  padding: 8px 8px;
  border-radius: 12px;
  top: 30px;
  font-size: 11px;
  z-index: 44;

  opacity: 0;
  visibility: hidden;
  transition: visibility 150ms ease, opacity 150ms ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

interface IProps {
  error?: TReduxError | string | boolean;
  errorTooltipDisabled?: boolean;
}

export const ErrorTooltip = ({
  error,
  errorTooltipDisabled,
}: IProps): React.ReactElement<IProps> => {
  const errorTooltipRef = useRef<HTMLDivElement>(null);
  const colors = useColors();

  const onMouseEnterError = () => {
    errorTooltipRef.current?.classList.add("active");
  };

  const onMouseLeaveError = () => {
    errorTooltipRef.current?.classList.remove("active");
  };

  if (!error) return <></>;

  let translation = null;

  if (typeof error !== "boolean" && typeof error !== "string") {
    translation = getErrorTranslation(error);
  } else if(typeof error === "string") {
    translation = error;
  }

  return (
    <div
      style={{
        cursor: "default",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <BsExclamation
        onMouseEnter={errorTooltipDisabled ? undefined : onMouseEnterError}
        onMouseLeave={errorTooltipDisabled ? undefined : onMouseLeaveError}
        size={26}
        color={colors.ERROR_500}
      />
      {translation && (
        <StyledErrorTooltip ref={errorTooltipRef} hidden={!translation}>
          {translation}
        </StyledErrorTooltip>
      )}
    </div>
  );
};

export default ErrorTooltip;
