import { ILanguage, ILanguageID } from "./../types/language";
import { CLEAR_ERRORS_OF_TYPE } from "../redux/actions/errorsActions";
import { IProductsState } from "../redux/reducers/productsReducer";
import { useDispatch, useSelector } from "../redux/store";
import { ErrorType, TReduxError } from "../types/error";
import { IProduct, IProductFilters } from "../types/products";
import { IRole } from "../types/role";
import { ITaskDB } from "../types/tasks";
import { IUserDB } from "../types/user";
import { SET_ERRORS } from "./../redux/actions/errorsActions";
import { ICategoriesState } from "./../redux/reducers/categoriesReducer";
import { ICurrencyID } from "./../types/currency";
import { IHistoryAction } from "./../types/history";
import { IManufacturerID } from "./../types/manufacturer";
import {
  IParameterGroupID,
  IParameterID,
  IParameterValueID,
} from "./../types/parameter";
import { IPaymentID } from "../types/payment";
import { IShippingID } from "../types/shipping";
import { IStoreID } from "../types/store";
import { AppConfigEnum, IAppConfig } from "../redux/reducers/appConfigReducer";

export const useAppConfigWithLoading = ():
  | IAppConfig
  | AppConfigEnum.IsLoading => {
  const appConfig = useSelector(({ appConfig }) => appConfig);

  return appConfig.config;
};

export const useAppConfig = (): IAppConfig => {
  const { config } = useSelector(({ appConfig }) => appConfig);

  if (config === AppConfigEnum.IsLoading) {
    throw new Error("App config is not loaded!");
  }

  return config;
};

export const useRole = (): IRole => {
  const role = useSelector(({ user }) => user.user?.role);

  return role!;
};

export const useUser = (): IUserDB => {
  const user = useSelector(({ user }) => user.user);

  return user;
};

export const useLoggedIn = (): boolean => {
  const logged = useSelector(({ user }) => user.logged);

  return logged;
};

export const useTasks = (): ITaskDB[] => {
  const tasks = useSelector(({ tasks }) => tasks.tasks);

  return tasks;
};

export const useProducts = (): IProduct[] => {
  const products = useSelector(({ products }) => products.products);

  return products;
};

export const useLanguages = (): ILanguageID[] => {
  const languages = useSelector(({ languages }) => languages.languages);

  return languages;
};

export const useStores = (): IStoreID[] => {
  const stores = useSelector(({ stores }) => stores.stores);

  return stores;
};

export const useCategoriesState = (): ICategoriesState => {
  const categoriesState = useSelector(({ categories }) => categories);

  return categoriesState;
};

export const useCurrencies = (): ICurrencyID[] => {
  const currencies = useSelector(({ currencies }) => currencies.currencies);

  return currencies;
};

export const useProductsState = (): IProductsState => {
  const products = useSelector(({ products }) => products);

  return products;
};

export const useProductFilters = (): IProductFilters | null => {
  const productFilters = useSelector(({ filters }) => filters.products.filters);

  return productFilters;
};

export const useProductFilterQuery = (): string => {
  const query = useSelector(({ filters }) => filters.products.query);

  return query;
};

export const useUsers = (): IUserDB[] => {
  const users = useSelector(({ users }) => users.users);

  return users;
};

export const useHistoryActions = (): IHistoryAction[] => {
  const historyActions = useSelector(
    ({ historyActions }) => historyActions.historyActions
  );

  return historyActions;
};

export const useParameters = (): IParameterID[] => {
  const parameters = useSelector(({ parameters }) => parameters.parameters);

  return parameters;
};

export const usePayments = (): IPaymentID[] => {
  const payments = useSelector(({ payments }) => payments.payments);

  return payments;
};

export const useShippings = (): IShippingID[] => {
  const shippings = useSelector(({ shippings }) => shippings.shippings);

  return shippings;
};

export const useParameterValues = (): IParameterValueID[] => {
  const values = useSelector(({ parameterValues }) => parameterValues.values);

  return values;
};

export const useParameterGroups = (): IParameterGroupID[] => {
  const groups = useSelector(({ parameterGroups }) => parameterGroups.groups);

  return groups;
};

export const useManufacturers = (): IManufacturerID[] => {
  const manufacturers = useSelector(
    ({ manufacturers }) => manufacturers.manufacturers
  );

  return manufacturers;
};

export type TGetError = (
  key: string,
  type: ErrorType,
  params?: any
) => TReduxError | undefined;

interface IUseErrors {
  getError: TGetError;
  getErrorsForType(type: ErrorType): TReduxError[] | undefined;
  clearErrorsOfType(type: ErrorType): void;
}

export const useErrors = (): IUseErrors => {
  const errors = useSelector(({ errors }) => errors.errors);
  const dispatch = useDispatch();

  const checkErrors = () => {
    if (!Array.isArray(errors) || !errors) {
      dispatch(SET_ERRORS([]));
      return false;
    }

    return true;
  };

  return {
    getError(key, type, params) {
      if (!checkErrors()) {
        return undefined;
      }

      return errors.find(
        (e) =>
          e &&
          e.key === key &&
          e.type === type &&
          (!params || !e.params
            ? true
            : JSON.stringify(params) === JSON.stringify(e.params))
      );
    },
    getErrorsForType(type) {
      if (!checkErrors()) {
        return undefined;
      }

      const err = errors.filter((e) => e.type === type);

      if (err.length) {
        return err;
      }

      return undefined;
    },
    clearErrorsOfType(type) {
      dispatch(CLEAR_ERRORS_OF_TYPE(type));
    },
  };
};
