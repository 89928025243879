import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Button from "../button";
import { ISelectedTimes } from "./types";
import { colors } from "./../colors";
import IconButton from "./../icon-button";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FlexCenterAll } from "./../common";
import styled, { css } from "styled-components";
import { useColors } from "../../providers/theme/theme-provider";

interface IStyledYearMonthSelectProps {
  active: boolean;
  height: number;
}

const StyledYearMonthSelect = styled.div<IStyledYearMonthSelectProps>`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_250};
    border-radius: ${props.theme.borderRadius}px;
  `}

  position: absolute;
  width: 100%;
  top: 40px;
  opacity: 0;
  transition: opacity 150ms ease, height 150ms ease;

  ${(props) =>
    props.active &&
    css`
      height: ${props.height}px;
      opacity: 1;
      transition: opacity 150ms ease, height 150ms ease;
    `}
`;

interface IAppearingMonthProps {
  i: number;
  opened: boolean;
  month: { component: JSX.Element; month: number };
  size: number;
  onSelectMonth(year: number): void;
  isOutOfRange: boolean;
}

const AppearingMonth = ({
  i,
  month,
  opened,
  size,
  onSelectMonth,
  isOutOfRange,
}: IAppearingMonthProps) => {
  const colors = useColors();

  return (
    <FlexCenterAll
      style={{
        opacity: isOutOfRange ? 0.2 : 1,
        width: size && size / 3,
        height: size && size / 4,
      }}
    >
      <Button
        hoverBackgroundColor={colors.MAIN_100}
        style={{
          backgroundColor: colors.MAIN_150,
          boxShadow: "unset",
          padding: 4,
          width: "100%",
        }}
        disabled={isOutOfRange}
        key={i}
        onClick={() =>
          onSelectMonth(month.month + 1 > 11 ? 0 : month.month + 1)
        }
      >
        {month.component}
      </Button>
    </FlexCenterAll>
  );
};

interface IMonthSelectProps {
  months: string[];
  opened: boolean;
  size: number;
  onSelectMonth(month: number): void;
  selectedYear: number;
  minDate?: Date;
  maxDate?: Date;
}

export const MonthSelect = ({
  months,
  opened,
  size,
  onSelectMonth,
  selectedYear,
  minDate,
  maxDate,
}: IMonthSelectProps): React.ReactElement<IMonthSelectProps> => {
  const [monthComponents, setMonthComponents] = useState<
      { component: JSX.Element; month: number }[]
    >([]),
    height = size / 4.5 - 24;

  useEffect(() => {
    if (height === 0) return;

    let componentArr = [];
    const _months = [...months];

    _months.push(_months.shift() || "");

    for (let i = 0; i < 12; i++) {
      componentArr.push({
        month: i,
        component: (
          <FlexCenterAll
            style={{
              height,
              justifyContent: "center",
              margin: "0px 8px",
            }}
          >
            <div
              style={{
                fontSize: 14,
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              {_months[i]}
            </div>
          </FlexCenterAll>
        ),
      });
    }

    setMonthComponents(componentArr);
  }, [height]);

  return (
    <div
      style={{
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-around",
        display: opened ? "flex" : "none",
      }}
    >
      {monthComponents.map((m, i) => {
        let outOfRange = false;

        const isMaxYear = maxDate && selectedYear === maxDate.getFullYear(),
          isMinYear = minDate && selectedYear === minDate.getFullYear();

        if (isMinYear) {
          outOfRange = minDate && m.month < minDate.getMonth();
        } else if (isMaxYear) {
          outOfRange = maxDate && m.month > maxDate.getMonth();
        }

        return (
          <AppearingMonth
            key={i}
            i={i}
            size={size}
            month={m}
            onSelectMonth={onSelectMonth}
            opened={opened}
            isOutOfRange={outOfRange}
          />
        );
      })}
    </div>
  );
};

interface IAppearingYearProps {
  i: number;
  opened: boolean;
  year: { component: JSX.Element; year: number };
  size: number;
  onSelectYear(year: number): void;
  minDate?: Date;
  maxDate?: Date;
}

const AppearingYear = ({
  i,
  year,
  opened,
  size,
  onSelectYear,
  minDate,
  maxDate,
}: IAppearingYearProps) => {
  const isOutOfRange =
    (minDate && year.year < minDate.getFullYear()) ||
    (maxDate && year.year > maxDate.getFullYear());

  const colors = useColors();

  return (
    <FlexCenterAll
      style={{
        opacity: isOutOfRange ? 0.2 : 1,
        width: size && size / 3,
        height: size && size / 3,
      }}
    >
      <Button
        style={{
          backgroundColor: colors.MAIN_150,
          boxShadow: "unset",
        }}
        hoverBackgroundColor={colors.MAIN_100}
        disabled={isOutOfRange}
        key={i}
        onClick={() => onSelectYear(year.year)}
      >
        {year.component}
      </Button>
    </FlexCenterAll>
  );
};

interface IYearSelectProps {
  opened: boolean;
  size: number;
  currentYear: number;
  onSelectYear(year: number): void;
  minDate?: Date;
  maxDate?: Date;
}

export const YearSelect = ({
  opened,
  size,
  currentYear,
  onSelectYear,
  minDate,
  maxDate,
}: IYearSelectProps): React.ReactElement<IYearSelectProps> => {
  const [yearComponents, setYearComponents] = useState<
    { component: JSX.Element; year: number }[]
  >([]);
  const height = size / 4 - 24;

  useEffect(() => {
    if (height === 0) return;

    let componentArr = [];

    for (let i = -4; i < 5; i++) {
      componentArr.push({
        year: currentYear + i,
        component: (
          <FlexCenterAll
            style={{
              height,
            }}
          >
            <div
              style={{
                fontSize: 15,
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              {currentYear + i}
            </div>
          </FlexCenterAll>
        ),
      });
    }

    setYearComponents(componentArr);
  }, [currentYear, size]);

  return (
    <div
      style={{
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-around",
        display: opened ? "flex" : "none",
      }}
    >
      {yearComponents.map((y, i) => (
        <AppearingYear
          opened={opened}
          key={i}
          i={i}
          size={size}
          onSelectYear={onSelectYear}
          year={y}
          minDate={minDate}
          maxDate={maxDate}
        />
      ))}
    </div>
  );
};

export interface YearMonthSelectRef {
  open(): void;
}

enum Opened {
  None,
  Year,
  Month,
}

interface IProps {
  months: string[];
  size: number;
  selectedTimes: ISelectedTimes;
  setSelectedTimes(selectedTimes: ISelectedTimes): void;
  minDate?: Date;
  maxDate?: Date;
}

export const YearMonthSelect = forwardRef(
  (
    { months, size, selectedTimes, setSelectedTimes, minDate, maxDate }: IProps,
    ref: ForwardedRef<YearMonthSelectRef>
  ): React.ReactElement<IProps> => {
    const [opened, setOpened] = useState<Opened>(Opened.None);
    const colors = useColors()

    const open = () => {
      if (opened === Opened.None) {
        setOpened(Opened.Year);
      } else {
        setOpened(Opened.None);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      []
    );

    const [browseYears, setBrowseYears] = useState(selectedTimes.year);

    const [selected, setSelected] = useState<{
      year: number;
      month: number;
    }>({
      year: -1,
      month: -1,
    });

    const onSelectYear = (year: number) => {
      setSelected({
        year,
        month: -1,
      });
      setOpened(Opened.Month);
    };

    const onSelectMonth = (month: number) => {
      setSelected({
        ...selected,
        month,
      });
      setOpened(Opened.None);
      setSelectedTimes({
        ...selectedTimes,
        month: month,
        year: selected.year,
      });
    };

    const isDisabledMin =
      opened === Opened.None
        ? minDate
          ? selectedTimes.month <= minDate.getMonth() + 1 &&
            selectedTimes.year <= minDate.getFullYear()
          : false
        : opened === Opened.Year
        ? minDate && !(minDate.getFullYear() < browseYears - 4)
        : false;

    const isDisabledMax =
      opened === Opened.None
        ? maxDate
          ? selectedTimes.month >= maxDate.getMonth() + 1 &&
            selectedTimes.year >= maxDate.getFullYear()
          : false
        : opened === Opened.Year
        ? maxDate && !(maxDate.getFullYear() > browseYears + 4)
        : false;

    return (
      <div
        style={{
          top: 3,
          zIndex: 1,
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            padding: "8px 0",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          {opened === Opened.Month ? (
            <div
              style={{
                height: 32,
              }}
            />
          ) : (
            <IconButton
              disabled={isDisabledMin}
              style={{
                opacity: isDisabledMin ? 0.2 : 1,
                padding: 6,
                backgroundColor: colors.MAIN_250,
              }}
              onClick={() => {
                if (opened === Opened.Year) {
                  setBrowseYears((b) => b - 9);

                  return;
                }

                const { month } = selectedTimes;
                let times: ISelectedTimes;

                if (month - 1 < 0) {
                  times = {
                    ...selectedTimes,
                    year: selectedTimes.year - 1,
                    month: 11,
                  };
                  setSelectedTimes(times);
                  return;
                }

                times = {
                  ...selectedTimes,
                  month: month - 1,
                };

                setSelectedTimes(times);
              }}
            >
              <FiChevronLeft size={20} color={colors.OPPOSITE_MAIN_400} />
            </IconButton>
          )}

          <Button
            style={{
              backgroundColor: colors.MAIN_250,
              boxShadow: "unset",
              padding: "0.5em 2em",
            }}
            onClick={open}
          >
            <div
              style={{
                fontSize: 15,
                letterSpacing: 0.75,
                color: colors.OPPOSITE_MAIN_400,
                textAlign: "center",
              }}
            >
              {opened === Opened.None ? (
                <>
                  {months[selectedTimes.month]} {selectedTimes.year}
                </>
              ) : opened === Opened.Month ? (
                selected.year
              ) : (
                "Vyberte rok"
              )}
            </div>
          </Button>

          {opened === Opened.Month ? (
            <div
              style={{
                height: 24,
              }}
            />
          ) : (
            <IconButton
              disabled={isDisabledMax}
              style={{
                opacity: isDisabledMin ? 0.2 : 1,
                padding: 6,
                backgroundColor: colors.MAIN_250,
              }}
              onClick={() => {
                if (opened === Opened.Year) {
                  setBrowseYears((b) => b + 9);

                  return;
                }

                const { month } = selectedTimes;
                let times: ISelectedTimes;

                if (month + 1 > 11) {
                  times = {
                    ...selectedTimes,
                    year: selectedTimes.year + 1,
                    month: 0,
                  };
                  setSelectedTimes(times);
                  return;
                }

                times = {
                  ...selectedTimes,
                  month: month + 1,
                };

                setSelectedTimes(times);
              }}
            >
              <FiChevronRight size={20} color={colors.OPPOSITE_MAIN_400} />
            </IconButton>
          )}
        </div>

        <StyledYearMonthSelect
          height={size - 40}
          active={opened !== Opened.None}
        >
          <YearSelect
            minDate={minDate}
            maxDate={maxDate}
            opened={opened === Opened.Year}
            size={size - 40}
            onSelectYear={onSelectYear}
            currentYear={browseYears}
          />
          <MonthSelect
            minDate={minDate}
            maxDate={maxDate}
            opened={opened === Opened.Month}
            size={size - 40}
            selectedYear={selected.year}
            onSelectMonth={onSelectMonth}
            months={months}
          />
        </StyledYearMonthSelect>
      </div>
    );
  }
);

export default YearMonthSelect;
