export enum LengthUnit {
  mm = "mm",
  cm = "cm",
  dm = "dm",
  m = "m",
}

export enum WeightUnit {
  mg = "mg",
  g = "g",
  dg = "dg",
  kg = "kg",
  t = "t",
}

export type TUnit = WeightUnit | LengthUnit;

export type TNumberWithUnit<T = TUnit> = {
  value: number;
  unit: T;
};

export const determineUnitType = (unit: TUnit): UnitType => {
  if (LengthUnit[unit as keyof typeof LengthUnit]) {
    return UnitType.Length;
  }

  return UnitType.Weight;
};

export enum UnitType {
  Length,
  Weight,
}

export const availableLengthUnits: LengthUnit[] = [
  LengthUnit.mm,
  LengthUnit.cm,
  LengthUnit.dm,
  LengthUnit.m,
];

export const availableWeightUnits: WeightUnit[] = [
  WeightUnit.mg,
  WeightUnit.g,
  WeightUnit.dg,
  WeightUnit.kg,
  WeightUnit.t,
];
