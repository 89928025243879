import React, { useState, useCallback } from "react";
import Dropdown from "../../../ui/dropdown";
import { IOrder, OrderState, ShippingType } from "../../../types/order";
import { getOrderStateTitle } from "../../../locales/determineString";
import { FlexCenterAll, HeaderText } from "../../../ui/common";
import Button from "../../../ui/button";
import { useColors } from "../../../providers/theme/theme-provider";
import { useModal } from "../../../providers/modal/modal-provider";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { orderModel } from "../../../model/query/orderModel";

interface IProps {
  order: IOrder;
  fetch(state: OrderState): void;
}

export const OrderStateChangeModal = ({
  order,
  fetch,
}: IProps): React.ReactElement<IProps> => {
  const { closeModal } = useModal();
  const [state, setState] = useState(order.state);
  const colors = useColors();

  const { call } = useQueryWithPopupErrorHandling();

  const okClick = useCallback(() => {
    if (state === order.state) {
      closeModal();
    }

    const data = new FormData();

    data.append("_id", order._id);
    data.append("state", state);

    call(
      () => orderModel.editOrderState(data),
      "Změna stavu objednávky nebyla úspěšná",
      "Změna stavu objednávky byla úspěšná",
      () => {
        fetch(state);
        closeModal();
      }
    );
  }, [order, state]);

  return (
    <div
      style={{
        padding: 20,
        paddingRight: 40,
      }}
    >
      <HeaderText
        style={{
          fontSize: 14,
          marginLeft: 4,
          marginBottom: 4,
        }}
      >
        Změnit stav objednávky
      </HeaderText>
      <Dropdown
        list={Object.values(OrderState).map((state) => ({
          content: getOrderStateTitle(state),
          disabled: state === OrderState.HandedToCarrier && order.shipping_details?.type === ShippingType.PPL && !order.shipping_details.shipmentNumber,
          value: state,
          unique_id: state,
        }))}
        onSelectValue={(state) => setState(state)}
        selectedUniqueId={state || ""}
        title="Stav objednávky"
      />

      <FlexCenterAll
        style={{
          marginTop: 8,
        }}
      >
        <div>
          <Button
            disabled={!state}
            style={{
              backgroundColor: colors.MAIN_300,
              color: colors.OPPOSITE_MAIN_300,
              fontSize: 13,
            }}
            hoverBackgroundColor={colors.MAIN_250}
            onClick={okClick}
          >
            OK
          </Button>
        </div>
        <div>
          <Button
            style={{
              backgroundColor: colors.MAIN_400,
              color: colors.OPPOSITE_MAIN_400,
              fontSize: 13,
              marginLeft: 8,
            }}
            hoverBackgroundColor={colors.MAIN_350}
            onClick={closeModal}
          >
            Storno
          </Button>
        </div>
      </FlexCenterAll>
    </div>
  );
};

export default OrderStateChangeModal;
