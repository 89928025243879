import { AdKey, IAdID } from "../../types/ad";
import axios from "../axios";

export interface IAdModel {
  getAdEntries(
    key: AdKey,
    offset: number
  ): Promise<{ totalCount: number; adEntries: IAdID[] }>;
}

export const adModel: IAdModel = {
  getAdEntries(key, offset) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get(`/ad/get-ad-entries`, {
          params: { key, offset },
        });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
