export const DARK_THEME: IThemeColors = {
  MAIN_500: "#111",
  OPPOSITE_MAIN_500: "#eee",
  MAIN_450: "#1c1c1c",
  OPPOSITE_MAIN_450: "#e3e3e3",
  MAIN_400: "#252525",
  OPPOSITE_MAIN_400: "#dadada",
  MAIN_350: "#2f2f2f",
  OPPOSITE_MAIN_350: "#d0d0d0",
  MAIN_300: "#3a3a3a",
  OPPOSITE_MAIN_300: "#c5c5c5",
  MAIN_250: "#444",
  OPPOSITE_MAIN_250: "#bbb",
  MAIN_200: "#4d4d4d",
  OPPOSITE_MAIN_200: "#b2b2b2",
  MAIN_150: "#585858",
  OPPOSITE_MAIN_150: "#a7a7a7",
  MAIN_100: "#666",
  OPPOSITE_MAIN_100: "#999",
  MAIN_50: "#777",
  OPPOSITE_MAIN_50: "#888",
  SECONDARY_500: "#444",
  OPPOSITE_SECONDARY_500: "#bbb",
  SECONDARY_450: "#4d4d4d",
  OPPOSITE_SECONDARY_450: "#b2b2b2",
  SECONDARY_400: "#555",
  OPPOSITE_SECONDARY_400: "#aaa",
  SECONDARY_350: "#606060",
  OPPOSITE_SECONDARY_350: "#9f9f9f",
  SECONDARY_300: "#666",
  OPPOSITE_SECONDARY_300: "#999",
  SECONDARY_250: "#717171",
  OPPOSITE_SECONDARY_250: "#8e8e8e",
  SECONDARY_200: "#777",
  OPPOSITE_SECONDARY_200: "#888",
  SECONDARY_150: "#828282",
  OPPOSITE_SECONDARY_150: "#7d7d7d",
  SECONDARY_100: "#888",
  OPPOSITE_SECONDARY_100: "#777",
  SECONDARY_50: "#999",
  OPPOSITE_SECONDARY_50: "#666",
  ACCENT_400: "#0078d4",
  ACCENT_300: "#4a86e8",
  ERROR_400: "#db7879",
  ERROR_500: "#c96163",
  SUCCESS_400: "#99cc33",
  WARNING_400: "#c49d02",
};

export const LIGHT_THEME: IThemeColors = {
  MAIN_500: "#fff",
  MAIN_450: "#f2f2f2",
  MAIN_400: "#e6e6e6",
  MAIN_350: "#d9d9d9",
  MAIN_300: "#cccccc",
  MAIN_250: "#bfbfbf",
  MAIN_200: "#b3b3b3",
  MAIN_150: "#a6a6a6",
  MAIN_100: "#999",
  MAIN_50: "#8c8c8c",
  OPPOSITE_MAIN_500: "#000",
  OPPOSITE_MAIN_450: "#0d0d0d",
  OPPOSITE_MAIN_400: "#191919",
  OPPOSITE_MAIN_350: "#262626",
  OPPOSITE_MAIN_300: "#333",
  OPPOSITE_MAIN_250: "#404040",
  OPPOSITE_MAIN_200: "#4d4d4d",
  OPPOSITE_MAIN_150: "#595959",
  OPPOSITE_MAIN_100: "#666",
  OPPOSITE_MAIN_50: "#737373",
  SECONDARY_500: "#999",
  SECONDARY_450: "#8c8c8c",
  SECONDARY_400: "#828282",
  SECONDARY_350: "#767676",
  SECONDARY_300: "#6b6b6b",
  SECONDARY_250: "#5f5f5f",
  SECONDARY_200: "#555",
  SECONDARY_150: "#4a4a4a",
  SECONDARY_100: "#3e3e3e",
  SECONDARY_50: "#333",
  OPPOSITE_SECONDARY_500: "#666",
  OPPOSITE_SECONDARY_450: "#737373",
  OPPOSITE_SECONDARY_400: "#7d7d7d",
  OPPOSITE_SECONDARY_350: "#898989",
  OPPOSITE_SECONDARY_300: "#949494",
  OPPOSITE_SECONDARY_250: "#a1a1a1",
  OPPOSITE_SECONDARY_200: "#b0b0b0",
  OPPOSITE_SECONDARY_150: "#bcbcbc",
  OPPOSITE_SECONDARY_100: "#c9c9c9",
  OPPOSITE_SECONDARY_50: "#d6d6d6",
  ACCENT_400: "#0078d4",
  ACCENT_300: "#4a86e8",
  ERROR_400: "#db7879",
  ERROR_500: "#c96163",
  SUCCESS_400: "#99cc33",
  WARNING_400: "#c49d02",
};

export interface IThemeColors {
  MAIN_500: string;
  OPPOSITE_MAIN_500: string;
  MAIN_450: string;
  OPPOSITE_MAIN_450: string;
  MAIN_400: string;
  OPPOSITE_MAIN_400: string;
  MAIN_350: string;
  OPPOSITE_MAIN_350: string;
  MAIN_300: string;
  OPPOSITE_MAIN_300: string;
  MAIN_250: string;
  OPPOSITE_MAIN_250: string;
  MAIN_200: string;
  OPPOSITE_MAIN_200: string;
  MAIN_150: string;
  OPPOSITE_MAIN_150: string;
  MAIN_100: string;
  OPPOSITE_MAIN_100: string;
  MAIN_50: string;
  OPPOSITE_MAIN_50: string;
  SECONDARY_500: string;
  OPPOSITE_SECONDARY_500: string;
  SECONDARY_450: string;
  OPPOSITE_SECONDARY_450: string;
  SECONDARY_400: string;
  OPPOSITE_SECONDARY_400: string;
  SECONDARY_350: string;
  OPPOSITE_SECONDARY_350: string;
  SECONDARY_300: string;
  OPPOSITE_SECONDARY_300: string;
  SECONDARY_250: string;
  OPPOSITE_SECONDARY_250: string;
  SECONDARY_200: string;
  OPPOSITE_SECONDARY_200: string;
  SECONDARY_150: string;
  OPPOSITE_SECONDARY_150: string;
  SECONDARY_100: string;
  OPPOSITE_SECONDARY_100: string;
  SECONDARY_50: string;
  OPPOSITE_SECONDARY_50: string;
  ACCENT_400: string;
  ACCENT_300: string;
  ERROR_400: string;
  ERROR_500: string;
  SUCCESS_400: string;
  WARNING_400: string;
}
