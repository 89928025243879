import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { compareArrays, getFullName } from "../../../common/functions";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import { useErrors, useUser, useUsers } from "../../../common/redux-hooks";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useModal } from "../../../providers/modal/modal-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_TASK,
  EDIT_TASK,
  REMOVE_TASK_BY_ID,
} from "../../../redux/actions/tasksActions";
import { useDispatch } from "../../../redux/store";
import { EditorType } from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { ImageType } from "../../../types/gallery";
import { ITask, ITaskDB, TaskPriority, TaskState } from "../../../types/tasks";
import Button from "../../../ui/button";
import CalendarButton from "../../../ui/calendar-button";
import Checkbox from "../../../ui/checkbox";
import {
  CloseButton,
  EditorImage,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  HeaderText,
  InputsWrapper,
} from "../../../ui/common";
import GalleryPicker from "../../../ui/gallery-picker";
import Input from "../../../ui/input";
import { GalleryListMode } from "../../gallery/index";
import { DropdownMultiSelect } from "./../../../ui/dropdown-multi-select";
import TaskDropdown from "./components";
import PriorityDropdown from "./priority-dropdown";
import { getTaskEditorErrors } from "./tasks-errors";
import { SelectedDateType } from "../../../ui/calendar/types";

const appendTaskToForm = (form: FormData, task: ITask, _id?: string) => {
  const {
    assigned,
    assignees_edit_permission,
    description,
    due_date,
    priority,
    state,
    title,
    created_by,
    images,
  } = task;

  if (_id) {
    form.append("_id", _id);
  }
  form.append("assigned", JSON.stringify(assigned.map((u) => u._id)));
  form.append(
    "assignees_edit_permission",
    JSON.stringify(assignees_edit_permission)
  );
  form.append("description", description);
  form.append("due_date", due_date.toString());
  form.append("priority", priority);
  form.append("state", state);
  form.append("title", title);
  form.append("created_by", created_by._id);
  form.append("images", JSON.stringify(images.map((i) => i._id)));
};

const compareTasks = (task1: ITask, task2: ITask): boolean => {
  const due_date1 = new Date(task1.due_date),
    due_date2 = new Date(task2.due_date);

  return (
    task1.title === task2.title &&
    task1.description === task2.description &&
    task1.priority === task2.priority &&
    compareArrays(
      task1.assigned.map((a) => a._id),
      task2.assigned.map((a) => a._id),
      true
    ) &&
    due_date1.getDate() === due_date2.getDate() &&
    due_date1.getMonth() === due_date2.getMonth() &&
    due_date1.getFullYear() === due_date2.getFullYear() &&
    task1.state === task2.state &&
    task1.created_by._id === task2.created_by._id &&
    task1.created_date === task2.created_date &&
    task1.assignees_edit_permission === task2.assignees_edit_permission
  );
};

const InvisibleTextArea = styled.textarea`
  ${(props) => css`
    background-color: ${props.theme.colors.MAIN_400};
    color: ${props.theme.colors.OPPOSITE_MAIN_400};
  `}

  padding: 8px;
  border: unset;
  border-radius: 8px;
  resize: none;
  width: 100%;
  min-width: 380px;
  min-height: 160px;
  font-family: inherit;

  &::placeholder {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }
`;

const defaultTask = {
  assigned: [],
  assignees_edit_permission: false,
  created_date: new Date().getTime(),
  description: "",
  due_date: new Date().getTime() + 100000,
  priority: TaskPriority.Medium,
  state: TaskState.New,
  title: "",
  images: [],
};

interface IProps {
  type: EditorType;
  task?: ITask;
  _id?: string;
}

export const TaskView = ({
  _id,
  type,
  task,
}: IProps): React.ReactElement<IProps> => {
  const user = useUser();
  const users = useUsers();
  const { closeModal } = useModal();
  const { createConfirmation } = useConfirmation();
  const [changedTask, setChangedTask] = useState<ITask>(
    task || { ...defaultTask, created_by: user }
  );

  const okClick = () => {
    if (!_id || !task) {
      closeModal();
      return;
    }

    changeTask({ ...task, state: changedTask.state, _id });
  };

  const onRemoveTaskClick = () => {
    if (!_id || !task) {
      closeModal();
      return;
    }

    createConfirmation("Opravdu chcete vymazat tento úkol?", () => {
      removeTask(_id);
      closeModal();
    });
  };

  const onEditTaskClick = () => {
    if (!_id || !task) {
      closeModal();
      return;
    }

    changeTask({ ...changedTask, _id });
  };

  const onAddTaskClick = () => {
    if (type !== EditorType.Add) {
      return;
    }

    addTask(changedTask);
  };

  const dispatch = useDispatch();

  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getTaskEditorErrors(getError, changedTask);

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.TASK);
    };
  }, []);

  const addTask = async (task: ITask) => {
    const form = new FormData();

    appendTaskToForm(form, task);

    try {
      const newTask = await ADD_TASK(form);

      dispatch(newTask);
      closeModal();
    } catch (err) {
      dispatch(err);
    }
  };

  const { call } = useQueryWithPopupErrorHandling();

  const removeTask = async (id: string) => {
    call(
      () => REMOVE_TASK_BY_ID(id),
      "Odstranění úkolu nebylo úspěšné",
      "Odstranění úkolu bylo úspěšné",
      (data) => {
        dispatch(data);
      }
    );
  };

  const changeTask = async (task: ITaskDB) => {
    const form = new FormData();

    appendTaskToForm(form, task, task._id);

    try {
      const editedTask = await EDIT_TASK(form);

      dispatch(editedTask);
      closeModal();
    } catch (err) {
      dispatch(err);
    }
  };

  const isOwner = user._id === changedTask.created_by._id;
  const canEditTask = isOwner || changedTask.assignees_edit_permission;

  const colors = useColors();
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <InputsWrapper
      ref={wrapperRef}
      style={{
        minWidth: 350,
        padding: 24,
        paddingRight: 44,
        position: "relative",
        paddingTop: 24,
        overflow: "auto",
        maxHeight: "90vh",
      }}
    >
      <CloseButton
        data-title="Zavřít okno (Esc)"
        onClick={closeModal}
        style={{
          position: "absolute",
          top: 12,
          right: 12,
        }}
      />
      <HeaderText
        style={{
          opacity: 0.5,
          marginLeft: 12,
          marginBottom: 5,
          fontSize: 13,
          letterSpacing: 0.1,
        }}
      >
        Úkol ze dne {new Date(changedTask.created_date).toLocaleDateString()} od{" "}
        {getFullName(changedTask.created_by)}
      </HeaderText>
      <div
        style={{
          padding: 10,
        }}
      >
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 10,
            }}
          >
            <PriorityDropdown
              disabled={!canEditTask}
              priority={changedTask.priority}
              onSelectPriority={(priority) =>
                setChangedTask((t) => ({ ...t, priority }))
              }
            />
          </div>
          <Input
            data-title={!canEditTask ? changedTask.title : undefined}
            error={errors.title}
            wrapperStyle={{
              width: "100%",
            }}
            disabled={!canEditTask}
            style={{
              fontSize: 23,
              width: "100%",
              padding: "8px 12px",
            }}
            placeholder="Název úkolu"
            value={changedTask.title || ""}
            onChange={(e) =>
              setChangedTask({ ...changedTask, title: e.target.value })
            }
          />
        </FlexCenterAlign>

        <InvisibleTextArea
          placeholder="Popis úkolu"
          disabled={!canEditTask}
          style={{
            marginTop: 18,
            fontSize: 14,
          }}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setChangedTask((t) => ({ ...t, description: e.target.value }))
          }
          value={changedTask.description || ""}
        />

        <div
          style={{
            marginTop: 8,
          }}
        >
          {canEditTask && (
            <GalleryPicker
              mode={GalleryListMode.ChooseAndUploadOrManage}
              type={ImageType.Task}
              onChoose={(image) =>
                setChangedTask({
                  ...changedTask,
                  images: [...changedTask.images, image],
                })
              }
              title="Nahrát foto"
            />
          )}

          <div
            style={{
              overflow: "auto",
              maxHeight: 400,
              marginTop: 8,
            }}
          >
            {changedTask.images?.length > 0 && (
              <div
                style={{
                  marginTop: 12,
                }}
              >
                {changedTask.images.map((image, index) => (
                  <EditorImage
                    preventDelete={!canEditTask}
                    style={{
                      marginBottom: 8,
                      justifyContent: "flex-start",
                    }}
                    key={index}
                    src={image.url}
                    onDeleteClick={() => {
                      setChangedTask({
                        ...changedTask,
                        images: changedTask.images.filter(
                          (_, i) => i !== index
                        ),
                      });
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <FlexCenterAlign
          style={{
            marginTop: 8,
          }}
        >
          <div
            style={{
              marginRight: 8,
              fontSize: 14,
            }}
          >
            Stav:{" "}
          </div>
          <TaskDropdown
            selectedState={changedTask.state}
            setSelectedState={(state) =>
              setChangedTask((t) => ({ ...t, state }))
            }
          />
        </FlexCenterAlign>

        <FlexCenterAlign
          style={{
            marginTop: 8,
            zIndex: 10,
          }}
        >
          <div
            style={{
              marginRight: 8,
              fontSize: 14,
            }}
          >
            Platnost do:{" "}
          </div>
          <CalendarButton
            disabled={!canEditTask}
            selectedDate={{
              date: new Date(changedTask.due_date),
              type: SelectedDateType.Single,
            }}
            setSelectedDate={(date) => {
              if (date?.type === SelectedDateType.Single) {
                setChangedTask((t) => ({
                  ...t,
                  due_date: date.date.getTime(),
                }));
              }
            }}
          />
        </FlexCenterAlign>

        <Flex
          style={{
            marginTop: 10,
          }}
        >
          <div
            style={{
              fontSize: 14,
              marginRight: 8,
              marginTop: canEditTask ? 8 : 3,
            }}
          >
            Pověření:{" "}
          </div>
          {canEditTask ? (
            <DropdownMultiSelect
              disabled={!canEditTask}
              list={users.map((user) => ({
                content: getFullName(user),
                value: user._id,
                query: getFullName(user),
                unique_id: user._id,
                disabled: !!changedTask.assigned.find(
                  (u) => u._id === user._id
                ),
              }))}
              title="Přidat uživatele"
              deleteTitle="Odebrat uživatele"
              onRemoveUniqueID={(id) =>
                setChangedTask((t) => ({
                  ...t,
                  assigned: t.assigned.filter((u) => u._id !== id),
                }))
              }
              onSelectValue={(id) => {
                const user = users.find((u) => u._id === id);

                if (user) {
                  setChangedTask((t) => ({
                    ...t,
                    assigned: [...t.assigned, user],
                  }));
                }
              }}
              selectedDropdownIDs={changedTask.assigned.map((user) => user._id)}
            />
          ) : (
            <div>
              {changedTask.assigned.map((user, i) => (
                <div
                  key={i}
                  style={{
                    marginTop: i === 0 ? 0 : 3,
                    fontSize: 13,
                    backgroundColor: colors.MAIN_400,
                    padding: "4px 12px",
                    borderRadius: 24,
                    cursor: "default",
                    opacity: 0.9,
                  }}
                >
                  {getFullName(user)}
                </div>
              ))}
            </div>
          )}
        </Flex>
        {isOwner && (
          <div>
            <FlexCenterAlign>
              <Checkbox
                value={changedTask.assignees_edit_permission}
                setValue={(value) =>
                  setChangedTask((t) => ({
                    ...t,
                    assignees_edit_permission: value,
                  }))
                }
              />
              <div
                style={{
                  fontSize: 13,
                  opacity: 0.9,
                  marginLeft: 8,
                }}
              >
                Pověření mohou upravovat úkol
              </div>
            </FlexCenterAlign>
          </div>
        )}
      </div>
      {type === EditorType.Add ? (
        <FlexCenterAll
          style={{
            marginTop: 8,
          }}
        >
          <div>
            <Button
              disabled={
                compareTasks(
                  { ...defaultTask, created_by: user },
                  changedTask
                ) || !isValid
              }
              onClick={onAddTaskClick}
              style={{ fontSize: 13, backgroundColor: colors.MAIN_300 }}
              hoverBackgroundColor={colors.MAIN_250}
            >
              Vytvořit úkol
            </Button>
          </div>

          <div>
            <Button
              style={{
                backgroundColor: colors.MAIN_400,
                color: colors.OPPOSITE_MAIN_400,
                fontSize: 13,
                marginLeft: 8,
              }}
              hoverBackgroundColor={colors.MAIN_350}
              onClick={closeModal}
            >
              Storno
            </Button>
          </div>
        </FlexCenterAll>
      ) : (
        <FlexCenterAll
          style={{
            marginTop: 8,
          }}
        >
          {canEditTask ? (
            <>
              <div>
                <Button
                  disabled={
                    (task && compareTasks(task, changedTask)) || !isValid
                  }
                  onClick={onEditTaskClick}
                  style={{ fontSize: 13, backgroundColor: colors.MAIN_300 }}
                  hoverBackgroundColor={colors.MAIN_250}
                >
                  Upravit úkol
                </Button>
              </div>
              {isOwner && (
                <div
                  style={{
                    marginLeft: 8,
                  }}
                >
                  <Button
                    onClick={onRemoveTaskClick}
                    style={{
                      fontSize: 13,
                      backgroundColor: colors.ERROR_400,
                      color: "#fff",
                    }}
                    hoverBackgroundColor={colors.ERROR_500}
                  >
                    Vymazat úkol
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div>
              <Button
                disabled={task && compareTasks(task, changedTask)}
                style={{
                  backgroundColor: colors.MAIN_300,
                  color: colors.OPPOSITE_MAIN_300,
                  fontSize: 13,
                }}
                hoverBackgroundColor={colors.MAIN_250}
                onClick={okClick}
              >
                OK
              </Button>
            </div>
          )}
          <div>
            <Button
              style={{
                backgroundColor: colors.MAIN_400,
                color: colors.OPPOSITE_MAIN_400,
                fontSize: 13,
                marginLeft: 8,
              }}
              hoverBackgroundColor={colors.MAIN_350}
              onClick={closeModal}
            >
              Storno
            </Button>
          </div>
        </FlexCenterAll>
      )}
    </InputsWrapper>
  );
};

export default TaskView;
