import { ITaskDB } from "../../types/tasks";
import { TasksActions, TTasksAction } from "../actions/tasksActions";

export interface ITasksState {
  tasks: ITaskDB[];
}

const initialUserState: ITasksState = {
  tasks: [],
};

export const tasksReducer = (
  state: ITasksState = initialUserState,
  action: TTasksAction
): ITasksState => {
  switch (action.type) {
    case TasksActions.SET_TASKS:
      return { ...state, tasks: action.payload };
    case TasksActions.ADD_TASK:
      return { ...state, tasks: [...state.tasks, action.payload] };
    case TasksActions.EDIT_TASK:
      return (() => {
        const { payload } = action;

        if (!payload) return state;

        const t = [...state.tasks],
          i = t.findIndex((task) => task._id === payload._id);

        t[i] = payload;

        return { ...state, tasks: t };
      })();
    case TasksActions.REMOVE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((t) => t._id !== action.payload),
      };
    default:
      return state;
  }
};
