import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Button from "../../ui/button";
import { FlexCenterAlign, InputsWrapper } from "../../ui/common";
import Overlay from "../common/overlay";
import { FlexCenterAll } from "./../../ui/common";
import { colors } from "./../../ui/colors";
import { useColors } from "../theme/theme-provider";

export interface IConfirmationWindowRef {
  show(title: string, onSubmit: () => void, onCancel?: () => void): void;
}

interface IConfirmationHandlers {
  title: string;
  onSubmit: () => void;
  onCancel?: () => void;
}

interface IProps {}

export const ConfirmationWindow = forwardRef(
  (
    {}: IProps,
    ref: React.ForwardedRef<IConfirmationWindowRef>
  ): React.ReactElement<IProps> => {
    const [active, setActive] = useState(false);
    const [handlers, setHandlers] = useState<IConfirmationHandlers>({
      title: "",
      onSubmit() {},
    });

    useImperativeHandle(
      ref,
      () => ({
        show(title, onSubmit, onCancel) {
          setActive((a) => !a);
          setHandlers({
            title,
            onSubmit,
            onCancel,
          });
        },
      }),
      []
    );

    const escHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handlers.onCancel?.();
        setActive(false);
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", escHandler, false);
      return () => {
        document.removeEventListener("keydown", escHandler, false);
      };
    }, []);

    const colors = useColors()

    return (
      <Overlay active={active} zIndex={2}>
        <InputsWrapper
          style={{
            padding: "24px 40px",
          }}
        >
          <div
            style={{
              fontSize: 20,
              fontWeight: 500,
              textAlign: "center",
              marginBottom: 6,
              color: colors.OPPOSITE_MAIN_400,
              paddingTop: 12,
            }}
          >
            {handlers.title}
          </div>
          <FlexCenterAll
            style={{
              marginTop: 20,
            }}
          >
            <div
              style={{
                marginRight: 8,
              }}
            >
              <Button
                style={{
                  backgroundColor: colors.MAIN_300,
                  fontSize: 14,
                  fontWeight: 400,
                }}
                hoverBackgroundColor={colors.MAIN_250}
                onClick={() => {
                  handlers.onSubmit();
                  setActive(false);
                }}
              >
                OK
              </Button>
            </div>
            <div>
              <Button
                style={{
                  backgroundColor: colors.MAIN_400,
                  fontSize: 14,
                  border: "none",
                  fontWeight: 400,
                }}
                hoverBackgroundColor={colors.MAIN_350}
                onClick={() => {
                  handlers.onCancel?.();
                  setActive(false);
                }}
              >
                Storno
              </Button>
            </div>
          </FlexCenterAll>
        </InputsWrapper>
      </Overlay>
    );
  }
);

export default ConfirmationWindow;
