import React, {
  forwardRef, useEffect, useImperativeHandle,
  useState
} from "react";
import Overlay from "../common/overlay";

export interface IGlobalErrorWindowRef {
  showError(e: ErrorEvent): void;
  close(): void;
}

interface IProps {}

export const GlobalErrorWindow = forwardRef(
  (
    {}: IProps,
    ref: React.ForwardedRef<IGlobalErrorWindowRef>
  ): React.ReactElement<IProps> => {
    const [active, setActive] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        close() {
          setActive(false);
        },
        showError(e) {
          setActive(true);
        },
      }),
      [active]
    );

    const escHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setActive(false);
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", escHandler, false);
      return () => {
        document.removeEventListener("keydown", escHandler, false);
      };
    }, []);

    return (
      <Overlay active={active} zIndex={10000}>
        <div>Erro hadad</div>
      </Overlay>
    );
  }
);

export default GlobalErrorWindow;
