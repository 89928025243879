import React from "react";
import { useColors } from "../../providers/theme/theme-provider";
import { GoPayPaymentState as GoPayPaymentStateEnum } from "../../types/order";
import { getOrderGoPayPaymentState } from "../../locales/determineString";

interface IProps {
  goPayState: GoPayPaymentStateEnum | null;
  isForTable?: boolean;
}

export const GoPayPaymentStateComponent = ({
  goPayState,
  isForTable,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <div
      style={{
        fontSize: 12,
        marginTop: isForTable ? 0 : 12,
        marginBottom: isForTable ? 6 : 0,
        fontWeight: 400,
        color: colors.OPPOSITE_MAIN_100,
        textAlign: "center",
      }}
    >
      {isForTable ? "" : "Aktuální stav platby"}
      {goPayState ? (
        <div
          style={{
            fontWeight: 500,
            fontSize: isForTable ? 12 : 15,
            color: colors.OPPOSITE_MAIN_500,
            marginTop: 4,
          }}
        >
          {getOrderGoPayPaymentState(goPayState)}
        </div>
      ) : (
        <div
          style={{
            color: colors.OPPOSITE_MAIN_300,
            marginTop: 4,
          }}
        >
          (nezjištěno)
        </div>
      )}
    </div>
  );
};

export default GoPayPaymentStateComponent;
