import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isDecimalNumber } from "../../../../../common/functions";
import { useLanguageContext } from "../../../../../common/languange-context";
import {
  useAppConfig,
  useErrors,
  useParameterValues,
  useParameters,
} from "../../../../../common/redux-hooks";
import {
  NonTranslatedString,
  nonTranslatedString,
} from "../../../../../constants/language";
import { useColors } from "../../../../../providers/theme/theme-provider";
import { IProductParameter } from "../../../../../types/parameter";
import { Button } from "../../../../../ui/button";
import {
  DeleteButton,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
} from "../../../../../ui/common";
import Dropdown from "../../../../../ui/dropdown";
import Input from "../../../../../ui/input";
import { getProductParameterErrors } from "../../editor-errors";
import { getContentForParameterValue } from "./content";

const ParameterWrapper = styled.div`
  margin-bottom: 8px;
`;

interface IParameterProps {
  parameter: IProductParameter;
  setParameter(parameter: IProductParameter): void;
  removeParameter?(): void;
  parameterGroupId?: string;
}

export const ParameterInput = ({
  parameter,
  setParameter,
  removeParameter,
  parameterGroupId,
}: IParameterProps): React.ReactElement<IParameterProps> => {
  const parameters = useParameters();
  const { language } = useAppConfig();
  const values = useParameterValues();

  const [search, setSearch] = useState(
    !parameter.value_id && parameter.value_number
      ? parameter.value_number.toString()
      : ""
  );

  useEffect(() => {
    if (parameter.value_number && !parameter.value_id) {
      setSearch(parameter.value_number.toString());
    }
  }, [parameter.value_number, parameter.value_id]);

  const { getError } = useErrors();
  const { errors } = getProductParameterErrors(
    getError,
    parameter,
    parameterGroupId
  );

  const filteredValues = !parameter.parameter
    ? values
    : parameter.parameter.canHaveAnyValue
    ? values
    : values.filter((v) =>
        parameter.parameter?.values.find((_v) =>
          typeof _v === "string" ? _v === v._id : _v._id === v._id
        )
      );

  return (
    <ParameterWrapper>
      <FlexCenterAlign>
        <Dropdown
          disabled={!!parameterGroupId}
          style={{
            width: 240,
          }}
          title="Vyberte parametr"
          list={parameters.map((p) => ({
            content: p.title[language.locale] || <NonTranslatedString />,
            value: p,
            query: p.title[language.locale] || nonTranslatedString,
            unique_id: p._id,
          }))}
          selectedUniqueId={parameter.parameter?._id}
          onSelectValue={(value) => {
            setSearch("");
            setParameter({
              ...parameter,
              parameter: value || undefined,
              value_id: undefined,
              value_number: undefined,
              unit: "",
            });
          }}
        />

        <Dropdown
          error={errors.value_missing || errors.wrong_value_for_parameter}
          title={
            parameter.parameter?.canEditWithNumber
              ? "Vyberte hodnotu nebo napište číslo"
              : "Vyberte hodnotu"
          }
          disabled={!parameter.parameter}
          list={filteredValues.map((v) => ({
            content: getContentForParameterValue(v, language.locale),
            value: v,
            unique_id: v._id,
            query: v.value[language.locale],
          }))}
          selectedUniqueId={parameter.value_id?._id}
          onSelectValue={(value_id) => {
            setParameter({ ...parameter, value_id, unit: "" });
          }}
          onSearchChange={(value) => setSearch(value)}
          onBlurSearch={(search) => {
            const isNumber = isDecimalNumber(search);

            if (
              !isNumber ||
              parameter.value_id ||
              !parameter.parameter?.canEditWithNumber
            ) {
              setParameter({ ...parameter, value_number: undefined });
              return;
            }

            setParameter({ ...parameter, value_number: parseFloat(search) });
          }}
          activeSearch={search}
          canHaveValue={parameter.parameter?.canEditWithNumber}
          style={{
            marginLeft: 8,
            marginRight:
              errors.value_missing || errors.wrong_value_for_parameter ? 0 : 8,
          }}
        />

        <Input
          value={parameter.unit || ""}
          onChange={(e) => setParameter({ ...parameter, unit: e.target.value })}
          disabled={
            !parameter.parameter ||
            !parameter.parameter.canEditWithNumber ||
            !!parameter.value_id
          }
          placeholder="Jednotka"
        />

        {!parameterGroupId && (
          <DeleteButton
            onClick={removeParameter}
            data-title="Odstranit parametr"
            style={{
              padding: 4,
              marginLeft: 8,
            }}
          />
        )}
      </FlexCenterAlign>
    </ParameterWrapper>
  );
};

interface IProps {
  params: IProductParameter[];
  setParams(params: IProductParameter[]): void;
  isForFilter?: boolean;
}

export const ParametersInput = ({
  params,
  setParams,
  isForFilter,
}: IProps): React.ReactElement<IProps> => {
  const colors = useColors();

  return (
    <Flex>
      <InputsWrapper
        style={{
          padding: isForFilter ? 0 : undefined,
        }}
      >
        {params.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              marginBottom: 16,
              padding: "0 16px",
            }}
          >
            {!isForFilter && (
              <div
                style={{
                  marginBottom: 6,
                  opacity: 0.6,
                  fontSize: 13,
                }}
              >
                Nebyly nalezeny žádné parametry
              </div>
            )}
            <div
              style={{
                opacity: 0.8,
                fontSize: 11,
              }}
            >
              {isForFilter
                ? "Filtrujte parametry pomocí stisknutím tlačítka"
                : "Přidejte parametr stisknutím tlačítka"}
            </div>
          </div>
        ) : (
          params.map((p, index) => (
            <ParameterInput
              key={index}
              parameter={p}
              removeParameter={() => {
                setParams([...params].filter((_, i) => i !== index));
              }}
              setParameter={(parameter) => {
                let p = [...params];

                p[index] = parameter;

                setParams(p);
              }}
            />
          ))
        )}
        <FlexCenterAll
          style={{
            width: "100%",
            marginTop: 8,
          }}
        >
          <Button
            style={{
              backgroundColor: colors.MAIN_300,
              padding: "0.6em 1.5em",
            }}
            hoverBackgroundColor={colors.MAIN_250}
            onClick={() => setParams([...params, {}])}
          >
            Přidat parametr
          </Button>
        </FlexCenterAll>
      </InputsWrapper>
    </Flex>
  );
};

export default ParametersInput;
