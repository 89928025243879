import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { useModal } from "../../providers/modal/modal-provider";
import { GalleryPickerModal } from "../gallery-picker";
import { ImageType } from "../../types/gallery";
import styled from "styled-components";

const EditorWrapper = styled.div`
  display: inline;
`;

export interface IRichTextEditorRef {
  getContent(): string;
}

interface IProps {
  value?: string;
  initialValue?: string;
  onEditorChange?(value: string): void;
  imagesUploadHandler?(blobInfo: any, progress: any): Promise<string>;
  imageType?: ImageType;
}

export const RichTextEditor = forwardRef(
  (
    {
      initialValue,
      onEditorChange,
      imagesUploadHandler,
      value,
      imageType,
    }: IProps,
    ref: React.ForwardedRef<IRichTextEditorRef>
  ): React.ReactElement<IProps> => {
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const { createModal } = useModal();

    useImperativeHandle(
      ref,
      () => ({
        getContent() {
          return editorRef.current?.getContent() || "";
        },
      }),
      [editorRef.current]
    );

    return (
      <EditorWrapper>
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          onInit={(_, editor) => (editorRef.current = editor)}
          initialValue={initialValue}
          onEditorChange={onEditorChange}
          value={value}
          init={{
            content_css: "./styles.css",
            font_css: "Roboto",
            branding: false,
            promotion: false,
            height: 600,
            resize: false,
            width: "100%",
            plugins: "link image insertdatetime paste code lists",
            automatic_uploads: false,
            skin: "oxide",
            file_picker_callback: (callback) => {
              createModal(
                <GalleryPickerModal
                  type={imageType}
                  onClickImage={(image) => callback(image.url)}
                />
              );
            },
            language: "cs",
            images_upload_handler: imagesUploadHandler,
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | image media | code",
          }}
        />
      </EditorWrapper>
    );
  }
);

export default RichTextEditor;
