import { ParameterGroupActions, TParameterGroupAction } from "./../actions/parameterGroupsActions";
import { IParameterGroupID } from "./../../types/parameter";

export interface IParameterGroupsState {
  groups: IParameterGroupID[];
}

const initialParameterGroupsState: IParameterGroupsState = {
  groups: [],
};

export const parameterGroupsReducer = (
  state: IParameterGroupsState = initialParameterGroupsState,
  action: TParameterGroupAction
): IParameterGroupsState => {
  switch (action.type) {
    case ParameterGroupActions.SET_PARAMETER_GROUPS:
      return { ...state, groups: action.payload };
    case ParameterGroupActions.ADD_PARAMETER_GROUP:
      return { ...state, groups: [...state.groups, action.payload] };
    case ParameterGroupActions.EDIT_PARAMETER_GROUP:
      let groups = [...state.groups],
        index = groups.findIndex((i) => i._id === action.payload._id);

      groups[index] = action.payload;

      return { ...state, groups };
    case ParameterGroupActions.REMOVE_PARAMETER_GROUP_BY_ID:
      return {
        ...state,
        groups: state.groups.filter((p) => p._id !== action.payload),
      };
    default:
      return state;
  }
};
