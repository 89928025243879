import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryWithPopupErrorHandling } from "../../../common/hooks";
import {
  useAppConfig,
  useErrors,
  usePayments,
} from "../../../common/redux-hooks";
import { getPaymentTypeTitle } from "../../../locales/determineString";
import { paymentModel } from "../../../model/query/paymentModel";
import { useConfirmation } from "../../../providers/confirmation/confirmation-provider";
import { useNotifications } from "../../../providers/notifications/notification-provider";
import { useColors } from "../../../providers/theme/theme-provider";
import {
  ADD_PAYMENT,
  EDIT_PAYMENT,
  REMOVE_PAYMENT_BY_ID,
} from "../../../redux/actions/paymentActions";
import { useDispatch } from "../../../redux/store";
import {
  EditorType,
  TLanguageNumber
} from "../../../types/common";
import { ErrorType } from "../../../types/error";
import { ILanguageID } from "../../../types/language";
import { IPaymentID, PaymentType } from "../../../types/payment";
import { IStoreID } from "../../../types/store";
import BottomFixedWrapperWrapper from "../../../ui/bottom-fixed-wrapper-wrapper";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/checkbox";
import {
  AddButton,
  AddButtonStyles,
  Flex,
  FlexCenterAlign,
  FlexCenterAll,
  InputsWrapper,
  RemoveButton,
  RemoveButtonStyles,
} from "../../../ui/common";
import Dropdown from "../../../ui/dropdown";
import { InputWithTitle } from "../../../ui/input-with-title";
import LanguageSelector from "../../../ui/language-selector";
import BackButton from "../../components/back-button";
import { getPaymentEditorErrors } from "./editor-errors";

const mapPaymentToEditorPayment = (payment: IPaymentID): IEditorPayment => {
  const { type, stores, price } = payment;

  return {
    type,
    price: price || {},
    stores: stores || [],
  };
};

const initialEditorPayment: IEditorPayment = {
  type: PaymentType.Card,
  price: {},
  stores: [],
};

export interface IEditorPayment {
  type: PaymentType;
  price: TLanguageNumber;
  stores: IStoreID[];
}

interface IProps {
  type: EditorType;
}

export const PaymentEditor = ({ type }: IProps): React.ReactElement<IProps> => {
  const { _id } = useParams();
  const [originalEditingPayment, setOriginalEditingPayment] =
    useState<IEditorPayment | null>(null);
  const [payment, setPayment] = useState<IEditorPayment>(initialEditorPayment);

  const { closeNotification } = useNotifications();
  const { getError, clearErrorsOfType } = useErrors();
  const { errors, isValid } = getPaymentEditorErrors(getError, payment);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { call } = useQueryWithPopupErrorHandling();
  const { createConfirmation } = useConfirmation();
  const colors = useColors();
  const appConfig = useAppConfig();

  const [language, setLanguage] = useState<ILanguageID>(appConfig.language);
  const payments = usePayments();

  useEffect(() => {
    return () => {
      clearErrorsOfType(ErrorType.PAYMENT);
      closeNotification();
    };
  }, []);

  useEffect(() => {
    const getPayment = async (id: string) => {
      call(
        () => paymentModel.getPaymentById(id),
        "Zobrazení platební metody nebylo úspěšné",
        null,
        (payment) => {
          const c = mapPaymentToEditorPayment(payment);

          setOriginalEditingPayment(c);
          setPayment(c);
        },
        () => {
          navigate("/payments");
        }
      );
    };

    if (_id && type === EditorType.Edit) {
      getPayment(_id);
    }
  }, [_id, type]);

  const appendPaymentToForm = useCallback(
    (form: FormData) => {
      form.append("type", payment.type);
      form.append("price", JSON.stringify(payment.price));
      form.append(
        "stores",
        JSON.stringify(payment.stores.map((store) => store._id))
      );
    },
    [payment]
  );

  const addPayment = () => {
    const data = new FormData();

    appendPaymentToForm(data);

    call(
      () => ADD_PAYMENT(data),
      "Přidání platební metody nebylo úspěšné",
      "Přidání platební metody bylo úspěšné",
      (newParameter) => {
        dispatch(newParameter);
        navigate("/payments");
      },
      (err) => {
        if (err?.payload) {
          dispatch(err);
        }
      }
    );
  };

  const editPayment = (exit?: boolean) => {
    createConfirmation("Potvrzení: Upravit platební metodu", async () => {
      if (!_id) {
        return;
      }

      const data = new FormData();

      data.append("_id", _id);
      appendPaymentToForm(data);

      call(
        () => EDIT_PAYMENT(data),
        "Úprava platební metody nebyla úspěšná",
        "Úprava platební metody byla úspěšná",
        (action) => {
          dispatch(action);

          if (exit) {
            navigate("/payments");
          } else {
            setPayment(mapPaymentToEditorPayment(action.payload));
          }
        },
        (err) => {
          dispatch(err);
        }
      );
    });
  };

  const removePayment = () => {
    createConfirmation("Potvrzení: Odstranit platební metodu", async () => {
      if (!_id) {
        return;
      }

      call(
        () => REMOVE_PAYMENT_BY_ID(_id),
        "Odstranění platební metody nebylo úspěšné",
        "Odstranění platební metody bylo úspěšné",
        (remove) => {
          dispatch(remove);
          navigate("/payments");
        }
      );
    });
  };

  const node = (
    <FlexCenterAll>
      {type === EditorType.Add ? (
        <Button
          style={{ ...AddButtonStyles, backgroundColor: colors.MAIN_300 }}
          hoverBackgroundColor={colors.MAIN_250}
          data-title={
            payments.some((p) => p.type === payment.type)
              ? "Tento typ platby je již vytvořen"
              : undefined
          }
          disabled={!isValid || payments.some((p) => p.type === payment.type)}
          onClick={addPayment}
        >
          Přidat platební metodu
        </Button>
      ) : (
        <FlexCenterAlign>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton
              data-title={
                originalEditingPayment?.type !== payment.type &&
                payments.some((p) => p.type === payment.type)
                  ? "Tento typ platby je již vytvořen"
                  : undefined
              }
              disabled={
                !isValid ||
                (originalEditingPayment?.type !== payment.type &&
                  payments.some((p) => p.type === payment.type))
              }
              onClick={() => editPayment()}
            >
              Uložit
            </AddButton>
          </div>
          <div
            style={{
              marginRight: 16,
            }}
          >
            <AddButton disabled={!isValid} onClick={() => editPayment(true)}>
              Uložit a zavřít
            </AddButton>
          </div>
          <RemoveButton
            style={{
              ...RemoveButtonStyles,
              display: "block",
            }}
            onClick={removePayment}
          >
            Odstranit
          </RemoveButton>
        </FlexCenterAlign>
      )}
    </FlexCenterAll>
  );

  return (
    <BottomFixedWrapperWrapper
      node={node}
      deps={[isValid, appendPaymentToForm]}
    >
      <div>
        <BackButton path="/payments" />
        <div
          style={{
            marginTop: 40,
            fontSize: 36,
            marginLeft: 8,
            opacity: 0.444,
            marginBottom: 20,
          }}
        >
          {type === EditorType.Add
            ? "Přidání platební metody"
            : "Úprava platební metody"}
        </div>

        <FlexCenterAlign
          style={{
            margin: "20px 8px",
            marginBottom: 16,
          }}
        >
          <LanguageSelector onSelectLanguage={setLanguage} />
        </FlexCenterAlign>

        <Flex>
          <div>
            <Flex>
              <InputsWrapper>
                <div
                  style={{
                    marginLeft: 4,
                    fontSize: 13,
                    marginBottom: 4,
                  }}
                >
                  Typ platební metody
                </div>
                <Dropdown
                  list={Object.values(PaymentType).map((type) => ({
                    content: getPaymentTypeTitle(type),
                    value: type,
                    query: getPaymentTypeTitle(type),
                    unique_id: type,
                  }))}
                  selectedUniqueId={payment.type}
                  onSelectValue={(type) => setPayment((p) => ({ ...p, type }))}
                  isDeleteButtonDisabled
                />
                <InputWithTitle
                  inputProps={{
                    type: "number",
                    error: errors.symbol_empty,
                  }}
                  inputStyle={{
                    width: 320,
                  }}
                  value={payment.price[language.locale] || ""}
                  setValue={(price) => {
                    {
                      setPayment((c) => ({
                        ...c,
                        price: {
                          ...c.price,
                          [language.locale]: price as number,
                        },
                      }));
                    }
                  }}
                  title="Cena platební metody"
                  unit={language.currency.symbol}
                />
                <FlexCenterAlign
                  style={{
                    marginTop: 16,
                  }}
                >
                  <Checkbox
                    value={payment.stores.some(
                      (paymentStore) => paymentStore._id === appConfig.store._id
                    )}
                    setValue={(value) =>
                      setPayment((p) => ({
                        ...p,
                        stores: value ? [...p.stores, appConfig.store] : p.stores.filter((store) => store._id !== appConfig.store._id),
                      }))
                    }
                  />
                  <div
                    style={{
                      marginLeft: 12,
                      fontWeight: 400,
                      fontSize: 13,
                    }}
                  >
                    Dostupné v obchodě{" "}
                    <span
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {appConfig.store.title}
                    </span>
                  </div>
                </FlexCenterAlign>
              </InputsWrapper>
            </Flex>
          </div>
        </Flex>
      </div>
    </BottomFixedWrapperWrapper>
  );
};

export default PaymentEditor;
