import React, { PropsWithChildren, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { statusModel } from "./model/query/statusModel";
import AdminRegistration from "./view/admin-registration/admin-registration";
import Loading from "./ui/loading";
import DefaultCurrencyRegistration from "./view/admin-registration/registration-steps/steps/default-currency-registration";
import { AdminRegistrationProvider } from "./view/admin-registration/admin-registration-provider";
import DefaultLanguageRegistration from "./view/admin-registration/registration-steps/steps/default-language-registration";
import DefaultStoreRegistration from "./view/admin-registration/registration-steps/steps/default-store-registration";
import DefaultAdminRegistration from "./view/admin-registration/registration-steps/steps/default-admin-registration";
import { FlexCenterAll } from "./ui/common";

export const ServerStatusWrapper = ({
  children,
}: PropsWithChildren): React.ReactElement<PropsWithChildren> => {
  const [backendIsOffline, setBackendIsOffline] = useState(false);
  const [shouldRegister, setShouldRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const status = await statusModel.getStatus();
        setIsLoading(false);

        if (status === "OK") {
          return;
        }

        setShouldRegister(true);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
        setBackendIsOffline(true);
      }
    };

    getStatus();
  }, []);

  const reloadStatus = async () => {
    const status = await statusModel.getStatus();

    if (status === "OK") {
      setBackendIsOffline(false);
      setShouldRegister(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (backendIsOffline) {
    return <div>Server neodpovídá.</div>;
  }

  if (shouldRegister) {
    return (
      <AdminRegistrationProvider>
        <FlexCenterAll
          style={{
            width: "100%",
          }}
        >
          <Routes>
            <Route path="/registration/*">
              <Route index element={<AdminRegistration />} />
              <Route
                path="currency-configuration"
                element={<DefaultCurrencyRegistration />}
              />
              <Route
                path="language-configuration"
                index
                element={<DefaultLanguageRegistration />}
              />
              <Route
                path="store-configuration"
                index
                element={<DefaultStoreRegistration />}
              />
              <Route
                path="admin-configuration"
                index
                element={<DefaultAdminRegistration reloadStatus={reloadStatus} />}
              />
              <Route path="*" element={<Navigate to="/registration" />} />
            </Route>
            <Route path="*" element={<Navigate to="/registration" />} />
          </Routes>
        </FlexCenterAll>
      </AdminRegistrationProvider>
    );
  }

  return <>{children}</>;
};

export default ServerStatusWrapper;
