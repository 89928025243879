import React from "react";
import { useSearchParams } from "react-router-dom";
import ViewWrapper from "../view-wrapper";
import ProductActionButtons from "./product-action-buttons";
import ProductSearch from "./product-search";

interface IProps {}

export const Products = ({}: IProps): React.ReactElement<IProps> => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <ViewWrapper>
      <ProductActionButtons />

      <ProductSearch
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </ViewWrapper>
  );
};

export default Products;
