import React, { useRef, useEffect, useCallback, useState } from "react";
import {
  borderRadiusRange,
  useTheme,
} from "../../providers/theme/theme-provider";
import GlobalLanguageSelector from "../../router/global-language-selector";
import { Animations, Theme } from "../../types/theme";
import AppleSwitcher from "../../ui/apple-switcher";
import {
  Flex,
  FlexCenterAlign,
  HeaderText,
  InputsWrapper,
} from "../../ui/common";
import Dropdown from "../../ui/dropdown";
import { Scroller } from "../../ui/number-scroller";
import { ISingleScrollerRef, SingleScroller } from "../../ui/single-scroller";
import { SingleScroller2 } from "../../ui/single-scroller-2";
import ViewWrapper from "../view-wrapper";

interface IProps {}

export const Settings = ({}: IProps): React.ReactElement<IProps> => {
  const {
    updateTheme,
    updateBorderRadius,
    updateAnimations,
    animations,
    borderRadius,
    theme,
  } = useTheme();

  const [currentBorderRadius, setCurrentBorderRadius] = useState(borderRadius);

  useEffect(() => {
    if (currentBorderRadius === -1) {
      setCurrentBorderRadius(borderRadius);
    }
  }, [borderRadius, currentBorderRadius]);

  const handleValue = (value: number) => {
    setCurrentBorderRadius(value);
    updateBorderRadius(Math.round(value));
  };

  return (
    <ViewWrapper>
      <HeaderText
        style={{
          marginLeft: 5,
          marginBottom: 12,
        }}
      >
        Nastavení aplikace
      </HeaderText>

      <Flex>
        <InputsWrapper
          style={{
            marginRight: 16,
          }}
        >
          <div
            style={{
              fontSize: 12,
              marginLeft: 5,
              marginBottom: 6,
              fontWeight: 500,
              opacity: 0.75,
            }}
          >
            Jazyk aplikace
          </div>
          <GlobalLanguageSelector />
        </InputsWrapper>
        <InputsWrapper
          style={{
            minWidth: 240,
          }}
        >
          <div
            style={{
              fontSize: 12,
              marginLeft: 5,
              marginBottom: 12,
              fontWeight: 500,
              opacity: 0.75,
            }}
          >
            Vzhled aplikace
          </div>
          <FlexCenterAlign>
            <AppleSwitcher
              value={theme === Theme.Light}
              setValue={(value) => {
                value ? updateTheme(Theme.Light) : updateTheme(Theme.Dark);
              }}
            />
            <div
              style={{
                fontSize: 12,
                marginLeft: 8,
                fontWeight: 500,
              }}
            >
              {theme === Theme.Light ? "Světlý režim" : "Tmavý režim"}
            </div>
          </FlexCenterAlign>
          <FlexCenterAlign
            style={{
              marginTop: 10,
            }}
          >
            <AppleSwitcher
              value={animations === Animations.Pretty}
              setValue={(value) => {
                value
                  ? updateAnimations(Animations.Pretty)
                  : updateAnimations(Animations.None);
              }}
            />
            <div
              style={{
                fontSize: 12,
                marginLeft: 8,
                fontWeight: 500,
              }}
            >
              {animations === Animations.Pretty ? "Animace" : "Bez animací"}
            </div>
          </FlexCenterAlign>

          <div
            style={{
              marginTop: 16,
            }}
          >
            <div
              style={{
                fontSize: 12,
                marginLeft: 8,
                fontWeight: 500,
                textAlign: "center",
                marginTop: 8,
                marginBottom: 4,
              }}
            >
              Zaoblení krajů
            </div>
            <SingleScroller2
              range={borderRadiusRange}
              value={currentBorderRadius}
              setValue={handleValue}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              marginLeft: 8,
              fontWeight: 500,
              textAlign: "center",
              marginTop: 4,
              marginBottom: 4,
            }}
          >
            {((borderRadius / borderRadiusRange.max) * 100).toFixed(0)}%
          </div>
        </InputsWrapper>
      </Flex>
    </ViewWrapper>
  );
};

export default Settings;
