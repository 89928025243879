import { IEmailID } from "../../types/email";
import { IEmailRecipientID } from "../../types/emailRecipient";
import axios from "../axios";

export interface IEmailsModel {
  getEmails(): Promise<IEmailID[]>;
  getEmailById(id: string): Promise<IEmailID | null>;
  getUnfinishedEmails(): Promise<IEmailID[]>;
  getEmailRecipients(): Promise<IEmailRecipientID[]>;
  removeEmail(id: string): Promise<boolean>;
  changeRecipientActive(
    recipientId: string,
    isDisabled: boolean
  ): Promise<"OK">;
  sendEmail(data: FormData): Promise<IEmailID>;
  sendTestEmail(data: FormData): Promise<boolean>;
  saveEmail(data: FormData): Promise<IEmailID>;
}

export const emailsModel: IEmailsModel = {
  getEmails() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IEmailID[]>(`/emails/get-all`);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getEmailById(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IEmailID>(`/emails/get-by-id`, {
          params: { id },
        });

        res(response.data);
      } catch (err: any) {
        res(null);
      }
    });
  },
  getUnfinishedEmails() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IEmailID[]>(
          `/emails/get-all-unfinished`
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  getEmailRecipients() {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get<IEmailRecipientID[]>(
          `/emails/get-all-recipients`
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  changeRecipientActive(id, isDisabled) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<"OK">(
          `/emails/change-recipient-active`,
          { id, isDisabled }
        );

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  sendEmail(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IEmailID>(`/emails/send`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  removeEmail(id) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/emails/remove`, { id });

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  saveEmail(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<IEmailID>(`/emails/save`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
  sendTestEmail(data: FormData) {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<boolean>(`/emails/send-test`, data);

        res(response.data);
      } catch (err: any) {
        rej(err.response.data);
      }
    });
  },
};
