import { ErrorsActions } from "./errorsActions";
import { languageModel } from "../../model/query/languageModel";
import { ILanguageID } from "../../types/language";
import { adminRegistrationModel } from "../../model/query/adminRegistrationModel";

export enum LanguageActions {
  SET_LANGUAGES = "SET_LANGUAGES",
  ADD_LANGUAGE = "ADD_LANGUAGE",
  EDIT_LANGUAGE = "EDIT_LANGUAGE",
  REMOVE_LANGUAGE_BY_ID = "REMOVE_LANGUAGE_BY_ID",
}

type TSetLanguagesAction = {
  type: LanguageActions.SET_LANGUAGES;
  payload: ILanguageID[];
};

type TAddLanguageAction = {
  type: LanguageActions.ADD_LANGUAGE;
  payload: ILanguageID;
};

type TEditLanguageAction = {
  type: LanguageActions.EDIT_LANGUAGE;
  payload: ILanguageID;
};

type TRemoveLanguageByIdAction = {
  type: LanguageActions.REMOVE_LANGUAGE_BY_ID;
  payload: string;
};

export type TLanguageAction =
  | TSetLanguagesAction
  | TAddLanguageAction
  | TEditLanguageAction
  | TRemoveLanguageByIdAction;

export const FETCH_AND_SET_LANGUAGES = async () => {
  try {
    const languages = await languageModel.getLanguages();

    return {
      type: LanguageActions.SET_LANGUAGES,
      payload: languages,
    };
  } catch (err) {
    throw err;
  }
};

export const SET_LANGUAGES = (languages: ILanguageID[]) => {
  return {
    type: LanguageActions.SET_LANGUAGES,
    payload: languages,
  };
};

export const ADD_LANGUAGE = async (data: FormData) => {
  try {
    const language = await languageModel.addLanguage(data);

    return {
      type: LanguageActions.ADD_LANGUAGE,
      payload: language,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const EDIT_LANGUAGE = async (data: FormData) => {
  try {
    const language = await languageModel.editLanguage(data);

    return {
      type: LanguageActions.EDIT_LANGUAGE,
      payload: language,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const ADD_DEFAULT_LANGUAGE = async (data: FormData) => {
  try {
    const language = await adminRegistrationModel.addDefaultLanguage(data);

    return {
      type: LanguageActions.ADD_LANGUAGE,
      payload: language,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const EDIT_DEFAULT_LANGUAGE = async (data: FormData) => {
  try {
    const language = await adminRegistrationModel.editDefaultLanguage(data);

    return {
      type: LanguageActions.EDIT_LANGUAGE,
      payload: language,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: err,
    };
  }
};

export const REMOVE_LANGUAGE_BY_ID = async (id: string) => {
  try {
    await languageModel.removeLanguageById(id);

    return {
      type: LanguageActions.REMOVE_LANGUAGE_BY_ID,
      payload: id,
    };
  } catch (err) {
    throw {
      type: ErrorsActions.SET_ERRORS,
      payload: [],
    };
  }
};
