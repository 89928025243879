import { combineReducers } from "@reduxjs/toolkit";
import { searchReducer as search } from "./searchReducer";
import { categoriesReducer as categories } from "./categoriesReducer";
import { currenciesReducer as currencies } from "./currencyReducer";
import { parametersReducer as parameters } from "./parametersReducer";
import { parameterValuesReducer as parameterValues } from "./parameterValuesReducer";
import { productsReducer as products } from "./productsReducer";
import { languagesReducer as languages } from "./languagesReducer";
import { appConfigReducer as appConfig } from "./appConfigReducer";
import { galleryReducer as gallery } from "./galleryReducer";
import { userReducer as user } from "./userReducer";
import { tasksReducer as tasks } from "./tasksReducer";
import { usersReducer as users } from "./usersReducer";
import { historyActionsReducer as historyActions } from "./historyActionsReducer";
import { errorsReducer as errors } from "./errorsReducer";
import { manufacturersReducer as manufacturers } from "./manufacturersReducer";
import { parameterGroupsReducer as parameterGroups } from "./parameterGroupsReducer";
import { filtersReducer as filters } from "./filtersReducer";
import { shippingsReducer as shippings } from "./shippingsReducer";
import { paymentsReducer as payments } from "./paymentsReducer";
import { storesReducer as stores } from "./storesReducer";

export type TAction = {
  type: string;
  payload: any;
};

const appReducer = combineReducers({
  search,
  languages,
  currencies,
  categories,
  products,
  parameters,
  parameterValues,
  appConfig,
  gallery,
  stores,
  user,
  tasks,
  users,
  errors,
  manufacturers,
  historyActions,
  parameterGroups,
  filters,
  shippings,
  payments,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
