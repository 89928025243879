import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { useQueryWithPopupErrorHandling } from "../../common/hooks";
import { adminRegistrationModel } from "../../model/query/adminRegistrationModel";
import { SET_CURRENCIES } from "../../redux/actions/currencyActions";
import { SET_LANGUAGES } from "../../redux/actions/languagesActions";
import { SET_STORES } from "../../redux/actions/storesActions";
import { useDispatch } from "../../redux/store";
import Button from "../../ui/button";
import { FlexCenterAll, InputsWrapper } from "../../ui/common";
import { InputWithTitle } from "../../ui/input-with-title";
import {
  useAdminRegistration
} from "./admin-registration-provider";

const Heading = styled.h2`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {}

export const AdminRegistration = ({}: IProps): React.ReactElement<IProps> => {
  return (
    <Wrapper>
      <AdminRegistrationKeyInput />
    </Wrapper>
  );
};

export const AdminRegistrationKeyInput = () => {
  const [registrationKey, setRegistrationKey] = useState("");
  const { call } = useQueryWithPopupErrorHandling();
  const [hasError, setHasError] = useState(false);
  const { setAdminRegistration, adminRegistration } = useAdminRegistration();
  const dispatch = useDispatch();

  useEffect(() => {
    setHasError(false);
  }, [registrationKey]);

  const onSubmit = () => {
    if (!registrationKey) {
      return setHasError(true);
    }

    call(
      () => adminRegistrationModel.checkRegistrationKey(registrationKey),
      "Zadaný registrační klíč není správný",
      null,
      (data) => {
        setAdminRegistration((a) => ({
          ...a,
          registrationKey,
          store: data.store,
          language: data.language,
          user: data.admin,
          currency: data.currency,
        }));

        if (data.currency) {
          dispatch(SET_CURRENCIES([data.currency]));
        }

        if (data.language) {
          dispatch(SET_LANGUAGES([data.language]));
        }

        if (data.store) {
          dispatch(SET_STORES([data.store]));
        }
      },
      () => {
        setHasError(true);
      }
    );
  };

  if (adminRegistration.registrationKey) {
    return <Navigate to="/registration/currency-configuration" />;
  }

  return (
    <InputsWrapper
      style={{
        padding: 30,
      }}
    >
      <Heading>Registrace nové administrace</Heading>
      <InputWithTitle
        titleStyle={{ textAlign: "center", width: "100%" }}
        wrapperStyle={{ width: "100%" }}
        inputStyle={{ width: "100%" }}
        inputProps={{
          error:
            hasError && !registrationKey
              ? "Zadejte registrační klíč"
              : hasError
              ? "Nesprávný registrační klíč"
              : undefined,
        }}
        title="Zadejte registrační klíč"
        value={registrationKey}
        setValue={setRegistrationKey}
      />
      <FlexCenterAll
        style={{
          marginTop: 12,
        }}
      >
        <Button onClick={onSubmit}>Potvrdit</Button>
      </FlexCenterAll>
    </InputsWrapper>
  );
};

export default AdminRegistration;
