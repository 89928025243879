import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useColors } from "../../../../providers/theme/theme-provider";
import { EditorType } from "../../../../types/common";
import { ILanguageID } from "../../../../types/language";
import Button from "../../../../ui/button";
import { AddButtonStyles, FlexCenterAll } from "../../../../ui/common";
import LanguageEditor from "../../../languages/language-editor/language-editor";
import { useAdminRegistration } from "../../admin-registration-provider";

interface IProps {}

export const DefaultLanguageRegistration =
  ({}: IProps): React.ReactElement<IProps> => {
    const navigate = useNavigate();
    const colors = useColors();
    const { adminRegistration, setAdminRegistration } = useAdminRegistration();

    const onSuccess = (language: ILanguageID) => {
      setAdminRegistration((a) => ({ ...a, language }));
    };

    const onContinue = () => {
      navigate("/registration/store-configuration");
    };

    if (!adminRegistration.registrationKey) {
      return <Navigate to="/registration" />;
    }

    return (
      <div>
        <LanguageEditor
          onSuccess={onSuccess}
          isForDefaultLanguage
          outerId={
            adminRegistration.language
              ? adminRegistration.language._id
              : undefined
          }
          type={adminRegistration.language ? EditorType.Edit : EditorType.Add}
          registrationKey={adminRegistration.registrationKey}
        />
        <FlexCenterAll
          style={{
            marginTop: 12,
          }}
        >
          <Button
            disabled={!adminRegistration.language}
            style={{
              ...AddButtonStyles,
              backgroundColor: colors.MAIN_400,
              fontSize: 15,
            }}
            hoverBackgroundColor={colors.MAIN_350}
            onClick={onContinue}
          >
            Pokračovat
          </Button>
        </FlexCenterAll>
      </div>
    );
  };

export default DefaultLanguageRegistration;
