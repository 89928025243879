import React, { PropsWithChildren, ReactNode, useState } from "react";
import { useBottomFixedWrapper } from "./bottom-fixed-wrapper";

interface IProps extends PropsWithChildren {
  node: ReactNode;
  deps: React.DependencyList;
}

export const BottomFixedWrapperWrapper = ({
  node,
  children,
  deps,
}: IProps): React.ReactElement<IProps> => {
  const [height, setHeight] = useState(0);

  useBottomFixedWrapper(
    (height) => {
      setHeight(height);
    },
    node,
    deps
  );

  return (
    <div
      style={{
        paddingBottom: height,
      }}
    >
      {children}
    </div>
  );
};

export default BottomFixedWrapperWrapper;
