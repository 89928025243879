import { CouponType } from "../types/coupon";
import { HistoryActionItemType, HistoryActionType } from "../types/history";
import { TUnit } from "../types/units";

export const nonTranslatedString = "(Nepřeloženo)";

export const NonTranslatedString = () => {
  return (
    <span
      style={{
        opacity: 0.5,
      }}
    >
      {nonTranslatedString}
    </span>
  );
};

export const defaultLanguageKey = "_default";

export const translateUnit = (unit: TUnit): string => {
  switch (unit) {
    default:
      return unit;
  }
};

export const translateHistoryActionType = (type: HistoryActionType): string => {
  switch (type) {
    case HistoryActionType.ADD:
      return "Přidáno";
    case HistoryActionType.EDIT:
      return "Upraveno";
    case HistoryActionType.REMOVE:
      return "Vymazáno";
  }
};

export const translateHistoryActionItemType = (
  type: HistoryActionItemType
): string => {
  switch (type) {
    case HistoryActionItemType.PRODUCT:
      return "Produkt";
    case HistoryActionItemType.CATEGORY:
      return "Category";
  }
};

export const translateCouponType = (
  type: CouponType
): string => {
  switch (type) {
    case CouponType.Percent:
      return "% Procentuální slevový kupón";
    case CouponType.Value:
      return "- Hodnota zboží ve měně";
  }
};
